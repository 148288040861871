import React, { useState, useEffect, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { useDispatch } from 'react-redux';
import * as appActions from 'modules/app/appActions';
import InputFeedback from 'components/InputFeedback/InputFeedback';
import Button from 'components/Button/Button';
import colors from 'theme/Colors.module.scss';
import 'theme/Forms.scss';

const defaults = { resetEmail: '' };
const schema = Yup.object().shape({
  resetEmail: Yup.string()
    .email('Invalid email.')
    .required('Email is required.')
    .trim(),
});

const ResetPassword = props => {
  const [emailSent, setEmailSent] = useState(false);

  const dispatch = useDispatch();

  // Actions
  const sendPwResetEmail = useCallback(
    (payload, doneCallback) =>
      dispatch(appActions.sendPwResetEmail(payload, doneCallback)),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  useEffect(() => {
    emailSent && setEmailSent(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = (values, isSubmitting, resetForm) => {
    const { resetEmail } = values;

    const payload = {
      email: resetEmail,
    };

    sendPwResetEmail(payload, () => {
      resetForm();
      !emailSent && setEmailSent(true);
    });

    isSubmitting(false);
  };

  return (
    <React.Fragment>
      <Formik
        initialValues={defaults}
        validationSchema={schema}
        onSubmit={(values, { setSubmitting, resetForm }) =>
          handleSubmit(values, setSubmitting, resetForm)
        }
      >
        {form => (
          <Form className="authForm">
            <h1 className="title">Reset your Password</h1>

            <Field
              name="resetEmail"
              type="email"
              placeholder="Your Email*"
              onFocus={() => emailSent && setEmailSent(false)}
            />
            <InputFeedback name="resetEmail" />

            {emailSent && (
              <p style={{ color: colors.status00 }}>
                <strong>Please check your email to continue</strong>.
              </p>
            )}

            <Button
              type="submit"
              text="Reset my Password"
              textColor={colors.white}
              btnStyle="solid"
              disabled={form.isSubmitting}
            />

            <p className="loginOptions">
              <Link to="/login">Log in</Link>.
            </p>

            <p className="footnote">An asterisk (*) marks a required field.</p>
          </Form>
        )}
      </Formik>
    </React.Fragment>
  );
};

export default ResetPassword;
