const environment = (() => {
  switch (window.location.hostname) {
    case 'api.undigital.com':
      return 'production';
    case 'qa.progpack.goundigital.net':
      return 'qa';
    case 'demo.progpack.goundigital.net':
      return 'demo';
    case 'localhost':
      return 'development';
    default:
      return null;
  }
})();

export const uploadServiceEnv = {
  production: 'https://upl.undigital.com/api/v1',
  qa: 'https://qa.upl.goundigital.net/api/v1',
  demo: 'https://demo.upl.goundigital.net/api/v1',
  development: 'http://localhost:4100/api/v1',
}[environment];

export default environment;
