import classNames from 'classnames';
import DotLoader from 'react-spinners/DotLoader';

import colors from '../../theme/Colors.module.scss';

export default ({ requesting }) => {
  return (
    <div
      className={classNames('EntityTable-progress', {
        'EntityTable-progress-active': requesting,
      })}
    >
      <DotLoader color={colors.blue} size="40px" margin="4px" />
    </div>
  );
};
