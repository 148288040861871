import React from 'react';
import { Box, Flex, Text } from '@chakra-ui/react';
import Icons from 'components/Icons';

export default function Modal({
  children,
  isLargerThan750,
  open,
  onClose,
  modalState,
  step,
}) {
  return (
    <>
      <Box
        w="100%"
        h="100%"
        bgColor="rgba(0, 0, 0, 0.4)"
        zIndex={888}
        position="fixed"
        top="0"
        left="0"
        display={open ? 'block' : 'none'}
        transition="0.3s"
      />

      <Flex
        display={open ? 'flex' : 'none'}
        position="fixed"
        w="100%"
        h="100%"
        justify="center"
        align="center"
        top="0"
        left="0"
        zIndex={999}
      >
        <Box
          minW="250px"
          maxW="90%"
          bgColor="white"
          p="1rem 1rem 2rem 1rem"
          transition="0.3s"
          m={!isLargerThan750 ? '0 1rem' : 0}
          borderRadius="5px"
        >
          <Flex justify="end">
            <Icons.Close
              height="15"
              style={{ cursor: 'pointer' }}
              onClick={onClose}
            />
          </Flex>

          <Box m={isLargerThan750 ? `0 2rem` : '0'}>
            <Flex align="center" direction="column">
              {modalState.title && (
                <Text color="#293854" fontSize="20px" fontWeight="700" m="0">
                  {modalState.title}
                </Text>
              )}
              {modalState.description && (
                <Text color="#293854e5" fontSize="18px" mt="1.5rem">
                  {modalState.description}
                </Text>
              )}
            </Flex>
            {children}
          </Box>
        </Box>
      </Flex>
    </>
  );
}
