import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as appActions from 'modules/app/appActions';
import * as appSelectors from 'modules/app/appSelectors';
import { showMessages } from 'helpers/messageHelpers';
import Editor from './Editor';
import Providers from './Providers';

const TemplateEditor = props => {
  const dispatch = useDispatch();
  const messages = useSelector(state => appSelectors.messages(state));
  const clearMessage = index => dispatch(appActions.clearMessage(index));

  return (
    <Providers>
      <Editor {...props} />
      {showMessages(messages, clearMessage)}
    </Providers>
  );
};

export default TemplateEditor;
