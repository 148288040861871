import React from 'react';
import { Box, Button } from '@chakra-ui/react';
import Icons from './components/Icons';
import { useHistory } from 'react-router-dom';

export default function () {
  const history = useHistory();

  return (
    <Box
      sx={{
        height: '100vh',
        width: '100vw',
        flexDirection: 'column',
        position: 'relative',
        display: 'flex',
      }}
    >
      <Box
        sx={{
          height: '4rem',
          background: 'linear-gradient(90deg,#4e90d6,#4e50d6 53%,#7f66f1)',
          flex: 'none',
          display: 'grid',
          alignItems: 'center',
          padding: '0 1rem',
          gridTemplateColumns: '360px 1fr 360px',
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Box
            onClick={() => {
              history.push('/templates');
            }}
            sx={{
              display: 'flex',
              alignItems: 'center',
              fontWeight: 500,
              height: '42px',
              paddingRight: '1rem',
              fontSize: '14px',
              color: '#ffffff',
              ':hover': {
                background: 'rgba(255,255,255,0.15)',
                cursor: 'pointer',
              },
            }}
          >
            <Icons.ChevronLeft size={24} />
            Exit
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          flex: 1,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          marginBottom: '3rem',
          flexDirection: 'column',
          gap: '1rem',
          maxWidth: '1200px',
          margin: 'auto',
        }}
      >
        There are not format sizes enabled for your account. Please get in touch
        with our support team to enable one.
        <Button
          onClick={() => {
            history.push('/templates');
          }}
          colorScheme={'orange'}
        >
          Go back
        </Button>
      </Box>
    </Box>
  );
}
