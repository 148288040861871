import React from 'react';

function CheveronDown({ height = 14, width = 14, style = {} }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 12 12"
      fill="none"
      style={{ ...style }}
    >
      <path
        d="M9 4.80005L6 7.80005L3 4.80005"
        stroke={style.color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default CheveronDown;
