import React from 'react';
import { Input } from '@chakra-ui/react';
import colors from 'theme/Colors.module.scss';

export const TextField = ({ text, ...props }) => (
  <Input
    border={`1px solid ${colors.grey300}`}
    borderRadius="4px"
    focusBorderColor={colors.primary600}
    h="35px"
    {...props}
  />
);