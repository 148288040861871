import React from 'react';
import { NavLink } from 'react-router-dom';
import './Menu.scss';

const Menu = props => {
  const { content } = props;

  const buildMenu = () => {
    const renderContent = [];
    // const Specific = Icons.Calendar;
    content.menuItems.forEach((item, index) => {
      // const iconElement = React.createRef();
      const LocalIcon = item.icon
      renderContent.push(
        item.link === '/logout' ? (
          <div key={index} onClick={item.callback}>
            <li className="menu__item">
              <LocalIcon width={item.iconSize} height={item.iconSize}/>
              {item.text}
            </li>
          </div>
        ) : (
          <NavLink
            key={index}
            to={item.link}
            exact={item.exact}
            activeClassName="menu__active-item"
            onClick={() => {
              window.scrollTo(0, 0);
              item.callback();
            }}
          >
            <li className="menu__item">
              <LocalIcon width={item.iconSize} height={item.iconSize}/>
              {item.text}
            </li>
          </NavLink>
        )
      );
    });

    return renderContent;
  };

  const buildTitle = () => {
    if (content.title) {
      return <h5 className="menu__title">{content.title.toUpperCase()}</h5>;
    }
  };

  return (
    <>
      {buildTitle()}
      <ul id={`${content.title}-menu__list`} className="menu__list">
        {buildMenu()}
      </ul>
    </>
  );
};

export default Menu;
