import { fromJS } from 'immutable';
import { schema } from 'normalizr';
import { success, failure, error, start } from 'helpers/reducerHelpers';
// import { searchParams, pageParams, paramsToString, dashboardParams } from 'helpers/paramHelpers';
// import { actionCreator } from 'helpers/actionHelpers';
// import { createSelector } from 'reselect';
import { createApiErrorSelector } from '../api/apiDuck';
import * as api from './analyticsApi';

// Constants
const GET_STATS_BY_ID = 'modules/analytics/GET_STATS_BY_ID';
const STATE_KEY_ANALYTICS = 'analytics';
const STATE_KEY_ANALYTICS_BY_ID = 'analyticsById';
const STATE_KEY_STATS_BY_ID = 'statsById';

export const analyticsConstants = {
  GET_STATS_BY_ID,
  STATE_KEY_ANALYTICS,
  STATE_KEY_ANALYTICS_BY_ID,
  STATE_KEY_STATS_BY_ID,
};

// Schema
const SCHEMA_ANALYTICS = 'analytics';
export const analyticsSchema = new schema.Entity(SCHEMA_ANALYTICS);

// Reducer
export const reducer = (ogState = {}, action) => {
  const state = fromJS(ogState);

  switch (action.type) {
    case start(GET_STATS_BY_ID):
      return state.set(`${STATE_KEY_STATS_BY_ID}.loading`, true).toJS();
    case success(GET_STATS_BY_ID):
      return state
        .merge({
          [STATE_KEY_STATS_BY_ID]: action.payload.data,
          [`${STATE_KEY_STATS_BY_ID}.loading`]: false,
        })
        .toJS();

    case failure(GET_STATS_BY_ID):
    case error(action.type):
      // GENERIC API ERROR HANDLER, will match any error for any type
      //console.log('ANALYTICS_REDUCER : ', 'API_ERROR', action.payload)
      return ogState;

    default:
      return ogState;
  }
};

// Actions
export const analyticsActions = {
  getStatsById: (id, params) => api.getStatsById(id, params, GET_STATS_BY_ID),
};

// Selectors
export const analyticsSelectors = {
  statsById: state => state.modules.analytics[STATE_KEY_STATS_BY_ID],
  isStatsLoading: state =>
    state.modules.analytics[`${STATE_KEY_STATS_BY_ID}.loading`],
  getApiError: createApiErrorSelector([GET_STATS_BY_ID]),
};

export default reducer;
