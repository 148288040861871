import React from "react"

function ChevronDown({ size }) {
    return (
        <svg height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M19.0571 8L12.4695 14.5876C12.3427 14.7093 12.1737 14.7773 11.998 14.7773C11.8223 14.7773 11.6533 14.7093 11.5265 14.5876L4.9429 8.00333L4 8.94624L10.5836 15.5306C10.965 15.894 11.4716 16.0967 11.9983 16.0967C12.5251 16.0967 13.0317 15.894 13.413 15.5306L20 8.9429L19.0571 8Z" fill="currentColor" />
        </svg>
    )
}

export default ChevronDown