import React, { useState } from 'react';
// import { useSelector } from 'react-redux';
// import _ from 'lodash';
import { withRouter } from 'react-router-dom';
import { defaultModal, openModal, showModal } from 'helpers/modals';
import { useFlag } from 'flags';
// import AudSummary from './AudSummary';
import Tooltip from 'components/Tooltip/Tooltip';
import DeleteAudience from '../Modals/DeleteAudience';
import Button from 'components/Button/Button';
import TableRowCard from 'components/TableRowCard/TableRowCard';
import colors from 'theme/Colors.module.scss';
// import { formatDate } from 'react-day-picker/moment';
import { parseISO } from 'date-fns';
import { format } from 'date-fns-tz';
import './AudienceCard.scss';
// import * as audiencesActions from 'modules/audiences/audiencesActions';
// import * as audiencesSelectors from 'modules/audiences/audiencesSelectors';

// This component requires an object as a prop called "content" that is used to build.sh the card.

const userTz = Intl.DateTimeFormat().resolvedOptions().timeZone;

const AudienceCard = props => {
  const {
    headers,
    onClick,
    focus,
    // history,
    // isMobile,
    deleteAudience,
    // entry,
    // ruleSets,
    onDelete,
    content: {
      is_campaign_using,
      inserted_at,
      updated_at,
      name,
      // type,
      // size,
      id,
    },
  } = props;

  const [warningModal, setWarningModal] = useState(defaultModal);
  const isReadOnly = useFlag(['features', 'isReadOnly']);

  const deleteAud = id => {
    deleteAudience(id);
    setWarningModal(defaultModal);
    if (onDelete) onDelete();
  };

  const getIndicatorColor = () => {
    const borderColor = is_campaign_using
      ? colors.status00
      : colors.greyDisabled;
    return borderColor;
  };

  const dateCreated = inserted_at
    ? format(parseISO(inserted_at), 'LLL do, yyyy h:mm:ss aaa', {
        timeZone: userTz,
      })
    : '--';
  const lastUpdated = updated_at
    ? format(parseISO(updated_at), 'LLL do, yyyy h:mm:ss aaa', {
        timeZone: userTz,
      })
    : '--';

  // Temporary hidden due to lack of ruleset data
  // const includes =
  //   (ruleSets && ruleSets.filter(set => set.logical_op_type)) || 0;
  // const excludes =
  //   (ruleSets && ruleSets.filter(set => !set.logical_op_type)) || 0;
  // let totalRules = 0;

  // ruleSets?.forEach(set => set.rules.forEach(rule => (totalRules += 1)));

  return (
    <TableRowCard
      className={`audCard ${isReadOnly && 'readOnly'}`}
      to={`/audiences/${id}`}
      onClick={onClick}
      focus={focus}
      headers={headers}
    >
      <td
        className="audName"
        style={{
          borderLeft: `5px solid ${getIndicatorColor()}`,
        }}
      >
        {name || '--'}
      </td>

      {/*
        <li className="audType">
          {isMobile && <span className="audCardTitle">Type: &nbsp;</span>}
          {type || '--'}
        </li>

        <li className="audSize">
          {isMobile && <span className="audCardTitle">Size: &nbsp;</span>}
          {size || '--'}
        </li>
        */}

      <td className="audDateCreated">{dateCreated}</td>
      <td className="audLastUpdated">{lastUpdated}</td>

      {/* Temporary hidden due to lack of ruleset data */}
      {/* <td className="audSummary">
        {isMobile && <span className="audCardTitle">Summary: &nbsp;</span>}

        <div className="summaryGroup">
          {totalRules} Rules in {includes.length + excludes.length || 0} Sets
          &nbsp;
          <Tooltip
            fullWidth
            tipText={() => <AudSummary data={{ includes, excludes }} />}
            direction="left"
          />
        </div>
      </td> */}

      {!isReadOnly && (
        <td className="audActions" style={{ paddingLeft: '0.5rem' }}>
          <Tooltip
            tipText={
              is_campaign_using
                ? 'Detach audience from all campaigns to remove.'
                : 'Delete audience.'
            }
            direction="left"
            render={() => (
              <Button
                icon="delete"
                iconColor={colors.slate}
                btnStyle="transparent"
                disabled={is_campaign_using}
                onClick={event => {
                  event.stopPropagation();
                  event.preventDefault();

                  if (!is_campaign_using) {
                    openModal(
                      setWarningModal,
                      id,
                      'Caution: Audience Removal!',
                      <DeleteAudience
                        callback={deleteAud}
                        name={name}
                        id={id}
                      />
                    );
                  }
                }}
                data-id={id}
              />
            )}
          />
          {showModal(warningModal, setWarningModal)}
        </td>
      )}
    </TableRowCard>
  );
};

export default withRouter(AudienceCard);
