import { createAction } from '@reduxjs/toolkit';
import { createApiAction } from '../../helpers/actionHelpers';
// import {
//   campaignParams,
//   pageParams,
//   paramsToString,
// } from '../../helpers/paramHelpers';
import * as api from './campaignsApi';

export const setCurrentCreative = createAction(
  'modules/campaigns/SET_CREATIVE'
);
export const clearCurrentCampaign = createAction(
  'modules/campaigns/CLEAR_CURRENT_CAMPAIGN'
);
export const getCampaign = createApiAction(
  'modules/campaigns/GET_CAMPAIGN',
  (type, id) => api.getCampaign(id, type)
);
export const createCampaign = createApiAction(
  'modules/campaigns/CREATE_CAMPAIGN',
  (type, campaign, doneCallback, failureCallback) =>
    api.createCampaign(campaign, type, doneCallback, failureCallback)
);
export const updateCampaign = createApiAction(
  'modules/campaigns/UPDATE_CAMPAIGN',
  (type, id, campaign, doneCallback, failureCallback) =>
    api.updateCampaign(id, campaign, type, doneCallback, failureCallback)
);
export const deleteCampaign = createApiAction(
  'modules/campaigns/DELETE_CAMPAIGN',
  (type, id) => api.deleteCampaign(id, type)
);
export const getRetailers = createAction('modules/campaigns/GET_RETAILERS');
export const submitToRetailers = createApiAction(
  'modules/campaigns/SUBMIT_TO_RETAILERS',
  (type, id, retailers, doneCallback) =>
    api.submitToRetailers(id, retailers, type, doneCallback)
);
export const getCampaigns = createApiAction(
  'modules/campaigns/GET_CAMPAIGNS',
  (type, params, successCallback, failureCallback) => {
    return api.getCampaigns(params, successCallback, failureCallback, type);
  }
);
export const setSort = createAction('modules/campaigns/SET_SORT');
export const uploadImage = createApiAction(
  'modules/campaigns/UPLOAD_IMAGE',
  (type, file) => {
    const formData = new FormData();
    formData.append('creative[image]', file);

    return api.uploadImage(formData, type);
  }
);
export const setDateRange = createAction('modules/campaigns/SET_DATE_RANGE');
