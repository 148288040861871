import React from 'react';

const NotFound = props => {
  return (
    <React.Fragment>
      <h1>404: Oops!</h1>
      <p>Looks like the page you are looking for does not exist.</p>
    </React.Fragment>
  )
}

export default NotFound;