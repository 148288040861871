import React, { useRef, useState, useEffect, useCallback } from 'react';
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Flex,
  Image,
  Input,
  InputGroup,
  InputLeftElement,
  Tooltip,
} from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import { templateEditorSelectors } from 'modules/templateEditor/templateEditorDuck';
import CustomScroller from 'components/CustomScroller';
import { useActiveObject, useEditor } from 'components/Canvas';
import useTemplateEditorContext from '../../../hooks/useTemplateEditorContext';
import Icons from '../../Icons';
// import UploadFont from '../../../../../components/UploadUserFont/UploadFont';
import UploadFontModal from './components/UploadFontModal';

const fontFaceSet = document.fonts;

function FontFamily(props) {
  const [publicFonts, setPublicFonts] = useState([]);
  const [privateFonts, setPrivateFonts] = useState([]);
  const { setIsFontLoading, activeSubMenu, setActiveSubMenu } =
    useTemplateEditorContext();
  const userFonts = useSelector(state =>
    templateEditorSelectors.userFonts(state)
  );
  const editor = useEditor();
  const [query, setQuery] = useState('');
  const activeObject = useActiveObject();
  const [activeFontFamily, setActiveFontFamily] = useState(
    activeObject?.fontFamily
  );
  const [isUploadFontModalOpen, setIsUploadFontModalOpen] = useState(false);
  // const [isFontOptionsOpen, setIsFontOptionsOpen] = useState(false);
  const [file, setFile] = useState(false);
  const fileInput = useRef(null);

  const privateFontRef = useRef([]);
  const publicFontRef = useRef([]);

  const [activePrivateFontIndex, setActivePrivateFontIndex] = useState(null);
  const [activePublicFontIndex, setActivePublicFontIndex] = useState(null);

  const [privateFontRefLoaded, setPrivateFontRefLoaded] = useState(false);
  const [publicFontRefLoaded, setPublicFontRefLoaded] = useState(false);

  const [privateHandwritingFontRefLoaded, setPrivateHandwritingFontRefLoaded] =
    useState(false);
  const [publicHandwritingFontRefLoaded, setPublicHandwritingFontRefLoaded] =
    useState(false);

  const [privateAccordionIndex, setPrivateAccordionIndex] = useState();
  const [publicAccordionIndex, setPublicAccordionIndex] = useState();

  const isFontLoaded = fontFace => {
    let fontExist = false;

    fontFaceSet.forEach(fontInSet => {
      if (fontInSet.family === fontFace.family) {
        fontExist = true;
      }
    });

    return fontExist;
  };

  const addFontToRefs = (el, index, category) => {
    if (category === 'private') {
      if (el && !privateFontRef.current.includes(el)) {
        privateFontRef.current[index] = el;
      }
    } else {
      if (el && !publicFontRef.current.includes(el)) {
        publicFontRef.current[index] = el;
      }
    }
  };

  const handleFontFamilyChange = async fontItem => {
    if (editor) {
      sortFonts();
      const font = {
        name: fontItem.name,
        url: fontItem.url,
      };

      const fontFace = new FontFace(font.name, `url(${font.url})`);

      // let __fontFilename;
      // if (font.url.match(/s3.amazonaws.com/g)) {
      const __fontFilename = font.url
        .substring(font.url.lastIndexOf('/') + 1)
        .split('?')[0];
      // } else {
      //   __fontFilename = font.url.substring(font.url.lastIndexOf('/') + 1);
      // }

      await fontFaceSet.ready.then(() => {
        if (isFontLoaded(fontFace)) {
          editor.update({
            fontFamily: font.name,
            metadata: {
              __fontFilename,
            },
          });
          setActiveFontFamily(font.name);
        } else {
          setIsFontLoading(true);

          if (fontFaceSet) {
            fontFaceSet.add(fontFace);
            fontFace.load();
            fontFace.loaded
              .then(fontFace => {
                editor.update({
                  fontFamily: font.name,
                  metadata: {
                    __fontFilename,
                  },
                });
                setActiveFontFamily(font.name);
              })
              .catch(err => console.log(err))
              .finally(() => {
                setIsFontLoading(false);
              });
          }
        }
      });
    }
  };

  const isFiltered = element => {
    const ele = element.toLowerCase();
    const res = query.toLowerCase();
    return ele.includes(res) || ele === res;
  };

  const closeUploadFontModal = () => {
    setIsUploadFontModalOpen(false);
    setFile(null);
    if (fileInput?.current) {
      fileInput.current.value = null;
    }
  };

  // const showFontOptions = () => {
  //   setIsFontOptionsOpen(!isFontOptionsOpen);
  // };

  const sortFonts = () => {
    if (userFonts) {
      if (userFonts.public) {
        const publicFonts = userFonts.public;
        const updatedPublicFonts = Object.keys(publicFonts).map(key => {
          const needle = 400;
          const regularStyles = publicFonts[key].filter(
            pf => !pf.fullname.includes('Italic')
          );
          const regularStylesWeights = regularStyles.map(f => f.weight);
          const regularStylesSorted = regularStylesWeights.sort((a, b) => {
            return Math.abs(needle - a) - Math.abs(needle - b);
          });

          const italicStyles = publicFonts[key].filter(pf =>
            pf.fullname.includes('Italic')
          );
          const italicStylesWeights = italicStyles.map(f => f.weight);
          const italicStylesSorted = italicStylesWeights.sort((a, b) => {
            return Math.abs(needle - a) - Math.abs(needle - b);
          });
          const defaultFont = regularStylesSorted[0]
            ? regularStyles.find(f => f.weight === regularStylesSorted[0])
            : italicStyles.find(f => f.weight === italicStylesSorted[0]);
          return defaultFont;
        });
        setPublicFonts(updatedPublicFonts);
      }
      if (userFonts.private) {
        const privateFonts = userFonts.private;
        setPrivateFonts(privateFonts);
      }
    }
  };

  const handleUserKeyPress = event => {
    const key = event.key;
    let currentFont;
    let isPublic;
    let isPrivate;
    let updatedPublicFonts;

    //validate if the current font is on public
    if (userFonts.public) {
      updatedPublicFonts = Object.keys(userFonts.public)
        .map(key => {
          const needle = 400;
          const regularStyles = userFonts.public[key].filter(
            pf => !pf.fullname.includes('Italic')
          );
          const regularStylesWeights = regularStyles.map(f => f.weight);
          const regularStylesSorted = regularStylesWeights.sort((a, b) => {
            return Math.abs(needle - a) - Math.abs(needle - b);
          });

          const italicStyles = userFonts.public[key].filter(pf =>
            pf.fullname.includes('Italic')
          );
          const italicStylesWeights = italicStyles.map(f => f.weight);
          const italicStylesSorted = italicStylesWeights.sort((a, b) => {
            return Math.abs(needle - a) - Math.abs(needle - b);
          });
          const defaultFont = regularStylesSorted[0]
            ? regularStyles.find(f => f.weight === regularStylesSorted[0])
            : italicStyles.find(f => f.weight === italicStylesSorted[0]);
          return defaultFont;
        })
        .sort((a, b) => a.name.localeCompare(b.name));

      isPublic = updatedPublicFonts.find(font => {
        return font.fullname === activeObject.fontFamily;
      });
    }

    //validate if the current font is on private
    if (userFonts.private) {
      isPrivate = userFonts.private.find(font => {
        return font.fullname === activeObject.fontFamily;
      });
    }

    // if the current font is on private or is on both
    if ((isPrivate && !isPublic) || (isPrivate && isPublic)) {
      userFonts.private
        .sort((a, b) => a.name.localeCompare(b.name))
        .map((font, index) => {
          if (font.fullname === activeObject.fontFamily) {
            currentFont = {
              index: index,
              font: font,
            };
          }
          return font;
        });

      if (userFonts.private[currentFont.index + 1] && key === 'ArrowDown') {
        handleFontFamilyChange(userFonts.private[currentFont.index + 1]);
        setActiveFontFamily(userFonts.private[currentFont.index + 1].fullname);
      }
      if (userFonts.private[currentFont.index - 1] && key === 'ArrowUp') {
        handleFontFamilyChange(userFonts.private[currentFont.index - 1]);
        setActiveFontFamily(userFonts.private[currentFont.index - 1].fullname);
      }
    }
    // if the current font is on public
    if (isPrivate === undefined && isPublic) {
      updatedPublicFonts.map((font, index) => {
        if (font.fullname === activeObject.fontFamily) {
          currentFont = {
            index: index,
            font: font,
          };
        }
        return font;
      });

      if (updatedPublicFonts[currentFont.index + 1] && key === 'ArrowDown') {
        handleFontFamilyChange(updatedPublicFonts[currentFont.index + 1]);
        setActiveFontFamily(updatedPublicFonts[currentFont.index + 1].fullname);
      }
      if (updatedPublicFonts[currentFont.index - 1] && key === 'ArrowUp') {
        handleFontFamilyChange(updatedPublicFonts[currentFont.index - 1]);
        setActiveFontFamily(updatedPublicFonts[currentFont.index - 1].fullname);
      }
    }
  };

  useEffect(() => {
    window.addEventListener('keydown', handleUserKeyPress);

    return () => {
      window.removeEventListener('keydown', handleUserKeyPress);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    sortFonts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      privateFontRefLoaded === true &&
      publicFontRefLoaded === true &&
      privateHandwritingFontRefLoaded === true &&
      publicHandwritingFontRefLoaded === true
    ) {
      if (
        ((activePrivateFontIndex !== null && activePublicFontIndex !== null) ||
          (activePrivateFontIndex !== null &&
            activePublicFontIndex === null)) &&
        privateFontRef.current.length > 0
      ) {
        let privateEl = privateFontRef.current[activePrivateFontIndex];
        if (privateAccordionIndex === 0) {
          setTimeout(() => {
            privateEl = privateFontRef.current[activePrivateFontIndex];
            privateEl.scrollIntoView({
              behavior: 'smooth',
              block: 'start',
            });
          }, '100');
        } else {
          privateEl.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
          });
        }
      }

      if (
        activePrivateFontIndex === null &&
        activePublicFontIndex !== null &&
        publicFontRef.current.length > 0
      ) {
        let publicEl = publicFontRef.current[activePublicFontIndex];
        if (publicAccordionIndex === 0) {
          setTimeout(() => {
            publicEl = publicFontRef.current[activePublicFontIndex];
            publicEl.scrollIntoView({
              behavior: 'smooth',
              block: 'start',
            });
          }, '100');
        } else {
          publicEl.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
          });
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    publicFontRefLoaded,
    privateFontRefLoaded,
    privateHandwritingFontRefLoaded,
    publicHandwritingFontRefLoaded,
  ]);

  const privateFontList = () => {
    const getLastChar = s => +s.match(/\d+(?=\D*$)/);
    const handWritingGroup = privateFonts
      .filter(i => isFiltered(i.name))
      .filter(i => i.font_type === 'handwriting');

    if (handWritingGroup.length === 0) {
      if (privateHandwritingFontRefLoaded === false) {
        setPrivateHandwritingFontRefLoaded(true);
      }
    }
    return (
      <Box>
        {handWritingGroup.length !== 0 && (
          <Box>
            <Accordion
              allowMultiple
              allowToggle
              index={privateAccordionIndex}
              onChange={setPrivateAccordionIndex}
            >
              <AccordionItem style={{ borderTop: 'none' }}>
                <h2>
                  <AccordionButton style={{ fontSize: '15px' }}>
                    <Box
                      flex="1"
                      textAlign="left"
                      style={{
                        fontSize: '12px',
                        paddingLeft: '7px',
                        color: 'blue',
                      }}
                    >
                      Handwriting
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                </h2>

                {privateFonts
                  .filter(i => isFiltered(i.name))
                  .sort((a, b) => a.name.localeCompare(b.name))
                  .sort((a, b) => getLastChar(a.name) - getLastChar(b.name))
                  .map((privateFont, index) => {
                    if (privateFont.fullname === activeFontFamily) {
                      if (
                        activePrivateFontIndex === null &&
                        privateFont.font_type === 'handwriting'
                      ) {
                        setActivePrivateFontIndex(index);
                        setPrivateAccordionIndex(0);
                      }
                    }
                    if (index === privateFonts.length - 1) {
                      if (privateHandwritingFontRefLoaded === false) {
                        setPrivateHandwritingFontRefLoaded(true);
                      }
                    }
                    return (
                      privateFont.font_type === 'handwriting' && (
                        <AccordionPanel
                          key={privateFont.id}
                          ref={el => addFontToRefs(el, index, 'private')}
                          sx={{
                            background: 'rgba(0,0,0,0.035)',
                          }}
                          _hover={{
                            background: 'rgba(0,0,0,0.05)',
                            cursor: 'pointer',
                          }}
                          style={{
                            position: 'relative',
                            height: '2.5rem',
                            display: 'flex',
                            alignItems: 'center',
                            width: '100%',
                            paddingBottom: '0',
                            paddingTop: '0',
                            background:
                              privateFont.fullname === activeFontFamily
                                ? 'rgba(0,0,0,0.05)'
                                : '',
                          }}
                        >
                          <Tooltip
                            label={privateFont.fullname}
                            placement="right"
                          >
                            <Box
                              name={privateFont.fullname}
                              onClick={() =>
                                handleFontFamilyChange(privateFont)
                              }
                              key={privateFont.id}
                              style={{
                                paddingLeft: '1.5rem',
                              }}
                            >
                              <Image src={privateFont.thumb} />
                            </Box>
                          </Tooltip>
                        </AccordionPanel>
                      )
                    );
                  })}
              </AccordionItem>
            </Accordion>
          </Box>
        )}

        {privateFonts
          .filter(i => isFiltered(i.name))
          .sort((a, b) => a.name.localeCompare(b.name))
          .sort((a, b) => getLastChar(a.name) - getLastChar(b.name))
          .map((privateFont, index) => {
            if (privateFont.fullname === activeFontFamily) {
              if (
                activePrivateFontIndex === null &&
                privateFont.font_type !== 'handwriting'
              ) {
                setActivePrivateFontIndex(index);
              }
            }
            if (index === privateFonts.length - 1) {
              if (privateFontRefLoaded === false) {
                setPrivateFontRefLoaded(true);
              }
            }
            return (
              privateFont.font_type !== 'handwriting' && (
                <Box
                  key={privateFont.id}
                  ref={el => addFontToRefs(el, index, 'private')}
                  style={{
                    position: 'relative',
                    height: '2.5rem',
                    display: 'flex',
                    alignItems: 'center',
                    width: '100%',
                    background:
                      privateFont.fullname === activeFontFamily
                        ? 'rgba(0,0,0,0.05)'
                        : '',
                  }}
                >
                  <Tooltip label={privateFont.fullname} placement="right">
                    <Box
                      name={privateFont.fullname}
                      onClick={() => handleFontFamilyChange(privateFont)}
                      key={privateFont.id}
                      style={{
                        paddingLeft: '1.5rem',
                      }}
                    >
                      <Image src={privateFont.thumb} />
                    </Box>
                  </Tooltip>
                </Box>
              )
            );
          })}
      </Box>
    );
  };

  const publicFontList = () => {
    const getLastChar = s => +s.match(/\d+(?=\D*$)/);
    const handWritingGroup = publicFonts
      .filter(i => isFiltered(i.name))
      .filter(i => i.font_type === 'handwriting');

    if (handWritingGroup.length === 0) {
      if (publicHandwritingFontRefLoaded === false) {
        setPublicHandwritingFontRefLoaded(true);
      }
    }
    return (
      <Box>
        {handWritingGroup.length !== 0 && (
          <Box>
            <Accordion
              allowMultiple
              allowToggle
              index={publicAccordionIndex}
              onChange={setPublicAccordionIndex}
            >
              <AccordionItem style={{ borderTop: 'none' }}>
                <h2>
                  <AccordionButton style={{ fontSize: '15px' }}>
                    <Box
                      flex="1"
                      textAlign="left"
                      style={{
                        fontSize: '12px',
                        paddingLeft: '7px',
                        color: 'blue',
                      }}
                    >
                      Handwriting
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                </h2>

                {publicFonts
                  .filter(i => isFiltered(i.name))
                  .sort((a, b) => a.name.localeCompare(b.name))
                  .sort((a, b) => getLastChar(a.name) - getLastChar(b.name))
                  .map((publicFont, index) => {
                    if (publicFont.fullname === activeFontFamily) {
                      if (
                        activePublicFontIndex === null &&
                        publicFont.font_type === 'handwriting'
                      ) {
                        setActivePublicFontIndex(index);
                        setPublicAccordionIndex(0);
                      }
                    }
                    if (index === publicFonts.length - 1) {
                      if (publicHandwritingFontRefLoaded === false) {
                        setPublicHandwritingFontRefLoaded(true);
                      }
                    }
                    return (
                      publicFont.font_type === 'handwriting' && (
                        <AccordionPanel
                          key={publicFont.id}
                          ref={el => addFontToRefs(el, index, 'public')}
                          sx={{
                            background: 'rgba(0,0,0,0.035)',
                          }}
                          _hover={{
                            background: 'rgba(0,0,0,0.05)',
                            cursor: 'pointer',
                          }}
                          style={{
                            position: 'relative',
                            height: '2.5rem',
                            display: 'flex',
                            alignItems: 'center',
                            width: '100%',
                            paddingBottom: '0',
                            paddingTop: '0',
                            background:
                              publicFont.fullname === activeFontFamily
                                ? 'rgba(0,0,0,0.05)'
                                : '',
                          }}
                        >
                          <Tooltip
                            label={publicFont.fullname}
                            placement="right"
                          >
                            <Box
                              name={publicFont.fullname}
                              onClick={() => handleFontFamilyChange(publicFont)}
                              key={publicFont.id}
                              style={{
                                paddingLeft: '1.5rem',
                              }}
                            >
                              <Image src={publicFont.thumb} />
                            </Box>
                          </Tooltip>
                        </AccordionPanel>
                      )
                    );
                  })}
              </AccordionItem>
            </Accordion>
          </Box>
        )}
        {publicFonts
          .filter(i => isFiltered(i.name))
          .sort((a, b) => a.name.localeCompare(b.name))
          .sort((a, b) => getLastChar(a.name) - getLastChar(b.name))
          .map((publicFont, index) => {
            if (publicFont.fullname === activeFontFamily) {
              if (
                activePublicFontIndex === null &&
                publicFont.font_type !== 'handwriting'
              ) {
                setActivePublicFontIndex(index);
              }
            }
            if (index === publicFonts.length - 1) {
              if (publicFontRefLoaded === false) {
                setPublicFontRefLoaded(true);
              }
            }
            return (
              publicFont.font_type !== 'handwriting' && (
                <Box
                  key={publicFont.id}
                  ref={el => addFontToRefs(el, index, 'public')}
                  style={{
                    position: 'relative',
                    height: '2.5rem',
                    display: 'flex',
                    alignItems: 'center',
                    width: '100%',
                    background:
                      publicFont.fullname === activeFontFamily
                        ? 'rgba(0,0,0,0.05)'
                        : '',
                  }}
                  _hover={{
                    background: 'rgba(0,0,0,0.05)',
                    cursor: 'pointer',
                  }}
                >
                  <Tooltip label={publicFont.fullname} placement="right">
                    <Box
                      name={publicFont.fullname}
                      onClick={() => handleFontFamilyChange(publicFont)}
                      key={publicFont.id}
                      style={{
                        paddingLeft: '1.5rem',
                      }}
                    >
                      <Image src={publicFont.thumb} />
                    </Box>
                  </Tooltip>
                </Box>
              )
            );
          })}
      </Box>
    );
  };
  return (
    <Box
      sx={{
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        background: '#ffffff',
        borderRight: '1px solid rgba(57,76,96,.15)',
      }}
    >
      <Box sx={{ padding: '1.5rem 1.5rem 1rem' }}>
        <InputGroup>
          <InputLeftElement
            pointerEvents="none"
            children={<Icons.Search size={24} color="gray.300" />}
          />
          <Input
            value={query}
            onChange={e => setQuery(e.target.value)}
            borderRadius="4px"
            placeholder="Search font"
            _placeholder={{
              color: '#a3a3a3',
            }}
            sx={{
              backgroundColor: '#ffffff',
              fontSize: '14px',
            }}
            _focus={{
              borderColor: 'rgb(255, 111, 15)',
              boxShadow: '0 0 0 1px rgb(255, 111, 15)',
            }}
          />
        </InputGroup>
      </Box>
      <Box
        sx={{
          flex: 1,
          position: 'relative',
        }}
      >
        <Box
          sx={{
            position: 'absolute',
            height: '100%',
            width: '100%',
          }}
        >
          <CustomScroller>
            <Box>
              <Flex
                align="center"
                justify="space-between"
                p="1rem 1.5rem"
                borderTop="1px solid lightgray"
                borderBottom={
                  privateFonts.length > 0
                    ? '1px solid lightgray'
                    : '0px solid lightgray'
                }
                fontSize="12px"
              >
                <Box sx={{ fontWeight: 600 }}>Private fonts</Box>

                <Button
                  leftIcon={<Icons.UploadCloud height={15} />}
                  bgColor="#f0f0f0"
                  borderRadius="5px"
                  fontSize="10px"
                  p="8px 10px"
                  height="auto"
                  onClick={() => fileInput?.current?.click()}
                >
                  Upload a font
                </Button>
                <input
                  hidden
                  type="file"
                  ref={fileInput}
                  accept=".ttf, .otf"
                  multiple={Boolean(false)}
                  onChange={ev => {
                    setFile(ev.target.files[0]);
                    setIsUploadFontModalOpen(true);
                  }}
                />
              </Flex>
              {privateFontList()}
            </Box>

            <Box
              sx={{
                padding: '0rem 0rem 2rem',
              }}
            >
              <Box
                sx={{
                  fontWeight: 600,
                  padding: '1.2rem 1.5rem',
                  fontSize: '12px',
                  borderTop: '1px solid lightgray',
                  borderBottom: '1px solid lightgray',
                }}
              >
                Public fonts
              </Box>
              {publicFontList()}
            </Box>
          </CustomScroller>
        </Box>
      </Box>
      {/* {isUploadFontModalOpen && (
        <UploadFont
          activeSubMenu={activeSubMenu}
          setActiveSubMenu={setActiveSubMenu}
          handleFontFamilyChange={handleFontFamilyChange}
          closeUploadFontModal={closeUploadFontModal}
        />
      )} */}

      <UploadFontModal
        isOpen={isUploadFontModalOpen}
        onClose={closeUploadFontModal}
        handleFontFamilyChange={handleFontFamilyChange}
        file={file}
        setActiveSubMenu={setActiveSubMenu}
        activeSubMenu={activeSubMenu}
      />
    </Box>
  );
}

export default FontFamily;
