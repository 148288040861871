import { fabric } from 'fabric';
import BaseHandler from './BaseHandler';
import throttle from 'lodash/throttle';

class GridHandler extends BaseHandler {
  enabled = false;
  grid;
  options;
  constructor(props) {
    super(props);
    this.options = this.context.grid;
  }

  toggle(options) {
    this.options = Object.assign(this.options, options);
    if (this.options.enabled) {
      this.add();
    } else {
      this.remove();
    }
    this.context.setGrid(options);
  }

  update = throttle(options => {
    this.options = Object.assign(this.options, options);
    if (this.options.enabled) {
      this.add();
    } else {
      this.remove();
    }
    this.context.setGrid(options);
  }, 500);

  add() {
    if (this.grid) {
      this.remove();
    }
    this.draw();
  }

  remove() {
    if (this.grid) {
      this.canvas.remove(this.grid);
      this.grid = null;
    }
  }

  draw() {
    if (!this.options.enabled) {
      return;
    }

    if (this.grid) {
      this.remove();
    }

    const frameCnt = this.handlers.frameHandler.getFrames().length;
    const frameMargin = this.handlers.frameHandler.getFrameMargin();
    const { width, height } = this.handlers.frameHandler.options;
    const midPointX = width / 2;
    const midPointY = height / 2;

    const gridGroupOpts = {
      opacity: 0.5,
      selectable: false,
      hoverCursor: 'default',
      originX: 'center',
      originY: 'center',
      excludeFromExport: true,
      name: 'bgGrid',
      evented: false,
    };

    const gridGroup = new fabric.Group(null, gridGroupOpts);
    const offsetY =
      frameCnt === 1
        ? 0
        : height > width
        ? -4 * frameMargin + 12
        : -1 * frameMargin + 12;
    for (let i = 0; i < frameCnt; i++) {
      const baseY = i === 0 ? offsetY : offsetY + height + frameMargin;
      const posXCoords = x => [
        midPointX + this.options.size * x,
        baseY + 0,
        midPointX + this.options.size * x,
        baseY + height,
      ];
      const negXCoords = x => [
        midPointX - this.options.size * x,
        baseY + 0,
        midPointX - this.options.size * x,
        baseY + height,
      ];
      const posYCoords = y => [
        0,
        baseY + midPointY - this.options.size * y,
        width,
        baseY + midPointY - this.options.size * y,
      ];
      const negYCoords = y => [
        0,
        baseY + midPointY + this.options.size * y,
        width,
        baseY + midPointY + this.options.size * y,
      ];

      const centerX = [midPointX, baseY + 0, midPointX, baseY + height];
      const centerY = [0, baseY + midPointY, width, baseY + midPointY];
      const lineOptions = { stroke: this.options.color, strokeWidth: 1 };

      const setStrokeWidth = x => {
        if (!x) return 2.8;
        if (x % 4 === 0) return 2;
        if (x % 2 === 0) return 1;
        return 0.5;
      };

      // Establish center lines
      gridGroup.addWithUpdate(
        new fabric.Line(centerX, {
          ...lineOptions,
          strokeWidth: setStrokeWidth(),
        })
      );

      gridGroup.addWithUpdate(
        new fabric.Line(centerY, {
          ...lineOptions,
          strokeWidth: setStrokeWidth(),
        })
      );

      const maxXLines = (width - midPointX) / this.options.size + 2;
      const maxYLines = (height - midPointY) / this.options.size + 2;

      // Draw all x lines off of centerX
      for (let x = 1; x < maxXLines; x++) {
        gridGroup.addWithUpdate(
          new fabric.Line(posXCoords(x), {
            ...lineOptions,
            strokeWidth: setStrokeWidth(x),
          })
        );

        gridGroup.addWithUpdate(
          new fabric.Line(negXCoords(x), {
            ...lineOptions,
            strokeWidth: setStrokeWidth(x),
          })
        );
      }

      // Draw all y lines off of centerY
      for (let y = 1; y < maxYLines; y++) {
        gridGroup.addWithUpdate(
          new fabric.Line(posYCoords(y), {
            ...lineOptions,
            strokeWidth: setStrokeWidth(y),
          })
        );

        gridGroup.addWithUpdate(
          new fabric.Line(negYCoords(y), {
            ...lineOptions,
            strokeWidth: setStrokeWidth(y),
          })
        );
      }
    }
    this.canvas.add(gridGroup);
    if (frameCnt === 1) gridGroup.center();
    else gridGroup.centerH();
    this.grid = gridGroup;
    const background = this.handlers.frameHandler.getBackgroundImage();
    if (background) {
    } else {
      gridGroup.moveTo(2);
    }
  }
}

export default GridHandler;
