import React from 'react';

function Success({ height = 48, style = {} }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height={height}
      fill="none"
      viewBox="0 0 49 48"
      style={{ ...style }}
    >
      <path
        fill="#0BC196"
        d="M44.5 24c0 11.045-8.955 20-20 20s-20-8.955-20-20 8.955-20 20-20 20 8.955 20 20z"
        opacity="0.2"
      ></path>
      <path
        fill="#089171"
        d="M35.086 14.586l-13.57 13.586-5.602-5.586-2.828 2.828 8.434 8.414 16.395-16.414-2.829-2.828z"
      ></path>
    </svg>
  );
}

export default Success;
