import { Box } from '@chakra-ui/react';
import React from 'react';
import { useEditor } from '../../../../components/Canvas';
// import Delete from '../Icons/Delete';
// import ArrowUp from '../Icons/ArrowUp';
// import ArrowDown from '../Icons/ArrowDown';

function FrontFrameTitle() {
  const editor = useEditor();

  // const removeFrame = () => {
  //   editor.handlers.frameHandler.remove('frontSide');
  //   editor.handlers.scrollbarHandler.updateScrollPosition();
  // };

  // const scrollToBottom = () => {
  //   editor.handlers.scrollbarHandler.setScrollToBottom();
  // };

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        position: 'absolute',
        fontWeight: 'bold',
        alignItems: 'center',
      }}
      id="front-title"
      onWheel={event => {
        editor.handlers.scrollbarHandler.onMouseWheel(event);
      }}
    >
      <Box
        fontSize="0.9rem"
        whiteSpace="nowrap"
        overflow="hidden"
        textOverflow="ellipsis"
      >
        Page 1 - Front Side
      </Box>
    </Box>
  );
}

export default FrontFrameTitle;
