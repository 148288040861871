export const LOG_IN = 'modules/app/LOG_IN';
export const LOG_OUT = 'modules/app/LOG_OUT';
export const UPLOAD_TEMPLATE = 'modules/app/UPLOAD_TEMPLATE';
export const RESUME_IMPORT = 'modules/app/RESUME_IMPORT';
export const GET_UPLOAD_TEMPLATE_STATUS =
  'modules/app/GET_UPLOAD_TEMPLATE_STATUS';
export const SEND_UPLOAD_INFORMATION = 'modules/app/SEND_UPLOAD_INFORMATION';
export const CHECK_TOKEN = 'modules/app/CHECK_TOKEN';
export const SET_APP_MODE = 'modules/app/SET_APP_MODE';
export const SEND_PW_RESET_EMAIL = 'modules/app/SEND_PW_RESET_EMAIL';
export const VERIFY_RESET_TOKEN = 'modules/app/VERIFY_RESET_TOKEN';
export const CHANGE_PASSWORD = 'modules/app/CHANGE_PASSWORD';
export const CLEAR_INVALID_PW_RESET_TOKEN =
  'modules/app/CLEAR_INVALID_PW_RESET_TOKEN';
export const SET_MESSAGE = 'modules/app/SET_MESSAGE';
export const CLEAR_MESSAGE = 'modules/app/CLEAR_MESSAGE';

export const STATE_KEY_TOKEN = 'token';
export const STATE_KEY_APP_MODE = 'appMode';
export const STATE_KEY_INVALID_PW_RESET_TOKEN = 'invalidPwResetToken';
export const STATE_KEY_MESSAGES = 'messages';
