import React from 'react';

function Elements({ size }) {
  return (
    <svg height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8 8.40187V7.60187C7.99975 6.71499 8.29426 5.85318 8.83721 5.15193C9.38017 4.45068 10.1408 3.94975 10.9995 3.72792C11.8581 3.50608 12.7662 3.5759 13.5809 3.92642C14.3956 4.27693 15.0706 4.88825 15.5 5.66427L16.8992 4.88747C16.2978 3.80132 15.3526 2.94581 14.212 2.4554C13.0715 1.965 11.8003 1.86751 10.5983 2.17827C9.3963 2.48902 8.33165 3.1904 7.5717 4.17215C6.81175 5.1539 6.39959 6.36035 6.4 7.60187V8.40187H4V18.8019C4 19.4384 4.25286 20.0488 4.70294 20.4989C5.15303 20.949 5.76348 21.2019 6.4 21.2019H17.6C18.2365 21.2019 18.847 20.949 19.2971 20.4989C19.7471 20.0488 20 19.4384 20 18.8019V8.40187H8ZM18.4 18.8019C18.4 19.014 18.3157 19.2175 18.1657 19.3676C18.0157 19.5176 17.8122 19.6019 17.6 19.6019H6.4C6.18783 19.6019 5.98434 19.5176 5.83431 19.3676C5.68429 19.2175 5.6 19.014 5.6 18.8019V10.0019H18.4V18.8019Z"
        fill="currentColor"
      />
      <path d="M12.8002 13.2017H11.2002V16.4017H12.8002V13.2017Z" fill="currentColor" />
    </svg>
  );
}

export default Elements;
