import { schema } from 'normalizr';
import { fromJS } from 'immutable';
import { success, failure } from '../../helpers/reducerHelpers';
import isEmpty from 'lodash/isEmpty';
// import { actionCreator } from '../../helpers/actionHelpers';
import * as api from './audienceRulesApi';
import { createSelector } from 'reselect';

// Constants
const GET_AUDIENCE_RULE = 'modules/audienceRules/GET_AUDIENCE_RULE';
const GET_AUDIENCE_RULES = 'modules/audienceRules/GET_AUDIENCE_RULES';
const UPDATE_AUDIENCE_RULE = 'modules/audienceRules/UPDATE_AUDIENCE_RULE';
const CREATE_AUDIENCE_RULE = 'modules/audienceRules/CREATE_AUDIENCE_RULE';
const CREATE_AUDIENCE_RULES = 'modules/audienceRules/CREATE_AUDIENCE_RULES';
const DELETE_AUDIENCE_RULE = 'modules/audienceRules/DELETE_AUDIENCE_RULE';

const SK_AUDIENCE_RULES_CREATED_SUCCESS = 'audienceRulesCreatedSuccess';

const STATE_KEY = 'audienceRules';
const STATE_KEY_AUDIENCE_RULES_BY_AUDIENCE_ID = 'audienceRulesByAudienceId';
const STATE_KEY_AUDIENCE_RULE = 'audienceRule';
const STATE_KEY_AUDIENCE_RULE_ERROR = 'error';
const STATE_KEY_CREATED_AUDIENCE_RULE = 'createdAudienceRule';

const SCHEMA_AUDIENCE_RULES = 'audienceRules';

export const audienceRuleConstants = {
  GET_AUDIENCE_RULE,
  GET_AUDIENCE_RULES,
  UPDATE_AUDIENCE_RULE,
  CREATE_AUDIENCE_RULE,
  CREATE_AUDIENCE_RULES,

  SK_AUDIENCE_RULES_CREATED_SUCCESS,

  STATE_KEY,
  STATE_KEY_AUDIENCE_RULES_BY_AUDIENCE_ID,
  STATE_KEY_AUDIENCE_RULE,
  STATE_KEY_AUDIENCE_RULE_ERROR,
  STATE_KEY_CREATED_AUDIENCE_RULE,

  SCHEMA_AUDIENCE_RULES,
};

// Schema
export const audienceRuleSchema = {
  schema: new schema.Entity(SCHEMA_AUDIENCE_RULES),
};

// Reducer
export const reducer = (ogState = {}, action) => {
  const state = fromJS(ogState);

  switch (action.type) {
    case success(GET_AUDIENCE_RULE):
      return state.set(STATE_KEY_AUDIENCE_RULE, action.payload).toJS();
    case success(GET_AUDIENCE_RULES):
      return state
        .set(STATE_KEY_AUDIENCE_RULES_BY_AUDIENCE_ID, action.payload)
        .toJS();
    case success(CREATE_AUDIENCE_RULE):
      return state.set(STATE_KEY_CREATED_AUDIENCE_RULE, action.payload).toJS();
    case success(CREATE_AUDIENCE_RULES):
      let key = SK_AUDIENCE_RULES_CREATED_SUCCESS;
      return state.set(key, Date.now()).toJS();

    case failure(CREATE_AUDIENCE_RULE):
    case failure(GET_AUDIENCE_RULES):
      let err = action.payload;
      if (isEmpty(err)) err = { error: 'API error occured' };
      return state.set(STATE_KEY_AUDIENCE_RULE_ERROR, err).toJS();

    default:
      return ogState;
  }
};

// Actions
export const audienceRuleActions = {
  getAudienceRule: id => api.getOne(id, GET_AUDIENCE_RULE),
  getAudienceRulesByAudienceId: id =>
    api.getAudienceRulesByAudienceId(id, GET_AUDIENCE_RULES),
  createAudienceRule: rule =>
    api.createAudienceRule(rule, CREATE_AUDIENCE_RULE),
  createAudienceRules: rules =>
    api.createAudienceRule(rules, CREATE_AUDIENCE_RULES),
  updateAudienceRule: rule =>
    api.updateAudienceRule(rule, UPDATE_AUDIENCE_RULE),
  deleteAudienceRule: id =>
    api.deleteAudienceRule(id, DELETE_AUDIENCE_RULE, id),
};

// Selectors
const getUnorderedAudienceRulesByAudienceId = state =>
  state.modules.audienceRules[STATE_KEY_AUDIENCE_RULES_BY_AUDIENCE_ID];

export const audienceRuleSelectors = {
  audienceRulesByAudienceId: createSelector(
    [getUnorderedAudienceRulesByAudienceId],
    data => {
      if (data == null) return [];
      return data.result.map(id => data.entities[SCHEMA_AUDIENCE_RULES][id]);
    }
  ),

  audienceRule: state => state.modules.audienceRules[STATE_KEY_AUDIENCE_RULE],
  createdRule: state =>
    state.modules.audienceRules[STATE_KEY_CREATED_AUDIENCE_RULE],
  getError: state => state.modules.audienceRules[STATE_KEY_AUDIENCE_RULE_ERROR],
  getAudienceRulesCreatedSuccess: state =>
    state.modules.audienceRules[SK_AUDIENCE_RULES_CREATED_SUCCESS],
};

export default reducer;
