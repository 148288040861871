import React from 'react';
import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Box,
} from '@chakra-ui/react';

function DeleteModal({
  isOpen,
  onClose,
  toBeDeleted,
  deleteTemplateHandler,
  deleteInProgress,
}) {
  return (
    <Modal isCentered isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent
        sx={{
          width: '360px',
          paddingBottom: '1rem',
        }}
      >
        <ModalHeader fontSize="18px" fontWeight={600}>
          Template delete
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody
          sx={{
            display: 'flex',
            gap: '1rem',
            flexDirection: 'column',
            textAlign: 'center',
            fontSize: '14px',
          }}
        >
          <Box>You are about to delete the following template:</Box>
          <Box sx={{ fontWeight: 600 }}>{toBeDeleted && toBeDeleted.name}</Box>
          <Box>Would you like to proceed?</Box>
          <Button
            onClick={() => deleteTemplateHandler(toBeDeleted.id)}
            colorScheme="orange"
            backgroundColor="rgb(255, 111, 15)"
            fontSize="14px"
          >
            {deleteInProgress ? 'Deleting...' : 'Delete template'}
          </Button>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}

export default DeleteModal;
