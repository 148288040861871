import React from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useFlag } from 'flags';
import { Formik, Form, Field } from 'formik';
import { showMessages } from 'helpers/messageHelpers';
import FieldProto from 'components/FieldProto/FieldProto';
import Button from 'components/Button/Button';
import LoadingIndicator from 'components/LoadingIndicator/LoadingIndicator';
import colors from 'theme/Colors.module.scss';
import { setAudienceValidationSchema } from 'helpers/audiencesHelpers';
import RulesSection from './RulesSection/RulesSection';
import { useStateHandler } from './hooks/useStateHandler';
import * as appActions from 'modules/app/appActions';
import * as appSelectors from 'modules/app/appSelectors';
import * as companyParamsSelectors from 'modules/params/companyParamsSelectors';
import * as audienceHelpers from 'helpers/audiencesHelpers';
import * as audiencesSelectors from 'modules/audiences/audiencesSelectors';
import * as audiencesActions from 'modules/audiences/audiencesActions';
import './NewAudience.scss';

const includes = 'incParams';
const excludes = 'excParams';

const schema = setAudienceValidationSchema();

const NewAudience = props => {
  const {
    match: { params, path },
    audienceId,
    onModalClose,
    onUpdateAudience,
    history,
  } = props;

  const isReadOnly = useFlag(['features', 'isReadOnly']);
  const { search } = useLocation();
  const dispatch = useDispatch();

  // Selectors
  const messages = useSelector(state => appSelectors.messages(state));
  const sessionUser = useSelector(state => appSelectors.getSessionUser(state));
  const companyParams = useSelector(companyParamsSelectors.selectCompanyParams);
  const audience = useSelector(state =>
    audiencesSelectors.getExistingAudience(state)
  );
  const isAudienceLoading = useSelector(state =>
    audiencesSelectors.isAudienceLoading(state)
  );
  const createdAudience = useSelector(state =>
    audiencesSelectors.getCreatedAudience(state)
  );
  const updatedAudience = useSelector(state =>
    audiencesSelectors.getUpdatedAudience(state)
  );

  // Actions
  const setMessage = payload => dispatch(appActions.setMessage(payload));
  const clearMessage = index => dispatch(appActions.clearMessage(index));
  const createAudience = (audience, doneCallback) =>
    dispatch(audiencesActions.createAudience(audience, doneCallback));
  const updateAudience = (audience, doneCallback) =>
    dispatch(audiencesActions.updateAudience(audience, doneCallback));

  const { initialForm, setInitialForm, isEditing, redirect } = useStateHandler({
    audience,
    id: audienceId !== undefined ? audienceId : params.id,
    createdAudience: createdAudience,
    updatedAudience: updatedAudience,
  });

  const buttonIcon = isEditing ? null : 'add';
  const buttonText = isEditing ? 'Save Audience' : 'Add Audience';
  const title = isEditing ? 'Update your Audience' : 'Create your New Audience';
  const pathName = path.split('/')[1];

  const redirectBack = () =>
    history.location.state
      ? history.goBack()
      : history.push(`/audiences${search || ''}`);

  const buildAudience = values => {
    const { incParams, excParams, ...audience } = values;
    const { payload } = audienceHelpers.buildAudience({
      audience,
      excParams,
      incParams,
      company_id: sessionUser.company_id,
    });

    return payload;
  };

  const handleSubmit = (values, setSubmitting) => {
    const audienceVals = buildAudience(values);

    if (isEditing) {
      updateAudience(audienceVals, data => {
        setMessage({
          message: 'Audience updated successfully',
          type: 'success',
        });
        if (pathName === 'audiences') redirect && redirectBack();
        if (pathName === 'campaigns') {
          onUpdateAudience(data);
          onModalClose();
        }
        setSubmitting(false);
      });
    } else {
      createAudience(audienceVals, data => {
        setMessage({
          message: 'Audience created successfully',
          type: 'success',
        });
        if (pathName === 'audiences') redirect && redirectBack();
        if (pathName === 'campaigns') {
          onUpdateAudience(data);
          onModalClose();
        }
        setSubmitting(false);
      });
    }
  };

  return (
    <div
      className={`ppkgNew ${
        pathName === 'audiences' ? 'audienceSection' : 'campaignSection'
      }`}
    >
      {pathName === 'audiences' && (
        <div className="rail">
          <Button
            type="button"
            text="Back"
            className="backBtn"
            textColor={colors.slate}
            icon="back"
            btnStyle="transparent"
            url={
              history.location.state
                ? history.location.state.from
                : '/audiences'
            }
          />
        </div>
      )}
      <div className="formContainer">
        <Formik
          enableReinitialize
          initialValues={initialForm}
          validationSchema={schema}
          onSubmit={(values, { setSubmitting }) =>
            handleSubmit(values, setSubmitting)
          }
        >
          {form => {
            const { isSubmitting } = form;

            return (
              <Form className="newAudienceForm" name="newAudienceForm">
                <div className="title">
                  <h1>{title}</h1>
                </div>

                <LoadingIndicator loading={isEditing && isAudienceLoading}>
                  <div className="headerFields">
                    <Field name="name">
                      {({ field, form }) => (
                        <FieldProto
                          label="Audience Name"
                          placeholder="Name this Audience"
                          style={{ marginTop: '0.5rem' }}
                          disabled={isReadOnly}
                          {...field}
                          error={
                            form.touched[field.name] && form.errors[field.name]
                          }
                        />
                      )}
                    </Field>
                  </div>

                  <p className="paramsSectionTitle">Targeting</p>

                  <div
                    className={`mainContent ${
                      pathName === 'audiences'
                        ? 'audienceContent'
                        : 'campaignContent'
                    }`}
                  >
                    <RulesSection
                      group={includes}
                      initialForm={initialForm}
                      setInitialForm={setInitialForm}
                      companyParams={companyParams || []}
                    />
                    <RulesSection
                      group={excludes}
                      initialForm={initialForm}
                      setInitialForm={setInitialForm}
                      companyParams={companyParams || []}
                    />
                  </div>

                  {!isReadOnly && (
                    <div className="submit">
                      <Button
                        type="submit"
                        icon={buttonIcon}
                        iconColor={colors.white}
                        text={buttonText}
                        textColor={colors.white}
                        hoverColor={colors.secondary900}
                        textHoverColor={colors.white}
                        btnStyle="solid"
                        disabled={isSubmitting}
                      />
                    </div>
                  )}

                  <div className="footnote"></div>
                </LoadingIndicator>
              </Form>
            );
          }}
        </Formik>
      </div>

      {showMessages(messages, clearMessage)}
    </div>
  );
};

export default NewAudience;
