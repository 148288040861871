import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
// import { useFlag } from 'flags';

import * as usersActions from 'modules/users/usersActions';

import EntityTable from 'components/EntityTable/EntityTable';
import Legend from 'components/Legend/Legend';
import UserCard from './UserCard/UserCard';

import colors from 'theme/Colors.module.scss';

const USERS_HEADERS = [
  {
    name: 'First Name',
    field: 'firstname',
    hasIndicator: true,
    sortable: true,
    sortIcon: 'alpha',
    align: 'left',
    width: '30%',
  },
  {
    name: 'Last Name',
    field: 'lastname',
    sortable: true,
    sortIcon: 'alpha',
    align: 'left',
    width: '30%',
  },
  {
    name: 'Email',
    field: 'email',
    sortable: true,
    sortIcon: 'alpha',
    align: 'left',
    width: '30%',
  },
];

const USERS_PERSISTENCE_KEYS = ['search'];

export default ({
  searchParameters,
  setSearchParameters,
  setSearchPlaceholder,
  setSearchLegend,
}) => {
  // const isReadOnly = useFlag(['features', 'isReadOnly']);
  const dispatch = useDispatch();

  useEffect(() => {
    setSearchPlaceholder('Search users');
    setSearchLegend(<Legend color={colors.status00} label="Active" />);

    return () => {
      setSearchPlaceholder('');
      setSearchLegend(null);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderUserCard = (user, state) => {
    return (
      <UserCard
        key={user.id}
        headers={USERS_HEADERS}
        focus={state.focus}
        user={user}
      />
    );
  };

  return (
    <EntityTable
      headers={USERS_HEADERS}
      pageable
      sortable
      focusable
      scrollable
      // scrollSpacerHeight={65}
      persistable
      persistenceKeys={USERS_PERSISTENCE_KEYS}
      defaultSearchParameters={searchParameters}
      onSearchParameters={setSearchParameters}
      entityType="users"
      getEntities={searchParameters => {
        return new Promise((resolve, reject) => {
          dispatch(usersActions.getAllUsers(searchParameters, resolve, reject));
        });
      }}
      renderRow={renderUserCard}
    />
  );
};
