import { isEmpty } from 'lodash';

export const paramsToObject = params => {
  const decodedParams = decodeURI(params);
  const rawString = decodedParams.split(/[?&]+/);
  const paramsArray = rawString.slice(1, rawString.length);
  const paramsObject = {};

  paramsArray.forEach(param => {
    const rawVal = param.split('=');
    const key = rawVal[0];
    const value = rawVal[1];

    paramsObject[key] = value;
  });

  return paramsObject;
};

export const paramsToString = params => {
  let paramsArray = [];

  for (let key in params) {
    paramsArray.push(`${key}=${params[key]}`);
  }

  paramsArray.forEach((param, index) => {
    if (index === 0) {
      paramsArray[index] = `?${encodeURI(param)}`;
    } else {
      paramsArray[index] = `&${encodeURI(param)}`;
    }
  });

  return paramsArray.join('');
};

export const paramsFromURL = () => {
  const location = window.location.href;
  const paramSplit = location.split('?');
  const params = `?${paramSplit[1]}`;
  const workingParams = paramsToObject(params);

  delete workingParams.undefined;

  return paramsToString(workingParams);
};

export const searchParams = payload => {
  if (isEmpty(payload)) payload = {};
  const existingParams = paramsToObject(paramsFromURL());
  const existingSearch = { search: existingParams.search };
  const workingParams = !isEmpty(existingSearch.search) ? existingSearch : {};

  if (!isEmpty(payload) && !isEmpty(payload.search)) {
    workingParams.search = payload.search;
  } else if (payload.search === '') {
    delete workingParams.search;
  }

  return workingParams;
};

export const pageParams = payload => {
  if (isEmpty(payload)) payload = {};
  const workingParams = {};

  !!payload.page && (workingParams.page = payload.page);
  !!payload.size && (workingParams.size = payload.size);
  !!payload.sort && (workingParams.sort = sortToString(payload.sort));

  return workingParams;
};

export const sortToString = sort => {
  return (sort || [])
    .map(sortItem => {
      return sortItem.field + ':' + sortItem.direction;
    })
    .join(',');
};

export const sortFromString = sortString => {
  try {
    return sortString.split(',').map(sortItemString => {
      const tokens = sortItemString.split(':');

      return {
        field: tokens[0],
        direction: tokens[1],
      };
    });
  } catch (error) {
    return [];
  }
};

export const reverseSortDirection = sortDirection => {
  // eslint-disable-next-line no-useless-escape
  switch (sortDirection.replace(/(\-nulls\-(first|last))$/, '')) {
    case 'asc':
      return 'desc';
    case 'desc':
    default:
      return 'asc'; // if neither "asc" or "desc", return "asc"
  }
};

export const advanceSortDirection = sortDirection => {
  // eslint-disable-next-line no-useless-escape
  switch (sortDirection?.replace(/(\-nulls\-(first|last))$/, '')) {
    case 'desc':
      return 'asc';
    case 'asc':
      return null;
    default:
      return 'desc';
  }
};

export const creativesParams = payload => {
  if (isEmpty(payload)) payload = {};
  const existingParams = paramsToObject(paramsFromURL());
  const existingStatus = { archived: existingParams.archived === 'true' };
  const workingParams = !isEmpty(existingStatus.archived) ? existingStatus : {};

  if (!isEmpty(payload) && !isEmpty(payload.archived)) {
    workingParams.archived = payload.archived;
  } else {
    workingParams.archived = existingStatus.archived;
  }

  return workingParams;
};

export const usersParams = payload => {
  if (isEmpty(payload)) payload = {};
  const existingParams = paramsToObject(paramsFromURL());
  const existingStatus = { inactive: existingParams.inactive === 'true' };
  const workingParams = !isEmpty(existingStatus.inactive) ? existingStatus : {};

  if (!isEmpty(payload) && !isEmpty(payload.inactive)) {
    workingParams.inactive = payload.inactive;
  } else {
    workingParams.inactive = existingStatus.inactive;
  }

  workingParams.company_id = payload.company_id;

  return workingParams;
};

export const campaignParams = payload => {
  if (isEmpty(payload)) payload = {};
  const workingParams = {};

  if (!isEmpty(payload) && !isEmpty(payload.target_type)) {
    workingParams.target_type = !!payload.target_type
      ? payload.target_type
      : 'all';
  } else {
    workingParams.target_type = 'all';
  }

  if (payload.search) workingParams.search = payload.search;

  if (payload.model) workingParams.model = payload.model;

  return workingParams;
};

export const audienceParams = payload => {
  if (isEmpty(payload)) payload = {};
  const workingParams = {};

  // if (payload.id) workingParams.companyId = payload.id;
  if (payload.model) workingParams.model = payload.model;
  if (payload.search) workingParams.search = payload.search;

  return workingParams;
};
