import { useContext } from 'react';
import { EditorContext } from '../EditorContext';

function useEditorContext() {
  const {
    currentTemplate,
    setCurrentTemplate,
    defaultSizeFormat,
    setDefaultSizeFormat,
    canvas,
    setCanvas,
    activeObject,
    setActiveObject,
    handlers,
    setHandlers,
    sizeFormat,
    setSizeFormat,
    doubleSided,
    setDoubleSided,
    zoomRatio,
    setZoomRatio,
    fitZoomRatio,
    setFitZoomRatio,
    fillZoomRatio,
    setFillZoomRatio,
    zoomMode,
    setZoomMode,
    contextMenuType,
    setContextMenuType,
    keysMenu,
    setKeysMenu,
    contextMenu,
    setContextMenu,
    editor,
    setEditor,
    currentBackgroundColor,
    setCurrentBackgroundColor,
    currentBackgroundImage,
    setCurrentBackgroundImage,
  } = useContext(EditorContext);

  return {
    currentTemplate,
    setCurrentTemplate,
    defaultSizeFormat,
    setDefaultSizeFormat,
    canvas,
    setCanvas,
    activeObject,
    setActiveObject,
    handlers,
    setHandlers,
    sizeFormat,
    setSizeFormat,
    doubleSided,
    setDoubleSided,
    zoomRatio,
    setZoomRatio,
    fitZoomRatio,
    setFitZoomRatio,
    fillZoomRatio,
    setFillZoomRatio,
    zoomMode,
    setZoomMode,
    contextMenuType,
    setContextMenuType,
    keysMenu,
    setKeysMenu,
    contextMenu,
    setContextMenu,
    editor,
    setEditor,
    currentBackgroundColor,
    setCurrentBackgroundColor,
    currentBackgroundImage,
    setCurrentBackgroundImage,
  };
}

export default useEditorContext;
