import React from 'react';
import { Table, Thead, Th, Tbody, Td, Tr, Text, Flex } from '@chakra-ui/react';
import colors from 'theme/Colors.module.scss';
import ToggleSwitch from 'components/ToggleSwitch/ToggleSwitch';
import Checkbox from 'components/Checkbox/Checkbox';
import Icons from 'components/Icons';
import * as tableHelpers from 'helpers/tableHelpers';
// import { useHistory } from 'react-router-dom';
// import { formatDate } from 'react-day-picker/moment';
import { format } from "date-fns";
import { useSortTable } from './hooks/useSortTable';

export default function TableComponent({
  headers,
  data,
  onSwitchToggled,
  setSwitchValue = () => {},
  handleSort,
  onItemChecked,
  onHeaderChecked,
  checkedItems = [],
  id,
  showCheckBoxes = true,
  showSwitchToggles = true,
  onRowClicked,
  sortable = true,
}) {
  const [isHeaderChecked, setIsHeaderChecked] = React.useState(false);
  const preventEvent = React.useRef(false);

  const { sortByField, getFieldDirection, isSortedByField } = useSortTable({
    headers,
    handleSort,
    sortable,
  });

  const fields = headers.map(({ field }) => field) || [];

  const handleClickRow = id => {
    if (!preventEvent.current) {
      onRowClicked(id);
    }
  };

  const onChecked = id => {
    onItemChecked(id, !isItemChecked(id));
  };

  const isItemChecked = itemId => {
    return !!checkedItems.find(checkedItemId => checkedItemId === itemId);
  };

  const displayFieldData = fieldData => {
    if (!fieldData) return '--';
    if (fieldData instanceof Date) {
      return format(fieldData);
    }
    return fieldData;
  };

  const setHeaderAlignment = fieldName => {
    const value = tableHelpers.setHeaderAlignment(headers, fieldName);
    const values = {
      center: 'center',
      left: 'flex-start',
      right: 'flex-end',
    };

    return values[value];
  };

  React.useEffect(() => {
    if (
      checkedItems &&
      data &&
      data.length > 0 &&
      checkedItems.length === data.length
    ) {
      setIsHeaderChecked(true);
    } else {
      setIsHeaderChecked(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [headers, checkedItems]);

  return (
    <Table
      variant="unstyled"
      sx={{ borderCollapse: 'collapse' }}
      id={id}
      w="100%"
    >
      <Thead
        fontWeight={600}
        fontSize="12px"
        color={colors.grey500}
        h="40px"
        bgColor="white"
      >
        <Tr>
          {showCheckBoxes && (
            <Th
              border={`1px solid ${colors.grey200}`}
              minWidth="56px"
              textAlign="center"
              pl="0"
            >
              <Checkbox
                size="lg"
                inline
                outlined
                name={'headerCheckbox'}
                onChange={() => {
                  onHeaderChecked(!isHeaderChecked);
                }}
                checked={isHeaderChecked}
              />
            </Th>
          )}

          {showSwitchToggles && (
            <Th
              border={`1px solid ${colors.grey200}`}
              minWidth="72px"
              textAlign="center"
              pl="0"
            >
              Off/On
            </Th>
          )}

          {headers.map((header, i) => (
            <Th
              border={`1px solid ${colors.grey200}`}
              minWidth={header.width}
              textAlign="left"
              pl="13px"
              cursor="pointer"
              key={header.name}
              onClick={() => sortByField(header.sortField)}
            >
              <Flex align="center" justify={setHeaderAlignment(header.field)}>
                <Text as="span" textAlign="center">
                  {header.name}
                </Text>
                {isSortedByField(header?.sortField) && (
                  <Icons.Arrow
                    style={{ marginLeft: '7px' }}
                    toTop={getFieldDirection(header.sortField) !== 'asc'}
                  />
                )}
              </Flex>
            </Th>
          ))}
        </Tr>
      </Thead>
      <Tbody fontWeight="normal" fontSize="12px">
        {data.map(datum => (
          <Tr
            h="40px"
            bgColor="white"
            cursor="pointer"
            key={datum.id}
            onClick={() => handleClickRow(datum.id)}
          >
            {showCheckBoxes && (
              <Td
                border={`1px solid ${colors.grey200}`}
                textAlign="center"
                pl="0"
                onMouseEnter={() => (preventEvent.current = true)}
                onMouseLeave={() => (preventEvent.current = false)}
              >
                <Checkbox
                  id={datum.id}
                  name={datum.id}
                  checked={isItemChecked(datum.id)}
                  inline
                  outlined
                  onChange={() => onChecked(datum.id)}
                />
              </Td>
            )}

            {showSwitchToggles && (
              <Td
                border={`1px solid ${colors.grey200}`}
                textAlign="center"
                pl="0"
                onMouseEnter={() => (preventEvent.current = true)}
                onMouseLeave={() => (preventEvent.current = false)}
              >
                <ToggleSwitch
                  color={colors.primaryLight}
                  name="cmpToggle"
                  // disabled={updatingCampaign}
                  value={setSwitchValue(datum.id)}
                  onChange={event => {
                    onSwitchToggled(datum.id, event.target.checked);
                  }}
                />
              </Td>
            )}

            {fields.map(field => (
              <Td
                border={`1px solid ${colors.grey200}`}
                pl="13px"
                pr="13px"
                key={field}
                color={
                  field?.includes('name')
                    ? '#035BDA'
                    : tableHelpers.setColor(headers, field)
                }
                textAlign={tableHelpers.setTextAlignment(headers, field)}
              >
                {displayFieldData(datum[field])}
              </Td>
            ))}
          </Tr>
        ))}
      </Tbody>
    </Table>
  );
}
