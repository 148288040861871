import React, { useState } from 'react';
import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Input,
  Box,
} from '@chakra-ui/react';

function SaveModal({ isOpen, onClose, onSave, createInProgress, requestFail }) {
  const [name, setName] = useState('');
  const isSaveDisabled = name.length <= 0;

  return (
    <Modal isCentered isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent
        sx={{
          width: '320px',
          paddingBottom: '1rem',
        }}
      >
        <ModalHeader fontWeight={600}>Name template</ModalHeader>
        <ModalCloseButton />
        <ModalBody
          sx={{ display: 'flex', gap: '1rem', flexDirection: 'column' }}
        >
          <Input
            value={name}
            onChange={e => setName(e.target.value)}
            placeholder="Template name"
            sx={{
              backgroundColor: '#ffffff',
              ':focus': {
                borderColor: 'rgb(255, 111, 15)',
                boxShadow: '0 0 0 1px rgb(255, 111, 15)',
              },
            }}
            onKeyUp={ev => ev.key === 'Enter' && onSave(name)}
          />
          <Button
            onClick={() => onSave(name)}
            _hover={{
              backgroundColor: 'rgba(127,102,241)',
            }}
            isDisabled={isSaveDisabled}
            fontWeight={400}
            isActive={false}
            w="100%"
            backgroundColor="rgb(78, 80, 214)"
            colorScheme="blue"
          >
            {createInProgress ? 'Creating' : 'Create template'}
          </Button>
          {requestFail && (
            <Box
              sx={{
                fontSize: '12px',
                color: 'red',
              }}
            >
              <strong>*</strong> Could not save template
            </Box>
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}

export default SaveModal;
