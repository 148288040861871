import React from 'react';
import { Box, Text, Image } from '@chakra-ui/react';
import { CodeBlock, nord } from 'react-code-blocks';
import CustomScroller from 'components/CustomScroller';
import TutorialClose from './components/TutorialClose';

const code = `{
  "audience": {
    ...
  },
  "template": {
    "firstname": "Arabella"
  }
}`;

function TextTutorial() {
  return (
    <Box sx={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
      <Box
        sx={{
          flex: 1,
          position: 'relative',
        }}
      >
        <TutorialClose />
        <Box
          sx={{
            position: 'absolute',
            height: '100%',
            width: '100%',
          }}
        >
          <CustomScroller>
            <Box
              sx={{
                background: '#ffffff',
                flex: 1,
                borderRight: '1px solid rgba(57,76,96,.15)',
                padding: '1.5rem',
                fontSize: '14px',
              }}
            >
              <Text sx={{ fontSize: '18px', fontWeight: 700 }}>Text</Text>
              <Box lineHeight="22px">
                <Text mt="1rem">
                  Text blocks allow you to add text to your ad template. The
                  text can be styled in the font of your choice by selecting an
                  available font or uploading your own.
                </Text>
                <Text mt="1rem">
                  You can also place personalization tokens within text blocks.
                  Personalization tokens allow your order/customer data to
                  populate the value of the token. Personalization tokens appear
                  in curly braces when looking at the raw text.
                </Text>
                <Text mt="1rem">For example,</Text>
                <Box pt="1rem">
                  <Image
                    boxShadow="md"
                    width="210px"
                    margin="auto"
                    src="https://i.ibb.co/hMynzw1/first-name.png"
                  />
                </Box>
                <Text mt="1rem">
                  When not editing the text, the curly braces will not be shown
                  and instead the default value given to the personalization
                  token will be shown. This allows you to easily view what a
                  generated ad will look like while editing your template.
                </Text>
                <Box pt="1rem">
                  <Image
                    boxShadow="md"
                    width="210px"
                    margin="auto"
                    src="https://i.ibb.co/qJ5BG7L/1b77febe-ca28-4b4b-9e30-2c77e34614ff.png"
                  />
                </Box>

                <Text mt="1rem">
                  When order data is posted to UnDigital, the system will
                  replace the personalization token with the matching variable
                  name from the template params section of the post.
                </Text>
                <Text mt="1rem">Example order data post</Text>
                <Box
                  sx={{
                    fontFamily: "'Fira Code', monospace",
                    marginTop: '1rem',
                  }}
                >
                  <CodeBlock
                    text={code}
                    language={'json'}
                    showLineNumbers={false}
                    theme={nord}
                  />
                </Box>

                <Text mt="1rem">
                  Will produce an ad image that replaces the personalization
                  token with the value provided. The font style will use the
                  same style you selected for the personalization token.
                </Text>
                <Box pt="1rem">
                  <Image
                    boxShadow="md"
                    width="210px"
                    margin="auto"
                    src="https://i.ibb.co/68qNtFW/e6217131-4b05-48c8-ae55-2d2aaeb5823d-1-1.png"
                  />
                </Box>

                <Text mt="1rem">
                  You can also use the Preview button at the top right corner to
                  provide sample values for your personalization tokens to
                  preview what the ad template would generate during an order
                  post.
                </Text>

                <Image
                  boxShadow="md"
                  pt="1rem"
                  width="308px"
                  margin="auto"
                  src="https://i.ibb.co/Hn6Vw87/modal.png"
                />
              </Box>
            </Box>
          </CustomScroller>
        </Box>
      </Box>
    </Box>
  );
}
export default TextTutorial;
