import React from 'react';
import './DotSpinner.scss';

export default function DotSpinner({ size = '3.2', height = 17.6, style={} }) {
  const d = `${height}px`;

  return (
    <div
      style={{
        width: `${size}px`,
        height: `${size}px`,
        borderRadius: '50%',
        color: '#000000',
        boxShadow: `
          calc(1*${d})      calc(0*${d})     0 0,
          calc(0.707*${d})  calc(0.707*${d}) 0 ${size / 4}px,
          calc(0*${d})      calc(1*${d})     0 ${size / 2}px,
          calc(-0.707*${d}) calc(0.707*${d}) 0 ${size / 1.33}px,
          calc(-1*${d})     calc(0*${d})     0 ${size}px,
          calc(-0.707*${d}) calc(-0.707*${d})0 ${size * 1.25}px,
          calc(0*${d})      calc(-1*${d})    0 ${size * 1.49}px
      `,
      ...style
      }}
      className="spinner"
    />
  );
}