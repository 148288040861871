import { fabric } from 'fabric';
import {
  copyStyleProps,
  getCopyStyleCursor,
  getPanningStyleCursor,
  getRockHandStyleCursor,
  objectTypes,
} from '../common/constants';
import BaseHandler from './BaseHandler';
import pick from 'lodash/pick';
// import { isInsideFrontSideFrame } from '../utils/frame';

class ObjectHandler extends BaseHandler {
  clipboard;
  isCut;
  copyStyleClipboard;
  dragMode = false;

  add(options) {
    const { getOptionsByScrollPosition } = this.handlers.frameHandler;
    const frameOptions = getOptionsByScrollPosition();

    const isBackFrame = frameOptions.id === 'backSide';

    const top = this.handlers.frameHandler.getCenterY({
      frameOptions,
      isBackFrame,
    });
    const centerLeft = frameOptions.width;
    const focusedFrame =
      this.handlers.frameHandler.getFocusedFrame(isBackFrame);

    //set element options
    let object;
    switch (options.type) {
      case 'DynamicText':
        object = new fabric.DynamicText({
          ...options,
          top: top,
          left: frameOptions.left + centerLeft / 2 - options.width / 2,
        });
        break;
      case 'DynamicImage':
        object = new fabric.DynamicImage({
          ...options,
          top: top,
          left: frameOptions.left + centerLeft / 2,
        });
        break;
      case 'ConditionalText':
        object = new fabric.ConditionalText(options.text, {
          ...options,
          top: top,
          left: frameOptions.left + centerLeft / 2 - options.width / 2,
        });
        break;
      default:
        break;
    }

    // if (this.config.clipToFrame)
    object.clipPath = focusedFrame;
    this.canvas.add(object);
    this.canvas.setActiveObject(object);
    this.context.setActiveObject(object);
    this.handlers.historyHandler.save('object:created');
  }

  /**
   * Update object from canvas
   */
  insertKey(value) {
    const activeObject = this.canvas.getActiveObject();
    if (activeObject && activeObject.insertKey) {
      activeObject.insertKey(value);
    }
  }

  setKeyValue(key) {
    const activeObject = this.canvas.getActiveObject();
    if (activeObject) {
      if (activeObject.type === 'DynamicText') {
        const currentKeyValues = activeObject.keyValues;
        const updatedKeyValues = currentKeyValues.map(cv => {
          const value = cv.key === key.key ? key.value : cv.value;
          return {
            ...cv,
            value,
          };
        });
        activeObject.set('keyValues', updatedKeyValues);
      } else {
        activeObject.set('keyValues', [
          {
            key: key.key,
            value: key.value,
          },
        ]);
      }
    }
  }

  updateMetadata(key, value) {
    const activeObject = this.canvas.getActiveObject();
    if (activeObject && key) {
      const metadata = activeObject.metadata
        ? { ...activeObject.metadata }
        : {};
      if (value)
        activeObject.set('metadata', {
          ...metadata,
          [key]: value,
        });
      else {
        delete metadata[key];
        activeObject.set('metadata', {
          ...metadata,
        });
      }
    }
  }

  updateTextVar(props) {
    const activeObject = this.canvas.getActiveObject();
    if (activeObject) {
      if (activeObject.type === 'DynamicText') {
        activeObject.updateVariableName(props);
      }
    }
  }
  /**
   * Get canvas object by id
   */
  update = options => {
    const activeObject = this.canvas.getActiveObject();
    if (!activeObject) {
      return;
    }
    const canvas = this.canvas;
    if (activeObject) {
      for (const property in options) {
        if (property === 'angle' || property === 'top' || property === 'left') {
          if (property === 'angle') {
            activeObject.rotate(options['angle']);
            canvas.requestRenderAll();
          } else {
            activeObject.set(property, options[property]);
            canvas.requestRenderAll();
          }
        } else {
          if (activeObject._objects) {
            activeObject._objects.forEach(object => {
              if (property === 'metadata') {
                object.set('metadata', {
                  ...object.metadata,
                  ...options['metadata'],
                });
              } else {
                object.set(property, options[property]);
              }
              object.setCoords();
            });
          } else {
            if (property === 'metadata') {
              activeObject.set('metadata', {
                ...activeObject.metadata,
                ...options[property],
              });
            } else {
              activeObject.set(property, options[property]);
            }
            activeObject.setCoords();
          }
          // if (activeObject.type === 'DynamicText') {
          //   activeObject.updateHeigth();
          // }
          canvas.requestRenderAll();
        }
      }
    }
    this.handlers.historyHandler.save('object:updated');
  };

  /**
   * Remove active object
   */

  remove() {
    this.canvas.getActiveObjects().forEach(obj => {
      this.canvas.remove(obj);
    });
    this.canvas.discardActiveObject().renderAll();
    this.handlers.historyHandler.save('object:removed');
  }

  deselect = () => {
    this.canvas.discardActiveObject();
    this.canvas.requestRenderAll();
  };

  /**
   * Clear canvas
   * @param {boolean} [includeWorkarea=false]
   */
  clear = (includeFrame = false) => {
    if (includeFrame) {
      this.canvas.clear();
    } else {
      const frame = this.handlers.frameHandler.get();
      this.canvas.getObjects().forEach(object => {
        if (object.type !== 'Frame' && object.type !== 'group') {
          this.canvas.remove(object);
        }
      });
      frame.set('fill', '#ffffff');
    }
    this.canvas.backgroundColor = '#f6f7f9';
    this.canvas.renderAll();
  };

  moveVertical = value => {
    const activeObject = this.canvas.getActiveObject();
    if (!activeObject) {
      return;
    }
    const top = activeObject.top + value;
    this.update({
      top: top,
    });
  };

  moveHorizontal = value => {
    const activeObject = this.canvas.getActiveObject();
    if (!activeObject) {
      return;
    }
    const left = activeObject.left + value;
    this.update({
      left: left,
    });
  };

  updateLineHeight = value => {
    const activeObject = this.canvas.getActiveObject();
    if (!activeObject) {
      return;
    }
    if (
      activeObject.type === 'DynamicText' ||
      activeObject.type === 'StaticImage'
    ) {
      const lineHeight = activeObject.lineHeight + value;
      this.update({
        lineHeight: lineHeight,
      });
    }
  };

  updateCharSpacing = value => {
    const activeObject = this.canvas.getActiveObject();
    if (!activeObject) {
      return;
    }
    if (
      activeObject.type === 'DynamicText' ||
      activeObject.type === 'StaticImage'
    ) {
      const charSpacing = activeObject.charSpacing + value;
      this.update({
        charSpacing: charSpacing,
      });
    }
  };

  cut = () => {
    this.copy();
    this.isCut = true;
    this.remove();
  };

  copy = () => {
    const activeObject = this.canvas.getActiveObject();
    // if (!activeObject) return;
    if (activeObject) {
      activeObject.clone(
        cloned => {
          this.clipboard = cloned;
        },
        ['metadata', 'subtype']
      );
    }
  };

  copyStyle = () => {
    const activeObject = this.canvas.getActiveObject();
    if (activeObject) {
      const clonableProps = copyStyleProps[activeObject.type];
      const clonedProps = pick(activeObject.toJSON(), clonableProps);

      this.copyStyleClipboard = {
        objectType: activeObject.type,
        props: clonedProps,
      };

      this.handlers.frameHandler.setHoverCursor(getCopyStyleCursor());
      this.canvas.hoverCursor = getCopyStyleCursor();
      this.canvas.defaultCursor = getCopyStyleCursor();
    }
  };

  pasteStyle = () => {
    const activeObject = this.canvas.getActiveObject();
    if (activeObject && this.copyStyleClipboard) {
      if (activeObject.type === this.copyStyleClipboard.objectType) {
        const { fill, ...basicProps } = this.copyStyleClipboard.props;
        activeObject.set(basicProps);

        if (fill) {
          if (fill.type) {
            activeObject.set({ fill: new fabric.Gradient(fill) });
          } else {
            activeObject.set({ fill });
          }
        }
      }
    }
    this.copyStyleClipboard = null;
    this.handlers.frameHandler.setHoverCursor('default');
    this.canvas.hoverCursor = 'move';
    this.canvas.defaultCursor = 'default';
  };

  clone = () => {
    const activeObject = this.canvas.getActiveObject();
    if (activeObject) {
      const frame = activeObject.clipPath;

      this.canvas.discardActiveObject();

      this.duplicate(activeObject, frame, duplicates => {
        const selection = new fabric.ActiveSelection(duplicates, {
          canvas: this.canvas,
        });
        this.canvas.setActiveObject(selection);
        this.canvas.requestRenderAll();
      });
    }
  };

  duplicate(object, frame, callback) {
    if (object instanceof fabric.Group) {
      const objects = object.getObjects();
      const duplicates = [];
      for (let i = 0; i < objects.length; i++) {
        this.duplicate(objects[i], frame, clones => {
          duplicates.push(...clones);
          if (i === objects.length - 1) {
            callback(duplicates);
          }
        });
      }
    } else {
      object.clone(
        clone => {
          clone.clipPath = null;
          clone.set({
            left: object.left + 10,
            top: object.top + 10,
            subtype: object.subtype,
            metadata: object.metadata,
          });
          if (this.config.clipToFrame) object.clipPath = frame;
          if (clone.type === 'StaticText') {
            clone.set({ initialWidth: object.width });
          }

          this.canvas.add(clone);

          callback([clone]);
        },
        ['keyValues', 'src']
      );
    }
  }

  paste = () => {
    const { clipboard } = this;
    // const padding = isCut ? 0 : 10;
    if (!clipboard) return false;
    const { getOptionsByScrollPosition } = this.handlers.frameHandler;
    const frameOptions = getOptionsByScrollPosition();
    const isBackFrame = frameOptions.id === 'backSide';
    const top = this.handlers.frameHandler.getCenterY({
      frameOptions,
      isBackFrame,
    });
    const centerLeft = frameOptions.width;
    clipboard.clone(
      clonedObj => {
        this.canvas.discardActiveObject();
        let left =
          clonedObj.type === 'DynamicText'
            ? frameOptions.left + centerLeft / 2 - clonedObj.width / 2
            : frameOptions.left + centerLeft / 2;
        clonedObj.set({
          left,
          top,
          evented: true,
        });
        if (clonedObj.type === 'activeSelection') {
          // active selection needs a reference to the canvas.
          clonedObj.canvas = this.canvas;
          clonedObj.forEachObject(obj => {
            this.canvas.add(obj);
          });
          clonedObj.setCoords();
        } else {
          this.canvas.add(clonedObj);
        }
        // clipboard.top += padding;
        // clipboard.left += padding;
        this.canvas.setActiveObject(clonedObj);
        this.canvas.requestRenderAll();
      },
      ['metadata', 'subtype']
    );

    this.isCut = false;
  };

  remove = () => {
    const activeObjects = this.canvas.getActiveObjects();
    if (!activeObjects) {
      return;
    }
    activeObjects.forEach(obj => {
      this.canvas.remove(obj);
    });
    this.canvas.discardActiveObject().renderAll();
  };

  selectAll = () => {
    this.canvas.discardActiveObject();
    const filteredObjects = this.canvas.getObjects().filter(object => {
      if (object.type === 'Frame') {
        return false;
      } else if (!object.evented) {
        return false;
        //@ts-ignore
      } else if (object.locked) {
        return false;
      }
      return true;
    });
    if (!filteredObjects.length) {
      return;
    }
    if (filteredObjects.length === 1) {
      this.canvas.setActiveObject(filteredObjects[0]);
      this.canvas.renderAll();
      this.context.setActiveObject(filteredObjects[0]);
      return;
    }
    const activeSelection = new fabric.ActiveSelection(filteredObjects, {
      canvas: this.canvas,
    });
    this.canvas.setActiveObject(activeSelection);
    this.canvas.renderAll();
    this.context.setActiveObject(activeSelection);
  };

  lock = () => {
    const activeObject = this.canvas.getActiveObject();
    if (!activeObject) {
      return;
    }

    // @ts-ignore
    if (activeObject._objects) {
      // @ts-ignore
      activeObject._objects.forEach(object => {
        object.set({
          hasControls: false,
          lockMovementY: true,
          lockMovementX: true,
          locked: true,
        });
      });
      // @ts-ignore
      activeObject.set({
        hasControls: false,
        lockMovementY: true,
        lockMovementX: true,
        locked: true,
      });
    } else {
      // @ts-ignore

      activeObject.set({
        hasControls: false,
        lockMovementY: true,
        lockMovementX: true,
        locked: true,
      });
    }
    this.canvas.renderAll();
    this.handlers.historyHandler.save('object:updated');
  };

  unlock = () => {
    const activeObject = this.canvas.getActiveObject();
    if (!activeObject) {
      return;
    }

    // @ts-ignore
    if (activeObject._objects) {
      // @ts-ignore
      activeObject._objects.forEach(object => {
        object.set({
          hasControls: true,
          lockMovementY: false,
          lockMovementX: false,
          locked: false,
        });
      });
      // @ts-ignore
      activeObject.set({
        hasControls: true,
        lockMovementY: false,
        lockMovementX: false,
        locked: false,
      });
    } else {
      // @ts-ignore
      activeObject.set({
        hasControls: true,
        lockMovementY: false,
        lockMovementX: false,
        locked: false,
      });
    }
    this.canvas.renderAll();
    this.handlers.historyHandler.save('object:updated');
  };

  enableToggleDragMode = () => {
    this.dragMode = true;

    const STATE_IDLE = 'idle';
    const STATE_PANNING = 'panning';
    // Remember the previous X and Y coordinates for delta calculations
    let lastClientX;
    let lastClientY;
    // Keep track of the state
    let state = STATE_IDLE;

    // Discard any active object
    this.canvas.discardActiveObject();
    // Set the cursor to 'move'
    this.canvas.defaultCursor = getPanningStyleCursor();
    this.canvas.hoverCursor = getPanningStyleCursor();
    // Loop over all objects and disable events / selectable. We remember its value in a temp variable stored on each object
    this.canvas.forEachObject(function (object) {
      object.prevEvented = object.evented;
      object.prevSelectable = object.selectable;
      object.evented = false;
      object.selectable = true;
    });
    // Remove selection ability on the canvas
    this.canvas.selection = false;
    // When MouseUp fires, we set the state to idle

    this.canvas.on('mouse:up', e => {
      state = STATE_IDLE;
      this.canvas.defaultCursor = getPanningStyleCursor();
      this.canvas.hoverCursor = getPanningStyleCursor();
    });
    // When MouseDown fires, we set the state to panning
    this.canvas.on('mouse:down', e => {
      state = STATE_PANNING;
      lastClientX = e.e.clientX;
      lastClientY = e.e.clientY;

      this.canvas.defaultCursor = getRockHandStyleCursor();
      this.canvas.hoverCursor = getRockHandStyleCursor();
    });
    // When the mouse moves, and we're panning (mouse down), we continue
    this.canvas.on('mouse:move', e => {
      if (state === STATE_PANNING && e && e.e) {
        // let delta = new fabric.Point(e.e.movementX, e.e.movementY); // No Safari support for movementX and movementY
        // For cross-browser compatibility, I had to manually keep track of the delta

        // Calculate deltas
        let deltaX = 0;
        let deltaY = 0;
        if (lastClientX) {
          deltaX = e.e.clientX - lastClientX;
        }
        if (lastClientY) {
          deltaY = e.e.clientY - lastClientY;
        }
        // Update the last X and Y values
        lastClientX = e.e.clientX;
        lastClientY = e.e.clientY;

        let delta = new fabric.Point(deltaX, deltaY);
        this.canvas.relativePan(delta);
        // this.canvas.trigger('moved');
      }
    });
  };

  disableToggleMode = () => {
    this.dragMode = true;
    // When we exit dragmode, we restore the previous values on all objects
    this.canvas.forEachObject(function (object) {
      object.evented =
        object.prevEvented !== undefined ? object.prevEvented : object.evented;
      object.selectable =
        object.prevSelectable !== undefined
          ? object.prevSelectable
          : object.selectable;
    });
    // Reset the cursor
    this.canvas.defaultCursor = 'default';
    this.canvas.hoverCursor = 'move';
    // Remove the event listeners
    this.canvas.off('mouse:up');
    this.canvas.off('mouse:down');
    this.canvas.off('mouse:move');
    // Restore selection ability on the canvas
    this.canvas.selection = true;
  };

  bringForward = () => {
    const selectedObject = this.canvas.getActiveObject();
    if (selectedObject) {
      this.canvas.bringForward(selectedObject);
      this.handlers.historyHandler.save('object:updated');
      this.canvas.wrapperEl.focus();
    }
  };

  bringToFront = () => {
    const selectedObject = this.canvas.getActiveObject();
    if (selectedObject) {
      this.canvas.bringToFront(selectedObject);
      this.handlers.historyHandler.save('object:updated');
      this.canvas.wrapperEl.focus();
    }
  };

  sendBackwards = () => {
    const selectedObject = this.canvas.getActiveObject();
    if (selectedObject) {
      const totalObjectCnt = (this.canvas.getObjects() || []).length;
      if (!totalObjectCnt) return;
      // min: bottom, max: top
      const objectOrder = [];
      Array(totalObjectCnt)
        .fill(0)
        .map((_, index) => {
          const object = this.canvas.item(index);
          if (
            object.type !== objectTypes.FRAME &&
            object.type !== objectTypes.BACKGROUND_IMAGE
          )
            objectOrder.push(
              object.id
                ? object.id
                : object.type.concat(object.metadata?.__session_var_id)
            );
          return object.id;
        });
      if (
        objectOrder.length &&
        ((selectedObject.id && objectOrder[0] !== selectedObject.id) ||
          (!selectedObject.id &&
            objectOrder[0] !==
              selectedObject.type.concat(
                selectedObject.metadata?.__session_var_id
              )))
      )
        this.canvas.sendBackwards(selectedObject);
      this.handlers.historyHandler.save('object:updated');
      this.canvas.wrapperEl.focus();
    }

    // const frames = this.canvas
    //   .getObjects()
    //   .filter(object => object.type === objectTypes.FRAME);
    // const frontIdx = frames.findIndex(
    //   item => item.id === 'Frame1' || item.id === 'frontSide'
    // );
    // const isInsideFront =
    //   frames.length > 1 ? isInsideFrontSideFrame(frames, selectedOjbect) : true;
    // const currentFrame = frames[isInsideFront ? frontIdx : (frontIdx + 1) % 2];
    // this.canvas.sendToBack(currentFrame);
  };

  sendToBack = () => {
    const selectedObject = this.canvas.getActiveObject();
    if (selectedObject) {
      const basicObjectsCnt = (
        (this.canvas.getObjects() || []).filter(
          each =>
            each.type === objectTypes.FRAME ||
            each.type === objectTypes.BACKGROUND_IMAGE
        ) || []
      ).length;
      this.canvas.sendToBack(selectedObject);
      // put on top of frame, background image object
      for (let i = 0; i < basicObjectsCnt; i++)
        this.canvas.bringForward(selectedObject);
      this.handlers.historyHandler.save('object:updated');
      this.canvas.wrapperEl.focus();
    }
  };

  getDocumentColors = () => {
    let textElements = this.canvas._objects.filter(
      el => el?.text !== undefined
    );
    let documentColors = [];
    return [
      ...new Set(
        textElements.reduce((prev, cur) => {
          prev.push(cur.fill);
          return prev;
        }, documentColors)
      ),
    ];
  };
}

export default ObjectHandler;
