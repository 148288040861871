import { normalize } from 'normalizr';

const isValidAction = action =>
  action && action.meta && action.meta.schema && action.error !== true;
const isPaged = payload =>
  payload && payload.current_page && payload.size && payload.data && true;
// gets data accordingly to if payload is a page or not
const getData = payload => (isPaged(payload) ? payload.data : payload);
// assigns data accordingly to if payload is a page or not
const assignData = (payload, data) =>
  isPaged(payload) ? Object.assign(payload, { data: data }) : data;

const normalizrMiddleware =
  ({ dispatch, getState }) =>
  next =>
  action => {
    if (!isValidAction(action)) return next(action);

    const data = normalize(getData(action.payload), action.meta.schema);
    action.payload = assignData(action.payload, data);

    return next(action);
  };

export default normalizrMiddleware;
