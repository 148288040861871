import React, { createContext, useState } from 'react';
import { DEFAULT_FONT, DEFAULT_FORMAT_SIZE } from '../constants/editor';

export const TemplateEditorContext = createContext();
export const TemplateEditorProvider = ({ children }) => {
  const [activePanel, setActivePanel] = useState('Text');
  const [activeSubMenu, setActiveSubMenu] = useState(null);
  const [isFontLoading, setIsFontLoading] = useState(false);
  const [isEditorLoading, setIsEditorLoading] = useState(false);
  const [isEnabledDoubleSide, setIsEnabledDoubleSide] = useState(false);
  const [isEnabledDoubleSideMessage, setIsEnabledDoubleSideMessage] =
    useState(false);
  const [isTemplateLoading, setIsTemplateLoading] = useState(false);
  const [currentTemplate, setCurrentTemplate] = useState(null);
  const [defaultFont, setDefaultFont] = useState(DEFAULT_FONT);
  const [defaultFormatSize, setDefaultFormatSize] =
    useState(DEFAULT_FORMAT_SIZE);
  const [doubleSidedBgImgUrls, setDoubleSidedBgImgUrls] = useState(['', '']);

  const context = {
    activePanel,
    setActivePanel,
    activeSubMenu,
    setActiveSubMenu,
    isFontLoading,
    setIsFontLoading,
    isTemplateLoading,
    setIsTemplateLoading,
    currentTemplate,
    setCurrentTemplate,
    defaultFont,
    setDefaultFont,
    defaultFormatSize,
    setDefaultFormatSize,
    isEditorLoading,
    setIsEditorLoading,
    isEnabledDoubleSideMessage,
    setIsEnabledDoubleSideMessage,
    isEnabledDoubleSide,
    setIsEnabledDoubleSide,
    doubleSidedBgImgUrls,
    setDoubleSidedBgImgUrls,
  };
  return (
    <TemplateEditorContext.Provider value={context}>
      {children}
    </TemplateEditorContext.Provider>
  );
};
