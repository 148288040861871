import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { Row, Col } from 'react-grid-system';

import { Formik, Field, Form } from 'formik';
// import { paramsToObject } from 'helpers/paramHelpers';
import * as usersActions from 'modules/users/usersActions';
// import * as usersSelectors from 'modules/users/usersSelectors';
import { userValidation } from 'modules/users/userValidations';
import { useFlag } from 'flags';
// import * as yup from 'yup';
import InputFeedback from 'components/InputFeedback/InputFeedback';
import Tooltip from 'components/Tooltip/Tooltip';
import Button from 'components/Button/Button';
import colors from 'theme/Colors.module.scss';
import './UserDetail.scss';

export default ({ match }) => {
  const { id } = match.params;
  const [user, setUser] = useState(null);
  const [loaded, setLoaded] = useState(null);
  const [error, setError] = useState(null);
  const isReadOnly = useFlag(['features', 'isReadOnly']);
  const isAdmin = useFlag(['features', 'isAdmin']);
  const disabled = isReadOnly || !isAdmin || true; // disabled for now
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const user = await new Promise((resolve, reject) =>
          dispatch(usersActions.getUserById(id, resolve, reject))
        );
        setUser(user);
      } catch (error) {
        setError(error);
      } finally {
        setLoaded(true);
      }
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const updateUser = useCallback(
    data =>
      new Promise((resolve, reject) =>
        dispatch(usersActions.updateUserById(id, data, resolve, reject))
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [id]
  );

  const handleSubmit = async (values, form) => {
    form.setSubmitting(true);

    try {
      const updatedUser = await updateUser(values);
      setUser(updatedUser);
    } catch (err) {
      setError(err);
      console.error(error);
    } finally {
      form.setSubmitting(false);
    }
  };

  return (
    <div>
      <div className="rail rail-rule">
        <Button
          type="button"
          text="Back"
          className="backBtn"
          textColor={colors.slate}
          icon="back"
          btnStyle="transparent"
          url="/users"
        />
      </div>
      {loaded &&
        (user ? (
          <Formik
            enableReinitialize
            validateOnMount
            initialValues={user}
            validationSchema={userValidation}
            onSubmit={handleSubmit}
          >
            {form => {
              const { isSubmitting, dirty } = form;

              return (
                <Form className="formContainer">
                  <h1 className="formTitle">Manage User</h1>
                  <p className="formLabel">User Information</p>
                  <div className="formSection">
                    <Row>
                      <Col sm={6}>
                        <div className="formGroup">
                          <label htmlFor="firstname" className="formLabel">
                            First Name
                          </label>
                          <Field
                            className="fillWidth"
                            type="textarea"
                            name="firstname"
                            placeholder="First Name*"
                            disabled={disabled || isSubmitting}
                          />
                          <InputFeedback name="firstname" />
                        </div>
                      </Col>
                      <Col sm={6}>
                        <div className="formGroup">
                          <label htmlFor="lastname" className="formLabel">
                            Last Name
                          </label>
                          <Field
                            className="fillWidth"
                            type="textarea"
                            name="lastname"
                            placeholder="Last Name*"
                            disabled={disabled || isSubmitting}
                          />
                          <InputFeedback name="lastname" />
                        </div>
                      </Col>
                    </Row>
                    <div className="formGroup formGroup-noMargin">
                      <label
                        htmlFor="email"
                        className="formLabel formLabel-withTooltip"
                      >
                        Email
                        <Tooltip
                          tipText="Updating your email address affects the login credentials."
                          direction="right"
                        />
                      </label>
                      <Field
                        className="fillWidth"
                        type="email"
                        name="email"
                        placeholder="Email*"
                        disabled={disabled || isSubmitting}
                      />
                      <InputFeedback name="email" />
                    </div>
                  </div>
                  {!disabled && (
                    <div className="formActionsArea">
                      <Button
                        type="submit"
                        text="Update User"
                        textColor={colors.white}
                        btnStyle="solid"
                        hoverColor={colors.secondary900}
                        disabled={disabled || isSubmitting || !dirty}
                      />
                      <p className="footnote">
                        An asterisk (*) marks a required field.
                      </p>
                    </div>
                  )}
                </Form>
              );
            }}
          </Formik>
        ) : (
          'Not found'
        ))}
    </div>
  );
};
