import React from 'react';
import { withRouter } from 'react-router-dom';
import classNames from 'classnames';

import Icons from 'components/Icons';

import colors from '../../theme/Colors.module.scss';
import './Button.scss';

// This component accepts the following props:
// Type ("button") url (for external links), callback (for custom functions), newWin, text, textColor, icon, iconColor, bgColor, btnStyle (ghost, solid, transparent) and disabled.
// If no additional props for styling are provided, the default is a ghost button with orange border/text

const Button = props => {
  const {
    className,
    icon,
    iconColor,
    iconHeight,
    iconWidth,
    text,
    column,
    history,
    disabled,
    url,
    callback,
    newWin,
    type,
    textColor,
    iconBaseline,
    bgColor,
    btnStyle,
    staticContext,
    hoverColor,
    textHoverColor,
    border,
    opacity,
    boxShadow,
    height,
    width,
    fontSize,
    fontWeight,
    padding,
    borderRadius,
    ...rest
  } = props;

  const buttonRef = React.createRef();
  const getIconMargin = () => {
    if (text && !column) {
      return '0 0.5rem 0 0';
    } else if (!iconBaseline) {
      return '0 0 0.35rem 0';
    }
  };

  const buildIcon = () => {
    const iconStyle = {
      fontSize: '1rem',
      color: iconColor ? iconColor : colors.primary900,
      margin: getIconMargin(),
    };

    const backIcon = (
      <i className="fas fa-arrow-circle-left" style={iconStyle}></i>
    );
    const nextIcon = (
      <i className="fas fa-arrow-circle-right" style={iconStyle}></i>
    );
    const addIcon = <i className="fas fa-plus-circle" style={iconStyle}></i>;
    const removeIcon = (
      <i className="fas fa-times-circle" style={iconStyle}></i>
    );
    const deleteIcon = <i className="fas fa-trash" style={iconStyle}></i>;
    const uploadIcon = <i className="fas fa-file-upload" style={iconStyle}></i>;
    const downloadIcon = (
      <i className="fas fa-file-download" style={iconStyle} />
    );
    const searchIcon = <i className="fas fa-search" style={iconStyle}></i>;
    const archiveIcon = <i className="fas fa-archive" style={iconStyle}></i>;
    const undoIcon = <i className="fas fa-undo" style={iconStyle}></i>;
    const editIcon = <i className="fas fa-pen-square" style={iconStyle}></i>;
    const viewIcon = <i className="fas fa-binoculars" style={iconStyle}></i>;
    const submitIcon = <i className="fas fa-paper-plane" style={iconStyle}></i>;
    const imageIcon = <i className="fas fa-image" style={iconStyle} />;
    const bgImageIcon = <i className="far fa-images" style={iconStyle} />;
    const textIcon = <i className="fas fa-paragraph" style={iconStyle} />;
    const debugIcon = <i className="fas fa-file-alt" style={iconStyle} />;
    const clearIcon = <i className="fas fa-eraser" style={iconStyle} />;
    const leftAlignIcon = <i className="fas fa-align-left" style={iconStyle} />;
    const centerAlignIcon = (
      <i className="fas fa-align-center" style={iconStyle} />
    );
    const rightAlignIcon = (
      <i className="fas fa-align-right" style={iconStyle} />
    );
    const signOutIcon = <i className="fas fa-sign-out-alt" style={iconStyle} />;
    const signInIcon = <i className="fas fa-sign-in-alt" style={iconStyle} />;
    const pencilRulerIcon = (
      <i className="fas fa-pencil-ruler" style={iconStyle} />
    );
    const expandIcon = <i className="fas fa-angle-right" style={iconStyle} />;
    const collapseIcon = <i className="fas fa-angle-left" style={iconStyle} />;
    const closeIcon = <i className="fas fa-times" style={iconStyle} />;
    const linkIcon = <i className="fas fa-link rotate-45" style={iconStyle} />;
    const previewIcon = <i className="fas fa-binoculars" style={iconStyle} />;
    const portraitIcon = (
      <i
        className="fas fa-square"
        style={{ ...iconStyle, transform: 'scale(1, 1.5)' }}
      />
    );
    const landscapeIcon = (
      <i
        className="fas fa-square"
        style={{ ...iconStyle, transform: 'scale(1.5, 1)' }}
      />
    );
    const keyboardIcon = <i className="fas fa-keyboard" style={iconStyle} />;
    const copyIcon = <i className="fas fa-clone" style={iconStyle} />;
    const qrIcon = <i className="fas fa-qrcode" style={iconStyle} />;
    const bgIcon = <i className="fas fa-object-group" style={iconStyle} />;
    const templatesIcon = (
      <i
        className="fas fa-money-check"
        style={{ ...iconStyle, transform: 'scaleX(0.5)' }}
      />
    );
    const scaleIcon = <i className="fas fa-arrows-alt-h" style={iconStyle} />;
    const exportIcon = (
      <Icons.Export style={iconStyle} height={iconHeight} width={iconWidth} />
    );

    const setIcon = () => {
      if (icon === 'back') {
        return backIcon;
      }
      if (icon === 'next') {
        return nextIcon;
      }
      if (icon === 'add') {
        return addIcon;
      }
      if (icon === 'remove') {
        return removeIcon;
      }
      if (icon === 'delete') {
        return deleteIcon;
      }
      if (icon === 'upload') {
        return uploadIcon;
      }
      if (icon === 'download') return downloadIcon;
      if (icon === 'archive') {
        return archiveIcon;
      }
      if (icon === 'edit') {
        return editIcon;
      }
      if (icon === 'search') {
        return searchIcon;
      }
      if (icon === 'undo') {
        return undoIcon;
      }
      if (icon === 'view') {
        return viewIcon;
      }
      if (icon === 'submit') return submitIcon;
      if (icon === 'image') return imageIcon;
      if (icon === 'bgImage') return bgImageIcon;
      if (icon === 'text') return textIcon;
      if (icon === 'debug') return debugIcon;
      if (icon === 'clear') return clearIcon;
      if (icon === 'leftAlign') return leftAlignIcon;
      if (icon === 'centerAlign') return centerAlignIcon;
      if (icon === 'rightAlign') return rightAlignIcon;
      if (icon === 'signOut') return signOutIcon;
      if (icon === 'signIn') return signInIcon;
      if (icon === 'pencilRuler') return pencilRulerIcon;
      if (icon === 'expand') return expandIcon;
      if (icon === 'collapse') return collapseIcon;
      if (icon === 'close') return closeIcon;
      if (icon === 'link') return linkIcon;
      if (icon === 'preview') return previewIcon;
      if (icon === 'portrait') return portraitIcon;
      if (icon === 'landscape') return landscapeIcon;
      if (icon === 'keyboard') return keyboardIcon;
      if (icon === 'copy') return copyIcon;
      if (icon === 'qr') return qrIcon;
      if (icon === 'bg') return bgIcon;
      if (icon === 'templates') return templatesIcon;
      if (icon === 'scale') return scaleIcon;
      if (icon === 'export') return exportIcon;
      return <i className={`fas fa-${icon}`} style={iconStyle} />;
    };

    if (icon && typeof icon === 'object' && icon.type === 'svg') {
      const { children } = icon.props;
      const svgStyle = {
        fill: iconColor || colors.grey,
        margin: getIconMargin(),
      };

      return (
        <svg {...icon.props} style={svgStyle}>
          {children}
        </svg>
      );
    }

    if (icon) return typeof icon == 'string' ? setIcon() : icon;
  };

  const changeHistoryState = () => {
    if (!disabled) {
      history.push(url);
    }
  };

  const loadDestination = e => {
    if (!disabled) {
      if (callback) {
        callback(e);
      } else {
        // () => window.open(url, newWin ? '_blank' : '_self');
      }
    }
  };

  const buildButton = () => {
    const setBGColor = () => {
      if (bgColor) {
        return disabled ? colors.greyDisabled : bgColor;
      } else {
        return disabled ? colors.greyDisabled : colors.primary900;
      }
    };

    const setHoverColor = e => {
      const target = e.currentTarget;

      if (hoverColor) {
        target.style.backgroundColor = disabled
          ? colors.greyDisabled
          : hoverColor;
        if (btnStyle === 'transparent') {
          target.style.borderColor = colors.transparent;
        }
        if (btnStyle === 'solid') {
          target.style.borderColor = disabled
            ? colors.greyDisabled
            : hoverColor;
        }
        if (btnStyle === 'border') {
          target.style.borderColor = disabled
            ? colors.greyDisabled
            : setBGColor();
        }
        if (!disabled) {
          target.style.color = textHoverColor ? textHoverColor : 'currentcolor';
        }
      }
    };

    const removeHoverColor = e => {
      const target = e.currentTarget;

      if (hoverColor) {
        target.style.backgroundColor =
          btnStyle === 'solid' || btnStyle === 'border'
            ? setBGColor()
            : colors.transparent;
        target.style.border =
          border === undefined
            ? `0.125rem solid ${
                btnStyle !== 'transparent' ? setBGColor() : colors.transparent
              }`
            : border || 'none';
        if (!disabled) {
          target.style.color = textColor ? textColor : colors.grey;
        }
      }
    };

    const reactStyle = {
      flexDirection: column ? 'column' : 'row',
      color: textColor ? textColor : colors.primary900,
      backgroundColor:
        btnStyle === 'solid' || btnStyle === 'border'
          ? setBGColor()
          : colors.transparent,
      border:
        border === undefined
          ? `0.125rem solid ${
              btnStyle !== 'transparent' ? setBGColor() : colors.transparent
            }`
          : border || 'none',
      cursor: disabled ? 'not-allowed' : 'pointer',
      opacity: opacity !== undefined ? opacity : disabled ? '0.5' : '1',
      transition: 'all 0.2s ease',
      whiteSpace: 'pre',
      width: icon && !text ? '41px' : width ? width : undefined,
      boxShadow: boxShadow ? boxShadow : 'none',
      height: height ? height : 'unset',
      fontSize: fontSize ? fontSize : '1rem',
      fontWeight: fontWeight ? fontWeight : '300',
      padding: padding ? padding : '0.65rem',
      borderRadius: borderRadius ? borderRadius : '0.35rem',
    };

    const setMouseDownColor = ev => {
      const target = ev.currentTarget;
      const color = disabled
        ? colors.greyDisabled
        : hoverColor
        ? `${hoverColor}b3`
        : btnStyle === 'solid' || btnStyle === 'border'
        ? `${setBGColor()}b3`
        : colors.transparent;

      target.style.backgroundColor = color;

      const btnBorderColor =
        btnStyle !== 'transparent'
          ? disabled
            ? colors.greyDisabled
            : hoverColor
            ? `${hoverColor}b3`
            : btnStyle === 'solid' || btnStyle === 'border'
            ? `${setBGColor()}66`
            : colors.transparent
          : colors.transparent;

      target.style.borderColor = btnBorderColor;
    };

    return (
      <button
        ref={buttonRef}
        type={type}
        style={reactStyle}
        onClick={
          url && url.charAt(0) === '/' ? changeHistoryState : loadDestination
        }
        onMouseOver={setHoverColor}
        onMouseOut={removeHoverColor}
        disabled={disabled}
        onMouseDown={setMouseDownColor}
        {...rest}
        className={classNames('button', className)}
      >
        {buildIcon()}
        {text}
      </button>
    );
  };

  return <React.Fragment>{buildButton()}</React.Fragment>;
};

export default withRouter(Button);
