import React from 'react';

import EntityTableCellEmpty from './EntityTableCellEmpty';

export default ({ error, headers, fillRight }) => {
  return (
    <tr>
      <td colSpan={headers.length} className="EntityTable-error">
        <div className="EntityTable-error-image" />
        <label className="EntityTable-error-title">
          An error occurred while loading this page
        </label>
      </td>
      {fillRight && <EntityTableCellEmpty />}
    </tr>
  );
};
