import { apiActions } from '../api/apiDuck';

export const getTemplateById = (id, type, successCallback, failureCallback) => {
  return apiActions.apiAction({
    path: `templates/${id}`,
    type,
    method: 'GET',
    onSuccess: res => () => successCallback(res),
    onFailure: error => () => failureCallback(error),
  });
};

export const updateTemplateById = (
  id,
  formData,
  type,
  successCallback,
  failureCallback
) => {
  // For debugging
  // for (let key of formData.entries()) {
  //   console.log(`${key[0]}: ${key[1]}`);
  // }

  return apiActions.apiAction({
    path: `templates/${id}`,
    type,
    method: 'PUT',
    isUpload: true,
    'Content-Type': 'multipart/form-data',
    body: formData,
    onSuccess: res => successCallback(res),
    onFailure: error => () => failureCallback(error),
  });
};

export const createTemplate = (
  formData,
  type,
  successCallback,
  failureCallback
) => {
  // For debugging
  // for (let key of formData.entries()) {
  //   console.log(`${key[0]}: ${key[1]}`);
  // }

  return apiActions.apiAction({
    path: 'templates',
    type,
    method: 'POST',
    isUpload: true,
    'Content-Type': 'multipart/form-data',
    body: formData,
    onSuccess: res => () => successCallback(res),
    onFailure: error => () => failureCallback(error),
  });
};

export const createTemplateDoubleSided = (
  payload,
  type,
  successCallback,
  failureCallback,
  dataType
) => {
  if (dataType === 'json') {
    return apiActions.apiAction({
      path: 'templates',
      type,
      method: 'POST',
      body: JSON.stringify(payload),
      onSuccess: res => () => successCallback(res),
      onFailure: error => () => failureCallback(error),
    });
  }

  return apiActions.apiAction({
    path: 'templates',
    type,
    method: 'POST',
    isUpload: true,
    'Content-Type': 'multipart/form-data',
    body: payload,
    onSuccess: res => () => successCallback(res),
    onFailure: error => () => failureCallback(error),
  });
};

export const updateTemplateDoubleSidedById = (
  id,
  payload,
  type,
  successCallback,
  failureCallback,
  dataType
) => {
  if (dataType === 'json') {
    return apiActions.apiAction({
      path: `templates/${id}`,
      type,
      method: 'PUT',
      body: JSON.stringify(payload),
      onSuccess: res => () => successCallback(res),
      onFailure: error => () => failureCallback(error),
    });
  }

  return apiActions.apiAction({
    path: `templates/${id}`,
    type,
    method: 'PUT',
    isUpload: true,
    'Content-Type': 'multipart/form-data',
    body: payload,
    onSuccess: res => () => successCallback(res),
    onFailure: error => () => failureCallback(error),
  });
};

export const getTemplatePreview = (formData, type, doneCallback) => {
  // For debugging
  // for (let key of formData.entries()) {
  //   console.log(`${key[0]}: ${key[1]}`);
  // }

  return apiActions.apiAction({
    path: `fulfillments/preview`,
    type,
    method: 'POST',
    isUpload: true,
    'Content-Type': 'multipart/form-data',
    body: formData,
    onSuccess: res => () => doneCallback(res),
    onFailure: () => () => doneCallback(),
  });
};

export const getPremadeTemplates = (type, successCallback, failureCallback) => {
  return apiActions.apiAction({
    path: `template_samples`,
    type,
    method: 'GET',
    onSuccess: res => () => successCallback(res),
    onFailure: error => () => failureCallback(error),
  });
};

export const getEnabledDoubleSideStatus = (
  type,
  successCallback,
  failureCallback
) => {
  return apiActions.apiAction({
    path: `feature_settings`,
    type,
    method: 'GET',
    onSuccess: res => () => successCallback(res),
    onFailure: error => () => failureCallback(error),
  });
};

export const copyPremadeTemplate = (payload, type) => {
  return apiActions.apiAction({
    path: `template_copy`,
    type,
    method: 'POST',
    body: JSON.stringify(payload),
  });
};

export const getMruVariableKeys = (type, successCallback, failureCallback) => {
  return apiActions.apiAction({
    path: `ad_template_var_keys/mru?size=10`,
    type,
    method: 'GET',
    onSuccess: () => () => successCallback(),
    onFailure: error => () => failureCallback(error),
  });
};

export const getUserFonts = (type, successCallback, failureCallback) => {
  return apiActions.apiAction({
    // TODO: Change this hard-coded size to use params.
    path: `fonts?size=100&scope=all&grouped`,
    type,
    method: 'GET',
    onSuccess: res => () => successCallback(res),
    onFailure: error => () => failureCallback(error),
  });
};

export const getUserFontById = (id, type, doneCallback) => {
  return apiActions.apiAction({
    path: `fonts/${id}`,
    type,
    method: 'GET',
    onSuccess: res => () => doneCallback(res),
  });
};

export const addUserFont = (payload, type, doneCallback, failureCallback) => {
  return apiActions.apiAction({
    path: 'fonts',
    type,
    method: 'POST',
    isUpload: true,
    body: payload,
    onSuccess: res => () => doneCallback(res),
    onFailure: error => () => failureCallback && failureCallback(error),
  });
};

export const updateUserFont = (id, payload, type) => {
  return apiActions.apiAction({
    path: `fonts/${id}`,
    type,
    method: 'PUT',
    body: JSON.stringify(payload),
  });
};

export const removeUserFont = (id, type, doneCallback, failureCallback) => {
  return apiActions.apiAction({
    path: `fonts/${id}`,
    type,
    method: 'DELETE',
    onSuccess: () => () => doneCallback(),
    onFailure: error => () => failureCallback && failureCallback(error),
  });
};
