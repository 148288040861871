import React from 'react';
import TextField from 'components/TextField/TextField';
import Button from 'components/Button/Button';
import colors from 'theme/Colors.module.scss';
import './Filter.scss';

const Filter = props => {
  const { placeholder, value, onChange } = props;

  const updateValue = e => {
    const value = e.currentTarget.value;
    onChange && onChange(value);
  };

  return (
    <TextField
      placeholder={placeholder}
      value={value}
      onChange={updateValue}
      startIcon={<i className="fas fa-search" />}
      endIcon={
        <Button
          icon="close"
          iconColor={colors.grey}
          iconBaseline
          btnStyle="transparent"
          callback={() => onChange('')}
          disabled={!value}
          opacity={value ? 1 : 0}
        />
      }
    />
  );
};

export default Filter;
