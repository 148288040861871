import React from 'react';
import { Field, getIn } from 'formik';
import './InputFeedback.scss';

const InputFeedback = ({ name, touchOptional }) => {
  if (!name) return null;
  return (
    <Field
      name={name}
    >
      {({ form }) => {
        const error = getIn(form.errors, name);
        const touch = getIn(form.touched, name) || touchOptional;
        const msg = typeof error === 'string' ? error : null;
        return touch && error ? (
          <div className="input-feedback">{msg}</div>
        ) : null;
      }}
    </Field>
  );
};
export default InputFeedback;
