export const checkCampaignStatus = campaign => {
  if (campaign.enabled === false) return false;
  else {
    if (campaign.start_date === null && campaign.end_date === null) {
      return true;
    }
    if (
      campaign.start_date !== null &&
      new Date(campaign.start_date) > new Date()
    )
      return false;
    if (campaign.end_date !== null && new Date(campaign.end_date) < new Date())
      return false;
    if (
      campaign.end_date !== null &&
      campaign.start_date !== null &&
      new Date(campaign.start_date) <= new Date() &&
      new Date(campaign.end_date) >= new Date()
    )
      return true;
  }
};

export const formatAOV = average => {
  let currency_format = {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
  };
  return Number(average).toLocaleString('en-US', currency_format);
};

export const formatPercentage = percent => {
  let numberFormatter = new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 0,
    maximumFractionDigits: 1,
  });
  return numberFormatter.format(percent * 100);
};

export const getTemplateThumb = template => {
  if (template?.ad_template_pages?.length > 0) {
    const pages = template.ad_template_pages;
    const page1 = pages.find(x => x.page_num === 1);
    if (page1 && page1.bg_img?.thumb_url) {
      return page1.bg_img.thumb_url;
    }

    const page2 = pages.find(x => x.page_num === 2);
    if (page2 && page2.bg_img?.thumb_url) {
      return page2.bg_img.thumb_url;
    }
  }

  return template?.bg_img?.thumb_url;
};
