import React, { useRef, useCallback } from 'react';
import classNames from 'classnames';
import { useFlag } from 'flags';
import { advanceSortDirection } from 'helpers/paramHelpers';

import DirectionIcon from 'components/DirectionIcon/DirectionIcon';

import colors from 'theme/Colors.module.scss';
import './Headers.scss';

export default props => {
  const { headers, sort, onSort, className } = props;

  const isReadOnly = useFlag(['features', 'isReadOnly']);
  const resizerRef = useRef();
  const resizerEle = resizerRef.current;
  const inScale =
    document.getElementsByClassName('EntityTable-table-table')[0]
      ?.offsetWidth <=
    300 + 160 + 140 * 8;

  const getFieldSort = field => {
    return (sort || []).find(sortItem => sortItem.field === field);
  };

  const handleSort = header => {
    if (!header.sortable) return;
    const field = header.field;
    const fieldSort = getFieldSort(field);
    let direction = advanceSortDirection(fieldSort?.direction || null);

    if (direction) {
      if (header.sortNullsFirst) {
        direction += '-nulls-first';
      } else if (header.sortNullsLast) {
        direction += '-nulls-last';
      }

      onSort([
        {
          field,
          direction,
        },
      ]);
    } else {
      onSort([]);
    }
  };

  // make header resizable
  // https://phuoc.ng/collection/react-drag-drop/resize-columns-in-a-table/
  const handleMouseDown = useCallback(
    e => {
      if (!resizerEle) return;

      const startPos = {
        x: e.clientX,
      };
      const parent = resizerEle.parentElement.parentElement;
      const styles = window.getComputedStyle(parent);
      const w = parseInt(styles.width, 10);

      const handleMouseMove = e => {
        let tableWidth =
          document.getElementsByClassName('EntityTable-table-table')[0]
            .offsetWidth - 2;
        // let scale = tableWidth / (w + 160 + 140 * 8); // calculation includes other fields
        let scale = tableWidth / (w + 160 + 140 * 5);
        const dx = e.clientX - startPos.x;
        if (inScale) {
          if (w + dx >= headers[0].width && w + dx <= headers[0].maxWidth) {
            parent.style.width = `${w + dx}px`;
          }
        } else {
          if (
            w / scale + dx >= headers[0].width &&
            w / scale + dx <= headers[0].maxWidth
          ) {
            parent.style.width = `${w / scale + dx}px`;
          }
        }
        updateCursor();
      };
      const handleMouseUp = () => {
        document.removeEventListener('mousemove', handleMouseMove);
        document.removeEventListener('mouseup', handleMouseUp);
        resetCursor();
      };
      document.addEventListener('mousemove', handleMouseMove);
      document.addEventListener('mouseup', handleMouseUp);
    },
    [resizerEle, inScale, headers]
  );

  // handleTouchStart is for toiuch devices only
  const handleTouchStart = useCallback(
    e => {
      if (!resizerEle) return;

      const touch = e.touches[0];
      const startPos = {
        x: touch.clientX,
      };
      const parent = resizerEle.parentElement.parentElement;
      const styles = window.getComputedStyle(parent);
      const w = parseInt(styles.width, 10);

      const handleTouchMove = e => {
        let tableWidth =
          document.getElementsByClassName('EntityTable-table-table')[0]
            .offsetWidth - 2;
        // let scale = tableWidth / (w + 160 + 140 * 8); // calculation includes other fields
        let scale = tableWidth / (w + 160 + 140 * 5);
        const touch = e.touches[0];
        const dx = touch.clientX - startPos.x;
        if (inScale) {
          if (w + dx >= headers[0].width && w + dx <= headers[0].maxWidth) {
            parent.style.width = `${w + dx}px`;
          }
        } else {
          if (
            w / scale + dx >= headers[0].width &&
            w / scale + dx <= headers[0].maxWidth
          ) {
            parent.style.width = `${w / scale + dx}px`;
          }
        }
        updateCursor();
      };

      const handleTouchEnd = () => {
        document.removeEventListener('touchmove', handleTouchMove);
        document.removeEventListener('touchend', handleTouchEnd);
        resetCursor();
      };

      document.addEventListener('touchmove', handleTouchMove);
      document.addEventListener('touchend', handleTouchEnd);
    },
    [resizerEle, inScale, headers]
  );

  const resetCursor = () => {
    document.body.style.removeProperty('cursor');
    document.body.style.removeProperty('user-select');
  };

  const updateCursor = () => {
    document.body.style.cursor = 'col-resize';
    document.body.style.userSelect = 'none';
  };

  return (
    <tr
      className={`headerLabels ${className || ''} ${isReadOnly && 'readOnly'}`}
    >
      {headers.map((header, index) => {
        const headerSort = getFieldSort(header.field);
        return (
          <th
            key={index}
            className={classNames(
              'header',
              `header-align-${
                header.field === 'delivery_limit'
                  ? 'center'
                  : header.align || 'left'
              }`,
              {
                headerSortable: header.sortable,
                'header-hasIndicator': header.hasIndicator,
              },
              `${header.field === sort[0]?.field ? `header-active` : ''}`
            )}
            style={{
              width: header.width,
              whiteSpace: header.whiteSpace || 'unset',
            }}
            onClick={() => handleSort(header)}
          >
            <div style={{ display: 'inline-flex', alignItems: 'center' }}>
              {header.name}
              {header.sortable && headerSort && (
                <DirectionIcon
                  type={header.sortIcon}
                  direction={headerSort?.direction || 'asc'}
                  style={{
                    color: headerSort ? colors.primary900 : colors.neutral300,
                  }}
                />
              )}
              {header.sortable && !headerSort && (
                <DirectionIcon
                  type={header.sortIcon}
                  direction={'normal'}
                  style={{
                    color: headerSort ? colors.primary900 : colors.neutral300,
                  }}
                />
              )}
              {header.resizable && (
                <div
                  ref={resizerRef}
                  onMouseDown={handleMouseDown}
                  onTouchStart={handleTouchStart}
                  className={'resizer'}
                ></div>
              )}
            </div>
          </th>
        );
      })}
    </tr>
  );
};
