import {
  Box,
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
} from '@chakra-ui/react';
import React from 'react';
import Icons from 'components/Icons';
import colors from 'theme/Colors.module.scss';

export default function UploadImageConfirmModal({
  isOpen,
  onClose,
  onConfirm,
  onCancel,
}) {
  return (
    <Modal isCentered isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent
        sx={{
          width: '800px',
          paddingBottom: '1rem',
        }}
      >
        <Box>
          <Button
            sx={{
              position: 'absolute',
              top: '0.5rem',
              right: '0.5rem',
              background: 'white',
              cursor: 'pointer',
              padding: 0,
              minWidth: 0,
              width: '30px',
              height: '30px',
            }}
            _hover={{ background: '#F0F0F0' }}
            onClick={onClose}
          >
            <Icons.Close height={12} />
          </Button>
        </Box>
        <ModalHeader fontWeight={600} size="18px" textAlign="center" pb="0">
          Do you still wish to continue?
        </ModalHeader>

        <ModalBody>
          <Text size="1rem" textAlign="center">
            The image you are about to upload’s file size is larger than 2 MB.
            We recommend compressing the image down to a smaller size. <br />
            {/* Do you still wish to continue? */}
          </Text>

          <Flex align="center" mt="10px">
            <Button
              onClick={onConfirm}
              flex={1}
              color={colors.white}
              bgColor={colors.primary900}
              _hover={{ bgColor: colors.secondary900 }}
            >
              Yes
            </Button>
            <Button
              onClick={onCancel}
              flex={1}
              ml="1rem"
              color={colors.white}
              bgColor={colors.primary900}
              _hover={{ bgColor: colors.secondary900 }}
            >
              No
            </Button>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
