import React, { useEffect, useState } from 'react';
import Icons from '../../../Icons';
import {
  Box,
  IconButton,
  Popover,
  PopoverTrigger,
  PopoverContent,
  Tooltip,
  Slider,
  SliderTrack,
  SliderFilledTrack,
  SliderThumb,
  Input,
} from '@chakra-ui/react';
import { useEditor, useActiveObject } from 'components/Canvas';

function Opacity() {
  const [options, setOptions] = useState({ opacity: 100 });
  const activeObject = useActiveObject();
  const editor = useEditor();

  useEffect(() => {
    if (activeObject) {
      updateOptions(activeObject);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeObject]);

  const updateOptions = object => {
    const { opacity } = object;
    setOptions({ ...options, opacity: opacity * 100 });
    // editor.update({ opacity: opacity / 100 })
  };

  const updateOpacity = value => {
    setOptions({ ...options, opacity: value });
    editor.update({ opacity: value / 100 });
  };

  return (
    <Popover placement="bottom-end">
      <Tooltip placement="bottom" label="Opacity">
        <Box display="inline-block">
          <PopoverTrigger>
            <IconButton
              color="#000000"
              borderRadius="0"
              variant="ghost"
              size="sm"
              icon={<Icons.Opacity size={24} />}
            />
          </PopoverTrigger>
        </Box>
      </Tooltip>

      <PopoverContent sx={{ padding: '1.5rem' }}>
        <Box
          name="opacitySlice"
          sx={{
            display: 'grid',
            gridTemplateColumns: '60px 1fr 40px',
            alignItems: 'center',
            fontSize: '14px',
            gap: '0.5rem',
          }}
        >
          <Box>Opacity</Box>
          <Slider
            onChange={value => updateOpacity(value)}
            min={0}
            max={100}
            colorScheme="gray"
            size="sm"
            value={options.opacity}
          >
            <SliderTrack>
              <SliderFilledTrack />
            </SliderTrack>
            <SliderThumb
              boxSize={4}
              borderWidth="2px"
              borderColor="white"
              backgroundColor="black"
            />
          </Slider>
          <Box>
            <Input
              textAlign="center"
              onChange={() => {}}
              value={options.opacity}
              size="xs"
            />
          </Box>
        </Box>
      </PopoverContent>
    </Popover>
  );
}

export default Opacity;
