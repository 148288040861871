
const Templates = ({ width = 16, height = 16 }) => (
  <svg width={width} height={height} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1 1.875C1 1.39175 1.39175 1 1.875 1H14.125C14.6082 1 15 1.39175 15 1.875V3.625C15 4.10825 14.6082 4.5 14.125 4.5H1.875C1.39175 4.5 1 4.10825 1 3.625V1.875Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M1 8.875C1 8.39175 1.39175 8 1.875 8H7.125C7.60825 8 8 8.39175 8 8.875V14.125C8 14.6082 7.60825 15 7.125 15H1.875C1.39175 15 1 14.6082 1 14.125V8.875Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M11.5 8.875C11.5 8.39175 11.8918 8 12.375 8H14.125C14.6082 8 15 8.39175 15 8.875V14.125C15 14.6082 14.6082 15 14.125 15H12.375C11.8918 15 11.5 14.6082 11.5 14.125V8.875Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
);

export default Templates;
