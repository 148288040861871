import EntityTableTable from './EntityTableTable';
import EntityTableProgress from './EntityTableProgress';
import EntityTableHead from './EntityTableHead';
import EntityTableBody from './EntityTableBody';
import EntityTableFoot from './EntityTableFoot';
import EntityTableRowEmpty from './EntityTableRowEmpty';
import EntityTableCellEmpty from './EntityTableCellEmpty';
import EntityTableError from './EntityTableError';
import EntityTableNoResults from './EntityTableNoResults';
import EntityTablePagination from './EntityTablePagination';

export default {
  Table: EntityTableTable,
  TableProgress: EntityTableProgress,
  TableToolbar: null,
  TableHead: EntityTableHead,
  TableBody: EntityTableBody,
  TableFoot: EntityTableFoot,
  TableRowEmpty: EntityTableRowEmpty,
  TableCellEmpty: EntityTableCellEmpty,
  TableError: EntityTableError,
  TableNoResults: EntityTableNoResults,
  TablePagination: EntityTablePagination,
  TableRefreshButton: null,
};
