import { apiActions } from '../api/apiDuck';

export const getStats = (params, type) => {
  return apiActions.apiAction({
    path: `campaigns/stats${params}`,
    type: type,
    method: 'GET',
  });
};

export const getPrintStates = (params, type) => {
  return apiActions.apiAction({
    path: `campaigns/stats/prints${params}`,
    type: type,
    method: 'GET',
  });
};

export const getPrintedOrderStates = (params, type) => {
  return apiActions.apiAction({
    path: `campaigns/stats/printed-orders${params}`,
    type: type,
    method: 'GET',
  });
};

export const getQrCodesStats = (params, type) => {
  return apiActions.apiAction({
    path: `links/stats${params}`,
    type: type,
    method: 'GET',
  });
};
