import React, { useEffect, useRef } from 'react';
import { fabric } from 'fabric';
import { Box, Flex } from '@chakra-ui/react';
import { bufferModes } from 'components/Canvas/common/constants';
import useSizeFormat from 'components/Canvas/hooks/useSizeFormat';
import {
  loadBGIntoCanvas,
  calcFitRatio,
  SCALE_FACTOR,
} from 'helpers/canvasHelpers';

const bleed = 0;

function PreviewDoubleSided({ previewImages }) {
  const previewRef = useRef();
  const secondPreviewRef = useRef();
  const sizeFormat = useSizeFormat();

  const autoFit = dimension => {
    // This autoFit function is different from others -- uses previewMode and returns a dimension.
    const format = sizeFormat;
    const newWidth =
      (sizeFormat.isPortrait ? format.pixelWidth : format.pixelHeight) *
      SCALE_FACTOR;
    const newHeight =
      (sizeFormat.isPortrait ? format.pixelHeight : format.pixelWidth) *
      SCALE_FACTOR;
    const calcFitArgs = {
      previewMode: true,
      fitMode: bufferModes[0],
      canvasDimensions: { width: newWidth, height: newHeight },
    };

    const { width, height } = calcFitArgs.canvasDimensions;
    const fitRatio = calcFitRatio(calcFitArgs);
    const zoomedDimensions = {
      width: Math.round(width * fitRatio.zoomVal) + bleed,
      height: Math.round(height * fitRatio.zoomVal) + bleed,
    };

    return dimension === 'width'
      ? zoomedDimensions.width
      : zoomedDimensions.height;
  };

  const makePreviewCanvas = (image, reference) => {
    const mainHeight = autoFit('height');
    const mainWidth = autoFit('width');

    return new Promise(resolve => {
      fabric.Image.fromURL(image, fabricImage => {
        const { width, height } = fabricImage;
        const dimensions = {
          canvasWidth: width,
          canvasHeight: height,
          width: mainWidth,
          height: mainHeight,
        };

        const myPreviewCanvas = new fabric.Canvas(
          reference.current,
          dimensions
        );
        // setCanvasPreview(myPreviewCanvas);
        const conf = {
          top: myPreviewCanvas.height / 2,
          left: myPreviewCanvas.width / 2,
          width,
          height,
          scaleX:
            myPreviewCanvas[sizeFormat.isPortrait ? 'width' : 'height'] / width,
          scaleY:
            myPreviewCanvas[sizeFormat.isPortrait ? 'height' : 'width'] /
            height,
          originX: 'center',
          originY: 'center',
        };

        loadBGIntoCanvas(fabricImage, conf, myPreviewCanvas).then(resolve);

        !sizeFormat.isPortrait && myPreviewCanvas.backgroundImage.rotate(-90);

        // TODO: Ask if the stitcher is aware of background color.
        // It seems to always returns white as the background color.
        myPreviewCanvas.backgroundColor = '#ffffff';
        myPreviewCanvas.requestRenderAll();
      });
    });
  };

  useEffect(() => {
    if (previewImages) {
      makePreviewCanvas(previewImages[0], previewRef);
      makePreviewCanvas(previewImages[1], secondPreviewRef);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [previewImages]);
  return (
    <Box>
      {sizeFormat.isPortrait === true ? (
        <Flex justifyContent="space-between" paddingX="2rem" paddingY="1rem">
          <Box>
            <h1>Front Side</h1>
            <canvas style={{ border: '1px solid #B2BEAC' }} ref={previewRef} />
          </Box>
          {previewImages.length > 1 && (
            <Box sx={{ marginLeft: '1rem' }}>
              <h1>Back Side</h1>
              <canvas
                style={{ border: '1px solid #B2BEAC' }}
                ref={secondPreviewRef}
              />
            </Box>
          )}
        </Flex>
      ) : (
        <Box sx={{ overflow: 'auto', height: '750px' }} padding="3rem">
          <Box>
            <h1>Front Side</h1>
            <canvas style={{ border: '1px solid #B2BEAC' }} ref={previewRef} />
          </Box>
          {previewImages.length > 1 && (
            <Box sx={{ marginTop: '0.5rem' }}>
              <h1>Back Side</h1>
              <canvas
                style={{ border: '1px solid #B2BEAC' }}
                ref={secondPreviewRef}
              />
            </Box>
          )}
        </Box>
      )}
    </Box>
  );
}

export default PreviewDoubleSided;
