class ZoomController {
  handler
  constructor(handler) {
    this.handler = handler
  }

  zoomIn = () => {
    this.handler.zoomIn()
  }
  zoomOut = () => {
    this.handler.zoomOut()
  }
  zoomToOne = () => {
    this.handler.zoomToOne()
  }
  zoomToFit = () => {
    this.handler.zoomToFit()
  }
  zoomToRatio = (zoomRatio) => {
    this.handler.zoomToRatio(zoomRatio)
  }
}

export default ZoomController
