import React, { useEffect, useRef, createRef, memo } from 'react';
import Chart from 'chart.js';
import { sharedOptions } from 'helpers/chartHelpers';
import { isEqual } from 'lodash';
import './Graph.scss';
import colors from 'theme/Colors.module.scss';

const Graph = props => {
  let myChart = useRef();
  const { dataSet, customOptions, tabOption } = props;
  const { data } = dataSet;
  const ref = createRef();

  useEffect(() => {
    buildGraph();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataSet, tabOption]);

  const buildDataSets = () => {
    const datasets = [];
    data.forEach(item => {
      datasets.push({
        label: item.title,
        type: item.type,
        stack: item.stack,
        backgroundColor:
          tabOption.value === 'prints'
            ? colors.primary100
            : colors.secondary100,
        hoverBackgroundColor:
          tabOption.value === 'prints'
            ? colors.primary900
            : colors.secondary900,
        data: item.values,
      });
    });
    return datasets;
  };

  const buildGraph = () => {
    const xLabels = dataSet.xLabels;
    const width = ref.current.parentElement.clientWidth;
    const height = ref.current.parentElement.clientHeight;

    ref.current.width = width;
    ref.current.height = height;

    const ctx = ref.current;

    const data = {
      labels: xLabels,
      datasets: buildDataSets(),
    };
    const options = {
      // onClick: (e, a) => (a[0] && pointRoute) && history.push(pointRoute),
      // onHover: (e, a) => ref.current && ref.current.style.setProperty('cursor', a[0] ? 'pointer' : 'default'),
    };

    if (myChart.current) myChart.current.destroy();
    myChart.current = new Chart(ctx, {
      type: 'bar',
      data: data,
      options: {
        ...sharedOptions,
        ...options,
        ...customOptions,
      },
    });

    return myChart.current;
  };

  return <canvas id="dash-chart" height="420px" ref={ref} />;
};

const areEqual = (prevProps, nextProps) =>
  isEqual(prevProps.dataSet, nextProps.dataSet) &&
  isEqual(prevProps.tabOption, nextProps.tabOption);

export default memo(Graph, areEqual);
