import { connect } from 'react-redux';
import Login from './Login';
import { apiActions } from '../../modules/api/apiDuck';

import * as appActions from 'modules/app/appActions';
import * as appSelectors from 'modules/app/appSelectors';

function mapStateToProps(state) {
  return {
    // From appContainer
    token: appSelectors.getToken(state),
    apiError: appSelectors.getApiError(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    testError: () => {
      dispatch(
        apiActions.apiAction({
          path: `notfound`,
          type: 'modules/app/LOG_IN',
        })
      );
    },
    clearApiError: actionType => {
      dispatch(apiActions.clearApiError(actionType));
    },

    // From appContainer
    login: (userInfo, doneCallback) =>
      dispatch(appActions.login(userInfo, doneCallback)),
  };
}

const LoginContainer = connect(mapStateToProps, mapDispatchToProps)(Login);

export default LoginContainer;
