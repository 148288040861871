import React from 'react';
import { useMediaQuery } from '@chakra-ui/react';
import SelectFileDropZone from './SelectFileDropZone';
import ConversionProcess from './ConversionProcess';
import ResolveFonts from './ResolveFonts';
import Modal from './Modal';
import ResolveSizeFormat from './ResolveSizeFormat';
import SetTemplateName from './SetTemplateName';
import { showMessages } from 'helpers/messageHelpers';
import { useSelector } from 'react-redux';
import * as appSelectors from 'modules/app/appSelectors';
import { useDispatch } from 'react-redux';
import * as appActions from 'modules/app/appActions';
import { getSessionUser } from 'modules/app/appSelectors';
import UploadMissingFontError from './UploadMissingFontError';

export default function DesignImporterModal({
  open,
  onOpen,
  onClose,
  uploadTemplate,
  sendUploadInformation,
  getDesignStatus,
  resumeDesignImport,
  addUserFont,
  getUserFonts,
  getSizeFormats,
  createTemplate,
  refresh,
}) {
  const [modalState, setModalState] = React.useState({
    title: '',
    description: '',
  });

  const [step, setStep] = React.useState(0);
  const [file, setFile] = React.useState(null);
  const [templateInformation, setTemplateInformation] = React.useState(null);
  const [templateFile, setTemplateFile] = React.useState(null);
  const [fileFormat, setFileFormat] = React.useState(null);
  const [fontsImported, setFontsImported] = React.useState([]);
  const [missingFonts, setMissingFonts] = React.useState([]);
  const [fontsResolved, setFontsResolved] = React.useState({});
  const [designId, setDesignId] = React.useState(null);
  const [isLargerThan750] = useMediaQuery('(min-width: 750px)');
  const [currentFonts, setCurrentFonts] = React.useState([]);
  const [templateHeight, setTemplateHeight] = React.useState(0);
  const [uploadFontError, setUploadFontError] = React.useState({
    isOpen: false,
    uploaded: '',
    target: '',
  });
  const sessionUser = useSelector(getSessionUser);

  const dispatch = useDispatch();

  const messages = useSelector(state => appSelectors.messages(state));

  const setMessage = payload => dispatch(appActions.setMessage(payload));
  const clearMessage = index => dispatch(appActions.clearMessage(index));

  const onSuccess = () =>
    setMessage({
      message: 'Template imported successfully',
      type: 'success',
    });

  const clearState = () => {
    setFile(null);
    setStep(0);
    setTemplateInformation(null);
    setTemplateFile(null);
    setFileFormat(null);
    setMissingFonts([]);
    setFontsImported([]);
    setFontsResolved({});
    setDesignId(null);
  };

  const onModalClose = () => {
    onClose();
    clearState();
  };

  const getFontFilename = (postscriptname, fonts) => {
    const font = fonts.find(x => x.postscriptname === postscriptname);
    return font?.file?.file_name;
  };

  function flattenObject(obj) {
    return Object.keys(obj).reduce((arr, key) => {
      const innerArr = obj[key].map(item => ({
        ...item,
        family: key,
      }));
      return [...arr, ...innerArr];
    }, []);
  }

  const addFontFilenames = (template, currentFonts) => {
    // design_importer is not aware of font filenames so we must add them.  The stitcher
    // uses the __fontFilename key to resolve fonts so here we apply the property to any
    // vars that have a fontFamily key assigned.
    // TODO: font handling should be redone in this whole process later
    const publicFonts = flattenObject(currentFonts.public);
    const fonts = [...currentFonts.private, ...publicFonts];
    const updatedVars = template.ad_template_vars.map(x => {
      if (x.metadata.fontFamily) {
        const fontFn = getFontFilename(x.metadata.postscriptname, fonts);
        if (fontFn && !x.metadata.__fontFilename) {
          x.metadata.__fontFilename = fontFn;
        }
      }
      return x;
    });
    template.ad_template_vars = updatedVars;
    return template;
  };

  const createTemplateWrapper = (payload, doneCb) => {
    createTemplate(addFontFilenames(payload, currentFonts), doneCb);
  };

  const updateMissingFonts = fontMissing => {
    setMissingFonts(fontMissing);
    const missingObj = {};
    fontMissing.map(fm => {
      missingObj[fm] = false;
      return fm;
    });
    setFontsResolved(missingObj);
  };

  const addNewFont = fontMissing => {
    setFontsImported(fontMissing);
  };

  const getFileFormat = templateInformation => {
    const fileFormat = templateInformation.input.key.includes('psd')
      ? 'psd'
      : 'ai';
    setFileFormat(fileFormat);
  };

  const uploadFile = () => {
    const formData = new FormData();
    formData.append('files[0]', file);

    uploadTemplate(formData, fileInformation => {
      const design_file = {
        company_id: sessionUser?.company_id,
        design_file: {
          bucket: fileInformation.items[0].storage.bucket,
          key: fileInformation.items[0].storage.path,
        },
      };
      sendUploadInformation(design_file, templateInformation => {
        setTemplateInformation(templateInformation);
        getFileFormat(templateInformation);
      });
    });
  };

  const getMissingFonts = async templateFile => {
    const userFontsData = await new Promise(resolve =>
      getUserFonts(data => resolve(data))
    );

    const userFonts = userFontsData.data.data;
    setCurrentFonts(userFonts);

    let textLayers = [];
    let fonts = textLayers;
    const concatFonts = Object.values(userFonts.public).concat([
      userFonts.private,
    ]);

    templateFile.ad_template_vars.map(layer => {
      if (layer.res_type === 'txt_template') {
        textLayers.push(layer.metadata.postscriptname);
      }
      return layer;
    });

    textLayers = textLayers.filter((element, index) => {
      return textLayers.indexOf(element) === index;
    });

    //get missing fonts base on account fonts
    textLayers.map(textElement => {
      concatFonts.map(filterFonts => {
        filterFonts.map(filterFont => {
          if (filterFont.postscriptname === textElement) {
            fonts = fonts.filter(element => element !== textElement);
          }
          return filterFont;
        });
        return filterFonts;
      });
      return textElement;
    });

    fonts = fonts.filter((element, index) => {
      return fonts.indexOf(element) === index;
    });

    updateMissingFonts(fonts);

    return fonts;
  };

  // React.useEffect(() => {
  //   const onEscapePressed = ev => ev.key === 'Escape' && onModalClose();
  //   document.addEventListener('keydown', onEscapePressed);
  //   return () => document.removeEventListener('keydown', onEscapePressed);
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  return (
    <>
      <Modal
        open={open}
        onClose={onModalClose}
        step={step}
        modalState={modalState}
        isLargerThan750={isLargerThan750}
      >
        <>
          {!step && (
            <SelectFileDropZone
              file={file}
              setFile={setFile}
              sm={isLargerThan750}
              onImportClicked={() => {
                setStep(1);
                setModalState({ title: 'Uploading', description: '' });
              }}
              setModalState={setModalState}
            />
          )}

          {step === 1 && (
            <ConversionProcess
              uploadFile={uploadFile}
              templateInformation={templateInformation}
              getDesignStatus={getDesignStatus}
              saveTemplateFile={template => {
                setTemplateFile(template);
                setTemplateHeight(template.size_format.pixel_height);
              }}
              designId={designId}
              setDesignId={setDesignId}
              resumeDesignImport={resumeDesignImport}
              setStep={setStep}
              updateMissingFonts={updateMissingFonts}
              fileFormat={fileFormat}
              setModalState={setModalState}
              sm={isLargerThan750}
              getMissingFonts={getMissingFonts}
              currentFonts={currentFonts}
            />
          )}

          {step === 2 && (
            <ResolveFonts
              addNewFont={addNewFont}
              addUserFont={addUserFont}
              setStep={setStep}
              fileFormat={fileFormat}
              fontsImported={fontsImported}
              getUserFonts={getUserFonts}
              missingFonts={missingFonts}
              setTemplateFile={setTemplateFile}
              // template={template}
              templateFile={templateFile}
              updateMissingFonts={updateMissingFonts}
              setModalState={setModalState}
              sm={isLargerThan750}
              fontsResolved={fontsResolved}
              setFontsResolved={setFontsResolved}
              setMessage={setMessage}
              currentFonts={currentFonts}
              setCurrentFonts={setCurrentFonts}
              getMissingFonts={getMissingFonts}
              setUploadFontError={setUploadFontError}
            />
          )}

          {step === 3 && (
            <ResolveSizeFormat
              setModalState={setModalState}
              getSizeFormats={getSizeFormats}
              templateFile={templateFile}
              setTemplateFie={setTemplateFile}
              setStep={setStep}
              sm={isLargerThan750}
              setTemplateFile={setTemplateFile}
              onModalClose={onModalClose}
            />
          )}

          {step === 4 && (
            <SetTemplateName
              setModalState={setModalState}
              setStep={setStep}
              sm={isLargerThan750}
              closeModal={onModalClose}
              templateFile={templateFile}
              createTemplate={createTemplateWrapper}
              refresh={refresh}
              file={file}
              onSuccess={onSuccess}
              templateHeight={templateHeight}
            />
          )}
        </>
      </Modal>
      <UploadMissingFontError
        uploadedFont={uploadFontError.uploaded}
        targetFont={uploadFontError.target}
        isOpen={uploadFontError.isOpen}
        onClose={() =>
          setUploadFontError({ isOpen: false, uploaded: '', target: '' })
        }
      />
      {showMessages(messages, clearMessage)}
    </>
  );
}
