import React from 'react';
import { withRouter } from 'react-router-dom';
// import {
//   paramsFromURL,
//   paramsToObject,
//   paramsToString,
// } from '../../helpers/paramHelpers';
import _ from 'lodash';
import Button from '../Button/Button';
import colors from '../../theme/Colors.module.scss';
import './Pagination.scss';
import { Text } from '@chakra-ui/react';

/* Component requires 'pageData' (Object) prop:
 *  pageData = {
 *    total_pages: 5,
 *    size: 20,
 *    current_page: 2
 *  }
 */

const Pagination = props => {
  const { pageData, onPageChange } = props;
  const buildPrev = () => {
    return (
      <li className="prevBtn">
        <Button
          icon="back"
          iconColor={colors.slate}
          btnStyle="transparent"
          disabled={pageData.current_page === 1}
          callback={() =>
            onPageChange(pageData.current_page - 1, pageData.size)
          }
        />
      </li>
    );
  };

  const buildNext = () => {
    return (
      <li className="nextBtn">
        <Button
          icon="next"
          iconColor={colors.slate}
          btnStyle="transparent"
          disabled={pageData.current_page === pageData.total_pages}
          callback={() =>
            onPageChange(pageData.current_page + 1, pageData.size)
          }
        />
      </li>
    );
  };

  const buildPages = () => {
    const renderContent = [];
    const pages = pageData.total_pages;
    // const size = pageData.page_size;
    const currentPage = pageData.current_page;
    let subtractValue =
      currentPage % pageData.size === 0
        ? pageData.size
        : currentPage % pageData.size;
    for (
      let i = currentPage - subtractValue + 1;
      i <= Math.min(pages, currentPage - subtractValue + pageData.size);
      i++
    ) {
      renderContent.push(
        <li key={_.uniqueId('pageBtn_')}>
          <Button
            text={i}
            textColor={currentPage === i ? colors.white : colors.slate}
            bgColor={currentPage === i ? colors.slate : colors.lighterGrey}
            btnStyle={currentPage === i ? 'solid' : 'ghost'}
            callback={
              pageData.current_page !== i &&
              (() => onPageChange(i, pageData.size))
            }
          />
        </li>
      );
    }

    return renderContent;
  };

  const buildDescription = () => {
    const currentPage = pageData.current_page;
    const pages = pageData.total_pages;

    return (
      <Text
        as="span"
        display="flex"
        alignItems="center"
        marginLeft={20}
        marginBottom={10}
        marginTop={2}
      >
        Showing &nbsp;
        <Text as="span" fontWeight="bold">
          {currentPage - (currentPage % pageData.size) + 1}
          {/* {Math.min(
            pages,
            currentPage - (currentPage % pageData.size) + pageData.size
          )} */}
        </Text>
        &nbsp; of &nbsp;
        <Text as="span" fontWeight="bold">
          {pages}
        </Text>
      </Text>
    );
  };

  return (
    <React.Fragment>
      {pageData.total_pages > 1 && (
        <ul className="pagination">
          {buildPrev()}
          {buildPages()}
          {buildNext()}
          {buildDescription()}
        </ul>
      )}
    </React.Fragment>
  );
};

export default withRouter(Pagination);
