import classNames from 'classnames';

import { reverseSortDirection } from 'helpers/paramHelpers';

import SearchParameterFilterChip from 'components/SearchParameterFilterChip/SearchParameterFilterChip';
import SearchParameterSortChip from 'components/SearchParameterSortChip/SearchParameterSortChip';
import Headers from 'components/Headers/Headers';
// import ShadowRule from 'components/ShadowRule/ShadowRule';

export default ({
  cardLayout,
  headers,
  sort,
  onSort,
  searchParameterFilterChips,
  searchParameters,
  setSearchParameters,
}) => {
  return (
    <thead
      className={classNames('EntityTable-head', {
        // eslint-disable-next-line no-useless-computed-key
        ['EntityTable-head-cardLayout']: cardLayout,
      })}
    >
      {cardLayout ? (
        (() => {
          const getHeaderByField = field =>
            headers.find(header => header.field === field);

          return (
            <tr>
              <td>
                {(() => {
                  if (!sort || sort.length === 0) return null;

                  const fieldSort = sort[0];
                  const sortHeader = getHeaderByField(fieldSort.field);

                  const handleReverseSort = () => {
                    let reversedDirection = reverseSortDirection(
                      fieldSort.direction
                    );

                    if (sortHeader.sortNullsFirst) {
                      reversedDirection += '-nulls-first';
                    } else if (sortHeader.sortNullsLast) {
                      reversedDirection += '-nulls-last';
                    }

                    const newSort = [...sort];
                    newSort[0] = {
                      ...fieldSort,
                      direction: reversedDirection,
                    };

                    onSort(newSort);
                  };
                  const handleRemoveSort = () => onSort([]);
                  return (
                    <SearchParameterSortChip
                      header={sortHeader}
                      direction={fieldSort.direction}
                      onReverse={handleReverseSort}
                      onRemove={handleRemoveSort}
                    />
                  );
                })()}
                {(searchParameterFilterChips || []).map(({ key, label }) => {
                  if (!searchParameters[key]) return null;

                  const handleRemoveFilter = () => {
                    const newSearchParameters = { ...searchParameters };

                    delete newSearchParameters[key];

                    setSearchParameters(newSearchParameters);
                  };

                  return (
                    <SearchParameterFilterChip
                      label={label}
                      value={searchParameters[key]}
                      onRemove={handleRemoveFilter}
                    />
                  );
                })}
              </td>
            </tr>
          );
        })()
      ) : (
        <>
          <Headers headers={headers} sort={sort} onSort={onSort} />
          {/* <tr>
            <td colSpan={headers.length}>
              <ShadowRule />
            </td>
          </tr> */}
        </>
      )}
    </thead>
  );
};
