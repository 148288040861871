import { fromJS } from 'immutable';
import { start, success, failure, error } from 'helpers/reducerHelpers';
import * as actions from './audiencesActions';

const STATE_KEY = 'audiences';
const STATE_KEY_AUDIENCES_BY_COMPANY_ID = 'audiencesByCompanyId';
const STATE_KEY_AUDIENCES_LOADING = 'audiencesLoading';
const STATE_KEY_AUDIENCE = 'audience';
const STATE_KEY_AUDIENCE_LOADING = 'audienceLoading';
const STATE_KEY_CREATED_AUDIENCE = 'createdAudience';
const STATE_KEY_UPDATED_AUDIENCE = 'updatedAudience';
const STATE_KEY_SORT = 'sort';

export const constants = {
  STATE_KEY,
  STATE_KEY_AUDIENCES_BY_COMPANY_ID,
  STATE_KEY_AUDIENCE,
  STATE_KEY_AUDIENCE_LOADING,
  STATE_KEY_CREATED_AUDIENCE,
  STATE_KEY_UPDATED_AUDIENCE,
  STATE_KEY_AUDIENCES_LOADING,
};

export default (
  ogState = {
    [STATE_KEY_AUDIENCE_LOADING]: true,
    [STATE_KEY_AUDIENCES_LOADING]: true,
    [STATE_KEY_AUDIENCE]: null,
    [STATE_KEY_AUDIENCES_BY_COMPANY_ID]: [],
  },
  action
) => {
  const state = fromJS(ogState);

  switch (action.type) {
    case start(actions.getAudience.type):
      return state.merge({ [STATE_KEY_AUDIENCE_LOADING]: true }).toJS();
    case success(actions.getAudience.type):
      return state
        .merge({
          [STATE_KEY_AUDIENCE_LOADING]: false,
          [STATE_KEY_AUDIENCE]: action.payload,
        })
        .toJS();
    case start(actions.getAudiences.type):
      return state.merge({ [STATE_KEY_AUDIENCES_LOADING]: true }).toJS();
    case success(actions.getAudiences.type):
      return state
        .merge({
          [STATE_KEY_AUDIENCES_BY_COMPANY_ID]: action.payload,
          [STATE_KEY_AUDIENCES_LOADING]: false,
        })
        .toJS();
    case success(actions.createAudience.type):
      return state.set(STATE_KEY_CREATED_AUDIENCE, action.payload).toJS();
    case success(actions.updateAudience.type):
      return state.set(STATE_KEY_UPDATED_AUDIENCE, action.payload).toJS();

    case failure(actions.getAudience.type):
    case failure(actions.getAudiences.type):
    case failure(actions.updateAudience.type):
    case failure(actions.createAudience.type):
    case failure(actions.deleteAudience.type):
    case error(action.type):
      // GENERIC API ERROR HANDLER, will match any error for any type
      //console.log('AUDIENCE_REDUCER : ', 'API_ERROR', action.payload)
      return ogState;

    case actions.setSort.type:
      return state.set(STATE_KEY_SORT, action.payload).toJS();

    default:
      return ogState;
  }
};
