import React from 'react';
import './ToggleSwitch.scss';
import colors from '../../theme/Colors.module.scss';

const ToggleSwitch = ({
  isInitialValue,
  ...props
 }) => {
  const buildSwitch = () => {
    const checkedColor = props.color || colors.slate;
    // prevent toggle slider from animating when value
    // changes because of new data, i.e. a reusable menu
    // that is reloaded for different items of data should
    // not animate when the menu is reloaded for a different
    // data set, only when the user has done something to 
    // cause the change
    const maybeAnimate = isInitialValue ? '' : 'animate';
    return (
      <label 
        className="toggleSwitch" 
      >
        <input {...props} checked={props.value} type="checkbox" />
        <span
          className={`slider round ${maybeAnimate}`}
          style={{
            backgroundColor: !!props.value ? checkedColor : colors.greyDisabled,
          }}
        />
      </label>
    );
  };

  return <React.Fragment>{buildSwitch()}</React.Fragment>;
};

export default ToggleSwitch;
