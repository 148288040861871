import { Box, Flex } from '@chakra-ui/react';
// import Icons from 'components/Icons';
import colors from 'theme/Colors.module.scss';
import React from 'react';
// import { useHistory } from 'react-router-dom';
import ButtonComponent from 'components/Button/Button';

export default function Header({ url, showOpenAndTargeted = false }) {
  // const { push } = useHistory();

  // const onCreateClicked = () => push(url);

  return (
    <Flex
      align="center"
      justify="space-between"
      w="100%"
      flex="1"
      // ButtonComponent
      borderBottom={`1px solid ${colors.lighterGrey}`}
      sx={{
        '.filter': {
          w: '100%',
        },
      }}
      p="0.5rem 0"
    >
      <Flex align="center" borderRight={`1px solid ${colors.lighterGrey}`}>
        <ButtonComponent
          type="button"
          icon="add"
          text="New Param"
          textColor={colors.slate}
          btnStyle="transparent"
          url={url}
        />
      </Flex>

      {showOpenAndTargeted && (
        <Flex align="center" id="campaignsHeaderRight">
          <Flex align="center" fontSize="0.8rem">
            <Box
              w="10px"
              borderRadius="10px"
              bgColor={colors.primary900}
              mr="0.625rem"
              h="10px"
            />
            Open
          </Flex>

          <Flex align="center" fontSize="0.8rem" ml="1rem">
            <Box
              w="10px"
              borderRadius="10px"
              bgColor={colors.secondary900}
              mr="0.625rem"
              h="10px"
            />
            Targeted
          </Flex>
        </Flex>
      )}
    </Flex>
  );
}
