// import { analyticsSchema } from './analyticsDuck';
import { apiActions } from '../api/apiDuck';

export const getStatsById = (id, params, type) => {
  return apiActions.apiAction({
    path: `campaigns/${id}/stats${params}`,
    type: type,
    method: 'GET',
  });
};
