import React from 'react';
import _ from 'lodash';
import colors from 'theme/Colors.module.scss';
import './Table.scss';

const Table = props => {
  const { columns, data, activeData, rowClick, isMobile } = props;
  const colClassNames = columns.map((title, index) => `title_${index}`);

  const buildHeader = () => {
    const headerStyle = {
      gridTemplateColumns: `1fr repeat(2, 8rem)`, // `14rem repeat(${columns.length - 1}, 1fr)`,
      gridTemplateAreas: colClassNames.join(' '),
    };

    const colTitles = () => {
      return columns.map((title, index) => (
        <span key={title} className={colClassNames[index]}>
          {title}
        </span>
      ));
    };

    return (
      <div className="tHead" style={headerStyle}>
        {colTitles()}
      </div>
    );
  };

  const buildRows = () => {
    const rowStyle = id => {
      const style = {
        gridTemplateColumns: `1fr repeat(2, 8rem)`,
        gridTemplateAreas: 'trName trTotalRev trPrints',
      };

      if (activeData) {
        // TODO: Change to activeData.data.advertiser_id when data is ready.
        if (id === activeData.id) {
          style.backgroundColor = colors.secondary900;
          style.color = colors.white;
        }
      }

      return style;
    };

    const makeRows = () => {
      return data.map(item => {
        // TODO: Add value trTotalRev and change id to advertiser_id when data is ready.
        return (
          <li
            key={_.uniqueId('row_')}
            className="tr"
            onClick={() => rowClick(item.data.id)}
            style={rowStyle(item.data.id)}
          >
            <span className="trName">
              {isMobile && (
                <span className="mobileTitle">Advertiser Name: &nbsp;</span>
              )}
              [Advertiser Name]
            </span>
            <span className="trTotalRev">
              {isMobile && <span className="mobileTitle">Revenue: &nbsp;</span>}
              ${' '}
              {item.data
                ? parseFloat(item.data.total_amt).toLocaleString()
                : '--'}
            </span>
            <span className="trPrints">
              {isMobile && (
                <span className="mobileTitle">Impressions: &nbsp;</span>
              )}
              {item.data ? item.data.total_imp.toLocaleString() : '--'}
            </span>
          </li>
        );
      });
    };

    return <ul className="tBody">{data && makeRows()}</ul>;
  };

  return (
    <div className="table">
      {buildHeader()}
      {buildRows()}
    </div>
  );
};

export default Table;
