import React from "react";

function File({width = 55}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      fill="none"
      viewBox="0 0 109 120"
    >
      <path
        fill="#394A64"
        fillRule="evenodd"
        d="M67.506 20.952L83.91 39.01c.568.626.887 1.474.888 2.358v51.986c0 3.684-2.713 6.671-6.06 6.671H30.263c-3.347 0-6.06-2.987-6.06-6.67v-66.71c0-3.683 2.713-6.67 6.06-6.67h35.101c.804 0 1.574.352 2.142.977zm9.716 72.402c.837 0 1.515-.746 1.515-1.667V43.44c0-.442-.159-.865-.442-1.178l-13.743-15.13a1.448 1.448 0 00-1.069-.486H31.778c-.837 0-1.515.747-1.515 1.668v63.373c0 .92.678 1.667 1.514 1.667h45.445zM44.802 53.33c3.346 0 6.06-2.986 6.06-6.67 0-3.685-2.714-6.671-6.06-6.671-3.346 0-6.06 2.986-6.06 6.67 0 3.685 2.714 6.671 6.06 6.671zm15.148 1.478c.523 0 1.008.296 1.285.784l13.146 23.144a.907.907 0 01.021.848.75.75 0 01-.664.43H37.08a.75.75 0 01-.663-.43.907.907 0 01.021-.848l8.407-14.806c.277-.487.763-.784 1.285-.784.522 0 1.008.297 1.285.784l2.617 4.61c.14.242.382.389.642.389.261 0 .503-.147.643-.39l7.35-12.947c.277-.488.762-.784 1.284-.784z"
        clipRule="evenodd"
      ></path>
      <mask
        style={{ maskType: "alpha" }}
        width="61"
        height="82"
        x="24"
        y="19"
        maskUnits="userSpaceOnUse"
      >
        <path
          fill="#fff"
          fillRule="evenodd"
          d="M67.506 20.952L83.91 39.01c.568.626.887 1.474.888 2.358v51.986c0 3.684-2.713 6.671-6.06 6.671H30.263c-3.347 0-6.06-2.987-6.06-6.67v-66.71c0-3.683 2.713-6.67 6.06-6.67h35.101c.804 0 1.574.352 2.142.977zm9.716 72.402c.837 0 1.515-.746 1.515-1.667V43.44c0-.442-.159-.865-.442-1.178l-13.743-15.13a1.448 1.448 0 00-1.069-.486H31.778c-.837 0-1.515.747-1.515 1.668v63.373c0 .92.678 1.667 1.514 1.667h45.445zM44.802 53.33c3.346 0 6.06-2.986 6.06-6.67 0-3.685-2.714-6.671-6.06-6.671-3.346 0-6.06 2.986-6.06 6.67 0 3.685 2.714 6.671 6.06 6.671zm15.148 1.478c.523 0 1.008.296 1.285.784l13.146 23.144a.907.907 0 01.021.848.75.75 0 01-.664.43H37.08a.75.75 0 01-.663-.43.907.907 0 01.021-.848l8.407-14.806c.277-.487.763-.784 1.285-.784.522 0 1.008.297 1.285.784l2.617 4.61c.14.242.382.389.642.389.261 0 .503-.147.643-.39l7.35-12.947c.277-.488.762-.784 1.284-.784z"
          clipRule="evenodd"
        ></path>
      </mask>
    </svg>
  );
}

export default File;
