import React from 'react';

export const useSortTable = ({ headers, handleSort, sortable }) => {
  const fieldsDirection = React.useRef({});

  React.useEffect(() => {
    fieldsDirection.current = headers.reduce(
      (acc, header) => ({
        ...acc,
        [header.sortField]: {
          direction: 'asc',
          nullsLast: !!header.sortNullsLast,
          nullsFirst: !!header.sortNullsFirst,
          selected: false,
        },
      }),
      {}
    );
  }, [headers]);

  const sortByField = field => {
    if (!sortable) return;
    if (!field) return;

    const { direction, nullsFirst, nullsLast } = fieldsDirection.current[field];
    handleSort(field, direction, nullsFirst, nullsLast);
    Object.keys(fieldsDirection.current).map(fieldName => {
      fieldsDirection.current[fieldName] = {
        ...fieldsDirection.current[fieldName],
        selected: field === fieldName,
        direction: direction === 'asc' && field === fieldName ? 'desc' : 'asc',
      };
      return fieldName;
    });
  };

  const getFieldDirection = field => {
    const fieldData = fieldsDirection.current[field];
    if (!fieldData) return 'asc';

    return fieldData.direction;
  };

  const isSortedByField = field => {
    const fieldData = fieldsDirection.current[field];
    if (!fieldData) return false;

    return fieldData.selected || false;
  };

  return { sortByField, getFieldDirection, isSortedByField };
};
