import React from 'react';
import {
  Box,
  Flex,
  FormControl,
  FormErrorMessage,
  Text,
} from '@chakra-ui/react';
import { useHistory, useLocation } from 'react-router-dom';
import { useBreakpointDown } from 'modules/layout/layoutHooks';
import { Card } from 'components/Card/Card';
import { CardTitle } from 'components/Card/CartTitle';
import colors from 'theme/Colors.module.scss';
import Button from 'components/Button/Button';
import * as paramsHelpers from 'helpers/params';
import { InputLabel } from 'components/InputLabel/InputLabel';
import { TextField } from 'components/TextInput/TextField';
import { Formik } from 'formik';
import { CancelButton } from 'components/CancelButton/CancelButton';
import { NextButton } from 'components/NextButton/NextButton';
import { useDispatch, useSelector } from 'react-redux';
import {
  createConfigParam,
  getCompanyParam,
  updateCompanyParam,
  clearCompanyParam,
} from 'modules/params/companyParamsActions';
import { selectCompanyParam } from 'modules/params/companyParamsSelectors';
import * as appSelectors from 'modules/app/appSelectors';
import * as appActions from 'modules/app/appActions';
import { showMessages } from 'helpers/messageHelpers';

const initialFormState = {
  key: '',
  label: '',
  scopes: ['audience', 'template'],
};

export default function CreateCompanyParam() {
  const { push } = useHistory();
  const isSmall = useBreakpointDown('md');
  const [initialForm, setInitialForm] = React.useState(initialFormState);

  const { pathname } = useLocation();

  const dispatch = useDispatch();
  const companyParam = useSelector(selectCompanyParam);

  const user = useSelector(state => appSelectors.getSessionUser(state));
  const messages = useSelector(state => appSelectors.messages(state));

  const setMessage = payload => dispatch(appActions.setMessage(payload));
  const clearMessage = index => dispatch(appActions.clearMessage(index));

  const createOrUpdateParam = values => {
    const scopes = {
      audience: ['audience'],
      template: ['template'],
      all: ['audience', 'template'],
    };

    const params = {
      ...values,
      scopes: scopes[values.scopes],
      company_id: user.company_id,
    };
    const id = pathname.split('/')[2];
    const handleError = () => {
      setMessage({ message: 'Param already exist', type: 'error' });
    };

    if (id === 'create') {
      dispatch(
        createConfigParam(params, () => push('/companyParams'), handleError)
      );
    } else {
      dispatch(
        updateCompanyParam(
          id,
          params,
          () => push('/companyParams'),
          handleError
        )
      );
    }
  };

  React.useEffect(() => {
    const id = pathname.split('/')[2];
    if (!!id && id !== 'create') {
      dispatch(getCompanyParam(id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  React.useEffect(() => {
    setInitialForm(
      paramsHelpers.setFormInitialValues(companyParam || initialFormState)
    );
  }, [companyParam]);

  React.useEffect(() => {
    return () => dispatch(clearCompanyParam());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <Formik
        initialValues={initialForm}
        validationSchema={paramsHelpers.setValidationSchema()}
        onSubmit={createOrUpdateParam}
        enableReinitialize
      >
        {({
          getFieldMeta,
          getFieldProps,
          handleSubmit,
          values,
          setFieldValue,
        }) => {
          const isFieldInvalid = fieldName => {
            const { error, touched } = getFieldMeta(fieldName);
            return touched && error;
          };

          const handleFieldError = fieldName => {
            const { error, touched } = getFieldMeta(fieldName);
            const display = error && touched;
            return (
              <Box>
                {display && (
                  <FormErrorMessage
                    as="span"
                    fontWeight="500"
                    fontSize="0.875rem"
                    color="#E53E3E"
                    m="0"
                    display="inline-block"
                  >
                    {error}
                  </FormErrorMessage>
                )}
              </Box>
            );
          };

          const onScopeChecked = scope => {
            const { scopes } = values;
            const isAudience = scope === 'audience';
            const options = {
              '': isAudience ? 'audience' : 'template',
              audience: isAudience ? '' : 'all',
              template: isAudience ? 'all' : '',
              all: isAudience ? 'template' : 'audience',
            };
            setFieldValue('scopes', options[scopes]);
          };

          const getBorderColor = fieldName => {
            const { error, touched } = getFieldMeta(fieldName);
            if (error && touched) return '#E53E3E';
          };

          return (
            <Box
              textAlign={`${isSmall ? 'center' : 'left'}`}
              p={!isSmall ? '2rem' : '10px'}
              position="relative"
            >
              <Flex
                align="center"
                p={!isSmall ? '5px 0' : '5px 1rem 0 0'}
                borderBottom={`1px solid ${colors.lighterGrey}`}
                borderTop={
                  !isSmall ? `1px solid ${colors.lighterGrey}` : 'none'
                }
                borderX="none"
                position="absolute"
                left="-16px"
                top="0"
                w="calc(100% + 32px)"
                bgColor={colors.coolGrey}
                zIndex="99"
              >
                <Flex
                  align="center"
                  borderRight={`1px solid ${colors.lighterGrey}`}
                >
                  <Button
                    type="button"
                    text="Back"
                    className="backBtn"
                    textColor={colors.slate}
                    icon="back"
                    btnStyle="transparent"
                    callback={() => push('/companyParams')}
                  />
                </Flex>

                <Text
                  as="h2"
                  fontSize={!isSmall ? '18px' : '16px'}
                  color="black"
                  fontWeight="normal"
                  textAlign="center"
                  flex={1}
                  mr="128px"
                >
                  Configure Params Manager
                </Text>
              </Flex>

              <Card m="5.5rem auto 2rem" w={!isSmall ? '75%' : '100%'}>
                <CardTitle text="Configure Params" />

                <Flex
                  justify={`${isSmall ? 'center' : 'space-between'}`}
                  direction={`${isSmall ? 'column' : 'row'}`}
                  mt="1.75rem"
                >
                  <Flex direction="column" flex={1}>
                    <FormControl
                      isInvalid={isFieldInvalid('key')}
                      name="key"
                      id="key"
                      display="flex"
                      flexDirection="column"
                    >
                      <InputLabel
                        label="Key"
                        disclaimer
                        mb="10px"
                        htmlFor="key"
                        isSmall={isSmall}
                        tipText="Param unique key"
                        tipDirection="top"
                      />
                      <TextField
                        h="1.5rem"
                        borderColor={getBorderColor('key')}
                        {...getFieldProps('key')}
                      />
                      {handleFieldError('key')}
                    </FormControl>
                  </Flex>

                  <Flex
                    direction="column"
                    w={`${!isSmall ? '145px' : 'auto'}`}
                    m={`${!isSmall ? '0 1rem' : '1rem 0'}`}
                    flex={1}
                  >
                    <FormControl
                      display="flex"
                      flexDirection="column"
                      isInvalid={isFieldInvalid('label')}
                    >
                      <InputLabel
                        label="Label"
                        disclaimer
                        mb="10px"
                        htmlFor="label"
                        isSmall={isSmall}
                        tipText="Param label"
                        tipDirection="top"
                      />
                      <TextField
                        borderColor={getBorderColor('key')}
                        h="1.5rem"
                        flex={1}
                        {...getFieldProps('label')}
                      />
                      {handleFieldError('label')}
                    </FormControl>
                  </Flex>

                  <Flex
                    direction="column"
                    w={`${!isSmall ? '80px' : 'auto'}`}
                    flex={1}
                  >
                    <FormControl
                      display="flex"
                      flexDirection="column"
                      isInvalid={isFieldInvalid('scopes')}
                    >
                      <InputLabel
                        label="Scopes"
                        disclaimer
                        mb="10px"
                        d={`${isSmall ? 'flex' : 'block'}`}
                        justifyContent="center"
                        isSmall={isSmall}
                        tipText="Whether the parameter should be available to audiences, templates, or both"
                        tipDirection="top"
                      />

                      <fieldset
                        style={{
                          height: '1.5rem',
                          padding: '8px',
                          margin: '0',
                          border: 'none',
                        }}
                      >
                        <input
                          type="checkbox"
                          name="scopes"
                          value="audience"
                          id="audience-input"
                          style={{ cursor: 'pointer' }}
                          checked={
                            values.scopes === 'audience' ||
                            values.scopes === 'all'
                          }
                          onChange={ev => onScopeChecked(ev.target.value)}
                        />
                        <label
                          htmlFor="audience-input"
                          style={{ cursor: 'pointer', marginLeft: '5px' }}
                        >
                          Audience
                        </label>
                        <input
                          type="checkbox"
                          name="scopes"
                          value="template"
                          id="template-input"
                          style={{ cursor: 'pointer', marginLeft: '1rem' }}
                          checked={
                            values.scopes === 'template' ||
                            values.scopes === 'all'
                          }
                          onChange={ev => onScopeChecked(ev.target.value)}
                        />
                        <label
                          htmlFor="template-input"
                          style={{ cursor: 'pointer', marginLeft: '5px' }}
                        >
                          Template
                        </label>
                      </fieldset>

                      {handleFieldError('scopes', '80px')}
                    </FormControl>
                  </Flex>
                </Flex>
              </Card>

              <Flex
                direction={isSmall ? 'column-reverse' : 'row'}
                align="center"
                justify={!isSmall ? 'space-between' : 'flex-start'}
                m="0 auto"
                w={!isSmall ? '75%' : '100%'}
              >
                <CancelButton
                  onClick={() => push('/companyParams')}
                  w={isSmall ? '100%' : ''}
                  mt={isSmall ? '1rem' : '0'}
                  p="1rem"
                />
                <NextButton
                  w={!isSmall ? '155px' : '100%'}
                  onClick={() => handleSubmit()}
                  border="none"
                  outline="none"
                  cursor="pointer"
                  _hover={{ opacity: 0.75 }}
                />
              </Flex>
            </Box>
          );
        }}
      </Formik>
      {showMessages(messages, clearMessage)}
    </div>
  );
}
