import { fabric } from 'fabric';

export class BackgroundImageObject extends fabric.Image {
  static type = 'BackgroundImage';
  initialize(element, options) {
    super.initialize(element, {
      ...options,
      crossOrigin: 'anonymous',
      hasControls: false,
      lockMovementY: true,
      lockMovementX: true,
      hoverCursor: 'default',
      strokeWidth: 0,
      selectable: false,
      evented: false,
    });
    return this;
  }

  static fromObject(options, callback) {
    fabric.util.loadImage(options.src, function (img) {
      return callback && callback(new fabric.BackgroundImage(img, options));
    });
  }

  toObject(propertiesToInclude = []) {
    return super.toObject(propertiesToInclude, { clipPath: this.clipPath });
  }
  toJSON(propertiesToInclude = []) {
    return super.toObject(propertiesToInclude, { clipPath: this.clipPath });
  }
}

fabric.BackgroundImage = fabric.util.createClass(BackgroundImageObject, {
  type: BackgroundImageObject.type,
});
fabric.BackgroundImage.fromObject = BackgroundImageObject.fromObject;
