import React from 'react';
import { Box } from '@chakra-ui/react';

export default function () {
  return (
    <Box
      sx={{
        height: '100vh',
        width: '100vw',
        flexDirection: 'column',
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      Loading...
    </Box>
  );
}
