import React from 'react';
import { Box, Text } from '@chakra-ui/react';
import MissingFontManager from './MissingFontManager';
import ButtonComponent from './ButtonComponent';

export default function ResolveFonts({
  setStep,
  getUserFonts,
  addUserFont,
  templateFile,
  addNewFont,
  fontsImported,
  setTemplateFile,
  fileFormat,
  missingFonts,
  updateMissingFonts,
  setModalState,
  sm,
  fontsResolved,
  setFontsResolved,
  setMessage,
  currentFonts,
  setCurrentFonts,
  getMissingFonts,
  setUploadFontError,
}) {
  const mounted = React.useRef(false);

  const areAllFontsResolved = Object.values(fontsResolved).every(
    value => !!value
  );

  const continueBtnDisabled = fileFormat === 'ai' && !areAllFontsResolved;

  const showSuccesAlert = () =>
    setMessage({
      message: 'Font imported successfully',
      type: 'success',
    });

  const uploadFontFile = async (font, event, doneCallback, failureCallback) => {
    const file = event.target.files[0];
    if (!file) return;
    const formData = new FormData();
    const newFont = [...fontsImported];
    formData.append('font_request', font);
    formData.append('file', file);
    try {
      addUserFont(
        formData,
        res => {
          newFont.push(res.postscriptname);
          addNewFont(newFont);
          setFontsResolved(prev => ({ ...prev, [res.postscriptname]: true }));
          if (!!doneCallback) {
            !!doneCallback && doneCallback(showSuccesAlert);
          } else {
            showSuccesAlert();
          }
        },
        async error => {
          !!failureCallback && failureCallback();
          const json = await error.json();
          if (json?.error?.status === 404 && json?.error?.code === 2210) {
            setUploadFontError({
              isOpen: true,
              uploaded: json?.error?.data?.font_uploaded?.postscriptname || '',
              target: json?.error?.data?.font_request || '',
            });
          }
        }
      );
    } catch (e) {
      console.log('error', e);
    }
  };

  const handleInitialLoad = async (toNextStep = false) => {
    if (fileFormat === 'psd' && mounted.current) {
      let userFonts = await new Promise(resolve =>
        getUserFonts(data => resolve(data))
      );
      const fonts = userFonts.data.data;
      setCurrentFonts(fonts);
      await getMissingFontsForPSDFile(fonts);
    } else {
      let missingFontsBackup = [...missingFonts];
      let updated = false;

      missingFonts.map(font => {
        if (fontsImported.includes(font)) {
          updated = true;
          missingFontsBackup = missingFontsBackup.filter(
            missingFont => missingFont !== font
          );
        }
        return font;
      });
      if (updated) {
        updateMissingFonts(missingFontsBackup);
      }

      if ((!missingFontsBackup.length && !mounted.current) || toNextStep) {
        setModalState({ title: 'Resuming', description: '' });
        setStep(1);

        setFontsResolved({});
      }
    }

    mounted.current = true;
  };

  const getMissingFontsForPSDFile = async () => {
    const fonts = await getMissingFonts(templateFile);
    if (!fonts.length) setStep(3);
  };

  const replaceUnresolvedFonts = font => {
    const currentTemplateFile = templateFile;
    let new_ad_template_vars = [];

    templateFile.ad_template_vars.map(layer => {
      if (
        layer.res_type === 'txt_template' &&
        layer.metadata.postscriptname === font &&
        missingFonts.includes(layer.metadata.postscriptname)
      ) {
        let t_var = {
          label: 'img',
          metadata: {
            url: layer.metadata.src,
            // imported: true
          },
          pos_x: layer.pos_x,
          pos_y: layer.pos_y,
          res_type: 'img',
          size_x: layer.size_x,
          size_y: layer.size_y,
          var_type: 'static',
          z_index: layer.z_index,
        };
        if (layer.metadata.importInit) {
          t_var.metadata.importInit = {
            ...layer.metadata.importInit,
          };
        }

        new_ad_template_vars.push(t_var);
      } else {
        new_ad_template_vars.push(layer);
      }
      return layer;
    });
    currentTemplateFile.ad_template_vars = new_ad_template_vars.sort((a, b) => {
      return b.z_index - a.z_index;
    });
    setTemplateFile(currentTemplateFile);
    // handleInitialLoad();
  };

  const onContinue = () => {
    if (!areAllFontsResolved) {
      if (fileFormat === 'ai') return;
      Object.keys(fontsResolved).forEach(
        font => !fontsResolved[font] && replaceUnresolvedFonts(font)
      );
    }
    handleInitialLoad(true);
  };

  React.useEffect(() => {
    setModalState({
      title: 'Resolve missing fonts',
      description:
        "We've found some fonts in your design file that are missing from your account",
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (!mounted.current) {
      handleInitialLoad();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [templateFile]);

  return (
    <Box maxW="618px">
      <hr style={{ background: '#DEE5EF', margin: '5px 0 1rem' }} />
      {missingFonts.map((font, idx) => {
        const isFontUploaded = fontsImported.includes(font);
        return (
          <MissingFontManager
            key={font}
            font={font}
            isFontUploaded={isFontUploaded}
            uploadFontFile={(e, doneCallback, failureCallback) =>
              uploadFontFile(font, e, doneCallback, failureCallback)
            }
            index={idx}
            currentFonts={currentFonts}
            templateFile={templateFile}
            setTemplateFile={setTemplateFile}
            setFontsResolved={setFontsResolved}
            fileFormat={fileFormat}
          />
        );
      })}

      {!areAllFontsResolved && fileFormat === 'psd' && (
        <Text
          fontSize="18px"
          fontWeight={400}
          color="black"
          m="30px 0 0"
          textAlign="center"
        >
          If you choose to continue without resolving your missing fonts, we
          will attempt to automatically replace these text elements
        </Text>
      )}

      <ButtonComponent
        p={sm ? '0.65rem 3rem' : '0.65rem 1rem'}
        m="2rem auto 0"
        disabled={continueBtnDisabled}
        cursor={continueBtnDisabled ? 'default' : 'pointer'}
        _disabled={{ cursor: 'default' }}
        onClick={onContinue}
      >
        Continue
      </ButtonComponent>
    </Box>
  );
}
