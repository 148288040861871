import { fromJS } from 'immutable';
import { schema } from 'normalizr';
import { success, failure, error } from '../../helpers/reducerHelpers';
import { createSelector } from 'reselect';
import { createApiErrorSelector } from '../api/apiDuck';
import { pageParams, paramsToString } from '../../helpers/paramHelpers';
// import { isEmpty } from 'lodash';
import * as api from './inventoryApi';

// Constants
const GET_INVENTORY_ORDERS = 'modules/inventory/GET_INVENTORY_ORDERS';
const GET_INVENTORY_BALANCES = 'modules/inventory/GET_INVENTORY_BALANCES';
const STATE_KEY_INVENTORY_ORDERS = 'inventoryOrders';
const STATE_KEY_INVENTORY_BALANCES = 'inventoryBalances';
const SCHEMA_INVENTORY = 'inventory';

export const inventoryConstants = {
  GET_INVENTORY_ORDERS,
  GET_INVENTORY_BALANCES,
  STATE_KEY_INVENTORY_ORDERS,
  STATE_KEY_INVENTORY_BALANCES,
  SCHEMA_INVENTORY,
};

// Schema
export const inventorySchema = {
  schema: new schema.Entity(SCHEMA_INVENTORY),
};

// Reducer
const reducer = (ogState = {}, action) => {
  const state = fromJS(ogState);

  switch (action.type) {
    case success(GET_INVENTORY_ORDERS):
      return state.set(STATE_KEY_INVENTORY_ORDERS, action.payload).toJS();
    case success(GET_INVENTORY_BALANCES):
      return state.set(STATE_KEY_INVENTORY_BALANCES, action.payload).toJS();

    case failure(GET_INVENTORY_ORDERS):
    case failure(GET_INVENTORY_BALANCES):
    case error(action.type):
      // GENERIC API ERROR HANDLER, will match any error for any type
      // console.log('INVENTORY_REDUCER : ', 'API_ERROR', action.payload)
      return ogState;
    default:
      return ogState;
  }
};

// Actions
export const inventoryActions = {
  getInventoryOrders: payload => {
    const params = paramsToString({ ...pageParams(payload) });
    return api.getInventoryOrders(params, GET_INVENTORY_ORDERS);
  },

  getInventoryBalances: () => api.getInventoryBalances(GET_INVENTORY_BALANCES),
};

// Selectors
const getUnorderedInventoryOrders = state =>
  state.modules.inventory[STATE_KEY_INVENTORY_ORDERS];
const getUnorderedInventoryBalances = state =>
  state.modules.inventory[STATE_KEY_INVENTORY_BALANCES];

export const inventorySelectors = {
  // TODO: Use proper schema when not using mirage.
  inventoryOrders: createSelector([getUnorderedInventoryOrders], data => data),
  inventoryBalances: createSelector(
    [getUnorderedInventoryBalances],
    data => data
  ),

  getApiError: createApiErrorSelector([
    GET_INVENTORY_ORDERS,
    GET_INVENTORY_BALANCES,
  ]),

  getPageData: createSelector([getUnorderedInventoryOrders], page => page),
};

export default reducer;
