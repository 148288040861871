import React from 'react';
import classNames from 'classnames';
import { useHistory, useLocation } from 'react-router-dom';

import Headers from 'components/Headers/Headers';

import './TableRowCard.scss';

const TableRowCardIndicator = ({ color }) => {
  return (
    <div
      className="TableRowCard-indicator"
      style={{
        backgroundColor: color,
      }}
    />
  );
};

export default props => {
  const {
    rootRef,
    rootProps,
    forceFixedTableLayout,
    className,
    size = 'small',
    headers,
    hover,
    focus,
    indicatorColor,
    to,
    onClick,
    prependCells,
    appendCells,
    children,
    sort,
  } = props;
  const { push } = useHistory();
  const location = useLocation();
  const getHeaderAtIndex = index => Array.isArray(headers) && headers[index];
  const prependCellsCount = (prependCells || []).length;
  const renderTableCells = () => {
    const cellElements = (prependCells || [])
      .map((cellElement, index) => {
        const header = getHeaderAtIndex(index);

        return React.cloneElement(cellElement, {
          className: classNames(
            cellElement.props.className,
            'TableRowCard-wrappedCell'
          ),
          style: {
            width: header?.width,
          },
        });
      })
      .concat(
        React.Children.map(children, (childElement, index) => {
          if (!childElement) {
            return;
          } else if (childElement.type !== 'td') {
            throw "child element must be of type 'td'";
          }

          const header = getHeaderAtIndex(index + prependCellsCount);
          const align = header?.align || 'left';
          const width = header?.width || 'auto';
          const hasIndicator = index === 0 && indicatorColor;

          return (
            <childElement.type
              key={index}
              className={classNames(
                'TableRowCard-cell',
                `TableRowCard-cell-${size}`
              )}
              style={{
                width,
                cursor: 'auto',
              }}
            >
              <div
                {...childElement.props}
                className={classNames(
                  childElement?.props?.className,
                  'TableRowCard-cell-content',
                  `TableRowCard-cell-content-align-${align}`,
                  `${
                    sort && header?.field === sort[0]?.field
                      ? 'TableRowCard-cell-active'
                      : ''
                  }`,
                  {
                    'TableRowCard-cell-content-has-indicator': hasIndicator,
                  }
                )}
              >
                {hasIndicator && (
                  <TableRowCardIndicator color={indicatorColor} />
                )}
                {childElement.props.children}
              </div>
            </childElement.type>
          );
        })
      )
      .concat(appendCells || []);

    if (forceFixedTableLayout) {
      return (
        <td>
          <table className="TableRowCard-tableLayout-table">
            <tbody>
              <Headers headers={headers} sort={[]} onSort={null} />
              <tr className="TableRowCard-tableLayout-row">{cellElements}</tr>
            </tbody>
          </table>
        </td>
      );
    } else {
      return cellElements;
    }
  };

  const rowProps = {
    ref: rootRef,
    className: classNames(className, 'TableRowCard', {
      'TableRowCard-hover': hover || (!!to && hover !== false),
      'TableRowCard-focus': focus,
      'TableRowCard-tableLayout': forceFixedTableLayout,
    }),
    onClick,
    ...rootProps,
  };

  const redirect = () =>
    push(to, { from: location.pathname.concat(location.search) });

  return (
    <tr {...rowProps} onClick={to && redirect}>
      {renderTableCells()}
    </tr>
  );
};
