export const HEADERS = [
  {
    name: 'Key',
    field: 'key',
    sortable: true,
    hasIndicator: true,
    align: 'center',
    width: 200,
    sortField: 'key',
    headerAlign: 'center'
  },
  {
    name: 'Label',
    field: 'label',
    sortable: true,
    hasIndicator: true,
    align: 'center',
    width: 200,
    sortField: 'label',
    headerAlign: 'center'
  },
  {
    name: 'scopes',
    field: 'scopes',
    sortable: true,
    hasIndicator: true,
    align: 'center',
    width: 200,
    sortField: 'scope',
    headerAlign: 'center'
  },
];