import { fromJS } from 'immutable';
import { success, failure, error, start } from '../../helpers/reducerHelpers';
import * as actions from './campaignsActions';

const STATE_KEY_CURRENT_CREATIVE = 'currentCreative';
const STATE_KEY_CAMPAIGNS = 'campaigns';
const STATE_KEY_CURRENT_PAGE = 'currentPage';
const STATE_KEY_SORT = 'sort';
const STATE_KEY_CAMPAIGNS_LOADING = 'campaignsLoading';
const STATE_KEY_CAMPAIGN = 'campaign';
const STATE_KEY_CAMPAIGN_LOADING = 'campaignLoading';
const STATE_KEY_RETAILERS = 'retailers';
const STATE_KEY_DATERANGE = 'dateRange';

export default (
  ogState = {
    [STATE_KEY_DATERANGE]: { from: null, to: null },
    [STATE_KEY_CAMPAIGN]: null,
    [STATE_KEY_CAMPAIGNS]: [],
  },
  action
) => {
  const state = fromJS(ogState);

  switch (action.type) {
    case actions.setCurrentCreative.type:
      return state.set(STATE_KEY_CURRENT_CREATIVE, action.value).toJS();
    case actions.clearCurrentCampaign.type:
      return state.set(STATE_KEY_CAMPAIGN, null).toJS();
    case actions.setSort.type:
      return state.set(STATE_KEY_SORT, action.payload).toJS();
    case actions.setDateRange.type:
      return state.set(STATE_KEY_DATERANGE, action.payload).toJS();

    case success(actions.uploadImage.type):
      return state.set(STATE_KEY_CURRENT_CREATIVE, action.payload).toJS();
    case start(actions.getCampaign.type):
      return state
        .merge({
          [STATE_KEY_CAMPAIGN_LOADING]: true,
        })
        .toJS();
    case success(actions.getCampaign.type):
      return state
        .merge({
          [STATE_KEY_CAMPAIGN_LOADING]: false,
          [STATE_KEY_CAMPAIGN]: action.payload.entities.campaigns,
        })
        .toJS();
    case start(actions.getCampaigns.type):
      return state
        .merge({
          [STATE_KEY_CAMPAIGNS_LOADING]: true,
        })
        .toJS();
    case success(actions.getCampaigns.type):
      const pageData = action.payload;
      const arr = pageData.data.result.map(
        id => pageData.data.entities.campaigns[id]
      );

      return state
        .merge({
          [STATE_KEY_CAMPAIGNS]: arr,
          [STATE_KEY_CURRENT_PAGE]: pageData,
          [STATE_KEY_CAMPAIGNS_LOADING]: false,
        })
        .toJS();

    case success(actions.getRetailers.type):
      return state.set(STATE_KEY_RETAILERS, action.payload).toJS();

    case failure(actions.getCampaign.type):
    case failure(actions.getCampaigns.type):
    case failure(actions.updateCampaign.type):
    case failure(actions.createCampaign.type):
    case failure(actions.getRetailers.type):
    case failure(actions.submitToRetailers.type):
    case error(action.type):
      // GENERIC API ERROR HANDLER, will match any error for any type
      //console.log('CAMPAIGNS_REDUCER : ', 'API_ERROR', action.payload)
      return ogState;

    default:
      return ogState;
  }
};
