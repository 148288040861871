import { fabric } from 'fabric';
import { ZOOM_MODE } from '../common/constants';
import BaseHandler from './BaseHandler';

class ZoomHandler extends BaseHandler {
  // constructor(props) {
  //   super(props);
  // }

  initialZoom() {
    let zoomFitRatio = this.handlers.frameHandler.getInitialRatio();
    const doubleSided = this.editor.doubleSided;
    zoomFitRatio = doubleSided ? zoomFitRatio / 2 : zoomFitRatio;
    const center = this.canvas.getCenter();
    this.canvas.setViewportTransform([1, 0, 0, 1, 0, 0]);
    this.zoomToPoint(
      new fabric.Point(center.left, center.top),
      zoomFitRatio,
      true
    );
    if (doubleSided) {
      const frame = this.handlers.frameHandler.getFrame();
      const frameMargin = this.handlers.frameHandler.getFrameMargin();
      const pointY = -Math.abs(
        frame.getBoundingRect().top - frameMargin * zoomFitRatio
      );
      this.canvas.relativePan(new fabric.Point(0, pointY));
    }
    this.context.setZoomRatio(zoomFitRatio);
    this.context.setFitZoomRatio(zoomFitRatio);
    this.context.setZoomMode(ZOOM_MODE.FIT);
  }

  zoomIn() {
    let zoomRatio = this.canvas.getZoom();
    zoomRatio += 0.05;
    const center = this.canvas.getCenter();
    this.zoomToPoint(new fabric.Point(center.left, center.top), zoomRatio);
    this.context.setZoomRatio(zoomRatio);
  }

  zoomOut() {
    let zoomRatio = this.canvas.getZoom();
    zoomRatio -= 0.05;
    const center = this.canvas.getCenter();
    this.zoomToPoint(new fabric.Point(center.left, center.top), zoomRatio);
    this.context.setZoomRatio(zoomRatio);
  }

  zoomToOne() {
    const center = this.canvas.getCenter();
    this.canvas.setViewportTransform([1, 0, 0, 1, 0, 0]);
    this.zoomToPoint(new fabric.Point(center.left, center.top), 1);
    this.context.setZoomRatio(1);
  }

  zoomToFit() {
    let zoomFitRatio = this.handlers.frameHandler.getFitRatio();
    zoomFitRatio = this.editor.doubleSided ? zoomFitRatio / 2 : zoomFitRatio;
    const center = this.canvas.getCenter();

    this.canvas.setViewportTransform([1, 0, 0, 1, 0, 0]);
    this.zoomToPoint(new fabric.Point(center.left, center.top), zoomFitRatio);
    if (this.editor.doubleSided) {
      const frame = this.handlers.frameHandler.getFrame();
      const frameMargin = this.handlers.frameHandler.getFrameMargin();
      const pointY = -Math.abs(
        frame.getBoundingRect().top - frameMargin * zoomFitRatio
      );
      this.canvas.relativePan(new fabric.Point(0, pointY));
    }
    this.context.setZoomRatio(zoomFitRatio);
    this.context.setFitZoomRatio(zoomFitRatio);
    this.context.setZoomMode(ZOOM_MODE.FIT);
  }

  zoomToFill() {
    const zoomFillRatio = this.handlers.frameHandler.getFillRatio();
    const center = this.canvas.getCenter();
    this.canvas.setViewportTransform([1, 0, 0, 1, 0, 0]);
    this.zoomToPoint(new fabric.Point(center.left, center.top), zoomFillRatio);
    this.context.setZoomRatio(zoomFillRatio);
    this.context.setFillZoomRatio(zoomFillRatio);
    this.context.setZoomMode(ZOOM_MODE.FILL);
  }

  zoomToRatio(zoomRatio) {
    const center = this.canvas.getCenter();
    this.zoomToPoint(new fabric.Point(center.left, center.top), zoomRatio);
    this.context.setZoomRatio(zoomRatio);
    this.handlers.scrollbarHandler.updateScrollPosition();
  }

  zoomToPoint(point, zoom, initial = false) {
    const minZoom = 10;
    const maxZoom = 300;
    let zoomRatio = zoom;
    if (zoom <= minZoom / 100) {
      zoomRatio = minZoom / 100;
    } else if (zoom >= maxZoom / 100 && !initial) {
      zoomRatio = maxZoom / 100;
    }
    this.canvas.zoomToPoint(point, zoomRatio);
    this.context.setZoomRatio(zoomRatio);
    this.handlers.scrollbarHandler.updateScrollPosition();
  }
}

export default ZoomHandler;
