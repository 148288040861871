import React, { useCallback } from 'react';
import { Button, Tooltip } from '@chakra-ui/react';
import useEditorContext from 'components/Canvas/hooks/useEditorContext';

function Personalize() {
  const { activeObject, keysMenu, setKeysMenu, canvas } = useEditorContext();

  const triggerKeysMenu = useCallback(() => {
    if (activeObject) {
      activeObject.triggerKeysMenu();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeObject, keysMenu, setKeysMenu, canvas]);

  if (!activeObject) {
    return null;
  }

  return (
    <>
      {activeObject.type === 'DynamicText' ? (
        <Tooltip label="Personalize">
          <Button onClick={triggerKeysMenu} size="sm" variant="ghost">
            Personalize
          </Button>
        </Tooltip>
      ) : null}
    </>
  );
}

export default Personalize;
