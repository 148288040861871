import { callApi, handler } from '../../services/ApiService';
// import { billingSchema } from './billingInfoDuck';

export const addPayment = (payload, type, doneCallback) => {
  return (dispatch, getState) => {
    // TODO: Update 'payments' in callApi() with real end point when ready.
    callApi(
      dispatch,
      getState,
      type,
      null,
      'payments',
      {
        method: 'POST',
        body: JSON.stringify(payload),
      },
      handler(doneCallback)
    );
  };
};

export const updatePayment = (payload, type, doneCallback) => {
  return (dispatch, getState) => {
    // TODO: Update 'payments/:id' in callApi() with real end point when ready.
    callApi(
      dispatch,
      getState,
      type,
      null,
      `payments/${payload.id}`,
      {
        method: 'PUT',
        body: JSON.stringify(payload),
      },
      handler(doneCallback)
    );
  };
};

export const removePayment = (id, type, value) => {
  return (dispatch, getState) => {
    // TODO: Update 'payments/:id' in callApi() with real end point when ready.
    callApi(dispatch, getState, type, value, `payments/${id}`, {
      method: 'DELETE',
    });
  };
};

export const setPrimaryPayment = (payload, type) => {
  return (dispatch, getState) => {
    // TODO: Update 'payments/primaryPayment' in callApi() with real end point when ready.
    callApi(dispatch, getState, type, null, 'payments/primaryPayment', {
      method: 'POST',
      body: JSON.stringify(payload),
    });
  };
};

export const setBillingInfo = (payload, type, doneCallback) => {
  return (dispatch, getState) => {
    // TODO: Update 'billingInfo' in callApi() with real end point when ready.
    callApi(
      dispatch,
      getState,
      type,
      null,
      'billingInfo',
      {
        method: 'POST',
        body: JSON.stringify(payload),
      },
      handler(doneCallback)
    );
  };
};
