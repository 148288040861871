import React from 'react';

function Exchange({ style = {}, height = 20, color = 'black' }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      x="0"
      y="0"
      enableBackground="new 0 0 477.426 477.426"
      version="1.1"
      viewBox="0 0 477.426 477.426"
      xmlSpace="preserve"
      height={height}
      style={{...style}}
    >
      <path d="M86.213 143.435L476.213 143.435 476.213 113.435 86.213 113.435 86.213 41.892 0 128.387 86.213 214.319z"></path>
      <path d="M477.426 349.202L391.654 263.43 391.424 334.364 1.213 334.364 1.213 364.364 391.326 364.364 391.095 435.533z"></path>
    </svg>
  );
}

export default Exchange;
