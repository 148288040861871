import Tabs from 'components/Tabs/Tabs';

import { useLocation, matchPath } from 'react-router';
import { Link } from 'react-router-dom';

export default ({ tabs }) => {
  const location = useLocation();

  let renderedActive = false;

  return (
    <Tabs
      tabs={tabs.map(tabData => {
        let match = matchPath(location.pathname, tabData.to, {
          exact: tabData.exact,
        });

        if (match && tabData.exact && !match.isExact) {
          match = false;
        }

        const active = !renderedActive && !!match;

        if (active) {
          renderedActive = true;
        }

        return {
          ...tabData,
          active,
          component: Link,
          props: { to: tabData.to },
        };
      })}
    />
  );
};
