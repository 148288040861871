import React from 'react';
const { Text } = require('@chakra-ui/react');

export const CardTitle = ({ text, ...props }) => (
  <Text
    as="p"
    fontWeight="600"
    fontSize="0.875rem"
    color="black"
    m="0"
    {...props}
  >
    {text}
  </Text>
);
