import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useFlag } from 'flags';
import { defaultModal, openModal, showModal } from 'helpers/modals';
import { duplicateTemplate } from 'helpers/canvasHelpers';
import DeleteTemplate from '../Modals/Delete';
import Button from 'components/Button/Button';
import Tooltip from 'components/Tooltip/Tooltip';
import TableRowCard from 'components/TableRowCard/TableRowCard';
import colors from 'theme/Colors.module.scss';
import './TemplateCard.scss';
import templateSrc from '../templateHelpers';
import * as appActions from 'modules/app/appActions';
import { templateEditorActions } from 'modules/templateEditor/templateEditorDuck';
import LoadingDuplicatingModalContent from '../Modals/LoadingModal';

const TemplateCard = props => {
  const {
    headers,
    template,
    deleteTemplateById,
    createTemplate,
    getTemplates,
    isMobile,
    focus,
    onClick,
    onDelete,
    onDuplicate,
  } = props;
  const history = useHistory();
  const dispatch = useDispatch();

  const [warningModal, setWarningModal] = useState(defaultModal);
  const [isClicked, setIsClicked] = useState(false);

  // Dispatches
  const createTemplateDoubleSided = (
    payload,
    successCallback,
    failureCallback
  ) =>
    dispatch(
      templateEditorActions.createTemplateDoubleSided(
        payload,
        successCallback,
        failureCallback
      )
    );
  const setMessage = payload => dispatch(appActions.setMessage(payload));

  // const changeRoute = () => history.push();
  const isReadOnly = useFlag(['features', 'isReadOnly']);
  const inUse = template.campaigns_count > 0;

  const getIndicatorColor = () =>
    inUse ? colors.status00 : colors.greyDisabled;

  const actionButtons = () => {
    return (
      <React.Fragment>
        {isMobile && (
          <Button
            icon="copy"
            iconColor={colors.slate}
            btnStyle="transparent"
            disabled={isClicked}
            onClick={async event => {
              event.stopPropagation();
              event.preventDefault();
              setIsClicked(true);
              const context = {
                createTemplate,
                createTemplateDoubleSided,
                getTemplates,
                history,
              };
              openModal(
                setWarningModal,
                template.id,
                'Duplicating Template',
                <LoadingDuplicatingModalContent />
              );
              try {
                await duplicateTemplate(
                  template,
                  context,
                  () => {
                    onDuplicate();
                    setMessage({
                      type: 'success',
                      message: 'Template duplicated successfully',
                    });
                    setIsClicked(false);
                  },
                  () => {
                    setMessage({
                      type: 'error',
                      message: 'Template duplication failed',
                    });
                    setIsClicked(false);
                  }
                );
              } catch (e) {
                console.error(e);
              }
            }}
          />
        )}
        {!isMobile && (
          <Tooltip
            tipText={'Duplicate template.'}
            direction="left"
            render={() => (
              <Button
                icon="copy"
                iconColor={colors.slate}
                btnStyle="transparent"
                disabled={isClicked}
                onClick={async event => {
                  event.stopPropagation();
                  event.preventDefault();
                  setIsClicked(true);
                  const context = {
                    createTemplate,
                    createTemplateDoubleSided,
                    getTemplates,
                    history,
                  };
                  openModal(
                    setWarningModal,
                    template.id,
                    'Duplicating Template',
                    <LoadingDuplicatingModalContent />
                  );
                  try {
                    await duplicateTemplate(
                      template,
                      context,
                      () => {
                        onDuplicate();
                        setMessage({
                          type: 'success',
                          message: 'Template duplicated successfully',
                        });
                        setIsClicked(false);
                      },
                      () => {
                        setMessage({
                          type: 'error',
                          message: 'Template duplication failed',
                        });
                        setIsClicked(false);
                      }
                    );
                  } catch (e) {
                    console.error(e);
                  }
                }}
              />
            )}
          />
        )}
      </React.Fragment>
    );
  };

  const buildAssocOrArchived = () => {
    if (inUse) {
      return `Used in ${template.campaigns_count} campaign${
        template.campaigns_count > 1 ? 's' : ''
      } ...`;
    } else {
      return '--';
    }
  };

  const deleteTemplate = id => {
    deleteTemplateById(id);
    setWarningModal(defaultModal);
    if (onDelete) onDelete();
  };

  return (
    <TableRowCard
      className={`templateCard ${isReadOnly && 'readOnly'}`}
      indicatorColor={getIndicatorColor()}
      to={
        !isReadOnly &&
        `/template-editor${
          template && template.id !== 0 ? '?id=' + template.id : ''
        }&ref=/templates`
      }
      onClick={onClick}
      focus={focus}
      headers={headers}
    >
      <td className="preview">
        {!template.bg_img && template.double_sided === false && (
          <i className="fas fa-image noThumb" />
        )}

        {template.double_sided === true &&
          !template.ad_template_pages[0]?.bg_img &&
          !template.ad_template_pages[1]?.bg_img && (
            <i className="fas fa-image noThumb" />
          )}

        {template.bg_img && (
          <img src={templateSrc(template, 'thumb')} alt={template.name} />
        )}

        {(template.ad_template_pages[0]?.bg_img ||
          template.ad_template_pages[1]?.bg_img) && (
          <img src={templateSrc(template, 'thumb')} alt={template.name} />
        )}
      </td>

      <td className="name">
        <div className="nameGroup">{template.name}</div>
      </td>

      <td className="associations">
        <span className="audCardTitle">{buildAssocOrArchived()}</span>
      </td>

      {!isReadOnly && (
        <td
          className="actions"
          style={{
            justifyContent: isMobile && 'center',
          }}
        >
          {actionButtons()}
          {isMobile && (
            <Button
              icon="delete"
              iconColor={colors.slate}
              btnStyle="transparent"
              disabled={inUse}
              onClick={event => {
                event.stopPropagation();
                event.preventDefault();

                if (!inUse) {
                  openModal(
                    setWarningModal,
                    template.id,
                    'Template Delete',
                    <DeleteTemplate
                      callback={deleteTemplate}
                      name={template.name}
                      id={template.id}
                    />
                  );
                }
              }}
              data-id={template.id}
            />
          )}
          {!isMobile && (
            <Tooltip
              tipText={
                inUse
                  ? 'Detach template from all campaigns to delete.'
                  : 'Delete template.'
              }
              direction="left"
              render={() => (
                <>
                  <Button
                    icon="delete"
                    iconColor={colors.slate}
                    btnStyle="transparent"
                    disabled={inUse}
                    onClick={event => {
                      event.stopPropagation();
                      event.preventDefault();

                      if (!inUse) {
                        openModal(
                          setWarningModal,
                          template.id,
                          'Template Delete',
                          <DeleteTemplate
                            callback={deleteTemplate}
                            name={template.name}
                            id={template.id}
                          />
                        );
                      }
                    }}
                    data-id={template.id}
                  />
                </>
              )}
            />
          )}
          {showModal(warningModal, setWarningModal)}
        </td>
      )}
    </TableRowCard>
  );
};

export default TemplateCard;
