import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Flex, Text } from '@chakra-ui/react';
import Header from 'components/TableComponent/Header';
import TableComponent from 'components/TableComponent/TableComponent';
import { HEADERS } from './constants';
import { useHistory } from 'react-router-dom';
import { selectCompanyParams } from 'modules/params/companyParamsSelectors';
import { getCompanyParams } from 'modules/params/companyParamsActions';

export default function Params() {
  const dispatch = useDispatch();
  const { push } = useHistory();

  let companyParams = useSelector(selectCompanyParams);
  companyParams = companyParams.map(cp => ({
    ...cp,
    scopes: cp.scopes.length === 2 ? ['all'] : cp.scopes,
  }));

  React.useEffect(() => {
    dispatch(getCompanyParams());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box>
      <Header url="/companyParams/create" showOpenAndTargeted={false} />
      <Box mt="1rem" w="100%" overflowX="auto">
        {!companyParams.length && (
          <Flex justify="center" mt="1rem">
            <Text fontSize="1.5rem" fontWeight="500" textAlign="center">
              You don’t have any params configured.
            </Text>
          </Flex>
        )}

        {!!companyParams.length && (
          <TableComponent
            headers={HEADERS}
            data={companyParams}
            showCheckBoxes={false}
            showSwitchToggles={false}
            onRowClicked={id => push(`/companyParams/${id}`)}
            sortable={false}
          />
        )}
      </Box>
    </Box>
  );
}
