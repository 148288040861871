import React, { useRef, useEffect } from 'react';

// Used to call a function when a click happens outside of
// the wrapped component.
const useOutsideClickHandler = (ref, onHandler) => {
  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        onHandler(event);
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref]);
};

export default function OutsideClickHandler({ onHandler, children, ...rest }) {
  const wrapperRef = useRef(null);
  useOutsideClickHandler(wrapperRef, onHandler);

  return (
    <div ref={wrapperRef} {...rest}>
      {children}
    </div>
  );
}
