import React from 'react';
import { Box } from '@chakra-ui/react';

export default function ProgressBar({
  value,
  color = '#3182CE',
  height = 20,
  style = {},
}) {
  return (
    <Box
      w="100%"
      bgColor="lightgrey"
      borderRadius={`${height / 2.5}px`}
      height={`${height}px`}
      overflow="hidden"
      style={{ ...style }}
    >
      <Box
        height={`${height}px`}
        w={`${value || 0}%`}
        bgColor={color}
        borderTopLeftRadius={`${height / 2.5}px`}
        borderBottomLeftRadius={`${height / 2.5}px`}
      />
    </Box>
  );
}
