import React from 'react';
import { useSelector } from 'react-redux';
// import { defaultModal, openModal, showModal } from 'helpers/modals';
import { getSessionUser } from 'modules/app/appSelectors';
import TableRowCard from 'components/TableRowCard/TableRowCard';
import colors from 'theme/Colors.module.scss';
import './UserCard.scss';
import { Tag } from '@chakra-ui/tag';

export default props => {
  const { user, headers, focus, onClick } = props;

  const sessionUser = useSelector(getSessionUser);
  const isSessionUser = user.id === sessionUser?.id;

  const getIndicatorColor = () =>
    user.inactive_at ? colors.greyDisabled : colors.status00;

  return (
    <TableRowCard
      key={user.id}
      className="userCard"
      indicatorColor={getIndicatorColor()}
      to={isSessionUser ? '/user/me' : `/users/${user.id}`}
      onClick={onClick}
      focus={focus}
      headers={headers}
    >
      <td className="fName">
        <div className="nameGroup">{user.firstname}</div>
      </td>

      <td className="lName">
        <div className="nameGroup">{user.lastname}</div>
      </td>

      <td className="email">
        <div className="nameGroup">
          {user.email}
          {isSessionUser && (
            <Tag
              background={colors.primary900}
              color={colors.white}
              marginLeft={'8px'}
              borderRadius={'4px'}
            >
              Me
            </Tag>
          )}
        </div>
      </td>
    </TableRowCard>
  );
};
