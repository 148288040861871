import classNames from 'classnames';

import './ShadowRule.scss';

export default ({ absolute }) => {
  return (
    <div
      className={classNames('ShadowRule', {
        'ShadowRule-absolute': absolute,
      })}
    />
  );
};
