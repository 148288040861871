import { Box } from '@chakra-ui/react';
import React from 'react';
import { useEditor } from '../../../../components/Canvas';

function BackFrameTitle() {
  const editor = useEditor();

  // const removeFrame = () => {
  //   editor.handlers.frameHandler.remove('backSide');
  //   editor.handlers.scrollbarHandler.setScrollToTop();
  //   editor.handlers.scrollbarHandler.updateScrollPosition();
  // };

  // const scrollToTop = () => {
  //   editor.handlers.scrollbarHandler.setScrollToTop();
  // };

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        position: 'absolute',
        fontWeight: 'bold',
        alignItems: 'center',
      }}
      id="back-title"
      onWheel={event => {
        editor.handlers.scrollbarHandler.onMouseWheel(event);
      }}
    >
      <Box
        fontSize="0.9rem"
        whiteSpace="nowrap"
        overflow="hidden"
        textOverflow="ellipsis"
      >
        Page 2 - Back Side
      </Box>
    </Box>
  );
}

export default BackFrameTitle;
