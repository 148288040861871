import React, { useEffect } from 'react';
import { Box, Button, Flex, Text } from '@chakra-ui/react';
import { addUserFont } from '../../modules/templateEditor/templateEditorApi';
import { useDispatch } from 'react-redux';
import Icons from 'components/Icons';
import DotSpinner from 'components/DotSpinner/DotSpinner';
import ProgressBar from 'components/ProgressBar/ProgressBar';
import { templateEditorConstants } from 'modules/templateEditor/templateEditorDuck';

const DEFAULT_ERROR_MSG = 'Opps, an error occurred while uploading';
const FONT_ERROR_MSG = 'Opps, you already have this font available to you.';

const UploadFont = ({
  isOpen,
  onClose,
  file,
  setActiveSubMenu,
  handleFontFamilyChange,
  activeSubMenu,
  onSuccess,
}) => {
  const [isLoading, setIsLoading] = React.useState(false);
  const [progress, setProgress] = React.useState(0);
  const [error, setError] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState(DEFAULT_ERROR_MSG);
  const interval = React.useRef(null);
  const dispatch = useDispatch();

  const uploadFontFile = async () => {
    try {
      if (!file) return;
      const formData = new FormData();
      setIsLoading(true);
      initFakeProgress();
      formData.append('file', file);
      await new Promise(resolve => {
        dispatch(
          addUserFont(
            formData,
            templateEditorConstants.ADD_USER_FONTS,
            res => finishFakeProgress(res),
            async err => {
              const json = await err.json();
              // const errMsg = json?.error?.data?.fullname?.length
              //   ? json.error.data.fullname[0]
              //   : '';
              // console.error(errMsg);
              setErrorMessage(
                json?.error?.code === 2003 ? FONT_ERROR_MSG : DEFAULT_ERROR_MSG
              );
              setError(true);
              setIsLoading(false);
            }
          )
        );
      });
    } catch (e) {
      setErrorMessage(DEFAULT_ERROR_MSG);
      setError(true);
      setIsLoading(false);
      console.log('error', e);
    }
  };

  const initFakeProgress = () => {
    if (interval.current) clearInterval(interval.current);

    let value = 0;
    interval.current = setInterval(() => {
      if (value >= 80) {
        clearInterval(interval.current);
      } else {
        value += 2;
        setProgress(value);
      }
    }, 100);
  };

  const finishFakeProgress = res => {
    if (interval?.current) {
      clearInterval(interval.current);
    }

    let value = progress;
    interval.current = setInterval(() => {
      if (value >= 100) {
        clearInterval(interval.current);

        !!onSuccess && onSuccess();
        handleFontFamilyChange(res);
        if (activeSubMenu) {
          setActiveSubMenu(null);
          setActiveSubMenu('FontFamily');
        }

        closeModal();
      } else {
        value += 4;
        setProgress(value);
      }
    }, 100);
  };

  const closeModal = () => {
    setProgress(0);
    setError(false);
    setIsLoading(false);
    interval.current && clearInterval(interval.current);
    onClose();
  };

  useEffect(() => {
    const onEscape = ev => ev.key === 'Escape' && closeModal();
    window.addEventListener('keydown', onEscape);
    return () => window.removeEventListener('keydown', onEscape);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box
      height="100%"
      width="100%"
      zIndex={500}
      position="absolute"
      top={0}
      left={0}
      display={isOpen ? 'grid' : 'none'}
      placeContent="center"
      backgroundColor="rgba(0,0,0,.5)"
    >
      <Box
        w="450px"
        backgroundColor="white"
        fontWeight="bold"
        borderRadius="7px"
        display={isOpen ? 'block' : 'none'}
        position="relative"
      >
        {/* Close button */}
        {isLoading && (
          <Icons.Close
            height={15}
            style={{
              cursor: 'pointer',
              position: 'absolute',
              top: '1rem',
              right: '10px',
            }}
            onClick={closeModal}
          />
        )}

        {/* Modal Header */}
        <Box
          textAlign="center"
          m="0"
          p={!isLoading ? '10px 0 0' : '30px 0 10px'}
        >
          {!isLoading && !error && (
            <Icons.Checked
              height={40}
              style={{ display: 'block', margin: '0 auto' }}
            />
          )}

          {!isLoading && error && (
            <Icons.Warning
              height={48}
              style={{ display: 'block', margin: '0 auto' }}
            />
          )}
          <Text
            color="#293854"
            fontSize="18px"
            fontWeight="700"
            p="0"
            m="5px 0 0 "
          >
            {!isLoading
              ? !error
                ? 'Please Confirm'
                : `${errorMessage}`
              : 'Uploading'}
          </Text>
        </Box>

        {/* Modal Body */}

        <Box
          m="0"
          color="#293854"
          fontSize="1rem"
          fontWeight={400}
          textAlign="center"
          lineHeight="1.5rem"
          p="8px 24px"
        >
          {!isLoading &&
            !error &&
            'By uploading this font, I acknowledge that I own or have the right to use it for my intended purposes.'}

          {isLoading && !error && (
            <Flex
              w="100%"
              direction="column"
              align="center"
              pb="25px"
              mt="1rem"
            >
              <DotSpinner />
              <Box mt="2.5rem" w="100%">
                <ProgressBar value={progress} />
              </Box>
            </Flex>
          )}

          {error && 'Please try again.'}
        </Box>

        {!isLoading && <hr style={{ margin: 0 }} />}

        {/* Modal Foter */}

        {!isLoading && (
          <Box pt="21px" pb="1.1rem" p="21px 20px 1.1rem">
            <Flex align="center" w="100%">
              <Button
                flex="1"
                p="0.65rem 1rem"
                fontWeight={600}
                borderRadius="4px"
                fontSize="1rem"
                mr={!error ? '12px' : '0'}
                onClick={closeModal}
                bgColor={error ? '#ff6f0f' : ''}
                border="none"
                outline="none"
                cursor="pointer"
                _hover={{
                  backgroundColor: error ? '#ff6f0fe5' : '',
                }}
                color={error ? 'white' : ''}
              >
                Ok
              </Button>
              {!error && (
                <Button
                  flex="1"
                  p="0.65rem 1rem"
                  fontWeight={600}
                  borderRadius="4px"
                  fontSize="1rem"
                  bgColor="#ff6f0f"
                  color="white"
                  border="none"
                  outline="none"
                  cursor="pointer"
                  _hover={{
                    backgroundColor: '#ff6f0fe5',
                  }}
                  onClick={uploadFontFile}
                >
                  Yes, upload!
                </Button>
              )}
            </Flex>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default UploadFont;
