import { fabric } from 'fabric';

export class FrameObject extends fabric.Rect {
  static type = 'Frame';

  initialize(options) {
    super.initialize({
      ...options,
      selectable: true,
      hasControls: false,
      lockMovementY: true,
      lockMovementX: true,
      hoverCursor: 'default',
      absolutePositioned: true,
    });

    this.on('mousewheel', function () {
      this.canvas.renderAll();
      this._renderControls(this.canvas.contextTop, {
        hasControls: false,
      });
    });

    this.on('mousedown', e => {
      // this.canvas.discardActiveObject(e);
    });

    this.on('mouseup', e => {
      const canvasEl = document.getElementById('canvas');
      const canvasPosition = {
        left: canvasEl?.getBoundingClientRect().left,
        top: canvasEl?.getBoundingClientRect().top,
      };
      const eventData = {
        object: this,
        visible: false,
        position: {
          left: canvasPosition.left + e.pointer.x + 8,
          top: canvasPosition.top + e.pointer.y - 24,
        },
      };
      if (e.button === 1) {
        eventData.visible = false;
      } else if (e.button === 3) {
        eventData.visible = true;
      }
      this.canvas.fire('object:rightclicked', eventData);
    });

    return this;
  }

  toObject(propertiesToInclude = []) {
    return super.toObject(propertiesToInclude, { clipPath: this.clipPath });
  }
  toJSON(propertiesToInclude = []) {
    return super.toObject(propertiesToInclude, { clipPath: this.clipPath });
  }
  static fromObject(options, callback) {
    return callback && callback(new fabric.Frame(options));
  }
}

fabric.Frame = fabric.util.createClass(FrameObject, {
  type: FrameObject.type,
});

fabric.Frame.fromObject = FrameObject.fromObject;
