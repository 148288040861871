import Image from "./Image"
import Text from "./Text"
import Default from "./Default"

class ToolboxObjects {
    static DynamicImage = Image
    static StaticImage = Image
    static DynamicText = Text
    static StaticText = Text
    static Default = Default
    static ConditionalText = Text
    static ConditionalImage = Image
    static ConditionalQr = Image
}
export default ToolboxObjects