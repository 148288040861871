import { fabric } from 'fabric';
import {
  defaultFrameOptions,
  DEFAULT_HEIGHT,
  DEFAULT_WIDTH,
  FRAMES_MARGIN,
  objectTypes,
  PROPERTIES_TO_INCLUDE,
  SCALE_FACTOR,
} from 'components/Canvas/common/constants';
import BaseHandler from './BaseHandler';
import { loadImageFromURL } from '../utils/loader';
import { uniqueId } from 'lodash';

class FrameHandler extends BaseHandler {
  frame;
  options;
  sizeFormat;
  backgroundimage;

  constructor(props) {
    super(props);
    this.options = defaultFrameOptions;
    this.initialize();
  }

  initialize() {
    this.sizeFormat = this.context.defaultSizeFormat;
    const scaledSize = this.scaleDimension(this.sizeFormat);
    const shadow = new fabric.Shadow({
      color: '#F4F4F4',
      blur: 5,
    });

    const frame = new fabric.Frame({
      ...defaultFrameOptions,
      ...scaledSize,
      shadow,
      id: 'frontSide',
    });
    this.canvas.add(frame);
    frame.center();
    this.options = Object.assign(this.options, scaledSize);
    setTimeout(() => {
      if (
        this.handlers.scrollbarHandler &&
        this.handlers.scrollbarHandler.updateScrollPosition
      ) {
        this.handlers.scrollbarHandler.updateScrollPosition();
      }
    }, 100);
  }

  validateButtonWithZoom = () => {
    const zoomRatio = this.canvas.getZoom();
    const frontFrameTitle = document.getElementById('front-title');
    const frontFrameButtons = document.getElementById('front-buttons');
    const backFrameTitle = document.getElementById('back-title');
    const backFrameButtons = document.getElementById('back-buttons');

    const frame = this.getFrame();

    const isPortrait = frame.height > frame.width;
    const defaultHeight = isPortrait ? DEFAULT_HEIGHT : DEFAULT_WIDTH;
    const maxZoom = (defaultHeight * SCALE_FACTOR) / frame.height;

    if (zoomRatio <= maxZoom) {
      frontFrameTitle.style.display = 'none';
      frontFrameButtons.style.display = 'none';
      backFrameTitle.style.display = 'none';
      backFrameButtons.style.display = 'none';
    } else {
      frontFrameTitle.style.display = 'flex';
      frontFrameButtons.style.display = 'flex';
      backFrameTitle.style.display = 'flex';
      backFrameButtons.style.display = 'flex';
    }
  };

  setAdTemplatePageId(frontId, backId) {
    let frames = this.getFrames();
    let frontFrame = frames[0];
    let backFrame = frames[1];

    frontFrame.set('ad_template_page_id', frontId);
    backFrame.set('ad_template_page_id', backId);
  }

  setButtonsPosition() {
    let newFramesButtons = document.getElementById('add-duplicate-frames');
    let addNewPageButton = document.getElementById('add-new-frame');
    let frontFrameTitle = document.getElementById('front-title');
    let frontFrameButtons = document.getElementById('front-buttons');
    let backFrameTitle = document.getElementById('back-title');
    let backFrameButtons = document.getElementById('back-buttons');
    let panelWidth =
      document.getElementById('panel-container')?.clientWidth || 0;
    let toolBoxHeight =
      document.getElementById('toolboxWrapper')?.clientHeight || 0;

    newFramesButtons.style.display = 'block';
    addNewPageButton.style.display = 'block';

    const frames = this.getFrames();
    const frameRect = frames[0].getBoundingRect();

    if (frames.length > 1) {
      const backFrameRect = frames[1].getBoundingRect();

      newFramesButtons.style.display = 'none';
      addNewPageButton.style.display = 'none';
      frontFrameTitle.style.display = 'block';
      frontFrameButtons.style.display = 'flex';
      backFrameTitle.style.display = 'flex';
      backFrameButtons.style.display = 'flex';

      frontFrameTitle.style.width = `${frameRect.width / 2}px`;
      backFrameTitle.style.width = `${frameRect.width / 2}px`;

      const marginButton = 8;
      const btnMb = 2;

      const frontTitleTop =
        frameRect.top +
        toolBoxHeight -
        frontFrameTitle.clientHeight -
        marginButton;
      const frontTitleLeft = frameRect.left + panelWidth;
      const frontFrameButtonsTop =
        frameRect.top + toolBoxHeight - frontFrameButtons.clientHeight - btnMb;
      const frontFrameButtonsLeft =
        frameRect.left +
        panelWidth +
        frameRect.width -
        frontFrameButtons.clientWidth;

      const backTitleTop =
        backFrameRect.top +
        toolBoxHeight -
        backFrameTitle.clientHeight -
        marginButton;
      const backTitleLeft = backFrameRect.left + panelWidth;
      const backFrameButtonsTop =
        backFrameRect.top +
        toolBoxHeight -
        backFrameButtons.clientHeight -
        btnMb;
      const backFrameButtonsLeft =
        backFrameRect.left +
        panelWidth +
        backFrameRect.width -
        backFrameButtons.clientWidth;

      // front  frame title
      frontFrameTitle.style.top = `${frontTitleTop}px`;
      frontFrameTitle.style.left = `${frontTitleLeft}px`;

      frontFrameButtons.style.top = `${frontFrameButtonsTop}px`;
      frontFrameButtons.style.left = `${frontFrameButtonsLeft}px`;

      // back  frame title
      backFrameTitle.style.top = `${backTitleTop}px`;
      backFrameTitle.style.left = `${backTitleLeft}px`;

      backFrameButtons.style.top = `${backFrameButtonsTop}px`;
      backFrameButtons.style.left = `${backFrameButtonsLeft}px`;

      // this.validateButtonWithZoom();
    }

    if (frames.length <= 1) {
      newFramesButtons.style.display = 'block';
      addNewPageButton.style.display = 'block';
      frontFrameTitle.style.display = 'none';
      frontFrameButtons.style.display = 'none';
      backFrameTitle.style.display = 'none';
      backFrameButtons.style.display = 'none';

      const buttonsMarginBottom = 10;

      newFramesButtons.style.top = `${
        frameRect.top +
        toolBoxHeight -
        newFramesButtons.clientHeight -
        buttonsMarginBottom
      }px`;
      newFramesButtons.style.left = `${
        frameRect.left +
        panelWidth +
        frameRect.width -
        newFramesButtons.clientWidth
      }px`;

      let addNewPageButtonMarginTop = 20;

      addNewPageButton.style.top =
        toolBoxHeight +
        frameRect.height +
        addNewPageButtonMarginTop +
        frameRect.top +
        'px';
      addNewPageButton.style.left = frameRect.left + panelWidth + 'px';
      addNewPageButton.style.width = frameRect.width + 'px';
      addNewPageButton.style.margin = 0;
    }
  }

  update = options => {
    this.sizeFormat = options;
    const frames = this.getFrames();
    const { width, height } = this.scaleDimension(this.sizeFormat);
    this.options = Object.assign(this.options, {
      width,
      height,
      isPortrait: options.isPortrait,
    });

    const frontFrame = frames[0];

    frontFrame.set('width', width);
    frontFrame.set('height', height);
    frontFrame.center();

    this.canvas.clipPath = new fabric.Rect({
      width: frontFrame.width + 5,
      height: frontFrame.height + 4,
      left: frontFrame.left - 3,
      top: frontFrame.top - 2,
    });
    const clipPathPieces = [this.canvas.clipPath];
    if (frames.length !== 1) {
      const backFrame = frames[1];
      backFrame.set('width', width);
      backFrame.set('height', height);
      backFrame.center();

      backFrame.set({
        top: frontFrame.top + frontFrame.height + this.getFrameMargin(),
      });
      clipPathPieces.push(
        new fabric.Rect({
          width: backFrame.width + 5,
          height: backFrame.height + 4,
          left: backFrame.left - 3,
          top: backFrame.top - 2,
        })
      );
    }
    this.canvas.clipPath = new fabric.Group(clipPathPieces);
    this.context.setSizeFormat(options);
    this.handlers.historyHandler.save('frame:update');
    this.handlers.gridHandler.draw();
    this.setButtonsPosition();
  };

  setBackgroundColor = color => {
    const frame = this.getFrameByScrollPosition();
    frame.set('fill', color);
    this.canvas.renderAll();
  };

  getBackgroundColor = () => {
    const frame = this.getFrameByScrollPosition();
    return frame.fill;
  };

  setHoverCursor = cursor => {
    const frame = this.getFrame();
    if (frame) {
      frame.set('hoverCursor', cursor);
    }
  };

  setBackgroundImageURL = async (url, name) => {
    if (name) {
      this.backgroundFileName = name;
    } else {
      const urlParams = url.split('/');
      const fileName = urlParams[urlParams.length - 1];
      this.backgroundFileName = fileName;
    }
    this.removeBackgroundImage();
    const frame = this.getFrameByScrollPosition();
    const image = await loadImageFromURL(url);
    const element = new fabric.BackgroundImage(image);

    const scale = SCALE_FACTOR;

    const heightDiff = frame.height - element.height * scale;
    const widthDiff = frame.width - element.width * scale;
    let top = frame.top + heightDiff / 2;
    let left = frame.left + widthDiff / 2;

    element.set({
      top,
      left,
      scaleX: scale,
      scaleY: scale,
    });

    element.clipPath = frame;
    // element.scaleToWidth(frame.width);
    this.canvas.add(element);
    // element.center();
    if (this.editor.doubleSided === false) {
      element.moveTo(1);
    }
  };

  setBackgroundColorDoubleSided = colors => {
    const frames = this.getFrames();
    frames[0].set('fill', colors[0]);
    if (frames.length > 1) {
      frames[1].set('fill', colors[1]);
    }
    this.canvas.renderAll();
  };

  setBackgroundDoubleSided = async backgroundImages => {
    for (let i = 0; i < backgroundImages.length; i++) {
      if (backgroundImages[i] !== null) {
        if (backgroundImages[i].name) {
          this.backgroundFileName = backgroundImages[i].name;
        } else {
          const urlParams = backgroundImages[i].split('/');
          const fileName = urlParams[urlParams.length - 1];
          this.backgroundFileName = fileName;
        }

        const frame = this.getFrames()[i];
        const image = await loadImageFromURL(backgroundImages[i]);
        const element = new fabric.BackgroundImage(image);

        const scale = SCALE_FACTOR;

        const heightDiff = frame.height - element.height * scale;
        const widthDiff = frame.width - element.width * scale;
        let top = frame.top + heightDiff / 2;
        let left = frame.left + widthDiff / 2;

        element.set({
          top,
          left,
          scaleX: scale,
          scaleY: scale,
        });

        element.clipPath = frame;
        // element.scaleToWidth(frame.width);
        this.canvas.add(element);
        // element.center();
        if (this.editor.doubleSided === false) {
          element.moveTo(1);
        }
      }
    }
  };

  create = options => {
    const shadow = new fabric.Shadow({
      color: '#afafaf',
      blur: 2.5,
    });
    this.sizeFormat = options;
    const scaledSize = this.scaleDimension(this.sizeFormat);
    const frame = new fabric.Frame({
      ...defaultFrameOptions,
      ...scaledSize,
      shadow,
    });
    this.canvas.add(frame);
    frame.center();
    this.options = Object.assign(this.options, scaledSize);
    this.context.setSizeFormat(options);
  };

  addNewFrame = () => {
    const scaledSize = this.scaleDimension(this.sizeFormat);
    const [frontFrame] = this.getFrames();
    const frameMargin = this.getFrameMargin();
    if (!this.canvas.clipPath)
      this.canvas.clipPath = new fabric.Rect({
        width: frontFrame.width + 5,
        height: frontFrame.height + 4,
        left: frontFrame.left - 3,
        top: frontFrame.top - 2,
      });
    const clipPathPieces = [this.canvas.clipPath];

    frontFrame.clone(backFrame => {
      backFrame.set({
        id: 'backSide',
        fill: '#ffffff',
        top: frontFrame.height + frontFrame.top + frameMargin,
      });
      this.canvas.add(backFrame);
      backFrame.sendToBack();
      backFrame.bringForward();
      this.options = Object.assign(this.options, scaledSize);
      this.enabledDoubleSided(true);
      this.handlers.scrollbarHandler.updateScrollPosition();
      this.handlers.historyHandler.save('object:created');
      // add back frame to clip path
      clipPathPieces.push(
        new fabric.Rect({
          width: backFrame.width + 5,
          height: backFrame.height + 4,
          left: backFrame.left - 3,
          top: backFrame.top - 2,
        })
      );
    });
    this.canvas.clipPath = new fabric.Group(clipPathPieces);
  };

  duplicate = () => {
    const scaledSize = this.scaleDimension(this.sizeFormat);
    const frames = this.getFrames();
    let currentElements = this.canvas.getObjects();
    const frameMargin = this.getFrameMargin();

    const clipPathPieces = [this.canvas.clipPath];
    frames[0].clone(backFrame => {
      backFrame.set({
        id: 'backSide',
        top: frames[0].height + frames[0].top + frameMargin,
      });

      this.canvas.add(backFrame);

      backFrame.sendToBack();
      backFrame.bringForward();

      currentElements.map((element, i) => {
        if (element.type === 'Frame') return false;
        element.clone(clonedElement => {
          clonedElement.set({
            id: uniqueId(),
            top: frames[0].height + clonedElement.top + frameMargin,
            clipPath: backFrame,
            subtype: element.subtype,
            metadata: element.metadata,
          });

          if (clonedElement.type === 'StaticText') {
            clonedElement.set({ initialWidth: element.width });
          }

          this.canvas.add(clonedElement);

          if (i === currentElements.length - 1) {
            this.options = Object.assign(this.options, scaledSize);
            this.enabledDoubleSided(true);
            this.handlers.scrollbarHandler.updateScrollPosition();
            setTimeout(
              () => this.handlers.historyHandler.save('object:created'),
              1000
            );
          }
        });
        return true;
      });
      // add back frame to clip path
      clipPathPieces.push(
        new fabric.Rect({
          width: backFrame.width + 5,
          height: backFrame.height + 4,
          left: backFrame.left - 3,
          top: backFrame.top - 2,
        })
      );
    });
    this.canvas.clipPath = new fabric.Group(clipPathPieces);
  };

  getFrameMargin = () => {
    const { width, height } = this.getFrame();

    const frameHeight = height > width ? height : width;

    return (frameHeight * FRAMES_MARGIN) / DEFAULT_HEIGHT;
  };

  getBackgroundImage = () => {
    const currentFrame = this.getFrameByScrollPosition();

    return this.canvas
      .getObjects()
      .find(
        object =>
          object.type === 'BackgroundImage' &&
          object.clipPath.id === currentFrame.id
      );
  };

  removeBackgroundImage = () => {
    const backgroundImage = this.getBackgroundImage();
    if (backgroundImage) {
      this.canvas.remove(backgroundImage);
    }
  };

  get = () => {
    return this.canvas
      .getObjects()
      .find(object => object.type === objectTypes.FRAME);
  };

  getFrame = () => {
    return this.canvas
      .getObjects()
      .find(object => object.type === objectTypes.FRAME);
  };

  getFrames = () => {
    return this.canvas
      .getObjects()
      .filter(object => object.type === objectTypes.FRAME);
  };

  isFrontFrameMoreVisible = () => {
    const frames = this.getFrames();

    if (frames.length === 1) {
      return true;
    }

    if (frames.length > 1) {
      const framesHeight = frames[0].height + frames[1].height;
      const canvasCenter = this.canvas.getCenter();
      const zoomRatio = this.canvas.getZoom();

      const panningOffset =
        canvasCenter.top - (frames[0].top + framesHeight / 2) * zoomRatio;
      const pannintTop = this.canvas.viewportTransform[5] - panningOffset;

      return pannintTop >= 0;
    }
  };

  getFrameByScrollPosition = () => {
    const frames = this.getFrames();
    if (this.isFrontFrameMoreVisible()) {
      return frames[0];
    } else {
      return frames[1];
    }
  };

  isMoreThan20PercentVisible = frameSide => {
    const frames = this.getFrames();
    const frontFrame = frames[0];
    const backFrame = frames[1];

    const focusedFrame = frameSide === 'front' ? frontFrame : backFrame;

    const frameCoors = focusedFrame.getBoundingRect();
    const frameHeight = frameCoors.height;
    const frameTop = frameCoors.top;
    const canvasHeight = this.canvas.getHeight();

    if (frameSide === 'front') {
      return frameTop > frameHeight * 0.8 * -1;
    }

    return frameTop <= canvasHeight - frameHeight * 0.2;
  };

  getOptionsByScrollPosition = () => {
    const frames = this.getFrames();
    const backFrame = frames[1];
    const activeObject = this.canvas.getActiveObject();
    let frameOptions = this.getOptions();
    /**
     * If an element is selected
     * the frame remains active unless it's less than 20%
     */
    if (frames.length > 1 && activeObject) {
      const isBackFrame = backFrame.top <= activeObject.top;

      if (isBackFrame) {
        const isFrameMoreThan20percent =
          this.isMoreThan20PercentVisible('back');

        if (isFrameMoreThan20percent) {
          frameOptions = this.getBackFrameOptions();
        } else {
          frameOptions = this.getOptionsFrame();
        }
      } else {
        const isFrameMoreThan20percent =
          this.isMoreThan20PercentVisible('front');
        if (isFrameMoreThan20percent) {
          frameOptions = this.getOptionsFrame();
        } else {
          frameOptions = this.getBackFrameOptions();
        }
      }
    }

    return frameOptions;
  };

  getFocusedFrame = isBackFrame => {
    const frames = this.getFrames();
    const frontFrame = frames[0];
    const backFrame = frames[1];
    const activeObject = this.canvas.getActiveObject();

    let focusedFrame = frontFrame;

    if (frames.length > 1) {
      if (activeObject) {
        focusedFrame = isBackFrame ? backFrame : frontFrame;
      } else {
        focusedFrame = !this.isFrontFrameMoreVisible() ? backFrame : frontFrame;
      }
    }

    return focusedFrame;
  };

  getCenterY = ({ isBackFrame, frameOptions }) => {
    const canvasHeight = this.canvas.getHeight();
    const centerTop = frameOptions.height;
    const zoomRatio = this.canvas.getZoom();

    let focusedFrame = this.getFocusedFrame(isBackFrame);
    let frameTop = 0;

    const frameBR = focusedFrame.getBoundingRect();
    frameTop = frameBR.top;

    let y = frameOptions.top + centerTop / 2;

    if (frameTop !== 0) {
      const isScrolledDown = frameTop < 0;
      const isScrolledUp = frameTop > 0;
      const isNotFullVisible = frameBR.height + frameBR.top > canvasHeight;

      if (isScrolledDown && isNotFullVisible) {
        const top = frameOptions.top - frameTop / zoomRatio;
        y = top + canvasHeight / zoomRatio / 2;
      } else {
        const frameHeight = frameOptions.height;
        const absFrameTop = Math.abs(frameTop) / zoomRatio;

        if (isScrolledUp && isNotFullVisible) {
          const notVisibleFrameAreaHeight =
            frameHeight - canvasHeight / zoomRatio + absFrameTop;
          const visibleFrameAreaHeight =
            frameHeight - notVisibleFrameAreaHeight;
          const visibleFrameCenter = visibleFrameAreaHeight / 2;

          y = frameOptions.top + visibleFrameCenter;
        } else if (isScrolledDown) {
          const visibleFrameAreaHeight = frameHeight - absFrameTop;
          const visibleFrameCenter = visibleFrameAreaHeight / 2;

          y = frameOptions.top + visibleFrameCenter + absFrameTop;
        }
      }
    }

    return y;
  };

  reset = () => {
    const frame = this.get();
    frame.set('fill', defaultFrameOptions.fill);
  };

  remove = id => {
    if (id === 'backSide') {
      const frontFrame = this.getFrames()[0];
      const frame = this.canvas
        .getObjects()
        .find(object => object.type === objectTypes.FRAME && object.id === id);

      this.canvas
        .getObjects()
        .filter(
          object => object.type !== objectTypes.FRAME && frame.top <= object.top
        )
        .map(object => this.canvas.remove(object));

      this.canvas.remove(frame);
      this.canvas.clipPath = new fabric.Rect({
        width: frontFrame.width + 5,
        height: frontFrame.height + 4,
        left: frontFrame.left - 3,
        top: frontFrame.top - 2,
      });
      this.enabledDoubleSided(false);
    }

    if (id === 'frontSide' || id === 'Frame1') {
      const frontFrame = this.getFrames()[0];
      const backFrame = this.getFrames()[1];

      const frameMargin = this.getFrameMargin();

      this.canvas
        .getObjects()
        .filter(
          object =>
            object.type !== objectTypes.FRAME &&
            frontFrame.top <= object.top &&
            backFrame.top > object.top
        )
        .map(object => this.canvas.remove(object));

      this.canvas
        .getObjects()
        .filter(
          object =>
            object.type !== objectTypes.FRAME && backFrame.top <= object.top
        )
        .map(object =>
          object.set({ top: object.top - frontFrame.height - frameMargin })
        );

      const frontFrameTop = frontFrame.top;
      this.canvas.remove(frontFrame);
      this.canvas.clipPath = new fabric.Rect({
        width: frontFrame.width + 5,
        height: frontFrame.height + 4,
        left: frontFrame.left - 3,
        top: frontFrame.top - 2,
      });
      backFrame.set('id', 'frontSide');
      backFrame.set('top', frontFrameTop);
      this.enabledDoubleSided(false);
      const point = new fabric.Point(0, 0);
      this.canvas.relativePan(point);
      this.handlers.scrollbarHandler.updateScrollPosition();
    }
  };

  setSelectionBorder = () => {
    const frame = this.root.frameHandler.get();
    frame.setSelectionBorder();
  };

  getOptions = () => {
    const frame = this.getFrameByScrollPosition();
    return frame.toJSON(PROPERTIES_TO_INCLUDE);
  };

  getOptionsFrame = () => {
    const frame = this.getFrame();
    return frame.toJSON(PROPERTIES_TO_INCLUDE);
  };

  getBackFrameOptions = () => {
    const frame = this.getFrames()[1];
    return frame.toJSON(PROPERTIES_TO_INCLUDE);
  };

  scaleDimension = options => {
    const { pixelHeight, pixelWidth, isPortrait } = options;
    const height = isPortrait
      ? pixelHeight * SCALE_FACTOR
      : pixelWidth * SCALE_FACTOR;
    const width = isPortrait
      ? pixelWidth * SCALE_FACTOR
      : pixelHeight * SCALE_FACTOR;
    return {
      height,
      width,
      isPortrait,
    };
  };

  getFillRatio = () => {
    const canvasWidth = this.canvas.getWidth();
    const canvasHeight = this.canvas.getHeight();
    const options = this.getOptions();

    let fillZoomRatio = 1;
    fillZoomRatio = Math.max(
      (canvasHeight - 32) / options.height,
      (canvasWidth - 32) / options.width
    );
    return fillZoomRatio;
  };

  getFitRatio = () => {
    const canvasWidth = this.canvas.getWidth() - 32;
    const canvasHeight = this.canvas.getHeight() - 32;
    const options = this.getOptions();
    const frameMargin = this.getFrameMargin();

    let scaleX = canvasWidth / options.width;
    let scaleY =
      canvasHeight /
      (!this.editor.doubleSided
        ? options.height
        : options.height + frameMargin);

    if (options.height >= options.width) {
      scaleX = scaleY;
      if (canvasWidth < options.width * scaleX) {
        scaleX = scaleX * (canvasWidth / (options.width * scaleX));
      }
    } else {
      if (canvasHeight < options.height * scaleX) {
        scaleX = scaleX * (canvasHeight / (options.height * scaleX));
      }
    }
    return scaleX;
  };

  getInitialRatio = () => {
    const canvasWidth = this.canvas.getWidth() - 32;
    const canvasHeight = this.canvas.getHeight() - 32;
    const options = this.getOptions();

    const newFramesButtons = document.getElementById('add-duplicate-frames');
    const addNewPageButton = document.getElementById('add-new-frame');

    const addNewPageButtonHeight = addNewPageButton.clientHeight;
    const newFrameBtnsHeight = newFramesButtons.clientHeight;
    const btnsMB = 10;
    const newPageMT = 20;
    const margins = 60;
    const frameMargin = this.getFrameMargin();

    let height = options.height;
    const width = options.width;

    let scaleX = canvasWidth / width;

    let buttonsHeight =
      newFrameBtnsHeight +
      btnsMB +
      addNewPageButtonHeight +
      newPageMT +
      margins;

    buttonsHeight = (height * buttonsHeight) / DEFAULT_HEIGHT;

    let scaleY =
      canvasHeight /
      (!this.editor.doubleSided
        ? height + buttonsHeight
        : height + frameMargin);

    if (height >= width) {
      scaleX = scaleY;

      if (canvasWidth < width * scaleX) {
        scaleX = scaleX * (canvasWidth / (width * scaleX));
      }
    } else {
      if (canvasHeight < options.height * scaleX) {
        height = this.editor.doubleSided
          ? height + frameMargin
          : height + buttonsHeight;
        scaleX = scaleX * (canvasHeight / (height * scaleX));
      }
    }
    return scaleX;
  };

  enabledDoubleSided = value => {
    this.doubleSided = value;
    this.context.setDoubleSided(value);
    this.editor.doubleSided = value;
    this.setButtonsPosition();
  };

  setCurrentBackgroundColorByScrollPosition = () => {
    const frame = this.getFrameByScrollPosition();
    this.context.setCurrentBackgroundColor(frame.fill);
  };

  setCurrentBackgroundImageByScrollPosition = () => {
    const frame = this.getFrameByScrollPosition();
    const currentBackgroundImage = this.canvas
      .getObjects()
      .find(
        object =>
          object.type === 'BackgroundImage' && object.clipPath.id === frame.id
      );

    this.context.setCurrentBackgroundImage(currentBackgroundImage);
  };

  setActiveFrame = () => {
    const frames = this.getFrames();
    const activeFrame = this.getFrameByScrollPosition();
    const inactiveFrameShadow = {
      color: '#afafaf',
      blur: 2.5,
      id: 4,
    };

    const activeFrameShadow = {
      blur: 14,
      color: '#120808',
      id: 1,
    };

    if (frames.length === 1) {
      activeFrame.set('shadow', inactiveFrameShadow);
    }

    if (frames.length > 1) {
      activeFrame.set('shadow', activeFrameShadow);

      if (activeFrame !== frames[0]) {
        frames[0].set('shadow', inactiveFrameShadow);
      }

      if (activeFrame !== frames[1]) {
        frames[1].set('shadow', inactiveFrameShadow);
      }
    }
  };
}

export default FrameHandler;
