import React, { useEffect, useState } from 'react';
import { Link, withRouter, useHistory, useLocation } from 'react-router-dom';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import logo from '../../static/images/logo.png';
import InputFeedback from '../../components/InputFeedback/InputFeedback';
import { isEmpty } from 'lodash';
import Button from '../../components/Button/Button';
import FullScreen from '../../components/FullScreen/FullScreen';
import '../../theme/Forms.scss';
import colors from '../../theme/Colors.module.scss';

const defaults = { userEmail: '', userPassword: '' };
const schema = Yup.object().shape({
  userEmail: Yup.string()
    .email('Invalid email.')
    .required('Email is required.')
    .trim(),
  userPassword: Yup.string().required('Password is required.').trim(),
});

const Login = props => {
  const { login, token } = props;
  const history = useHistory();
  const { state } = useLocation();

  const [loggedIn, setLoggedIn] = useState(!!token);
  const [loginError, setLoginError] = useState(false);

  useEffect(() => {
    setLoggedIn(!!token);
  }, [token]);

  useEffect(() => {
    const route = !isEmpty(state)
      ? state.from.pathname + state.from.search
      : '/dashboard';
    loggedIn && history.push(route);
  }, [history, state, loggedIn]);

  const handleSubmit = (values, setSubmitting) => {
    const { userEmail, userPassword } = values;
    const userInfo = {
      user: {
        email: userEmail,
        password: userPassword,
      },
    };

    login(userInfo, status => {
      switch (status) {
        case 'success':
          setLoggedIn(true);
          break;
        case 'failure':
          setLoginError(true);
          break;
        default:
          console.log('Unexpected login response');
      }

      setSubmitting(false);
    });
  };

  const MaybeLoginError = ({ loginError }) => {
    return loginError ? (
      <div className="input-feedback">
        Invalid username or password. Please try again, or click "Forgot your
        password?" to initiate account recovery.
      </div>
    ) : (
      <span />
    );
  };

  return (
    <FullScreen className="authFormContainer">
      <Formik
        initialValues={defaults}
        validationSchema={schema}
        onSubmit={(values, { setSubmitting }) =>
          handleSubmit(values, setSubmitting)
        }
      >
        {form => (
          <Form className="authForm">
            <img className="authLogo" src={logo} alt="" />

            <MaybeLoginError loginError={loginError} />

            <Field type="email" name="userEmail" placeholder="Email*" />
            <InputFeedback name="userEmail" />

            <Field
              type="password"
              name="userPassword"
              placeholder="Password*"
            />
            <InputFeedback name="userPassword" />

            <Button
              type="submit"
              text="Log in"
              textColor={colors.white}
              btnStyle="solid"
              disabled={form.isSubmitting}
            />

            <p className="loginOptions">
              <Link to="/reset-password">Forgot your password?</Link>.
            </p>
          </Form>
        )}
      </Formik>
    </FullScreen>
  );
};

export default withRouter(Login);
