import createFlags from 'flag';
import environment from './environment';
import flagsJson from './flags.json';

/*
  This is a workaround to let us modify flags per user/session without needing
  to rebuild the bundle or add any backend support.

  Usage (in a browser devtools console):

  ```
  window.OVERRIDE_FLAG("isReadOnly", true);
  ```

  The page will reload with the new flag override set.  These overrides are clear on logout.
*/
const flagOverrides = (() => {
  try {
    const flagOverridesJson = window.localStorage.getItem('FLAG_OVERRIDES');

    return JSON.parse(flagOverridesJson);
  } catch (error) {
    console.log(error);
    return {};
  }
})();

window.OVERRIDE_FLAG = (flag, value) => {
  const flagOverridesJson = JSON.stringify({
    ...flagOverrides,
    [flag]: value,
  });

  window.localStorage.setItem('FLAG_OVERRIDES', flagOverridesJson);
  // window.location = window.location;
};

// Combine global flags, flags per environment, and local flag overrides
const flags = {
  features: {
    ...flagsJson.all,
    ...flagsJson[environment],
    ...flagOverrides,
  },
};

const { FlagsProvider, Flag, useFlag, useFlags } = createFlags(flags);

export { FlagsProvider, Flag, useFlag, useFlags, flags };

export const getFlag = useFlag;
export const getFlags = useFlags;
