import { fabric } from 'fabric';

export class StaticImageObject extends fabric.Image {
  static type = 'StaticImage';
  subtype = 'img';
  label = '';

  initialize(element, options) {
    this.subtype = options.subtype;
    super.initialize(element, options);

    this.on('mousewheel', function () {
      this.canvas.renderAll();
      this._renderControls(this.canvas.contextTop, {
        hasControls: false,
      });
    });

    this.on('mouseup', e => {
      if (e.button === 3) {
        const canvasEl = document.getElementById('canvas');
        const canvasPosition = {
          left: canvasEl?.getBoundingClientRect().left,
          top: canvasEl?.getBoundingClientRect().top,
        };
        const eventData = {
          object: this,
          visible: true,
          position: {
            left: canvasPosition.left + e.pointer.x + 8,
            top: canvasPosition.top + e.pointer.y - 24,
          },
        };
        this.canvas.fire('object:rightclicked', eventData);
        this.canvas.setActiveObject(this);
      }
    });
    return this;
  }

  static fromObject(options, callback) {
    fabric.util.loadImage(options.src, function (img) {
      return callback && callback(new fabric.StaticImage(img, options));
    });
  }

  toObject(propertiesToInclude = []) {
    return super.toObject(propertiesToInclude, {
      subtype: this.subtype,
      clipPath: this.clipPath,
      label: this.label,
    });
  }
  toJSON(propertiesToInclude = []) {
    return super.toObject(propertiesToInclude, {
      subtype: this.subtype,
      clipPath: this.clipPath,
      label: this.label,
    });
  }
}

fabric.StaticImage = fabric.util.createClass(StaticImageObject, {
  type: StaticImageObject.type,
});
fabric.StaticImage.fromObject = StaticImageObject.fromObject;
