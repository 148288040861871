import classNames from 'classnames';
import { formatAOV, formatPercentage } from 'helpers/campaignHelper';

export default props => {
  const { foots } = props;

  return (
    <tfoot className={classNames(props.className, 'EntityTable-foot')}>
      <tr className="table-foot__table-row">
        {foots.map((foot, index) => {
          if (foot.field === 'name') {
            return (
              <th key={index} className="table-foot__table-head" scope="row">
                <span>{foot.name}</span>
              </th>
            );
          } else if (foot.field === 'run_dates') {
            return (
              <td key={index} className="table-foot__table-cell">
                {''}
              </td>
            );
          } else if (
            foot.field === 'packages' ||
            foot.field === 'qrScans' ||
            foot.field === 'conversions'
          ) {
            return (
              <td
                key={index}
                className="table-foot__table-cell table-cell__right"
              >
                {Number(foot[foot.field]).toLocaleString()}
              </td>
            );
          } else {
            return (
              <td
                key={index}
                className="table-foot__table-cell table-cell__right"
              >
                {foot.field === 'conversion_rate' &&
                  `${formatPercentage(foot[foot.field])}%`}
                {foot.field === 'avg' && `${formatAOV(foot[foot.field])}`}
                {foot.field !== 'conversion_rate' &&
                  foot.field !== 'avg' &&
                  foot[foot.field] !== null &&
                  foot[foot.field]}
                {foot[foot.field] === null && '--'}
              </td>
            );
          }
        })}
      </tr>
    </tfoot>
  );
};
