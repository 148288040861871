import { createSelector } from 'reselect';
import { createApiErrorSelector } from '../api/apiDuck';
import * as campaignsActions from './campaignsActions';

const getCampaignsState = state => state.modules.campaigns;

export const campaigns = createSelector(
  [getCampaignsState],
  state => state.campaigns
);
export const isCampaignLoading = createSelector(
  [getCampaignsState],
  state => state.campaignLoading
);
export const isCampaignsLoading = createSelector(
  [getCampaignsState],
  state => state.campaignsLoading
);
export const getPageData = createSelector(
  [getCampaignsState],
  state => state.currentPage
);
export const getSort = createSelector(
  [getCampaignsState],
  state => state.sort || []
);
export const getApiError = createApiErrorSelector([
  campaignsActions.getCampaigns.type,
  campaignsActions.createCampaign.type,
  campaignsActions.updateCampaign.type,
  campaignsActions.deleteCampaign.type,
  campaignsActions.getRetailers.type,
  campaignsActions.submitToRetailers.type,
]);
export const campaign = createSelector(
  [getCampaignsState],
  state => state.campaign
);
export const updatedCampaign = createSelector(
  [getCampaignsState],
  state => state.updatedCampaign
);
export const getErrors = createSelector(
  [getCampaignsState],
  state => state.campaignsError
);
export const uploadImage = createSelector(
  [getCampaignsState],
  state => state.currentCreative
);
export const setExistingCreative = createSelector(
  [getCampaignsState],
  state => state.currentCreative
);
export const retailers = createSelector(
  [getCampaignsState],
  state => state.retailers
);
export const getDateRange = createSelector(
  [getCampaignsState],
  state => state.dateRange || []
);
