import { findRectCenter } from './coordination';

export const isInsideFrontSideFrame = (frames, obj) => {
  if (frames.length <= 1) return true;
  else {
    let minDis = Infinity;
    let frameId = 0;
    frames.map((frame, index) => {
      const frameCenter = {
        x: frame.left + frame.width / 2,
        y: frame.top + frame.height / 2,
      };
      const objCenter = findRectCenter(
        obj.left,
        obj.top,
        obj.width,
        obj.height,
        obj.angle
      );
      const distance = Math.sqrt(
        Math.pow(objCenter.x - frameCenter.x, 2) +
          Math.pow(objCenter.y - frameCenter.y, 2)
      );
      if (minDis > distance) {
        minDis = distance;
        frameId = index;
      }
      return distance;
    });
    return (
      frames[frameId].id === 'Frame1' || frames[frameId].id === 'frontSide'
    );
  }
};
