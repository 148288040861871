import React from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  Image,
  Center,
} from '@chakra-ui/react';

function ImageModal({ isModalOpen, onModalClose, imageURL }) {
  return (
    <Modal isOpen={isModalOpen} onClose={onModalClose} size="lg" isCentered>
      <ModalOverlay />
      <ModalContent
        minWidth="fit-content"
        height="fit-content"
        bgColor={'transparent'}
        boxShadow={'transparent'}
      >
        <ModalBody>
          <Center>
            <Image src={imageURL} alt="Image" />
          </Center>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}

export default ImageModal;
