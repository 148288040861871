import { uniqBy } from 'lodash';

export const getValueType = type => {
  switch (type) {
    case 'img:dynamic':
      return 'img';
    case 'qrc:dynamic':
      return 'qrc';
    case 'barcode:dynamic':
      return 'barcode';
    case 'txt:static':
    case 'txt_template:dynamic':
      return 'text';
    default:
      return 'text';
  }
};

export const getVarKeys = template => {
  let dynamicElements = [];
  let ad_template_vars = template.ad_template_vars;

  if (template.double_sided === true) {
    if (template.ad_template_pages.length === 1) {
      ad_template_vars = template.ad_template_pages[0].ad_template_vars;
    } else if (template.ad_template_pages.length > 1) {
      ad_template_vars = template.ad_template_pages[0].ad_template_vars.concat(
        template.ad_template_pages[1].ad_template_vars
      );
    }
  }

  ad_template_vars.forEach(item => {
    if (item.keys) {
      if (item.metadata.keyValues && item.metadata.keyValues.length > 0) {
        const objectType = `${item.res_type}:${item.var_type}`;
        const type = getValueType(objectType);
        const allKeys = item.keys.map(key => {
          const keyValues = item.metadata.keyValues;
          let value = '';
          if (keyValues) {
            const keyValue = keyValues.find(k => k.key === '{{' + key + '}}');
            value = keyValue && keyValue.value ? keyValue.value : '';
          }
          return {
            type: type,
            key: key,
            id: [type, key].join(':'),
            value: value,
          };
        });
        dynamicElements = dynamicElements.concat(allKeys);
      }
    }
  });
  const uniqueElements = uniqBy(dynamicElements, 'id');
  return uniqueElements.sort((a, b) => {
    const keyName = 'key';

    if (a[keyName] > b[keyName]) return 1;
    if (a[keyName] < b[keyName]) return -1;

    return 0;
  });
};
