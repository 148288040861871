import React from 'react';
import ClipLoader from 'react-spinners/ClipLoader';
import { Flex, Box } from '@chakra-ui/react';

function LoadingDuplicatingModalContent() {
  return (
    <React.Fragment>
      <Flex justifyContent="center" padding="1rem 0 2rem">
        <ClipLoader size="32px" />
      </Flex>
      <Box paddingBottom="1rem">Please wait ...</Box>
    </React.Fragment>
  );
}

export default LoadingDuplicatingModalContent;
