import React from 'react';

function SortDesc({ height = 12, width = 14, onClick, style = {} }) {
  return (
    <svg
      width={width}
      height={height}
      style={{ cursor: 'pointer' }}
      onClick={onClick}
      viewBox="0 0 14 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.49153 0.719159L7.49153 9.71158L9.21913 8.1628C9.50444 7.90703 9.99084 7.90703 10.2758 8.1628C10.5611 8.4334 10.5611 8.85463 10.2758 9.11006L7.30697 11.7716V11.7867H7.29005V11.8019H7.27314V11.8171H7.25622V11.8322H7.22277V11.8474H7.20585V11.8625H7.18894V11.8777H7.15548V11.8929H7.13857V11.908H7.10511V11.9232H7.07166V11.9384H7.0382V11.9535H6.98783V11.9687H6.93747V11.9839H6.85364V11.999H6.63562V11.9839H6.5518V11.9687H6.50143V11.9535H6.45106V11.9384H6.41761V11.9232H6.38415V11.908H6.3507V11.8929H6.33378V11.8777H6.30033V11.8625H6.28341V11.8474H6.2665C6.23304 11.8322 6.21613 11.8022 6.18267 11.7722L3.21387 9.11073C2.92857 8.85496 2.92857 8.43407 3.21387 8.16347C3.49917 7.9077 3.98558 7.9077 4.27051 8.16347L5.99811 9.71225L5.99811 0.719832C5.99811 0.358923 6.33341 0.0583334 6.73599 0.0583334H6.7529C7.15548 0.0583334 7.49078 0.358923 7.49078 0.719832L7.49153 0.719159Z"
        fill={style.color}
      />
    </svg>
  );
}

export default SortDesc;
