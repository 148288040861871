import { connect } from 'react-redux';
import Templates from './Templates';
import {
  creativesActions,
  creativesSelectors,
} from '../../modules/creatives/creativesDuck';
import { apiActions } from '../../modules/api/apiDuck';
import { templateEditorActions } from 'modules/templateEditor/templateEditorDuck';
import * as appActions from 'modules/app/appActions';

// TODO: This is the old file from when templates was the creatives page
// Need to migrate any relevant actions/selectors from creativesDuck into new templateEditorDuck.
// Then remove this container and call actions/selectors using Redux hooks in Templates.js

function mapStateToProps(state) {
  return {
    creatives: creativesSelectors.creatives(state),
    isCreativesLoading: creativesSelectors.isCreativesLoading(state),
    createdCreative: creativesSelectors.createdCreative(state),
    pageData: creativesSelectors.getPageData(state),
    apiError: creativesSelectors.getApiError(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    testError: () => {
      dispatch(
        apiActions.apiAction({
          path: `notfound`,
          type: 'modules/creatives/GET_CREATIVES',
        })
      );
    },

    createCreative: (payload, doneCallback) =>
      dispatch(creativesActions.createCreative(payload, doneCallback)),
    createTemplate: (payload, doneCallback) =>
      dispatch(templateEditorActions.createTemplate(payload, doneCallback)),
    getTemplates: (searchParameters, successCallback, failureCallback) =>
      dispatch(
        creativesActions.getTemplates(
          searchParameters,
          successCallback,
          failureCallback
        )
      ),
    deleteTemplateById: (id, doneCallback = () => {}) => {
      dispatch(creativesActions.deleteTemplateById(id, doneCallback));
      dispatch(
        appActions.setMessage({
          message: 'Template removed successfully',
          type: 'success',
        })
      );
    },
    clearCreatedCreative: () =>
      dispatch(creativesActions.clearCreatedCreative()),
    clearApiError: actionType => dispatch(apiActions.clearApiError(actionType)),
  };
}

const TemplatesContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(Templates);

export default TemplatesContainer;
