import { Box, IconButton, Tooltip } from '@chakra-ui/react';
import React from 'react';
import { useEditor } from '../../../../components/Canvas';
import Delete from '../Icons/Delete';
import ArrowUp from '../Icons/ArrowUp';
import ArrowDown from '../Icons/ArrowDown';

function FrontFrameButtons() {
  const editor = useEditor();

  const removeFrame = () => {
    editor.handlers.frameHandler.remove('frontSide');
    editor.handlers.scrollbarHandler.updateScrollPosition();
  };

  const scrollToBottom = () => {
    editor.handlers.scrollbarHandler.jumpDown();
  };

  return (
    <Box
      sx={{
        position: 'absolute',
        fontWeight: 'bold',
        alignItems: 'center',
      }}
      id="front-buttons"
      onWheel={event => {
        editor.handlers.scrollbarHandler.onMouseWheel(event);
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          width: '90px',
          justifyContent: 'space-between',
        }}
      >
        <Tooltip hasArrow label="Move up" mt="10px">
          <IconButton
            cursor="not-allowed"
            borderColor="#A7A7A7"
            variant="ghost"
            size="sm"
            w="28px"
            h="28px"
            _hover={{ backgroundColor: 'none' }}
            _active={{ backgroundColor: 'none' }}
            icon={<ArrowUp size={16} style={{ fill: '#A0AEC0' }} />}
          />
        </Tooltip>

        <Tooltip hasArrow label="Move down" mt="10px">
          <IconButton
            borderColor="#A7A7A7"
            variant="ghost"
            size="sm"
            w="28px"
            h="28px"
            icon={<ArrowDown size={16} />}
            onClick={() => {
              scrollToBottom();
            }}
          />
        </Tooltip>

        <IconButton
          cursor="pointer"
          borderColor="#A7A7A7"
          variant="ghost"
          size="sm"
          w="28px"
          h="28px"
          icon={<Delete size={20} />}
          onClick={() => {
            removeFrame();
          }}
        />
      </Box>
    </Box>
  );
}

export default FrontFrameButtons;
