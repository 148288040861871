import React from 'react';
import useTemplateEditorContext from '../../hooks/useTemplateEditorContext';
import ClipLoader from 'react-spinners/ClipLoader';

import {
  Flex,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Box,
} from '@chakra-ui/react';

function LoadingModal() {
  const { isEditorLoading, isTemplateLoading } = useTemplateEditorContext();
  const { isOpen, onOpen, onClose } = useDisclosure();
  React.useEffect(() => {
    if (isEditorLoading || isTemplateLoading) {
      !isOpen && onOpen();
    } else {
      isOpen && onClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEditorLoading, isTemplateLoading]);

  const statusText = isEditorLoading
    ? 'Loading your assets'
    : 'Loading template';
  return (
    <Modal motionPreset="none" isCentered isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalBody>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              textAlign: 'center',
              fontsize: '14px',
              paddingBottom: '1.5rem',
              color: '#333333',
            }}
          >
            <Flex justifyContent="center" padding="2rem">
              <ClipLoader size="32px" />
            </Flex>
            <Box>{statusText}</Box>
            <Box>Please wait</Box>
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}

export default LoadingModal;
