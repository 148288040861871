import { uploadServiceEnv } from 'environment';
import { apiActions } from '../api/apiDuck';

export const login = (userInfo, type, doneCallback) => {
  return apiActions.apiAction({
    path: `session`,
    type,
    method: 'POST',
    body: JSON.stringify(userInfo),
    onSuccess: res => () => doneCallback('success', res),
    onFailure: err => () => doneCallback('failure', err),
  });
};

export const logout = type => {
  return apiActions.apiAction({
    path: `session`,
    type,
    method: 'DELETE',
  });
};

export const uploadTemplate = (formData, type, doneCallback) => {
  return apiActions.apiAction({
    path: `${uploadServiceEnv}/uploads`,
    type,
    method: 'POST',
    isUpload: true,
    body: formData,
    onSuccess: res => () => doneCallback(res),
  });
};

export const sendUploadInformation = (
  uploadInformation,
  type,
  doneCallback
) => {
  return apiActions.apiAction({
    path: `design_imports`,
    type,
    method: 'POST',
    body: JSON.stringify(uploadInformation),
    onSuccess: res => () => doneCallback(res),
  });
};

export const getDesignStatus = (id, type, doneCallback) => {
  return apiActions.apiAction({
    path: `design_imports/${id}`,
    type,
    method: 'GET',
    onSuccess: res => () => doneCallback(res),
  });
};

export const resumeDesignImport = (id, type, doneCallback) => {
  return apiActions.apiAction({
    path: `design_imports/${id}/resume`,
    type,
    method: 'PUT',
    onSuccess: res => () => doneCallback(res),
  });
};

export const checkToken = type => {
  return apiActions.apiAction({
    path: `accounts/me`,
    type,
  });
};

export const sendPwResetEmail = (payload, type, doneCallback) => {
  return apiActions.apiAction({
    path: 'accounts/resetpw',
    type,
    method: 'POST',
    body: JSON.stringify(payload),
    onSuccess: () => () => doneCallback(),
  });
};

export const verifyResetToken = (payload, type, doneCallback) => {
  return apiActions.apiAction({
    path: 'accounts/resetpw/verify',
    type,
    method: 'POST',
    body: JSON.stringify(payload),
    onSuccess: () => () => doneCallback(),
  });
};

export const changePassword = (payload, type, doneCallback) => {
  return apiActions.apiAction({
    path: 'accounts/resetpw/change',
    type,
    method: 'POST',
    body: JSON.stringify(payload),
    onSuccess: () => () => doneCallback(),
  });
};

export const setAppMode = (newMode, type) => {
  return {
    type,
    payload: newMode,
  };
};
