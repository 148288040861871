import React from 'react';
import Button from 'components/Button/Button';
import colors from 'theme/Colors.module.scss';

const DeleteModalContent = props => {
  const { name, id, callback } = props;

  return (
    <React.Fragment>
      <p>You are about to delete the following template:</p>
      <p>
        <strong> {name} </strong>
      </p>
      <p>Would you like to proceed?</p>

      <Button
        text="Delete Template"
        textColor="white"
        bgColor={colors.primary900}
        btnStyle="solid"
        callback={() => callback(id)}
      />
    </React.Fragment>
  );
};

export default DeleteModalContent;
