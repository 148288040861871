import * as yup from 'yup';

export const setFormInitialValues = values => {
  const scopes = !values.scopes || !values.scopes.length
    ? ''
    : values.scopes.length > 1
      ? 'all'
      : values.scopes[0];

  return {
    key: values?.key || '',
    label: values?.label || '',
    scopes
  };
};

export const setValidationSchema = () =>
  yup.object().shape({
    label: yup.string().required('Label cannot be blank.').trim(),
    key: yup.string().required('Key name cannot be blank.').trim(),
    scopes: yup.string().required('Scope name cannot be blank.').trim(),
  });
