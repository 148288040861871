import React from 'react';
import { useSelector } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';
import { isEmpty } from 'lodash';

import * as appSelectors from 'modules/app/appSelectors';

export default props => {
  const token = useSelector(appSelectors.getToken);
  const authenticated = !isEmpty(token);

  return (
    <Route
      path={props.path}
      exact={props.exact}
      render={routeProps => {
        if (!authenticated) {
          return (
            <Redirect
              to={{
                pathname: '/login',
                state: { from: routeProps.location },
              }}
            />
          );
        }

        const mergedProps = {
          ...props,
          ...routeProps,
        };

        if (props.render) {
          return props.render(mergedProps);
        } else if (props.component) {
          return React.createElement(props.component, mergedProps);
        }
      }}
    />
  );
};
