import React from "react"
import Icons from "../../../Icons"
import { IconButton, Tooltip } from '@chakra-ui/react'
import { useEditor } from "components/Canvas"

function Delete() {
    const editor = useEditor()
    return (
        <Tooltip label="Delete">
            <IconButton
                onClick={() => {
                    editor.delete()
                }}
                color="#000000"
                borderRadius="0"
                variant="ghost"
                size="sm"
                icon={<Icons.Delete size={24} />}
            />
        </Tooltip>
    )
}

export default Delete