import React, { useEffect, useState } from 'react';
import FlashMessage from '../../components/FlashMessage/FlashMessage';

const ApiErrorMessage = props => {
  const { apiError } = props;
  const [queue, setQueue] = useState([]);

  useEffect(() => {
    // apiError = {id, type, error: {}, message: --}
    if (apiError) {
      const errObj = apiError?.error || {};
      const url = errObj?.url || '';
      if (
        !url.endsWith(`v1/accounts`) &&
        !url.includes('v1/campaigns') &&
        !url.includes('v1/fonts')
      )
        setQueue([
          ...queue,
          { ...apiError, message: errObj?.statusText || '' },
        ]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apiError.id]);

  const remove = index => {
    setQueue(prev => {
      const current = [...prev];
      current.splice(index, 1);
      return current;
    });
  };

  return (
    <div id="flash-container" className="api-error-container">
      {queue.map(e => (
        <FlashMessage
          id={e.id}
          key={e.id}
          text={e.message}
          type="error"
          onExited={() => remove(e.id)}
        />
      ))}
    </div>
  );
};

export default ApiErrorMessage;
