import { Button } from '@chakra-ui/react';
import React from 'react';

export default function ButtonComponent({
  children,
  onClick,
  disabled = false,
  mouseDownColor,
  hoverColor,
  bgColor,
  ...props
}) {
  const [isMouseDown, setIsMouseDown] = React.useState(false);
  const onMouseDown = () => setIsMouseDown(true);
  const onMouseUp = () => setIsMouseDown(false);

  const btnColor = bgColor || '#ff6f0f';
  const btnHoverColor = hoverColor || '#ff6f0fe4';
  const btnMouseDownColor = mouseDownColor || '#ff6f0fb2';

  return (
    <Button
      bgColor={btnColor}
      p="0.65rem 1rem"
      color="white"
      borderRadius="4px"
      fontWeight={600}
      fontSize="1rem"
      outline="none"
      border="none"
      display="block"
      cursor={!disabled ? 'pointer' : 'default'}
      m="0"
      _hover={{
        backgroundColor: !isMouseDown ? btnHoverColor : btnMouseDownColor,
      }}
      onMouseDown={onMouseDown}
      onMouseUp={onMouseUp}
      onMouseLeave={onMouseUp}
      onClick={onClick}
      {...props}
    >
      {children}
    </Button>
  );
}
