import {
  Box,
  Button,
  Popover,
  PopoverTrigger,
  PopoverContent,
  Slider,
  SliderTrack,
  SliderFilledTrack,
  SliderThumb,
  useDisclosure,
  Flex,
} from '@chakra-ui/react';
import { useEditor } from 'components/Canvas';
import useEditorContext from 'components/Canvas/hooks/useEditorContext';
import React from 'react';
import { zoomLevels } from 'components/Canvas/common/constants';
import colors from 'theme/Colors.scss';

function Zoom() {
  const { zoomRatio, fitZoomRatio, fillZoomRatio, zoomMode } =
    useEditorContext();
  const editor = useEditor();

  const { isOpen, onToggle, onClose } = useDisclosure();

  const isZoomToFit =
    zoomRatio.toFixed(2) === fitZoomRatio.toFixed(2) && zoomMode === 'fit';
  const isZoomToFill =
    zoomRatio.toFixed(2) === fillZoomRatio.toFixed(2) && zoomMode === 'fill';

  return (
    <Box
      name="zoom"
      onClick={() => {
        editor.closeKeysMenu();
        editor.closeContextMenu();
      }}
      sx={{ position: 'fixed', bottom: '1rem', right: '2rem', zIndex: 100 }}
    >
      <Popover placement="top-end" isOpen={isOpen} onClose={onClose}>
        <PopoverTrigger>
          <Button display="flex" alignItems="center" onClick={onToggle}>
            {isOpen && (
              <Slider
                w="150px"
                mr="1rem"
                aria-label="zoom-slider"
                value={(zoomRatio * 100 * 100) / 300}
                onChange={value => {
                  const newValue = (value * 3) / 100;
                  editor.handlers.zoomHandler.zoomToRatio(
                    value >= 5 ? newValue : 0.1
                  );
                }}
                onClick={ev => {
                  ev.preventDefault();
                  ev.stopPropagation();
                }}
              >
                <SliderTrack h="2px">
                  <SliderFilledTrack bgColor="grey" />
                </SliderTrack>
                <SliderThumb bgColor="black" />
              </Slider>
            )}
            {`${Math.round(zoomRatio * 100)}%`}
          </Button>
        </PopoverTrigger>
        <PopoverContent sx={{ width: 'auto', background: '#ffffff' }}>
          <Box w="auto">
            {zoomLevels.map((zoomRatioVal, index) => {
              return (
                <Flex
                  key={index}
                  align="center"
                  p="8px 1rem"
                  cursor="pointer"
                  textAlign="center"
                  fontSize="14px"
                  bgColor={
                    zoomRatio.toFixed(2) === zoomRatioVal.toFixed(2) &&
                    colors.lighterGrey
                  }
                  _hover={{
                    backgroundColor: 'rgba(0,0,0,0.025)',
                  }}
                  onClick={() => {
                    editor.handlers.zoomHandler.zoomToRatio(zoomRatioVal);
                  }}
                >
                  {`${Math.round(zoomRatioVal * 100)}%`}
                </Flex>
              );
            })}

            <Box
              sx={{
                backgroundColor: isZoomToFit && colors.lighterGrey,
                fontSize: '14px',
                display: 'flex',
                padding: '8px 1rem',
                cursor: 'pointer',
                alignItems: 'center',
                textAlign: 'center',
              }}
              _hover={{
                backgroundColor: 'rgba(0,0,0,0.025)',
              }}
              onClick={() => {
                editor.handlers.zoomHandler.zoomToFit();
              }}
            >
              Fit
            </Box>

            <Box
              sx={{
                backgroundColor: isZoomToFill && colors.lighterGrey,
                fontSize: '14px',
                display: 'flex',
                padding: '8px 1rem',
                cursor: 'pointer',
                alignItems: 'center',
              }}
              _hover={{
                backgroundColor: 'rgba(0,0,0,0.025)',
              }}
              onClick={() => {
                editor.handlers.zoomHandler.zoomToFill();
              }}
            >
              Fill
            </Box>
          </Box>
        </PopoverContent>
      </Popover>
    </Box>
  );
}

export default Zoom;
