import React from 'react';

export const viewportSizeContext = React.createContext();

const getWindowWidth = () => window.innerWidth;
const getWindowHeight = () => window.innerHeight;

export const ViewportSizeProvider = ({ children }) => {
  const [width, setWidth] = React.useState(getWindowWidth());
  const [height, setHeight] = React.useState(getWindowHeight());

  const handleWindowResize = () => {
    setWidth(getWindowWidth());
    setHeight(getWindowHeight());
  };

  React.useEffect(() => {
    window.addEventListener('resize', handleWindowResize, {
      passive: true,
    });

    return () => window.removeEventListener('resize', handleWindowResize);
  }, []);

  return (
    <viewportSizeContext.Provider value={{ width, height }}>
      {children}
    </viewportSizeContext.Provider>
  );
};
