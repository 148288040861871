import React from 'react';
import NewAudience from 'scenes/audiences/NewAudience/NewAudience';

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalBody,
} from '@chakra-ui/react';

function CreateAudienceModal({
  match,
  isModalOpen,
  onModalClose,
  audienceId,
  onUpdateAudience,
}) {
  return (
    <Modal isOpen={isModalOpen} onClose={onModalClose} size="lg" isCentered>
      <ModalOverlay />
      <ModalContent
        minWidth="fit-content"
        height="fit-content"
        bgColor={'#F6F7F9'}
      >
        <ModalCloseButton />
        <ModalBody>
          <NewAudience
            match={match}
            audienceId={audienceId}
            onModalClose={onModalClose}
            onUpdateAudience={onUpdateAudience}
          />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}

export default CreateAudienceModal;
