import React from 'react';
import { Flex, FormLabel } from '@chakra-ui/react';
import Tooltip from 'components/Tooltip/Tooltip';

export const InputLabel = ({
  label,
  disclaimer,
  isSmall,
  tipText,
  tipDirection = 'bottom',
  ...props
}) => (
  <Flex justify={`${isSmall ? 'center' : 'flex-start'}`}>
    <FormLabel
      as="p"
      fontWeight="600"
      fontSize="0.875rem"
      color="black"
      {...props}
    >
      {label}
    </FormLabel>
    {disclaimer && (
      // <Icons.Disclaimer style={{ marginLeft: '-6px', cursor: 'pointer' }} />
      <Tooltip
        tipText={tipText}
        direction={tipDirection}
        bgColor="#394A64"
        style={{
          fontFamily: 'Inter',
        }}
      />
    )}
  </Flex>
);
