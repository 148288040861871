import React, { useEffect, useState } from 'react';
import {
  Box,
  Input,
  Text,
  InputGroup,
  InputLeftElement,
  useDisclosure,
} from '@chakra-ui/react';
import { templateEditorSelectors } from 'modules/templateEditor/templateEditorDuck';
import { useSelector, useDispatch } from 'react-redux';
import CustomScroller from 'components/CustomScroller';
import { downloadFonts, getTemplateFonts } from 'helpers/canvasHelpers';
import { useEditor } from 'components/Canvas';
import useTemplateEditorContext from '../../../hooks/useTemplateEditorContext';
import TemplateFilter from './components/TemplateFilter';
import Icons from '../../Icons';
import { creativesSelectors } from 'modules/creatives/creativesDuck';
import MigrateSizeFormatModal from './components/MigrateSizeFormatModal';
import * as appActions from 'modules/app/appActions';
// import PanelTitle from './components/PanelTitle';
import { isPresignedURLExpired } from 'helpers/presetDateHelpers';

const getThumbURL = name => {
  const thumbName = name.replace(/\s+/g, '-').toLowerCase();
  const baseURL = 'http://dzqi3b5s5jl7u.cloudfront.net/standard_templates/';
  const thumbURL = `${baseURL}${thumbName}-preview-thumb.jpg`;
  return thumbURL;
};

function Templates({ getTemplateById }) {
  const dispatch = useDispatch();
  const setMessage = payload => dispatch(appActions.setMessage(payload));
  const [payload, setPayload] = React.useState(null);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const sizeFormats = useSelector(state =>
    creativesSelectors.sizeFormats(state)
  );
  const { setIsTemplateLoading } = useTemplateEditorContext();
  const premadeTemplates = useSelector(state =>
    templateEditorSelectors.premadeTemplates(state)
  );
  const userFonts = useSelector(state =>
    templateEditorSelectors.userFonts(state)
  );
  const editor = useEditor();
  const [templates, setTemplates] = useState([]);
  const [query, setQuery] = useState('');
  const [filter, setFilter] = useState({});

  useEffect(() => {
    if (premadeTemplates) {
      const sortedTemplates = premadeTemplates?.data?.sort((a, b) => {
        const keyName = 'name';
        if (a[keyName] > b[keyName]) return 1;
        if (a[keyName] < b[keyName]) return -1;
        return 0;
      });
      if (!sortedTemplates) return;
      const templates = sortedTemplates.map(sortedTemplate => {
        return {
          ...sortedTemplate,
          previewURL: `url(${getThumbURL(sortedTemplate.name)})`,
        };
      });
      setTemplates(templates);
    }
  }, [premadeTemplates]);

  const loadTemplate = async args => {
    args.setMessage = setMessage;
    const isTemplateEnabled = sizeFormats.find(
      sf => sf.label === args.template.size_format.label
    );
    if (isTemplateEnabled) {
      setIsTemplateLoading(true);
      const bg_img_url = args.template.bg_img?.original_url || '';
      // Handle font expiration
      if (bg_img_url && bg_img_url.match(/s3.amazonaws.com/g)) {
        const isExpired = isPresignedURLExpired(bg_img_url);
        if (isExpired) {
          let template = await new Promise(resolve => {
            getTemplateById(args.template.id, data => {
              resolve(data);
            });
          });
          args.template = template;
        }
      }

      const fontsList = getTemplateFonts(args, userFonts);
      await downloadFonts(fontsList);
      await editor.importFromJSON(args);
      setIsTemplateLoading(false);
    } else {
      setPayload(args);
      onOpen();
    }
  };

  const isFiltered = element => {
    const ele = element.toLowerCase();
    const res = query.toLowerCase();
    return ele.includes(res) || ele === res;
  };

  return (
    <Box sx={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
      <MigrateSizeFormatModal
        loadTemplate={loadTemplate}
        args={payload}
        sizeFormats={sizeFormats}
        isOpen={isOpen}
        onOpen={onOpen}
        onClose={onClose}
      />
      <Box sx={{ padding: '1.5rem' }}>
        <InputGroup>
          <InputLeftElement
            pointerEvents="none"
            children={<Icons.Search size={24} color="gray.300" />}
          />
          <Input
            value={query}
            onChange={e => setQuery(e.target.value)}
            placeholder="Search templates"
            sx={{
              backgroundColor: '#ffffff',
              ':focus': {
                borderColor: 'rgb(255, 111, 15)',
                boxShadow: '0 0 0 1px rgb(255, 111, 15)',
              },
            }}
          />
          <TemplateFilter
            setFilter={setFilter}
            sizeFormats={sizeFormats}
            templates={premadeTemplates}
          />
        </InputGroup>
      </Box>

      <Box
        sx={{
          color: '#ffffff',
          flex: 1,
          position: 'relative',
        }}
      >
        <Box
          sx={{
            position: 'absolute',
            height: '100%',
            width: '100%',
          }}
        >
          <CustomScroller>
            <Box sx={{ color: '#ffffff', padding: '0 1.5rem' }}>
              <Text fontWeight="700" fontSize="14px">
                Click to start with one of our ad templates
              </Text>
            </Box>
            <Box
              sx={{
                display: 'grid',
                gridTemplateColumns: '1fr 1fr',
                gap: '1rem',
                padding: '2rem',
              }}
            >
              {templates
                .filter(template => isFiltered(template.name))
                .filter(template => {
                  let filterExist = false;
                  Object.keys(filter).forEach(k => {
                    if (!filterExist) {
                      filterExist = filter[k];
                    }
                  });
                  if (!filterExist) {
                    return true;
                  }
                  const currentLabel = template.size_format.label;
                  if (filter[currentLabel]) {
                    return true;
                  } else {
                    return false;
                  }
                })
                .map(template => {
                  return (
                    <Box
                      key={template.id}
                      sx={{ cursor: 'pointer' }}
                      onClick={() => {
                        const { is_portrait, size_format_id, size_format } =
                          template;

                        const args = {
                          size_format: {
                            ...size_format,
                            isPortrait: is_portrait,
                          },
                          size_format_id,
                          userFonts,
                          template,
                          local: true,
                        };
                        // updateSizeFormat(args.size_format);
                        loadTemplate(args);
                      }}
                    >
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          backgroundColor: '#3c4349',
                        }}
                      >
                        <Box
                          sx={{
                            backgroundImage: template.previewURL,
                            height: '5rem',
                            width: '9rem',
                            backgroundSize: template.is_portrait
                              ? '100%'
                              : '150%',
                            backgroundRepeat: 'no-repeat',
                            backgroundPosition: template.is_portrait
                              ? 'top 0 left 50%'
                              : 'top 50% left 50%',
                            transition: 'all 0.2s ease',
                            padding: '4rem',
                            ':hover': {
                              backgroundSize: template.is_portrait
                                ? '67%'
                                : '100%',
                            },
                          }}
                        ></Box>
                      </Box>
                      <Box
                        sx={{
                          marginTop: '0.35rem',
                          fontSize: '0.8rem',
                          color: '#858b99',
                          textAlign: 'center',
                        }}
                      >
                        <Box color="rgba(255,255,255,0.8)">{template.name}</Box>
                        <Box sx={{ fontSize: '0.75rem' }}>
                          {template.size_format.label}
                        </Box>
                      </Box>
                    </Box>
                  );
                })}
            </Box>
          </CustomScroller>
        </Box>
      </Box>
    </Box>
  );
}

export default Templates;
