import './Legend.scss';

export default ({ icon = 'circle', color, label }) => {
  return (
    <div className="legend">
      <i className={`fas fa-${icon}`} style={{ color }} />
      {label}
    </div>
  );
};
