import React from "react"
import { Flex, Text, Box } from "@chakra-ui/react"
import useTemplateEditorContext from "scenes/templateEditor/hooks/useTemplateEditorContext"

function PanelTitle({ title, subMenu }) {
    const { setActiveSubMenu } = useTemplateEditorContext()

    return (
        <Flex sx={{ color: "#ffffff", padding: "0 1.5rem", display: "flex", justifyContent: "space-between" }}>
            <Text fontWeight="700" fontSize="14px">
                {title}
            </Text>
            <Box
                textDecoration="underline"
                cursor="pointer"
                onClick={() => setActiveSubMenu(subMenu)}
                fontSize="12px"
            >What is this ?</Box>
        </Flex>
    )
}

export default PanelTitle