import React from 'react';

const OrderModalContent = props => {
  const { order } = props;

  return (
    <React.Fragment>
      <div className="address">
        <h3 className="title">
          Shipped (<strong>{order.date_shipped}</strong>) to:
        </h3>

        <p>{order.address}</p>
        {order.address2 && (<p>{order.address2}</p>)}
        <p>{order.city} {order.state}, {order.zip}</p>
      </div>

      <div className="supplies">
        <div className="supplyGroup paper">
          <i className="fas fa-print"></i>

          <strong>Paper</strong>
          <p>{order.supplies.paper.toLocaleString()}</p>
        </div>

        <div className="supplyGroup maintenance">
          <i className="fas fa-fill-drip"></i>

          <strong>Maintenance</strong>
          <p>{order.supplies.maintenance.toLocaleString()}</p>
        </div>

        <div className="supplyGroup cyan">
          <i className="fas fa-tint"></i>

          <strong>Cyan</strong>
          <p>{order.supplies.c.toLocaleString()}</p>
        </div>

        <div className="supplyGroup magenta">
          <i className="fas fa-tint"></i>

          <strong>Magenta</strong>
          <p>{order.supplies.m.toLocaleString()}</p>
        </div>

        <div className="supplyGroup yellow">
          <i className="fas fa-tint"></i>

          <strong>Yellow</strong>
          <p>{order.supplies.y.toLocaleString()}</p>
        </div>

        <div className="supplyGroup black">
          <i className="fas fa-tint"></i>

          <strong>Black</strong>
          <p>{order.supplies.k.toLocaleString()}</p>
        </div>
      </div>
    </React.Fragment>
  );
}

export default OrderModalContent;