import React from 'react';
import { Box, Text, Image } from '@chakra-ui/react';
import { CodeBlock, nord } from 'react-code-blocks';
import CustomScroller from 'components/CustomScroller';
import TutorialClose from './components/TutorialClose';

const code = `{
  "audience": {
    ...
  },
  "template": {
    "image_2": "https://dzqi3b5s5jl7u.cloudfront.net/sample_fulfillments/bottle.jpg"
  }
}`;

function ImageTutorial() {
  return (
    <Box sx={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
      <Box
        sx={{
          flex: 1,
          position: 'relative',
        }}
      >
        <TutorialClose />
        <Box
          sx={{
            position: 'absolute',
            height: '100%',
            width: '100%',
          }}
        >
          <CustomScroller>
            <Box
              sx={{
                background: '#ffffff',
                flex: 1,
                borderRight: '1px solid rgba(57,76,96,.15)',
                padding: '1.5rem',
                fontSize: '14px',
              }}
            >
              <Text sx={{ fontSize: '18px', fontWeight: 700 }}>Image</Text>
              <Box lineHeight="22px">
                <Text mt="1rem">
                  Image blocks allow you to add a placeholder for an image into
                  the template. When order data is posted to UnDigital, the
                  system will replace the personalization token with the
                  matching variable name from the template params section of the
                  post.
                </Text>
                <Text mt="1rem">Example order data post</Text>

                <Box
                  sx={{
                    fontFamily: "'Fira Code', monospace",
                    marginTop: '1rem',
                  }}
                >
                  <CodeBlock
                    text={code}
                    language={'json'}
                    showLineNumbers={false}
                    theme={nord}
                  />
                </Box>

                <Text mt="1rem">
                  Will produce an ad image that replaces the personalization
                  token with the image provided.
                </Text>
                <Box pt="1rem">
                  <Image
                    boxShadow="md"
                    width="308px"
                    margin="auto"
                    src="https://i.ibb.co/qWKCnb5/imagepreview-1.png"
                  />
                </Box>

                <Text mt="1rem">
                  You can also use the Preview button at the top right corner to
                  provide sample values for your personalization tokens to
                  preview what the ad template would generate during an order
                  post.
                </Text>

                <Image
                  boxShadow="md"
                  pt="1rem"
                  width="308px"
                  margin="auto"
                  src="https://i.ibb.co/SPnhXjr/imagepform.png"
                />
              </Box>
            </Box>
          </CustomScroller>
        </Box>
      </Box>
    </Box>
  );
}
export default ImageTutorial;
