import React from "react"

function Check({ size }) {
    return (
        <svg height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8.82726 18.3012C8.52972 18.3017 8.23501 18.2435 7.96005 18.1298C7.68509 18.0161 7.4353 17.8492 7.22503 17.6387L3 13.4144L4.06816 12.3455L8.29318 16.5705C8.43485 16.7121 8.62695 16.7917 8.82726 16.7917C9.02757 16.7917 9.21968 16.7121 9.36134 16.5705L19.9318 6L21 7.06815L10.4295 17.6387C10.2192 17.8492 9.96944 18.0161 9.69448 18.1298C9.41952 18.2435 9.1248 18.3017 8.82726 18.3012Z" fill="currentColor" />
        </svg>
    )
}

export default Check