import React from 'react';
import { Box } from '@chakra-ui/react';
import PanelItem from './PanelItem';
import PanelItemsList from './PanelItemsList';
import { useEditor } from 'components/Canvas';

function Panel(props) {
  const editor = useEditor();
  const [isPanelOpen, setIsPanelOpen] = React.useState(true);

  const showPanel = () => {
    setIsPanelOpen(!isPanelOpen);
  };

  return (
    <Box
      id="panel-container"
      onClick={() => {
        editor.closeKeysMenu();
        editor.closeContextMenu();
      }}
      sx={{ display: 'flex', zIndex: 99 }}
    >
      <PanelItemsList isPanelOpen={isPanelOpen} showPanel={showPanel} />
      <PanelItem {...props} isPanelOpen={isPanelOpen} showPanel={showPanel} />
    </Box>
  );
}

export default Panel;
