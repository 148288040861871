import React from "react"

function Filter({ size }) {
    return (
        <svg height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M11.1981 5.06265C11.0371 4.47036 10.6857 3.9475 10.1981 3.57473C9.71049 3.20196 9.11377 3 8.5 3C7.88623 3 7.28951 3.20196 6.80191 3.57473C6.3143 3.9475 5.96291 4.47036 5.80194 5.06265H3V6.56261H5.80194C5.96291 7.1549 6.3143 7.67776 6.80191 8.05052C7.28951 8.42329 7.88623 8.62526 8.5 8.62526C9.11377 8.62526 9.71049 8.42329 10.1981 8.05052C10.6857 7.67776 11.0371 7.1549 11.1981 6.56261H20.9996V5.06265H11.1981ZM8.49962 7.1251C8.24004 7.1251 7.98629 7.04812 7.77045 6.90391C7.55462 6.75969 7.3864 6.55471 7.28706 6.31489C7.18772 6.07507 7.16173 5.81117 7.21237 5.55658C7.26301 5.30198 7.38802 5.06812 7.57157 4.88457C7.75512 4.70102 7.98898 4.57602 8.24357 4.52538C8.49817 4.47474 8.76206 4.50073 9.00188 4.60006C9.24171 4.6994 9.44669 4.86763 9.5909 5.08346C9.73512 5.29929 9.81209 5.55305 9.81209 5.81263C9.8115 6.16053 9.67303 6.49402 9.42702 6.74003C9.18102 6.98603 8.84753 7.1245 8.49962 7.1251V7.1251Z" fill="currentColor" />
            <path d="M15.5 9.1875C14.8868 9.1897 14.2912 9.39247 13.8041 9.76487C13.317 10.1373 12.9652 10.6588 12.8023 11.25H3V12.7499H12.8023C12.963 13.3423 13.3142 13.8654 13.8018 14.2383C14.2894 14.6112 14.8861 14.8133 15.5 14.8133C16.1138 14.8133 16.7106 14.6112 17.1981 14.2383C17.6857 13.8654 18.0369 13.3423 18.1977 12.7499H20.9996V11.25H18.1977C18.0348 10.6588 17.6829 10.1373 17.1958 9.76487C16.7087 9.39247 16.1131 9.1897 15.5 9.1875V9.1875ZM15.5 13.3124C15.2404 13.3124 14.9866 13.2354 14.7708 13.0912C14.555 12.947 14.3867 12.742 14.2874 12.5022C14.1881 12.2624 14.1621 11.9985 14.2127 11.7439C14.2634 11.4893 14.3884 11.2554 14.5719 11.0719C14.7555 10.8883 14.9893 10.7633 15.2439 10.7127C15.4985 10.662 15.7624 10.688 16.0022 10.7874C16.242 10.8867 16.447 11.0549 16.5912 11.2708C16.7355 11.4866 16.8124 11.7404 16.8124 11.9999C16.812 12.3479 16.6736 12.6815 16.4276 12.9276C16.1815 13.1736 15.8479 13.312 15.5 13.3124V13.3124Z" fill="currentColor" />
            <path d="M8.49962 15.375C7.88609 15.3767 7.29 15.5793 6.80244 15.9517C6.31489 16.3241 5.96269 16.846 5.79969 17.4374H3V18.9374H5.80194C5.96291 19.5297 6.3143 20.0526 6.80191 20.4253C7.28951 20.7981 7.88623 21.0001 8.5 21.0001C9.11377 21.0001 9.71049 20.7981 10.1981 20.4253C10.6857 20.0526 11.0371 19.5297 11.1981 18.9374H20.9996V17.4374H11.1981C11.0351 16.8462 10.6832 16.3246 10.1959 15.9521C9.70865 15.5797 9.1129 15.377 8.49962 15.375ZM8.49962 19.4999C8.24004 19.4999 7.98629 19.4229 7.77045 19.2787C7.55462 19.1345 7.3864 18.9295 7.28706 18.6897C7.18772 18.4499 7.16173 18.186 7.21237 17.9314C7.26301 17.6768 7.38802 17.4429 7.57157 17.2594C7.75512 17.0758 7.98898 16.9508 8.24357 16.9002C8.49817 16.8495 8.76206 16.8755 9.00188 16.9749C9.24171 17.0742 9.44669 17.2424 9.5909 17.4583C9.73512 17.6741 9.81209 17.9278 9.81209 18.1874C9.8115 18.5353 9.67303 18.8688 9.42702 19.1148C9.18102 19.3608 8.84753 19.4993 8.49962 19.4999V19.4999Z" fill="currentColor" />
        </svg>

    )
}
export default Filter