import React from 'react';

function QuestionmarkRound({ height = 14, width = 14, style = {} }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 7C0 3.13401 3.13401 0 7 0C10.866 0 14 3.13401 14 7C14 10.866 10.866 14 7 14C3.13401 14 0 10.866 0 7ZM8.16858 4.18799C7.53047 3.62964 6.46986 3.62964 5.83174 4.18799C5.60794 4.38382 5.26776 4.36114 5.07193 4.13734C4.8761 3.91354 4.89878 3.57336 5.12258 3.37753C6.16673 2.4639 7.8336 2.4639 8.87774 3.37753C9.96405 4.32804 9.96405 5.90273 8.87774 6.85324C8.69348 7.01448 8.49052 7.14655 8.27655 7.2501C7.79102 7.48508 7.53862 7.80567 7.53862 8.07692V8.61539C7.53862 8.91277 7.29755 9.15385 7.00016 9.15385C6.70278 9.15385 6.4617 8.91277 6.4617 8.61539V8.07692C6.4617 7.15864 7.22221 6.56395 7.80742 6.28073C7.93854 6.21727 8.06005 6.13774 8.16858 6.04278C8.76454 5.52132 8.76454 4.70945 8.16858 4.18799ZM7 11.3077C7.29738 11.3077 7.53846 11.0666 7.53846 10.7692C7.53846 10.4718 7.29738 10.2308 7 10.2308C6.70262 10.2308 6.46154 10.4718 6.46154 10.7692C6.46154 11.0666 6.70262 11.3077 7 11.3077Z"
        fill={style.backgroundColor}
      />
    </svg>
  );
}

export default QuestionmarkRound;
