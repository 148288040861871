import { apiConstants } from '../modules/api/apiDuck';

export function clean(type) {
  if (type.indexOf('.')) {
    return type.split('.')[1];
  } else {
    return type;
  }
}

// migrate away from and use start()
export function request(type) {
  return [apiConstants.API_REQUEST, type].join('.');
}

export function start(type) {
  return [apiConstants.API_START, type].join('.');
}

export function success(type) {
  return [apiConstants.API_SUCCESS, type].join('.');
}

export function failure(type) {
  return [apiConstants.API_FAILURE, type].join('.');
}

export function invalid(type) {
  return [apiConstants.API_INVALID, type].join('.');
}

export function unauthorized(type) {
  return [apiConstants.API_UNAUTHORIZED, type].join('.');
}

export function error(type = null) {
  if (type && type.startsWith(`${apiConstants.API_ERROR}.`)) {
    return type;
  }
  return [apiConstants.API_ERROR, type].join('.');
}
