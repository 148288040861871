import React from 'react';
import classNames from 'classnames';
import { useHistory, useLocation } from 'react-router-dom';

import './TableCard.scss';

const TableCardIndicator = ({ color }) => {
  return (
    <div
      className="TableCard-indicator"
      style={{
        backgroundColor: color,
      }}
    />
  );
};

// const ROW_HEIGHT = 40;

export default ({
  rootRef,
  rootProps,
  colSpan,
  className,
  size = 'small',
  hover,
  focus,
  indicatorColor,
  to,
  forceBlockLayout,
  onClick,
  children,
}) => {
  const { push } = useHistory();
  const location = useLocation();
  const getChildren = () => {
    // const height = React.Children.toArray(children).length * ROW_HEIGHT;

    return (
      <div
        className={classNames('TableCard-tableWrapper', {
          // eslint-disable-next-line no-useless-computed-key
          ['TableCard-tableWrapper-blockLayout']: forceBlockLayout,
        })}
        style={{
          colSpan,
        }}
      >
        <div
          className="TableCard-flex"
          // style={{
          //   height,
          // }}
        >
          {indicatorColor && <TableCardIndicator color={indicatorColor} />}
          <table className="TableCard-table">
            <tbody>{children}</tbody>
          </table>
        </div>
      </div>
    );
  };

  const cardProps = {
    ref: rootRef,
    className: classNames(className, 'TableCard', {
      'TableCard-hover': hover || (!!to && hover !== false),
      'TableCard-focus': focus,
    }),
    onClick,
    ...rootProps,
  };

  const redirect = () =>
    push(to, { from: location.pathname.concat(location.search) });

  return (
    <tr {...cardProps} onClick={to && redirect}>
      {getChildren()}
    </tr>
  );
};
