import React from 'react';
import { isEmpty } from 'lodash';
import FlashMessage from '../components/FlashMessage/FlashMessage';

/* Steps to add messages to the global message store...
 *  1. Import actions and selectors to the component container that will interact with the store.
 *      import * as appActions from 'path/to/appActions';
 *      import * as appSelectors from 'path/to/appSelectors';
 *
 *      a. Add the following to mapStateToProps:
 *          messages: appSelectors.getMessages(state),
 *
 *      b. Add the following to mapDispatchToProps:
 *          setMessage: payload => dispatch(appActions.setMessage(payload)),
 *          clearMessage: (payload) => dispatch(appActions.clearMessage(payload)),
 *
 *  2. Import message helpers in the component that will interact with the store.
 *      import { showMessages } from 'path/to/messageHelpers'
 *
 *  3. Deconstruct 'messages', 'setMessage' and 'clearMessage' in props for the component that will interact with the store.
 *
 *      a. Show/Dismiss existing messages with the following in that component's render method:
 *          {showMessages(messages, clearMessage)}
 *
 *      b. Add new messages with 'setMessage({ message, type })'
 *         Note: To add a message on server response, call 'setMessage({ message, type })' in an API callback
 *
 *      c. Possible message types are 'success', 'error' and 'warning'
 *         Note: If no type is provided, it will default to green styling with an info icon.
 */

export const showMessages = (messages, clearMessage) => {
  const apiErrorAlert = document.querySelector('.api-error-container');
  if (!isEmpty(messages)) {
    return (
      <div
        id="flash-container"
        style={{
          bottom: !!apiErrorAlert
            ? `${apiErrorAlert.offsetHeight + 32}px`
            : '1rem',
        }}
      >
        {messages?.map((item, index) => {
          const { message, type } = item;

          return (
            <FlashMessage
              key={`message_${index}`}
              text={message}
              index={index}
              type={type}
              onExited={() => clearMessage(index)}
            />
          );
        })}
      </div>
    );
  }
};
