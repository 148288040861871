import React from 'react';
const { Button } = require('@chakra-ui/react');

export const CancelButton = ({ ...props }) => (
  <Button
    h="40px"
    bgColor="transparent"
    borderRadius="4px"
    fontWeight="600"
    fontSize="14px"
    color="grey"
    textAlign="center"
    border={`1px solid lightgrey`}
    cursor="pointer"
    _hover={{bgColor: '#E2E8F0'}}
    {...props}
  >
    Cancel
  </Button>
);