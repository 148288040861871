import { Box } from '@chakra-ui/react';
import React from 'react';

import colors from 'theme/Colors.module.scss';
import placeholderCardViewDashboard from 'static/images/placeholder-card-view-dashboard.png';
import './ActiveCampaignCard2.scss';

const ActiveCampaignCard2 = ({ printsNum, title, imgSrc, tabOption }) => {
  return (
    <Box
      className='active-campaign-card'
    >
      <Box
        className='active-campaign-card__img-wrapper'
      >
         <Box
          className="active-campaign-card__img"
          bgImage={imgSrc ? imgSrc : placeholderCardViewDashboard}
        />
      </Box>
      <Box
        className='active-campaign-card__text-wrapper'
        color={colors.neutral700}
      >
        <span
          className="active-campaign-card__text ellipsis-span"
        >
          {title}
        </span>
        <span
          className="active-campaign-card__number"
          style={{color: tabOption.value === 'prints' ? colors.primary900 : colors.secondary900}}
        >
          {`${printsNum === undefined ? 0 : Number(printsNum).toLocaleString()} personalized prints`}
        </span>
      </Box>
    </Box>
  );
};

export default ActiveCampaignCard2;