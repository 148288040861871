import { connect } from 'react-redux';
import ApiErrorMessage from './ApiErrorMessage';
import { apiActions, apiSelectors } from '../../modules/api/apiDuck';

function mapStateToProps(state) {
  return {
    apiError: apiSelectors.getApiError(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    clearApiError: actionType => {
      dispatch(apiActions.clearApiError(actionType));
    },
  };
}

const ApiErrorMessageContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ApiErrorMessage);

export default ApiErrorMessageContainer;
