// import { createSelector } from 'reselect';
import { createApiErrorSelector } from 'modules/api/apiDuck';
import * as appConstants from './appConstants';

export const getAppMode = state =>
  state.modules.app[appConstants.STATE_KEY_APP_MODE];

export const getToken = state => {
  const token = state.modules.app[appConstants.STATE_KEY_TOKEN];
  if (token) return token.token;
};

export const getSessionUser = state => {
  const token = state.modules.app[appConstants.STATE_KEY_TOKEN];
  if (token) return token.user;
};
export const invalidPwResetToken = state =>
  state.modules.app[appConstants.STATE_KEY_INVALID_PW_RESET_TOKEN];

export const messages = state =>
  state.modules.app[appConstants.STATE_KEY_MESSAGES];

export const getApiError = createApiErrorSelector([
  appConstants.LOG_IN,
  appConstants.LOG_OUT,
  appConstants.SET_APP_MODE,
  appConstants.SEND_PW_RESET_EMAIL,
  appConstants.VERIFY_RESET_TOKEN,
  appConstants.CHANGE_PASSWORD,
  appConstants.UPLOAD_TEMPLATE,
]);
