import React from 'react';

function DownArrow({ size = 50, style = {} }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="10"
      height="7"
      viewBox="0 0 10 7"
      fill="none"
    >
      <path d="M1 1L5 5L9 1" stroke="#303030" strokeWidth="1.5" />
    </svg>
  );
}

export default DownArrow;
