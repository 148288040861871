import React from 'react';

function Export({ height = 14, width = 14, style = {} }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 11 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ ...style }}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.601562 10.9C0.601562 10.5134 0.914963 10.2 1.30156 10.2H9.70156C10.0882 10.2 10.4016 10.5134 10.4016 10.9C10.4016 11.2866 10.0882 11.6 9.70156 11.6H1.30156C0.914963 11.6 0.601563 11.2866 0.601562 10.9ZM2.90659 5.50505C3.17996 5.23168 3.62317 5.23168 3.89654 5.50505L4.80156 6.41007V1.10002C4.80156 0.713425 5.11496 0.400024 5.50156 0.400024C5.88816 0.400024 6.20156 0.713425 6.20156 1.10002L6.20156 6.41008L7.10659 5.50505C7.37996 5.23168 7.82317 5.23168 8.09654 5.50505C8.36991 5.77842 8.36991 6.22163 8.09654 6.495L5.99654 8.595C5.86526 8.72627 5.68721 8.80002 5.50156 8.80002C5.31591 8.80002 5.13786 8.72627 5.00659 8.595L2.90659 6.495C2.63322 6.22163 2.63322 5.77842 2.90659 5.50505Z"
        fill={style.color}
      />
    </svg>
  );
}

export default Export;
