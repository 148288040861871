import React from 'react';

import { BREAKPOINT_WIDTHS } from './layoutConstants';
import { viewportSizeContext } from './layoutContexts';

export const useViewportSize = () => {
  const { width, height } = React.useContext(viewportSizeContext);
  return { width, height };
};

export const useBreakpointUp = breakpoint => {
  const breakpointWidth = BREAKPOINT_WIDTHS.get(breakpoint);
  const { width } = useViewportSize();

  return width >= breakpointWidth;
};

export const useBreakpointDown = breakpoint => {
  const breakpointWidth = BREAKPOINT_WIDTHS.get(breakpoint);
  const { width } = useViewportSize();

  return width < breakpointWidth;
};

export const useBreakpointRange = (breakpointUp, breakpointDown) => {
  const isBreakpointUpOrAbove = useBreakpointUp(breakpointUp);
  const isBreakpointDownOrBelow = useBreakpointDown(breakpointDown);

  return isBreakpointUpOrAbove && isBreakpointDownOrBelow;
};
