import { Box, Grid, GridItem, Text, Button, Image } from '@chakra-ui/react';

import colors from 'theme/Colors.module.scss';
import guideImg from '../../static/images/dashboard-guide.png';
import welcomeImg from '../../static/images/dashboard-welcome.png';

const TopDashboard = () => {
  return (
    <Grid
      className="top-dashboard"
      gap={{ base: '35px', lg: '2.25em' }}
      display={{ base: 'grid', lg: 'flex' }}
    >
      <GridItem
        className="welcome-msg-container"
        bg={colors.white}
        borderRadius="24px"
      >
        <Box bg={colors.white} className="welcome-msg-wrapper">
          <Text
            className="welcome-msg__title"
            as="h3"
            fontSize={'20px'}
            color={colors.neutral700}
            fontWeight={700}
            letterSpacing={-0.32}
            marginBottom={15}
          >
            Welcome to the UnDigital platform!
          </Text>
          <Text
            className="welcome-msg__text"
            as="span"
            fontSize={'14px'}
            color={colors.neutral700}
            fontWeight={400}
            lineHeight={'20px'}
          >
            UnDigital empowers you to create hyper-targeted, tailor-made
            communications that speak directly to your customers’ desires. With
            personalized marketing inserts, you can captivate, engage, and
            convert like never before. Think of UnDigital as an extension of
            your team. Let’s level up your brand’s most important touchpoint!
          </Text>
        </Box>
        <Box className="welcome-msg__img" bgImage={welcomeImg} />
      </GridItem>
      <GridItem className="cta-container" bg={colors.white} borderRadius="24px">
        <Box className="cta-wrapper">
          <Text
            className="cta__text"
            as="span"
            fontSize={'20px'}
            fontWeight={700}
            marginBottom={{ base: '0.5em', lg: '0.75em' }}
            lineHeight="25px"
            color={'#232426'}
          >
            Create an&nbsp;
            <Text
              className="cta__inner-text"
              as="span"
              fontSize={'20px'}
              fontWeight={700}
              lineHeight="25px"
              color={colors.primary900}
              whiteSpace="pre-line"
            >
              Unforgettable{'\n'}
            </Text>
            Unboxing Experience
          </Text>
          <Box className="cta-btn-container">
            <a
              href="https://undigital.com/hubfs/2023%20Unboxing%20Experience%20Guidelines%20for%20Brands.pdf"
              download="2023 Unboxing Experience Guidelines for Brands.pdf"
              target="_blank"
              rel="noreferrer"
            >
              <Button
                className="cta-btn"
                textColor={colors.white}
                cursor="pointer"
                border="2px solid"
                borderRadius={'10px'}
                background={colors.primary900}
                borderColor={colors.primary900}
                marginTop={{ base: '0.5em', lg: '0' }}
                marginBottom={{ base: '0.5em', lg: '0' }}
                padding={{ base: '0.5em', lg: '10px 15px' }}
                fontSize={'12px'}
                fontWeight={700}
                lineHeight="18px"
                letterSpacing={-0.32}
                _hover={{ color: colors.primary900, background: colors.white }}
              >
                Read our guide
              </Button>
            </a>
          </Box>
        </Box>
        <Image
          className="cta__guide-img"
          src={guideImg}
          position={{ base: 'relative', lg: 'absolute' }}
          bottom={0}
          right={0}
          width={'100%'}
        />
      </GridItem>
    </Grid>
  );
};

export default TopDashboard;
