/* eslint-disable array-callback-return */
import React, { useEffect, useRef, useState } from 'react';
import { withRouter } from 'react-router-dom';
import colors from 'theme/Colors.module.scss';
import './Analytics.scss';
import {
  Box,
  Grid,
  GridItem,
  // IconButton,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import useOnClickOutside from 'use-onclickoutside';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment-timezone';
import { v4 as uuidv4 } from 'uuid';
import { formatAOV, formatPercentage } from 'helpers/campaignHelper';
import { exportToCSV } from 'helpers/csvHelpers';

import EntityTable from 'components/EntityTable/EntityTable';
import AnalyticsRow from './AnalyticsRow/AnalyticsRow';
import AnalyticsCard from './AnalyticsCard/AnalyticsCard';
// import AnalyticsSortDropdown from './AnalyticsSortDropdown/AnalyticsSortDropdown';
import Button from 'components/Button/Button';
// import ListView from 'scenes/templateEditor/components/Icons/ListView';
// import GridView from 'scenes/templateEditor/components/Icons/GridView';
import { defaultDates, isValidDate } from 'helpers/presetDateHelpers';
import { paramsToString } from 'helpers/paramHelpers';
import DateRangePicker from 'components/DateRangePicker/DateRangePicker';
import { checkCampaignStatus, getTemplateThumb } from 'helpers/campaignHelper';
import LoadingIndicator from 'components/LoadingIndicator/LoadingIndicator';
import OutsideClickHandler from 'hooks/OutsideClickHandler';
import Warning from 'components/Icons/Warning';
import AttributionWindowModal from './Modal/AttributionWindowModal';

import {
  creativesActions,
  creativesSelectors,
} from 'modules/creatives/creativesDuck';
import {
  dashboardActions,
  dashboardSelectors,
} from 'modules/dashboard/dashboardSlice';
import * as campaignsActions from 'modules/campaigns/campaignsActions';
import * as campaignsSelectors from 'modules/campaigns/campaignsSelectors';

const ANALYTICS_HEADERS = [
  {
    name: 'Campaign',
    field: 'name',
    align: 'center',
    width: 300,
    maxWidth: 500,
    sortable: false,
    resizable: true,
    sortIcon: 'alpha',
  },
  {
    name: 'Run Dates',
    field: 'run_dates',
    align: 'center',
    width: 160,
    sortable: false,
    sortIcon: 'numeric',
  },
  {
    name: 'Personalized Packages',
    field: 'packages',
    align: 'right',
    width: 140,
    sortable: true,
    sortIcon: 'numeric',
  },
  {
    name: 'QR Scans',
    field: 'qrScans',
    align: 'right',
    width: 140,
    sortable: true,
    sortIcon: 'numeric',
  },
  {
    name: 'Conversions',
    field: 'conversions',
    align: 'right',
    width: 140,
    sortable: true,
    sortIcon: 'numeric',
  },
  {
    name: 'Conversion Rate',
    field: 'conversion_rate',
    align: 'right',
    width: 140,
    sortable: true,
    sortIcon: 'numeric',
  },
  {
    name: 'Conversion AOV',
    field: 'avg',
    align: 'right',
    width: 140,
    sortable: true,
    sortIcon: 'numeric',
  },
  // temporary hidden untill the data is available
  // {
  //   name: 'Avg Days to Next Order',
  //   field: 'avg_days_to_next_order',
  //   align: 'right',
  //   width: 140,
  //   sortable: true,
  //   sortIcon: 'numeric',
  // },
  // {
  //   name: 'Revenue',
  //   field: 'revenue',
  //   align: 'right',
  //   width: 140,
  //   sortable: true,
  //   sortIcon: 'numeric',
  // },
  // {
  //   name: 'Revenue/Package',
  //   field: 'revenue_package',
  //   align: 'right',
  //   width: 140,
  //   sortable: true,
  //   sortIcon: 'numeric',
  // },
];

export const campaignFilterOptions = [
  {
    label: 'Personalized Packages: High to Low',
    type: 'packages',
    order: 'desc',
  },
  {
    label: 'Personalized Packages: Low to High',
    type: 'packages',
    order: 'asc',
  },
  {
    label: 'Conversions: High to Low',
    type: 'conversions',
    order: 'desc',
  },
  {
    label: 'Conversions: Low to High',
    type: 'conversions',
    order: 'asc',
  },
  {
    label: 'Conversion Rate: High to Low',
    type: 'conversion_rate',
    order: 'desc',
  },
  {
    label: 'Conversion Rate: Low to High',
    type: 'conversion_rate',
    order: 'asc',
  },
  { label: 'Conversion AOV: High to Low', type: 'avg', order: 'desc' },
  { label: 'Conversion AOV: Low to High', type: 'avg', order: 'asc' },
  { label: 'Total QR Scans: High to Low', type: 'qrScans', order: 'desc' },
  { label: 'Total QR Scans: Low to High', type: 'qrScans', order: 'asc' },
];

const Analytics = props => {
  const { isMobile } = props;
  const rangePickerRef = useRef(null);
  const [dateRangeDropdownVisible, setDateRangeDropdownVisible] =
    useState(false);
  const [isCustomRangePickerVisible, setIsCustomRangePickerVisible] =
    useState(false);
  // eslint-disable-next-line no-unused-vars
  const [isListView, setIsListView] = useState(true);
  // eslint-disable-next-line no-unused-vars
  const [isTypeOpen, setIsTypeOpen] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [isOrderOpen, setIsOrderOpen] = useState(false);

  const [searchParameters] = useState({
    sort: [{ field: 'packages', direction: 'desc' }],
  });
  const selectCampaignSortRef = useRef(null);
  const [filteredCampaigns, setFilteredCampaigns] = useState({
    campaigns: [],
    foots: [],
    token: uuidv4(),
  });
  // eslint-disable-next-line no-unused-vars
  const [filterOption, setFilterOption] = useState(campaignFilterOptions[0]);
  const [attributionWindow, setAttributionWindow] = useState(90);

  const {
    isOpen: isModalOpen,
    // eslint-disable-next-line no-unused-vars
    onOpen: onModalOpen,
    onClose: onModalClose,
  } = useDisclosure();

  const dispatch = useDispatch();

  // Selectors
  const stats = useSelector(state => dashboardSelectors.stats(state));
  const isStatsLoading = useSelector(state =>
    dashboardSelectors.isStatsLoading(state)
  );
  // const qrCodesStats = useSelector(state =>
  //   dashboardSelectors.qrCodesStats(state)
  // );
  // const isQrCodesStatsLoading = useSelector(state =>
  //   dashboardSelectors.isQrCodesStatsLoading(state)
  // );
  const campaigns = useSelector(state => campaignsSelectors.campaigns(state));
  const getDateRange = useSelector(state =>
    campaignsSelectors.getDateRange(state)
  );

  // Dispatches
  const getCampaigns = targetType =>
    dispatch(campaignsActions.getCampaigns(targetType));
  const setDateRange = payload =>
    dispatch(campaignsActions.setDateRange(payload));
  const getStats = params => dispatch(dashboardActions.getStats(params));
  // const getQrCodesStats = params =>
  //   dispatch(dashboardActions.getQrCodesStats(params));
  const getTemplates = payload =>
    dispatch(creativesActions.getTemplates(payload));

  useOnClickOutside(selectCampaignSortRef, e => {
    if (
      e.target.className.length > 0 &&
      e.target.className.includes('analytics-sort-dropdown-wrapper')
    )
      return;
    setIsTypeOpen(false);
    setIsOrderOpen(false);
  });

  useEffect(() => {
    setIsTypeOpen(false);
    setIsOrderOpen(false);
  }, [filterOption]);

  const { from, to } = getDateRange;

  const templates = useSelector(state => creativesSelectors.creatives(state));
  const range = {
    start: moment(from).local().format('YYYY-MM-DD'),
    startUtc: moment(from).startOf('day').utc().format(),
    startRaw: moment(from).local(),
    end: moment(to).local().format('YYYY-MM-DD'),
    endUtc: moment(to).endOf('day').utc().format(),
    endRaw: moment(to).local(),
    days: moment(to).diff(moment(from), 'days'),
  };

  const utcParams = paramsToString({
    start: range.startUtc,
    end: range.endUtc,
    tz: moment.tz(moment.tz.guess()).zoneAbbr(),
    all_campaigns: 1,
    show_by_day: 0,
    convdays: attributionWindow,
  });

  useEffect(() => {
    const payload = { current_page: 1, size: 10000 };

    getCampaigns(payload);

    if (templates.length === 0) {
      getTemplates(payload);
    }

    if (
      isValidDate(moment(from)) &&
      isValidDate(moment(to)) &&
      getDateRange?.length !== 0
    ) {
      getStats(utcParams);
      if (
        !inAttributionWindow() &&
        window.sessionStorage.getItem('aw-warning') !== 'false'
      ) {
        onModalOpen();
      }
      // getQrCodesStats(utcParams);
    } else {
      setDateRange({
        from: new Date(defaultDates.start),
        to: new Date(defaultDates.end),
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [from, to, utcParams]);

  const inAttributionWindow = () => {
    // attribution window warning logic
    // x = end of date range
    // y = attribution window in days
    // z = datetime now
    // (x + y) > z  == true then show show the warning message
    const now = moment().startOf('day').utc().format('YYYY-MM-DD');
    const endWithAttr = moment(to)
      .add(attributionWindow, 'd')
      .endOf('day')
      .utc()
      .format('YYYY-MM-DD');
    const diff = moment(endWithAttr).diff(now, 'days');
    return diff > 0 ? false : true;
  };

  useEffect(() => {
    let newCampaigns = [];
    stats &&
      campaigns &&
      // qrCodesStats &&
      templates &&
      // eslint-disable-next-line array-callback-return
      Object.values(stats.data).map(stat => {
        let object = {};
        object.id = stat.id;
        object.conversions = stat.total_conv;
        object.packages = stat.total_imp;
        object.conversion_rate = stat.total_conv_pct;
        object.avg = stat.data[0][6];
        let temp = campaigns.filter(campaign => campaign.id === stat.id);
        object.enabled = temp[0]?.enabled;
        let template = templates.filter(
          template => template.id === temp[0]?.ad_template_id
        );
        object.template = getTemplateThumb(template[0]);
        object.name = temp[0]?.name;
        object.start_date = temp[0]?.start_date
          ? moment(temp[0]?.start_date).format('MM/DD/YY')
          : undefined;
        object.end_date = temp[0]?.end_date
          ? moment(temp[0]?.end_date).format('MM/DD/YY')
          : undefined;
        object.qrScans = stat.total_scans;
        object.qrIx = [];
        stat.qr_ix?.map((item, index) => {
          if (stat.data[0][7].length === 0) return;
          object.qrIx[index] = new Map();
          object.qrIx[index].set('qr', item);
          object.qrIx[index].set('amount', stat.data[0][7][index]);
        });
        newCampaigns.push(object);
      });
    let fieldName = filterOption.type;
    let isAscending = filterOption.order === 'asc';
    let sortedCampaigns = newCampaigns.sort((campaign1, campaign2) => {
      if (isAscending) return campaign1[fieldName] - campaign2[fieldName];
      else return campaign2[fieldName] - campaign1[fieldName];
    });

    // generate total data for foot section
    let footData = [];

    ANALYTICS_HEADERS.forEach(header => {
      let data = {
        name: header.name,
        field: header.field,
        [header.field]: undefined,
      };

      if (header.field === 'name') {
        data.name = 'Total for all campaigns';
      } else {
        sortedCampaigns.forEach(campaign => {
          if (header.field === 'run_dates') {
            data[header.field] = '';
          } else if (header.field !== 'name' && header.field !== 'run_dates') {
            if (typeof campaign[header.field] === 'number') {
              data[header.field] === undefined
                ? (data[header.field] = campaign[header.field])
                : (data[header.field] += campaign[header.field]);
            } else if (typeof campaign[header.field] === 'string') {
              data[header.field] === undefined
                ? (data[header.field] = campaign[header.field])
                : (data[header.field] = (
                    +data[header.field] + +campaign[header.field]
                  ).toString());
            }
          }
        });
      }

      /* customize data display in total section */

      // calculate average conversion rate
      if (header.field === 'conversion_rate') {
        if (footData[2].packages === 0) {
          data[header.field] = 0;
        } else {
          data[header.field] = footData[4].conversions / footData[2].packages;
        }
      }

      // calculate average conversion AOV
      if (header.field === 'avg') {
        data[header.field] = (
          +data[header.field] / sortedCampaigns.length
        ).toString();
      }

      footData.push(data);
    });

    setFilteredCampaigns({
      campaigns: sortedCampaigns,
      token: uuidv4(),
      foots: footData,
    });
  }, [
    stats,
    // qrCodesStats,
    campaigns,
    templates,
    filterOption.type,
    filterOption.order,
  ]);

  const handleExport = () => {
    const csvList = [];
    const csvHead = [...ANALYTICS_HEADERS.map(header => header.name)];
    let csvData = '';

    filteredCampaigns.campaigns.forEach(campaign => {
      const campaignData = [];
      ANALYTICS_HEADERS.forEach(header => {
        if (header.field === 'run_dates') {
          campaignData.push(
            `${campaign.start_date ? campaign.start_date : '--/--/--'} - ${
              campaign.end_date ? campaign.end_date : '--/--/--'
            }`
          );
        } else if (header.field === 'conversion_rate') {
          campaignData.push(`${formatPercentage(campaign[header.field])}%`);
        } else if (header.field === 'avg') {
          campaignData.push(`${formatAOV(campaign[header.field])}`);
        } else {
          campaignData.push(campaign[header.field]);
        }
      });
      csvData += campaignData.join(',') + '\n';
    });
    csvList.push(csvHead + '\n');
    csvData += '\n'; // add line break to seperate data row from total row

    const footData = [];
    filteredCampaigns.foots.forEach(foot => {
      if (foot.field === 'name') {
        footData.push(foot.name);
      } else if (foot.field === 'run_dates') {
        footData.push('');
      } else if (foot.field === 'conversion_rate') {
        footData.push(`"${formatPercentage(foot[foot.field])}%"`);
      } else if (foot.field === 'avg') {
        footData.push(`"${formatAOV(foot[foot.field])}"`);
      } else {
        footData.push(foot[foot.field]);
      }
    });

    csvData += footData.join(',') + '\n';
    csvList.push(csvData);

    exportToCSV(csvList, `analytics_${range.start}-${range.end}`);
  };

  const renderCampaignCard = (campaign, state) => {
    const newKeyOrder = {
      ...campaign,
      enabled: campaign.enabled,
      start_date: campaign.start_date,
      end_date: campaign.end_date,
      name: campaign.name,
      packages: campaign.packages,
      conversions: campaign.conversions,
      conversionRate: campaign.conversion_rate,
      average: campaign.avg,
      qrScans: campaign.qrScans,
      template: campaign.template,
      // avg_days_to_next_order: 'not available', // will update when api is available
      // revenue: 'not available', // will update when api is available
      // revenue_package: 'not available', // will update when api is available
    };
    return (
      <AnalyticsRow
        key={uuidv4()}
        draggableProvided={state.draggableProvided}
        draggableSnapshot={state.draggableSnapshot}
        focus={state.focus}
        content={newKeyOrder}
        isMobile={isMobile}
        headers={ANALYTICS_HEADERS}
        sort={state.sort.sort}
      />
    );
  };

  const filteredCampaignsTable = filteredCampaigns => {
    const campaigns = filteredCampaigns.campaigns;
    const foots = filteredCampaigns.foots;

    return (
      <>
        {!isStatsLoading ? (
          <EntityTable
            className="analytics__content-table"
            key={filteredCampaigns.token}
            headers={ANALYTICS_HEADERS}
            foots={foots.length !== 0 ? foots : null}
            sortable
            focusable
            scrollable
            persistable
            entityType="campaigns"
            renderRow={renderCampaignCard}
            defaultSearchParameters={searchParameters}
            getEntities={searchParameters => {
              if (searchParameters.sort[0]) {
                let sortedCampaigns = [];
                let fieldName = searchParameters.sort[0]?.field;
                let isAscending = searchParameters.sort[0]?.direction === 'asc';
                if (fieldName === 'name') {
                  sortedCampaigns = campaigns.sort((campaign1, campaign2) => {
                    if (isAscending)
                      return campaign1[fieldName].localeCompare(
                        campaign2[fieldName]
                      );
                    else
                      return campaign2[fieldName].localeCompare(
                        campaign1[fieldName]
                      );
                  });
                } else if (
                  fieldName === 'start_date' ||
                  fieldName === 'end_date'
                ) {
                  sortedCampaigns = campaigns.sort((campaign1, campaign2) => {
                    if (campaign1[fieldName] === undefined) return 1;
                    if (campaign2[fieldName] === undefined) return -1;

                    if (isAscending)
                      return (
                        new Date(campaign1[fieldName]) -
                        new Date(campaign2[fieldName])
                      );
                    else
                      return (
                        new Date(campaign2[fieldName]) -
                        new Date(campaign1[fieldName])
                      );
                  });
                } else
                  sortedCampaigns = campaigns.sort((campaign1, campaign2) => {
                    if (isAscending)
                      return campaign1[fieldName] - campaign2[fieldName];
                    else return campaign2[fieldName] - campaign1[fieldName];
                  });
                return new Promise((resolve, reject) => {
                  resolve(sortedCampaigns);
                });
              } else {
                return new Promise((resolve, reject) => {
                  resolve(campaigns);
                });
              }
            }}
          />
        ) : (
          <></>
        )}
      </>
    );
  };

  const buildGridView = () => {
    const campaigns = filteredCampaigns.campaigns;
    return (
      <>
        <Box className="analytics-grid-view__container">
          <Grid className="analytics-grid__wrapper" background={colors.white}>
            {campaigns &&
              campaigns.map((campaign, index) => {
                return (
                  <GridItem key={`analytic-card-${index}`}>
                    <AnalyticsCard
                      startDate={
                        campaign.start_date
                          ? moment(campaign.start_date).format('MM/DD/YY')
                          : undefined
                      }
                      endDate={
                        campaign.end_date
                          ? moment(campaign.end_date).format('MM/DD/YY')
                          : undefined
                      }
                      to={campaign.id}
                      template={campaign.template}
                      conversionRate={campaign.conversion_rate}
                      campaignName={campaign.name}
                      isActive={checkCampaignStatus(campaign)}
                      packages={campaign.packages}
                      conversions={campaign.conversions}
                      average={campaign.avg}
                      qrScans={campaign.qrScans}
                      qrIx={campaign.qrIx}
                    />
                  </GridItem>
                );
              })}
          </Grid>
        </Box>
        {campaigns.length === 0 && !isStatsLoading && (
          <Text as="span" marginLeft={{ base: '1.25em', lg: '4em' }}>
            No data is available.
          </Text>
        )}
      </>
    );
  };
  return (
    <>
      <AttributionWindowModal
        isModalOpen={isModalOpen}
        onModalClose={onModalClose}
      />
      <Box className="analytics">
        <Box className="analytics__header">
          <Box className="header__title">
            <span>Unboxing Marketing Analytics</span>
          </Box>

          <Box className="header__export-btn-mobile">
            <Button
              type="button"
              icon={'export'}
              text={'Export'}
              iconColor={colors.white}
              textColor={colors.white}
              bgColor={colors.secondary900}
              hoverColor={colors.secondary700}
              textHoverColor={colors.white}
              border={`1px solid ${colors.neutral200}`}
              boxShadow={`0px 1px 2px 0px #1018280A`}
              iconHeight={11.2}
              iconWidth={9.8}
              height={32}
              width={84}
              btnStyle={'solid'}
              fontSize={12}
              fontWeight={500}
              onClick={handleExport}
            ></Button>
          </Box>

          <Box className="header__date-range-picker">
            <OutsideClickHandler
              onHandler={() => {
                setDateRangeDropdownVisible(false);
                setIsCustomRangePickerVisible(false);
              }}
              style={{ position: 'relative', right: '0px' }}
            >
              <DateRangePicker
                isCustomRangePickerVisible={isCustomRangePickerVisible}
                setIsCustomRangePickerVisible={setIsCustomRangePickerVisible}
                dateRangeDropdownVisible={dateRangeDropdownVisible}
                setDateRangeDropdownVisible={setDateRangeDropdownVisible}
                setAttributionWindow={setAttributionWindow}
                date={{ from, to }}
                ref={rangePickerRef}
                setDate={setDateRange}
              />
            </OutsideClickHandler>
          </Box>
          <Box className="header__export-btn">
            <Button
              type="button"
              icon={'export'}
              text={'Export'}
              iconColor={colors.white}
              textColor={colors.white}
              bgColor={colors.secondary900}
              hoverColor={colors.secondary700}
              textHoverColor={colors.white}
              border={`1px solid ${colors.neutral200}`}
              boxShadow={`0px 1px 2px 0px #1018280A`}
              iconHeight={11.2}
              iconWidth={9.8}
              height={32}
              width={84}
              btnStyle={'solid'}
              fontSize={12}
              fontWeight={500}
              onClick={handleExport}
            ></Button>
          </Box>
        </Box>
        <Box
          className={`analytics__table ${
            !inAttributionWindow() ? 'table-warning' : ''
          }`}
        >
          {!inAttributionWindow() && (
            <Box className="table__header">
              <Box className="header__notification">
                <Warning
                  height="16px"
                  width="16px"
                  style={{ backgroundColor: colors.primary900 }}
                />
                <span>
                  {`The attribution window you have selected is not long enough.
                  Please select an end date before ${moment()
                    .subtract(attributionWindow, 'd')
                    .startOf('day')
                    .utc()
                    .format('MMMM D, YYYY')} for more accurate results.`}
                </span>
              </Box>
              {/* comment out grid view for futher notice */}
              {/* <Box className="header__view-btn">
            {!isListView && (
              <Box
                className="header__gridview-sort-dropdown"
                ref={selectCampaignSortRef}
                color={colors.neutral700}
              >
                <AnalyticsSortDropdown
                  isTypeOpen={isTypeOpen}
                  setIsTypeOpen={setIsTypeOpen}
                  isOrderOpen={isOrderOpen}
                  setIsOrderOpen={setIsOrderOpen}
                  campaignFilterOptions={campaignFilterOptions}
                  setFilterOption={setFilterOption}
                />
              </Box>
            )}
            <IconButton
              className="view-switch__btn"
              size="sm"
              background={isListView ? colors.coolGrey1 : colors.white}
              onClick={() => setIsListView(true)}
              icon={<ListView size="0.75em" />}
            />
            <IconButton
              className="view-switch__btn"
              background={!isListView ? colors.coolGrey1 : colors.white}
              size="sm"
              onClick={() => setIsListView(false)}
              icon={<GridView size="0.75em" />}
            />
          </Box> */}
            </Box>
          )}
          <Box className="table__content">
            {isListView
              ? filteredCampaignsTable(filteredCampaigns)
              : buildGridView()}
            <LoadingIndicator
              loading={isStatsLoading || filteredCampaigns.length === 0}
            />
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default withRouter(Analytics);
