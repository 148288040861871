import React from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  Stack,
  Radio,
  RadioGroup,
  Text,
  Button,
  Box,
} from '@chakra-ui/react';
function MigrateSizeFormatModal({
  isOpen,
  loadTemplate,
  onClose,
  sizeFormats,
  args,
}) {
  const [selected, setSelected] = React.useState(sizeFormats[0]?.label);
  const applySelected = () => {
    const sizeFormat = sizeFormats.find(sf => sf.label === selected);
    const updatedArgs = {
      ...args,
      size_format: sizeFormat,
      size_format_id: sizeFormat.id,
      template: {
        ...args.template,
        size_format: sizeFormat,
        size_format_id: sizeFormat.id,
      },
    };
    loadTemplate(updatedArgs);
    onClose();
  };
  return (
    <Modal isCentered isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent sx={{ padding: '1.5rem' }}>
        <Text fontWeight={600} fontSize="14px">
          Select new format size
        </Text>
        <Text fontSize="14px">
          Selected template is not in your format sizes. Please choose a new
          format size target.
        </Text>
        <RadioGroup onChange={setSelected} value={selected}>
          <Stack marginY="1rem">
            {sizeFormats.map((sf, index) => {
              return (
                <Radio
                  key={index}
                  display="flex"
                  flexDirection="row"
                  size="sm"
                  value={sf.label}
                >
                  {sf.label}
                </Radio>
              );
            })}
          </Stack>
        </RadioGroup>
        <Box
          sx={{
            textStyle: 'italic',
            fontSize: '14px',
            color: '#7b7b7b',
            marginBottom: '0.5rem',
          }}
        >
          Changing original size format may affect objects positioning.
        </Box>
        <Button
          onClick={applySelected}
          fontSize="14px"
          borderRadius={2}
          colorScheme="orange"
          background="rgb(255, 111, 15)"
          w="100%"
        >
          Update size format
        </Button>
      </ModalContent>
    </Modal>
  );
}

export default MigrateSizeFormatModal;
