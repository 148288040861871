import React from 'react';

function UpArrow({ size = 50, style = {} }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="10"
      height="7"
      viewBox="0 0 10 7"
      fill="none"
    >
      <path
        d="M9 6L5 2L1 6"
        stroke="#303030"
        strokeOpacity="0.5"
        strokeWidth="1.5"
      />
    </svg>
  );
}

export default UpArrow;
