import { contextMenuType } from '../common/constants';

export function parseSizeFormat(options) {
  const sizeFormat = {
    formatHeight: options.format_height,
    formatUnit: options.format_unit,
    formatWidth: options.format_width,
    id: options.id,
    label: options.label,
    pixelHeight: options.pixel_height,
    pixelWidth: options.pixel_width,
    isPortrait: options.pixel_height > options.pixel_width,
  };
  return sizeFormat;
}

export const getContextMenuType = selection => {
  const types = new Set();
  if (!selection) {
    return contextMenuType.DEFAULT;
  }
  if (selection._objects) {
    for (const object of selection._objects) {
      types.add(object.type);
    }
  } else {
    types.add(selection.type);
  }

  const typesArray = Array.from(types);

  if (typesArray.length === 1) {
    return typesArray[0];
  } else {
    return contextMenuType.DEFAULT;
  }
};
