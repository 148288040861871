import React from "react"

function FormatColorText({ size }) {
    return (
        <svg height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M5.48999 15H7.90999L9.17999 11.42H14.83L16.09 15H18.51L13.25 1H10.75L5.48999 15ZM9.90999 9.39L11.94 3.6H12.06L14.09 9.39H9.90999Z" fill="black" />
            <rect fill="currentColor" x="2" y="18" width="20" height="4" />
        </svg>

    )
}

export default FormatColorText