// import classNames from 'classnames';
// import colors from 'theme/Colors.module.scss';
import './DirectionIcon.scss';
import SortDesc from 'components/Icons/SortDesc';
import SortAsc from 'components/Icons/SortAsc';
import { Box } from '@chakra-ui/react';

const UP = 'up-alt';
const DOWN = 'down';
const NORMAL = 'normal';

export default ({ type, direction, className, style }) => {
  const directionSuffix = (() => {
    switch (true) {
      case direction.startsWith('asc'):
        return UP;
      case direction.startsWith('desc'):
        return DOWN;
      case direction.startsWith('normal'):
        return NORMAL;
      default:
        return NORMAL;
    }
  })();

  return (
    <Box marginLeft={'5px'}>
      {(() => {
        switch (directionSuffix) {
          case UP:
            return <SortAsc style={{ color: style.color }} />;
          case DOWN:
            return <SortDesc style={{ color: style.color }} />;
          case NORMAL:
            return <SortDesc style={{ color: style.color }} />;
          default:
            return null;
        }
      })()}
    </Box>
  );
};
