import Add from './Add';
import ArrowUp from './ArrowUp';
import Checked from './Checked';
import Checkmark from './Checkmark';
import Close from './Close';
import DownArrow from './DownArrow';

import Edit from './Edit';
import Exchange from './Exchange';
import File from './File';
import PlusCircle from './PlusCircle';
import Search from './Search';
import Success from './Success';
import UpArrow from './UpArrow';
import UploadCloud from './UploadCloud';
import Warning from './Warning';
import SortAsc from './SortAsc';
import SortDesc from './SortDesc';
import Export from './Export';
import QuestionmarkRound from './QuestionmarkRound';
import CheveronDown from './CheveronDown';

class Icons {
  static Edit = Edit;
  static Add = Add;

  static ArrowUp = ArrowUp;

  static Close = Close;
  static UploadCloud = UploadCloud;
  static File = File;
  static Exchange = Exchange;
  static Checked = Checked;
  static Search = Search;
  static Success = Success;
  static Warning = Warning;

  static PlusCircle = PlusCircle;
  static Checkmark = Checkmark;
  static DownArrow = DownArrow;
  static UpArrow = UpArrow;
  static SortAsc = SortAsc;
  static SortDesc = SortDesc;
  static Export = Export;
  static QuestionmarkRound = QuestionmarkRound;
  static CheveronDown = CheveronDown;
}

export default Icons;
