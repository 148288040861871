import { connect } from 'react-redux';
import Campaigns from './Campaigns';
import * as campaignsActions from 'modules/campaigns/campaignsActions';
import * as campaignsSelectors from 'modules/campaigns/campaignsSelectors';
import * as appSelectors from 'modules/app/appSelectors';
import { apiActions } from 'modules/api/apiDuck';
import * as appActions from 'modules/app/appActions';

function mapStateToProps(state) {
  return {
    user: appSelectors.getSessionUser(state),
    apiError: campaignsSelectors.getApiError(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    testError: () => {
      dispatch(
        apiActions.apiAction({
          path: `notfound`,
          type: 'modules/campaigns/GET_CAMPAIGNS',
        })
      );
    },
    getCampaign: id => {
      dispatch(campaignsActions.getCampaign(id));
    },
    getCampaigns: campaignsActions.getCampaigns,
    deleteCampaign: id => {
      dispatch(campaignsActions.deleteCampaign(id));
      dispatch(
        appActions.setMessage({
          message: 'Campaign removed successfully',
          type: 'success',
        })
      );
    },
    updateCampaign: (id, campaign, doneCallback, failureCallback) => {
      dispatch(
        campaignsActions.updateCampaign(
          id,
          campaign,
          doneCallback,
          failureCallback
        )
      );
    },
    clearApiError: actionType => {
      dispatch(apiActions.clearApiError(actionType));
    },
  };
}

const CampaignsContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(Campaigns);

export default CampaignsContainer;
