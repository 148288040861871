import { fromJS } from 'immutable';
// import { schema } from 'normalizr';
import { success, error, start } from '../../helpers/reducerHelpers';
// import { createSelector } from 'reselect';
import { createApiErrorSelector } from '../api/apiDuck';
import * as api from './templateEditorApi';

// Constants
const ADD_USER_FONTS = 'modules/templateEditor/ADD_USER_FONTS';
const GET_TEMPLATE_BY_ID = 'modules/templateEditor/GET_TEMPLATE_BY_ID';
const UPDATE_TEMPLATE_BY_ID = 'modules/templateEditor/UPDATE_TEMPLATE_BY_ID';
const GET_TEMPLATE_PREVIEW = 'modules/templateEditor/GET_TEMPLATE_PREVIEW';
const COPY_PRE_MADE_TEMPLATE = 'modules/templateEditor/COPY_PRE_MADE_TEMPLATE';
const GET_PRE_MADE_TEMPLATES = 'modules/templateEditor/GET_PRE_MADE_TEMPLATES';
const GET_MRU_VARIABLE_KEYS = 'modules/templateEditor/GET_MRU_VARIABLE_KEYS';
const CLEAR_TEMPLATE = 'modules/templateEditor/CLEAR_TEMPLATE';
const CREATE_TEMPLATE = 'modules/templateEditor/CREATE_TEMPLATE';
const GET_USER_FONTS = 'modules/templateEditor/GET_USER_FONTS';
const GET_DOUBLE_SIDED_STATUS =
  'modules/templateEditor/GET_DOUBLE_SIDED_STATUS';
const GET_USER_FONT_BY_ID = 'modules/templateEditor/GET_USER_FONT_BY_ID';
const UPLOAD_USER_FONT = 'modules/templateEditor/UPLOAD_USER_FONT';
const UPDATE_USER_FONT = 'modules/templateEditor/UPDATE_USER_FONT';
const REMOVE_USER_FONT = 'modules/templateEditor/REMOVE_USER_FONT';
const UPDATE_LOCAL_CANVAS = 'modules/templateEditor/UPDATE_LOCAL_CANVAS';

const STATE_KEY_TEMPLATE = 'template';
const STATE_KEY_PRE_MADE_TEMPLATES = 'premadeTemplates';
const STATE_KEY_PRE_MADE_TEMPLATES_LOADING = 'premadeTemplatesLoading';
const STATE_KEY_COPIED_PRE_MADE_TEMPLATE = 'copiedPremadeTemplate';
const STATE_KEY_MRU_VARIABLE_KEYS = 'mruVariableKeys';
const STATE_KEY_USER_FONTS = 'userFonts';
const STATE_KEY_USER_FONT = 'userFont';
const STATE_KEY_TEMPLATE_PREVIEW = 'templatePreview';
const STATE_KEY_PREVIEW_LOADING = 'previewLoading';
const STATE_KEY_MRU_VARIABLE_KEYS_LOADING = 'mruVariableKeysLoading';
const STATE_KEY_LOCAL_CANVAS = 'localCanvas';
const STATE_KEY_CANVAS_OBJECT = 'canvasAsObject';

export const templateEditorConstants = {
  GET_TEMPLATE_BY_ID,
  UPDATE_TEMPLATE_BY_ID,
  GET_TEMPLATE_PREVIEW,
  COPY_PRE_MADE_TEMPLATE,
  GET_PRE_MADE_TEMPLATES,
  CLEAR_TEMPLATE,
  CREATE_TEMPLATE,

  GET_USER_FONTS,
  ADD_USER_FONTS,
  GET_USER_FONT_BY_ID,
  UPLOAD_USER_FONT,
  UPDATE_USER_FONT,
  REMOVE_USER_FONT,

  GET_MRU_VARIABLE_KEYS,

  UPDATE_LOCAL_CANVAS,

  STATE_KEY_TEMPLATE,
  STATE_KEY_PRE_MADE_TEMPLATES,
  STATE_KEY_PRE_MADE_TEMPLATES_LOADING,
  STATE_KEY_COPIED_PRE_MADE_TEMPLATE,
  STATE_KEY_MRU_VARIABLE_KEYS,
  STATE_KEY_USER_FONTS,
  STATE_KEY_USER_FONT,
  STATE_KEY_TEMPLATE_PREVIEW,
  STATE_KEY_PREVIEW_LOADING,
  STATE_KEY_MRU_VARIABLE_KEYS_LOADING,
  STATE_KEY_LOCAL_CANVAS,
  STATE_KEY_CANVAS_OBJECT,
};

// Reducer
const reducer = (ogState = {}, action) => {
  const state = fromJS(ogState);

  switch (action.type) {
    case start(GET_TEMPLATE_BY_ID):
      return state.set(STATE_KEY_TEMPLATE, null).toJS();
    case success(GET_TEMPLATE_BY_ID):
      return state.set(STATE_KEY_TEMPLATE, action.payload).toJS();
    case success(UPDATE_TEMPLATE_BY_ID):
      return state.set(STATE_KEY_TEMPLATE, action.payload).toJS();
    case UPDATE_LOCAL_CANVAS:
      return state
        .merge({
          [STATE_KEY_LOCAL_CANVAS]: action.payload,
          [STATE_KEY_CANVAS_OBJECT]: action.payload?.toJSON(),
        })
        .toJS();
    case start(GET_TEMPLATE_PREVIEW):
      return state
        .merge({
          [STATE_KEY_TEMPLATE_PREVIEW]: null,
          [STATE_KEY_PREVIEW_LOADING]: true,
        })
        .toJS();
    case success(GET_TEMPLATE_PREVIEW):
      return state
        .merge({
          [STATE_KEY_TEMPLATE_PREVIEW]: action.payload,
          [STATE_KEY_PREVIEW_LOADING]: false,
        })
        .toJS();
    case CLEAR_TEMPLATE:
      return state.set(STATE_KEY_TEMPLATE, action.payload).toJS();
    case success(CREATE_TEMPLATE):
      return state.set(STATE_KEY_TEMPLATE, action.payload).toJS();

    case start(GET_PRE_MADE_TEMPLATES):
      return state
        .merge({
          [STATE_KEY_PRE_MADE_TEMPLATES]: null,
          [STATE_KEY_PRE_MADE_TEMPLATES_LOADING]: true,
        })
        .toJS();
    case success(GET_PRE_MADE_TEMPLATES):
      return state
        .merge({
          [STATE_KEY_PRE_MADE_TEMPLATES]: action.payload,
          [STATE_KEY_PRE_MADE_TEMPLATES_LOADING]: false,
        })
        .toJS();
    case start(GET_MRU_VARIABLE_KEYS):
      return state
        .merge({
          [STATE_KEY_MRU_VARIABLE_KEYS]: null,
          [STATE_KEY_MRU_VARIABLE_KEYS_LOADING]: true,
        })
        .toJS();
    case success(GET_MRU_VARIABLE_KEYS):
      const pageData = action.payload;
      const arr = pageData.data.map(mru => {
        return {
          name: mru.key,
          value: `{{${mru.key}}}`,
        };
      });

      return state
        .merge({
          [STATE_KEY_MRU_VARIABLE_KEYS]: arr,
          [STATE_KEY_MRU_VARIABLE_KEYS_LOADING]: false,
        })
        .toJS();

    // TODO: Find and add/update/remove in STATE_KEY_USER_FONTS (Plural)...
    // Assigning to STATE_KEY_USER_FONT (Singular) is a placeholder.
    case success(GET_USER_FONTS):
      return state.set(STATE_KEY_USER_FONTS, action.payload.data.data).toJS();
    case success(ADD_USER_FONTS):
      return state
        .set(STATE_KEY_USER_FONTS, {
          ...ogState.userFonts,
          private: [...ogState.userFonts.private, action.payload],
        })
        .toJS();
    case success(GET_USER_FONT_BY_ID):
      return state.set(STATE_KEY_USER_FONT, action.payload).toJS();
    case success(UPLOAD_USER_FONT):
      return state.set(STATE_KEY_USER_FONT, action.payload).toJS();
    case success(UPDATE_USER_FONT):
      return state.set(STATE_KEY_USER_FONT, action.payload).toJS();
    case success(REMOVE_USER_FONT):
      return state.set(STATE_KEY_USER_FONT, null).toJS();

    // GENERIC API ERROR HANDLER, will match any error for any type
    case error(action.type):
      return ogState;

    default:
      return ogState;
  }
};

// Actions
export const templateEditorActions = {
  getTemplateById: (id, successCallback, failureCallback) =>
    api.getTemplateById(
      id,
      GET_TEMPLATE_BY_ID,
      successCallback,
      failureCallback
    ),
  clearTemplate: () => ({ type: CLEAR_TEMPLATE, payload: null }),
  createTemplateDoubleSided: (payload, successCallback, failureCallback) => {
    const formData = new FormData();
    const payloadClone = { ...payload };

    if (!payload.ad_template_vars) {
      payload.ad_template_vars = [];
    }

    if (!payload.ad_template_pages) {
      payload.ad_template_pages = [];
    }

    if (
      payload.ad_template_pages[0]?.bg_img ||
      payload.ad_template_pages[1]?.bg_img
    ) {
      for (let i = 0; i < payload.ad_template_pages.length; i++) {
        if (payload.ad_template_pages[i]?.bg_img) {
          formData.append(
            payload.ad_template_pages[i].bg_img.name,
            payload.ad_template_pages[i].bg_img
          );
          payloadClone.ad_template_pages[i].bg_img =
            payloadClone.ad_template_pages[i].bg_img.name;
        }
      }
      formData.append('data', JSON.stringify(payloadClone));
      return api.createTemplateDoubleSided(
        formData,
        CREATE_TEMPLATE,
        successCallback,
        failureCallback,
        'form-data'
      );
    } else if (payload?.bg_img) {
      for (let key in payload) {
        if (
          key === 'ad_template_vars' ||
          key === 'metadata' ||
          key === 'ad_template_pages'
        ) {
          formData.append(`${key}`, JSON.stringify(payload[key]));
        } else {
          formData.append(`${key}`, payload[key]);
        }
      }
      return api.createTemplateDoubleSided(
        formData,
        CREATE_TEMPLATE,
        successCallback,
        failureCallback,
        'form-data'
      );
    } else {
      return api.createTemplateDoubleSided(
        payload,
        CREATE_TEMPLATE,
        successCallback,
        failureCallback,
        'json'
      );
    }
  },
  updateTemplateByIdDoubleSided: (
    templateID,
    payload,
    successCallback,
    failureCallback
  ) => {
    const formData = new FormData();
    const payloadClone = { ...payload };

    if (!payload.ad_template_vars) {
      payload.ad_template_vars = [];
    }

    if (!payload.ad_template_pages) {
      payload.ad_template_pages = [];
    }

    if (
      payload.ad_template_pages[0]?.bg_img ||
      payload.ad_template_pages[1]?.bg_img
    ) {
      for (let i = 0; i < payload.ad_template_pages.length; i++) {
        if (payload.ad_template_pages[i]?.bg_img) {
          formData.append(
            payload.ad_template_pages[i].bg_img.name,
            payload.ad_template_pages[i].bg_img
          );
          payloadClone.ad_template_pages[i].bg_img =
            payloadClone.ad_template_pages[i].bg_img.name;
        }
      }
      formData.append('data', JSON.stringify(payloadClone));
      var res =  api.updateTemplateDoubleSidedById(
        templateID,
        formData,
        UPDATE_TEMPLATE_BY_ID,
        successCallback,
        failureCallback,
        'form-data'
      );
      return res;
    } else if (payload.bg_img) {
      for (let key in payload) {
        if (
          key === 'ad_template_vars' ||
          key === 'metadata' ||
          key === 'ad_template_pages'
        ) {
          formData.append(`${key}`, JSON.stringify(payload[key]));
        } else {
          formData.append(`${key}`, payload[key]);
        }
      }
      return api.updateTemplateDoubleSidedById(
        templateID,
        formData,
        UPDATE_TEMPLATE_BY_ID,
        successCallback,
        failureCallback,
        'form-data'
      );
    } else {
      return api.updateTemplateDoubleSidedById(
        templateID,
        payload,
        UPDATE_TEMPLATE_BY_ID,
        successCallback,
        failureCallback,
        'json'
      );
    }
  },
  createTemplate: (payload, successCallback, failureCallback) => {
    const formData = new FormData();

    if (!payload.ad_template_vars) {
      payload.ad_template_vars = [];
    }

    if (!payload.ad_template_pages) {
      payload.ad_template_pages = [];
    }

    for (let key in payload) {
      if (
        key === 'ad_template_vars' ||
        key === 'metadata' ||
        key === 'ad_template_pages'
      ) {
        formData.append(`${key}`, JSON.stringify(payload[key]));
      } else {
        formData.append(`${key}`, payload[key]);
      }
    }
    return api.createTemplate(
      formData,
      CREATE_TEMPLATE,
      successCallback,
      failureCallback
    );
  },
  updateTemplateById: (
    templateID,
    payload,
    successCallback,
    failureCallback
  ) => {
    const formData = new FormData();

    for (let key in payload) {
      if (key === 'ad_template_vars' || key === 'metadata') {
        formData.append(`${key}`, JSON.stringify(payload[key]));
      } else {
        formData.append(`${key}`, payload[key]);
      }
    }

    return api.updateTemplateById(
      templateID,
      formData,
      UPDATE_TEMPLATE_BY_ID,
      successCallback,
      failureCallback
    );
  },
  getTemplatePreview: (payload, doneCallback) => {
    if (payload.template.double_sided === true) {
      delete payload.template.ad_template_pages;
    }

    const formData = new FormData();

    for (let key in payload) {
      if (key === 'template' || key === 'params') {
        formData.append(`${key}`, JSON.stringify(payload[key]));
      } else {
        formData.append(`${key}`, payload[key]);
      }
    }

    return api.getTemplatePreview(formData, GET_TEMPLATE_PREVIEW, doneCallback);
  },
  getPremadeTemplates: (successCallback, failureCallback) =>
    api.getPremadeTemplates(
      GET_PRE_MADE_TEMPLATES,
      successCallback,
      failureCallback
    ),
  copyPremadeTemplate: payload => {
    // Payload sample: "const payload = { id }", where id is the template id to copy.
    // Eligible templates to copy should have a "company_id" matching user's company_id
    // or have "ad_template_company_id: null"
    return api.copyPremadeTemplate(payload, COPY_PRE_MADE_TEMPLATE);
  },
  getMruVariableKeys: (successCallback, failureCallback) =>
    api.getMruVariableKeys(
      GET_MRU_VARIABLE_KEYS,
      successCallback,
      failureCallback
    ),
  getEnabledDoubleSideStatus: (successCallback, failureCallback) =>
    api.getEnabledDoubleSideStatus(
      GET_DOUBLE_SIDED_STATUS,
      successCallback,
      failureCallback
    ),
  getUserFonts: (successCallback, failureCallback) =>
    api.getUserFonts(GET_USER_FONTS, successCallback, failureCallback),
  addUserFonts: (payload, doneCallback) =>
    api.addUserFont(payload, ADD_USER_FONTS, doneCallback),
  getUserFontById: (id, doneCallback) =>
    api.getUserFontById(id, GET_USER_FONT_BY_ID, doneCallback),
  updateUserFont: (id, payload) =>
    api.updateUserFont(id, payload, UPDATE_USER_FONT),
  addUserFont: (payload, doneCallback, failureCallback) =>
    api.addUserFont(payload, UPLOAD_USER_FONT, doneCallback, failureCallback),
  removeUserFont: (id, doneCallback) =>
    api.removeUserFont(id, REMOVE_USER_FONT, doneCallback),
  updateLocalCanvas: payload => ({ type: UPDATE_LOCAL_CANVAS, payload }),
};

// Selectors
export const templateEditorSelectors = {
  userFonts: state => state.modules.templateEditor[STATE_KEY_USER_FONTS],
  template: state => state.modules.templateEditor[STATE_KEY_TEMPLATE],
  localCanvas: state => state.modules.templateEditor[STATE_KEY_LOCAL_CANVAS],
  canvasAsObject: state =>
    state.modules.templateEditor[STATE_KEY_CANVAS_OBJECT],
  premadeTemplates: state =>
    state.modules.templateEditor[STATE_KEY_PRE_MADE_TEMPLATES],
  isPremadeTemplatesLoading: state =>
    state.modules.templateEditor[STATE_KEY_PRE_MADE_TEMPLATES_LOADING],
  copiedPremadeTemplate: state =>
    state.modules.templateEditor[STATE_KEY_COPIED_PRE_MADE_TEMPLATE],
  mruVariableKeys: state =>
    state.modules.templateEditor[STATE_KEY_MRU_VARIABLE_KEYS],
  templatePreview: state =>
    state.modules.templateEditor[STATE_KEY_TEMPLATE_PREVIEW],
  isPreviewLoading: state =>
    state.modules.templateEditor[STATE_KEY_PREVIEW_LOADING],
  isMruVariableKeysLoading: state =>
    state.modules.templateEditor[STATE_KEY_MRU_VARIABLE_KEYS_LOADING],
  getApiError: createApiErrorSelector([
    GET_TEMPLATE_BY_ID,
    UPDATE_TEMPLATE_BY_ID,
    GET_TEMPLATE_PREVIEW,

    GET_PRE_MADE_TEMPLATES,
    COPY_PRE_MADE_TEMPLATE,

    GET_MRU_VARIABLE_KEYS,
    GET_DOUBLE_SIDED_STATUS,
    GET_USER_FONTS,
    GET_USER_FONT_BY_ID,
    UPLOAD_USER_FONT,
    UPDATE_USER_FONT,
    REMOVE_USER_FONT,

    CREATE_TEMPLATE,
  ]),
};

export default reducer;
