// kv_relational_op has a default below and is set dynamically based on op selection:
// gt, ge, lt, le, eq, ne

export const customName = "Custom Data Field and Value";
export const paramTemplateTypes = [
  {
    id: null,
    name: "Order Value",
    key: "order_val",
    kv_relational_op: "gt",
    sort: null,
    value: "",
    value_type: "int",
  },
  {
    id: null,
    name: "Order Count",
    key: "order_count",
    kv_relational_op: "gt",
    sort: null,
    value: "",
    value_type: "int",
  },
  {
    id: null,
    name: "Order Placed",
    key: "order_date",
    kv_relational_op: "eq",
    sort: null,
    value: "",
    value_type: "dt",
  },
  {
    id: null,
    name: "Shipped to State",
    key: "shipping_state",
    kv_relational_op: "eq",
    sort: null,
    value: "",
    value_type: "str",
  },
  {
    id: null,
    name: "Shipped to City",
    key: "shipping_city",
    kv_relational_op: "eq",
    sort: null,
    value: "",
    value_type: "str",
  },
  {
    id: null,
    name: customName,
    key: "custom_key",
    kv_relational_op: "eq",
    sort: null,
    value: "",
    value_type: "str",
  }
];