import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
  Box,
  Button,
  IconButton,
  Input,
  Tooltip,
  useDisclosure,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Text,
} from '@chakra-ui/react';
import { HexColorPicker } from 'react-colorful';
import _ from 'lodash';
import Icons from '../../Icons';
import { useEditor } from 'components/Canvas';
import PanelTitle from './components/PanelTitle';
import useDoubleSided from '../../../../../components/Canvas/hooks/useDoubleSided';
import useBackgroundImage from '../../../../../components/Canvas/hooks/useBackgroundImage';
import UploadImageConfirmModal from './components/UploadImageConfirmModal';
import useBackgroundColor from '../../../../../components/Canvas/hooks/useBackgroundColor';
import { colorPickerList } from 'components/Canvas/common/constants';
import './Background.css';

function Background() {
  const editor = useEditor();
  const changeBgField = useRef(null);
  const { doubleSided } = useDoubleSided();
  const backgroundImage = useBackgroundImage();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [canvasBackgroundExists, setCanvasBackgroundExists] = useState(false);
  const [eTarget, setETarget] = useState(null);
  const [colorPickerIndex, setColorPickerIndex] = useState({
    recommend: '-1',
    document: '-1',
    solid: '-1',
    gradient: '-1',
  });
  const colorPickerRef = React.createRef();
  const backgroundColor = useBackgroundColor();
  const [prevBtnVisible, setPrevBtnVisible] = useState(false);
  const [nextBtnVisible, setNextBtnVisible] = useState(true);

  const [options, setOptions] = useState({
    backgroundColor: '#000000',
  });

  const setBackgroundColor = useCallback(
    color => {
      editor.handlers.frameHandler.setBackgroundColor(color);
      setOptions({ ...options, backgroundColor: color });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [editor]
  );

  useEffect(() => {
    setOptions({ ...options, backgroundColor: backgroundColor });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [backgroundColor]);

  useEffect(() => {
    if (editor) {
      const backgroundColor = editor.handlers.frameHandler.getBackgroundColor();
      setOptions({ ...options, backgroundColor });
    }
    // editor.handlers.objectsHandler.getDocumentColors();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editor]);

  const onScroll = () => {
    setPrevBtnVisible(colorPickerRef.current.scrollLeft > 10);
    setNextBtnVisible(colorPickerRef.current.scrollLeft < 295);
  };

  const updateBG = e => {
    const target = e.currentTarget;
    const file = target.files[0];
    if (file.size >= 2e6) {
      setETarget(target);
      onOpen();
    } else onUpload(target);
  };

  const onUpload = target => {
    onClose();
    if (!target) return;
    const file = target.files[0];
    const url = URL.createObjectURL(file);
    editor.handlers.frameHandler.setBackgroundImageURL(url, file.name);
    target.value = '';
    setCanvasBackgroundExists(true);
    setTimeout(() => {
      editor.handlers.historyHandler.save('frame:update');
    }, 100);
    closeModal();
  };

  const closeModal = () => {
    if (eTarget) eTarget.value = '';
    setETarget(null);
    onClose();
  };

  const removeBG = () => {
    editor.handlers.frameHandler.removeBackgroundImage();
    setCanvasBackgroundExists(false);
    setTimeout(() => {
      editor.handlers.historyHandler.save('frame:update');
    }, 100);
  };

  useEffect(() => {
    if (editor) {
      const exportedTemplate = editor.handlers.templateHandler.exportToJSON();
      if (!doubleSided) {
        const isBG = !!exportedTemplate.bg_img?.original_url;
        if (isBG) {
          setCanvasBackgroundExists(true);
        } else {
          setCanvasBackgroundExists(false);
        }
      }

      if (doubleSided) {
        if (backgroundImage) {
          setCanvasBackgroundExists(true);
        } else {
          setCanvasBackgroundExists(false);
        }
      }
    }
  }, [editor, backgroundImage, doubleSided]);

  const scrollRight = () => {
    colorPickerRef.current.scrollLeft < 220 &&
      colorPickerRef.current.scrollTo({
        left: colorPickerRef.current.scrollLeft + 100,
        behavior: 'smooth',
      });
  };

  const scrollLeft = () => {
    colorPickerRef.current.scrollLeft > 0 &&
      colorPickerRef.current.scrollTo({
        left: colorPickerRef.current.scrollLeft - 100,
        behavior: 'smooth',
      });
  };

  const colorPikcerPopOver = (
    <Popover placement="bottom-start">
      <PopoverTrigger>
        <IconButton
          minWidth="40px"
          height="40px"
          variant="outline"
          marginRight="8px"
          borderRadius="4px"
          border="none"
          display="flex"
          alignItems={'center'}
          justifyContent={'center'}
          size="sm"
          _hover={{ background: 'hsla(0,0%,100%,.15)' }}
          background="#343536"
          icon={<Icons.ColorPicker size={24} />}
        />
      </PopoverTrigger>
      <PopoverContent
        width={'347px'}
        maxHeight={'336px'}
        overflowY={'auto'}
        overflowX={'hidden'}
        padding={'0.5em 1.5em'}
      >
        <Box display={'flex'} alignItems={'center'}>
          <IconButton
            minWidth="34px"
            height="34px"
            variant="outline"
            borderRadius="4px"
            border="none"
            display="flex"
            alignItems={'center'}
            justifyContent={'flex-start'}
            size="sm"
            color={'#0d1216'}
            icon={<Icons.DocumentColor size={24} />}
          />
          <Text as="span" fontSize="14px" color={'#0d1216'} fontWeight={600}>
            Document colors
          </Text>
        </Box>
        <Box display={'flex'} flexWrap={'wrap'}>
          <Popover placement="right-end">
            <PopoverTrigger>
              <Box
                className="colorPicker-button"
                minWidth="34px"
                height="34px"
                borderRadius="4px"
                marginTop="16px"
                marginBottom="16px"
                marginRight="8px"
                border="none"
              >
                <IconButton
                  position={'absolute'}
                  minWidth="34px"
                  height="34px"
                  variant="outline"
                  borderRadius="4px"
                  border="none"
                  display="flex"
                  alignItems={'center'}
                  justifyContent={'center'}
                  size="sm"
                  color={'#0d1216'}
                  _hover={{
                    boxShadow:
                      '0 0 0 1px #8b3dff,inset 0 0 0 2px #8b3dff,inset 0 0 0 4px #fff',
                    transition: 'box-shadow .2s ease-in-out',
                  }}
                  icon={<Icons.PlusTE size={16} />}
                />
              </Box>
            </PopoverTrigger>
            <PopoverContent
              sx={{
                display: 'flex',
                width: 'auto',
                padding: '1rem',
                top: '50px',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '0.25rem',
                }}
              >
                <HexColorPicker
                  name="colorPicker"
                  color={options.backgroundColor}
                  onChange={color => setBackgroundColor(color)}
                />
                <Box sx={{ width: '200px' }}>
                  <Input
                    textAlign="center"
                    name="colorSelector"
                    color={'#0d1216'}
                    value={options.backgroundColor}
                    onChange={e =>
                      setOptions({
                        ...options,
                        backgroundColor: e.target.value,
                      })
                    }
                  />
                </Box>
              </Box>
            </PopoverContent>
          </Popover>

          {editor.handlers.objectsHandler
            .getDocumentColors()
            .map((color, index) => {
              return (
                <Tooltip key={_.uniqueId('colorPickerDefault')} label={color}>
                  <Box
                    sx={{
                      borderRadius: '4px',
                      overflow: 'hidden',
                      minWidth: '34px',
                      height: '34px',
                      background: color,
                      border: '1px solid #838383',
                      marginTop: '16px',
                      marginBottom: '16px',
                      marginRight: '8px',
                      cursor: 'pointer',
                      transition: 'box-shadow .2s ease-in-out',
                      boxShadow:
                        colorPickerIndex.document === index
                          ? '0 0 0 1px #8b3dff,inset 0 0 0 2px #8b3dff,inset 0 0 0 4px #fff'
                          : 'none',
                      ':hover': {
                        boxShadow:
                          '0 0 0 1px #8b3dff,inset 0 0 0 2px #8b3dff,inset 0 0 0 4px #fff',
                        transition: 'box-shadow .2s ease-in-out',
                      },
                    }}
                    onClick={() => {
                      setBackgroundColor(color);
                      setOptions({ ...options, backgroundColor: color });
                      setColorPickerIndex({
                        ...colorPickerIndex,
                        document: index,
                      });
                    }}
                  />
                </Tooltip>
              );
            })}
        </Box>
        <Box display={'flex'} alignItems={'center'}>
          <IconButton
            minWidth="34px"
            height="34px"
            variant="outline"
            borderRadius="4px"
            border="none"
            display="flex"
            alignItems={'center'}
            justifyContent={'flex-start'}
            size="sm"
            color={'#0d1216'}
            icon={<Icons.DefaultColor size={24} />}
          />
          <Text as="span" fontSize="14px" color={'#0d1216'} fontWeight={600}>
            Default colors
          </Text>
        </Box>
        <Box
          fontSize={'10px'}
          marginTop={'8px'}
          color={'rgba(13,18,22,.7)'}
          fontWeight={400}
        >
          Solid Colors
        </Box>
        <Box display={'flex'} width={'290px'} flexWrap={'wrap'}>
          {colorPickerList.solid.map((colorContent, index) => {
            let color = '#' + colorContent.split('#')[1];
            return (
              <Tooltip
                label={colorContent}
                key={_.uniqueId('colorPickerSolid')}
              >
                <Box
                  sx={{
                    borderRadius: '4px',
                    overflow: 'hidden',
                    minWidth: '40px',
                    height: '40px',
                    background: color,
                    marginTop: '8px',
                    marginBottom: '8px',
                    marginRight: '8px',
                    cursor: 'pointer',
                    border: '1px solid #838383',
                    transition: 'box-shadow .2s ease-in-out',
                    boxShadow:
                      colorPickerIndex.solid === index
                        ? '0 0 0 1px #8b3dff,inset 0 0 0 2px #8b3dff,inset 0 0 0 4px #fff'
                        : 'none',
                    ':hover': {
                      boxShadow:
                        '0 0 0 1px #8b3dff,inset 0 0 0 2px #8b3dff,inset 0 0 0 4px #fff',
                      transition: 'box-shadow .2s ease-in-out',
                    },
                  }}
                  onClick={() => {
                    setBackgroundColor(color);
                    setOptions({ ...options, backgroundColor: color });
                    setColorPickerIndex({
                      ...colorPickerIndex,
                      solid: index,
                    });
                  }}
                />
              </Tooltip>
            );
          })}
        </Box>
        {/* <Box
            fontSize={'10px'}
            marginTop={'8px'}
            color={'rgba(13,18,22,.7)'}
            fontWeight={400}
          >
            Gradients
          </Box>
          <Box display={'flex'} width={'290px'} flexWrap={'wrap'}>
            {colorPickerList.gradient.map((colorContent, index) => {
              let gradient =
                'linear-gradient(90deg,' +
                '#' +
                colorContent.split('#')[1] +
                '#' +
                colorContent.split('#')[2] +
                ')';
              return (
                <Box
                  key={_.uniqueId('colorPickerGradient')}
                  sx={{
                    borderRadius: '4px',
                    overflow: 'hidden',
                    minWidth: '40px',
                    height: '40px',
                    backgroundImage: gradient,
                    marginTop: '8px',
                    marginBottom: '8px',
                    marginRight: '8px',
                    cursor: 'pointer',
                    border: '1px solid #838383',
                    transition: 'box-shadow .2s ease-in-out',
                    boxShadow:
                      colorPickerIndex.gradient === index
                        ? '0 0 0 1px #8b3dff,inset 0 0 0 2px #8b3dff,inset 0 0 0 4px #fff'
                        : 'none',
                    ':hover': {
                     boxShadow:
                        '0 0 0 1px #8b3dff,inset 0 0 0 2px #8b3dff,inset 0 0 0 4px #fff'
                      transition: 'box-shadow .2s ease-in-out',
                    },
                  }}
                  onClick={() => {
                    setBackgroundColor(gradient);
                    setOptions({ ...options, backgroundColor: gradient });
                    setColorPickerIndex({
                      ...colorPickerIndex,
                      gradient: index,
                    });
                  }}
                />
              );
            })}
          </Box> */}
      </PopoverContent>
    </Popover>
  );

  return (
    <>
      <Box sx={{ flex: 1, marginTop: '1.5rem' }}>
        <PanelTitle
          title="Click to add to canvas"
          subMenu="BackgroundTutorial"
        />
        <Box sx={{ color: '#ffffff', padding: '1.5rem' }}>
          <Input
            type="file"
            sx={{ display: 'none' }}
            ref={changeBgField}
            onChange={updateBG}
          />
          <Box width="312px" position="relative">
            <Box
              background="linear-gradient(to left,#0000,#29303A)"
              width="24px"
              height="40px"
              top="16px"
              position="absolute"
              lineHeight="40px"
              cursor="pointer"
              fontSize={'1.5rem'}
              display="flex"
              alignItems="center"
              onClick={scrollLeft}
            >
              {prevBtnVisible && <Icons.Prev />}
            </Box>
            <Box
              position="absolute"
              right="0px"
              width="24px"
              height="40px"
              top="16px"
              aria-hidden="true"
              lineHeight="40px"
              display="flex"
              alignItems="center"
              background="linear-gradient(to right, #0000, #29303A)"
              cursor="pointer"
              fontSize={'1.5rem'}
              onClick={scrollRight}
            >
              {nextBtnVisible && <Icons.Next />}
            </Box>
            <Box
              display="flex"
              alignItems="center"
              overflow="hidden"
              width="312px"
              onScroll={onScroll}
              ref={colorPickerRef}
            >
              {colorPikcerPopOver}
              {colorPickerList.recommend.map((color, index) => {
                return (
                  <Box
                    key={_.uniqueId('colorPicker')}
                    sx={{
                      borderRadius: '4px',
                      overflow: 'hidden',
                      minWidth: '40px',
                      height: '40px',
                      background: color,
                      marginTop: '16px',
                      marginBottom: '16px',
                      marginRight: '8px',
                      cursor: 'pointer',
                      transition: 'box-shadow .2s ease-in-out',
                      boxShadow:
                        colorPickerIndex.recommend === index
                          ? '0 0 0 1px #8b3dff,inset 0 0 0 2px #8b3dff,inset 0 0 0 4px #fff'
                          : 'none',
                      ':hover': {
                        boxShadow:
                          '0 0 0 1px #8b3dff,inset 0 0 0 2px #8b3dff,inset 0 0 0 4px #fff',
                        transition: 'box-shadow .2s ease-in-out',
                      },
                    }}
                    onClick={() => {
                      setBackgroundColor(color);
                      setOptions({ ...options, gradient: color });
                      setColorPickerIndex({
                        ...colorPickerIndex,
                        recommend: index,
                      });
                    }}
                  />
                );
              })}
            </Box>
          </Box>
          <Box sx={{ display: 'flex', gap: '0.25rem' }}>
            <Button
              w="100%"
              onClick={() => changeBgField.current.click()}
              variant="ghost"
              sx={{
                display: 'flex',
                fontWeight: 500,
                paddingRight: '1rem',
                fontSize: '14px',
                color: '#ffffff',
                backgroundColor: 'rgba(255,255,255,0.1)',
                justifyContent: 'flex-start',
                height: '48px',
                ':hover': {
                  backgroundColor: 'rgba(255,255,255,0.15)',
                  cursor: 'pointer',
                },
              }}
            >
              {canvasBackgroundExists ? `Change background` : `Add background`}
            </Button>
            {canvasBackgroundExists && (
              <Box
                sx={{
                  width: '4rem',
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <Tooltip label="Remove">
                  <IconButton
                    onClick={() => removeBG()}
                    color="#ffffff"
                    variant="ghost"
                    icon={<Icons.Cross size={24} />}
                    sx={{
                      color: '#ffffff',
                      backgroundColor: 'rgba(255,255,255,0.1)',
                      ':hover': {
                        backgroundColor: 'rgba(255,255,255,0.15)',
                        cursor: 'pointer',
                      },
                    }}
                  />
                </Tooltip>
              </Box>
            )}
          </Box>
        </Box>
      </Box>
      <UploadImageConfirmModal
        isOpen={isOpen}
        onClose={closeModal}
        onConfirm={() => onUpload(eTarget)}
        onCancel={closeModal}
      />
    </>
  );
}

export default Background;
