// import * as colors from 'theme/Colors.module.scss';

export const sharedOptions = {
  // animation: {
  //   duration: 500,
  //   easing: 'easeOutBack',
  // },
  // bezierCurve: false,
  // tooltips: {
  //   intersect: false,
  //   callbacks: {
  //     label: tooltipItem => tooltipItem.yLabel.toLocaleString(),
  //   },
  // },
  // scales: {
  // xAxes: [
  //   {
  //     gridLines: {
  //       borderDash: [4, 4],
  //       color: colors.neutral100,
  //       lineWidth: 2.5,
  //     },
  //     type: 'time',
  //     distribution: 'linear',
  //     time: {
  //       tooltipFormat: 'ddd, MMM Do YYYY',
  //       unit: 'day',
  //       stepSize: 1,
  //     },
  //   },
  // ],
  // yAxes: [
  //   {
  //     ticks: {
  //       beginAtZero: true,
  //       userCallback: value => value.toLocaleString(),
  //     },
  //     gridLines: {
  //       color: colors.neutral100,
  //       lineWidth: 2,
  //       drawBorder: false,
  //     },
  //   },
  // ],
  //   y: {
  //     beginAtZero: true,
  //   },
  // },
  // elements: {
  //   line: { tension: 0 },
  // },
  // title: { display: false },
  // legend: { display: false },
  // stacked bar options
  scales: {
    xAxes: [
      {
        offset: true,
        type: 'time',
        // distribution: 'linear',
        time: {
          unit: 'day',
          tooltipFormat: 'MMM DD',
          // stepSize: 1,
        },
      },
    ],
    yAxes: [
      {
        ticks: {
          beginAtZero: true,
          userCallback: value => value.toLocaleString(),
        },

        stacked: true,
      },
    ],
    y: {
      ticks: { stepSize: 100 },
    },
  },
};
