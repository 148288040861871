import React from 'react';
import DotLoader from 'react-spinners/DotLoader';
import colors from '../../theme/Colors.module.scss';
import './LoadingIndicator.scss';

const LoadingIndicator = ({
  color,
  loading,
  children,
  minHeight = '10px',
  size = '40px',
}) => {
  let loader = (
    <DotLoader color={color || colors.blue} size={size} margin="4px" />
  );
  return loading === undefined || loading ? (
    <div className="ldg-container" style={{ minHeight: minHeight }}>
      {loader}
    </div>
  ) : (
    <div className="ldg-content-container">{children}</div>
  );
};

export default LoadingIndicator;
