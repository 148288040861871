import React from 'react';
import { Box, Text } from '@chakra-ui/react';
import CustomScroller from 'components/CustomScroller';
import TutorialClose from './components/TutorialClose';

function BackgroundTutorial() {
  return (
    <Box sx={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
      <Box
        sx={{
          flex: 1,
          position: 'relative',
          background: '#ffffff',
        }}
      >
        <TutorialClose />
        <Box
          sx={{
            position: 'absolute',
            height: '100%',
            width: '100%',
          }}
        >
          <CustomScroller>
            <Box
              sx={{
                background: '#ffffff',
                flex: 1,
                borderRight: '1px solid rgba(57,76,96,.15)',
                padding: '1.5rem',
                fontSize: '14px',
              }}
            >
              <Text sx={{ fontSize: '18px', fontWeight: 700 }}>Background</Text>
              <Box lineHeight="22px">
                <Text mt="1rem">
                  You can upload an image that will be the background for the ad
                  template. The recommended approach would be to create a
                  background image that is of the same exact dimensions to the
                  paper size format.
                </Text>
                <Text mt="1rem">
                  To determine the dimensions, you can multiply the measurement
                  in inches by 300 to get the exact pixel dimensions.
                </Text>

                <Text mt="1rem">
                  For example, 4 in x 8 in paper would be an image of 1200
                  pixels by 2400 pixels. (4 x 300 = 1200 and 8 x 300 = 2400).
                </Text>
              </Box>
            </Box>
          </CustomScroller>
        </Box>
      </Box>
    </Box>
  );
}
export default BackgroundTutorial;
