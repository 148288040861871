import { fromJS } from 'immutable';
import { error } from '../../helpers/reducerHelpers';
// import { createSelector } from 'reselect';
import { actionCreator } from 'helpers/actionHelpers';
import { createApiErrorSelector } from '../api/apiDuck';
import {
  zoomLevels,
  defaultGridSize,
  defaultGridColor,
} from 'helpers/canvasHelpers';
// import * as api from "./canvasUtilityApi";

// Constants
const TOGGLE_GRID_VISIBILITY = 'modules/canvasUtility/TOGGLE_GRID_VISIBILITY';
const TOGGLE_GRID_SNAPPING = 'modules.canvasUtility/TOGGLE_GRID_SNAPPING';
const SET_GRID_SIZE = 'modules/canvasUtility/SET_GRID_SIZE';
const RESET_GRID_SIZE = 'modules/canvasUtility/RESET_GRID_SIZE';
const SET_ZOOM_LEVEL = 'modules/canvasUtility/SET_ZOOM_LEVEL';
const SET_GRID_COLOR = 'modules/canvasUtility/SET_GRID_COLOR';
const RESET_GRID_COLOR = 'modules/canvasUtility/RESET_GRID_COLOR';

const STATE_KEY_GRID_VISIBILITY = 'gridVisibility';
const STATE_KEY_GRID_SNAPPING = 'gridSnapping';
const STATE_KEY_GRID_SIZE = 'gridSize';
const STATE_KEY_GRID_COLOR = 'gridColor';
const STATE_KEY_ZOOM_LEVEL = 'zoomLevel';

export const canvasUtilityConstants = {
  TOGGLE_GRID_VISIBILITY,
  TOGGLE_GRID_SNAPPING,
  SET_GRID_SIZE,
  RESET_GRID_SIZE,
  SET_ZOOM_LEVEL,
  SET_GRID_COLOR,
  RESET_GRID_COLOR,

  STATE_KEY_GRID_VISIBILITY,
  STATE_KEY_GRID_SNAPPING,
  STATE_KEY_GRID_SIZE,
  STATE_KEY_GRID_COLOR,
  STATE_KEY_ZOOM_LEVEL,
};

// Reducer
const reducer = (
  ogState = {
    [STATE_KEY_GRID_VISIBILITY]: false,
    [STATE_KEY_GRID_SNAPPING]: false,
    [STATE_KEY_GRID_SIZE]: defaultGridSize,
    [STATE_KEY_ZOOM_LEVEL]: zoomLevels[3],
  },
  action
) => {
  const state = fromJS(ogState);

  switch (action.type) {
    case TOGGLE_GRID_VISIBILITY:
      const gridVisibility = state.getIn([STATE_KEY_GRID_VISIBILITY]);
      return state.set(STATE_KEY_GRID_VISIBILITY, !gridVisibility).toJS();
    case TOGGLE_GRID_SNAPPING:
      const gridSnapping = state.getIn([STATE_KEY_GRID_SNAPPING]);
      return state.set(STATE_KEY_GRID_SNAPPING, !gridSnapping).toJS();
    case SET_GRID_SIZE:
      return state.set(STATE_KEY_GRID_SIZE, action.payload).toJS();
    case RESET_GRID_SIZE:
      return state.set(STATE_KEY_GRID_SIZE, defaultGridSize).toJS();
    case SET_GRID_COLOR:
      return state.set(STATE_KEY_GRID_COLOR, action.payload).toJS();
    case RESET_GRID_COLOR:
      return state.set(STATE_KEY_GRID_COLOR, defaultGridColor).toJS();
    case SET_ZOOM_LEVEL:
      return state.set(STATE_KEY_ZOOM_LEVEL, action.payload).toJS();

    // GENERIC API ERROR HANDLER, will match any error for any type
    case error(action.type):
      return ogState;

    default:
      return ogState;
  }
};

// Actions
export const canvasUtilityActions = {
  toggleGridVisibility: () => ({
    type: TOGGLE_GRID_VISIBILITY,
    payload: null,
  }),
  toggleGridSnapping: () => ({
    type: TOGGLE_GRID_SNAPPING,
    payload: null,
  }),
  setGridSize: actionCreator(SET_GRID_SIZE),
  resetGridSize: () => ({ type: RESET_GRID_SIZE, payload: defaultGridSize }),
  setGridColor: actionCreator(SET_GRID_COLOR),
  resetGridColor: actionCreator(RESET_GRID_COLOR),
  setZoomLevel: actionCreator(SET_ZOOM_LEVEL),
};

// Selectors
export const canvasUtilitySelectors = {
  gridVisibility: state =>
    state.modules.canvasUtility[STATE_KEY_GRID_VISIBILITY],
  gridSnapping: state => state.modules.canvasUtility[STATE_KEY_GRID_SNAPPING],
  gridSize: state => state.modules.canvasUtility[STATE_KEY_GRID_SIZE],
  gridColor: state => state.modules.canvasUtility[STATE_KEY_GRID_COLOR],
  zoomLevel: state => state.modules.canvasUtility[STATE_KEY_ZOOM_LEVEL],

  getApiError: createApiErrorSelector([]),
};

export default reducer;
