import { connect } from 'react-redux';
import App from './App';
import * as appActions from 'modules/app/appActions';
import * as appSelectors from 'modules/app/appSelectors';
import { apiActions } from '../../modules/api/apiDuck';
import { setDateRange } from 'modules/campaigns/campaignsActions';
import { defaultDates } from 'helpers/presetDateHelpers';

function mapStateToProps(state) {
  return {
    token: appSelectors.getToken(state),
    user: appSelectors.getSessionUser(state),
    appMode: appSelectors.getAppMode(state),
    apiError: appSelectors.getApiError(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    testError: () => {
      dispatch(
        apiActions.apiAction({
          path: `notfound`,
          type: 'modules/app/LOG_IN',
        })
      );
    },
    clearApiError: actionType => {
      dispatch(apiActions.clearApiError(actionType));
    },

    login: (userInfo, doneCallback) =>
      dispatch(appActions.login(userInfo, doneCallback)),
    logout: () => {
      dispatch(appActions.logout());
      //set initial date range
      dispatch(
        setDateRange({
          from: new Date(defaultDates.start),
          to: new Date(defaultDates.end),
        })
      );
    },
    uploadTemplate: (formData, doneCallback) =>
      dispatch(appActions.uploadTemplate(formData, doneCallback)),
    getDesignStatus: (id, doneCallback) =>
      dispatch(appActions.getDesignStatus(id, doneCallback)),
    resumeDesignImport: (id, doneCallback) =>
      dispatch(appActions.resumeDesignImport(id, doneCallback)),
    sendUploadInformation: (uploadInformation, doneCallback) =>
      dispatch(
        appActions.sendUploadInformation(uploadInformation, doneCallback)
      ),
    checkToken: () => dispatch(appActions.checkToken()),
    setAppMode: newMode => dispatch(appActions.setAppMode(newMode)),
  };
}

const AppContainer = connect(mapStateToProps, mapDispatchToProps)(App);

export default props => <AppContainer {...props} />;
