import React, { useRef } from 'react';
import { useLocation } from 'react-router-dom';
// import { useCustomRange } from 'helpers/presetDateHelpers';
// import DayPickerInput from 'react-day-picker/DayPickerInput';
// import moment from 'moment';
// import MomentLocaleUtils from 'react-day-picker/moment';
import _, { isEmpty } from 'lodash';
import ToggleSwitch from 'components/ToggleSwitch/ToggleSwitch';
// import LoadingIndicator from 'components/LoadingIndicator/LoadingIndicator';
import Graph from '../Graph/Graph';
import Legend from './DataCardLegend';
import colors from 'theme/Colors.module.scss';

import './DataCard.scss';
import LoadingIndicator from 'components/LoadingIndicator/LoadingIndicator';

const DataCard = props => {
  const {
    title = '',
    dataSet,
    range,
    showLegend,
    onRefresh,
    defaultData,
    // changeDate,
    kpiOptions,
    changeKPI,
    changeDRPresetRange,
    activeKPI,
    // presetDays,
    presetOptions,
    loading,
    visible,
    setVisible,
    customDates,
    setCustomDates,
  } = props;

  const { data } = dataSet;
  const location = useLocation();
  const isAdvertiser = location.pathname.includes('adv');
  const startDateRef = useRef();
  const endDateRef = useRef();

  const redirectToDefault = () => {
    if (defaultData && !isEmpty(defaultData)) {
      return `${isAdvertiser ? '/adv/analytics' : '/analytics'}/${
        defaultData[0].id
      }`;
    }
  };

  const buildLegend = () => {
    if (!showLegend) return null;

    return (
      <div className="legend">
        {data.map(item => {
          return (
            <Legend key={_.uniqueId('Legend_')} useTotal={true} data={item} />
          );
        })}
      </div>
    );
  };

  const buildGraph = () => {
    const empty = !data.some(item => item.values && !isEmpty(item.values));

    if (!empty) {
      return (
        <div className="graph">
          <Graph pointRoute={redirectToDefault()} dataSet={dataSet} />
        </div>
      );
    }

    return <p className="noData">No data found.</p>;
  };

  const buildDateField = (type, ref) => {
    const fieldName = `${type.toLowerCase()}Date`;

    // const disabledDates = () => {
    //   const dayThreshold = 1;

    //   switch (type) {
    //     case 'start':
    //       return {
    //         after: moment(range.endRaw).subtract(dayThreshold, 'days')._d,
    //       };
    //     case 'end':
    //       return {
    //         before: moment(range.startRaw).add(dayThreshold, 'days')._d,
    //       };
    //     default:
    //   }
    // };

    return (
      <div className="dateInput">
        <label htmlFor={fieldName}>
          <div className="rangeTitleContainer">
            <h4 className="title">{_.startCase(type)} Date</h4>

            {type === 'end' && (
              <p className="useCustom">
                Use custom:&nbsp;
                <ToggleSwitch
                  name="customRangeToggle"
                  value={customDates}
                  onChange={() => {
                    setCustomDates(!customDates);
                    changeDRPresetRange(7);
                  }}
                />
              </p>
            )}
          </div>
        </label>

        <div id={fieldName}>
          {/* <DayPickerInput
            ref={ref}
            formatDate={date => {
              const formattedDate = moment(date).format('YYYY-MM-DD');
              return formattedDate;
            }}
            parseDate={date => {
              const convertedFormat = moment(date, 'YYYY-MM-DD').format(
                'MM/DD/YYYY'
              );
              const parsedDate = moment(convertedFormat, 'MM/DD/YYYY')._d;
              return parsedDate;
            }}
            placeholder="YYYY-MM-DD"
            value={range[type]}
            // dayPickerProps={{ localeUtils: MomentLocaleUtils }}
            onDayChange={day => {
              if (type === 'start') {
                const endDateField = endDateRef.current;
                endDateField.input.focus();
                endDateField.input.click();
              }

              return changeDate(day, type);
            }}
            inputProps={{ readOnly: true }}
            dayPickerProps={{
              modifiers: {
                disabled: [disabledDates(), { after: moment()._d }],
              },
            }}
            render={props => <input {...props} />}
          /> */}
        </div>
      </div>
    );
  };

  const showCard = () => {
    const customArrowStyle = {
      top: '3.38rem',
      color: colors.darkGrey,
    };

    return (
      <div className="dataCard">
        <div className="range">
          {customDates && (
            <React.Fragment>
              <div id="startField">{buildDateField('start', startDateRef)}</div>
              <div id="endField">{buildDateField('end', endDateRef)}</div>
            </React.Fragment>
          )}

          {!customDates && (
            <label>
              <div className="rangeTitleContainer">
                <h4 className="title">Preset Range</h4>
                <p className="useCustom">
                  Use custom:&nbsp;
                  <ToggleSwitch
                    name="presetRangeToggle"
                    value={customDates}
                    onChange={() => setCustomDates(!customDates)}
                  />
                </p>
              </div>

              <select
                name="presetRange"
                value={range.days}
                onChange={({ target }) => {
                  const index = target.selectedIndex;
                  changeDRPresetRange(
                    target.value,
                    target[index].dataset.label
                  );
                }}
              >
                {presetOptions.map(option => {
                  return (
                    <option
                      key={option.label}
                      data-label={option.label}
                      value={option.days}
                    >
                      {option.label}
                    </option>
                  );
                })}
              </select>

              <div className="customArrow" style={customArrowStyle}>
                <i className="fas fa-caret-down"></i>
              </div>
            </label>
          )}
        </div>

        <div className="kpi">
          <label>
            <h4 className="title">Key Performance Indicator</h4>

            <select
              name="kpi"
              value={activeKPI}
              onChange={e => changeKPI(e.target.value)}
              style={{ minWidth: '330px' }}
            >
              {kpiOptions}
            </select>

            <div className="customArrow" style={customArrowStyle}>
              <i className="fas fa-caret-down"></i>
            </div>
          </label>
        </div>

        {buildLegend()}
        <LoadingIndicator loading={loading}>{buildGraph()}</LoadingIndicator>
      </div>
    );
  };

  return (
    <>
      <div className="cardHeader">
        <p className="cardTitle">
          {title.toUpperCase()}
          <i className="fas fa-sync" onClick={() => onRefresh()} />
        </p>

        <div className="bufferLine">&nbsp;</div>

        <p className="showHide" onClick={() => setVisible(!visible)}>
          {visible ? 'HIDE THIS' : 'SHOW THIS'}
        </p>
      </div>

      {visible && showCard()}
    </>
  );
};

export default DataCard;
