import React, { useEffect, useState } from 'react';
import { Box, IconButton } from '@chakra-ui/react';
import Common from './components/Common';
import Icons from '../../Icons';
import { useEditor } from 'components/Canvas';
import useEditorContext from 'components/Canvas/hooks/useEditorContext';

function Images() {
  // const mounted = React.useRef(false);
  const { activeObject } = useEditorContext();
  const [options, setOptions] = useState({ scaleToWidth: false });
  const editor = useEditor();

  const getScale = () => {
    const { width, scaleX } = activeObject.toJSON(['metadata']);
    return width * scaleX;
  };

  const toggleScaleToWidth = () => {
    if (!activeObject) return;

    if (!options.scaleToWidth) {
      const newScale = getScale();
      editor.update({ metadata: { run_scaleToWidth: newScale } });
      setOptions({ ...options, scaleToWidth: newScale });
      return;
    }

    editor.update({ metadata: { run_scaleToWidth: undefined } });
    setOptions({ ...options, scaleToWidth: null });
  };

  useEffect(() => {
    if (!activeObject) return;
    let scaleToWidth =
      activeObject.metadata && activeObject.metadata.run_scaleToWidth;
    if (activeObject.subtype === 'qrc') {
      activeObject.setControlsVisibility({
        ml: false,
        mr: false,
        mt: false,
        mb: false,
      });
      const newScale = getScale();
      editor.update({ metadata: { run_scaleToWidth: newScale } });
      scaleToWidth = newScale;
    }
    setOptions({ ...options, scaleToWidth });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeObject]);

  return (
    <Box
      sx={{
        height: '64px',
        padding: '0 1.5rem',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
      }}
    >
      <Box
        sx={{
          color: '#afafaf',
          visibility: activeObject?.subtype === 'qrc' ? 'hidden' : 'visible',
        }}
      >
        <IconButton
          onClick={toggleScaleToWidth}
          color={options.scaleToWidth ? '#000000' : '#b1b1b1'}
          borderRadius="0"
          variant="outline"
          size="sm"
          icon={<Icons.Expand size={32} />}
        />
      </Box>
      <Common />
    </Box>
  );
}

export default Images;
