import React from 'react';

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from '@chakra-ui/react';

import Button from 'components/Button/Button';
import colors from 'theme/Colors.module.scss';
import './AttributionWindowModal.scss';

const AttributionWindowModal = ({ isModalOpen, onModalClose }) => {
  return (
    <Modal
      className="aw-modal"
      closeOnOverlayClick={false}
      isOpen={isModalOpen}
      onClose={onModalClose}
      size="md"
      isCentered
    >
      <ModalOverlay />
      <ModalContent className="aw-modal__content" bgColor={colors.white}>
        <ModalHeader className="aw-modal__header">Are you sure?</ModalHeader>
        <ModalBody className="aw-modal__body">
          The selected attribution window may hinder fair campaign comparisons
          within your timeframe. For instance, with a 60-day window, there
          should be a minimum of 60 days from the end of your selected
          timeframe. This ensures each order has a full 60 days for potential
          conversion.
        </ModalBody>
        <ModalFooter className="aw-modal__footer">
          <Button
            type="button"
            text="I understand!"
            textColor={colors.white}
            textHoverColor={colors.primary900}
            hoverColor={colors.white}
            btnStyle="border"
            boxShadow="0px 1px 2px rgba(16, 24, 40, 0.04)"
            fontSize="12px"
            fontWeight="600"
            padding="10px 24px"
            borderRadius="8px"
            height="34px"
            onClick={() => {
              onModalClose();
              window.sessionStorage.setItem('aw-warning', false);
            }}
          />
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default AttributionWindowModal;
