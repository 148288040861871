import { templateEditorSelectors } from 'modules/templateEditor/templateEditorDuck';
import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect, useState } from 'react';
import { selectCompanyParams } from 'modules/params/companyParamsSelectors';
import * as companyParamsActions from 'modules/params/companyParamsActions';
import { Box } from '@chakra-ui/react';

function MRUMenu({ insertKey, closeMenu }) {
  const dispatch = useDispatch();
  const paramData = useSelector(selectCompanyParams);
  const [companyParams, setCompanyParams] = useState(paramData);
  const mruVariableKeys = useSelector(state =>
    templateEditorSelectors.mruVariableKeys(state)
  );

  useEffect(() => {
    const params = paramData.map(cp => ({
      ...cp,
      scopes: cp.scopes.length === 2 ? ['all'] : cp.scopes,
    }));
    setCompanyParams(params);
  }, [paramData]);

  const getCompanyParams = () =>
    dispatch(companyParamsActions.getCompanyParams({ scopes: 'template' }));

  useEffect(() => {
    getCompanyParams();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClick = key => {
    insertKey(key);
    closeMenu();
  };

  return (
    <Box data-testid="mru-edit-token">
      <Box
        sx={{
          fontWeight: 700,
          fontSize: '14px',
          paddingBottom: '1rem',
        }}
      >
        Edit Personalization Token
      </Box>
      <Box>
        {mruVariableKeys && mruVariableKeys.length > 0 && (
          <Box>
            <Box sx={{ fontSize: '14px', fontWeight: 500 }}>
              Recently Used Tokens
            </Box>
            <Box>
              {mruVariableKeys.slice(0, 5).map((option, index) => (
                <Box
                  data-testid={`mru-edit-token-${index}`}
                  key={option.value}
                  onClick={() => {
                    handleClick(option.value);
                  }}
                  sx={{
                    height: '2.125rem',
                    fontSize: '14px',
                    padding: '0 1rem',
                    cursor: 'pointer',
                    display: 'flex',
                    alignItems: 'center',
                    ':hover': {
                      background: '#f5f5f5',
                    },
                  }}
                >
                  {option.name}
                </Box>
              ))}
            </Box>
          </Box>
        )}

        {companyParams && !!companyParams.length && (
          <Box>
            <Box sx={{ fontSize: '14px', fontWeight: 500 }}>Configured</Box>
            <Box>
              {companyParams.map(option => (
                <Box
                  key={option.id}
                  onClick={() => handleClick(option.key)}
                  sx={{
                    height: '2.125rem',
                    fontSize: '14px',
                    padding: '0 1rem',
                    cursor: 'pointer',
                    display: 'flex',
                    alignItems: 'center',
                    ':hover': {
                      background: '#f5f5f5',
                    },
                  }}
                >
                  {option.label}
                </Box>
              ))}
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  );
}
export default MRUMenu;
