import { fabric } from 'fabric';

/**
 * Dummy textbox
 * Will prevent from loading default textbox menu
 */
export class DummyTextboxObject extends fabric.Textbox {
  static type = 'DummyTextbox';

  initialize(options) {
    const { text, ...textOptions } = options;
    super.initialize(text, textOptions);
    this.on('mouseup', e => {
      if (e.button === 3) {
        const canvasEl = document.getElementById('canvas');
        const canvasPosition = {
          left: canvasEl?.getBoundingClientRect().left,
          top: canvasEl?.getBoundingClientRect().top,
        };
        const eventData = {
          object: this,
          visible: true,
          position: {
            left: canvasPosition.left + e.pointer.x + 8,
            top: canvasPosition.top + e.pointer.y - 24,
          },
        };
        this.canvas.fire('object:rightclicked', eventData);
        this.canvas.setActiveObject(this);
      }
    });
    return this;
  }

  static fromObject(options, callback) {
    return callback && callback(new fabric.DummyTextbox(options));
  }
}

fabric.DummyTextbox = fabric.util.createClass(DummyTextboxObject, {
  type: DummyTextboxObject.type,
});
fabric.DummyTextbox.fromObject = DummyTextboxObject.fromObject;
