import React from 'react';
import { withRouter } from 'react-router-dom';
import './Dashboard.scss';
import TopDashboard from './TopDashboard';
import GraphDashboard from './Graph/Graph';

const Dashboard = props => {
  return (
    <>
      <TopDashboard />
      <GraphDashboard />
    </>
  );
};

export default withRouter(Dashboard);
