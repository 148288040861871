import { fabric } from 'fabric';
import throttle from 'lodash/throttle';
import BaseHandler from './BaseHandler';

class TransactionHandler extends BaseHandler {
  redos = [];
  undos = [];
  state = [];
  active = false;

  // constructor(props) {
  //   super(props);
  // }

  save = type => {
    try {
      if (this.state) {
        const json = this.state;
        this.undos.push({
          type,
          json,
        });
        const canvasJSON = this.canvas.toJSON(
          this.handlers.propertiesToInclude
        );

        canvasJSON.objects.forEach(object => {
          if (object.clipPath) {
            fabric.util.enlivenObjects([object.clipPath], function (arg1) {
              object.clipPath = arg1[0];
            });
          }
        });

        this.state = canvasJSON;
      }
    } catch (err) {
      console.log(err);
    }
    this.editor.emit('history:changed', {
      hasUndo: this.undos.length > 1,
      hasRedo: this.redos.length > 0,
    });
  };

  clear = () => {
    this.redos = [];
    this.undos = [];
  };

  undo = throttle(() => {
    const undo = this.undos.pop();
    if (!undo) {
      return;
    }
    if (!undo.json.objects?.length) return;
    this.redos.push({
      type: 'redo',
      json: this.state,
    });
    this.replay(undo);
  }, 100);

  redo = throttle(() => {
    const redo = this.redos.pop();
    if (!redo) {
      return;
    }
    this.undos.push({
      type: 'undo',
      json: this.state,
    });
    this.replay(redo);
  }, 100);

  replay = async transaction => {
    const objects = transaction.json;
    this.state = objects;
    this.active = true;
    this.handlers.objectsHandler.clear(false, false);
    this.canvas.discardActiveObject();
    this.canvas.loadFromJSON(objects, () => {
      this.canvas.renderAll.bind(this.canvas);
      this.editor.emit('history:changed', {
        hasUndo: this.undos.length > 1,
        hasRedo: this.redos.length > 0,
      });
      this.handlers.frameHandler.setButtonsPosition();
    });
    this.active = false;
  };

  getAll = () => {
    return {
      undos: this.undos,
      redos: this.redos,
      state: this.state,
    };
  };
}

export default TransactionHandler;
