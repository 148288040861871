import classNames from 'classnames';

export default props => {
  return (
    <>
      {props.toolbar}
      <div
        className={classNames(props.className, 'EntityTable-table-wrapper', {
          // eslint-disable-next-line no-useless-computed-key
          ['EntityTable-table-wrapper-tableLayout']: props.tableLayout,
        })}
      >
        {props.progress}
        <table
          className={classNames(
            props.tableClassName,
            'EntityTable-table-table'
          )}
        >
          {props.children}
        </table>
      </div>
      <div className="EntityTable-table-footer">{props.footer}</div>
    </>
  );
};
