import React, { useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Popover,
  PopoverTrigger,
  PopoverContent,
  Button,
  Box,
  Divider,
  extendTheme,
  ThemeProvider,
  Tooltip,
  IconButton,
  Input,
  useDisclosure,
} from '@chakra-ui/react';
import AddIcon from 'components/Icons/Add';
import EditIcon from 'components/Icons/Edit';
import { useHistory, useLocation } from 'react-router-dom';
import { getDuplicatePayload } from 'helpers/canvasHelpers';
import CustomScroller from 'components/CustomScroller';
import Icons from '../../Icons';
import DeleteModal from './DeleteModal';
import useTemplateEditorContext from '../../../hooks/useTemplateEditorContext';

import {
  templateEditorActions,
  templateEditorSelectors,
} from 'modules/templateEditor/templateEditorDuck';
import {
  creativesSelectors,
  creativesActions,
} from 'modules/creatives/creativesDuck';
import * as appActions from 'modules/app/appActions';

function useQuery() {
  const { search } = useLocation();
  return useMemo(() => new URLSearchParams(search), [search]);
}

const File = props => {
  const { resetEditor } = props;

  const { onOpen, onClose, isOpen } = useDisclosure();
  const {
    onOpen: onOpenDeleteModal,
    onClose: onCloseDeleteModal,
    isOpen: isOpenDeleteModal,
  } = useDisclosure();
  const { currentTemplate } = useTemplateEditorContext();
  const query = useQuery();
  const [options, setOptions] = useState({
    renaming: false,
    name: '',
    toBeDeleted: null,
    deleteInProgress: false,
  });

  const history = useHistory();

  const dispatch = useDispatch();

  // Selectors
  const templates = useSelector(state => creativesSelectors.creatives(state));
  const template = useSelector(state =>
    templateEditorSelectors.template(state)
  );

  // Dispatches
  const getTemplates = (payload, successCallback, failureCallback) =>
    dispatch(
      creativesActions.getTemplates(payload, successCallback, failureCallback)
    );
  const createTemplate = (payload, successCallback, failureCallback) =>
    dispatch(
      templateEditorActions.createTemplate(
        payload,
        successCallback,
        failureCallback
      )
    );
  const updateTemplateById = (
    templateID,
    payload,
    successCallback,
    failureCallback
  ) =>
    dispatch(
      templateEditorActions.updateTemplateById(
        templateID,
        payload,
        successCallback,
        failureCallback
      )
    );
  const deleteTemplateById = (id, successCallback, failureCallback) =>
    dispatch(
      creativesActions.deleteTemplateById(id, successCallback, failureCallback)
    );
  const setMessage = payload => dispatch(appActions.setMessage(payload));

  useEffect(() => {
    if (currentTemplate) {
      setOptions({
        ...options,
        name: currentTemplate.name,
        template: currentTemplate,
      });
    } else {
      setOptions({ ...options, name: '', template: null });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTemplate]);

  useEffect(() => {
    if (!query.get('id')) {
      setOptions({ ...options, name: '', template: null });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  const duplicateTemplateHandler = async template => {
    const payload = await getDuplicatePayload(template);
    let duplicatedTemplate = await new Promise((resolve, reject) => {
      createTemplate(
        payload,
        data => resolve(data),
        err => reject(err)
      );
    });

    const id = duplicatedTemplate.id;
    id && history.push(`/template-editor?id=${id}`);

    getTemplates({}, () => {});
    setMessage({
      message: 'Duplicated template',
      type: 'success',
    });
  };

  const updateTemplateHandler = async () => {
    const payload = {
      ad_template_vars: template.ad_template_vars || [],
      name: options.name,
    };

    await new Promise((resolve, reject) => {
      updateTemplateById(
        template.id,
        payload,
        data => resolve(data),
        err => reject(err)
      );
    });
    setOptions({ ...options, renaming: false });
    setMessage({
      message: 'Updated template name',
      type: 'success',
    });
  };

  const deleteTemplateHandler = async id => {
    try {
      setOptions({ ...options, deleteInProgress: true });
      await new Promise((resolve, reject) => {
        deleteTemplateById(
          id,
          () => resolve(true),
          err => reject(err)
        );
      });
      setOptions({ ...options, deleteInProgress: false, toBeDeleted: null });
      onCloseDeleteModal();
      setMessage({
        message: 'Deleted template',
        type: 'success',
      });
      if (id === template.id) {
        history.push('/templates');
      }
    } catch (err) {
      console.log(err);
    }
  };

  const deleteTemplateActivate = template => {
    setOptions({ ...options, toBeDeleted: template });
    onOpenDeleteModal();
  };

  return (
    <ThemeProvider
      theme={extendTheme({
        components: {
          Popover: {
            variants: {
              scrollable: {
                popper: {
                  height: '100%',
                  maxHeight: '300px',
                },
              },
            },
          },
        },
      })}
    >
      <>
        <DeleteModal
          isOpen={isOpenDeleteModal}
          onOpen={onOpenDeleteModal}
          onClose={onCloseDeleteModal}
          toBeDeleted={options.toBeDeleted}
          deleteTemplateHandler={deleteTemplateHandler}
          deleteInProgress={options.deleteInProgress}
        />
        {options.renaming ? (
          <>
            <Input
              focusBorderColor="rgba(255,255,255,0.5)"
              border="none"
              width="240px"
              backgroundColor="rgba(255,255,255,0)"
              variant="ghost"
              color="#ffffff"
              value={options.name}
              onChange={e => setOptions({ ...options, name: e.target.value })}
            />
            <Tooltip label="Apply">
              <IconButton
                onClick={updateTemplateHandler}
                color="#ffffff"
                variant="ghost"
                icon={<Icons.Check size={20} />}
                sx={{
                  color: '#ffffff',
                  ':hover': {
                    backgroundColor: 'rgba(255,255,255,0.15)',
                    cursor: 'pointer',
                  },
                }}
              />
            </Tooltip>
            <Tooltip label="Cancel">
              <IconButton
                onClick={() => setOptions({ ...options, renaming: false })}
                color="#ffffff"
                variant="ghost"
                icon={<Icons.Cross size={20} />}
                sx={{
                  color: '#ffffff',
                  ':hover': {
                    backgroundColor: 'rgba(255,255,255,0.15)',
                    cursor: 'pointer',
                  },
                }}
              />
            </Tooltip>
          </>
        ) : (
          <>
            <Popover
              isOpen={isOpen}
              onOpen={onOpen}
              onClose={onClose}
              variant="scrollable"
              placement="bottom-end"
            >
              <PopoverTrigger>
                <Button
                  variant="ghost"
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    fontWeight: 500,
                    height: '42px',
                    paddingRight: '1rem',
                    fontSize: '14px',
                    color: '#ffffff',
                    ':hover': {
                      background: 'rgba(255,255,255,0.15)',
                      cursor: 'pointer',
                    },
                  }}
                >
                  <p
                    style={{
                      textOverflow: 'ellipsis',
                      width: '130px',
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                    }}
                  >
                    {options.name ? options.name : 'New template'}
                  </p>
                </Button>
              </PopoverTrigger>

              <PopoverContent sx={{ position: 'relative', height: '100%' }}>
                <Box
                  sx={{
                    position: 'absolute',
                    height: '100%',
                    width: '100%',
                    padding: '1rem 0',
                  }}
                >
                  <CustomScroller>
                    <Box
                      onClick={() => {
                        resetEditor();
                        history.push('/template-editor');
                        onClose();
                      }}
                      sx={{
                        display: 'grid',
                        gridTemplateColumns: '24px 1fr',
                        gap: '0.75rem',
                        height: '44px',
                        alignItems: 'center',
                        padding: '0 1rem',
                        ':hover': {
                          background: 'rgba(0,0,0,0.05)',
                          cursor: 'pointer',
                        },
                      }}
                    >
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}
                      >
                        <AddIcon size={20} />
                      </Box>
                      <Box>New template</Box>
                    </Box>
                    <Divider />
                    {templates.map(template => {
                      const inUse = template.campaigns_count > 0;
                      return (
                        <Box
                          sx={{
                            display: 'grid',
                            gridTemplateColumns: '24px 1fr 24px',
                            gap: '0.75rem',
                            // height: "40px",
                            alignItems: 'center',
                            padding: '0.125rem 1rem',
                            ':hover': {
                              background: 'rgba(0,0,0,0.05)',
                              cursor: 'pointer',
                            },
                          }}
                          key={template.id}
                        >
                          <Box
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                            }}
                            onClick={() => duplicateTemplateHandler(template)}
                          >
                            <Icons.Duplicate size={20} />
                          </Box>
                          <Box
                            onClick={() => {
                              history.push(
                                `/template-editor?id=${template.id}`
                              );
                              onClose();
                            }}
                          >
                            {template.name}
                          </Box>
                          {inUse ? (
                            <Tooltip label="Detach template from all campaigns to delete.">
                              <IconButton
                                cursor="not-allowed"
                                color="#b1b1b1"
                                variant="ghost"
                                icon={<Icons.Delete size={20} />}
                              />
                            </Tooltip>
                          ) : (
                            <Tooltip label="Delete">
                              <IconButton
                                disabled={inUse}
                                onClick={() => {
                                  deleteTemplateActivate(template);
                                }}
                                variant="ghost"
                                icon={<Icons.Delete size={20} />}
                              />
                            </Tooltip>
                          )}
                        </Box>
                      );
                    })}
                  </CustomScroller>
                </Box>
              </PopoverContent>
            </Popover>
            {options.template && (
              <Tooltip label="Rename">
                <IconButton
                  onClick={() => setOptions({ ...options, renaming: true })}
                  color="#ffffff"
                  variant="ghost"
                  icon={<EditIcon size={16} />}
                  sx={{
                    color: '#ffffff',
                    ':hover': {
                      backgroundColor: 'rgba(255,255,255,0.15)',
                      cursor: 'pointer',
                    },
                  }}
                />
              </Tooltip>
            )}
          </>
        )}
      </>
    </ThemeProvider>
  );
};

export default File;
