import React, { useCallback, useEffect, useState } from 'react';
import Icons from '../../../Icons';
import {
  Box,
  IconButton,
  Popover,
  PopoverTrigger,
  PopoverContent,
  Tooltip,
  Input,
  Text,
} from '@chakra-ui/react';
import { HexColorPicker } from 'react-colorful';
import { useEditor, useActiveObject } from 'components/Canvas';
import { colorPickerList } from 'components/Canvas/common/constants';
import _ from 'lodash';

function TextColor() {
  const [options, setOptions] = useState({ fill: '#000000' });
  const activeObject = useActiveObject();
  const editor = useEditor();
  const [colorPickerIndex, setColorPickerIndex] = useState({
    recommend: '-1',
    document: '-1',
    solid: '-1',
    gradient: '-1',
  });

  useEffect(() => {
    if (activeObject) {
      updateOptions(activeObject);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeObject]);

  const updateOptions = object => {
    setOptions({ ...options, fill: object.fill });
  };

  const setTextColor = useCallback(
    color => {
      editor.update({ fill: color });
      setOptions({ ...options, fill: color });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [editor]
  );

  return (
    <Popover placement="bottom-start">
      <Tooltip placement="bottom" label="Format color">
        <Box display="inline-block">
          <PopoverTrigger>
            <IconButton
              color={options.fill}
              borderRadius="0"
              variant="ghost"
              size="sm"
              icon={<Icons.FormatColorText size={24} />}
            />
          </PopoverTrigger>
        </Box>
      </Tooltip>
      <PopoverContent
        width={'347px'}
        maxHeight={'336px'}
        overflowY={'auto'}
        overflowX={'hidden'}
        padding={'0.5em 1.5em'}
      >
        <Box display={'flex'} alignItems={'center'}>
          <IconButton
            minWidth="34px"
            height="34px"
            variant="outline"
            borderRadius="4px"
            border="none"
            display="flex"
            alignItems={'center'}
            justifyContent={'flex-start'}
            size="sm"
            color={'#0d1216'}
            icon={<Icons.DocumentColor size={24} />}
          />
          <Text as="span" fontSize="14px" color={'#0d1216'} fontWeight={600}>
            Document colors
          </Text>
        </Box>
        <Box display={'flex'} flexWrap={'wrap'}>
          <Popover placement="right-end">
            <PopoverTrigger>
              <Box
                className="colorPicker-button"
                minWidth="34px"
                height="34px"
                borderRadius="4px"
                marginTop="16px"
                marginBottom="16px"
                marginRight="8px"
                border="none"
              >
                <IconButton
                  position={'absolute'}
                  minWidth="34px"
                  height="34px"
                  variant="outline"
                  borderRadius="4px"
                  border="none"
                  display="flex"
                  alignItems={'center'}
                  justifyContent={'center'}
                  size="sm"
                  color={'#0d1216'}
                  _hover={{
                    boxShadow:
                      '0 0 0 1px #8b3dff,inset 0 0 0 2px #8b3dff,inset 0 0 0 4px #fff',
                    transition: 'box-shadow .2s ease-in-out',
                  }}
                  icon={<Icons.PlusTE size={16} />}
                />
              </Box>
            </PopoverTrigger>
            <PopoverContent
              sx={{
                display: 'flex',
                width: 'auto',
                padding: '1rem',
                top: '50px',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '0.25rem',
                }}
              >
                <HexColorPicker
                  name="colorPicker"
                  color={options.fill}
                  onChange={color => setTextColor(color)}
                />
                <Box sx={{ width: '200px' }}>
                  <Input
                    textAlign="center"
                    name="colorSelector"
                    color={'#0d1216'}
                    value={options.fill}
                    onChange={e => {
                      setOptions({
                        ...options,
                        fill: e.target.value,
                      });
                      setTextColor(e.target.value);
                    }}
                  />
                </Box>
              </Box>
            </PopoverContent>
          </Popover>

          {editor.handlers.objectsHandler
            .getDocumentColors()
            .map((color, index) => {
              return (
                <Box
                  key={_.uniqueId('colorPickerDefault')}
                  sx={{
                    borderRadius: '4px',
                    overflow: 'hidden',
                    minWidth: '34px',
                    height: '34px',
                    background: color,
                    border: '1px solid #838383',
                    marginTop: '16px',
                    marginBottom: '16px',
                    marginRight: '8px',
                    cursor: 'pointer',
                    transition: 'box-shadow .2s ease-in-out',
                    boxShadow:
                      colorPickerIndex.document === index
                        ? '0 0 0 1px #8b3dff,inset 0 0 0 2px #8b3dff,inset 0 0 0 4px #fff'
                        : 'none',
                    ':hover': {
                      boxShadow:
                        '0 0 0 1px #8b3dff,inset 0 0 0 2px #8b3dff,inset 0 0 0 4px #fff',
                      transition: 'box-shadow .2s ease-in-out',
                    },
                  }}
                  onClick={() => {
                    setTextColor(color);
                    // setOptions({ ...options, backgroundColor: color });
                    setColorPickerIndex({
                      ...colorPickerIndex,
                      document: index,
                    });
                  }}
                />
              );
            })}
        </Box>
        <Box display={'flex'} alignItems={'center'}>
          <IconButton
            minWidth="34px"
            height="34px"
            variant="outline"
            borderRadius="4px"
            border="none"
            display="flex"
            alignItems={'center'}
            justifyContent={'flex-start'}
            size="sm"
            color={'#0d1216'}
            icon={<Icons.DefaultColor size={24} />}
          />
          <Text as="span" fontSize="14px" color={'#0d1216'} fontWeight={600}>
            Default colors
          </Text>
        </Box>
        <Box
          fontSize={'10px'}
          marginTop={'8px'}
          color={'rgba(13,18,22,.7)'}
          fontWeight={400}
        >
          Solid Colors
        </Box>
        <Box display={'flex'} width={'290px'} flexWrap={'wrap'}>
          {colorPickerList.solid.map((colorContent, index) => {
            let color = '#' + colorContent.split('#')[1];
            return (
              <Box
                key={_.uniqueId('colorPickerSolid')}
                sx={{
                  borderRadius: '4px',
                  overflow: 'hidden',
                  minWidth: '40px',
                  height: '40px',
                  background: color,
                  marginTop: '8px',
                  marginBottom: '8px',
                  marginRight: '8px',
                  cursor: 'pointer',
                  border: '1px solid #838383',
                  transition: 'box-shadow .2s ease-in-out',
                  boxShadow:
                    colorPickerIndex.solid === index
                      ? '0 0 0 1px #8b3dff,inset 0 0 0 2px #8b3dff,inset 0 0 0 4px #fff'
                      : 'none',
                  ':hover': {
                    boxShadow:
                      '0 0 0 1px #8b3dff,inset 0 0 0 2px #8b3dff,inset 0 0 0 4px #fff',
                    transition: 'box-shadow .2s ease-in-out',
                  },
                }}
                onClick={() => {
                  setTextColor(color);
                  // setOptions({ ...options, backgroundColor: color });
                  setColorPickerIndex({
                    ...colorPickerIndex,
                    solid: index,
                  });
                }}
              />
            );
          })}
        </Box>
      </PopoverContent>
      {/* 
      <PopoverContent
        sx={{ display: 'flex', width: 'auto', padding: '1.5rem' }}
      >
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '0.75rem' }}>
          <HexColorPicker
            name="colorPicker"
            color={options.fill}
            onChange={color => setTextColor(color)}
          />
          <Box sx={{ width: '200px' }}>
            <Input
              textAlign="center"
              name="colorSelector"
              value={options.fill ? options.fill : '#000000'}
              onChange={e => setOptions({ ...options, fill: e.target.value })}
            />
            <Button
              name="applyColor"
              onClick={() => setTextColor(options.fill)}
              mt="0.75rem"
              w="100%"
            >
              Apply
            </Button>
          </Box>
        </Box>
      </PopoverContent> */}
    </Popover>
  );
}

export default TextColor;
