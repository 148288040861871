import { fromJS } from 'immutable';
import { schema } from 'normalizr';
import { start, success, failure, error } from 'helpers/reducerHelpers';
// import {
//   searchParams,
//   pageParams,
//   paramsToString,
//   dashboardParams,
// } from 'helpers/paramHelpers';
// import { actionCreator } from 'helpers/actionHelpers';
// import { createSelector } from 'reselect';
import { createApiErrorSelector } from '../api/apiDuck';
import * as api from './dashboardApi';

// Constants
const GET_STATS = 'modules/dashboard/GET_STATS';
const GET_PRINT_STATS = 'modules/dashboard/GET_PRINT_STATS';
const GET_PRINTED_ORDER_STATS = 'modules/dashboard/GET_PRINTED_ORDER_STATS';
const SAVE_DASH_PARAMS_TO_STATE = 'modules/dashboard/SAVE_DASH_PARAMS_TO_STATE';
const SET_KPI_CARD_VISIBILITY = 'modules/dashbarod/SET_KPI_CARD_VISIBILITY';
const STATE_KEY_STATS = 'stats';
const STATE_KEY_PRINT_STATS = 'print_stats';
const STATE_KEY_PRINTED_ORDER_STATS = 'printed_order_stats';
const STATE_KEY_DASH_PARAMS_FROM_STATE = 'dashParamsFromState';
const STATE_KEY_KPI_CARD_VISIBILITY = 'kpiCardVisibility';
const GET_QR_CODES_STATS = 'modules/dashboard/GET_QR_CODES_STATS';
const STATE_KEY_QR_CODES_STATS = 'qr_codes_stats';

export const dashboardConstants = {
  GET_STATS,
  GET_PRINT_STATS,
  GET_PRINTED_ORDER_STATS,
  SAVE_DASH_PARAMS_TO_STATE,
  SET_KPI_CARD_VISIBILITY,
  STATE_KEY_STATS,
  STATE_KEY_PRINT_STATS,
  STATE_KEY_PRINTED_ORDER_STATS,
  STATE_KEY_DASH_PARAMS_FROM_STATE,
  STATE_KEY_KPI_CARD_VISIBILITY,
  GET_QR_CODES_STATS,
  STATE_KEY_QR_CODES_STATS,
};

// Schema
const SCHEMA_DASHBOARD = 'dashboard';
export const dashboardSchema = new schema.Entity(SCHEMA_DASHBOARD);

// Reducer
export const reducer = (
  ogState = {
    [STATE_KEY_KPI_CARD_VISIBILITY]: true,
    [STATE_KEY_STATS]: {
      data: {},
      id: null,
      qr_ix: [],
      total_amt: '0',
      total_conv: 0,
      total_conv_pct: '0',
      total_imp: 0,
      total_scans: 0,
    },
    [`${STATE_KEY_STATS}.loading`]: true,
    [STATE_KEY_QR_CODES_STATS]: {},
    [`${STATE_KEY_QR_CODES_STATS}.loading`]: true,
    [STATE_KEY_PRINT_STATS]: { data: [], index: [] },
    [`${STATE_KEY_PRINT_STATS}.loading`]: true,
    [STATE_KEY_PRINTED_ORDER_STATS]: { data: [], index: [] },
    [`${STATE_KEY_PRINTED_ORDER_STATS}.loading`]: true,
  },
  action
) => {
  const state = fromJS(ogState);

  switch (action.type) {
    case start(GET_STATS):
      return state.merge({ [`${STATE_KEY_STATS}.loading`]: true }).toJS();
    case start(GET_PRINT_STATS):
      return state.merge({ [`${STATE_KEY_PRINT_STATS}.loading`]: true }).toJS();
    case start(GET_PRINTED_ORDER_STATS):
      return state
        .merge({ [`${STATE_KEY_PRINTED_ORDER_STATS}.loading`]: true })
        .toJS();
    case success(GET_STATS):
      return state
        .merge({
          [STATE_KEY_STATS]: action.payload.data,
          [`${STATE_KEY_STATS}.loading`]: false,
        })
        .toJS();
    case success(GET_PRINT_STATS):
      return state
        .merge({
          [STATE_KEY_PRINT_STATS]: action.payload,
          [`${STATE_KEY_PRINT_STATS}.loading`]: false,
        })
        .toJS();
    case success(GET_PRINTED_ORDER_STATS):
      return state
        .merge({
          [STATE_KEY_PRINTED_ORDER_STATS]: action.payload,
          [`${STATE_KEY_PRINTED_ORDER_STATS}.loading`]: false,
        })
        .toJS();

    case success(GET_QR_CODES_STATS):
      return state
        .merge({
          [STATE_KEY_QR_CODES_STATS]: action.payload,
          [`${STATE_KEY_QR_CODES_STATS}.loading`]: false,
        })
        .toJS();

    case failure(GET_STATS):
    case failure(GET_PRINT_STATS):
    case failure(GET_PRINTED_ORDER_STATS):
    case failure(GET_QR_CODES_STATS):
    case SAVE_DASH_PARAMS_TO_STATE:
      return state.set(STATE_KEY_DASH_PARAMS_FROM_STATE, action.payload).toJS();
    case SET_KPI_CARD_VISIBILITY:
      return state.set(STATE_KEY_KPI_CARD_VISIBILITY, action.payload).toJS();

    // GENERIC API ERROR HANDLER, will match any error for any type
    case error(action.type):
      //console.log('DASHBOARD_REDUCER : ', 'API_ERROR', action.payload)
      return ogState;

    default:
      return ogState;
  }
};

// Actions
export const dashboardActions = {
  getStats: params => api.getStats(params, GET_STATS),
  getPrintStates: params => api.getPrintStates(params, GET_PRINT_STATS),
  getPrintedOrderStates: params =>
    api.getPrintedOrderStates(params, GET_PRINTED_ORDER_STATS),
  getQrCodesStats: params => api.getQrCodesStats(params, GET_QR_CODES_STATS),
  saveDashParamsToState: payload => ({
    type: SAVE_DASH_PARAMS_TO_STATE,
    payload,
  }),
  setKpiCardVisibility: payload => ({ type: SET_KPI_CARD_VISIBILITY, payload }),
};

// Selectors
export const dashboardSelectors = {
  stats: state => state.modules.dashboard[STATE_KEY_STATS],
  printStats: state => state.modules.dashboard[STATE_KEY_PRINT_STATS],
  printedOrderStats: state =>
    state.modules.dashboard[STATE_KEY_PRINTED_ORDER_STATS],
  isStatsLoading: state =>
    state.modules.dashboard[`${STATE_KEY_STATS}.loading`],
  isPrintStatsLoading: state =>
    state.modules.dashboard[`${STATE_KEY_PRINT_STATS}.loading`],
  isPrintedOrderStatsLoading: state =>
    state.modules.dashboard[`${STATE_KEY_PRINTED_ORDER_STATS}.loading`],
  qrCodesStats: state => state.modules.dashboard[STATE_KEY_QR_CODES_STATS],
  isQrCodesStatsLoading: state =>
    state.modules.dashboard[`${STATE_KEY_QR_CODES_STATS}.loading`],
  dashParamsFromState: state =>
    state.modules.dashboard[STATE_KEY_DASH_PARAMS_FROM_STATE],
  kpiCardVisibility: state =>
    state.modules.dashboard[STATE_KEY_KPI_CARD_VISIBILITY],
  getApiError: createApiErrorSelector([GET_STATS]),
};

export default reducer;
