import { createAction } from '@reduxjs/toolkit';
import { createApiAction } from 'helpers/actionHelpers';
import * as api from './companyParamsApi';

export const getCompanyParams = createApiAction(
  'modules/company/GET_COMPANY_PARAMS',
  (type, params, successCallback, failureCallback) =>
    api.getCompanyParams(params, successCallback, failureCallback, type)
);

export const getCompanyParam = createApiAction(
  'modules/campaigns/GET_COMPANY_PARAM',
  (type, id) => api.getCompanyParam(id, type)
);

export const createConfigParam = createApiAction(
  'modules/company/CREATE_COMPANY_PARAMS',
  (type, companyParams, doneCallback, failureCallback) =>
    api.createCompanyParam(companyParams, type, doneCallback, failureCallback)
);

export const updateCompanyParam = createApiAction(
  'modules/company/UPDATE_COMPANY_PARAMS',
  (type, id, companyParam, successCallback, failureCallback) =>
    api.updateCompanyParam(id, companyParam, type, successCallback, failureCallback)
);

export const clearCompanyParam = createAction(
  'modules/campaigns/CLEAR_COMPANY_PARAM'
);