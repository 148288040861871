import { useState } from 'react';
import { Switch, Route } from 'react-router-dom';

import UsersIndex from './UsersIndex';
import InvitationsIndex from './InvitationsIndex';

import TabsNavigation from 'components/TabsNavigation/TabsNavigation';
import Button from 'components/Button/Button';
import Filter from 'components/Filter/Filter';

import colors from 'theme/Colors.module.scss';
import './UsersIndexContainer.scss';

export default ({ match }) => {
  const usersIndexPath = `/users`;
  const invitationsIndexPath = `/users/invited`;

  const [searchParameters, setSearchParameters] = useState({
    search: '',
  });
  const [searchPlaceholder, setSearchPlaceholder] = useState('');
  const [searchLegend, setSearchLegend] = useState(null);

  const indexProps = {
    searchParameters,
    setSearchParameters,
    setSearchPlaceholder,
    setSearchLegend,
  };

  return (
    <>
      <div className="usersHeader">
        <div className="left">
          <Button
            type="button"
            icon="add"
            text="Add New User"
            textColor={colors.slate}
            btnStyle="transparent"
            url="/users/new"
          />
          <Button
            type="button"
            icon="paper-plane"
            text="Invite User"
            textColor={colors.slate}
            btnStyle="transparent"
            url="/users/invite"
          />
        </div>

        <div className="nav">
          <TabsNavigation
            tabs={[
              {
                key: 'users',
                label: 'Users',
                exact: true,
                to: usersIndexPath,
              },
              {
                key: 'invitations',
                label: 'Invited',
                to: invitationsIndexPath,
              },
            ]}
          />
        </div>

        <div className="right">{searchLegend}</div>

        <div className="search">
          <Filter
            value={searchParameters.search}
            placeholder={searchPlaceholder}
            onChange={search =>
              setSearchParameters({
                ...searchParameters,
                search,
              })
            }
          />
        </div>
      </div>
      <Switch>
        <Route
          path={usersIndexPath}
          exact
          render={routeProps => {
            return <UsersIndex {...indexProps} {...routeProps} />;
          }}
        />
        <Route
          path={invitationsIndexPath}
          render={routeProps => {
            return <InvitationsIndex {...indexProps} {...routeProps} />;
          }}
        />
      </Switch>
    </>
  );
};
