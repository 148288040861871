import { fromJS } from 'immutable';
import { success, failure, error, start } from 'helpers/reducerHelpers';
import * as actions from './companyParamsActions';
import * as companyParamasSchema from './companyParamsSchema';

const SCHEMA_KEY = companyParamasSchema.default.key;

const STATE_KEY_COMPANY_PARAMS = 'company_params';
const STATE_KEY_COMPANY_PARAMS_LOADING = 'companyParamsLoading';
const STATE_KEY_COMPANY_PARAM = 'company_param';
const STATE_KEY_COMPANY_PARAM_LOADING = 'companyParamLoading';

const initialState = {
  [STATE_KEY_COMPANY_PARAMS]: [],
  [STATE_KEY_COMPANY_PARAMS_LOADING]: false,
  [STATE_KEY_COMPANY_PARAM]: null,
  [STATE_KEY_COMPANY_PARAM_LOADING]: false,
};

export default (ogState = initialState, action) => {
  const state = fromJS(ogState);

  switch (action.type) {
    case actions.clearCompanyParam.type:
      return state.set(STATE_KEY_COMPANY_PARAM, null).toJS();

    case start(actions.getCompanyParams.type):
      return state
        .merge({
          [STATE_KEY_COMPANY_PARAMS_LOADING]: true,
        })
        .toJS();

    case success(actions.getCompanyParams.type):
      const { data } = action.payload;
      const companyParams = data.result.map(
        id => data.entities[SCHEMA_KEY][id]
      );

      return state
        .merge({
          [STATE_KEY_COMPANY_PARAMS_LOADING]: false,
          [STATE_KEY_COMPANY_PARAMS]: companyParams,
        })
        .toJS();

    case start(actions.getCompanyParam.type):
      return state
        .merge({
          [STATE_KEY_COMPANY_PARAM_LOADING]: true,
          [STATE_KEY_COMPANY_PARAM]: null,
        })
        .toJS();
    case success(actions.getCompanyParam.type):
      return state
        .merge({
          [STATE_KEY_COMPANY_PARAM_LOADING]: false,
          [STATE_KEY_COMPANY_PARAM]: action.payload,
        })
        .toJS();

    case failure(actions.getCompanyParams.type):
    case failure(actions.getCompanyParam.type):
    case failure(actions.createConfigParam.type):
    case failure(actions.updateCompanyParam.type):
    case error(action.type):
      return ogState;

    default:
      return ogState;
  }
};
