import { createAction } from '@reduxjs/toolkit';
import { createApiAction } from '../../helpers/actionHelpers';
import {
  audienceParams,
  pageParams,
  // paramsToString,
} from '../../helpers/paramHelpers';
import * as api from './audiencesApi';

export const getAudience = createApiAction(
  'modules/audiences/GET_AUDIENCE',
  (type, id) => api.getOne(id, type)
);
export const getAudienceDetail = createApiAction(
  'modules/audiences/GET_AUDIENCE',
  (type, id, successCallback) => api.getAudienceDetail(id, type, successCallback)
);
export const getAudiences = createApiAction(
  'modules/audiences/GET_AUDIENCES',
  (type, params, successCallback, failureCallback) => {
    return api.getAudiences(
      {
        ...audienceParams(params),
        ...pageParams(params),
      },
      successCallback,
      failureCallback,
      type
    );
  }
);
export const createAudience = createApiAction(
  'modules/audiences/CREATE_AUDIENCE',
  (type, audience, doneCallback) =>
    api.createAudience(audience, type, doneCallback)
);
export const updateAudience = createApiAction(
  'modules/audiences/UPDATE_AUDIENCE',
  (type, audience, doneCallback) =>
    api.updateAudience(audience, type, doneCallback)
);
export const deleteAudience = createApiAction(
  'modules/audiences/DELETE_AUDIENCE',
  (type, id) => api.deleteAudience(id, type)
);
export const setSort = createAction('modules/audiences/SET_SORT');
