import React, { useEffect, useState } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { isEmpty } from 'lodash';
import MenuNav from './menuNav/MenuNav';
// import CompanyHeader from './companyHeader/CompanyHeader';
import PrivateRoute from '../../components/PrivateRoute/PrivateRoute';
import loginContainer from '../login/loginContainer';
import Dashboard from '../dashboard/Dashboard';
import inventoryContainer from '../inventory/inventoryContainer';
import campaignsContainer from '../campaigns/campaignsContainer';
import newCampaign from '../campaigns/NewCampaign/NewCampaign';
import audiences from '../audiences/Audiences';
import newAudience from '../audiences/NewAudience/NewAudience';
import templatesContainer from '../templates/templatesContainer';
import UsersContainer from '../users/UsersContainer';
import MyUserDetail from '../users/MyUserDetail/MyUserDetail';

// import profileSettingsContainer from '../profileSettings/profileSettingsContainer';
// import notificationSettingsContainer from '../notificationSettings/notificationSettingsContainer';
// import billingInfoSettingsContainer from '../billingInfoSettings/billingInfoSettingsContainer';
import notFoundContainer from '../notFound/notFoundContainer';
import resetPasswordContainer from '../resetPassword/resetPasswordContainer';
import NewPassword from '../resetPassword/NewPassword';
import TemplateEditor from 'scenes/templateEditor';
import 'normalize.css';
import './App.scss';
import ApiErrorMessageContainer from '../../components/ApiErrorMessage/ApiErrorMessageContainer';
// import WizardIntro from 'scenes/wizardIntro';
import FontsContainer from '../fonts/FontsContainer';
import Params from 'scenes/Params/Params';
import CreateCompanyParam from 'scenes/Params/CreateCompanyParam';
import AnalyticsDetailContainer from '../analytics/AnalyticsDetail/analyticsDetailContainer';
import Analytics from 'scenes/analytics/Analytics';
import colors from 'theme/Colors.module.scss';
import { Box } from '@chakra-ui/react';
import CompanyHeader from './companyHeader/CompanyHeader';

import 'react-day-picker/dist/style.css';

const inEditorStyle = 'inEditor';
const mobileBreakpoint = 1280;

const App = props => {
  const { checkToken, token, user, logout, appMode, setAppMode } = props;
  // const [requestWizard, setRequestWizard] = React.useState(false);
  const location = useLocation();
  const inTemplateEditor = location.pathname.includes('template-editor');
  // const inCreateConfigParam = location.pathname.match('companyParams/');
  const isResetPassword = location.pathname.includes('reset-password');

  const [isMobile, setIsMobile] = useState(
    window.innerWidth <= mobileBreakpoint
  );
  const [mobileMenu, setMobileMenu] = useState(false);

  const mobileMenuListener = e => {
    const element = e.currentTarget;
    const newWidth = element.innerWidth;
    const mobile = newWidth <= mobileBreakpoint;

    if (mobile !== isMobile) setIsMobile(!isMobile);
  };

  const rightClickListener = e => {
    // e.preventDefault();
    // e.stopPropagation();
  };

  useEffect(() => {
    checkToken();
    // Calculate the viewport height and set it as a CSS variable
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);

    // Listen to the resize event and recalculate the viewport height when necessary
    window.addEventListener('resize', () => {
      let vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // useEffect(() => {
  //   const requestedWizard = localStorage.getItem('requestedWizard');
  //   if (!requestedWizard) {
  //     setRequestWizard(true);
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  useEffect(() => {
    window.addEventListener('resize', e => mobileMenuListener(e));
    return () => {
      window.removeEventListener('resize', e => mobileMenuListener(e));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMobile]);

  useEffect(() => {
    window.addEventListener('contextmenu', e => rightClickListener(e));
    return () => {
      window.removeEventListener('contextmenu', e => rightClickListener(e));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loggedIn = !isEmpty(token);
  const userDetails = {
    id: loggedIn ? user.id : null,
    fName: loggedIn ? user.firstname : null,
    lName: loggedIn ? user.lastname : null,
    email: loggedIn ? user.email : null,
    company_id: loggedIn ? user.company_id : null,
  };

  return (
    <main
      className={`${(inTemplateEditor || isResetPassword) && inEditorStyle}`}
    >
      <section
        id="manu-nav"
        className={`${(inTemplateEditor || isResetPassword) && inEditorStyle} ${
          (mobileMenu || !isMobile) && 'menu-nav__offset'
        }`}
      >
        <MenuNav
          setMobileMenu={setMobileMenu}
          userDetails={userDetails}
          mode={appMode}
          logout={logout}
        />
        <Box
          position={isMobile ? 'absolute' : 'relative'}
          display={isMobile ? 'block' : 'none'}
          right={isMobile ? '1em' : '0'}
          top={isMobile ? '1em' : '0'}
        >
          <i
            className={`fas fa-times hamburger-menu-mobile`}
            onClick={() => setMobileMenu(!mobileMenu)}
          />
        </Box>
      </section>

      {isMobile && !mobileMenu && !inTemplateEditor && !isResetPassword && (
        <i
          className={`fas fa-${mobileMenu ? 'times' : 'bars'} hamburger-menu`}
          style={{
            position: isMobile ? 'absolute' : 'relative',
          }}
          onClick={() => setMobileMenu(!mobileMenu)}
        />
      )}
      {!inTemplateEditor && isMobile && (
        <div
          id="head-content"
          className={`${inTemplateEditor && inEditorStyle} ${
            !inTemplateEditor && mobileMenu
          }`}
        >
          <CompanyHeader
            isMobile={isMobile}
            token={token}
            logout={logout}
            userDetails={userDetails}
            appMode={appMode}
            inTemplateEditor={inTemplateEditor}
            changeMode={newMode => setAppMode(newMode)}
          />
        </div>
      )}

      <div
        id="body-content"
        className={`body-content ${inTemplateEditor && inEditorStyle} ${
          !inTemplateEditor && mobileMenu
        }`}
        style={{
          overflow: inTemplateEditor ? 'unset' : 'auto',
          background: colors.coolGrey1,
        }}
      >
        <Switch>
          {/* {(token && requestWizard) && <WizardIntro setRequestWizard={setRequestWizard}/>} */}
          <Route path="/login" component={loginContainer} />
          <Route path="/reset-password" component={resetPasswordContainer} />
          <Route path="/pw/reset" component={NewPassword} />
          {/* Dashboard */}
          <PrivateRoute
            path="/dashboard"
            isMobile={isMobile}
            component={Dashboard}
          />
          <PrivateRoute
            path="/analytics"
            exact={true}
            isMobile={isMobile}
            component={Analytics}
          />
          <PrivateRoute
            path="/analytics/:id"
            isMobile={isMobile}
            component={AnalyticsDetailContainer}
          />

          {/* Ads */}
          <PrivateRoute
            path={['/campaigns/new', '/campaigns/:id']}
            component={newCampaign}
          />
          <PrivateRoute
            path="/campaigns"
            isMobile={isMobile}
            component={campaignsContainer}
          />
          <PrivateRoute
            path="/template-editor"
            component={TemplateEditor}
            inEditorStyle={inEditorStyle}
          />
          <PrivateRoute
            path={['/audiences/new', '/audiences/:id']}
            component={newAudience}
          />
          <PrivateRoute
            path="/audiences"
            isMobile={isMobile}
            component={audiences}
          />
          <PrivateRoute
            path="/templates"
            isMobile={isMobile}
            component={templatesContainer}
          />
          <PrivateRoute path="/inventory" component={inventoryContainer} />
          {/* Users */}
          <Route path="/users" component={UsersContainer} />
          <PrivateRoute path="/user/me" component={MyUserDetail} />
          <PrivateRoute path="/fonts" component={FontsContainer} />
          <PrivateRoute path="/companyParams" exact component={Params} />
          <PrivateRoute
            path={['/companyParams/create', '/companyParams/:id']}
            exact
            component={CreateCompanyParam}
          />

          {/* <PrivateRoute authed={loggedIn} path="/profile" component={profileSettingsContainer} />
            <PrivateRoute authed={loggedIn} path="/notifications" component={notificationSettingsContainer} />
            <PrivateRoute authed={loggedIn} path="/billing-info" component={billingInfoSettingsContainer} /> */}
          {/* Recirects */}
          <Redirect from="/" exact to={'/dashboard'} />
          {/* 404 -- Unknown Routes */}
          <Route component={notFoundContainer} />
        </Switch>

        <ApiErrorMessageContainer />
      </div>
    </main>
  );
};

export default App;
