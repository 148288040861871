import { useContext } from 'react'
import { EditorContext } from '../'

export function useEditor() {
  const editorContext = useContext(EditorContext);
  const { editor } = editorContext;
  if (editor && editorContext) {
    editor.context = editorContext;
  }
  return editor
}
