import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import * as audiencesActions from 'modules/audiences/audiencesActions';
import * as companyParamsActions from 'modules/params/companyParamsActions';
import * as audienceHelpers from 'helpers/audiencesHelpers';
import { paramTemplateTypes } from 'scenes/wizardIntro/components/NewAudience/ParamTemplates/ParamTemplates';

export const useStateHandler = ({
  createdAudience,
  updatedAudience,
  audience,
  id,
}) => {
  const initParam = paramTemplateTypes[0];

  const [redirect, setRedirect] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [initialForm, setInitialForm] = useState(
    audienceHelpers.setInitialAudienceForm()
  );

  const dispatch = useDispatch();

  const fetchAudience = id => {
    dispatch(audiencesActions.getAudienceDetail(id));
  };

  const getCompanyParams = () =>
    dispatch(companyParamsActions.getCompanyParams({ scopes: ['audience'] }));

  const loadAudience = () => {
    if (audience && isEditing) {
      const { incParamSets, excParamSets } = audienceHelpers.loadAudience({
        audience,
      });

      setInitialForm({
        ...audience,
        name: audience.name,
        incParams:
          incParamSets.length === 0 ? initialForm.incParams : incParamSets,
        excParams:
          excParamSets.length === 0 ? initialForm.excParams : excParamSets,
      });
    }
  };

  useEffect(() => {
    setRedirect(true);
  }, [createdAudience, updatedAudience]);

  useEffect(() => {
    loadAudience();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [audience]);

  useEffect(() => {
    if (redirect) setRedirect(false);

    if (id) {
      setIsEditing(true);
      fetchAudience(id);
    }

    // set a inclusion set as default if both incParams and excParams are empty
    if (
      !id &&
      initialForm.incParams.length === 0 &&
      initialForm.excParams.length === 0
    ) {
      setInitialForm({
        ...initialForm,
        incParams: [
          {
            logical_op_type: true,
            rules: [!id && initParam],
          },
        ],
      });
    }

    getCompanyParams();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { redirect, isEditing, initialForm, setInitialForm };
};
