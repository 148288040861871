import { Route, Switch } from 'react-router';

import PrivateRoute from 'components/PrivateRoute/PrivateRoute';

import UsersIndexContainer from './UsersIndex/UsersIndexContainer';
import UserInvite from './UserInvite/UserInvite';
import UserCreation from './UserCreation/UserCreation';
import UserDetail from './UserDetail/UserDetail';

export default ({ match }) => {
  return (
    <Switch>
      <Route path={`${match.url}/new`} component={UserCreation} />
      <PrivateRoute path={`${match.url}/invite`} component={UserInvite} />
      <PrivateRoute
        path={[`${match.url}`, `${match.url}/invited`]}
        exact
        component={UsersIndexContainer}
      />
      <PrivateRoute path={`${match.url}/:id`} component={UserDetail} />
    </Switch>
  );
};
