import { connect } from 'react-redux';
import * as campaignsActions from 'modules/campaigns/campaignsActions';
import * as audiencesActions from 'modules/audiences/audiencesActions';
import * as appSelectors from 'modules/app/appSelectors';
import * as appActions from 'modules/app/appActions';
import { templateEditorActions } from 'modules/templateEditor/templateEditorDuck';
import { creativesActions } from 'modules/creatives/creativesDuck';
import DesignImporterModal from './components/DesignImporterModal';

function mapStateToProps(state) {
  return {
    sessionUser: appSelectors.getSessionUser(state),
    user: appSelectors.getSessionUser(state),
    // userFonts: templateEditorSelectors.userFonts(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    createCampaign: (campaign, doneCallback, failureCallback) => {
      dispatch(
        campaignsActions.createCampaign(campaign, doneCallback, failureCallback)
      );
    },
    getCampaigns: targetType =>
      dispatch(campaignsActions.getCampaigns(targetType)),
    createAudience: (audience, doneCallback) => {
      dispatch(audiencesActions.createAudience(audience, doneCallback));
    },
    getDesignStatus: (id, doneCallback) => {
      dispatch(appActions.getDesignStatus(id, doneCallback));
    },
    resumeDesignImport: (id, doneCallback) => {
      dispatch(appActions.resumeDesignImport(id, doneCallback));
    },
    sendUploadInformation: (uploadInformation, doneCallback) => {
      dispatch(
        appActions.sendUploadInformation(uploadInformation, doneCallback)
      );
    },
    uploadTemplate: (formData, doneCallback) => {
      dispatch(appActions.uploadTemplate(formData, doneCallback));
    },

    getUserFonts: doneCallback => {
      dispatch(templateEditorActions.getUserFonts(doneCallback));
    },
    getSizeFormats: doneCallback => {
      dispatch(creativesActions.getSizeFormats(doneCallback));
    },
    addUserFont: (payload, doneCallback, failureCallback) => {
      dispatch(
        templateEditorActions.addUserFont(
          payload,
          doneCallback,
          failureCallback
        )
      );
    },

    createTemplate: (payload, doneCallback) => {
      dispatch(templateEditorActions.createTemplate(payload, doneCallback));
    },

    logout: () => dispatch(appActions.logout()),
  };
}

const Container = connect(
  mapStateToProps,
  mapDispatchToProps
)(DesignImporterModal);

export default Container;
