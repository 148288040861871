import { creativesSchema } from './creativesDuck';
import { apiActions } from '../api/apiDuck';

export const createCreative = (formData, type, doneCallback) => {
  return apiActions.apiAction({
    path: `templates`,
    type,
    isUpload: true,
    method: 'POST',
    body: formData,
    onComplete: () => () => doneCallback(),
  });
};

export const getTemplates = (
  params,
  type,
  successCallback,
  failureCallback
) => {
  return apiActions.apiAction({
    path: `templates${params}`,
    type,
    method: 'GET',
    normalize: creativesSchema.schema,
    onSuccess: pageData => {
      if (successCallback) successCallback(pageData);
    },
    onFailure: failureCallback,
  });
};

export const getCreativeById = (id, type) => {
  return apiActions.apiAction({
    path: `templates/${id}`,
    type,
    method: 'GET',
  });
};

export const updateCreative = (id, formData, type, doneCallback) => {
  return apiActions.apiAction({
    path: `templates/${id}`,
    type,
    isUpload: true,
    method: 'PUT',
    body: formData,
    onSuccess: () => () => doneCallback(),
  });
};

export const deleteTemplateById = (
  id,
  type,
  successCallback,
  failureCallback
) => {
  return apiActions.apiAction({
    path: `templates/${id}`,
    type,
    method: 'DELETE',
    meta: { id },
    onSuccess: () => () => successCallback(),
    onFailure: error => () => failureCallback(error),
  });
};

export const getSizeFormats = (type, successCallback, failureCallback) => {
  return apiActions.apiAction({
    // TODO: "path" should hit the enabled_size_formats table isntead of size_formats
    path: `size_formats`,
    method: 'GET',
    type,
    onSuccess: res => () => successCallback(res),
    onFailure: error => () => failureCallback(error),
  });
};
