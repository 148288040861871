import React from 'react';
import { Box, Text } from '@chakra-ui/react';
import CustomScroller from 'components/CustomScroller';
import TutorialClose from './components/TutorialClose';
function TemplateTutorial() {
  return (
    <Box sx={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
      <Box
        sx={{
          flex: 1,
          position: 'relative',
          background: '#ffffff',
        }}
      >
        <TutorialClose />
        <Box
          sx={{
            position: 'absolute',
            height: '100%',
            width: '100%',
          }}
        >
          <CustomScroller>
            <Box
              sx={{
                background: '#ffffff',
                flex: 1,
                borderRight: '1px solid rgba(57,76,96,.15)',
                padding: '1.5rem',
                fontSize: '14px',
              }}
            >
              <Text
                sx={{
                  fontSize: '18px',
                  fontWeight: 700,
                }}
              >
                Templates
              </Text>
              <Box lineHeight="22px">
                <Text mt="1rem">
                  We’ve made available a collection of templates that you can
                  import to get you started. These can be used as is or
                  customized however you like.
                </Text>
              </Box>
            </Box>
          </CustomScroller>
        </Box>
      </Box>
    </Box>
  );
}
export default TemplateTutorial;
