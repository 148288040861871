import React from 'react';

function Checkmark({ size = 50, style = {} }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="10"
      height="8"
      viewBox="0 0 10 8"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.50973 6.29324L9.05535 0.765032C9.1914 0.629017 9.38974 0.575886 9.5756 0.625696C9.76146 0.67544 9.90664 0.820621 9.95645 1.00651C10.0063 1.1924 9.95309 1.39072 9.81705 1.52673L3.89005 7.4347C3.67935 7.6441 3.33905 7.6441 3.12835 7.4347L0.434147 4.74919C0.298563 4.61311 0.245864 4.41506 0.29594 4.22964C0.345982 4.04414 0.491163 3.89949 0.676789 3.85015C0.862382 3.80074 1.06026 3.85413 1.19585 3.99022L3.50973 6.29324Z"
        fill="#F15F24"
      />
    </svg>
  );
}

export default Checkmark;
