import React, { Fragment, useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { showMessages } from 'helpers/messageHelpers';
import { Field, Form, Formik } from 'formik';
import { defaultModal, openModal, showModal } from 'helpers/modals';
import NewPreview from '../Modals/NewPreview';
import * as yup from 'yup';
import InputFeedback from 'components/InputFeedback/InputFeedback';
import Button from 'components/Button/Button';
import ToggleSwitch from 'components/ToggleSwitch/ToggleSwitch';
import Tooltip from 'components/Tooltip/Tooltip';
import ActionZone from 'components/ActionZone/ActionZone';
import LoadingIndicator from 'components/LoadingIndicator/LoadingIndicator';
import AudienceDropdown from './AudienceDropdown/AudienceDropdown';
import AdTemplateDropdown from './AdTemplateDropdown/AdTemplateDropdown';
import { isEmpty } from 'lodash';
import { useFlag } from 'flags';
import colors from 'theme/Colors.module.scss';
import './NewCampaign.scss';
import CreateAudienceModal from './CreateAudienceModal';
import AudSummary from './AudSummary';
import Icons from 'components/Icons';
import imagePlaceholder from '../../../static/images/placeholder-image.png';
import { Input, useDisclosure } from '@chakra-ui/react';

import * as appActions from 'modules/app/appActions';
import * as appSelectors from 'modules/app/appSelectors';
import * as audiencesActions from 'modules/audiences/audiencesActions';
import * as campaignsActions from 'modules/campaigns/campaignsActions';
import * as campaignsSelectors from 'modules/campaigns/campaignsSelectors';
import {
  creativesActions,
  creativesSelectors,
} from 'modules/creatives/creativesDuck';

const targetTypeAud = 'audience';
const targetTypeOpen = 'open';

const schema = yup.object().shape({
  name: yup.string().required('Campaign Name cannot be blank.').trim(),
  // audience: yup.number().required('Audience is required.'),
  maxPerFulfillment: yup
    .number()
    .required('Required.')
    .positive('Invalid')
    .integer(),
  priority: yup.number().positive('Invalid').integer(),
  useDateRange: yup.boolean(),
  startDate: yup.date().when('useDateRange', {
    is: true,
    then: yup.date().required('Start Date/Time is required'),
  }),
  endDate: yup.date().when('useDateRange', {
    is: true,
    then: yup
      .date()
      .min(yup.ref('startDate'), 'End Date should be greater than Start Date')
      .required('End Date/Time is required'),
  }),
  totalDeliveryLimit: yup.number().when('deliveryLimit', {
    is: false,
    then: yup
      .number()
      .required('No limit set, a limit is required.')
      .positive('Invalid delivery limit.')
      .integer(),
  }),
  customerFrequencyCap: yup.number().when('frequencyCapEnabled', {
    is: true,
    then: yup
      .number()
      .required(
        'No customer frequency cap set, must have a value of 1 or greater.'
      )
      .positive('Invalid customer frequency cap.')
      .integer(),
  }),
  adTemplate: yup
    .number()
    .required('Ad template is required.')
    .positive('Please select an ad template.')
    .integer(),
});

const NewCampaign = props => {
  const { match, history } = props;

  const [redirect, setRedirect] = useState(false);
  const [previewModal, setPreviewModal] = useState(defaultModal);
  const [isEditing, setIsEditing] = useState(false);
  const {
    isOpen: isModalOpen,
    onOpen: onModalOpen,
    onClose: onModalClose,
  } = useDisclosure();
  const [audienceId, setAudienceId] = useState(0);
  const [editID] = useState(match.params.id);
  const [initialForm, setInitialForm] = useState({
    name: '',
    fulfillCount: '',
    maxPerFulfillment: 1,
    audience: 0,
    priority: '',
    useDateRange: false,
    startDate: '',
    endDate: '',
    totalDeliveryLimit: '',
    deliveryLimit: true, // this name is misleading, it means 'no delivery limit' and matches that toggle switch value
    customerFrequencyCap: '',
    frequencyCapEnabled: false,
    adTemplate: 0,
    enabled: true,
  });

  const dispatch = useDispatch();
  const title = isEditing ? 'Update your Campaign' : 'Create your New Campaign';
  const buttonIcon = isEditing ? null : 'add';
  const buttonText = isEditing ? 'Save Campaign' : 'Add Campaign';
  const isReadOnly = useFlag(['features', 'isReadOnly']);
  const [currentValueData, setCurrentValueData] = useState(null);

  const [audienceDdRefreshToken, setAudienceDdRefreshToken] = useState(0);
  const refreshAudienceDd = () => {
    setAudienceDdRefreshToken(prevKey => prevKey + 1);
  };

  // Selectors
  const messages = useSelector(state => appSelectors.messages(state));
  const allCreatives = useSelector(state =>
    creativesSelectors.creatives(state)
  );
  const isCreativesLoading = useSelector(state =>
    creativesSelectors.isCreativesLoading(state)
  );
  const currentCreative = useSelector(state =>
    campaignsSelectors.setExistingCreative(state)
  );
  const currentCampaign = useSelector(state =>
    campaignsSelectors.campaign(state)
  );
  const isCampaignLoading = useSelector(state =>
    campaignsSelectors.isCampaignLoading(state)
  );

  // Actions
  const setMessage = payload => dispatch(appActions.setMessage(payload));
  const clearMessage = index => dispatch(appActions.clearMessage(index));
  const getTemplates = payload =>
    dispatch(creativesActions.getTemplates(payload));
  const createCampaign = (campaign, doneCallback, failureCallback) =>
    dispatch(
      campaignsActions.createCampaign(campaign, doneCallback, failureCallback)
    );
  const updateCampaign = (id, campaign, doneCallback, failureCallback) =>
    dispatch(
      campaignsActions.updateCampaign(
        id,
        campaign,
        doneCallback,
        failureCallback
      )
    );
  const getCampaign = id => dispatch(campaignsActions.getCampaign(id));
  const clearCurrentCampaign = () =>
    dispatch(campaignsActions.clearCurrentCampaign());
  const getAudiences = (payload, doneCb, failCb) =>
    dispatch(audiencesActions.getAudiences(payload, doneCb, failCb));
  const fetchAudience = (id, successCb) =>
    dispatch(audiencesActions.getAudienceDetail(id, successCb));

  const everyTemplate = [
    {
      id: '',
      name: 'None',
      is_portrait: true,
    },
    ...allCreatives,
  ];

  everyTemplate?.map(o => ({ key: o.id, value: o.id, label: o.name }));

  useEffect(() => {
    redirect && setRedirect(false);

    clearCurrentCampaign();
    fetchAllTemplates();
    editMode();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const prevData = currentCampaign && currentCampaign[editID];
    autofill(prevData);
    setRedirect(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentCampaign]);

  useEffect(() => {
    fetchAllTemplates();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentCreative]);

  useEffect(() => {
    setAudienceId(
      (currentCampaign && currentCampaign[editID]?.audience_id) ?? 0
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentCampaign && currentCampaign[editID]?.audience_id]);

  useEffect(() => {
    if (!audienceId || audienceId === 0) setCurrentValueData(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [audienceId]);

  const autofill = prevData => {
    if (!isEmpty(prevData)) {
      setInitialForm({
        name: prevData.name || '',
        fulfillCount: prevData.fulfill_count || '',
        maxPerFulfillment: prevData.max_per_fulfillment || 1,
        audience: +prevData.audience_id || 0,
        priority: prevData.priority || '',
        useDateRange: !!prevData.start_date || !!prevData.end_date || false,
        startDate: prevData.start_date
          ? prevData.start_date.toISOString().slice(0, 16)
          : '',
        endDate: prevData.end_date
          ? prevData.end_date.toISOString().slice(0, 16)
          : '',
        totalDeliveryLimit: prevData.delivery_limit || '',
        deliveryLimit: !prevData.delivery_limit || false,
        customerFrequencyCap: prevData.customer_delivery_limit || '',
        frequencyCapEnabled: prevData.customer_delivery_limit ? true : false,
        adTemplate: +prevData.ad_template_id || 0,
        enabled: !!prevData ? prevData.enabled : true,
      });
    }
  };

  const handleSubmit = (values, setSubmitting) => {
    const {
      name,
      fulfillCount,
      maxPerFulfillment,
      audience,
      priority,
      deliveryLimit,
      totalDeliveryLimit,
      customerFrequencyCap,
      frequencyCapEnabled,
      useDateRange,
      startDate,
      endDate,
      adTemplate,
      enabled,
    } = values;

    const prepAudIDVal = () => {
      if (audience) {
        return audience > 0 ? +audience : null;
      } else {
        return null;
      }
    };

    const now = new Date();
    const localTimeOffset = now.getTimezoneOffset();
    const handleTimeOffset = time =>
      new Date(time.setMinutes(time.getMinutes() - localTimeOffset));

    const start_date = startDate ? handleTimeOffset(new Date(startDate)) : '';
    const end_date = endDate ? handleTimeOffset(new Date(endDate)) : '';

    const formData = {
      campaign: {
        name: name,
        fulfill_count: fulfillCount || null,
        max_per_fulfillment: maxPerFulfillment || 1,
        campaign_type: 'marketing',
        target_type: prepAudIDVal() ? targetTypeAud : targetTypeOpen,
        audience_id: prepAudIDVal(),
        priority: priority ? priority : null,
        delivery_limit: deliveryLimit
          ? null
          : (!!totalDeliveryLimit && totalDeliveryLimit) || null,
        customer_delivery_limit: frequencyCapEnabled
          ? customerFrequencyCap
          : null,
        start_date: useDateRange ? start_date : null,
        end_date: useDateRange ? end_date : null,
        ad_template_id: adTemplate > 0 ? +adTemplate : null,
        enabled: enabled,
      },
    };

    if (isEditing) {
      updateCampaign(
        editID,
        formData,
        () => {
          setMessage({
            message: 'Campaign updated successfully',
            type: 'success',
          });

          redirect && redirectBack();
          window.scrollTo(0, 0);
          setSubmitting(false);
        },
        async err => {
          const json = await err.json();
          Object.keys(json?.error?.data || {}).map(errorKey => {
            json.error.data[errorKey].map(message =>
              setMessage({
                message,
                type: 'error',
              })
            );
            return true;
          });
        }
      );
    } else {
      createCampaign(
        formData,
        () => {
          setMessage({
            message: 'Campaign created successfully',
            type: 'success',
          });

          redirect && redirectBack();
          window.scrollTo(0, 0);
          setSubmitting(false);
        },
        err => {
          err.json().then(json => {
            Object.keys(json?.error?.data || {}).map(errorKey => {
              json.error.data[errorKey].map(message =>
                setMessage({
                  message,
                  type: 'error',
                })
              );
              return true;
            });
          });
        }
      );
    }
  };

  const redirectBack = () =>
    history.location.state
      ? history.goBack()
      : history.push(`/campaigns${history.location.search || ''}`);

  const editMode = () => {
    if (!isEmpty(editID)) {
      getCampaign(editID);
      setIsEditing(true);
    }
  };

  const fetchAllTemplates = () => {
    const payload = { current_page: 1, size: 500 };
    getTemplates(payload);
  };

  const showDateFields = (type, useDateRange) => {
    const fieldName = `${type.toLowerCase()}Date`;
    if (useDateRange) {
      return (
        <div className="dateInput">
          <label htmlFor={fieldName}>
            <h4 className="title">{type} Date</h4>
          </label>
          <div id={fieldName} style={{ display: 'flex' }}>
            <Field name={fieldName}>
              {({ field, form }) => (
                <Input
                  type="datetime-local"
                  value={field.value}
                  onChange={e => form.setFieldValue(fieldName, e.target.value)}
                  style={{ flex: 1 }}
                />
              )}
            </Field>
          </div>
          <InputFeedback name={fieldName} />
        </div>
      );
    }
  };

  const buildScheduleOptions = ({ isSubmitting, handleChange, values }) => {
    const {
      deliveryLimit,
      totalDeliveryLimit,
      customerFrequencyCap,
      frequencyCapEnabled,
      useDateRange,
      enabled,
    } = values;

    const valAsBool = useDateRange =>
      typeof useDateRange !== 'boolean'
        ? useDateRange === 'true'
        : useDateRange;

    return (
      <>
        <div className="statusSection">
          <div className="statusOptions">
            <h4 className="title">Campaign Status</h4>
          </div>

          <div className="statusAttrs">
            <div className="enableToggle campaignStatus">
              <h4 className="title">Enabled:</h4>
              <ToggleSwitch
                disabled={isSubmitting || isReadOnly}
                name="enabled"
                value={enabled}
                onChange={e => {
                  handleChange(e);
                }}
                color={colors.status00}
              />
            </div>
          </div>
        </div>
        <div className="scheduleOptions">
          <div className="scheduleOptionsLeft">
            <h4 className="title">Schedule</h4>

            <div className="scheduleGroup">
              <div className="radioCheckbox">
                <Field name="useDateRange">
                  {({ field, form }) => {
                    return (
                      <input
                        {...field}
                        id="runContinuously"
                        type="radio"
                        value={false}
                        disabled={isReadOnly}
                        checked={!valAsBool(useDateRange)}
                        onChange={() =>
                          form.setFieldValue(
                            field.name,
                            !valAsBool(useDateRange)
                          )
                        }
                      />
                    );
                  }}
                </Field>
                <label htmlFor="runContinuously">
                  Run continuously starting today
                </label>
              </div>

              <div className="radioCheckbox">
                <Field name="useDateRange">
                  {({ field, form }) => {
                    return (
                      <input
                        {...field}
                        id="setStartEndDates"
                        type="radio"
                        value={true}
                        disabled={isReadOnly}
                        checked={valAsBool(useDateRange)}
                        onChange={() =>
                          form.setFieldValue(
                            field.name,
                            !valAsBool(useDateRange)
                          )
                        }
                      />
                    );
                  }}
                </Field>
                <label htmlFor="setStartEndDates">
                  Set start and end dates
                </label>
              </div>
            </div>
          </div>

          <div className="scheduleOptionsRight">
            {showDateFields('Start', values.useDateRange)}
            {showDateFields('End', values.useDateRange)}
          </div>

          <div className="deliveryOptions">
            <h4 className="title">
              Total Delivery Limit &nbsp;
              <Tooltip
                tipText="The number of deliveries to hit before this campaign turns off automatically."
                direction="top"
              />
            </h4>

            <Field
              type="number"
              name="totalDeliveryLimit"
              placeholder={
                deliveryLimit ? 'No Delivery Limit.' : 'Max Limit e.g. 125, 000'
              }
              disabled={isReadOnly || deliveryLimit}
              value={totalDeliveryLimit}
            />

            <InputFeedback name="totalDeliveryLimit" />
          </div>

          <div className="deliveryAttrs">
            <div className="radioCheckbox deliveryLimit">
              <h4 className="title">No Limit:</h4>
              <ToggleSwitch
                disabled={isSubmitting || isReadOnly}
                name="deliveryLimit"
                value={deliveryLimit}
                onChange={handleChange}
                color={colors.status00}
              />
            </div>
          </div>

          <div className="frequencyOptions">
            <h4 className="title">
              Customer Frequency Cap &nbsp;
              <Tooltip
                tipText="Enables a maximum number of times this campaign will be delivered to a customer."
                direction="top"
              />
            </h4>

            <Field
              type="number"
              name="customerFrequencyCap"
              placeholder={frequencyCapEnabled ? '1' : 'None'}
              disabled={isReadOnly || !frequencyCapEnabled}
              value={customerFrequencyCap}
            />

            <InputFeedback name="customerFrequencyCap" />
          </div>

          <div className="frequencyAttrs">
            <div className="enableToggle frequencyCap">
              <h4 className="title">Enabled:</h4>
              <ToggleSwitch
                disabled={isSubmitting || isReadOnly}
                name="frequencyCapEnabled"
                value={frequencyCapEnabled}
                onChange={e => {
                  handleChange(e);
                }}
                color={colors.status00}
              />
            </div>
          </div>
        </div>
      </>
    );
  };

  const buildTemplatePreview = templateID => {
    const template = everyTemplate.find(
      template => template.id === parseInt(templateID)
    );

    if (templateID !== 0 && template) {
      let previewSrc;
      let originalSrc;

      let previewSecondSrc;
      let originalSecondSrc;

      if (template.double_sided === false) {
        previewSrc = template.bg_img?.original_url; // Use thumb_url for square ratio.
        originalSrc = template.bg_img?.original_url;
      }

      if (
        template.double_sided === true &&
        (template.ad_template_pages[0].bg_img ||
          template.ad_template_pages[1].bg_img)
      ) {
        template.ad_template_pages.sort((a, b) => a.page_num - b.page_num);
        previewSrc =
          template.ad_template_pages[0].bg_img &&
          template.ad_template_pages[0].bg_img.original_url; // Use thumb_url for square ratio.
        originalSrc =
          template.ad_template_pages[0].bg_img &&
          template.ad_template_pages[0].bg_img.original_url;

        previewSecondSrc =
          template.ad_template_pages[1].bg_img &&
          template.ad_template_pages[1].bg_img.original_url; // Use thumb_url for square ratio.
        originalSecondSrc =
          template.ad_template_pages[1].bg_img &&
          template.ad_template_pages[1].bg_img.original_url;
      }

      return (
        <>
          <div
            className="creativePreview"
            onClick={() => {
              openModal(
                setPreviewModal,
                null,
                'Template Background Preview',
                <NewPreview
                  creative={{
                    original_url: originalSrc,
                    filename: 'Insert',
                    originalSecondSrc: originalSecondSrc,
                  }}
                />
              );
            }}
          >
            <img src={previewSrc ? previewSrc : imagePlaceholder} alt="" />

            {template.double_sided && (
              <img
                style={{ marginLeft: '10px' }}
                src={previewSecondSrc ? previewSecondSrc : imagePlaceholder}
                alt=""
              />
            )}
          </div>
        </>
      );
    }
  };

  const buildTemplateEditor = templateID => {
    const template = everyTemplate.find(
      template => template.id === parseInt(templateID)
    );
    const referrer = `/campaigns/${editID || 'new'}`;
    const params =
      template && template.id !== 0
        ? `?id=${template.id}&ref=${referrer}`
        : `?ref=${referrer}`;

    return (
      <>
        <div
          className="editorCTA"
          onClick={() => {
            history.push(`/template-editor${params}`);
          }}
        >
          <ActionZone
            text={
              templateID !== 0 ? 'Edit this template' : 'Build your template'
            }
          />
        </div>
      </>
    );
  };

  const buildAudienceList = form => {
    const includes =
      currentValueData?.rule_sets?.filter(set => set.logical_op_type) || 0;
    const excludes =
      currentValueData?.rule_sets?.filter(set => !set.logical_op_type) || 0;

    return (
      <>
        <CreateAudienceModal
          match={match}
          isModalOpen={isModalOpen}
          onModalClose={onModalClose}
          audienceId={audienceId}
          onUpdateAudience={aud => {
            if (aud) {
              setAudienceId(aud.id);
              // get updated audience
              fetchAudience(aud.id);
              form.setFieldValue('audience', aud?.id ? aud.id : 0);
              refreshAudienceDd();
            }
          }}
        />
        <div>
          <div style={{ position: 'relative' }}>
            <div style={{ paddingBottom: '1rem' }}>Audience</div>
            <div id="audience-dropdown" style={{ width: '100%' }}>
              <InputFeedback name="audience" touchOptional />
              <AudienceDropdown
                isDisabled={isReadOnly}
                getAudiences={getAudiences}
                fetchAudience={fetchAudience}
                selectedValue={audienceId}
                onChange={aud => {
                  setCurrentValueData(aud);
                  setAudienceId(aud?.id ?? 0);
                  form.setFieldValue('audience', aud?.id ? aud.id : 0);
                }}
                openNewAudience={() => {
                  setAudienceId(0);
                  form.setFieldValue('audience', 0);
                  onModalOpen();
                }}
                refreshToken={audienceDdRefreshToken}
              />
            </div>
          </div>
          <div style={{ position: 'relative' }}>
            <div
              style={{
                padding: '1rem 0',
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <div>Rules</div>
              {currentValueData &&
                currentValueData?.value !== 0 &&
                !isReadOnly && (
                  <div
                    onClick={() => {
                      setAudienceId(currentValueData?.id);
                      onModalOpen();
                    }}
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      fontSize: '14px',
                      color: '#rgba(0,0,0,0.25)',
                      gap: '0.25rem',
                      cursor: 'pointer',
                    }}
                  >
                    <div>Edit</div>
                    <Icons.Edit size={14} />
                  </div>
                )}
            </div>
            <div>
              <div>
                {currentValueData && currentValueData.rule_sets ? (
                  <div>
                    <div
                      style={{
                        backgroundColor: 'rgba(255,255,255,0.85)',
                        padding: '0 1rem 2rem',
                        border: '1px solid rgba(0,0,0,0.1)',
                        fontSize: '15px',
                      }}
                    >
                      <div style={{ backgroundColor: '#ffffff' }}>
                        <AudSummary
                          listBackgroundColor="rgba(0,0,0,0.0375)"
                          data={{ includes, excludes }}
                        />
                      </div>
                    </div>
                  </div>
                ) : (
                  <div
                    style={{
                      background: 'rgba(0,0,0,0.02)',
                      padding: '1rem',
                      textAlign: 'center',
                      fontSize: '14px',
                    }}
                  >
                    <i>NO RULES</i>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  const buildHeaderFields = form => {
    return (
      <>
        <div className="headerFields">
          <div id="campaignName">
            <label id="nameField" htmlFor="name">
              Campaign Name
              <Field
                type="text"
                name="name"
                disabled={isReadOnly}
                placeholder="Name this Campaign"
              />
              <InputFeedback name="name" />
            </label>
          </div>

          <div id="campaignMaxFulfillment">
            <label htmlFor="maxPerFulfillment">
              <span className="title">
                Max/Fulfillment &nbsp;
                <Tooltip
                  tipText="Maximum number of times this campaign will produce a fulfillment print for a single order.  Only applies to configurations that fulfill multiple prints with a single order."
                  direction="bottom"
                />
              </span>

              <Field
                type="number"
                name="maxPerFulfillment"
                disabled={isReadOnly}
                placeholder="e.g 1"
              />
              <InputFeedback name="maxPerFulfillment" />
            </label>

            <Field
              type="number"
              name="fulfillCount"
              disabled
              style={{ display: 'none' }}
            />
          </div>

          <div id="campaignPriority">
            <label htmlFor="priority">
              <span className="title">
                Priority &nbsp;
                <Tooltip
                  tipText="Campaigns will be checked for audience match in the order of priority."
                  direction="bottom"
                />
              </span>

              <Field
                type="number"
                name="priority"
                disabled={isReadOnly}
                placeholder="e.g 1"
              />
              <InputFeedback name="priority" />
            </label>
          </div>
        </div>
      </>
    );
  };

  return (
    <div className="ppkgNew">
      <div className="rail">
        <Button
          type="button"
          text="Back"
          className="backBtn"
          textColor={colors.slate}
          icon="back"
          btnStyle="transparent"
          callback={redirectBack}
        />
      </div>
      <div className="formContainer">
        <Formik
          enableReinitialize
          initialValues={initialForm}
          validationSchema={schema}
          onSubmit={(values, { setSubmitting }) =>
            handleSubmit(values, setSubmitting)
          }
        >
          {form => {
            const isValid = isEditing
              ? Object.keys(form.errors).length === 0
              : form.isValid;
            return (
              <Form className="newCampaignForm" name="newCampaignForm">
                <div className="title">
                  <h1>{title}</h1>
                </div>

                <LoadingIndicator loading={isEditing && isCampaignLoading}>
                  {buildHeaderFields(form)}

                  <div id="audienceSelect">
                    <label htmlFor="audience">
                      <div>Audience Details</div>
                      <div
                        style={{
                          padding: '2rem',
                          display: 'flex',
                          flexDirection: 'column',
                          backgroundColor: '#ffffff',
                        }}
                      >
                        {buildAudienceList(form)}
                      </div>
                    </label>
                  </div>
                  <div className="campaignSectionTitle">Campaign Details</div>
                  <div className="mainContent">
                    {buildScheduleOptions(form)}
                    <div className="adTemplateArea">
                      <h4 className="title">Ad Template</h4>
                      {!isCreativesLoading && (
                        <Field name="adTemplate">
                          {({ form, meta }) => (
                            <>
                              {meta.touched && meta.error && (
                                <InputFeedback
                                  name="adTemplate"
                                  touchOptional
                                />
                              )}
                              <AdTemplateDropdown
                                initialForm={initialForm}
                                onChange={e => {
                                  form.setFieldValue(
                                    'adTemplate',
                                    e && e.value !== '' ? e.value : 0
                                  );
                                }}
                                isDisabled={isReadOnly}
                              />
                            </>
                          )}
                        </Field>
                      )}
                      <div style={{ marginTop: '1rem' }}>
                        {!isReadOnly &&
                          buildTemplateEditor(form.values.adTemplate)}
                      </div>
                    </div>
                    {!isReadOnly &&
                      buildTemplatePreview(form.values.adTemplate)}
                  </div>

                  {!isReadOnly && (
                    <Fragment>
                      <div className="submit">
                        <Button
                          type="button"
                          icon={buttonIcon}
                          iconColor={colors.white}
                          text={buttonText}
                          textColor={colors.white}
                          hoverColor={colors.secondary900}
                          textHoverColor={colors.white}
                          btnStyle="solid"
                          onClick={() => form.submitForm()}
                          disabled={form.isSubmitting}
                          callback={() => {
                            if (!isValid) {
                              const message =
                                'Form errors: please see fields for details.';
                              setMessage({ message, type: 'error' });
                            }
                          }}
                        />
                      </div>
                    </Fragment>
                  )}
                </LoadingIndicator>
              </Form>
            );
          }}
        </Formik>
      </div>

      {showModal(previewModal, setPreviewModal)}
      {showMessages(messages, clearMessage)}
    </div>
  );
};

export default withRouter(NewCampaign);
