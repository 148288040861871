import React from 'react';
import _ from 'lodash';
// import colors from 'theme/Colors.module.scss';
import './Overview.scss';

const Overview = props => {
  const { title, cards } = props;

  const buildSection = () => {
    const renderContent = [];

    cards.forEach(card => {
      const cardStyle = { backgroundColor: card.bgColor };

      renderContent.push(
        <li
          key={_.uniqueId('overviewCard_')}
          className="overviewCard"
          style={cardStyle}
        >
          <div className="icon">{card.icon}</div>
          <div className="value">{card.value}</div>

          <div className="title">
            <p>{card.title}</p>
            <p className="subTitle">
              {card.subTitle ? card.subTitle : <span>&nbsp;</span>}
            </p>
          </div>
        </li>
      );
    });

    return renderContent;
  };

  return (
    <React.Fragment>
      <li className="cardSection">
        <div className="header">
          <p className="title">{title.toUpperCase()}</p>
          <div className="bufferLine">&nbsp;</div>
        </div>

        <ul className="cards">{buildSection()}</ul>
      </li>
    </React.Fragment>
  );
};

export default Overview;
