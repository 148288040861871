import React from "react"
import Icons from "../../../Icons"
import { IconButton, Tooltip } from '@chakra-ui/react'
import { useEditor } from "components/Canvas"

function CopyStyle() {
    const editor = useEditor()
    return (
        <Tooltip label="Copy style">
            <IconButton
                onClick={() => editor.copyStyle()}
                color="#000000"
                borderRadius="0"
                variant="ghost"
                size="sm"
                icon={<Icons.CopyStyle size={24} />}
            />
        </Tooltip>
    )
}

export default CopyStyle
