import React, { useState, useEffect } from 'react';
import _, { isEmpty } from 'lodash';
import { defaultModal, openModal, showModal } from '../../helpers/modals';
import Order from './Modals/Order';
import Overview from '../dashboard/Overview/Overview';
import Pagination from '../../components/Pagination/Pagination';
import InventoryCard from './InventoryCard/InventoryCard';
import InventoryHeaders from './InventoryHeaders/InventoryHeaders';
import colors from '../../theme/Colors.module.scss';
import './Inventory.scss';

const Inventory = props => {
  const {
    inventoryBalances,
    inventoryOrders,
    getInventoryBalances,
    getInventoryOrders,
    pageData,
  } = props;

  const [inventoryModal, setInventoryModal] = useState(defaultModal);

  // On Mount
  useEffect(() => {
    getInventoryOrders();
    getInventoryBalances();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handlePageChange = (newPage, newSize) => {
    const newData = {
      current_page: newPage,
      size: newSize,
    };

    getInventoryOrders(newData);
  };

  const buildInventoryBalances = () => {
    if (!isEmpty(inventoryBalances)) {
      const {
        cyan_balance,
        magenta_balance,
        yellow_balance,
        black_balance,
        paper_balance,
        // maintenance_balance,
      } = inventoryBalances;

      return (
        <ul className="overview">
          <Overview
            title={`Ink`}
            cards={[
              {
                icon: <i className="fas fa-tint" />,
                value: cyan_balance.toLocaleString(),
                title: 'Cyan',
                subTitle: null,
                bgColor: colors.cyan,
              },
              {
                icon: <i className="fas fa-tint" />,
                value: magenta_balance.toLocaleString(),
                title: `Magenta`,
                subTitle: null,
                bgColor: colors.magenta,
              },
              {
                icon: <i className="fas fa-tint" />,
                value: yellow_balance.toLocaleString(),
                title: 'Yellow',
                subTitle: null,
                bgColor: colors.pastelYellow,
              },
              {
                icon: <i className="fas fa-tint" />,
                value: black_balance.toLocaleString(),
                title: 'Black',
                subTitle: null,
                bgColor: colors.darkGrey,
              },
            ]}
          />

          <Overview
            title={`Paper`}
            cards={[
              {
                icon: <i className="fas fa-print" />,
                value: paper_balance.toLocaleString(),
                title: 'Paper',
                subTitle: null,
                bgColor: colors.grey,
              },
            ]}
          />

          <Overview
            title={`Maintenance`}
            cards={[
              {
                icon: <i className="fas fa-fill-drip" />,
                value: paper_balance.toLocaleString(),
                title: 'Maintenance',
                subTitle: null,
                bgColor: colors.slate,
              },
            ]}
          />
        </ul>
      );
    }
  };

  const buildInventoryOrders = () => {
    const renderContent = [];
    if (!isEmpty(inventoryOrders)) {
      inventoryOrders.forEach(order => {
        const title = `Order #${order.id} Summary`;

        renderContent.push(
          <InventoryCard
            key={_.uniqueId('inventoryCard_')}
            order={order}
            callback={() =>
              openModal(
                setInventoryModal,
                order.id,
                title,
                <Order order={order} />
              )
            }
          />
        );
      });
    }

    return (
      <div className="inventoryContainer">
        {renderContent}

        {isEmpty(inventoryOrders) && (
          <div className="noInventory">
            <p>No orders found...</p>
          </div>
        )}
      </div>
    );
  };

  return (
    <React.Fragment>
      <div className="inventoryOverview">{buildInventoryBalances()}</div>

      <div className="inventoryBody">
        <InventoryHeaders />
        {buildInventoryOrders()}
        <Pagination
          pageData={!isEmpty(pageData) && pageData}
          onPageChange={handlePageChange}
        />
      </div>

      {showModal(inventoryModal, setInventoryModal)}
    </React.Fragment>
  );
};

export default Inventory;
