import React from 'react';
function DocumentColor({ size }) {
  return (
    <svg
      fill="none"
      height={size}
      viewBox="0 0 24 24"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        clipRule="evenodd"
        d="m6.31 8.25 3.94-3.94V8a.25.25 0 0 1-.25.25zm-.81 12V9.75H10A1.75 1.75 0 0 0 11.75 8V3.5h4.5a.25.25 0 0 1 .25.25V7A.75.75 0 0 0 18 7V3.75A1.75 1.75 0 0 0 16.25 2h-5.086c-.464 0-.909.184-1.237.513L4.513 7.927A1.75 1.75 0 0 0 4 9.164V20.25c0 .967.784 1.75 1.75 1.75H10a.75.75 0 0 0 0-1.5H5.75a.25.25 0 0 1-.25-.25zm17-5.75c0 2.13-.996 4.202-3.423 4.202h-1.459a.3.3 0 0 0-.244.474l.067.093c.06.084.122.169.177.257.137.219.225.448.268.677.206 1.082-.576 2.145-1.799 1.911-2.983-.571-5.515-2.288-5.978-5.425-.742-5.033 3.53-8.71 8.245-7.446 2.274.61 4.146 2.366 4.146 5.257zm-1.5 0c0 .862-.206 1.58-.528 2.037-.276.392-.677.666-1.395.666h-1.459c-1.465 0-2.31 1.653-1.467 2.841.033.047.065.09.095.13.121.162.208.279.18.389-.023.088-.186.053-.264.036l-.011-.002c-2.519-.54-4.234-1.927-4.558-4.127-.584-3.962 2.696-6.764 6.373-5.778C19.722 11.163 21 12.422 21 14.5zm-5.5-1a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm4-1a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm-1 4a1 1 0 1 0 0-2 1 1 0 0 0 0 2z"
        fill="currentColor"
        fillRule="evenodd"
      ></path>
    </svg>
  );
}
export default DocumentColor;
