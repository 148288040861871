import Images from './Images';
import Templates from './Templates';
import Text from './Text';
import QR from './QR';
import Barcode from './Barcode';
import Background from './Background';
import FontFamily from './FontFamily';
import TextTutorial from './TextTutorial';
import ImageTutorial from './ImageTutorial';
import BackgroundTutorial from './BackgroundTutorial';
import QRTutorial from './QRTutorial';
import BarcodeTutorial from './BarcodeTutorial';
import TemplateTutorial from './TemplateTutorial';

class PanelItems {
  static Images = Images;
  static Templates = Templates;
  static Text = Text;
  static QR = QR;
  static Barcode = Barcode;
  static Background = Background;
  static FontFamily = FontFamily;
  static TextTutorial = TextTutorial;
  static ImageTutorial = ImageTutorial;
  static BackgroundTutorial = BackgroundTutorial;
  static QRTutorial = QRTutorial;
  static BarcodeTutorial = BarcodeTutorial;
  static TemplateTutorial = TemplateTutorial;
}

export default PanelItems;
