import React from 'react';

function Warning({ height = 14, width = 14, style = {} }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.24401 1.51419C6.02416 0.161936 7.97584 0.161935 8.75598 1.51419L13.7257 10.1284C14.5054 11.4799 13.53 13.1687 11.9697 13.1687H2.03028C0.469986 13.1687 -0.505417 11.4799 0.274299 10.1284L5.24401 1.51419ZM7.00014 5.05961C7.28005 5.05961 7.50696 5.28652 7.50696 5.56642V8.1005C7.50696 8.38041 7.28005 8.60732 7.00014 8.60732C6.72023 8.60732 6.49333 8.38041 6.49333 8.1005V5.56642C6.49333 5.28652 6.72023 5.05961 7.00014 5.05961ZM7.00014 10.6346C7.28005 10.6346 7.50696 10.4077 7.50696 10.1278C7.50696 9.84785 7.28005 9.62095 7.00014 9.62095C6.72023 9.62095 6.49333 9.84785 6.49333 10.1278C6.49333 10.4077 6.72023 10.6346 7.00014 10.6346Z"
        fill={style.backgroundColor}
      />
    </svg>
  );
}

export default Warning;
