import React from 'react';

import EntityTableCellEmpty from './EntityTableCellEmpty';

export default ({ headers, fillRight }) => {
  return (
    <tr>
      <td className="EntityTable-noResults">
        <label className="EntityTable-noResults-title">
          No data is available.
        </label>
      </td>
      {fillRight && <EntityTableCellEmpty />}
    </tr>
  );
};
