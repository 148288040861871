import React from 'react';
import { Box, Text, Image } from '@chakra-ui/react';
import { CodeBlock, nord } from 'react-code-blocks';
import CustomScroller from 'components/CustomScroller';
import TutorialClose from './components/TutorialClose';

const code = `{
  "audience": {
    ...
  },
  "template": {
    "barcode_val": "1234-ASDF-5678"
  }
}`;

function BarcodeTutorial() {
  return (
    <Box sx={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
      <Box
        sx={{
          flex: 1,
          position: 'relative',
        }}
      >
        <TutorialClose />
        <Box
          sx={{
            position: 'absolute',
            height: '100%',
            width: '100%',
          }}
        >
          <CustomScroller>
            <Box
              sx={{
                background: '#ffffff',
                flex: 1,
                borderRight: '1px solid rgba(57,76,96,.15)',
                padding: '1.5rem',
                fontSize: '14px',
              }}
            >
              <Text sx={{ fontSize: '18px', fontWeight: 700 }}>Barcode</Text>
              <Box lineHeight="22px">
                <Text mt="1rem">
                  Barcode blocks allow you to add a placeholder for a barcode
                  into template
                </Text>
                <Text mt="1rem">
                  When order data is posted to UnDigital, the system will use
                  the specified dynamic key name to find the value from the
                  matching key in the template params section of the post and
                  encode that as a barcode.
                </Text>

                <Text mt="1rem">Example order data post</Text>

                <Box
                  sx={{
                    fontFamily: "'Fira Code', monospace",
                    marginTop: '1rem',
                  }}
                >
                  <CodeBlock
                    text={code}
                    language={'json'}
                    showLineNumbers={false}
                    theme={nord}
                  />
                </Box>

                <Text mt="1rem">
                  Will produce an ad image that replaces the personalization
                  token with the provided value encoded as a barcode.
                </Text>

                <Box pt="1rem">
                  <Image
                    boxShadow="md"
                    width="308px"
                    margin="auto"
                    src="https://i.ibb.co/Gvfx0x8/qrpreview-1.png"
                  />
                </Box>

                <Text mt="1rem">
                  You can also use the Preview button at the top right corner to
                  provide sample values in order to preview what the ad template
                  would generate during an order post.
                </Text>

                <Image
                  boxShadow="md"
                  pt="1rem"
                  width="308px"
                  margin="auto"
                  src="https://i.ibb.co/G3c3Sxb/qrform-1.png"
                />
              </Box>
            </Box>
          </CustomScroller>
        </Box>
      </Box>
    </Box>
  );
}
export default BarcodeTutorial;
