import {
  Box,
  Button,
  Divider,
  Flex,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  useDisclosure,
} from '@chakra-ui/react';
import React from 'react';
import useTemplateEditorContext from '../../hooks/useTemplateEditorContext';
import Checked from '../Icons/Checked';

function EnabledDoubleSideModal() {
  const { isEnabledDoubleSideMessage, setIsEnabledDoubleSideMessage } =
    useTemplateEditorContext();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const closeModal = () => {
    setIsEnabledDoubleSideMessage(false);
  };

  React.useEffect(() => {
    if (isEnabledDoubleSideMessage) {
      !isOpen && onOpen();
    } else {
      isOpen && onClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEnabledDoubleSideMessage]);

  return (
    <Modal
      motionPreset="none"
      isCentered
      isOpen={isOpen}
      onClose={() => {
        onClose();
        closeModal();
      }}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalBody p="0">
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              textAlign: 'center',
              fontsize: '14px',
              paddingBottom: '1.5rem',
              color: '#333333',
              padding: '0 1rem',
            }}
          >
            <Flex justifyContent="center" padding="0.5rem">
              <Checked />
            </Flex>
            <Box sx={{ fontSize: '24px', fontWeight: 'bold' }}>
              Double sided printing
            </Box>
            <Box sx={{ fontSize: '14px', marginTop: '0.5rem' }}>
              To enable your account for double sided printing, please
            </Box>
            <Box sx={{ fontSize: '14px' }}>contact your account manager.</Box>
          </Box>

          <Divider m="1.2rem 0 1rem" />

          <Box p="0 1.8rem">
            <Button
              onClick={() => {
                closeModal();
              }}
              sx={{
                backgroundColor: '#5E2EBA',
                color: 'white',
                width: '100%',
                marginBottom: '1rem',
                margin: '0 auto 1rem',
              }}
            >
              OK
            </Button>
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}

export default EnabledDoubleSideModal;
