import { useState, useEffect, useRef } from 'react';
import classNames from 'classnames';

export default props => {
  const { requesting, requestingSilent } = props;
  const [showRequesting, setShowRequesting] = useState(requesting);
  const previousRequesting = useRef(requesting);

  useEffect(() => {
    if (requesting === previousRequesting.current) return;

    if (!requesting && showRequesting) {
      setShowRequesting(false);
    } else if (requesting && !showRequesting && !requestingSilent) {
      setShowRequesting(true);
    }

    previousRequesting.current = requesting;
  }, [showRequesting, requesting, requestingSilent]);

  return (
    <tbody
      ref={props.droppableProvided?.innerRef}
      className={classNames(props.className, 'EntityTable-body', {
        'EntityTable-body-requesting': showRequesting,
      })}
    >
      {props.children}
      {props.droppableProvided?.placeholder}
    </tbody>
  );
};
