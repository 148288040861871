import React, { useState, useEffect, useCallback } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import * as appActions from 'modules/app/appActions';
import * as appSelectors from 'modules/app/appSelectors';
import InputFeedback from 'components/InputFeedback/InputFeedback';
import Button from 'components/Button/Button';
import colors from 'theme/Colors.module.scss';
import 'theme/Forms.scss';
import { paramsToObject } from 'helpers/paramHelpers';

const defaults = {
  newPassword: '',
  confirmPassword: '',
};

const schema = Yup.object().shape({
  newPassword: Yup.string().required('New password is required.').trim(),
  confirmPassword: Yup.string()
    .required('Confirm new password is required.')
    .oneOf([Yup.ref('newPassword'), null], 'Passwords must match.')
    .trim(),
});

const NewPassword = props => {
  const [noToken, setNoToken] = useState(false);

  const history = useHistory();
  const { search } = useLocation();
  const params = paramsToObject(search);
  const dispatch = useDispatch();

  // Selectors
  const invalidPwResetToken = useSelector(state =>
    appSelectors.invalidPwResetToken(state)
  );

  // Actions
  const clearInvalidPwResetToken = () =>
    dispatch(appActions.clearInvalidPwResetToken());
  const verifyResetToken = useCallback(
    (payload, doneCallback) =>
      dispatch(appActions.verifyResetToken(payload, doneCallback)),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
  const changePassword = useCallback(
    (payload, doneCallback) =>
      dispatch(appActions.changePassword(payload, doneCallback)),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const verifyPayload = { token: params?.t };

  useEffect(() => {
    if (!params.t) {
      setNoToken(true);
    } else {
      noToken && setNoToken(false);

      verifyResetToken(verifyPayload, () => {
        invalidPwResetToken && clearInvalidPwResetToken();
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = (values, isSubmitting) => {
    const { newPassword, confirmPassword } = values;

    !noToken &&
      verifyResetToken(verifyPayload, () => {
        const resetPayload = {
          token: params?.t,
          pw: newPassword,
          pw_confirm: confirmPassword,
        };

        changePassword(resetPayload, () => history.push('/login'));
      });

    isSubmitting(false);
  };

  return (
    <React.Fragment>
      <Formik
        initialValues={defaults}
        validationSchema={schema}
        onSubmit={(values, { setSubmitting }) =>
          handleSubmit(values, setSubmitting)
        }
      >
        {form => (
          <Form className="authForm">
            <h1 className="title">Reset your Password</h1>

            <Field
              name="newPassword"
              type="password"
              placeholder="New Password*"
              onFocus={() => {
                invalidPwResetToken && clearInvalidPwResetToken();
              }}
            />
            <InputFeedback name="newPassword" />

            <Field
              name="confirmPassword"
              type="password"
              placeholder="Confirm Password*"
              onFocus={() => {
                invalidPwResetToken && clearInvalidPwResetToken();
              }}
            />
            <InputFeedback name="confirmPassword" />

            {noToken && (
              <p style={{ color: colors.status100 }}>
                <strong>Token not found, please use email link</strong>.
              </p>
            )}

            {invalidPwResetToken && (
              <p style={{ color: colors.status100 }}>
                <strong>
                  Invalid token,{' '}
                  <Link to="/reset-password">request a new token</Link>
                </strong>
                .
              </p>
            )}

            <Button
              type="submit"
              text="Reset my Password"
              textColor={colors.white}
              btnStyle="solid"
              disabled={form.isSubmitting || noToken}
            />

            <p className="loginOptions">
              <Link to="/login">Log in</Link>.
            </p>

            <p className="footnote">An asterisk (*) marks a required field.</p>
          </Form>
        )}
      </Formik>
    </React.Fragment>
  );
};

export default NewPassword;
