import React from 'react';
import { FieldArray } from 'formik';
import { isEmpty } from 'lodash';
import ActionZone from 'components/ActionZone/ActionZone';
import { paramTemplateTypes } from '../ParamTemplates/ParamTemplates';
import colors from 'theme/Colors.module.scss';
import Param from '../Param/Param';
import Tooltip from 'components/Tooltip/Tooltip';
import InputFeedback from 'components/InputFeedback/InputFeedback';
import * as audiencesHelpers from 'helpers/audiencesHelpers';
import './RulesSection.scss';

const includes = 'incParams';
const excludes = 'excParams';

export default function RulesSection({
  group,
  initialForm,
  setInitialForm,
  companyParams = [],
}) {
  const includesGroup = group === includes;

  const initParam = paramTemplateTypes[0];

  const addSet = (form, group) => {
    const { newForm } = audiencesHelpers.addSet({ form, group });
    setInitialForm(newForm);
  };

  const removeSet = ({ form, group, index }) => {
    const { newForm } = audiencesHelpers.removeSet({ form, group, index });
    setInitialForm(newForm);
  };

  const removeParam = ({ ruleIndex, ruleSetIndex, rules, form }) => {
    const { newForm } = audiencesHelpers.removeParam({
      initialForm,
      ruleIndex,
      ruleSetIndex,
      form,
      group,
      ruleSetGroup: form.values[group],
      rules,
    });
    setInitialForm(newForm);
  };

  const buildParams = ({ ruleSetIndex, rules, form, name }) => {
    const emptyRuleSet = isEmpty(rules[0]);

    if (!emptyRuleSet) {
      return rules.map((rule, ruleIndex) => (
        <Param
          key={ruleIndex}
          companyParams={companyParams}
          recentlyUsedKeys={[]}
          index={ruleIndex}
          item={rule}
          remove={() => removeParam({ ruleSetIndex, ruleIndex, form, rules })}
          name={name}
        />
      ));
    }
  };

  return (
    <FieldArray name={group}>
      {arrayHelpers => {
        const { form } = arrayHelpers;

        const groupData = form.values[group].sort((a, b) => a.id - b.id);

        return (
          <>
            <div
              className={`${
                includesGroup ? 'includeParams' : 'excludeParams'
              } includeParams ${isEmpty(groupData) && 'off'}`}
            >
              {groupData.map((ruleSet, index) => {
                const emptyRuleSet = ruleSet.rules[0]?.length === 0;
                const emptyInclude = initialForm.incParams.length === 0;
                const firstExclude = group === excludes && index === 0;
                const moreExclude = initialForm.excParams.length > 1;
                const ruleSetIndex = index;

                return !emptyRuleSet ? (
                  <div key={index}>
                    <FieldArray name={`${group}[${index}].rules`}>
                      {fieldArrayHelpers => (
                        <div className="ruleSet">
                          {((includesGroup && index !== 0) ||
                            (group === excludes && !emptyInclude) ||
                            (moreExclude && index !== 0)) && (
                            <p
                              className={`sectionLink ${
                                firstExclude ? 'firstLink' : ''
                              }`}
                            >
                              {firstExclude ? 'ALSO...' : 'AND...'}
                            </p>
                          )}

                          <div className="mainParams">
                            <p className="paramsTitle">
                              <span key={1} className="bold">
                                {includesGroup ? 'Include' : 'Exclude'}
                                &nbsp;
                              </span>
                              <span>
                                people who match at least ONE of the following
                              </span>
                              {group === 'incParams' &&
                                ((initialForm.incParams.length > 0 &&
                                  initialForm.excParams.length > 0) ||
                                  initialForm.incParams.length > 1) && (
                                  <Tooltip
                                    key={2}
                                    direction="top"
                                    tipText="Remove this set."
                                    render={() => (
                                      <i
                                        className="fas fa-trash-alt"
                                        onClick={() =>
                                          removeSet({
                                            form: fieldArrayHelpers.form,
                                            group,
                                            index,
                                          })
                                        }
                                      />
                                    )}
                                    setInitialForm
                                  />
                                )}
                              {group === 'excParams' &&
                                ((initialForm.excParams.length > 0 &&
                                  initialForm.incParams.length > 0) ||
                                  initialForm.excParams.length > 1) && (
                                  <Tooltip
                                    key={2}
                                    direction="top"
                                    tipText="Remove this set."
                                    render={() => (
                                      <i
                                        className="fas fa-trash-alt"
                                        onClick={() =>
                                          removeSet({
                                            form: fieldArrayHelpers.form,
                                            group,
                                            index,
                                          })
                                        }
                                      />
                                    )}
                                    setInitialForm
                                  />
                                )}
                            </p>

                            <div className="params">
                              {buildParams({
                                ruleSetIndex,
                                rules: form.values[group][index]?.rules,
                                form,
                                name: fieldArrayHelpers.name,
                              })}
                            </div>

                            <InputFeedback name={fieldArrayHelpers.name} />

                            <div className="addParams">
                              <ActionZone
                                text="Add Data Param"
                                callback={() =>
                                  fieldArrayHelpers.push(initParam)
                                }
                              />
                            </div>
                          </div>
                        </div>
                      )}
                    </FieldArray>
                  </div>
                ) : (
                  <React.Fragment key={index}></React.Fragment>
                );
              })}
            </div>

            <div className="addSet">
              <ActionZone
                text={`Add New ${
                  includesGroup ? 'Inclusion' : 'Exclusion'
                } Set`}
                iconColor={colors.secondary900}
                callback={() => addSet(form, group)}
              />
            </div>
          </>
        );
      }}
    </FieldArray>
  );
}
