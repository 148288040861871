import React from "react";

function Checked({height = 25, style = {}}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={height}
      height={height}
      fill="none"
      viewBox="0 0 45 45"
      style={{...style}}
    >
      <path
        fill="#0BC196"
        d="M41.25 22.5c0 10.355-8.395 18.75-18.75 18.75S3.75 32.855 3.75 22.5 12.145 3.75 22.5 3.75s18.75 8.395 18.75 18.75z"
        opacity="0.2"
      ></path>
      <path
        fill="#089171"
        d="M32.424 13.675L19.703 26.412l-5.252-5.237-2.652 2.651 7.907 7.888 15.37-15.388-2.652-2.651z"
      ></path>
    </svg>
  );
}

export default Checked;
