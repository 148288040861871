import { cloneDeep } from 'lodash';
import { paramTemplateTypes } from 'scenes/audiences/NewAudience/ParamTemplates/ParamTemplates';
import * as audienceSchema from 'modules/audiences/audiencesSchema';
import * as yup from 'yup';

const includes = 'incParams';
const excludes = 'excParams';

const initParam = paramTemplateTypes[0];

export const filterAudiences = ({ result, entities }) => {
  if (!result || !result.length) return [];
  return result.map(id => entities[audienceSchema.default.key][id]);
};

export const parseOperator = operator => {
  const operators = {
    ge: 'Greater/Equal to',
    le: 'Less/Equal to',
    gt: 'Greater than',
    lt: 'Less than',
    eq: 'Equal to',
    ne: 'Not equal to',
    // eq: 'On',
    // le: 'On or before',
    // lt: 'Before',
    // ge: 'On or after',
    // gt: 'After',
    // ne: 'Not on',
    // eq: 'Is',
    // ne: 'Is not',
    con: 'Contains',
    starts: 'Starts with',
  };

  return operators[operator] || operator;
};

export const setInitialAudienceForm = () => ({
  name: '',
  type: 'Data Params',
  incParams: [],
  excParams: [],
  rule_sets: [],
});

const rulesSchema = yup.array().of(
  yup.object().shape({
    value_type: yup.string(),
    name: yup.string(),
    value: yup
      .string()
      .required('Field required.')
      .when('value_type', {
        is: 'date',
        then: yup
          .string()
          .test(
            'is-date',
            'Date required.',
            val => new Date(val) !== 'Invalid Date'
          ),
      })
      .when(['value_type', 'kv_relational_op'], {
        is: (type, op) =>
          (type === 'int' || type === 'str' || type === '[str]') &&
          (op === 'eq' || op === 'ne'),
        then: () => yup.string().nullable(),
        otherwise: () => yup.string().nullable().required('Value required.'),
      }),
    key: yup.string().required('Key required.'),
  })
);

export const setAudienceValidationSchema = () =>
  yup.object().shape({
    name: yup.string().required('Name is required.'),
    type: yup
      .string()
      .matches(/(Data Params)/, 'Only Data Params is supported currently.'),
    incParams: yup.array().of(
      yup.object().shape({
        rules: rulesSchema,
      })
    ),
    excParams: yup.array().of(
      yup.object().shape({
        rules: rulesSchema,
      })
    ),
  });

export const loadAudience = ({ audience }) => {
  const incParamSets = [
    ...audience.rule_sets.filter(ruleSet => ruleSet.logical_op_type === true),
  ];

  const excParamSets = [
    ...audience.rule_sets.filter(ruleSet => ruleSet.logical_op_type === false),
  ];

  return { incParamSets, excParamSets };
};

export const buildAudience = ({
  audience,
  incParams,
  excParams,
  company_id,
}) => {
  const state_rs = [...incParams, ...excParams];
  incParams.forEach(ruleSet => {
    const emptyRuleSet = ruleSet.rules[0] === false;

    if (!emptyRuleSet) {
      ruleSet.rules.forEach(rule => {
        if (typeof rule.value == 'number') {
          rule.value = `${rule.value}`;
        } else if (rule.value === '') {
          rule.value = null;
        }
      });
    }
  });

  excParams.forEach(ruleSet => {
    const emptyRuleSet = ruleSet.rules[0] === false;

    if (!emptyRuleSet) {
      ruleSet.rules.forEach(rule => {
        if (typeof rule.value == 'number') {
          rule.value = `${rule.value}`;
        } else if (rule.value === '') {
          rule.value = null;
        }
      });
    }
  });

  return {
    payload: {
      ...audience,
      company_id: company_id,
      rule_sets: state_rs,
    },
  };
};

const includesGroup = group => group === includes;

export const addSet = ({ form, group }) => {
  const newSet = {
    logical_op_type: includesGroup(group),
    rules: [initParam],
  };

  const workingValues = [...form.values[group], newSet];
  const ruleSets = [...form.values.rule_sets, newSet];
  const newForm = {
    ...form.values,
    [group]: cloneDeep(workingValues),
    rule_sets: cloneDeep(ruleSets),
  };

  return { newForm };
};

export const removeSet = ({ form, group, index }) => {
  const ruleSetGroup = form.values[group];

  ruleSetGroup.splice(index, 1);

  const ruleSets = [...form.values[includes], ...form.values[excludes]];
  const newForm = {
    ...form.values,
    [group]: cloneDeep(ruleSetGroup),
    rule_sets: cloneDeep(ruleSets),
  };

  return { newForm };
};

export const removeParam = ({
  initialForm,
  ruleSetGroup,
  ruleSetIndex,
  ruleIndex,
  rules,
  form,
  group,
}) => {
  const defaultParam =
    (group === includes &&
      ruleSetGroup.length === 1 &&
      ruleSetGroup[0].rules.length === 1 &&
      initialForm.incParams.length > 0 &&
      initialForm.excParams.length === 0) ||
    (group === excludes &&
      ruleSetGroup.length === 1 &&
      ruleSetGroup[0].rules.length === 1 &&
      initialForm.incParams.length === 0 &&
      initialForm.excParams.length > 0);

  if (defaultParam) {
    // clear param value if it only has one data
    rules[ruleIndex].value = '';
  } else {
    rules.splice(ruleIndex, 1);
    if (rules.length === 0) ruleSetGroup.splice(ruleSetIndex, 1);
  }

  rules.forEach(rule => {
    if (!rule.value) {
      rule.value = '';
    }
  });

  const ruleSets = [...form.values[includes], ...form.values[excludes]];
  const newForm = {
    ...form.values,
    [group]: cloneDeep(ruleSetGroup),
    rule_sets: cloneDeep(ruleSets),
  };

  return { newForm };
};
