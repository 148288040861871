import { apiActions } from 'modules/api/apiDuck'; 
import schema from './companyParamsSchema';

export const getCompanyParams = (
  params,
  successCallback,
  failureCallback,
  type
) => {
  return apiActions.apiAction({
    path: `company_params`,
    params,
    type,
    normalize: schema,
    method: 'GET',
    onSuccess: pageData => {
      return () => {
        if (successCallback) successCallback(pageData);
      };
    },
    onFailure: error => {
      return () => {
        if (failureCallback) failureCallback(error);
      };
    },
  });
};

export const getCompanyParam = (id, type) => {
  return apiActions.apiAction({
    path: `company_params/${id}`,
    type,
    method: 'GET',
    // normalize: schema,
  });
};

export const createCompanyParam = (configParam, type, doneCallback, failureCallback) => {
  return apiActions.apiAction({
    path: 'company_params',
    type,
    method: 'POST',
    body: JSON.stringify(configParam),
    onSuccess: res => () => doneCallback(res),
    onFailure: error => !!failureCallback && failureCallback(error)
  });
};

export const updateCompanyParam = (
  id,
  companyParam,
  type,
  successCallback,
  failureCallback
) => {
  return apiActions.apiAction({
    path: `company_params/${id}`,
    type,
    method: 'PUT',
    body: JSON.stringify(companyParam),
    onSuccess: response => {
      return () => {
        if (successCallback) successCallback(response.data);
      };
    },
    onFailure: error => {
      return () => {
        if (failureCallback) failureCallback(error);
      };
    },
    meta: { id },
  });
};
