import { createSelector } from 'reselect';
import { isEmpty } from 'lodash';
import { createApiErrorSelector } from '../api/apiDuck';
import * as actions from './audiencesActions';
import schema from './audiencesSchema';
import { constants } from './audiencesReducer';

const getUnorderedAudiencesByCompanyId = state =>
  state.modules.audiences[constants.STATE_KEY_AUDIENCES_BY_COMPANY_ID];

export const getAudsByCompanyId = createSelector(
  [getUnorderedAudiencesByCompanyId],
  page => {
    if (page == null) return [];
    return {
      has_next: page.has_next,
      has_previous: page.has_previous,
      total_pages: page.total_pages,
      current_page: page.current_page,
      data:
        !isEmpty(page.data) &&
        page.data.result.map(id => page.data.entities[schema.key][id]),
    };
  }
);

export const isAudienceLoading = state =>
  state.modules.audiences[constants.STATE_KEY_AUDIENCE_LOADING];
export const isAudiencesLoading = state =>
  state.modules.audiences[constants.STATE_KEY_AUDIENCES_LOADING];
export const getPageData = createSelector(
  [getUnorderedAudiencesByCompanyId],
  page => page
);
export const getApiError = createApiErrorSelector([
  actions.getAudience.type,
  actions.getAudiences.type,
  actions.createAudience.type,
  actions.updateAudience.type,
  actions.deleteAudience.type,
]);
export const getExistingAudience = state =>
  state.modules.audiences[constants.STATE_KEY_AUDIENCE];
export const getCreatedAudience = state =>
  state.modules.audiences[constants.STATE_KEY_CREATED_AUDIENCE];
export const getUpdatedAudience = state =>
  state.modules.audiences[constants.STATE_KEY_UPDATED_AUDIENCE];
export const getSort = state =>
  state.modules.audiences[constants.STATE_KEY_SORT];
