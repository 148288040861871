import React, { useEffect, useState, useContext } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  Flex,
  Box,
  IconButton,
  Button,
} from '@chakra-ui/react';
import { EditorContext, useEditor } from 'components/Canvas';
import Icons from '../Icons';
import { getVarKeys } from './utils';
import VarsForm from './VarsForm';
import Preview from './Preview';
import { isObjectURL, remoteImageToFile } from 'helpers/editor';
import DotLoader from 'react-spinners/ClipLoader';
import PreviewDoubleSided from './PreviewDoubleSided';

const STEPS = {
  NOT_STARTED: 'NOT_STARTED',
  STARTED: 'STARTED',
  HAS_VARS: 'HAS_VARS',
  NO_VARS: 'NO_VARS',
  PREVIEW_LOADING: 'PREVIEW_LOADING',
  PREVIEW: 'PREVIEW',
  PREVIEW_DOUBLE_SIDED: 'PREVIEW_DOUBLE_SIDED',
  ERROR: 'ERROR',
};

function PreviewModal({ isOpen, onClose, getTemplatePreview }) {
  const [options, setOptions] = useState({
    step: STEPS.NOT_STARTED,
    varKeys: [],
    previewImage: null,
    previewImages: [],
  });
  const editorContext = useContext(EditorContext);
  const editor = useEditor();
  const imagePages = [];

  useEffect(() => {
    if (editor) {
      const template = editor.exportToJSON();
      const varKeys = getVarKeys(template);
      setOptions({
        step: STEPS.STARTED,
        varKeys,
      });
      if (varKeys.length) {
        setOptions({
          step: STEPS.HAS_VARS,
          varKeys,
        });
      } else {
        setOptions({
          step: STEPS.NO_VARS,
          varKeys,
        });
      }
    }
  }, [editor]);

  useEffect(() => {
    if (options.step === STEPS.NO_VARS) {
      buildPreview({});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options]);

  const downloadImage = () => {
    if (options.previewImage) {
      const a = document.createElement('a');
      a.target = '_blank';
      a.href = options.previewImage;
      a.download = 'preview_template.png';
      a.click();
    }
    return false;
  };

  const buildPreview = async params => {
    setOptions({ ...options, step: STEPS.PREVIEW_LOADING });
    const exportedTemplate = editor.exportToJSON();
    const payload = {
      template: { ...exportedTemplate, name: 'Preview' },
      params: params,
    };

    if (!editorContext.doubleSided) {
      const url = exportedTemplate.bg_img?.original_url;
      if (url && isObjectURL(url)) {
        const blob = await fetch(url).then(r => r.blob());
        const fileName = editor.handlers.frameHandler.backgroundFileName;
        const file = new File([blob], fileName, { type: blob.type });
        payload.bg_img = file;
      }

      const templatePreview = await new Promise(resolve => {
        getTemplatePreview(payload, data => resolve(data));
      });
      if (templatePreview) {
        setOptions({
          ...options,
          step: STEPS.PREVIEW,
          previewImage: `data:image/jpg;base64,${templatePreview?.img_base64}`,
        });
      } else setOptions({ ...options, step: STEPS.ERROR });
    }

    if (editorContext.doubleSided) {
      const adTemplatePages = payload.template.ad_template_pages;

      for (let i = 0; i < adTemplatePages.length; i++) {
        const url = adTemplatePages[i].bg_img?.original_url;
        const fileName = adTemplatePages[i].bg_img?.fileName;

        // set canvas objects and get the metadata
        payload.template.ad_template_vars = adTemplatePages[i].ad_template_vars;
        payload.template.metadata = adTemplatePages[i].metadata;

        // set the bg_img in case exist
        if (url && isObjectURL(url)) {
          const blob = await fetch(url).then(r => r.blob());
          const file = new File(
            [blob],
            fileName + '.' + blob.type.split('/')[1],
            { type: blob.type }
          );
          payload.bg_img = file;
          payload.template.bg_img = adTemplatePages[i].bg_img;
        } else if (url) {
          const file = await remoteImageToFile(url);
          payload.template.bg_img = adTemplatePages[i].bg_img;
          payload.bg_img = file;
        }

        const templatePreview = await new Promise(resolve => {
          getTemplatePreview(payload, data => resolve(data));
        });

        imagePages.push(`data:image/jpg;base64,${templatePreview?.img_base64}`);

        // clean field for loop
        payload.template.bg_img = null;
        delete payload.bg_img;
      }

      // update status modal
      if (imagePages) {
        setOptions({
          ...options,
          step: STEPS.PREVIEW_DOUBLE_SIDED,
          previewImages: imagePages,
        });
      } else {
        setOptions({
          ...options,
          step: STEPS.ERROR,
        });
      }
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay sx={{ overflow: 'hidden' }} />
      <ModalContent
        sx={{
          maxWidth: 'max-content',
          minWidth: '480px',
          paddingBottom: '1.5rem',
        }}
      >
        <Flex
          sx={{
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '0.5rem 1.5rem 0',
          }}
        >
          <Box fontWeight="600">Template preview</Box>
          <Box>
            {options.step === STEPS.PREVIEW && (
              <IconButton
                onClick={downloadImage}
                color="#000000"
                borderRadius="0"
                variant="ghost"
                icon={<Icons.Download size={20} />}
              />
            )}
            <IconButton
              color="#000000"
              borderRadius="0"
              variant="ghost"
              icon={<Icons.Cross size={20} />}
              onClick={onClose}
            />
          </Box>
        </Flex>
        <Box>
          {
            {
              [STEPS.NOT_STARTED]: <div>NOT STARTED</div>,
              [STEPS.STARTED]: <div>STARTED</div>,
              [STEPS.PREVIEW_LOADING]: (
                <Flex justifyContent="center" padding="2rem">
                  <DotLoader size="32px" />
                </Flex>
              ),
              [STEPS.HAS_VARS]: (
                <VarsForm
                  varKeys={options.varKeys}
                  buildTemplate={buildPreview}
                />
              ),
              [STEPS.PREVIEW]: <Preview previewImage={options.previewImage} />,
              [STEPS.PREVIEW_DOUBLE_SIDED]: (
                <PreviewDoubleSided previewImages={options.previewImages} />
              ),
              [STEPS.ERROR]: (
                <>
                  <Flex
                    padding="1.5rem"
                    textAlign="center"
                    alignItems="center"
                    justifyContent="center"
                    fontSize="14px"
                  >
                    An error occurred trying to generate the preview. Please try
                    again.
                  </Flex>
                  <Box display="flex" justifyContent="center">
                    <Button
                      onClick={onClose}
                      size="sm"
                      colorScheme="blackAlpha"
                      width={100}
                    >
                      OK
                    </Button>
                  </Box>
                </>
              ),
            }[options.step]
          }
        </Box>
      </ModalContent>
    </Modal>
  );
}

export default PreviewModal;
