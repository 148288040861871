import { fabric } from 'fabric';

/**
 * Conditional textbox
 * Will add additional functionalities to textbox
 */
export class ConditionalTextObject extends fabric.Textbox {
  static type = 'ConditionalText';
  label = '';
  
  validateText() {
    if (!this.text) {
      this.canvas.remove(this);
    }
  }
  initialize(text, options) {
    options.editable = false;
    this.label = options.label;
    super.initialize(text, options);
    this.on('modified', () => {
      this.validateText();
    });

    this.on('mousewheel', function () {
      this.canvas.renderAll();
      this._renderControls(this.canvas.contextTop, {
        hasControls: false,
      });
    });

    this.on('mouseup', e => {
      if (e.button === 3) {
        const canvasEl = document.getElementById('canvas');
        const canvasPosition = {
          left: canvasEl?.getBoundingClientRect().left,
          top: canvasEl?.getBoundingClientRect().top,
        };
        const eventData = {
          object: this,
          visible: true,
          position: {
            left: canvasPosition.left + e.pointer.x + 8,
            top: canvasPosition.top + e.pointer.y - 24,
          },
        };
        this.canvas.fire('object:rightclicked', eventData);
        this.canvas.setActiveObject(this);
      }
    });

    return this;
  }
  // allows width of box to be set regardless of text value, adds an ellipsis if text is longer than width
  _wrapLine(_line, lineIndex, desiredWidth, reservedSpace) {
    const offset = 0;
    let textWidth = this._measureWord(_line, lineIndex, offset);

    if (textWidth > desiredWidth && !this.isEditing) {
        const ellipsis = '…';
        const lengthReducingFactor = desiredWidth / textWidth;
        let length = Math.ceil(_line.length * lengthReducingFactor);
        while (textWidth >= desiredWidth && length > 0) {
            _line = _line.substring(0, length--) + ellipsis;
            textWidth = this._measureWord(_line, lineIndex, offset);
        }
    }

    return super._wrapLine(_line, lineIndex, desiredWidth, reservedSpace);
  }
  toObject(propertiesToInclude = []) {
    return super.toObject(propertiesToInclude, {
      text: this.text,
      clipPath: this.clipPath,
      label: this.label,
    });
  }
  toJSON(propertiesToInclude = []) {
    return super.toObject(propertiesToInclude, {
      text: this.text,
      clipPath: this.clipPath,
      label: this.label,
    });
  }

  static fromObject(options, callback) {
    const { text, ...textOptions } = options;
    return callback && callback(new fabric.ConditionalText(text, textOptions));
  }
}

fabric.ConditionalText = fabric.util.createClass(ConditionalTextObject, {
  type: ConditionalTextObject.type,
});
fabric.ConditionalText.fromObject = ConditionalTextObject.fromObject;
