import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Formik, Field, Form } from 'formik';
import * as yup from 'yup';
import * as usersActions from 'modules/users/usersActions';
import Tooltip from 'components/Tooltip/Tooltip';
import Button from 'components/Button/Button';
import InputFeedback from 'components/InputFeedback/InputFeedback';
import colors from 'theme/Colors.module.scss';
import './UserInvite.scss';

const schema = yup.object().shape({
  email: yup
    .string()
    .email('Invalid email.')
    .required('Email is required.')
    .trim(),
});

export default props => {
  const dispatch = useDispatch();
  const defaults = {
    email: '',
  };
  const [invitations, setInvitations] = useState([]);

  const addInvitation = invitation => {
    setInvitations(invitations => invitations.concat(invitation));
  };

  const handleSubmit = (values, form) => {
    const { email } = values;

    dispatch(
      usersActions.invite(email, invitation => {
        addInvitation(invitation);

        form.setSubmitting(false);
        form.setValues(defaults);
        form.setTouched({});
      })
    );
  };

  return (
    <div className="user-invite">
      <div className="rail rail-rule">
        <Button
          type="button"
          text="Back"
          className="backBtn"
          textColor={colors.slate}
          icon="back"
          btnStyle="transparent"
          url="/users"
        />
      </div>
      <Formik
        enableReinitialize={true}
        initialValues={defaults}
        validationSchema={schema}
        onSubmit={handleSubmit}
      >
        {form => {
          const { isSubmitting } = form;

          return (
            <Form id="userInviteForm">
              <div className="formTitle">
                <h1>Invite user</h1>
              </div>
              <div className="formContainer">
                <label id="email" htmlFor="email">
                  <h4 className="title labelWithTooltip">
                    Email &nbsp;
                    <Tooltip
                      tipText="An email with an invitation to join will be sent to the provided address."
                      direction="top"
                    />
                  </h4>
                </label>
                <div className="formRow">
                  <Field type="email" name="email" />
                  <Button
                    type="submit"
                    text="Send Invitation"
                    btnStyle="solid"
                    disabled={isSubmitting}
                    textColor={colors.white}
                    hoverColor={colors.secondary900}
                    icon="paper-plane"
                    iconColor={colors.white}
                    className="buttonInlineEnd"
                  />
                </div>
                <InputFeedback name="email" />
                {invitations.length > 0 && (
                  <>
                    <hr className="divider" />
                    <p>You've invited:</p>
                    <ul>
                      {invitations.map((invitation, index) => {
                        return <li key={index}>{invitation.email}</li>;
                      })}
                    </ul>
                  </>
                )}
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};
