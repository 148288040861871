import { fabric } from 'fabric';
import {
  fabricObjects,
  SCALE_FACTOR,
} from 'components/Canvas/common/constants';
import { loadImageFromURL } from './loader';

class ObjectToFabric {
  async run(item, sizeFormat, frameOptions, frame) {
    const objectType = this.getObjectType(item);
    let object;

    switch (objectType) {
      case fabricObjects.STATIC_TEXT:
        object = await this.staticText(item, sizeFormat, frameOptions, frame);
        break;
      case fabricObjects.DYNAMIC_TEXT_OLD:
      case fabricObjects.DYNAMIC_TEXT:
        object = await this.dynamicText(item, sizeFormat, frameOptions, frame);
        break;
      case fabricObjects.DYNAMIC_IMAGE:
      case fabricObjects.DYNAMIC_QR:
      case fabricObjects.STATIC_BARCODE:
      case fabricObjects.DYNAMIC_BARCODE:
      case fabricObjects.STATIC_QR:
        object = await this.dynamicImage(item, sizeFormat, frameOptions, frame);
        break;
      case fabricObjects.STATIC_IMAGE:
        object = await this.staticImage(item, sizeFormat, frameOptions, frame);
        break;
      case fabricObjects.SHAPE:
        object = await this.staticShape(item, sizeFormat, frameOptions, frame);
        break;
      case fabricObjects.CONDITIONAL_TEXT:
        object = await this.conditionalText(
          item,
          sizeFormat,
          frameOptions,
          frame
        );
        break;
      case fabricObjects.CONDITIONAL_IMAGE:
        object = await this.conditionalImage(
          item,
          sizeFormat,
          frameOptions,
          frame
        );
        break;
      case fabricObjects.CONDITIONAL_QR:
        object = await this.conditionalQr(
          item,
          sizeFormat,
          frameOptions,
          frame
        );
        break;
      default:
    }
    if (!object) {
      console.log(`UNABLE TO LOAD OBJECT: ${objectType}`);
    }

    return object;
  }

  staticText(item, sizeFormat, options, frame) {
    return new Promise((resolve, reject) => {
      try {
        const baseOptions = this.getBaseOptions(item, sizeFormat, options);
        const metadata = item.metadata;
        const text = metadata.value ? metadata.value : 'Default text';
        const {
          textAlign,
          fontFamily,
          fontSize,
          fontWeight,
          charSpacing,
          lineHeight,
        } = metadata;

        const textOptions = {
          ...baseOptions,
          ...(textAlign && { textAlign }),
          ...(fontFamily && { fontFamily }),
          ...(fontSize && { fontSize: SCALE_FACTOR * fontSize }),
          ...(fontWeight && { fontWeight }),
          ...(charSpacing && { charSpacing }),
          ...(lineHeight && { lineHeight }),
          initialWidth: baseOptions.width,
        };
        const element = new fabric.StaticText(text, textOptions);

        element.clipPath = frame;

        resolve(element);
      } catch (err) {
        reject(err);
      }
    });
  }

  dynamicText(item, sizeFormat, options, frame) {
    return new Promise((resolve, reject) => {
      try {
        const baseOptions = this.getBaseOptions(item, sizeFormat, options);
        const metadata = item.metadata;
        const text = metadata.template ? metadata.template : 'Default text';
        const {
          textAlign,
          fontFamily,
          fontSize,
          fontWeight,
          lineHeight,
          charSpacing,
          keyValues,
          fontStyle,
          scaleX,
          scaleY,
        } = metadata;

        const textOptions = {
          ...baseOptions,
          keyValues: keyValues ? keyValues : [],
          ...(text && { text }),
          ...(textAlign && { textAlign }),
          ...(fontFamily && { fontFamily }),
          ...(fontSize && { fontSize: fontSize }),
          ...(fontWeight && { fontWeight }),
          ...(charSpacing && { charSpacing }),
          ...(lineHeight && { lineHeight }),
          ...(fontStyle && { fontStyle }),
          ...(scaleX && { scaleX }),
          ...(scaleY && { scaleY }),
          initialWidth: baseOptions.width,
        };
        const element = new fabric.DynamicText(textOptions);

        element.clipPath = frame;

        resolve(element);
      } catch (err) {
        reject(err);
      }
    });
  }

  dynamicImage(item, sizeFormat, options, frame) {
    return new Promise((resolve, reject) => {
      try {
        const { res_type, metadata, var_type } = item;
        const baseOptions = this.getBaseOptions(item, sizeFormat, options);
        const { keyValues } = metadata;
        const element = new fabric.DynamicImage({
          ...baseOptions,
          keys: item.keys,
          keyValues: keyValues ? keyValues : [],
          subtype: res_type,
          var_type,
          ...(res_type === 'qrc' && {
            width: metadata.run_scaleToWidth
              ? metadata.run_scaleToWidth * SCALE_FACTOR
              : metadata.width,
            height: metadata.run_scaleToWidth
              ? metadata.run_scaleToWidth * SCALE_FACTOR
              : metadata.height,
          }),
        });

        element.clipPath = frame;

        resolve(element);
      } catch (err) {
        reject(err);
      }
    });
  }

  staticImage(item, sizeFormat, options, frame) {
    return new Promise(async (resolve, reject) => {
      try {
        const { res_type } = item;
        let baseOptions = this.getBaseOptions(item, sizeFormat, options);
        const src = item.metadata.url;
        const image = await loadImageFromURL(src);

        if (baseOptions.metadata.imported && baseOptions.metadata.angle) {
          baseOptions.originX = 'center';
          baseOptions.originY = 'center';
          baseOptions.top += baseOptions.height / 2;
          baseOptions.left += baseOptions.width / 2;
        }

        const element = new fabric.StaticImage(image, {
          ...baseOptions,
          subtype: res_type,
          frame,
        });

        const width = image.width;
        const height = image.height;

        let scaleX = SCALE_FACTOR;
        let scaleY = SCALE_FACTOR;

        if (item?.metadata?.imported) {
          scaleX = (item.size_x * scaleX) / width;
          scaleY = (item.size_y * scaleY) / height;
        }

        element.set({
          scaleX,
          scaleY,
          width,
          height,
        });

        element.clipPath = frame;

        resolve(element);
      } catch (err) {
        reject(err);
      }
    });
  }

  staticShape(item, sizeFormat, options, frame) {
    return new Promise(async (resolve, reject) => {
      try {
        const { res_type } = item;
        const baseOptions = this.getBaseOptions(item, sizeFormat, options);
        const element = new fabric.Path(item.metadata.path, {
          ...baseOptions,
          subtype: res_type,
        });
        element.set({
          scaleX: SCALE_FACTOR,
          scaleY: SCALE_FACTOR,
        });

        element.clipPath = frame;

        element.on('mousewheel', function () {
          this.canvas.renderAll();
          this._renderControls(this.canvas.contextTop, {
            hasControls: false,
          });
        });

        resolve(element);
      } catch (err) {
        reject(err);
      }
    });
  }

  conditionalText(item, sizeFormat, options, frame) {
    return new Promise((resolve, reject) => {
      try {
        const baseOptions = this.getBaseOptions(item, sizeFormat, options);
        const metadata = item.metadata;
        const text = metadata.template ?? 'Conditional template';
        const {
          textAlign,
          fontFamily,
          fontSize,
          fontWeight,
          charSpacing,
          lineHeight,
        } = metadata;

        const textOptions = {
          ...baseOptions,
          ...(textAlign && { textAlign }),
          ...(fontFamily && { fontFamily }),
          ...(fontSize && { fontSize: SCALE_FACTOR * fontSize }),
          ...(fontWeight && { fontWeight }),
          ...(charSpacing && { charSpacing }),
          ...(lineHeight && { lineHeight }),
          keys: item.keys,
        };
        const element = new fabric.ConditionalText(text, textOptions);

        element.clipPath = frame;

        resolve(element);
      } catch (err) {
        reject(err);
      }
    });
  }

  conditionalImage(item, sizeFormat, options, frame) {
    // return new Promise((resolve, reject) => {
    //   try {
    //     const { res_type, metadata, var_type } = item;
    //     const baseOptions = this.getBaseOptions(item, sizeFormat, options);
    //     const { keyValues } = metadata;
    //     const element = new fabric.ConditionalImage({
    //       ...baseOptions,
    //       keys: item.keys,
    //       keyValues: keyValues ? keyValues : [],
    //       subtype: res_type,
    //       var_type,
    //       ...(res_type === 'qrc' && {
    //         width: metadata.run_scaleToWidth
    //           ? metadata.run_scaleToWidth * SCALE_FACTOR
    //           : metadata.width,
    //         height: metadata.run_scaleToWidth
    //           ? metadata.run_scaleToWidth * SCALE_FACTOR
    //           : metadata.height,
    //       }),
    //     });

    //     element.clipPath = frame;

    //     resolve(element);
    //   } catch (err) {
    //     reject(err);
    //   }
    // });
    return new Promise((resolve, reject) => {
      try {
        const { res_type, var_type } = item;
        const baseOptions = this.getBaseOptions(item, sizeFormat, options);
        const element = new fabric.ConditionalImage({
          ...baseOptions,
          keys: item.keys,
          subtype: res_type,
          var_type,
        });
        element.clipPath = frame;

        resolve(element);
      } catch (err) {
        reject(err);
      }
    });
  }

  conditionalQr(item, sizeFormat, options, frame) {
    return new Promise((resolve, reject) => {
      try {
        const { res_type, var_type } = item;
        const baseOptions = this.getBaseOptions(item, sizeFormat, options);
        const element = new fabric.ConditionalQr({
          ...baseOptions,
          keys: item.keys,
          subtype: res_type,
          var_type,
        });
        element.clipPath = frame;

        resolve(element);
      } catch (err) {
        reject(err);
      }
    });
  }

  getBaseOptions(item, sizeFormat, options) {
    const isPortrait = sizeFormat.isPortrait;
    const { id, pos_x, pos_y, size_x, size_y, scaleX, scaleY, label } = item;
    let metadata = item.metadata ? item.metadata : {};
    const { fill, angle, originX, originY, height, width, opacity } = metadata;

    let baseOptions = {
      id,
      angle: angle ? angle : 0,
      top:
        options.top +
        (isPortrait
          ? pos_y * SCALE_FACTOR
          : (sizeFormat.pixelWidth - pos_x) * SCALE_FACTOR),
      left:
        options.left +
        (isPortrait ? pos_x * SCALE_FACTOR : pos_y * SCALE_FACTOR),
      width: size_x ? size_x * SCALE_FACTOR : width,
      height: size_y ? size_y * SCALE_FACTOR : height,
      originX: originX || 'left',
      originY: originY || 'top',
      scaleX: scaleX || 1,
      scaleY: scaleY || 1,
      fill: fill || '#000000',
      opacity: opacity ? opacity : 1,
      metadata,
      label
    };
    return baseOptions;
  }

  getObjectType(item) {
    const { res_type, var_type } = item;
    const objectType = `${res_type}:${var_type}`;
    return objectType;
  }
}

export default new ObjectToFabric();
