import { apiActions } from '../api/apiDuck';
import { pageParams } from 'helpers/paramHelpers';

export const getAllUsers = (params, successCallback, failureCallback, type) => {
  return apiActions.apiAction({
    path: `accounts`,
    params: {
      ...params,
      ...pageParams(params),
    },
    type,
    method: 'GET',
    onSuccess: response => {
      if (successCallback) {
        successCallback(response.data);
      }
    },
    onFailure: failureCallback,
  });
};

export const getUserById = (id, successCallback, failureCallback, type) => {
  return apiActions.apiAction({
    path: `accounts/${id}`,
    type,
    method: 'GET',
    onSuccess: response => {
      if (successCallback) {
        successCallback(response.progpack_account);
      }
    },
    onFailure: failureCallback,
  });
};

export const createUser = (payload, successCallback, failureCallback, type) => {
  return apiActions.apiAction({
    path: 'accounts',
    type,
    method: 'POST',
    body: JSON.stringify(payload),
    onSuccess: response => {
      if (successCallback) {
        successCallback(response.data);
      }
    },
    onFailure: failureCallback,
  });
};

export const updateUserById = (
  id,
  data,
  successCallback,
  failureCallback,
  type
) => {
  return apiActions.apiAction({
    path: `accounts/${id}`,
    type,
    method: 'PUT',
    body: JSON.stringify(data),
    onSuccess: response => {
      if (successCallback) {
        successCallback(response.data);
      }
    },
    onFailure: failureCallback,
  });
};

export const removeUserById = (id, type) => {
  return apiActions.apiAction({
    path: `accounts/${id}`,
    type,
    method: 'DELETE',
  });
};

export const searchInvitations = (
  params,
  successCallback,
  failureCallback,
  type
) => {
  return apiActions.apiAction({
    path: `invitations`,
    params: {
      ...params,
      ...pageParams(params),
    },
    type,
    method: 'GET',
    onSuccess: response => {
      if (successCallback) {
        successCallback(response.data);
      }
    },
    onFailure: failureCallback,
  });
};

export const invite = (email, successCallback, type) => {
  return apiActions.apiAction({
    path: `invitations`,
    type,
    method: 'POST',
    body: JSON.stringify({ email }),
    onSuccess: response => () => successCallback(response.data),
  });
};

export const checkInvitationToken = (token, successCallback, type) => {
  return apiActions.apiAction({
    path: `invitations/check`,
    params: {
      token,
    },
    type,
    method: 'GET',
    onSuccess: response => () => successCallback(response.data),
  });
};
