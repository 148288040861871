import React from 'react';
import { withRouter } from 'react-router-dom';
import { useFlag } from 'flags';

import { useHistory, useLocation } from 'react-router-dom';
import TableRowCard from 'components/TableRowCard/TableRowCard';
import './AnalyticsRow.scss';
import {
  Button,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  Box,
  Tooltip,
  useDisclosure,
} from '@chakra-ui/react';
import ImageModal from 'components/ImageModal/ImageModal';
import placeholderListViewImg from 'static/images/placeholder-list-view.png';
import { formatAOV, formatPercentage } from 'helpers/campaignHelper';
import { ChevronDownIcon } from '@chakra-ui/icons';
import colors from 'theme/Colors.module.scss';

const AnalyticsRow = props => {
  const {
    draggableProvided,
    draggableSnapshot,
    headers,
    focus,
    sort,
    content: {
      enabled,
      start_date,
      end_date,
      name,
      id,
      packages,
      template,
      conversions,
      conversionRate,
      average,
      qrScans,
      qrIx,
      // avg_days_to_next_order,
      // revenue,
      // revenue_package,
    },
  } = props;

  const { push } = useHistory();
  const location = useLocation();
  const to = `/campaigns/${id}`;

  const isReadOnly = useFlag(['features', 'isReadOnly']);
  const startDate = start_date ? start_date : '--/--/--';
  const endDate = end_date ? end_date : '--/--/--';

  const {
    isOpen: isModalOpen,
    onOpen: onModalOpen,
    onClose: onModalClose,
  } = useDisclosure();

  const getIndicatorColor = () => {
    if (enabled) {
      return colors.status00;
    }
    return colors.neutral200;
  };

  const redirect = () =>
    push(to, { from: location.pathname.concat(location.search) });

  const cardProps = {
    ...(draggableProvided &&
      !isReadOnly && {
        rootRef: draggableProvided.innerRef,
        rootProps: {
          ...draggableProvided.draggableProps,
          ...draggableProvided.dragHandleProps,
        },
      }),
    focus,
    sort,
    indicatorColor: getIndicatorColor(),
  };

  return (
    <TableRowCard
      {...cardProps}
      className="analytics-row"
      forceFixedTableLayout={draggableSnapshot?.isDragging}
      headers={headers}
    >
      <td className={`analytics-name`}>
        <div className="analytics-name-container">
          <div className="analytics-name__img-wrapper">
            <ImageModal
              isModalOpen={isModalOpen}
              onModalClose={onModalClose}
              imageURL={template ? template : placeholderListViewImg}
            />
            <Box
              className="analytics-name__img"
              bgImage={template ? template : placeholderListViewImg}
              onClick={() => onModalOpen()}
            />
          </div>
          <div
            className="analytics-name__text ellipsis-span"
            onClick={to && redirect}
          >
            {name || '--'}
          </div>
        </div>
      </td>
      <td className="analytics-td">{`${startDate} - ${endDate}`}</td>
      <td className="analytics-td">{Number(packages).toLocaleString()}</td>
      <td className="analytics-td analytics-qrc">
        {qrScans === 0 && qrScans}
        {qrScans > 0 && (
          <Menu>
            <MenuButton
              className="qrc__menu-btn"
              onClick={e => e.stopPropagation()}
              as={Button}
              rightIcon={<ChevronDownIcon />}
            >
              {Number(qrScans).toLocaleString()}
            </MenuButton>
            <MenuList className="qrc__menu-list">
              {qrIx.map((item, index) => (
                <MenuItem
                  className="qrc__menu-item"
                  key={index}
                  onClick={e => {
                    e.stopPropagation();
                    window.open(item.get('qr')[1], '_blank');
                  }}
                >
                  <Tooltip
                    bg="linear-gradient(0deg, rgba(241, 95, 36, 0.20) 0%, rgba(241, 95, 36, 0.20) 100%), #FFF"
                    label={<Text color="#F15F24">{item.get('qr')[1]}</Text>}
                    aria-label="A tooltip"
                  >
                    <Box>
                      <Text as="u" fontSize={'12px'} fontWeight={400}>
                        {item.get('qr')[0]}
                      </Text>
                      <Text fontSize={'12px'} fontWeight={600}>
                        {item.get('amount')}
                      </Text>
                    </Box>
                  </Tooltip>
                </MenuItem>
              ))}
            </MenuList>
          </Menu>
        )}
      </td>
      <td className="analytics-td">{Number(conversions).toLocaleString()}</td>
      <td className="analytics-td">{formatPercentage(conversionRate)}%</td>
      <td className="analytics-td">{formatAOV(average)}</td>
      {/* temporary hidden untill the data is available */}
      {/* <td className="analytics-td">{avg_days_to_next_order || `--`}</td>
        <td className="analytics-td">{revenue || `--`}</td>
        <td className="analytics-td">{revenue_package || `--`}</td> */}
    </TableRowCard>
  );
};

export default withRouter(AnalyticsRow);
