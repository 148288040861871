import { apiConstants, apiActions } from 'modules/api/apiDuck';
import { isEmpty } from 'lodash';
import 'whatwg-fetch';
import * as appSelectors from 'modules/app/appSelectors';

const handleCallback = (dispatch, callback, args) => {
  if (!callback) return;

  const action = callback.apply(null, args);

  if (action) {
    dispatch(action);
  }
};

const handleSuccess = (dispatch, onSuccess, type, action, data) => {
  action = Object.assign(action, { payload: data });
  dispatch(apiActions.apiSuccess(type, action));
  handleCallback(dispatch, onSuccess, [data]);
};

const handleFailure = (dispatch, onFailure, type, action, error, resp) => {
  action = Object.assign(action, { payload: error, error: true });
  if (resp && resp.status) {
    // resp may not exist in all failure scenarios
    switch (resp.status) {
      case 401:
        dispatch(apiActions.apiUnauthorized(type, action));
        break;
      case 422:
      case 400:
      default:
        // For debugging
        // resp.json()
        // .then(function({ error }) {
        //    console.error(error.message, error.data);
        //  });

        dispatch(apiActions.apiFailure(type, action));
    }
  } else {
    dispatch(apiActions.apiFailure(type, action));
  }
  dispatch(apiActions.apiError(type, action));
  handleCallback(dispatch, onFailure, [error]);
};

const isApiCall = action =>
  action && action.type && action.type.startsWith(`${apiConstants.API}.`);
const apiEndpoint = (path, paramsObject) => {
  const params = new URLSearchParams();

  Object.entries(paramsObject).forEach(entry => {
    const [key, value] = entry;
    let stringValue = value;

    if (value === undefined) {
      return;
    } else if (value === null) {
      stringValue = '';
    } else if (Array.isArray(value)) {
      stringValue = value.join(',');
    }

    params.set(key, stringValue);
  });

  const paramsString = params.toString();

  return `${path.startsWith('http') ? path : '/api/v1/' + path}${
    paramsString ? `?${paramsString}` : ''
  }`;
};
const mergeHeaders = (headers, state, isUpload) => {
  var def = {};
  if (!isUpload) {
    def['Content-Type'] = 'application/json';
  }

  // TODO: explore moving to authMiddleware
  const loginState = appSelectors.getToken(state);
  if (!isEmpty(loginState)) {
    def.Authorization = `Bearer ${loginState}`;
  }
  return Object.assign(def, headers);
};

const apiMiddleware =
  ({ dispatch, getState }) =>
  next =>
  action => {
    if (!isApiCall(action)) return next(action);

    const {
      path,
      params,
      method,
      body,
      headers, // {'key': 'value'}
      isUpload = false,
      credentials, // 'omit', 'same-origin', or 'include'
      onSuccess,
      onFailure,
      onComplete,
      type,
    } = action.payload;

    if (type) {
      dispatch(apiActions.apiStart(type, action));
    }

    fetch(apiEndpoint(path, params || {}), {
      method: method,
      body: body,
      headers: mergeHeaders(headers, getState(), isUpload),
      credentials: credentials,
    })
      .then(resp => {
        handleCallback(dispatch, onComplete);
        if (resp.status === 204) {
          // no content response
          handleSuccess(dispatch, onSuccess, type, action);
        } else {
          if (resp.ok) {
            const isJson = action.payload.json !== false;
            (isJson ? resp.json() : resp.text())
              .then(data => {
                handleSuccess(dispatch, onSuccess, type, action, data);
              })
              .catch(e => {
                handleFailure(dispatch, onFailure, type, action, e);
              });
          } else {
            handleFailure(dispatch, onFailure, type, action, resp);
          }
        }
      })
      .catch(e => {
        // clear out meta since error to prevent any pipeline middleware processing
        console.log('error occurred while handling response:');
        console.log(e);
        handleFailure(dispatch, onFailure, type, action, e);
      })
      .finally(() => type && dispatch(apiActions.apiEnd(type)));
  };

export default apiMiddleware;
