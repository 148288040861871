import React from 'react';

function AddNewPage({ size }) {
  return (
    <svg
      width="24px"
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.14185 5.75C3.14185 4.23122 4.37306 3 5.89185 3H18.3918C19.9106 3 21.1418 4.23122 21.1418 5.75V12.1153C20.6807 11.8027 20.1772 11.5479 19.6418 11.3611V5.75C19.6418 5.05964 19.0822 4.5 18.3918 4.5H5.89185C5.20149 4.5 4.64185 5.05964 4.64185 5.75V18.25C4.64185 18.9404 5.20149 19.5 5.89185 19.5H11.3135C11.4858 20.0335 11.7253 20.5368 12.0218 21H5.89185C4.37306 21 3.14185 19.7688 3.14185 18.25V5.75Z"
        fill="#212121"
      />
      <path
        d="M23 17.5C23 14.4624 20.5376 12 17.5 12C14.4624 12 12 14.4624 12 17.5C12 20.5376 14.4624 23 17.5 23C20.5376 23 23 20.5376 23 17.5ZM18.0006 18L18.0011 20.5035C18.0011 20.7797 17.7773 21.0035 17.5011 21.0035C17.225 21.0035 17.0011 20.7797 17.0011 20.5035L17.0006 18H14.4961C14.22 18 13.9961 17.7762 13.9961 17.5C13.9961 17.2239 14.22 17 14.4961 17H17.0005L17 14.4993C17 14.2231 17.2239 13.9993 17.5 13.9993C17.7761 13.9993 18 14.2231 18 14.4993L18.0005 17H20.503C20.7792 17 21.003 17.2239 21.003 17.5C21.003 17.7762 20.7792 18 20.503 18H18.0006Z"
        fill="#212121"
      />
    </svg>
  );
}

export default AddNewPage;
