export const DEFAULT_FONT = {
  company_id: null,
  format: 'ttf',
  id: 0,
  name: 'Arial',
  url: 'https://dzqi3b5s5jl7u.cloudfront.net/fonts/arial.ttf',
};

export const DEFAULT_FORMAT_SIZE = {
  format_height: '8.0',
  format_unit: 'in',
  format_width: '4.0',
  id: 0,
  label: '4.0x8.0 in',
  pixel_height: 2400,
  pixel_width: 1200,
};
