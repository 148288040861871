import { pageParams } from 'helpers/paramHelpers';
import campaignsSchema from './campaignsSchema';
import { apiActions } from '../api/apiDuck';

export const getCampaigns = (
  params,
  successCallback,
  failureCallback,
  type
) => {
  return apiActions.apiAction({
    path: `campaigns`,
    params: {
      ...params,
      ...pageParams(params),
    },
    type,
    method: 'GET',
    normalize: campaignsSchema.schema,
    onSuccess: pageData => {
      if (successCallback) successCallback(pageData);
    },
    onFailure: failureCallback,
  });
};

export const getCampaign = (id, type) => {
  return apiActions.apiAction({
    path: `campaigns/${id}`,
    type,
    method: 'GET',
    normalize: campaignsSchema.schema,
  });
};

export const getRetailers = type => {
  return apiActions.apiAction({
    path: `retailers`,
    type,
    method: 'GET',
  });
};

export const submitToRetailers = (id, retailers, type, doneCallback) => {
  return apiActions.apiAction({
    path: `campaigns/${id}/submit`,
    type,
    method: 'POST',
    body: JSON.stringify(retailers),
    onSuccess: () => () => doneCallback(),
  });
};

export const uploadImage = (formData, type) => {
  return apiActions.apiAction({
    path: `creatives`,
    type,
    method: 'POST',
    body: formData,
  });
};

export const createCampaign = (
  campaign,
  type,
  doneCallback,
  failureCallback
) => {
  campaign.campaign.source_type = 'internal';
  return apiActions.apiAction({
    path: 'campaigns',
    type,
    method: 'POST',
    body: JSON.stringify(campaign),
    onSuccess: res => () => doneCallback(res),
    onFailure: error => !!failureCallback && failureCallback(error),
  });
};

export const updateCampaign = (
  id,
  campaign,
  type,
  doneCallback,
  failureCallback
) => {
  return apiActions.apiAction({
    path: `campaigns/${id}`,
    type,
    method: 'PUT',
    body: JSON.stringify(campaign),
    onSuccess: res => () => doneCallback(res),
    onFailure: error => () => failureCallback && failureCallback(error),
    meta: { id },
  });
};

export const deleteCampaign = (id, type) => {
  return apiActions.apiAction({
    path: `campaigns/${id}`,
    method: 'DELETE',
    type,
    meta: { id },
  });
};
