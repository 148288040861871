import SearchParameterChip from 'components/SearchParameterChip/SearchParameterChip';
import DirectionIcon from 'components/DirectionIcon/DirectionIcon';

import './SearchParameterSortChip.scss';

export default ({ header, direction, onReverse, ...props }) => {
  return (
    <SearchParameterChip {...props}>
      <button className="SearchParameterSortChip-reverse" onClick={onReverse}>
        <DirectionIcon type={header.sortIcon} direction={direction} />
      </button>
      <span className="SearchParameterChip-value">{header.name}</span>
    </SearchParameterChip>
  );
};
