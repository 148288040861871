import React from 'react';
import { Flex, Text } from '@chakra-ui/react';
import DotSpinner from 'components/DotSpinner/DotSpinner';
import ButtonComponent from './ButtonComponent';

export default function ResolveSizeFormat({
  setModalState,
  sm,
  templateFile,
  setTemplateFile,
  getSizeFormats,
  setStep,
}) {
  const [matchSizeFormat, setMatchSizeFormat] = React.useState(false);
  const [sizeFormats, setSizeFormats] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(true);
  const loaded = React.useRef(false);

  const onContinue = () => {
    if (!matchSizeFormat) {
      const templateSizeFormat =
        templateFile.size_format.pixel_width *
        templateFile.size_format.pixel_height;

      const closest = sizeFormats.reduce((prev, curr) => {
        const currentSizeFormat = curr.pixel_width * curr.pixel_height;
        const prevSizeFormat = prev.pixel_width * prev.pixel_height;
        return Math.abs(currentSizeFormat - templateSizeFormat) <
          Math.abs(prevSizeFormat - templateSizeFormat)
          ? curr
          : prev;
      });

      if (closest) {
        setTemplateFile({
          ...templateFile,
          size_format: closest,
          size_format_id: closest.id,
        });
        setStep(4);
      }
    }
  };

  const handleInitialLoad = async () => {
    const { data: sizeFormatsList } = await new Promise(resolve => {
      getSizeFormats(data => {
        resolve(data);
      });
    });
    if (sizeFormatsList) setSizeFormats(sizeFormatsList);
    const currentSizeFormat = templateFile.size_format;
    let found;
    // Find by pixel_height
    sizeFormatsList.forEach(sf => {
      if (
        sf.pixel_height === currentSizeFormat.pixel_height &&
        sf.pixel_width === currentSizeFormat.pixel_width
      ) {
        found = sf;
      }
    });
    if (!found) {
      sizeFormatsList.forEach(sf => {
        if (
          sf.pixel_width === currentSizeFormat.pixel_height &&
          sf.pixel_height === currentSizeFormat.pixel_width
        ) {
          found = sf;
        }
      });
    }
    setMatchSizeFormat(found);
    loaded.current = true;
    if (found) {
      setTemplateFile({
        ...templateFile,
        size_format: found,
        size_format_id: found.id,
      });
      setStep(4);
    } else {
      setModalState({
        title: 'Dimension size mismatch',
        description:
          'Your design file doesn’t match any of your available size formats.',
      });
      setIsLoading(false);
    }
  };

  React.useEffect(() => {
    setModalState({ title: 'Loading', description: '' });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (templateFile && !loaded.current) handleInitialLoad();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [templateFile]);

  return (
    <>
      {isLoading ? (
        <Flex justify="center" minW={sm ? '500px' : '250px'} mt="2.5rem">
          <DotSpinner />
        </Flex>
      ) : (
        <Flex direction="column" align="center">
          <Text
            fontSize="18px"
            fontWeight={400}
            color="#293854e5"
            m="10px 0 0"
            maxW="500px"
            textAlign="center"
          >
            We’ve automatically selected the best fit, but you may need to
            adjust your design to fit.
          </Text>
          <ButtonComponent
            p={sm ? '0.65rem 3rem' : '0.65rem 1rem'}
            m="2rem auto 0"
            onClick={onContinue}
          >
            OK
          </ButtonComponent>
        </Flex>
      )}
    </>
  );
}
