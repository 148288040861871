import { loadImageFromURL } from 'components/Canvas/utils/loader';
import _ from 'lodash';
// import { SCALE_FACTOR } from './canvasHelpers';

export function isObjectURL(fileString) {
  return fileString.startsWith('blob');
}

export function isBase64Image(fileString) {
  return fileString.startsWith('data:');
}

export async function templateHasChanged(server, local, bgUrl) {
  if (!local && !server) {
    return false;
  }
  if (!server && local.ad_template_vars) {
    if (local && local.bg_img && local.bg_img.original_url) {
      return true;
    }
    const hasVars =
      local.ad_template_vars.length || local.metadata.bg_color !== '#ffffff';
    return !!hasVars;
  } else if (!server) {
    return false;
  }
  const serverVars = [...server.ad_template_vars].filter(
    el => !(el.res_type === 'qrc' && el.var_type === 'static')
  );
  const localVars = [...local.ad_template_vars];

  let hasChanged = false;

  // compare base options
  let serverBase = _.omit(server, [
    'archived_at',
    'campaigns_count',
    'name',
    'size_format',
    'id',
    'company_id',
    'ad_template_vars',
    'bg_img',
    'size_format_id',
    'page_num',
  ]);
  const localBase = _.omit(local, [
    'ad_template_vars',
    'bg_img',
    'size_format_id',
    'id',
    'page_num',
  ]);
  let isBaseEqual = _.isEqual(serverBase, localBase);

  if (
    localBase?.metadata?.bg_color === '#ffffff' &&
    (!serverBase.metadata || !serverBase?.metadata?.bg_color)
  ) {
    const localBaseClone = { ...localBase };
    delete localBaseClone.metadata.bg_color;

    if (!Object.keys(localBaseClone.metadata).length) {
      localBaseClone.metadata = null;
    }

    isBaseEqual = _.isEqual(serverBase, localBaseClone);
  } else if (
    typeof localBase.metadata === 'object' &&
    localBase.metadata !== null &&
    !Object.keys(localBase.metadata).length &&
    serverBase.metadata === null
  ) {
    const localBaseClone = { ...localBase };
    localBaseClone.metadata = null;
    isBaseEqual = _.isEqual(serverBase, localBaseClone);
  } else if (
    typeof serverBase.metadata === 'object' &&
    serverBase.metadata !== null &&
    !Object.keys(serverBase.metadata).length &&
    localBase.metadata === null
  ) {
    const serverBaseClone = { ...serverBase };
    serverBase.metadata = null;
    isBaseEqual = _.isEqual(serverBaseClone, localBase);
  }

  if (!isBaseEqual) {
    hasChanged = true;
    return hasChanged;
  }

  if (serverVars.length !== localVars.length) {
    hasChanged = true;
    return hasChanged;
  }

  //order localVars by id
  serverVars
    .map(({ id }) => id)
    .reduce(
      (prev, curr) => ({
        ...prev,
        [curr]: localVars.find(({ id }) => curr === id),
      }),
      {}
    );

  // compare vars
  for (let i = 0; i < serverVars.length; i++) {
    const serverVarMetadata = _.omit(
      serverVars[i].metadata,
      '__session_var_id'
    );
    const baseServerVar = _.omit(serverVars[i], [
      'id',
      'ad_template_id',
      // 'z_index',
      'ad_template_page_id',
      '__fontFilename',
      'metadata.imported',
    ]);
    const serverVar = { ...baseServerVar, metadata: serverVarMetadata };

    const localVarMetadata = _.omit(
      localVars[i].metadata,
      '__session_var_id',
      'imported'
    );
    const baseLocalVar = _.omit(localVars[i], ['run_scaleToWidth', 'id']);
    const localVar = { ...baseLocalVar, metadata: localVarMetadata };

    const localMetadata = { ...localVar.metadata };
    const serverMetadata = { ...serverVar.metadata };
    //Omit rotation if there is no rotation
    if (!localVar.metadata.angle && !serverVar.metadata.angle) {
      delete localMetadata.angle;
      delete serverMetadata.angle;
    }
    //Omit opacity if opacity has not changed
    if (
      localVar.metadata.opacity === 1 &&
      serverVar.metadata.opacity === undefined
    ) {
      delete localMetadata.opacity;
    }

    localVar.metadata = localMetadata;
    serverVar.metadata = serverMetadata;

    if (
      localVar.res_type !== serverVar.res_type ||
      localVar.var_type !== serverVar.var_type
    )
      return true;

    if (localVar.res_type === 'img' && localVar.var_type === 'static') {
      const { width, height } = await loadImageFromURL(localVar.metadata.url);
      serverVar.size_x = width;
      serverVar.size_y = height;
      // let scaleX = (serverVar.size_x * SCALE_FACTOR) / width;
      // let scaleY = (serverVar.size_y * SCALE_FACTOR) / height;
    }

    //Text validations
    if (localVar.res_type === 'txt_template' || localVar.res_type === 'txt') {
      delete localVar.size_y;
      delete serverVar.size_y;

      delete serverVar.metadata.__fontFilename;
      delete localVar.metadata.__fontFilename;
      delete localVar.metadata.type;
      delete serverVar.metadata.type;
      delete localVar.metadata.lineHeight;
      delete serverVar.metadata.lineHeight;

      if (
        serverVar.metadata.fontStyle === '' &&
        localVar.metadata.fontStyle === 'normal'
      ) {
        delete localVar.metadata.fontStyle;
        delete serverVar.metadata.fontStyle;
      }

      if (!localVar.keyValues?.length && !serverVar.metadata.keyValues) {
        delete localVar.metadata.keyValues;
      }

      if (!serverVar.keyValues?.length && !localVar.metadata.keyValues) {
        delete localVar.metadata.keyValues;
        delete serverVar.metadata.keyValues;
      }

      if (Math.round(serverVar.size_x) === Math.round(localVar.size_x)) {
        serverVar.size_x = Math.round(serverVar.size_x);
        localVar.size_x = Math.round(localVar.size_x);
      }
    }

    //shapes validation
    if (serverVar.res_type === 'shape') {
      delete serverVar.size_x;
      delete serverVar.size_y;
      delete localVar.size_x;
      delete localVar.size_y;
    }

    //Set number values to three decimals
    Object.keys(serverVar).map(
      key =>
        typeof serverVar[key] === 'number' &&
        (serverVar[key] = parseFloat(serverVar[key].toFixed(3)))
    );
    Object.keys(localVar).map(
      key =>
        typeof localVar[key] === 'number' &&
        (localVar[key] = parseFloat(localVar[key].toFixed(3)))
    );

    const isEqual = _.isEqual(serverVar, localVar);

    if (!isEqual) {
      hasChanged = true;
      break;
    }
  }

  // compare backgrounds
  const serverBackgroundUrl = server?.bg_img?.original_url;
  const localBackogrundUrl = local?.bg_img?.original_url;

  if (
    serverBackgroundUrl !== localBackogrundUrl &&
    serverBackgroundUrl !== bgUrl
  ) {
    hasChanged = true;
  }

  return hasChanged;
}

export function base64ImageToFile(str) {
  // extract content type and base64 payload from original string
  const pos = str.indexOf(';base64,');
  const type = str.substring(5, pos);
  const b64 = str.substr(pos + 8);

  // decode base64
  const imageContent = atob(b64);

  // create an ArrayBuffer and a view (as unsigned 8-bit)
  const buffer = new ArrayBuffer(imageContent.length);
  const view = new Uint8Array(buffer);

  // fill the view, using the decoded base64
  for (let n = 0; n < imageContent.length; n++) {
    view[n] = imageContent.charCodeAt(n);
  }

  const filename = `background.${type.split('/')[1]}`;
  // convert ArrayBuffer to Blob
  let blob = new Blob([buffer], { type: type });
  const file = new File([blob], filename);
  return file;
}

export const remoteImageToFile = async original_url => {
  const relativeURL = () => {
    const relativePath = `/uploads/${original_url.split('/uploads')[1]}`;

    if (relativePath.includes('?')) {
      const corePath = relativePath.split('?');
      return corePath[0];
    }

    return relativePath;
  };
  const pathSplit = relativeURL().split('/');
  const extSplit = pathSplit[pathSplit.length - 1].split('.');
  const fileName = extSplit[0];
  const extension = extSplit[extSplit.length - 1];

  const res = await fetch(original_url, {
    cache: 'no-cache',
  });
  const data = await res.blob();
  const file = new File([data], `${fileName}.${extension}`, {
    type: `image/${extension || 'jpeg'}`,
  });

  return file;
};

export const awaiter = () => {
  return new Promise(resolve => {
    setTimeout(() => resolve(), 1000);
  });
};

const getFontStatus = font => {
  return document.fonts.check(`12px ${font}`);
};

export const loadFont = font => {
  const isFontReady = getFontStatus(font.name);
  return new Promise(resolve => {
    if (!isFontReady) {
      const fontFace = new FontFace(font.name, `url(${font.url})`);
      fontFace
        .load()
        .then(loadedFont => {
          document.fonts.add(loadedFont);
          fontFace.loaded
            .then(() => {
              resolve(true);
            })
            .catch(err => console.log(err));
        })
        .catch(err => console.log(err))
        .finally(() => {
          resolve(true);
        });
    } else {
      resolve(true);
    }
  });
};
