// @ts-nocheck
import { fabric } from 'fabric';
import BaseHandler from './BaseHandler';
class PersonalizationHandler extends BaseHandler {
  constructor(props) {
    super(props);
    this.init();
  }

  init() {
    //@ts-ignore
    fabric.util.addListener(
      document.getElementsByClassName('upper-canvas')[0],
      'contextmenu',
      function (e) {
        e.preventDefault();
      }
    );
    fabric.Object.prototype.transparentCorners = false;
    fabric.Object.prototype.cornerColor = '#3782F7';
    fabric.Object.prototype.cornerStyle = 'circle';
    fabric.Object.prototype.borderColor = '#3782F7';
    fabric.Object.prototype.cornerStrokeColor = '#ffffff';
    fabric.Object.prototype.cornerSize = 12;
    fabric.Object.prototype.borderScaleFactor = 2.75;
    // fabric.Object.prototype.borderOpacityWhenMoving = 0;
    fabric.Object.prototype.borderOpacity = 1;

    this.canvas.selectionColor = 'rgba(55, 130, 247, 0.15)';
    this.canvas.selectionBorderColor = '#3782F7';
    this.canvas.selectionLineWidth = 1.5;
    this.canvas.on('selection:created', ev => {
      const objects = this.canvas.getActiveObjects();
      if (objects.length > 1) {
        ev.target.setControlsVisibility({
          mt: false,
          mb: false,
          mr: false,
          ml: false,
        });
        ev.target.borderDashArray = [7];
      }
    });

    this.canvas.on('mouse:over', e => {
      if (e.target) {
        if (e.target !== this.canvas.getActiveObject()) {
          e.target._renderControls(e.target.canvas.contextTop, {
            hasControls: false,
          });
        }
      }
    });

    this.canvas.on('mouse:out', e => {
      if (e.target) {
        e.target.canvas.clearContext(e.target.canvas.contextTop);
        this.canvas.renderAll();
      }
    });
  }
}

export default PersonalizationHandler;
