import React from 'react';
import colors from 'theme/Colors.module.scss';

export default props => {
  const { data, useTotal } = props;

  const hasValues = data.values && data.values.length > 0;
  const value =
    hasValues && (useTotal ? data.total : data.values[data.values.length - 1]);

  const color = hasValues ? colors.slate : colors.greyDisabled;
  const lineStyle = { borderTop: `0.125rem solid ${color}` };
  const valueStyle = { color };

  const renderValue = () => {
    if (hasValues) return value.toLocaleString();
    return 'N/A';
  };

  return (
    <>
      <div className="dataCardLegend">
        <p className="title">{data.name}</p>
        <p className="value" style={valueStyle}>
          {renderValue()}
        </p>
        <p className="line" style={lineStyle}>
          &nbsp;
        </p>
      </div>
    </>
  );
};
