import React from 'react';
import { Button } from '@chakra-ui/react';
import colors from 'theme/Colors.module.scss';

export const NextButton = ({ text = 'Next', ...props }) => (
  <Button
    h="40px"
    bgColor={colors.secondary600}
    borderRadius="4px"
    fontWeight="600"
    fontSize="14px"
    color="white"
    textAlign="center"
    {...props}
  >
    {text}
  </Button>
);