import React, { useEffect, useRef, useState } from 'react';
// import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
// import { formatDate } from 'react-day-picker/moment';
import { useFlag } from 'flags';
import classNames from 'classnames';

import { Formik, Form } from 'formik';
import * as yup from 'yup';

// import * as campaignsActions from 'modules/campaigns/campaignsActions';
import { useBreakpointUp } from 'modules/layout/layoutHooks';
import Tooltip from 'components/Tooltip/Tooltip';
import TableRowCard from 'components/TableRowCard/TableRowCard';
import TableCard from 'components/TableCard/TableCard';
import TextField from 'components/TextField/TextField';
import Button from 'components/Button/Button';
import colors from 'theme/Colors.module.scss';
import './CampaignCard.scss';
import * as campaignStatus from '../campaignStatus';
import { getTemplateThumb } from 'helpers/campaignHelper';
// import { campaign } from 'modules/campaigns/campaignsSelectors';

const FORM_SCHEMA = yup.object().shape({
  priority: yup.number().integer().min(1),
});

const CampaignCard = props => {
  const {
    draggableProvided,
    draggableSnapshot,
    headers,
    template,
    focus,
    prependControl,
    content: {
      is_campaign_using,
      enabled,
      status,
      start_date,
      end_date,
      name,
      priority,
      delivery_limit,
      id,
      target_type,
    },
    updateCampaign,
    editingPrioritySymbol,
    onEditingPrioritySymbol,
    onUpdate,
  } = props;
  // const dispatch = useDispatch();
  const isBreakpointSmallOrAbove = useBreakpointUp('sm');
  const isReadOnly = useFlag(['features', 'isReadOnly']);
  const priorityInputRef = useRef();
  const editingPrioritySymbolRef = useRef(Symbol());
  const [editingPriority, setEditingPriority] = useState(false);

  useEffect(() => {
    if (editingPrioritySymbol !== editingPrioritySymbolRef.current) {
      setEditingPriority(false);
    }
  }, [editingPrioritySymbol]);

  const editPriority = () => {
    const symbol = (editingPrioritySymbolRef.current = Symbol());

    if (onEditingPrioritySymbol) {
      onEditingPrioritySymbol(symbol);
    }

    setEditingPriority(true);
  };

  const cancelEditPriority = () => {
    if (onEditingPrioritySymbol) {
      onEditingPrioritySymbol(null);
    }

    setEditingPriority(false);
  };

  const formatUTCDateTime = date => {
    // extract the UTC values from the date object
    const year = date.getUTCFullYear();
    const month = date.getUTCMonth() + 1; // add 1 because getUTCMonth() returns 0-11
    const day = date.getUTCDate();
    let hours = date.getUTCHours();
    const minutes = date.getUTCMinutes();

    // determine if it's AM or PM
    const ampm = hours >= 12 ? 'PM' : 'AM';

    // convert hours from 24-hour format to 12-hour format
    hours = hours % 12;
    hours = hours ? hours : 12; // handle midnight (0 o'clock)

    // pad the values with zeros if they're single digit
    const formattedDay = day < 10 ? `0${day}` : day;
    const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;

    // return the formatted string with AM or PM appended
    return `${month}/${formattedDay}/${year
      .toString()
      .substr(-2)} ${hours}:${formattedMinutes} ${ampm} UTC`;
  };

  // Actions
  // const deleteCampaign = id => dispatch(campaignsActions.deleteCampaign(id));

  const getIndicatorColor = () => {
    if (status === campaignStatus.delivering || is_campaign_using || enabled) {
      return colors.status00;
    }
    if (status === campaignStatus.inactive || !enabled) {
      return colors.greyDisabled;
    }
    if (status === campaignStatus.notDelivering) {
      return colors.status100;
    }
  };

  const startDate = start_date ? formatUTCDateTime(start_date) : '--';
  const endDate = end_date ? formatUTCDateTime(end_date) : '--';
  const cmpTypeStyle = {
    fontSize: '0.7rem',
    color: target_type === 'open' ? colors.primary900 : colors.secondary900,
    marginRight: '1rem',
  };

  const hasTemplateThumb = template && getTemplateThumb(template);
  const renderTemplateTooltip = () => {
    return (
      <div className="campaign-card__template-tooltip">
        <img src={getTemplateThumb(template)} alt="" />
        <label>{template.name}</label>
      </div>
    );
  };

  const renderCardDragHandle = () => {
    return <i className="campaign-card__drag-handle fa fa-grip-vertical" />;
  };

  const renderLegend = () => {
    return (
      <span className="cmpTypeLegend">
        <i className="fas fa-circle" style={cmpTypeStyle} />
      </span>
    );
  };

  const renderName = () => {
    return <div className="cmpName-text">{name || '--'}</div>;
  };

  const handleSubmitSavePriority = async (values, form) => {
    const priority = values.priority ? parseInt(values.priority) : null;

    form.setSubmitting(true);

    try {
      const campaign = await updateCampaign(id, { campaign: { priority } });

      form.setSubmitting(false);
      form.setValues({
        priority: campaign.priority,
      });

      cancelEditPriority();

      if (onUpdate) {
        onUpdate();
      }
    } catch (error) {
      console.error(error);

      form.setSubmitting(false);
    }
  };

  const handleClickEvent = event => {
    event.preventDefault();
    event.stopPropagation();
  };

  const renderPriority = () => {
    if (isReadOnly) return priority || '--';

    return (
      <Formik
        validateOnMount
        enableReinitialize
        initialValues={{ priority: priority?.toString() || '' }}
        validationSchema={FORM_SCHEMA}
        onSubmit={handleSubmitSavePriority}
      >
        {form => {
          const { values, isSubmitting, isValid, dirty } = form;

          return (
            <Form id="campaign-card__form">
              <TextField
                inputRef={priorityInputRef}
                flush={!editingPriority}
                className="campaign-card__priority-textfield"
                placeholder="--"
                name="priority"
                value={editingPriority ? values.priority : priority}
                onChange={form.handleChange}
                onFocus={() => {
                  if (!editingPriority) {
                    editPriority();
                  }
                }}
                disabled={isSubmitting}
                endIcon={(() => {
                  const submitButtonDisabled =
                    isSubmitting || !isValid || !dirty;
                  switch (true) {
                    case !editingPriority:
                      return (
                        <Button
                          key="edit"
                          className="campaign-card__priority-edit-btn"
                          icon="pen"
                          iconColor={colors.slate}
                          btnStyle="transparent"
                          opacity={null}
                          onClick={() => {
                            if (priorityInputRef.current) {
                              priorityInputRef.current.focus();

                              const originalValue =
                                priorityInputRef.current.value;

                              priorityInputRef.current.value = '';
                              priorityInputRef.current.value = originalValue;
                            }

                            editPriority();
                          }}
                        />
                      );
                    case dirty:
                      return (
                        <Button
                          key="submit"
                          className="campaign-card__priority-submit-btn"
                          type="submit"
                          icon="save"
                          iconColor={
                            submitButtonDisabled
                              ? colors.slate
                              : colors.primary900
                          }
                          btnStyle="transparent"
                          disabled={submitButtonDisabled}
                          onClick={() => handleSubmitSavePriority(values, form)}
                        />
                      );
                    default:
                      return (
                        <Button
                          key="cancel-edit"
                          className="campaign-card__priority-cancel-edit-btn"
                          icon="times-circle"
                          iconColor={colors.slate}
                          btnStyle="transparent"
                          opacity={null}
                          onClick={() => {
                            setEditingPriority(false);
                          }}
                        />
                      );
                  }
                })()}
              />
            </Form>
          );
        }}
      </Formik>
    );
  };

  const renderTemplatePreview = () => {
    return (
      template &&
      (hasTemplateThumb ? (
        <Tooltip tipText={renderTemplateTooltip()} direction="left">
          <img
            className="campaign-card__template-preview"
            src={getTemplateThumb(template)}
            alt=""
          />
        </Tooltip>
      ) : (
        <Tooltip tipText={template.name} direction="left" />
      ))
    );
  };

  const renderMobileTemplatePreview = () => {
    return (
      template &&
      (hasTemplateThumb ? (
        <img
          className="campaign-card__template-preview-mobile"
          src={getTemplateThumb(template)}
          alt=""
        />
      ) : (
        <></>
      ))
    );
  };

  const cardProps = {
    ...(draggableProvided &&
      !isReadOnly && {
        rootRef: draggableProvided.innerRef,
        rootProps: {
          ...draggableProvided.draggableProps,
          ...draggableProvided.dragHandleProps,
        },
      }),
    className: classNames('cmpCard', {
      enabled,
    }),
    to: `/campaigns/${id}`,
    focus,
    indicatorColor: getIndicatorColor(),
  };

  if (isBreakpointSmallOrAbove) {
    return (
      <TableRowCard
        {...cardProps}
        forceFixedTableLayout={draggableSnapshot?.isDragging}
        headers={headers}
        prependCells={
          prependControl &&
          !isReadOnly && [
            <td className="bulkActions" key={0}>
              {prependControl}
            </td>,
          ]
        }
      >
        <td className={`cmpName`}>
          {!isReadOnly && renderCardDragHandle()}
          {renderLegend()}
          {renderName()}
        </td>
        <td className="cmpPriority" onClick={handleClickEvent}>
          {renderPriority()}
        </td>
        <td className="cmpStatus">{status || '--'}</td>
        <td className="cmpDeliveries">{delivery_limit || '--'}</td>
        <td className="cmpStart">{startDate || '--'}</td>
        <td className="cmpEnd">{endDate || '--'}</td>
        <td>{renderTemplatePreview() || '--'}</td>

        {/* Re-enable actions column when ready -- see scss file for additional grid comments */}
        {/* Re-enable delete button for debugging and removing test campaigns. */}
        {/* <li className="cmpActions">
            <Button
              icon="delete"
              iconColor={colors.slate}
              btnStyle="transparent"
              callback={e => {
                e.stopPropagation();
                deleteCampaign(id);
              }} />
          </li> */}
      </TableRowCard>
    );
  } else {
    return (
      <TableCard
        {...cardProps}
        forceBlockLayout={draggableSnapshot?.isDragging}
      >
        <tr>
          <td colSpan={2}>
            <div className="cmpName">
              {renderCardDragHandle()}
              <div className="campaign-card__enabled-control">
                {prependControl}
              </div>
              {renderLegend()}
              {renderName()}
            </div>
          </td>
        </tr>
        <tr>
          <td>
            <span className="cmpCardTitle">
              <label>Status:</label> {status || '--'}
            </span>
          </td>
          <td>
            <label>Impressions:</label> {delivery_limit || '--'}
          </td>
        </tr>
        <tr>
          <td>
            <label>Start Date:</label> {startDate || '--'}
          </td>
          <td>
            <label>End Date:</label> {endDate || '--'}
          </td>
        </tr>
        <tr>
          <td>
            <label>Priority:</label> {priority || '--'}
          </td>
          <td>
            <label>Template:</label> {renderMobileTemplatePreview() || '--'}
          </td>
        </tr>
      </TableCard>
    );
  }
};

export default withRouter(CampaignCard);
