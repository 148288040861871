import React, { useEffect } from 'react';
import { Box } from '@chakra-ui/react';
import PanelItems from './PanelItems';
import useTemplateEditorContext from '../../hooks/useTemplateEditorContext';
import useEditorContext from 'components/Canvas/hooks/useEditorContext';

function PanelItem(props) {
  const { activePanel, activeSubMenu, setActiveSubMenu } =
    useTemplateEditorContext();
  const { activeObject } = useEditorContext();

  const { showPanel, isPanelOpen } = props;

  useEffect(() => {
    setActiveSubMenu(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeObject]);

  useEffect(() => {
    if (activeSubMenu === 'FontFamily' && !isPanelOpen) {
      showPanel();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeSubMenu]);

  if (
    (activeSubMenu && activeSubMenu === 'TextTutorial') ||
    activeSubMenu === 'ImageTutorial' ||
    activeSubMenu === 'BackgroundTutorial' ||
    activeSubMenu === 'QRTutorial' ||
    activeSubMenu === 'BarcodeTutorial' ||
    activeSubMenu === 'TemplateTutorial'
  ) {
    const Component = PanelItems[activeSubMenu];

    return (
      <Box
        sx={{
          backgroundColor: '#29303A',
          width: '360px',
          display: isPanelOpen ? 'flex' : 'none',
        }}
      >
        <Component showPanel={showPanel} />
      </Box>
    );
  }
  const Component =
    activeObject && activeSubMenu
      ? PanelItems[activeSubMenu]
      : PanelItems[activePanel];

  return (
    <Box
      sx={{
        backgroundColor: '#29303A',
        width: '360px',
        display: isPanelOpen ? 'flex' : 'none',
      }}
    >
      <Component {...props} showPanel={showPanel} />
    </Box>
  );
}

export default PanelItem;
