import Button from '../../components/Button/Button';
import React, { useRef, useState } from 'react';
import colors from 'theme/Colors.module.scss';
import { Box } from '@chakra-ui/react';
import { useDispatch } from 'react-redux';
import EntityTable from '../../components/EntityTable/EntityTable';
import {
  getUserFonts,
  removeUserFont,
} from '../../modules/templateEditor/templateEditorApi';
import FontCard from './FontIndex/FontCard/FontCard';
import UploadFont from '../../components/UploadUserFont/UploadFont';
import RemoveFontError from './RemoveUserFont/RemoveFont';
import { showMessages } from 'helpers/messageHelpers';
import { useSelector } from 'react-redux';
import * as appSelectors from 'modules/app/appSelectors';
import * as appActions from 'modules/app/appActions';
import { templateEditorConstants } from 'modules/templateEditor/templateEditorDuck';

export default function FontContainer() {
  // const USERS_PERSISTENCE_KEYS = ['search'];
  const [showModal, setShowModal] = useState(false);
  const [showRemoveModal, setShowRemoveModal] = useState(false);
  const [file, setFile] = useState(null);
  // const [fonts, setFonts] = useState([]);
  const dispatch = useDispatch();
  const [refreshSymbol, setRefreshSymbol] = useState(Symbol());
  const fileInput = useRef(null);

  const messages = useSelector(state => appSelectors.messages(state));

  const setMessage = payload => dispatch(appActions.setMessage(payload));
  const clearMessage = index => dispatch(appActions.clearMessage(index));

  const USERS_HEADERS = [
    {
      name: 'Font Name',
      field: 'fullname',
      hasIndicator: true,
      sortable: false,
      sortIcon: 'alpha',
      align: 'left',
      width: '40%',
    },
    {
      name: 'Font Family',
      field: 'family',
      sortable: false,
      sortIcon: 'alpha',
      align: 'left',
      width: '40%',
    },
    {
      name: 'Action',
      sortable: false,
      sortIcon: 'alpha',
      align: 'left',
      width: '20%',
    },
  ];

  const closeUploadFontModal = () => {
    setShowModal(false);
    setFile(null);
    if (fileInput?.current) {
      fileInput.current.value = null;
    }
  };

  const handleFontFamilyChange = res => {
    setRefreshSymbol(Symbol());
  };

  const deleteUserFont = async id => {
    await new Promise(resolve => {
      dispatch(
        removeUserFont(
          id,
          '',
          data => {
            resolve(data);
            handleFontFamilyChange(data);
            setMessage({
              message: 'Font removed successfully.',
              type: 'success',
            });
          },
          async err => {
            const json = await err.json();
            // console.error(json?.error?.data?.desc || '');
            if (json?.error?.code === 2003) setShowRemoveModal(true);
            else
              setMessage({
                message:
                  'There was an error trying to remove the font, please try again.',
                type: 'error',
              });
          }
        )
      );
    });
  };

  const onSuccess = () =>
    setMessage({ message: 'Font upload successfully', type: 'success' });

  return (
    <Box>
      <Box
        className="fontHeader"
        sx={{ borderBottom: '0.0625rem solid #e2e2e2;', padding: '10px' }}
      >
        <Box className="left">
          <Button
            type="button"
            icon="add"
            text="Add New Font"
            textColor={colors.slate}
            btnStyle="transparent"
            onClick={() => {
              fileInput.current.value = '';
              fileInput?.current?.click();
            }}
          />
          <input
            hidden
            type="file"
            ref={fileInput}
            accept=".ttf, .otf"
            multiple={false}
            onChange={ev => {
              setFile(ev.target.files[0]);
              if (ev.target.files[0]) {
                setShowModal(true);
              }
            }}
          />
        </Box>
      </Box>
      <EntityTable
        headers={USERS_HEADERS}
        sortable
        focusable
        scrollable
        // scrollSpacerHeight={65}
        persistable
        entityType="fonts"
        refreshSymbol={refreshSymbol}
        getEntities={async () => {
          const userFonts = await new Promise(resolve => {
            dispatch(
              getUserFonts(templateEditorConstants.GET_USER_FONTS, data =>
                resolve(data)
              )
            );
          });
          return userFonts.data.data.private;
        }}
        renderRow={font => {
          return (
            <FontCard
              key={font.id}
              font={font}
              headers={USERS_HEADERS}
              deleteUserFont={deleteUserFont}
            />
          );
        }}
      />
      <UploadFont
        isOpen={showModal}
        onClose={() => setShowModal(false)}
        closeUploadFontModal={closeUploadFontModal}
        handleFontFamilyChange={handleFontFamilyChange}
        file={file}
        onSuccess={onSuccess}
      />
      <RemoveFontError
        isOpen={showRemoveModal}
        onClose={() => setShowRemoveModal(false)}
      />

      {showMessages(messages, clearMessage)}
    </Box>
  );
}
