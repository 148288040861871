import React from "react"

function Download({ size }) {
    return (
        <svg height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12.0241 17.2498C12.3197 17.2503 12.6124 17.1924 12.8855 17.0794C13.1587 16.9664 13.4068 16.8005 13.6156 16.5913L16.5548 13.6499L15.4943 12.5924L12.7696 15.3178L12.7501 3H11.2501L11.2696 15.3058L8.55463 12.5909L7.49414 13.6499L10.4334 16.5891C10.6419 16.7986 10.8898 16.9648 11.1628 17.0782C11.4358 17.1915 11.7285 17.2499 12.0241 17.2498V17.2498Z" fill="currentColor" />
            <path d="M19.4998 15V18.7499C19.4998 18.9489 19.4208 19.1396 19.2801 19.2803C19.1395 19.4209 18.9487 19.4999 18.7498 19.4999H5.24997C5.05106 19.4999 4.8603 19.4209 4.71965 19.2803C4.579 19.1396 4.49998 18.9489 4.49998 18.7499V15H3V18.7499C3 19.3467 3.23705 19.919 3.659 20.3409C4.08095 20.7629 4.65324 20.9999 5.24997 20.9999H18.7498C19.3465 20.9999 19.9188 20.7629 20.3408 20.3409C20.7627 19.919 20.9998 19.3467 20.9998 18.7499V15H19.4998Z" fill="currentColor" />
        </svg>
    )
}

export default Download