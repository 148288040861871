import React, { useCallback, useState } from 'react';
import { Box, Button, Input, Stack, Flex } from '@chakra-ui/react';
import uniqueId from 'lodash/uniqueId';
import { useEditor } from 'components/Canvas/hooks';
import PanelTitle from './components/PanelTitle';
import ToggleSwitch from 'components/ToggleSwitch/ToggleSwitch';
import colors from 'theme/Colors.module.scss';

function Barcode() {
  const editor = useEditor();
  const [checked, setChecked] = useState(false);
  const [value, setValue] = useState('');

  const addObjectToCanvas = useCallback(() => {
    const options = checked
      ? {
          keyValues: [{ key: '{{' + uniqueId('barcode_') + '}}', value: '' }],
          metadata: { runglobal_scaleToCover: true },
        }
      : { keyValues: [], metadata: { value, runglobal_scaleToCover: true } };
    const objectOptions = {
      ...options,
      type: 'DynamicImage',
      width: 100,
      height: 100,
      subtype: 'barcode',
      backgroundColor: '#bdc3c7',
      metadata: {
        ...options.metadata,
        // unique id to allow us to tie back a newly saved record with it's entity id
        __session_var_id: uniqueId('s'),
      },
    };
    editor.add(objectOptions);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checked, value]);

  return (
    <Box sx={{ flex: 1, marginTop: '1.5rem' }}>
      <PanelTitle title="Click to add to canvas" subMenu="BarcodeTutorial" />
      <Stack spacing={3} sx={{ color: '#ffffff', padding: '1.5rem' }}>
        <Flex gap="0.5rem" alignItems="center">
          <ToggleSwitch
            value={checked}
            onChange={() => setChecked(!checked)}
            color={colors.status00}
          />
          <Box>Dynamic</Box>
        </Flex>
        {!checked && (
          <Input
            name="barcode-value"
            fontSize="14px"
            borderRadius={3}
            style={{ backgroundColor: 'white', color: 'black' }}
            value={value || ''}
            placeholder="Value"
            onChange={e => setValue(e.target.value)}
            _disabled={{
              color: '#666666 !important',
            }}
          />
        )}
        <Button
          w="100%"
          onClick={() => addObjectToCanvas()}
          disabled={!checked && !value}
          size="sm"
          sx={{
            color: 'rgba(255,255,255,0.9)',
            backgroundColor: 'rgba(255,255,255,0.1)',
            height: '48px',
            justifyContent: 'center',
            borderRadius: '4px',
            marginTop: '25px !important',
          }}
          _hover={{
            backgroundColor: 'rgba(255,255,255,0.15)',
          }}
          _active={{
            backgroundColor: 'rgba(255,255,255,0.2)',
          }}
        >
          Add Barcode
        </Button>
      </Stack>
    </Box>
  );
}

export default Barcode;
