import React from 'react';

function Close({ style = {}, height = 20, onClick }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={height}
      height={height}
      fill="none"
      viewBox="0 0 22 22"
      style={style}
      onClick={onClick}
    >
      <path
        fill="#394A64"
        d="M21.5 2.615L19.385.5 11 8.885 2.615.5.5 2.615 8.885 11 .5 19.385 2.615 21.5 11 13.115l8.385 8.385 2.115-2.115L13.115 11 21.5 2.615z"
      ></path>
    </svg>
  );
}

export default Close;
