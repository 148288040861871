import {
  Box,
  Popover,
  PopoverTrigger,
  PopoverContent,
  useDisclosure,
  Stack,
  Flex,
} from '@chakra-ui/react';
import ToggleSwitch from 'components/ToggleSwitch/ToggleSwitch';
import colors from 'theme/Colors.module.scss';
import useEditorContext from 'components/Canvas/hooks/useEditorContext';
import React, { useEffect, useRef, useState, Fragment } from 'react';
import { useEditor } from 'components/Canvas';
import Draggable from 'react-draggable';
import QRCodeMenu from './menus/QRCodeMenu';
import TextMenu from './menus/TextMenu';
import MRUMenu from './menus/MRUMenu';
import BarcodeMenu from './menus/BarcodeMenu';

const varTypes = {
  DEFAULT: 0, // Dynamic Image, Dynamic Text without keys
  TOKEN_SELECTION: 1,
  QR_CODE: 2,
  BARCODE: 3,
};

function KeysMenu() {
  const { onOpen, onClose, isOpen } = useDisclosure({ isOpen: true });

  const containerRef = useRef();

  const editor = useEditor();
  const { keysMenu, setKeysMenu } = useEditorContext();
  const [varType, setVarType] = useState(varTypes.TOKEN_SELECTION);
  const [keyObj, setKeyObj] = useState();
  const [dynSwitchChecked, setDynSwitchChecked] = useState(
    keysMenu?.object?.keyValues.length > 0 || false
  );
  const [isReset, setIsReset] = useState(false);

  useEffect(() => {
    setIsReset(true);

    if (keysMenu) {
      setKeyObj(keysMenu);
      setDynSwitchChecked(keysMenu?.object?.keyValues.length > 0);
    }
    if (keysMenu?.object?.type === 'DynamicText') {
      keysMenu?.key
        ? setVarType(varTypes.DEFAULT)
        : setVarType(varTypes.TOKEN_SELECTION);
    } else if (keysMenu?.object?.subtype === 'img') {
      setVarType(varTypes.DEFAULT);
    } else if (keysMenu?.object?.subtype === 'qrc') {
      setVarType(varTypes.QR_CODE);
    } else if (keysMenu?.object?.subtype === 'barcode') {
      setVarType(varTypes.BARCODE);
    }

    if (keysMenu.visible) onOpen();
    else onClose();

    // reset to false on next tick to inform toggleswitch that animations
    // should occur now if there's a change in value
    setTimeout(() => setIsReset(false), 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [keysMenu]);

  useEffect(() => {
    const canvas = document.getElementById('canvas');
    const container = document.getElementById('undigital-editor-container');

    canvas.addEventListener('mouseup', handleCanvasClick);
    container.addEventListener('mouseup', handleEditorClick);

    return () => {
      canvas?.removeEventListener('mouseup', handleCanvasClick);
      container?.removeEventListener('mouseup', handleEditorClick);
    };
  }, []);

  const handleCanvasClick = e => {
    e.stopPropagation();
  };
  const handleEditorClick = () => {
    document.getElementById('panel-container').click();
  };

  const closeMenu = () => {
    setKeysMenu({ ...keysMenu, visible: false });
  };

  const insertKey = key => {
    editor.handlers.objectsHandler.insertKey(key);
  };

  const setKeyData = keyData => {
    editor.handlers.objectsHandler.setKeyValue(keyData);
  };

  // updates a single key in keyValues
  const updateSingleKey = (key, value) => {
    editor.handlers.objectsHandler.setKeyValue(
      {
        ...keysMenu.key,
        value: value,
        key: key,
      }
    );
  };

  const updateMetadata = (key, value) => {
    editor.handlers.objectsHandler.updateMetadata(key, value);
  };

  const renderMenu = () => {
    switch (varType) {
      case varTypes.DEFAULT:
        return textMenu();
      case varTypes.TOKEN_SELECTION:
        return mruMenu();
      case varTypes.QR_CODE:
        return qrcMenu();
      case varTypes.BARCODE:
        return barcodeMenu();
      default:
        return textMenu();
    }
  };

  const textMenu = () => {
    return (
      <TextMenu
        keyObj={keyObj}
        updateSingleKey={updateSingleKey}
        closeMenu={closeMenu}
      />
    );
  };

  const mruMenu = () => {
    return <MRUMenu insertKey={insertKey} closeMenu={closeMenu} />;
  };

  const qrcMenu = () => {
    return (
      <Fragment>
        {dynamicSwitch('QR Code')}
        <QRCodeMenu
          keyObj={keyObj}
          setKeyData={setKeyData}
          updateMetadata={updateMetadata}
          closeMenu={closeMenu}
          dynSwitchChecked={dynSwitchChecked}
        />
      </Fragment>
    );
  };

  const barcodeMenu = () => {
    return (
      <Fragment>
        {dynamicSwitch('Barcode')}
        <BarcodeMenu
          keyObj={keyObj}
          setKeyData={setKeyData}
          updateMetadata={updateMetadata}
          closeMenu={closeMenu}
          dynSwitchChecked={dynSwitchChecked}
        />
      </Fragment>
    );
  };

  const handleDynSwitchChange = () => setDynSwitchChecked(!dynSwitchChecked);

  const dynamicSwitch = label => {
    return (
      <Stack direction="row" justifyContent="space-between">
        <Box
          sx={{
            fontWeight: 700,
            fontSize: '14px',
            paddingBottom: '1rem',
          }}
        >
          {label}
        </Box>
        <Box>
          <Flex gap="0.5rem" alignItems="center">
            <ToggleSwitch
              value={dynSwitchChecked}
              onChange={handleDynSwitchChange}
              color={colors.status00}
              isInitialValue={isReset}
            />
            <Box sx={{ fontSize: '14px' }}>Dynamic</Box>
          </Flex>
        </Box>
      </Stack>
    );
  };

  return (
    <Draggable cancel="input">
      <Box
        sx={{
          display: `${keysMenu.visible ? 'block' : 'none'}`,
          top: keysMenu.position.top + 20,
          left: keysMenu.position.left - 10,
          position: 'absolute',
          zIndex: 1111,
          background: '#ffffff',
          boxShadow: '0 0.1rem 0.25rem #f5f5f5',
        }}
        ref={containerRef}
      >
        <Popover isOpen={isOpen} onClose={onClose} placement="bottom">
          <PopoverTrigger>
            <Box sx={{ height: '1px', width: '10px' }}></Box>
          </PopoverTrigger>
          <PopoverContent
            borderRadius={0}
            sx={{
              padding: '1.5rem',
              maxH: 450,
              overflowY: 'auto',
            }}
          >
            {renderMenu()}
          </PopoverContent>
        </Popover>
      </Box>
    </Draggable>
  );
}
export default KeysMenu;
