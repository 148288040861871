/* eslint-disable no-useless-computed-key */
import { useState } from 'react';
import classNames from 'classnames';

import './TextField.scss';

export default ({
  ref,
  inputRef,
  className,
  type,
  name,
  value,
  startIcon,
  endIcon,
  placeholder,
  disabled,
  flush,
  onChange,
  onFocus,
  onBlur,
  onClick,
}) => {
  const [focus, setFocus] = useState(false);
  const handleFocus = event => {
    if (onFocus) onFocus(event);

    setFocus(true);
  };

  const handleBlur = event => {
    if (onBlur) onBlur(event);

    setFocus(false);
  };

  return (
    <div
      ref={ref}
      className={classNames(className, 'TextField', {
        ['TextField-hasStartIcon']: !!startIcon,
        ['TextField-hasEndIcon']: !!endIcon,
        ['TextField-hasValue']: !!value,
        ['TextField-focus']: focus,
        ['TextField-flush']: flush,
      })}
      onClick={onClick}
    >
      {startIcon && <div className="TextField-startIcon">{startIcon}</div>}
      <input
        ref={inputRef}
        type={type || 'text'}
        placeholder={placeholder}
        disabled={disabled}
        name={name}
        value={value}
        onChange={onChange}
        onFocus={handleFocus}
        onBlur={handleBlur}
        spellCheck={false}
        autoComplete="off"
      />
      {endIcon && <div className="TextField-endIcon">{endIcon}</div>}
    </div>
  );
};
