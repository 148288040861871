import { TemplateEditorContext } from '../context/TemplateEditorContext';
import { useContext } from 'react';

function useTemplateEditorContext() {
  const {
    activePanel,
    setActivePanel,
    activeSubMenu,
    setActiveSubMenu,
    isFontLoading,
    setIsFontLoading,
    isTemplateLoading,
    setIsTemplateLoading,
    currentTemplate,
    setCurrentTemplate,
    isEditorLoading,
    setIsEditorLoading,
    defaultFont,
    setDefaultFont,
    defaultFormatSize,
    setDefaultFormatSize,
    isEnabledDoubleSideMessage,
    setIsEnabledDoubleSideMessage,
    isEnabledDoubleSide,
    setIsEnabledDoubleSide,
    doubleSidedBgImgUrls,
    setDoubleSidedBgImgUrls,
  } = useContext(TemplateEditorContext);
  return {
    isFontLoading,
    setIsFontLoading,
    activePanel,
    setActivePanel,
    activeSubMenu,
    setActiveSubMenu,
    isTemplateLoading,
    setIsTemplateLoading,
    currentTemplate,
    setCurrentTemplate,
    isEditorLoading,
    setIsEditorLoading,
    defaultFont,
    setDefaultFont,
    defaultFormatSize,
    setDefaultFormatSize,
    isEnabledDoubleSideMessage,
    setIsEnabledDoubleSideMessage,
    isEnabledDoubleSide,
    setIsEnabledDoubleSide,
    doubleSidedBgImgUrls,
    setDoubleSidedBgImgUrls,
  };
}

export default useTemplateEditorContext;
