import React from 'react';
import { Button, Box } from '@chakra-ui/react';
import { ChakraInput } from './ChakraInput';
import { Formik, Form } from 'formik';
import CustomScroller from 'components/CustomScroller';

function VarsForm({ varKeys, buildTemplate }) {
  const getDefaultValues = () => {
    const localKeys = localStorage.getItem('ud_form_local')
      ? JSON.parse(localStorage.getItem('ud_form_local'))
      : {};
    const defaults = {};
    varKeys.forEach(item => {
      const { key } = item;
      defaults[key] = localKeys[key] ? localKeys[key] : item.value;
    });
    return defaults;
  };
  const defaultValues = getDefaultValues();

  return (
    <Box>
      <CustomScroller>
        <Formik
          initialValues={defaultValues}
          onSubmit={(values, actions) => {
            localStorage.setItem('ud_form_local', JSON.stringify(values));
            buildTemplate(values);
          }}
        >
          <Form
            style={{ maxHeight: 'calc(100vh - 80px)', padding: '0 1.5rem' }}
          >
            {varKeys.map(varKey => (
              <ChakraInput
                key={varKey.key}
                name={varKey.key}
                label={varKey.key}
                placeholder={varKey.key}
              />
            ))}
            <Button
              marginTop="1rem"
              borderRadius={0}
              colorScheme="orange"
              backgroundColor="rgb(255, 111, 15)"
              type="submit"
            >
              Build preview
            </Button>
          </Form>
        </Formik>
      </CustomScroller>
    </Box>
  );
}

export default VarsForm;
