import merge from 'lodash/merge';
import { apiBaseUrl } from '../env';
import { isEmpty } from 'lodash';
import ES6Promise from 'es6-promise';
import 'whatwg-fetch';
import * as appSelectors from 'modules/app/appSelectors';
import { apiConstants } from '../modules/api/apiDuck';

ES6Promise.polyfill();

export function apiEndpoint(path) {
  return `${apiBaseUrl}/${path}`;
}

export const handler = fn => {
  const invalidParam = () => {
    throw 'Invalid handler param.';
  };
  const assign = (fn, prop) =>
    typeof fn === 'function'
      ? fn
      : typeof fn === 'object'
      ? fn[prop]
      : typeof fn === 'undefined'
      ? undefined
      : invalidParam();
  return {
    success: assign(fn, 'success'),
    invalid: assign(fn, 'invalid'),
    failure: assign(fn, 'failure'),
    unauthorized: assign(fn, 'unauthorized'),
  };
};

function request(dispatch, type, value, handler) {
  dispatch({ type: [apiConstants.API_REQUEST, type].join('.'), value: value });
  handler && handler();
}

function success(dispatch, type, value, payload, handler) {
  dispatch({
    type: [apiConstants.API_SUCCESS, type].join('.'),
    value: value,
    payload: payload,
  });
  handler && handler();
}

function failure(dispatch, type, value, payload, handler) {
  dispatch({
    type: [apiConstants.API_FAILURE, type].join('.'),
    value: value,
    payload: payload,
  });
  handler && handler();
}

function invalid(dispatch, type, value, payload, handler) {
  dispatch({
    type: [apiConstants.API_INVALID, type].join('.'),
    value: value,
    payload: payload,
  });
  handler && handler();
}

function unauthorized(dispatch, type, value, payload, handler) {
  dispatch({
    type: [apiConstants.API_UNAUTHORIZED, type].join('.'),
    value: value,
    payload: payload,
  });
  handler && handler();
}

function headers(state) {
  const loginState = appSelectors.getToken(state);
  let headers = {
    'Content-Type': 'application/json',
  };

  if (!isEmpty(loginState)) {
    headers.Authorization = `Bearer ${loginState}`;
  }

  return headers;
}

function handleFailure(dispatch, response, type, value, handlers, json = {}) {
  if (response.status === 401) {
    unauthorized(dispatch, type, value, json, handlers.unauthorized);
  } else if (response.status === 422) {
    invalid(dispatch, type, value, json, handlers.invalid);
  } else {
    failure(dispatch, type, value, json, handlers.failure);
  }
}

function handleSuccess(
  dispatch,
  type,
  value,
  handlers,
  respProcessor,
  json = {}
) {
  if (respProcessor) json = respProcessor(json);
  success(dispatch, type, value, json, handlers.success);
}

/* dispatch, getState passed in from thunk in middleware, thunks are created in the api defs
   type: actionType
   value: actionValue, used to pass an argument that can be referenced in the reducer
                       Usage example: when deleting an item from a list, you can pass in
                       the item to be deleted's key when dispatching the action.  When
                       the api response is returned successful, you can use the actionValue
                       to remove the deleted item's key from the state.
*/
export function callApi(
  dispatch,
  getState,
  type,
  value,
  requestPath,
  opt = {},
  handlers = {},
  respProcessor
) {
  let options = merge(
    {
      endpoint: apiEndpoint(requestPath),
      method: 'GET',
      headers: headers(getState()),
    },
    opt
  );
  request(dispatch, type, value, handlers.request);
  fetch(options.endpoint, options).then(function (response) {
    if (response.status === 204) {
      // no content response
      handleSuccess(dispatch, type, value, handlers);
    } else {
      response
        .json()
        .then(json => {
          if (response.ok) {
            handleSuccess(dispatch, type, value, handlers, respProcessor, json);
          } else {
            handleFailure(dispatch, response, type, value, handlers, json);
          }
        })
        .catch(e => {
          //console.log('error', e)
          handleFailure(dispatch, response, type, value, handlers);
        });
    }
  });
}

// TODO: Revisit this to supply options to disable certain keys in headers object on the left.
export function callApiNoHeaders(
  dispatch,
  getState,
  type,
  value,
  requestPath,
  opt = {},
  handlers = {},
  respProcessor
) {
  let headersWithoutContentType = headers(getState());
  delete headersWithoutContentType['Content-Type'];
  let options = merge(
    {
      endpoint: apiEndpoint(requestPath),
      method: 'GET',
      headers: headersWithoutContentType,
    },
    opt
  );
  request(dispatch, type, value, handlers.request);
  fetch(options.endpoint, options).then(function (response) {
    if (response.status === 204) {
      // no content response
      handleSuccess(dispatch, type, value, handlers);
    } else {
      response
        .json()
        .then(json => {
          if (response.ok) {
            handleSuccess(dispatch, type, value, handlers, respProcessor, json);
          } else {
            handleFailure(dispatch, response, type, value, handlers, json);
          }
        })
        .catch(e => {
          //console.log('error', e)
          handleFailure(dispatch, response, type, value, handlers);
        });
    }
  });
}
