import { schema } from 'normalizr';
import { fromJS } from 'immutable';
import { isEmpty } from 'lodash';
import { success, failure } from '../../helpers/reducerHelpers';
// import { createSelector } from 'reselect';
// import { apiSelectors } from '../api/apiDuck';
import { actionCreator } from '../../helpers/actionHelpers';
// import { searchParams, pageParams, paramsToString } from '../../helpers/paramHelpers';
import * as api from './billingInfoApi';

// Constants
const ADD_PAYMENT = 'modules/billingInfo/ADD_PAYMENT';
const REMOVE_PAYMENT = 'modules/billingInfo/REMOVE_PAYMENT';
const UPDATE_PAYMENT = 'modules/billingInfo/UPDATE_PAYMENT';
const SET_PRIMARY_PAYMENT = 'modules/billingInfo/SET_PRIMARY_PAYMENT';
const SET_BILLING_INFO = 'modules/billingInfo/SET_BILLING_INFO';
const CLEAR_ERROR = 'modules/billingInfo/CLEAR_PAYMENTES_ERROR';

const STATE_KEY_PAYMENTS = 'payments';
const STATE_KEY_PRIMARY_PAYMENT = 'primaryPayment';
const STATE_KEY_BILLING_INFO = 'billingInfo';
const STATE_KEY_BILLING_INFO_ERROR = 'error';

const SCHEMA_BILLING = 'billing';

export const billingConstants = {
  ADD_PAYMENT,
  REMOVE_PAYMENT,
  UPDATE_PAYMENT,
  SET_PRIMARY_PAYMENT,
  SET_BILLING_INFO,
  CLEAR_ERROR,

  STATE_KEY_PAYMENTS,
  STATE_KEY_PRIMARY_PAYMENT,
  STATE_KEY_BILLING_INFO,
  STATE_KEY_BILLING_INFO_ERROR,

  SCHEMA_BILLING,
};

// Schema
export const billingSchema = {
  schema: new schema.Entity(SCHEMA_BILLING),
};

// Reducer
const reducer = (ogState = {}, action) => {
  const state = fromJS(ogState);

  switch (action.type) {
    case success(SET_BILLING_INFO):
      return state.set(STATE_KEY_BILLING_INFO, action.payload).toJS();
    case failure(SET_BILLING_INFO):
      let setInfoErr = action.payload.error;
      if (isEmpty(setInfoErr)) {
        setInfoErr = 'API error occured';
      }
      return state.set(STATE_KEY_BILLING_INFO_ERROR, setInfoErr).toJS();

    case success(ADD_PAYMENT):
      return state.set(STATE_KEY_PAYMENTS, action.payload).toJS();
    case failure(ADD_PAYMENT):
      let addPaymentErr = action.payload.error;
      if (isEmpty(addPaymentErr)) {
        addPaymentErr = 'API error occured';
      }
      return state.set(STATE_KEY_BILLING_INFO_ERROR, addPaymentErr).toJS();

    case success(UPDATE_PAYMENT):
      return state.set(STATE_KEY_PAYMENTS, action.payload).toJS();
    case failure(UPDATE_PAYMENT):
      let updatePaymentErr = action.payload.error;
      if (isEmpty(updatePaymentErr)) {
        updatePaymentErr = 'API error occured';
      }
      return state.set(STATE_KEY_BILLING_INFO_ERROR, updatePaymentErr).toJS();

    case success(SET_PRIMARY_PAYMENT):
      return state.set(STATE_KEY_PRIMARY_PAYMENT, action.payload).toJS();
    case failure(SET_PRIMARY_PAYMENT):
      let setPrimaryErr = action.payload.error;
      if (isEmpty(setPrimaryErr)) {
        setPrimaryErr = 'API error occured';
      }
      return state.set(STATE_KEY_BILLING_INFO_ERROR, setPrimaryErr).toJS();

    case success(REMOVE_PAYMENT):
      return state.set(STATE_KEY_PAYMENTS, action.payload).toJS();
    case failure(REMOVE_PAYMENT):
      let removePaymentErr = action.payload.error;
      if (isEmpty(removePaymentErr)) {
        removePaymentErr = 'API error occured';
      }
      return state.set(STATE_KEY_BILLING_INFO_ERROR, removePaymentErr).toJS();

    case CLEAR_ERROR:
      return state.set(STATE_KEY_BILLING_INFO_ERROR, null).toJS();

    default:
      return ogState;
  }
};

// Actions
export const billingActions = {
  clearError: actionCreator(CLEAR_ERROR),
  addPayment: (payload, doneCallback) =>
    api.addPayment(payload, ADD_PAYMENT, doneCallback),
  updatePayment: (payload, doneCallback) =>
    api.updatePayment(payload, UPDATE_PAYMENT, doneCallback),
  removePayment: id => api.removePayment(id, REMOVE_PAYMENT),
  setPrimaryPayment: payload =>
    api.setPrimaryPayment(payload, SET_PRIMARY_PAYMENT),
  setBillingInfo: (payload, doneCallback) =>
    api.setBillingInfo(payload, SET_BILLING_INFO, doneCallback),
};

// Selectors
export const billingSelectors = {
  getPayments: state => state.modules.app[STATE_KEY_PAYMENTS],
  getPrimaryPayment: state => state.modules.app[STATE_KEY_PRIMARY_PAYMENT],
  getBillingInfo: state => state.modules.app[STATE_KEY_BILLING_INFO],
  getError: state => state.modules.app[STATE_KEY_BILLING_INFO_ERROR],
};

export default reducer;
