import { createAction } from '@reduxjs/toolkit';

import * as appConstants from './appConstants';
import * as api from './appApi';

export const login = (userInfo, doneCallback) =>
  api.login(userInfo, appConstants.LOG_IN, doneCallback);

export const logout = () => api.logout(appConstants.LOG_OUT);

export const checkToken = () => api.checkToken(appConstants.CHECK_TOKEN);

export const setAppMode = createAction(appConstants.SET_APP_MODE);

export const sendPwResetEmail = (payload, doneCallback) =>
  api.sendPwResetEmail(payload, appConstants.SEND_PW_RESET_EMAIL, doneCallback);

export const verifyResetToken = (payload, doneCallback) =>
  api.verifyResetToken(payload, appConstants.VERIFY_RESET_TOKEN, doneCallback);

export const changePassword = (payload, doneCallback) =>
  api.changePassword(payload, appConstants.CHANGE_PASSWORD, doneCallback);

export const getDesignStatus = (id, doneCallback) =>
  api.getDesignStatus(id, appConstants.UPLOAD_TEMPLATE, doneCallback);

export const resumeDesignImport = (id, doneCallback) =>
  api.resumeDesignImport(id, appConstants.RESUME_IMPORT, doneCallback);

export const sendUploadInformation = (uploadInformation, doneCallback) =>
  api.sendUploadInformation(
    uploadInformation,
    appConstants.SEND_UPLOAD_INFORMATION,
    doneCallback
  );

export const uploadTemplate = (formData, doneCallback) =>
  api.uploadTemplate(formData, appConstants.UPLOAD_TEMPLATE, doneCallback);

export const clearInvalidPwResetToken = createAction(
  appConstants.CLEAR_INVALID_PW_RESET_TOKEN
);

export const setMessage = createAction(appConstants.SET_MESSAGE);

export const clearMessage = createAction(appConstants.CLEAR_MESSAGE);
