import React, { useState } from 'react';
import Button from '../Button/Button';
import _ from 'lodash';
import colors from '../../theme/Colors.module.scss';
import './ActionZone.scss';

// This component accepts the props "icon" (add, upload), "iconColor", "type" (file, button), "text", "url" and "callback".
// Setting the "type" prop to 'file' will change the component's funtionality to handle file uploading.
// It can also be expanded to include drag and drop functionality for file uploads.

const ActionZone = props => {
  const { callback, type, text, textColor, btnStyle, icon, iconColor, url } =
    props;

  const [fileContent, setFileContent] = useState({
    fileName: null,
    file: null,
  });

  const { fileName, file } = fileContent;

  const uploadField = React.createRef();
  const uploadFieldID = _.uniqueId('upload_');

  const handleChange = () => {
    const sourceName = uploadField.current.files[0].name;
    const sourceFile = uploadField.current.files[0];

    setFileContent({
      fileName: sourceName,
      file: sourceFile,
    });
  };

  const handleSubmit = e => {
    e.preventDefault();

    if (callback) {
      if (file) {
        callback(e, file);
      }
    }
  };

  const showFileName = () => {
    if (fileName) {
      return <p className="fileName">{fileName}</p>;
    }
  };

  const buildUploadForm = () => {
    const uploadIcon = <i className="fas fa-file-upload"></i>;
    const instructionText = () =>
      fileName ? 'replace the selected file.' : 'select a file for upload.';

    return (
      <form className="imgUpload" onSubmit={e => handleSubmit(e)}>
        <label htmlFor={uploadFieldID}>
          <p className="uploadIcon">{uploadIcon}</p>
          {showFileName()}

          <input
            className="uploadField"
            type={type}
            id={uploadFieldID}
            ref={uploadField}
            onChange={handleChange}
          />

          <p className="selectText">
            {text ? text : `Click to ${instructionText()}`}
          </p>
        </label>

        <Button
          text="Upload file"
          textColor={textColor ? textColor : colors.white}
          btnStyle={btnStyle ? btnStyle : 'solid'}
          disabled={!fileName}
        />
      </form>
    );
  };

  const buildZone = () => {
    if (type === 'file') {
      return buildUploadForm();
    } else {
      return (
        <Button
          type="button"
          icon={icon ? icon : 'add'}
          iconColor={iconColor ? iconColor : colors.primary900}
          text={text}
          textColor={textColor ? textColor : colors.slate}
          btnStyle={btnStyle ? btnStyle : 'transparent'}
          callback={callback}
          url={url}
          {...props}
        />
      );
    }
  };

  return <div className="actionZone">{buildZone()}</div>;
};

export default ActionZone;
