import { connect } from 'react-redux';
import ResetPassword from './ResetPassword'

function mapStateToProps(state) { return {}}

function mapDispatchToProps(dispatch) { return {}}

const ResetPasswordContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ResetPassword);

export default ResetPasswordContainer;
