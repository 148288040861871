import React from 'react';
import _ from 'lodash';
import colors from 'theme/Colors.module.scss';
import './Table.scss';
import LoadingIndicator from 'components/LoadingIndicator/LoadingIndicator';

const Table = props => {
  const { columns, data, activeData, rowClick, campaigns, isMobile } = props;
  const colClassNames = columns.map((_, index) => `title_${index}`);

  const buildHeader = () => {
    const headerStyle = {
      gridTemplateColumns: `1fr repeat(1, 8rem)`, // `14rem repeat(${columns.length - 1}, 1fr)`
      gridTemplateAreas: colClassNames.join(' '),
    };

    const colTitles = () => {
      return columns.map((title, index) => (
        <span
          key={title}
          className={colClassNames[index]}
          style={{
            textAlign: index === columns.length - 1 ? 'center' : 'left',
          }}
        >
          {title}
        </span>
      ));
    };

    return (
      <div className="tHead" style={headerStyle}>
        {colTitles()}
      </div>
    );
  };

  const buildRows = () => {
    const rowStyle = id => {
      const style = {
        gridTemplateColumns: `1fr repeat(1, 8rem)`,
        gridTemplateAreas: 'trName trPrints', // 'trName trConversions trPrints trTotalRev'
      };

      if (activeData) {
        if (id === activeData.id) {
          style.backgroundColor = colors.secondary900;
          style.color = colors.white;
        }
      }

      return style;
    };

    const makeRows = () => {
      const campaign = id =>
        campaigns?.filter(campaign => campaign.id === id)[0];

      return data.map(item => {
        // TODO: Add trConversions, trAvgOrderVal and trTotalOrderVal.
        return (
          <li
            key={_.uniqueId('row_')}
            className="tr"
            onClick={() => rowClick(item.data.id)}
            style={rowStyle(item.data.id)}
          >
            <span className="trName">
              {isMobile && (
                <span className="mobileTitle">Campaign Name: &nbsp;</span>
              )}
              {item.data
                ? campaign(item.data.id)?.name || <LoadingIndicator />
                : '--'}
            </span>

            {/* <span className="trConversions">
              {isMobile && (
                <span className="mobileTitle">Conversions: &nbsp;</span>
              )}
              {item.data
                ? `${item.data.total_conv.toLocaleString()} (${(
                    parseFloat(item.data.total_conv_pct) * 100
                  ).toFixed(2)}%)`
                : '--'}
            </span> */}

            <span
              className="trPrints"
              style={{ textAlign: isMobile ? 'left' : 'center' }}
            >
              {isMobile && (
                <span className="mobileTitle">Personalized Prints: &nbsp;</span>
              )}
              {item.data ? item.data.total_imp.toLocaleString() : '--'}
            </span>

            {/* <span className="trTotalRev">
              {isMobile && <span className="mobileTitle">Revenue: &nbsp;</span>}
              ${' '}
              {item.data
                ? parseFloat(item.data.total_amt).toLocaleString()
                : '--'}
            </span> */}
          </li>
        );
      });
    };

    const EmptyDataRow = () => (
      <li className="liEmpty">
        <span className="trEmpty">No activity found</span>
      </li>
    );

    const Rows = () =>
      data && data.length > 0 ? makeRows() : <EmptyDataRow />;

    return (
      <ul className="tBody">
        <Rows />
      </ul>
    );
  };

  return (
    <div className="table">
      {buildHeader()}
      {buildRows()}
    </div>
  );
};

export default Table;
