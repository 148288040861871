import React from 'react';
import Container from './Container';

export default function DesignImporter({ open, onOpen, onClose, refresh }) {
  return (
    <Container
      open={open}
      onOpen={onOpen}
      onClose={onClose}
      refresh={refresh}
    />
  );
}
