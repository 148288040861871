import React, { useEffect, useRef } from 'react';
import { v4 as uuid } from 'uuid';
import './Checkbox.scss';

const Checkbox = ({ id, name, checked, onChange, label, disabled }) => {
  const getId = () => id || name || uuid();
  const idRef = useRef(getId());

  useEffect(() => {
    idRef.current = getId();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, name]);

  return (
    <div className="checkbox">
      <div className="checkContainer">
        <input
          id={idRef.current}
          name={name}
          checked={checked}
          disabled={disabled}
          onChange={onChange}
          type="checkbox"
        />
        <span className="checkmark"></span>
      </div>
      {label && <label htmlFor={idRef.current}>{label}</label>}
    </div>
  );
};

export default Checkbox;
