import React from 'react';
import TableRowCard from 'components/TableRowCard/TableRowCard';
import colors from 'theme/Colors.module.scss';
import './FontCard.scss';
import Tooltip from '../../../../components/Tooltip/Tooltip';
import Button from '../../../../components/Button/Button';

export default props => {
  const { font, headers, deleteUserFont } = props;
  const getIndicatorColor = () =>
    font.inactive_at ? colors.greyDisabled : colors.status00;

  return (
    <TableRowCard
      key={font.id}
      className="userCard"
      indicatorColor={getIndicatorColor()}
      headers={headers}
    >
      <td className="fName">
        <div className="nameGroup">{font.fullname}</div>
      </td>

      <td className="lName">
        <div className="nameGroup">{font.family}</div>
      </td>

      <td className="lName" style={{ paddingLeft: '0.5rem' }}>
        <Tooltip
          tipText={'Delete Font.'}
          direction="left"
          render={() => (
            <Button
              icon="delete"
              iconColor={colors.slate}
              btnStyle="transparent"
              onClick={() => {
                deleteUserFont(font.id);
              }}
            />
          )}
        />
      </td>
    </TableRowCard>
  );
};
