import React from "react"
function DoubleSide({ size }) {
    return (
        <svg viewBox="0 0 28 28" height={size}>
          <path d="M9.3 10C9.13432 10 9 10.1343 9 10.3V10.7C9 10.8657 9.13432 11 9.3 11H12.7C12.8657 11 13 10.8657 13 10.7V10.3C13 10.1343 12.8657 10 12.7 10H9.3Z"
                fill="black"/>
          <path d="M9 18.3C9 18.1343 9.13432 18 9.3 18H18.7C18.8657 18 19 18.1343 19 18.3V18.7C19 18.8657 18.8657 19 18.7 19H9.3C9.13432 19 9 18.8657 9 18.7V18.3Z"
                fill="black"/>
          <path d="M9.3 14C9.13432 14 9 14.1343 9 14.3V14.7C9 14.8657 9.13432 15 9.3 15H18.7C18.8657 15 19 14.8657 19 14.7V14.3C19 14.1343 18.8657 14 18.7 14H9.3Z"
                fill="black"/>
          <path
                fillRule="evenodd"
                d="M16.1716 2H5.5C4.5 2 4 2.94772 4 3.5V24.5C4 25.5 5 26 5.5 26H22.5C23.0523 26 24 25.5 24 24.5V10C24 9.5 24 9 23.5 8.5L17.5 2.5C17 2 16.5 2 16.1716 2ZM7 4C6.44772 4 6 4.44772 6 5V23C6 23.5523 6.44772 24 7 24H21C21.5523 24 22 23.5523 22 23V10.5H16C15.4477 10.5 15 10.0523 15 9.5V4H7ZM17 4.82842L20.6716 8.5H17V4.82842Z"
                fill="black" />
        </svg>
    )
}
export default DoubleSide