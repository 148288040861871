import React from 'react';

const NewPreviewModalContent = props => {
  const { creative } = props;

  return (
    <div className="previewModalContent">
      <img src={creative.original_url} alt={`${creative.filename}, Full Preview`} />
      {
        creative.originalSecondSrc && (
          <img style={{ marginLeft: '10px' }} src={creative.originalSecondSrc} alt={`${creative.filename}, Full Preview`} />
        )
      }
    </div>
  );
}

export default NewPreviewModalContent;