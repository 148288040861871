import React from 'react';
import { withRouter, Link } from 'react-router-dom';
import { isEmpty } from 'lodash';
// import { useFlag } from '../../../flags';
import Button from '../../../components/Button/Button';
import logo from '../../../static/images/logo.png';
import colors from '../../../theme/Colors.module.scss';
import 'scenes/app/menuNav/MenuNav.scss';
import './CompanyHeader.scss';

// const appModes = ['retailer', 'advertiser'];
// const customArrowStyle = {
//   top: '0.9rem',
//   color: colors.darkGrey,
// };

const CompanyHeader = props => {
  const {
    isMobile,
    logout,
    userDetails,
    token,
    // appMode,
    // changeMode,
    // history,
    inTemplateEditor,
  } = props;

  // const newAppMode = newMode => {
  //   if (newMode !== appMode) {
  //     newMode = appModes[0] && history.push('/dashboard');
  //     newMode = appModes[1] && history.push('/adv/dashboard');
  //     changeMode(newMode);
  //   }
  // };

  const logoStyle = {
    filter: inTemplateEditor && 'brightness(20)',
  };

  const buildHeader = () => {
    if (!isEmpty(token)) {
      return (
        <>
          <div className="brand">
            {(isMobile || inTemplateEditor) && (
              <div className="nav-brand-mobile">
                <Link to="/">
                  <img
                    className="nav-brand__logo"
                    src={logo}
                    style={logoStyle}
                    alt="UnDigital Logo."
                  />
                </Link>
              </div>
            )}
          </div>

          <div className="authBtn">
            <Button
              type="button"
              icon="signOut"
              text="Log out"
              border={false}
              textColor={inTemplateEditor ? colors.white : colors.slate}
              iconColor={inTemplateEditor ? colors.white : colors.primary900}
              btnStyle={inTemplateEditor ? 'solid' : 'transparent'}
              bgColor={inTemplateEditor && 'rgba(255, 255, 255, 0.1)'}
              hoverColor={inTemplateEditor && 'rgba(255, 255, 255, 0.25'}
              callback={logout}
            />
          </div>

          {!isMobile && (
            <div className="companySummary">
              {!inTemplateEditor && (
                <>
                  <p className="name">
                    <i className="fas fa-user"></i>&nbsp;
                    {/* TODO: Show company name and link to website when ready */}
                    {/* <a href={userDetails.companySite} target="_blank" rel="noopener noreferrer">{userDetails.name}</a> */}
                  </p>

                  <p className="email">{userDetails.email}</p>
                </>
              )}
            </div>
          )}
        </>
      );
    } else {
      return (
        <>
          {/* {isMobile && ( */}
          <div className="nav-brand-mobile brand">
            <Link to="/">
              <img
                className="nav-brand__logo"
                src={logo}
                style={logoStyle}
                alt="UnDigital Logo."
              />
            </Link>
          </div>
          {/* )} */}

          <div className="authBtn">
            <Button
              type="button"
              icon="signIn"
              text="Log in"
              border={false}
              textColor={inTemplateEditor ? colors.white : colors.slate}
              iconColor={inTemplateEditor ? colors.white : colors.primary900}
              btnStyle={inTemplateEditor ? 'solid' : 'transparent'}
              bgColor={inTemplateEditor && 'rgba(255, 255, 255, 0.1)'}
              hoverColor={inTemplateEditor && 'rgba(255, 255, 255, 0.25'}
              url="/login"
            />
          </div>

          <div className="companySummary resetPassword">
            <p>
              <span className="name">Welcome to UnDigital!</span>
              &nbsp;&nbsp;&nbsp;&nbsp;
              <span className="email">Personalized packaging</span>
            </p>
          </div>
        </>
      );
    }
  };

  return (
    <div
      className="header-container"
      style={{
        height: inTemplateEditor && '3.65rem',
      }}
    >
      {buildHeader()}
    </div>
  );
};

export default withRouter(CompanyHeader);
