import moment from 'moment';
import { uniqBy } from 'lodash';
import colors from 'theme/Colors.module.scss';

export const SCALE_FACTOR = 0.4;
export const FONT_SCALE_FACTOR = 0.675;
export const defaultGridSize = 15;
export const defaultGridColor = colors.lighterGrey;
export const bufferModes = ['fit', 'fill'];
export const zoomLevels = [
  { friendlyName: '300%', zoomVal: 3 },
  { friendlyName: '200%', zoomVal: 2 },
  { friendlyName: '125%', zoomVal: 1.25 },
  { friendlyName: '100%', zoomVal: 1 },
  { friendlyName: '75%', zoomVal: 0.75 },
  { friendlyName: '50%', zoomVal: 0.5 },
  { friendlyName: '25%', zoomVal: 0.25 },
  { friendlyName: '10%', zoomVal: 0.1 },
];

export const loadBGIntoCanvas = (img, conf, canvas, updateLocalCanvas) => {
  return new Promise(resolve => {
    const canvasCallback = canvas.renderAll.bind(canvas);
    const finalize = () => {
      canvas && canvasCallback();
      updateLocalCanvas && updateLocalCanvas(canvas);
      resolve();
    };

    canvas.setBackgroundImage(img, finalize, conf);
  });
};

export const downloadFonts = fonts => {
  return new Promise((resolve, reject) => {
    const promisesList = fonts.map(font =>
      new FontFace(font.fontFamily, font.fontURL).load().catch(err => err)
    );
    Promise.all(promisesList)
      .then(res => {
        res.forEach(uniqueFont => {
          if (uniqueFont && uniqueFont.family) {
            document.fonts.add(uniqueFont);
          }
        });
        resolve(true);
      })
      .catch(err => reject(err));
  });
};

export const getTemplateFonts = (args, userFonts) => {
  const template = args.template;
  const elements =
    template.ad_template_pages?.length > 0
      ? template.ad_template_pages.reduce((acc, x) => {
          return acc.concat(x.ad_template_vars);
        }, [])
      : template.ad_template_vars;
  const setMessage = args.setMessage;

  let fonts = [];
  const privateFonts = userFonts.private;
  const publicFonts = userFonts.public;
  Object.keys(publicFonts).forEach(key => {
    const items = publicFonts[key];
    fonts = fonts.concat(items);
  });
  fonts = fonts.concat(privateFonts);

  const fontsArray = elements
    // eslint-disable-next-line array-callback-return
    ?.map(item => {
      if (item.metadata) {
        const {
          res_type,
          metadata: {
            fontFamily,
            __fontFilename: fontFile,
            postscriptname,
            postScriptName,
          },
        } = item;
        if (!fontFamily) return undefined;
        if (res_type === 'txt' || res_type === 'txt_template') {
          const fontURL = fonts
            ?.filter(font => !!font.url)
            // eslint-disable-next-line array-callback-return
            .find(({ url, postscriptname: scriptFont, fullname }) => {
              const fileName = url
                .substring(url.lastIndexOf('/') + 1)
                .split('?')[0];

              if (postscriptname) return scriptFont === postscriptname;
              if (postScriptName) return scriptFont === postScriptName;
              if (fontFamily === fullname) return true;
              if (!postscriptname && fontFamily) return fileName === fontFile;
            })?.url;
          if (!fontURL) {
            const message = `Error loading: ${fontFamily}`;
            if (setMessage) {
              setMessage({ message, type: 'error' });
            } else {
              console.error(message);
            }
            return undefined;
          }
          return { fontFamily, fontURL: `url(${fontURL})` };
        }
      }
    })
    .filter(i => !!i);
  return uniqBy(fontsArray, obj => obj.fontURL);
};

const getDuplicateTemplateName = name => {
  const originalName = name.replace(
    /\s?(\(duplicated at \d+\/\d+\/\d+ \d+:\d+:\d+ \w+\))$/i,
    ''
  );

  return `${originalName} (duplicated at ${moment().format(
    'M/D/YYYY h:mm:ss A'
  )})`;
};

export const getDuplicatePayload = async template => {
  const workingTemplate = {
    ...template,
    name: getDuplicateTemplateName(template.name),
  };

  // Clean up keys that server will assign
  workingTemplate.ad_template_vars.forEach(item => {
    delete item.id;
    delete item.ad_template_id;
  });

  delete workingTemplate.id;
  delete workingTemplate.campaigns_count;
  delete workingTemplate.archived_at;

  //
  const payload = workingTemplate;

  if (workingTemplate.bg_img) {
    const { original_url } = workingTemplate.bg_img;

    const relativeURL = () => {
      const relativePath = `/uploads${original_url.split('/uploads')[1]}`;

      if (relativePath.includes('?')) {
        const corePath = relativePath.split('?');
        return corePath[0];
      }

      return relativePath;
    };
    const pathSplit = relativeURL().split('/');
    const extSplit = pathSplit[pathSplit.length - 1].split('.');
    const fileName = extSplit[0];
    const extension = extSplit[extSplit.length - 1];

    const res = await fetch(original_url);
    const data = await res.blob();
    const file = new File([data], `${fileName}.${extension}`, {
      type: `image/${extension || 'jpeg'}`,
    });

    payload.bg_img = file;
  } else {
    delete payload.bg_img;
  }
  return payload;
};

export const duplicateTemplate = async (
  template,
  context,
  successCallback,
  failureCallback
) => {
  const workingTemplate = {
    ...template,
    name: getDuplicateTemplateName(template.name),
  };

  // Clean up keys that server will assign
  workingTemplate.ad_template_vars.forEach(item => {
    delete item.id;
    delete item.ad_template_id;
  });

  delete workingTemplate.id;
  delete workingTemplate.campaigns_count;
  delete workingTemplate.archived_at;

  const getFileFromUrl = async url => {
    if (!url) return null;
    const relativeURL = () => {
      const relativePath = `/uploads${url.split('/uploads')[1]}`;

      if (relativePath.includes('?')) {
        const corePath = relativePath.split('?');
        return corePath[0];
      }

      return relativePath;
    };
    const pathSplit = relativeURL().split('/');
    const extSplit = pathSplit[pathSplit.length - 1].split('.');
    const fileName = extSplit[0];
    const extension = extSplit[extSplit.length - 1];

    const res = await fetch(url, {mode: 'cors', cache: 'no-cache'});
    const data = await res.blob();
    return new File([data], `${fileName}.${extension}`, {
      type: `image/${extension || 'jpeg'}`,
    });
  };

  const createFile = async () => {
    const payload = workingTemplate;

    const callback = res => {
      if (successCallback) successCallback();

      const id = res.id;
      context.getTemplates();

      if (context.history && id) {
        context.history.push(`/template-editor?id=${id}`);
      }
    };

    if (payload.double_sided) {
      for (let i = 0; i < payload.ad_template_pages.length; i++) {
        if (payload.ad_template_pages[i]?.bg_img) {
          payload.ad_template_pages[i].bg_img = await getFileFromUrl(
            payload.ad_template_pages[i].bg_img?.original_url || ''
          );
        } else {
          delete payload.ad_template_pages[i].bg_img;
        }
      }
      context.createTemplateDoubleSided(payload, callback);
    } else {
      if (payload.bg_img) {
        const { original_url } = payload.bg_img;
        payload.bg_img = await getFileFromUrl(original_url);
      } else {
        delete payload.bg_img;
      }
      context.createTemplate(payload, callback);
    }
  };
  try {
    await createFile();
  } catch (e) {
    console.error(e);
    failureCallback();
  }
};

export const calcFitRatio = args => {
  const { canvasDimensions, fitMode, previewMode } = args;
  const { width, height } = canvasDimensions;
  const headerSpace = previewMode ? 75 : 135;
  const panelSpace = previewMode ? 0 : 460;
  const buffer = previewMode ? 62 : 30;

  const areaHeight = window.innerHeight - buffer - headerSpace;
  const areaWidth = window.innerWidth - buffer - panelSpace;
  let newZoomRatio;

  // Fit
  if (fitMode === bufferModes[0]) {
    const greaterWidth = width > areaWidth;
    const greaterHeight = height > areaHeight;

    if (!greaterWidth && greaterHeight) {
      newZoomRatio = areaHeight / height;
    } else if (greaterWidth && !greaterHeight) {
      newZoomRatio = areaWidth / width;
    } else {
      const widthRatio = areaWidth / width;
      const heightRatio = areaHeight / height;
      const lesserRatio = widthRatio < heightRatio ? widthRatio : heightRatio;
      newZoomRatio = lesserRatio;
    }
  }

  // Fill
  if (fitMode === bufferModes[1]) {
    const lesserWidth = width < areaWidth;
    const lesserHeight = height < areaHeight;

    if (!lesserWidth && lesserHeight) {
      newZoomRatio = areaHeight / height;
    } else if (lesserWidth && !lesserHeight) {
      newZoomRatio = areaWidth / width;
    } else {
      const widthRatio = areaWidth / width;
      const heightRatio = areaHeight / height;
      const lesserRatio = widthRatio > heightRatio ? widthRatio : heightRatio;
      newZoomRatio = lesserRatio;
    }
  }

  const newFitObj = {
    friendlyName: `${parseInt(newZoomRatio * 100)}%`,
    zoomVal: newZoomRatio,
  };

  return newFitObj;
};
