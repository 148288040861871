import React from 'react';
import { Field } from 'formik';
import {
  FormControl,
  FormLabel,
  Input,
  FormErrorMessage,
} from '@chakra-ui/react';

export const ChakraInput = ({ ...props }) => {
  const { label, name, helperText, ...rest } = props;
  return (
    <Field name={name}>
      {({ field, form }) => (
        <FormControl
          id={name}
          py={2}
          isInvalid={form.errors[name] && form.touched[name]}
        >
          <FormLabel htmlFor={name} mb={2}>
            {label}
          </FormLabel>
          <Input
            borderRadius={0}
            id={name}
            {...field}
            {...rest}
            sx={{
              backgroundColor: '#ffffff',
              ':focus': {
                borderColor: 'rgb(255, 111, 15)',
                boxShadow: '0 0 0 1px rgb(255, 111, 15)',
              },
            }}
          />
          <FormErrorMessage>{form.errors[name]}</FormErrorMessage>
        </FormControl>
      )}
    </Field>
  );
};
