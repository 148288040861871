import React, { useCallback } from 'react';
import {
  Box,
  // Input,
  Button,
  // InputGroup,
  // InputLeftElement,
} from '@chakra-ui/react';
import { useEditor } from 'components/Canvas';
import useTemplateEditorContext from 'scenes/templateEditor/hooks/useTemplateEditorContext';
import PanelTitle from './components/PanelTitle';
// import Icons from '../../Icons';
import { loadFont } from 'helpers/editor';
import { uniqueId } from 'lodash';

function Text() {
  const editor = useEditor();
  const { defaultFont } = useTemplateEditorContext();

  const addObjectToCanvas = useCallback(async () => {
    await loadFont(defaultFont);
    let __fontFilename;

    if ((defaultFont?.url || '').match(/s3.amazonaws.com/g)) {
      __fontFilename = (defaultFont?.url || '')
        .substring(defaultFont.url.lastIndexOf('/') + 1)
        .split('?')[0];
    } else {
      __fontFilename = (defaultFont?.url || '').substring(
        (defaultFont?.url || '').lastIndexOf('/') + 1
      );
    }
    const objectOptions = {
      type: 'DynamicText',
      width: 240,
      fontSize: 64,
      text: 'Add some text',
      fontFamily: defaultFont?.name || '',
      textAlign: 'center',
      fill: '#000000',
      keyValues: [],
      metadata: {
        __fontFilename: __fontFilename,

        // unique id to allow us to tie back a newly saved record with it's entity id
        __session_var_id: uniqueId('s'),
      },
      //id: uniqueId()
    };
    editor.add(objectOptions);
  }, [editor, defaultFont]);

  return (
    <Box sx={{ flex: 1, marginTop: '1.5rem' }}>
      <PanelTitle title="Click to add to canvas" subMenu="TextTutorial" />
      <Box sx={{ color: '#ffffff', padding: '1.5rem' }}>
        <Button
          size="sm"
          w="100%"
          onClick={() => addObjectToCanvas()}
          sx={{
            // color: '#333333',
            backgroundColor: 'rgba(255,255,255,0.1)',
            color: 'rgba(255,255,255,0.9)',
            height: '48px',
            justifyContent: 'start',
            borderRadius: '4px',
          }}
          _hover={{
            backgroundColor: 'rgba(255,255,255,0.15)',
          }}
          _active={{
            backgroundColor: 'rgba(255,255,255,0.2)',
          }}
        >
          Add text
        </Button>
      </Box>
    </Box>
  );
}

export default Text;
