import React from 'react';

import EntityTableCellEmpty from './EntityTableCellEmpty';

export default ({ headers, fillRight }) => {
  return (
    <tr style={{ height: 77 }}>
      <td colSpan={headers.length} />
      {fillRight && <EntityTableCellEmpty />}
    </tr>
  );
};
