import React from 'react';
import { Flex, Text } from '@chakra-ui/react';
import { useDropzone } from 'react-dropzone';
import Icons from 'components/Icons';
import ButtonComponent from './ButtonComponent';

const defaultModalState = {
  title: 'Import a template',
  description: 'You can import a template from a Photoshop or Ilustrator file.',
};

export default function SelectFileDropZone({
  file,
  setFile,
  sm,
  onImportClicked,
  setModalState,
}) {
  const onDrop = React.useCallback(files => {
    const selectedFile = files[0];
    if (
      selectedFile?.name?.endsWith('.psd') ||
      selectedFile?.name?.endsWith('.ai')
    ) {
      setFile(selectedFile);
      onImportClicked();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { getInputProps, getRootProps, isDragActive } = useDropzone({
    onDrop,
    multiple: false,
  });

  React.useEffect(() => {
    setModalState(defaultModalState);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Flex
        {...getRootProps()}
        // w="100%"
        border={`4px dashed ${!isDragActive ? '#999' : '#5E2EBA'}`}
        borderRadius="3px"
        direction="column"
        align="center"
        p="2rem"
        m="14px auto 0"
        maxW="410px"
      >
        <input {...getInputProps()} />
        <Icons.UploadCloud height={45} color="rgba(0, 0, 0, 0.35)" />

        {!file ? (
          <>
            <Text fontWeight={400} fontSize="22px" m="1rem 0 0 0" color="black">
              {!isDragActive ? 'Drag & Drop file here' : 'Drop file'}
            </Text>
            <Text
              fontWeight={400}
              fontSize="22px"
              m="1.5rem 0 1rem 0"
              color="black"
            >
              or
            </Text>
          </>
        ) : (
          <Flex align="center" m="0 0 10px " maxW="100%">
            <Icons.File />
            <Text
              fontWeight={400}
              fontSize="22px"
              color="black"
              m="0"
              whiteSpace="nowrap"
              overflow="hidden"
              textOverflow="ellipsis"
            >
              {file.name}
            </Text>
          </Flex>
        )}
        <ButtonComponent>
          {!file ? 'Browse a file' : 'Select different file'}
        </ButtonComponent>
      </Flex>

      {file && (
        <ButtonComponent
          p={sm ? '0.65rem 3rem' : '0.65rem 1rem'}
          m="2rem auto 0"
          onClick={onImportClicked}
        >
          Start import
        </ButtonComponent>
      )}
    </>
  );
}
