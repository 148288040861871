import React from 'react';

function Checked({ height = 48, style = {} }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={height}
      height={height}
      fill="none"
      viewBox="0 0 48 48"
      style={{...style}}
    >
      <path
        fill="#0BC196"
        d="M44 24c0 11.045-8.955 20-20 20S4 35.045 4 24 12.955 4 24 4s20 8.955 20 20z"
        opacity="0.2"
      ></path>
      <path
        fill="#089171"
        d="M34.586 14.586l-13.57 13.586-5.602-5.586-2.828 2.828 8.434 8.414 16.395-16.414-2.83-2.828z"
      ></path>
    </svg>
  );
}

export default Checked;
