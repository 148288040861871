import React, { useState, forwardRef, useEffect } from 'react';
import { Box, List, ListItem, Text, Tooltip } from '@chakra-ui/react';
import moment from 'moment';
import { DayPicker } from 'react-day-picker';
import colors from 'theme/Colors.module.scss';
import {
  changeDRPresetRange,
  drPresetOptions,
  awPresetOptions,
} from 'helpers/presetDateHelpers';
import './DateRangePicker.scss';
import Button from 'components/Button/Button';
import QuestionmarkRound from 'components/Icons/QuestionmarkRound';
import CheveronDown from 'components/Icons/CheveronDown';

const drDropdownOptions = [
  ...drPresetOptions,
  { label: 'Custom dates', days: 0 },
];

const initDRPresetIndex = (from, to) => {
  if (
    moment(to).format('YYYY-MM-DD') ===
      moment().subtract(1, 'days').format('YYYY-MM-DD') &&
    from !== undefined &&
    to !== undefined
  ) {
    if (
      moment(from).format('YYYY-MM-DD') ===
      moment().subtract(drDropdownOptions[0], 'days').format('YYYY-MM-DD')
    )
      return 0;
    else if (
      moment(from).format('YYYY-MM-DD') ===
      moment().subtract(drDropdownOptions[1], 'days').format('YYYY-MM-DD')
    )
      return 1;
    else if (
      moment(from).format('YYYY-MM-DD') ===
      moment().subtract(drDropdownOptions[2], 'days').format('YYYY-MM-DD')
    )
      return 2;
  }

  if (
    moment(to).format('YYYY-MM-DD') === moment().format('YYYY-MM-DD') &&
    from !== undefined &&
    to !== undefined
  ) {
    if (
      moment(from).format('YYYY-MM-DD') ===
      moment().subtract(drDropdownOptions[3], 'days').format('YYYY-MM-DD')
    )
      return 3;
  }

  if (
    from !== undefined &&
    to !== undefined &&
    drDropdownOptions[4] &&
    drDropdownOptions[4].days === 0
  )
    return 4;

  return null;
};

const DateRangePicker = forwardRef((props, ref) => {
  const {
    date,
    setDate,
    isCustomRangePickerVisible,
    setIsCustomRangePickerVisible,
    dateRangeDropdownVisible,
    setDateRangeDropdownVisible,
    setAttributionWindow,
  } = props;

  const { from, to } = date;
  const [currentDRPresetIndex, setCurrentDRPresetIndex] = useState(
    initDRPresetIndex(date.from, date.to)
  );
  const [currentAWPresetIndex, setCurrentAWPresetIndex] = useState(2);

  const modifiers = { start: new Date(from), end: new Date(to) };

  const [selectedRange, setSelectedRange] = useState(null);

  useEffect(() => {
    setCurrentDRPresetIndex(initDRPresetIndex(from, to));
    setSelectedRange(date);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [from, to]);

  return (
    <>
      <Box
        className="date-range-picker"
        onClick={() => {
          setDateRangeDropdownVisible(!dateRangeDropdownVisible);
        }}
      >
        <Text
          className="date-range-picker__date-range-text"
          as="span"
          color={
            !dateRangeDropdownVisible ? colors.neutral900 : colors.neutral400
          }
        >
          {drDropdownOptions[currentDRPresetIndex]?.label !== 'Custom'
            ? `${
                moment(date.from).format('YYYY') !==
                moment(date.to).format('YYYY')
                  ? `${
                      drDropdownOptions[currentDRPresetIndex]?.label
                    } (${moment(date.from).format('MMM D, YYYY')} - ${moment(
                      date.to
                    ).format('MMM D, YYYY')})`
                  : `${
                      drDropdownOptions[currentDRPresetIndex]?.label
                    } (${moment(date.from).format('MMM D')} - ${moment(
                      date.to
                    ).format('MMM D, YYYY')})`
              }`
            : `${
                moment(date.from).format('YYYY') !==
                moment(date.to).format('YYYY')
                  ? `${
                      drDropdownOptions[currentDRPresetIndex]?.label
                    }: ${moment(date.from).format('MMM D, YYYY')} - ${moment(
                      date.to
                    ).format('MMM D, YYYY')}`
                  : `${
                      drDropdownOptions[currentDRPresetIndex]?.label
                    }: ${moment(date.from).format('MMM D')} - ${moment(
                      date.to
                    ).format('MMM D, YYYY')}`
              }`}
          <CheveronDown
            height="12px"
            width="12px"
            style={{
              color: !dateRangeDropdownVisible
                ? colors.neutral700
                : colors.neutral400,
              transform: !dateRangeDropdownVisible ? `` : `rotateX(180deg)`,
              transition: `all 0.2s ease`,
            }}
          />
        </Text>
        <List
          className="date-range-picker__list"
          hidden={!dateRangeDropdownVisible}
          onClick={e => {
            e.stopPropagation();
          }}
        >
          <Box
            className={`date-range-picker__aw ${
              !setAttributionWindow ? 'aw__disabled' : ''
            }`}
          >
            <Box className="aw__title">
              <span>Attribution Window</span>
              <Tooltip
                className="aw__tooltip"
                bg={colors.white}
                color={colors.neutral700}
                hasArrow
                arrowSize={15}
                label={
                  <Text>
                    Length of time from when an order ships to measure a
                    conversion.
                    <br />
                    <br />
                    (i.e. 90 day window will display conversions from all orders
                    within 90 days of ship date)
                  </Text>
                }
                aria-label="A tooltip"
              >
                <span>
                  <QuestionmarkRound
                    height="16px"
                    style={{ backgroundColor: colors.neutral700 }}
                  />
                </span>
              </Tooltip>
            </Box>
            <Box className="aw__list-item-wrapper">
              {awPresetOptions.map((option, index) => {
                return (
                  <ListItem
                    className="aw__list-item"
                    key={`analytics-${index}`}
                    fontWeight={
                      option === awPresetOptions[currentAWPresetIndex]
                        ? 600
                        : 400
                    }
                    bgColor={
                      option === awPresetOptions[currentAWPresetIndex] &&
                      colors.primary900
                    }
                    color={
                      option === awPresetOptions[currentAWPresetIndex]
                        ? colors.white
                        : colors.neutral900
                    }
                    _hover={
                      option !== awPresetOptions[currentAWPresetIndex] && {
                        color: colors.neutral900,
                        fontWeight: 600,
                        backgroundColor: colors.neutral100,
                      }
                    }
                    _disabled={true}
                    onClick={e => {
                      e.stopPropagation();
                      setCurrentAWPresetIndex(index);
                      setAttributionWindow(option.days);
                      setDateRangeDropdownVisible(false);
                    }}
                  >
                    <Box className="aw__label-wrapper">
                      <span>{option.label}</span>
                    </Box>
                  </ListItem>
                );
              })}
            </Box>
          </Box>
          <Box
            className={`date-range-picker__dr ${
              !setAttributionWindow ? 'aw__disabled' : ''
            }`}
          >
            <Box className="dr__title">
              <span>Date Range</span>
            </Box>
            {!isCustomRangePickerVisible && (
              <Box className="dr__list-item-wrapper">
                {drDropdownOptions.map((option, index) => {
                  return (
                    <ListItem
                      className="dr__list-item"
                      key={`analytics-${index}`}
                      fontWeight={
                        option === drDropdownOptions[currentDRPresetIndex]
                          ? 600
                          : 400
                      }
                      bgColor={
                        option === drDropdownOptions[currentDRPresetIndex] &&
                        colors.primary900
                      }
                      color={
                        option === drDropdownOptions[currentDRPresetIndex]
                          ? colors.white
                          : colors.neutral900
                      }
                      _hover={
                        option !== drDropdownOptions[currentDRPresetIndex] && {
                          color: colors.neutral900,
                          fontWeight: 600,
                          backgroundColor: colors.neutral100,
                        }
                      }
                      onClick={e => {
                        e.stopPropagation();
                        if (option.days === 0) {
                          setIsCustomRangePickerVisible(true);
                          setCurrentDRPresetIndex(index);
                        } else {
                          setDateRangeDropdownVisible(false);
                          setCurrentDRPresetIndex(index);
                          changeDRPresetRange(
                            option.days,
                            setDate,
                            option.label,
                            true
                          );
                        }
                      }}
                    >
                      <Box className="dr__label-wrapper">
                        <span>{option.label}</span>
                      </Box>
                    </ListItem>
                  );
                })}
              </Box>
            )}
            {isCustomRangePickerVisible && (
              <Box className="custom-date-range-picker__wrapper" ref={ref}>
                <DayPicker
                  className="custom-date-range-picker__dropdown"
                  captionLayout="dropdown-buttons"
                  fromYear={2015}
                  toYear={new Date().getFullYear() + 1}
                  mode="range"
                  numberOfMonths={2}
                  defaultMonth={new Date(from)}
                  selected={selectedRange}
                  modifiers={modifiers}
                  showOutsideDays
                  onSelect={range => setSelectedRange(range)}
                />
                <div className="rdp-btns__container">
                  <div className="rdp-btns__sub-container">
                    <Button
                      type="button"
                      text="Cancel"
                      className="rdp-btns__btn"
                      textColor={colors.neutral900}
                      hoverColor={colors.neutral100}
                      textHoverColor={colors.neutral900}
                      border={`1px solid ${colors.neutral200}`}
                      btnStyle="transparent"
                      boxShadow="0px 1px 2px rgba(16, 24, 40, 0.04)"
                      fontSize="12px"
                      fontWeight="600"
                      height="34px"
                      onClick={e => {
                        e.stopPropagation();
                        setIsCustomRangePickerVisible(false);
                      }}
                    />
                    <Button
                      type="button"
                      text="Apply"
                      className="rdp-btns__btn"
                      textColor={colors.white}
                      textHoverColor={colors.primary900}
                      hoverColor={colors.white}
                      btnStyle="border"
                      boxShadow="0px 1px 2px rgba(16, 24, 40, 0.04)"
                      fontSize="12px"
                      fontWeight="600"
                      height="34px"
                      onClick={e => {
                        e.stopPropagation();
                        setDate(selectedRange);
                        setIsCustomRangePickerVisible(false);
                        setDateRangeDropdownVisible(false);
                      }}
                    />
                  </div>
                </div>
              </Box>
            )}
          </Box>
        </List>
      </Box>
    </>
  );
});

export default DateRangePicker;
