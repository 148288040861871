import React, { useContext } from 'react';
import Icons from '../../../Icons';
import { IconButton, Tooltip } from '@chakra-ui/react';
import { useActiveObject, useEditor } from 'components/Canvas/hooks';
import { EditorContext } from 'components/Canvas';

function Duplicate() {
  const editor = useEditor();
  const activeObject = useActiveObject();
  const { setActiveObject } = useContext(EditorContext);

  return (
    <Tooltip label="Duplicate">
      <IconButton
        onClick={() => {
          editor.clone();
          setActiveObject(activeObject);
        }}
        color="#000000"
        borderRadius="0"
        variant="ghost"
        size="sm"
        icon={<Icons.Duplicate size={24} />}
      />
    </Tooltip>
  );
}

export default Duplicate;
