export const actionCreator = type => {
  return payload => ({ type, payload });
};

export const actionWrapper = wrapperType => {
  return (type, action) => {
    return Object.assign(action, { type: `${wrapperType}.${type}` });
  };
};

export const createApiAction = (type, prepare) => {
  const apiActionCreator = function () {
    const prepareArguments = [type].concat(Array.from(arguments));
    const action = prepare.apply(null, prepareArguments);
    return action;
  };

  apiActionCreator.type = type;

  return apiActionCreator;
};
