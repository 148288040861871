import React from 'react';
import { withRouter } from 'react-router-dom';
import colors from 'theme/Colors.module.scss';
import './AnalyticsCard.scss';
import {
  Box,
  Text,
  Menu,
  MenuButton,
  MenuItem,
  Tooltip,
  MenuList,
  Button,
} from '@chakra-ui/react';
import placeholderCardViewImg from 'static/images/placeholder-card-view.png';
import { formatAOV, formatPercentage } from 'helpers/campaignHelper';
import { ChevronDownIcon } from '@chakra-ui/icons';

const AnalyticsCard = props => {
  const {
    startDate,
    endDate,
    campaignName,
    packages,
    conversions,
    conversionRate,
    average,
    template,
    qrScans,
    qrIx,
    history,
    to,
    isActive,
  } = props;

  return (
    <Box
      className="analytics-card"
      onClick={() => history.push(`/campaigns/${to}`)}
      backgroundColor={colors.coolGrey1}
    >
      <Box className="analytics-card__img-container" background={colors.white}>
        <Box
          className="analytics-card__img"
          bgImage={template ? template : placeholderCardViewImg}
        />
      </Box>

      <Box className="analytics-card__data-container">
        <p className="analytics-card__date">
          Start Date: {startDate === undefined ? '--' : startDate}
          &nbsp;&nbsp;&nbsp; End Date: {endDate === undefined ? '--' : endDate}
        </p>
        <span
          className="analytics-card__campaign-name ellipsis-span"
          style={{ color: colors.neutral700 }}
        >
          {campaignName}
        </span>
        <Box className="analytics-card__info-container">
          <Box className="analytics-card__status-container">
            <Box
              className="analytics-card__status-indicator"
              background={isActive ? colors.status00 : '#AAA'}
            />
            <span className="analytics-card__status">
              {isActive ? 'Active' : 'Inactive'}
            </span>
          </Box>
          <Box className="analytics-card__stats-container" paddingTop="5px">
            <Box className="analytics-card__stats" color={colors.primary900}>
              <span className="stats__title bold">Personalized Packages</span>
              <span className="stats__value">
                {Number(packages).toLocaleString()}
              </span>
            </Box>
            <Box className="analytics-card__stats" color={colors.neutral700}>
              <span className="stats__title">Conversions</span>
              <span className="stats__value">
                {Number(conversions).toLocaleString()}
              </span>
            </Box>
            <Box className="analytics-card__stats" color={colors.neutral700}>
              <span className="stats__title">Conversion Rate</span>
              <span className="stats__value">
                {formatPercentage(conversionRate)}%
              </span>
            </Box>
            <Box className="analytics-card__stats" color={colors.neutral700}>
              <span className="stats__title">Conversion AOV</span>
              <span className="stats__value">{formatAOV(average)}</span>
            </Box>
            <Box className="analytics-card__divider" />
            <Box className="analytics-card__stats" color={colors.black}>
              <span className="stats__title semi-bold">Total QR Scans</span>
              <Box className="analytics-td">
                {qrScans === 0 && qrScans}
                {qrScans > 0 && (
                  <Menu>
                    <MenuButton
                      onClick={e => e.stopPropagation()}
                      as={Button}
                      rightIcon={<ChevronDownIcon />}
                    >
                      {qrScans.toLocaleString()}
                    </MenuButton>
                    <MenuList>
                      {qrIx.map((item, index) => (
                        <MenuItem
                          key={index}
                          onClick={e => {
                            e.stopPropagation();
                            window.open(item.get('qr')[1], '_blank');
                          }}
                          minH="48px"
                        >
                          <Tooltip
                            bg="linear-gradient(0deg, rgba(241, 95, 36, 0.20) 0%, rgba(241, 95, 36, 0.20) 100%), #FFF"
                            label={
                              <Text color="#F15F24">{item.get('qr')[1]}</Text>
                            }
                            aria-label="A tooltip"
                          >
                            <Box>
                              <Text fontSize={'10px'} fontWeight={700}>
                                {item.get('qr')[0]}
                              </Text>
                              <Text fontSize={'12px'} fontWeight={400}>
                                {item.get('amount').toLocaleString()}
                              </Text>
                            </Box>
                          </Tooltip>
                        </MenuItem>
                      ))}
                    </MenuList>
                  </Menu>
                )}
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default withRouter(AnalyticsCard);
