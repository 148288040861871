import React from 'react';
import Joyride from 'react-joyride';

const steps = [
  {
    content: <h2>Learn how to use our editor</h2>,
    placement: 'center',
    target: 'body',
  },
  {
    content: 'Add static and dynamic text elements',
    placement: 'right',
    target: '.panel_text',
    hideCloseButton: true,
  },
  {
    target: '.panel_images',
    content: 'Add dynamic and static images',
    placement: 'right',
    hideCloseButton: true,
  },
  {
    target: '.panel_background',
    content: 'Change background',
    placement: 'right',
    hideCloseButton: true,
  },
  {
    target: '.panel_qr',
    content: 'Add dynamic QR code',
    placement: 'right',
  },
  {
    target: '.panel_barcode',
    content: 'Add dynamic barcode',
    placement: 'right',
  },
  {
    target: '.panel_templates',
    content: 'Select a premade template',
    placement: 'right',
  },
  {
    target: '.navbar_preview',
    content: 'Generate a preview of your design',
  },
];

function UserGuide() {
  return (
    <Joyride
      run={true}
      steps={steps}
      showProgress={true}
      scrollToFirstStep={true}
      continuous={true}
      hideCloseButton={true}
      showSkipButton={true}
      styles={{
        options: {
          arrowColor: '#e3ffeb',
          primaryColor: '#4E51D6',
          textColor: '#004a14',
        },
      }}
    />
  );
}

export default UserGuide;
