import { Box, Center, Divider } from '@chakra-ui/layout';
import React from 'react';
import Delete from './Delete';
import Duplicate from './Duplicate';
import Opacity from './Opacity';
// import Position from './Position'
import Lock from './Lock';
import CopyStyle from './CopyStyle';
import Personalize from './Personalize';

function Common() {
  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <Box name="personalize">
        <Personalize />
        {/* <Position /> */}
      </Box>
      <Center sx={{ margin: '0.5rem' }} height="30px">
        <Divider orientation="vertical" />
      </Center>
      <Box sx={{ display: 'flex', gap: '0.1rem' }}>
        <Box name="copyStyle">
          <CopyStyle />
        </Box>

        <Box name="opacity">
          <Opacity />
        </Box>
      </Box>

      <Center sx={{ margin: '0.5rem' }} height="30px">
        <Divider orientation="vertical" />
      </Center>

      <Box sx={{ display: 'flex', gap: '0.1rem' }}>
        <Box name="lock">
          <Lock />
        </Box>
        <Box name="duplicate">
          <Duplicate />
        </Box>
        <Box name="delete">
          <Delete />
        </Box>
      </Box>
    </div>
  );
}

export default Common;
