import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Button, useDisclosure } from '@chakra-ui/react';
import useSizeFormat from 'components/Canvas/hooks/useSizeFormat';
import Icons from '../Icons';
import PreviewModal from '../PreviewModal';
import FileSelector from './components/File';
import { useHistory } from 'react-router-dom';
import { useEditor } from 'components/Canvas';
import {
  isObjectURL,
  remoteImageToFile,
  templateHasChanged,
} from 'helpers/editor';
import CreateModal from './components/CreateModal';
import useTemplateEditorContext from 'scenes/templateEditor/hooks/useTemplateEditorContext';
import useDoubleSided from '../../../../components/Canvas/hooks/useDoubleSided';
import ConfirmChangesModal from './components/ConfirmChangesModal';

import { templateEditorActions } from 'modules/templateEditor/templateEditorDuck';
import { creativesActions } from 'modules/creatives/creativesDuck';
import * as appActions from 'modules/app/appActions';

const Navbar = props => {
  const { resetEditor, onTemplateSave } = props;

  const editor = useEditor();
  const [options, setOptions] = useState({
    createInProgress: false,
    updateInProgress: false,
    requestFail: false,
  });
  const {
    isOpen: isOpenPopover,
    onOpen: onOpenPopover,
    onClose: onClosePopover,
  } = useDisclosure();
  const {
    isOpen: isOpenModal,
    onOpen: onOpenModal,
    onClose: onCloseModal,
  } = useDisclosure();
  const {
    isOpen: isConfirmChangesModalOpen,
    onClose: onConfirmChangesModalClose,
    onOpen: onConfirmChangesModalOpen,
  } = useDisclosure();
  const {
    currentTemplate,
    setCurrentTemplate,
    setDoubleSidedBgImgUrls,
    doubleSidedBgImgUrls,
  } = useTemplateEditorContext();
  const sizeFormat = useSizeFormat();
  const { doubleSided } = useDoubleSided();
  const history = useHistory();

  const dispatch = useDispatch();

  // Dispatches
  const getTemplates = (payload, successCallback, failureCallback) =>
    dispatch(
      creativesActions.getTemplates(payload, successCallback, failureCallback)
    );
  const createTemplateDoubleSided = (
    payload,
    successCallback,
    failureCallback
  ) =>
    dispatch(
      templateEditorActions.createTemplateDoubleSided(
        payload,
        successCallback,
        failureCallback
      )
    );
  const updateTemplateByIdDoubleSided = (
    emplateID,
    payload,
    successCallback,
    failureCallback
  ) =>
    dispatch(
      templateEditorActions.updateTemplateByIdDoubleSided(
        emplateID,
        payload,
        successCallback,
        failureCallback
      )
    );
  const getUserFonts = (successCallback, failureCallback) =>
    dispatch(
      templateEditorActions.getUserFonts(successCallback, failureCallback)
    );
  const setMessage = payload => dispatch(appActions.setMessage(payload));

  const getTemplateLabel = () => {
    if (sizeFormat) {
      const { pixelWidth, pixelHeight } = sizeFormat;
      const width = sizeFormat.isPortrait ? pixelWidth : pixelHeight;
      const height = sizeFormat.isPortrait ? pixelHeight : pixelWidth;
      return (
        <Box sx={{ display: 'flex' }}>
          <Box>{width}px</Box> <Box mx="0.5rem">x</Box> <Box>{height}px</Box>
        </Box>
      );
    }
  };
  const updateTemplateHandler = async () => {
    setOptions({ ...options, updateInProgress: true });
    const payload = await editor.handlers.templateHandler.exportToJSON();

    let invalidFile = false;

    if (doubleSided === false) {
      const url = payload.bg_img?.original_url;
      if (url !== currentTemplate.bg_img?.original_url) {
        if (url && isObjectURL(url)) {
          const fileName = editor.handlers.frameHandler.backgroundFileName;
          let blob = await fetch(url).then(r => r.blob());
          const file = new File([blob], fileName, { type: blob.type });
          payload.bg_img = file;
          invalidFile = !!file.type.includes('webp');
        } else if (url) {
          const file = await remoteImageToFile(url);
          payload.bg_img = file;
          invalidFile = !!file.type.includes('webp');
        } else {
          payload.bg_img = '';
        }
      }
    } else {
      for (let i = 0; i < payload.ad_template_pages.length; i++) {
        const url = payload.ad_template_pages[i].bg_img?.original_url;
        const fileName = payload.ad_template_pages[i].bg_img?.fileName;
        if (url && isObjectURL(url)) {
          let blob = await fetch(url).then(r => r.blob());
          const file = new File(
            [blob],
            fileName + '.' + blob.type.split('/')[1],
            { type: blob.type }
          );
          payload.ad_template_pages[i].bg_img = file;
          invalidFile = !!file.type.includes('webp');
        } else if (url) {
          const file = await remoteImageToFile(url);
          payload.ad_template_pages[i].bg_img = file;
          invalidFile = !!file.type.includes('webp');
        }
      }
    }
    // clean up importInit functions so they aren't run on next load of template
    const cleanedVars = payload.ad_template_vars.map(x => {
      if (x.metadata.importInit) {
        delete x.metadata.importInit;
      }
      return x;
    });
    payload.ad_template_vars = cleanedVars;

    if (payload.metadata?.importInit) {
      console.log('deleting importinit');
      delete payload.metadata.importInit;
    }

    try {
      let updatedTemplate = await new Promise((resolve, reject) => {
        updateTemplateByIdDoubleSided(
          currentTemplate.id,
          payload,
          data => resolve(data),
          err => reject(err)
        );
      });

      // ensure any new entity ids created for template vars are updated into
      // the var objects in the editor
      editor.handlers.templateHandler.setNewEntityIds(updatedTemplate);

      if (doubleSided) {
        setDoubleSidedBgImgUrls([
          updatedTemplate?.ad_template_pages?.[0]?.bg_img?.original_url || '',
          updatedTemplate?.ad_template_pages?.[1]?.bg_img?.original_url || '',
        ]);
      } else {
        setDoubleSidedBgImgUrls([
          updatedTemplate?.bg_img?.original_url || '',
          '',
        ]);
      }

      // refetch user fonts after update
      getUserFonts(() => {});

      
      if (updatedTemplate !== 'Bad Request') {
        setCurrentTemplate(updatedTemplate);
        setMessage({
          message: 'Updated template',
          type: 'success',
        });
      } else {
        invalidFile &&
          setMessage({
            type: 'error',
            message: 'Background image format is invalid',
          });
      }
    } catch (err) {
      console.log("Error updating template.")
    } finally {
      setOptions({ ...options, updateInProgress: false });
    }    
  };

  const createTemplateHandler = async name => {
    setOptions({ ...options, createInProgress: true });
    const template = await editor.handlers.templateHandler.exportToJSON();
    const payload = { ...template, name };

    let invalidFile = false;

    if (doubleSided === false) {
      const url = payload.bg_img?.original_url;
      if (url && isObjectURL(url)) {
        const fileName = editor.handlers.frameHandler.backgroundFileName;
        let blob = await fetch(url).then(r => r.blob());
        const file = new File([blob], fileName, { type: blob.type });
        payload.bg_img = file;
        invalidFile = !!file.type.includes('webp');
      } else if (url) {
        const file = await remoteImageToFile(url);
        payload.bg_img = file;
        invalidFile = !!file.type.includes('webp');
      }
    } else {
      for (let i = 0; i < payload.ad_template_pages.length; i++) {
        const url = payload.ad_template_pages[i].bg_img?.original_url;
        const fileName = payload.ad_template_pages[i].bg_img?.fileName;
        if (url && isObjectURL(url)) {
          let blob = await fetch(url).then(r => r.blob());
          const file = new File(
            [blob],
            fileName + '.' + blob.type.split('/')[1],
            { type: blob.type }
          );
          payload.ad_template_pages[i].bg_img = file;
          invalidFile = !!file.type.includes('webp');
        } else if (url) {
          const file = await remoteImageToFile(url);
          payload.ad_template_pages[i].bg_img = file;
          invalidFile = !!file.type.includes('webp');
        }
      }
    }

    let createdTemplate = await new Promise((resolve, reject) => {
      createTemplateDoubleSided(
        payload,
        data => resolve(data),
        err => reject(err)
      );
    });

    // ensure any new entity ids created for template vars are updated into
    // the var objects in the editor
    editor.handlers.templateHandler.setNewEntityIds(createdTemplate);

    // refetch user fonts after save
    getUserFonts(() => {});

    if (createdTemplate !== 'Bad Request') {
      if (onTemplateSave) {
        onCloseModal();
        onTemplateSave(createdTemplate);
        setOptions({ ...options, createInProgress: false });
        getTemplates({}, () => {});
      } else {
        const id = createdTemplate.id;
        id && history.push(`/template-editor?id=${id}`);
        setOptions({ ...options, createInProgress: false });
        getTemplates({}, () => {});
        setMessage({
          message: 'Saved template',
          type: 'success',
        });
        onCloseModal();
      }
    } else {
      setOptions({ ...options, requestFail: true });
      if (invalidFile) {
        setMessage({
          type: 'error',
          message: 'Background image format is invalid',
        });
      }
    }
  };

  const leaveEditor = () =>
    onTemplateSave
      ? onTemplateSave()
      : history.location.state
      ? history.goBack()
      : history.push('/templates');

  const onExit = async () => {
    const payload = await editor.handlers.templateHandler.exportToJSON();

    if (doubleSided) {
      const frontHasChanged = await templateHasChanged(
        currentTemplate?.ad_template_pages?.[0],
        payload.ad_template_pages[0],
        doubleSidedBgImgUrls[0]
      );
      const backHasChanged = await templateHasChanged(
        currentTemplate?.ad_template_pages?.[1],
        payload.ad_template_pages[1],
        doubleSidedBgImgUrls[1]
      );
      !frontHasChanged && !backHasChanged
        ? leaveEditor()
        : onConfirmChangesModalOpen();
    } else {
      const hasChanged = await templateHasChanged(
        currentTemplate,
        payload,
        doubleSidedBgImgUrls[0]
      );
      !hasChanged ? leaveEditor() : onConfirmChangesModalOpen();
    }
  };

  const closeConfirmModal = () => {
    onConfirmChangesModalClose();
    leaveEditor();
  };

  const onChangesSaved = async () => {
    if (currentTemplate) {
      await updateTemplateHandler();
      closeConfirmModal();
    } else {
      onOpenModal();
    }
    onConfirmChangesModalClose();
  };

  return (
    <>
      <CreateModal
        isOpen={isOpenModal}
        onClose={onCloseModal}
        onSave={createTemplateHandler}
        createInProgress={options.createInProgress}
        requestFail={options.requestFail}
      />
      {isOpenPopover && (
        <PreviewModal
          isOpen={isOpenPopover}
          onOpen={onOpenPopover}
          onClose={onClosePopover}
        />
      )}

      <ConfirmChangesModal
        isOpen={isConfirmChangesModalOpen}
        onClose={onConfirmChangesModalClose}
        onSave={onChangesSaved}
        onCancel={closeConfirmModal}
      />
      <Box
        className="template-editor-navbar"
        onClick={() => {
          editor.closeKeysMenu();
          editor.closeContextMenu();
        }}
        sx={{
          height: '4rem',
          background: 'linear-gradient(90deg,#4e90d6,#4e50d6 53%,#7f66f1)',
          flex: 'none',
          display: 'grid',
          alignItems: 'center',
          padding: '0 1rem',
          zIndex: '100',
          gridTemplateColumns: '360px 1fr 360px',
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Box
            onClick={onExit}
            sx={{
              display: 'flex',
              alignItems: 'center',
              fontWeight: 500,
              height: '42px',
              paddingRight: '1rem',
              fontSize: '14px',
              color: '#ffffff',
              ':hover': {
                background: 'rgba(255,255,255,0.15)',
                cursor: 'pointer',
              },
            }}
          >
            <Icons.ChevronLeft size={24} />
            Exit
          </Box>
          <FileSelector resetEditor={resetEditor} />
        </Box>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            fontSize: '14px',
            fontWeight: 600,
            color: '#ffffff',
          }}
        >
          <Box>{getTemplateLabel()}</Box>
        </Box>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            gap: '0.75rem',
            color: '#ffffff',
          }}
        >
          <Button
            onClick={currentTemplate ? updateTemplateHandler : onOpenModal}
            variant="variant"
            sx={{
              color: '#ffffff',
              borderRadius: '2px',
              fontSize: '14px',
              background: 'rgba(255,255,255,0.1)',
            }}
            _hover={{
              background: 'rgba(255,255,255,0.2)',
            }}
            disabled={options.updateInProgress ? true : false}
          >
            {currentTemplate ? (
              <>{options.updateInProgress ? 'Saving' : 'Save'}</>
            ) : (
              'Create'
            )}
          </Button>
          <Button
            onClick={onOpenPopover}
            className="navbar_preview"
            sx={{
              color: '#333333',
              borderRadius: '2px',
              fontSize: '14px',
            }}
          >
            Preview
          </Button>
        </Box>
      </Box>
    </>
  );
};

export default Navbar;
