import React, { useEffect, useRef } from 'react';
import { isEmpty } from 'lodash';
import './Tooltip.scss';

// This component requires the "tipText" prop.
// The "icon" prop is optional but is the default if icon={false} is not provided.
// The "text" prop is optional -- it allows you to use text alongside, or in place of, an icon.
// The "direction" prop is optional -- if it is not provided, the tooltip will be placed generically around the user mouse location.

const setPosition = (e, direction, tipElement) => {
  const tip = tipElement?.current;
  const x = e.clientX;
  const y = e.clientY;

  if (tip && !isEmpty(direction)) {
    const setStyle = tip.style;
    const tipHeight = tip.clientHeight;
    const tipWidth = tip.clientWidth;

    if (direction === 'top') {
      setStyle.setProperty('left', x - tipWidth / 2 + 'px');
      setStyle.setProperty('top', y - (tipHeight + 20) + 'px');
    }

    if (direction === 'bottom') {
      setStyle.setProperty('left', x - tipWidth / 2 + 'px');
      setStyle.setProperty('top', y + 20 + 'px');
    }

    if (direction === 'left') {
      setStyle.setProperty('left', x - (tipWidth + 28) + 'px');
      setStyle.setProperty('top', y - tipHeight / 2 + 'px');
    }

    if (direction === 'right') {
      setStyle.setProperty('left', x + 28 + 'px');
      setStyle.setProperty('top', y - tipHeight / 2 + 'px');
    }
  } else {
    const setStyle = tip?.style;

    setStyle?.setProperty('left', x + 16 + 'px');
    setStyle?.setProperty('top', y + 16 + 'px');
  }
};

const Tooltip = props => {
  const {
    direction,
    icon,
    text,
    tipText,
    bgColor,
    render,
    fullWidth,
    children,
  } = props;
  const tipElement = useRef();

  useEffect(() => {
    if (tipText && tipElement.current) {
      window.addEventListener('mousemove', e =>
        setPosition(e, direction, tipElement)
      );
      return () =>
        window.removeEventListener('mouseMove', e =>
          setPosition(e, direction, tipElement)
        );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tipText, tipElement]);

  const renderIcon = () => {
    if (icon !== false) {
      return <i className="fas fa-info-circle" />;
    }
  };

  return (
    <span className="tooltip">
      {(() => {
        switch (true) {
          case !!children:
            return children;
          case !!render:
            return render();
          case !!text:
            return text;
          default:
            return renderIcon();
        }
      })()}

      {tipText && (
        <span
          className={`${fullWidth && 'fullWidth'}`}
          style={{ backgroundColor: bgColor }}
          ref={tipElement}
        >
          {typeof tipText === 'function' ? tipText() : tipText}
        </span>
      )}
    </span>
  );
};

export default Tooltip;
