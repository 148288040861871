export const findRectCenter = (left, top, width, height, degrees) => {
  const radians = (degrees * Math.PI) / 180;
  const cosa = Math.cos(radians);
  const sina = Math.sin(radians);
  const wp = width / 2;
  const hp = height / 2;
  return {
    x: left + wp * cosa - hp * sina,
    y: top + wp * sina + hp * cosa,
  };
};

/**
 * Find point after rotation around another point by X degrees
 *
 * @param {Array} point The point to be rotated [X,Y]
 * @param {Array} rotationCenterPoint The point that should be rotated around [X,Y]
 * @param {Number} degrees The degrees to rotate the point
 * @return {Array} Returns point after rotation [X,Y]
 */
export const rotatePoint = (point, rotationCenterPoint, degrees) => {
  // Using radians for this formula
  const radians = (degrees * Math.PI) / 180;

  // Translate the plane on which rotation is occurring.
  // We want to rotate around 0,0. We'll add these back later.
  point[0] -= rotationCenterPoint[0];
  point[1] -= rotationCenterPoint[1];

  // Perform the rotation
  const newPoint = [];
  newPoint[0] = point[0] * Math.cos(radians) - point[1] * Math.sin(radians);
  newPoint[1] = point[0] * Math.sin(radians) + point[1] * Math.cos(radians);

  // Translate the plane back to where it was.
  newPoint[0] += rotationCenterPoint[0];
  newPoint[1] += rotationCenterPoint[1];

  return newPoint;
};

/**
 * Find the vertices of a rotating rectangle
 *
 * @param {Array} position From left, top [X,Y]
 * @param {Array} size Lengths [X,Y]
 * @param {Number} degrees Degrees rotated around center
 * @param {Array} rotationCenterPoint The point that should be rotated around [X,Y]
 * @return {Object} Arrays LT, RT, RB, LB [X,Y]
 */

export const findRectVertices = (
  position,
  size,
  degrees,
  rotationCenterPoint
) => {
  const left = position[0];
  const right = position[0] + size[0];
  const top = position[1];
  const bottom = position[1] + size[1];

  const LT = [left, top];
  const RT = [right, top];
  const RB = [right, bottom];
  const LB = [left, bottom];

  return {
    LT: rotatePoint(LT, rotationCenterPoint, degrees),
    RT: rotatePoint(RT, rotationCenterPoint, degrees),
    RB: rotatePoint(RB, rotationCenterPoint, degrees),
    LB: rotatePoint(LB, rotationCenterPoint, degrees),
  };
};

/**
 * Distance formula
 *
 * @param {Array} p1 First point [X,Y]
 * @param {Array} p2 Second point [X,Y]
 * @return {Number} Returns distance between points
 */
export const distance = (p1, p2) => {
  return Math.sqrt(Math.pow(p1[0] - p2[0], 2) + Math.pow(p1[1] - p2[1], 2));
};

/**
 * Heron's formula (triangle area)
 *
 * @param {Number} d1 Distance, side 1
 * @param {Number} d2 Distance, side 2
 * @param {Number} d3 Distance, side 3
 * @return {Number} Returns area of triangle
 */
export const triangleArea = (d1, d2, d3) => {
  // See https://en.wikipedia.org/wiki/Heron's_formula
  const s = (d1 + d2 + d3) / 2;
  return Math.sqrt(s * (s - d1) * (s - d2) * (s - d3));
};

/**
 * Determine if a click hit a rotated rectangle
 *
 * @param {Array} click Click position [X,Y]
 * @param {Array} position Rect from left, top [X,Y]
 * @param {Array} size Rect size as lengths [X,Y]
 * @param {Number} degrees Degrees rotated around center
 * @param {Array} rotationCenterPoint The point that should be rotated around [X,Y]
 * @return {Boolean} Returns true if hit, false if miss
 */
export const clickHit = (
  click,
  position,
  size,
  degrees,
  rotationCenterPoint
) => {
  // Find the area of the rectangle
  // Round to avoid small JS math differences
  const rectArea = Math.round(size[0] * size[1]);
  const originalPosition = rotatePoint(
    position,
    rotationCenterPoint,
    -1 * degrees
  );
  // Find the vertices
  const vertices = findRectVertices(
    originalPosition,
    size,
    degrees,
    rotationCenterPoint
  );

  // Create an array of the areas of the four triangles
  let triArea = [
    // Click, LT, RT
    triangleArea(
      distance(click, vertices.LT),
      distance(vertices.LT, vertices.RT),
      distance(vertices.RT, click)
    ),
    // Click, RT, RB
    triangleArea(
      distance(click, vertices.RT),
      distance(vertices.RT, vertices.RB),
      distance(vertices.RB, click)
    ),
    // Click, RB, LB
    triangleArea(
      distance(click, vertices.RB),
      distance(vertices.RB, vertices.LB),
      distance(vertices.LB, click)
    ),
    // Click, LB, LT
    triangleArea(
      distance(click, vertices.LB),
      distance(vertices.LB, vertices.LT),
      distance(vertices.LT, click)
    ),
  ];

  // Reduce this array with a sum function
  // Round to avoid small JS math differences
  triArea = Math.round(
    triArea.reduce(function (a, b) {
      return a + b;
    }, 0)
  );

  // Finally do that simple thing we visualized earlier
  return triArea <= rectArea;
};
