import React, { Fragment, useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import * as appActions from 'modules/app/appActions';
import {
  paramTemplateTypes,
  customName,
} from '../ParamTemplates/ParamTemplates';
import { isEmpty } from 'lodash';
import { useFlag } from 'flags';
import { Field } from 'formik';
import Button from 'components/Button/Button';
import FieldProto from 'components/FieldProto/FieldProto';
import InputFeedback from 'components/InputFeedback/InputFeedback';
import colors from 'theme/Colors.module.scss';
import './Param.scss';
import DatePicker from 'components/DatePicker/DatePicker';
import { parseISO } from 'date-fns';
import { zonedTimeToUtc } from 'date-fns-tz';

const userTz = Intl.DateTimeFormat().resolvedOptions().timeZone;

const greaterOrEq = ['Greater/Equal to', 'ge', '>='];
const lessOrEq = ['Less/Equal to', 'le', '<='];
const greater = ['Greater than', 'gt', '>'];
const less = ['Less than', 'lt', '<'];
const equals = ['Equal to', 'eq', '='];
const notEquals = ['Not equal to', 'ne', '!='];
const on = ['On', 'eq', '='];
const onBefore = ['On or before', 'le', '<='];
const before = ['Before', 'lt', '<'];
const onAfter = ['On or after', 'ge', '>='];
const after = ['After', 'gt', '>'];
const notOn = ['Not on', 'ne', '!='];
const isEquals = ['Is', 'eq', '='];
const notIsEquals = ['Is not', 'ne', '!='];
const contains = ['Contains', 'con'];
const notContains = ['Does not contain', 'ncon'];
const starts = ['Starts with', 'starts'];
const inList = ['Is one of', 'in'];
const notInList = ['Is not one of', 'nin'];
const intOpValues = [greater, less, greaterOrEq, lessOrEq, equals, notEquals];
const decOpValues = [greater, less, greaterOrEq, lessOrEq, equals, notEquals];
const dtOpValues = [on, before, after, onBefore, onAfter, notOn];
const strOpValues = [isEquals, starts, contains, notIsEquals, notContains];
const listOpValues = [isEquals, notIsEquals, inList, notInList];
const customOpValues = [
  ...strOpValues,
  ...intOpValues,
  ...decOpValues,
  ...dtOpValues,
  ...listOpValues,
];

export const opTypeValues = {
  dt: dtOpValues,
  str: strOpValues,
  int: intOpValues,
  dec: decOpValues,
  // eslint-disable-next-line no-useless-computed-key
  ['[str]']: listOpValues,
  custom: customOpValues,
};

const customSelect = 'customSelect';
const caret = 'fas fa-caret-down';
let errorFound = false;

const Param = props => {
  const {
    remove,
    name,
    index,
    item,
    companyParams,
    recentlyUsedKeys,
    ...rest
  } = props;
  const [customMenu, setCustomMenu] = useState(false);
  const [filteredOp, setFilteredOp] = useState('');
  const [holdFocus, setHoldFocus] = useState(false);
  const dispatch = useDispatch();

  // if custom data field, set field to key, so user can input a key
  const primaryFieldName = item.name === customName ? 'key' : 'name';
  const value_type = !isEmpty(item) && item.value_type;
  const paramName = !isEmpty(item) && item.name;
  const fieldArrayName = name;
  const isReadOnly = useFlag(['features', 'isReadOnly']);

  // Actions
  const setMessage = payload => dispatch(appActions.setMessage(payload));
  // const clearMessage = index => dispatch(appActions.clearMessage(index));

  const resetCustomOpMenu = () => {
    setFilteredOp('');
    setCustomMenu(false);
  };

  useEffect(() => {
    return () => {
      resetCustomOpMenu();
      // clearMessage(0);
      errorFound = false;
    };
  }, []);

  const args = {
    fieldArrayName,
    index,
    value_type,
    item,
    customMenu,
    setCustomMenu,
    filteredOp,
    setFilteredOp,
    resetCustomOpMenu,
    holdFocus,
    setHoldFocus,
    paramName,
    isReadOnly,
    setMessage,
    rest,
  };

  return (
    <div className={`paramGroup ${isReadOnly && 'readOnly'}`}>
      <div className="mainParam">
        <div className={`orContainer ${index === 0 && 'noOrLink'}`}>
          <span className="or">
            {index !== 0 ? 'or' : <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>}
          </span>
        </div>

        <Field
          validate={value => {
            let errorMessage = 'Key required.';
            if (value === 'custom_key' || value === '') return errorMessage;
          }}
          name={`${fieldArrayName}.${index}.${primaryFieldName}`}
        >
          {({ field, form }) =>
            buildPrimaryField({
              ...args,
              form,
              field,
              companyParams,
              recentlyUsedKeys,
            })
          }
        </Field>
      </div>

      <div className="secondGroup">
        <div className="opField">
          <Field name={`${fieldArrayName}.${index}.op`}>
            {({ field, form }) => buildOpField({ ...args, form, field })}
          </Field>
        </div>

        <div className="secondParam">
          <Field
            validate={value => {
              let errorMessage = 'Value required.';
              if (value === undefined) return errorMessage;
            }}
            name={`${fieldArrayName}.${index}.value`}
          >
            {({ field, form }) => {
              return buildSecondaryField({ ...args, form, field });
            }}
          </Field>
        </div>
      </div>

      <div className="rmvParam">
        <Button
          type="button"
          icon="remove"
          iconColor={colors.greyDisabled}
          btnStyle="transparent"
          callback={() => remove(index)}
          {...rest}
        />
      </div>
    </div>
  );
};

const buildPrimaryField = args => {
  const {
    fieldArrayName,
    index,
    field,
    form,
    rest,
    isReadOnly,
    companyParams,
    // recentlyUsedKeys,
  } = args;

  let options = paramTemplateTypes.map(option => {
    return {
      key: option.key,
      value: option.name,
      label: option.name === customName ? 'Enter custom key...' : option.name,
    };
  });

  const companyParamsOptions = companyParams.map(({ key, label }) => ({
    key,
    label: label,
    value: label,
    optgroup: 'Configured',
  }));

  const defaultOptions = options.map(option => ({
    ...option,
    optgroup: 'Common',
  }));

  if (companyParams.length) {
    options = [...companyParamsOptions, ...defaultOptions];
  }

  // eslint-disable-next-line no-eval
  const fieldRule = eval(`form.values.${fieldArrayName}[${index}]`);

  const optionsTypes = [
    ...paramTemplateTypes,
    ...companyParams.map(({ id, key, label }) => ({
      id,
      name: label,
      key,
      kv_relational_op: 'eq',
      sort: null,
      value: '',
      value_type: 'str',
    })),
  ];

  return fieldRule.name === customName ? (
    <FieldProto
      type="text"
      autoFocus={fieldRule.key === 'custom_key'}
      disabled={isReadOnly}
      {...rest}
      {...field}
      value={fieldRule.key !== 'custom_key' ? fieldRule.key : ''}
      placeholder="Data key: i.e. zipcode"
    />
  ) : (
    <FieldProto
      type="select"
      options={options}
      disabled={isReadOnly}
      {...rest}
      {...field}
      onChange={e => {
        const newParam = Object.assign(
          {},
          optionsTypes.find(option => option.name === e.target.value)
        );

        const defaultKV = () => {
          const type = newParam.value_type;

          switch (type) {
            case '[str]':
              return 'in';
            case 'bool':
              return 'eq';
            default:
              return opTypeValues[type][0][1];
          }
        };

        newParam.kv_relational_op = defaultKV();

        form.setFieldValue(`${fieldArrayName}.${index}`, newParam);
      }}
    />
  );
};

const buildOpField = args => {
  const {
    fieldArrayName,
    index,
    field,
    value_type,
    form,
    item,
    customMenu,
    setCustomMenu,
    filteredOp,
    setFilteredOp,
    resetCustomOpMenu,
    holdFocus,
    setHoldFocus,
    isReadOnly,
    setMessage,
    rest,
  } = args;

  // Operand menu for custom_keys with options.
  if (item.name === customName) {
    const setValByType = (type, op) => {
      const newParam = Object.assign({}, item);
      newParam.kv_relational_op = op;

      if (value_type !== type) {
        newParam.value = '';
        newParam.value_type = type;
      }

      form.setFieldValue(`${fieldArrayName}.${index}`, newParam);
      setHoldFocus(false);
      resetCustomOpMenu();
    };

    const userFilter = opArrays => {
      const val = filteredOp?.toLowerCase();
      const filtered = opArrays.filter(arr => {
        let found;

        !found &&
          arr.forEach(item => {
            if (item.toLowerCase().includes(val)) {
              found = true;
            }
          });

        return found;
      });

      return filtered;
    };

    const opArray = opTypeValues[item.value_type].find(
      opType => opType[1] === item.kv_relational_op
    );
    const opValue = opArray && opArray[0];

    if (!opValue && !errorFound) {
      const message = 'Unable to load operator — kv_relational_op not found.';
      setMessage({ message, type: 'error' });
      errorFound = true;
    }

    const makeSectionList = (type, title, list) => {
      return (
        <Fragment>
          <p className="typeTitle">{title}</p>
          <ul className={`${type === '[str]' ? 'list' : type}Operands`}>
            {userFilter(list).map((op, i) => {
              const active =
                item.value_type === type && op[1] === item.kv_relational_op;

              return (
                <li
                  key={i}
                  className={active ? 'active' : ''}
                  onMouseDown={e => setHoldFocus(true)}
                  onClick={() => setValByType(type, op[1])}
                >
                  {op[0]}
                </li>
              );
            })}
          </ul>
        </Fragment>
      );
    };

    return (
      <Fragment>
        <div className="customOpSelect">
          <FieldProto
            id={`custom-op-select-input-${fieldArrayName}-${index}`}
            type="text"
            className={`${customSelect} ${isReadOnly && 'readOnly'}`}
            autoComplete="off"
            useArrow
            placeholder={opValue}
            value={filteredOp}
            disabled={isReadOnly}
            onChange={e => setFilteredOp(e.currentTarget.value)}
            onFocus={e => setCustomMenu(true)}
            onBlur={e => !holdFocus && resetCustomOpMenu()}
          />

          <div
            className={`customArrow`}
            onClick={() =>
              document
                .getElementById(
                  `custom-op-select-input-${fieldArrayName}-${index}`
                )
                .focus()
            }
          >
            <i className={caret} />
          </div>
        </div>

        {customMenu && (
          <div className="customOpList scrollbar">
            {!isEmpty(userFilter(strOpValues)) &&
              makeSectionList('str', 'String', strOpValues)}
            {!isEmpty(userFilter(intOpValues)) &&
              makeSectionList('int', 'Number', intOpValues)}
            {!isEmpty(userFilter(decOpValues)) &&
              makeSectionList('dec', 'Decimal', decOpValues)}
            {!isEmpty(userFilter(dtOpValues)) &&
              makeSectionList('dt', 'Date', dtOpValues)}
            {!isEmpty(userFilter(listOpValues)) &&
              makeSectionList('[str]', 'List', listOpValues)}
          </div>
        )}
      </Fragment>
    );
  }

  const makeOpField = type => {
    const options = opTypeValues[`${type}`].map(o => ({
      key: o[0],
      value: o[1],
      label: o[0],
    }));
    const updateOpVal = e => {
      const newParam = Object.assign({}, item);
      const new_kv_val = opTypeValues[value_type][e.target.selectedIndex][1];
      newParam.kv_relational_op = new_kv_val;

      form.setFieldValue(`${fieldArrayName}.${index}`, newParam);
    };

    return (
      <FieldProto
        type="select"
        disabled={isReadOnly}
        options={options}
        selected={item.kv_relational_op}
        className="secondary"
        {...rest}
        {...field}
        onChange={updateOpVal}
      />
    );
  };

  if (value_type === 'int') return makeOpField('int');
  if (value_type === 'dec') return makeOpField('dec');
  if (value_type === 'dt') return makeOpField('dt');
  if (value_type === 'str') return makeOpField('str');
  if (value_type === '[str]') return makeOpField('[str]');

  return null;
};

const buildSecondaryField = args => {
  const { field, value_type, paramName, form, item, isReadOnly, rest } = args;
  const { kv_relational_op } = item;
  const equalityType = kv_relational_op === 'eq' || kv_relational_op === 'ne';
  const leaveEmpty = 'or leave blank';

  // Number param options
  if (value_type === 'int') {
    return (
      <FieldProto
        type="number"
        placeholder={`i.e. 0.00 ${equalityType ? leaveEmpty : ''}`}
        className="secondary"
        disabled={isReadOnly}
        {...rest}
        {...field}
      />
    );
  }

  if (value_type === 'dec') {
    return (
      <FieldProto
        type="number"
        placeholder={`i.e. 0.00 ${equalityType ? leaveEmpty : ''}`}
        className="secondary"
        disabled={isReadOnly}
        {...rest}
        {...field}
      />
    );
  }

  // Date param options
  if (value_type === 'dt') {
    let selectedDt = null;
    if (field.value) {
      selectedDt = parseISO(field.value);
    }
    return (
      <React.Fragment>
        <DatePicker
          inputStyle={{ width: '100%' }}
          selectedDate={selectedDt}
          onInputChange={dt => {
            const utcDate = zonedTimeToUtc(dt, userTz);
            // NOTE: this should be a UTC timestamp for a day that is saved along with
            // the user's timezone so that the backend can parse the date correctly based
            // on the user's timezone when the date was entered.  THe backend does not
            // support this yet, so instead we just save the date portion of the string.
            // jk 2/14/24
            form.setFieldValue(field.name, utcDate.toISOString().split('T')[0]);
          }}
        />
        <InputFeedback name={field.name} />
      </React.Fragment>
    );
  }

  // String, List and Custom param options
  if (value_type === 'str' || value_type === '[str]') {
    if (paramName === 'Shipped to City') {
      return (
        <FieldProto
          type="text"
          placeholder={`City ${equalityType ? leaveEmpty : ''}`}
          disabled={isReadOnly}
          {...rest}
          {...field}
        />
      );
    }

    if (paramName === 'Shipped to State' && kv_relational_op !== 'con') {
      return (
        <FieldProto
          type="select"
          disabled={isReadOnly}
          options={[
            { key: '1', value: '', label: 'None/Select One' },
            ...allStates.map(o => ({ key: o, value: o, label: o })),
          ]}
          {...rest}
          {...field}
        />
      );
    }

    return (
      <FieldProto
        type="text"
        placeholder={`i.e. Custom ${equalityType ? leaveEmpty : ''}`}
        disabled={isReadOnly}
        {...rest}
        {...field}
      />
    );
  }

  return null;
};

const allStates = [
  'Alabama',
  'Alaska',
  'Arizona',
  'Arkansas',
  'California',
  'Colorado',
  'Connecticut',
  'Delaware',
  'Florida',
  'Georgia',
  'Hawaii',
  'Idaho',
  'Illinois',
  'Indiana',
  'Iowa',
  'Kansas',
  'Kentucky',
  'Louisiana',
  'Maine',
  'Maryland',
  'Massachusetts',
  'Michigan',
  'Minnesota',
  'Mississippi',
  'Missouri',
  'Montana',
  'Nebraska',
  'Nevada',
  'New Hampshire',
  'New Jersey',
  'New Mexico',
  'New York',
  'North Carolina',
  'North Dakota',
  'Ohio',
  'Oklahoma',
  'Oregon',
  'Pennsylvania',
  'Rhode Island',
  'South Carolina',
  'South Dakota',
  'Tennessee',
  'Texas',
  'Utah',
  'Vermont',
  'Virginia',
  'Washington',
  'West Virginia',
  'Wisconsin',
  'Wyoming',
];

export default Param;
