import { schema } from 'normalizr';
import { isEmpty } from 'lodash';
import moment from 'moment';

function toDate(date) {
  const milliseconds = moment(date);
  const newDate = new Date(milliseconds);

  return newDate;
}

export default {
  schema: new schema.Entity(
    'campaigns',
    {},
    {
      processStrategy: entity => {
        let newEntity = Object.assign({}, entity);

        if (!isEmpty(newEntity.start_date)) {
          newEntity.start_date = toDate(newEntity.start_date);
        }

        if (!isEmpty(newEntity.end_date)) {
          newEntity.end_date = toDate(newEntity.end_date);
        }

        // if (!isEmpty(newEntity.start_date_2)) {
        //   newEntity.start_date_2 = toDate(newEntity.start_date_2);
        // }

        // if (!isEmpty(newEntity.end_date_2)) {
        //   newEntity.end_date_2 = toDate(newEntity.end_date_2);
        // }

        return newEntity;
      },
    }
  ),
};
