import React from 'react';

function SortAsc({ height = 12, width = 14, onClick, style = {} }) {
  return (
    <svg
      width={width}
      height={height}
      style={{ cursor: 'pointer' }}
      onClick={onClick}
      viewBox="0 0 14 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_3012_11683)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.0105 11.2795L6.0105 2.30226L4.2829 3.83621C3.9976 4.10681 3.51119 4.10681 3.22627 3.83621C2.92442 3.58044 2.92442 3.14438 3.22627 2.88895L6.19507 0.227449V0.212285H6.21198V0.19712H6.2289V0.181956H6.26235V0.166792H6.27927V0.151628H6.29618L6.3131 0.136463L6.33001 0.121299H6.34693V0.106135H6.36384L6.38076 0.09097H6.39767V0.0758066H6.43113L6.44804 0.0606422H6.46496L6.48187 0.0454769H6.51533L6.53224 0.0303135H6.58261V0.0151491H6.68335V-1.43051e-05H6.81754L6.83446 0.0151491H6.9352V0.0303135H6.98557V0.0454769H7.03594V0.0606422H7.06939V0.0758066H7.10285L7.11976 0.09097H7.13668V0.106135H7.15359L7.17051 0.121299H7.18742V0.136463H7.20434V0.151628H7.23779C7.25471 0.18162 7.28816 0.196783 7.32162 0.226775L10.2904 2.88828C10.5757 3.14405 10.5757 3.5801 10.2904 3.83554C9.98858 4.10613 9.51871 4.10613 9.23378 3.83554L7.50618 2.30159L7.50618 11.2795C7.50618 11.6556 7.17051 11.9562 6.75139 11.9562C6.34881 11.9562 6.01351 11.6556 6.01351 11.2795H6.0105Z"
          fill={style.color}
        />
      </g>
      <defs>
        <clipPath id="clip0_3012_11683">
          <rect width={width} height={height} fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default SortAsc;
