import React from 'react';

import Pagination from 'components/Pagination/Pagination';

export default ({ total, page, size, onChangePage }) => {
  return (
    <Pagination
      pageData={{
        current_page: page,
        size,
        total_pages: Math.ceil(total / size),
      }}
      onPageChange={onChangePage}
    />
  );
};
