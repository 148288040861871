import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import Tooltip from 'components/Tooltip/Tooltip';
import AudSummary from '../AudSummary';
import Icons from 'components/Icons';
import './AudienceDropdown.scss';
import { AsyncPaginate } from 'react-select-async-paginate';

const AudienceDropdown = props => {
  const {
    getAudiences,
    fetchAudience,
    selectedValue,
    onChange,
    openNewAudience,
    refreshToken,
    isDisabled,
  } = props;

  const [defaultOptions] = useState([
    { label: 'New Audience', value: -1, type: 'new_audience' },
    { label: 'Open to all', value: 0, data: { id: 0, value: 0 } },
  ]);
  const [options] = useState([]);
  const [selectedOption, setSelectedOption] = useState();

  useEffect(() => {
    if (selectedValue === 0) {
      setSelected(null);
    } else if (selectedValue > 0) {
      loadAudience(selectedValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedValue]);

  // refreshes selected value, user may have updated the audience
  useEffect(() => {
    if (selectedValue > 0) {
      loadAudience(selectedValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshToken]);

  const loadAudience = audienceId => {
    fetchAudience(audienceId, aud => {
      setSelected(aud);
    });
  };

  const setSelected = aud => {
    const option = mapAudienceToOption(aud);
    onOptionChange(option);
  };

  const onOptionChange = option => {
    setSelectedOption(option);
    onChange(option?.data);
  };

  const loadOptions = (inputValue, page, resolve, reject) => {
    const params = {
      search: inputValue,
      model: 'audiencedetail',
      page: page,
      size: 200,
    };
    getAudiences(params, resolve, reject);
  };

  const customStyles = {
    control: provided => ({
      ...provided,
      width: '100%',
    }),
  };

  const mapAudienceToOption = x => {
    if (x) {
      return {
        value: x?.id,
        label: x?.name,
        data: x,
      };
    } else {
      return defaultOptions[1];
    }
  };

  const mapPageToOptions = (page, doAppend) => {
    const opts = page?.data?.map(x => {
      return {
        value: x.id,
        label: x.name,
        data: x,
      };
    });

    let totalOpts = [];
    if (doAppend) {
      if (options[options.length - 1].value === -1) {
        // remove More... item indicating another page from last set of options loaded
        totalOpts = options.slice(0, options.length - 1);
      } else {
        // eslint-disable-next-line no-unused-vars
        totalOpts = options;
      }
    }
    return {
      options: opts,
      hasMore: page?.has_next,
      additional: {
        page: page?.current_page + 1,
      },
    };
  };

  const NewAudienceOption = ({ innerProps }) => {
    return (
      <div
        onClick={openNewAudience}
        className="audience__list-item list-item__new-audience"
      >
        <Icons.Add size={18} />
        <div>New Audience</div>
      </div>
    );
  };

  const ExistingAudienceOption = ({
    innerProps,
    isFocused,
    isSelected,
    data,
    children,
  }) => {
    const aud = data;
    const includes =
      (aud.data?.rule_sets &&
        aud.data?.rule_sets.filter(set => set.logical_op_type)) ||
      0;
    const excludes =
      (aud.data?.rule_sets &&
        aud.data?.rule_sets.filter(set => !set.logical_op_type)) ||
      0;

    if (data?.type === 'new_audience') return NewAudienceOption({ innerProps });
    else
      return (
        <div className="audience__list-item" key={aud.value} {...innerProps}>
          <div
            className={`${isFocused ? 'focused' : ''} ${
              isSelected ? 'selected' : ''
            } list-item__wrapper`}
          >
            <div className="list-item__text">{children}</div>
            {aud.value !== 0 && (
              <Tooltip
                className="list-item__tooltip"
                fullWidth
                tipText={() => <AudSummary data={{ includes, excludes }} />}
                direction="left"
              />
            )}
          </div>
        </div>
      );
  };

  const search = (search, loadedOptions, { page }) => {
    return new Promise((resolve, reject) => {
      const handleResolve = res => {
        const auds = Object.values(res.data.entities.audiences ?? []);
        res.data = auds;
        resolve(mapPageToOptions(res));
      };
      loadOptions(search, page, handleResolve, reject);
    });
  };

  return (
    <AsyncPaginate
      className="audienceDropdown"
      inputId="audienceInput"
      isClearable
      cacheOptions
      defaultOptions={defaultOptions}
      options={options}
      loadOptions={search}
      components={{ Option: ExistingAudienceOption }}
      additional={{
        page: 1,
      }}
      debounceTimeout={400}
      value={selectedOption}
      // menuIsOpen={true} // force menu open for debug purpose
      onChange={onOptionChange}
      styles={customStyles}
      isDisabled={isDisabled}
    />
  );
};

export default withRouter(AudienceDropdown);
