import './SearchParameterChip.scss';

export default ({ children, onRemove }) => {
  return (
    <div className="SearchParameterChip">
      {children}
      <button className="SearchParameterChip-remove" onClick={onRemove}>
        <i className="fa fa-times" />
      </button>
    </div>
  );
};
