import { connect } from 'react-redux';
import AnalyticsDetail from './AnalyticsDetail';
import {
  analyticsActions,
  analyticsSelectors,
} from 'modules/analytics/analyticsDuck';
import {
  dashboardActions,
  dashboardSelectors,
} from 'modules/dashboard/dashboardSlice';
import * as campaignsActions from 'modules/campaigns/campaignsActions';
import * as campaignsSelectors from 'modules/campaigns/campaignsSelectors';
import { apiActions } from 'modules/api/apiDuck';

const { clearApiError } = apiActions;

function mapStateToProps(state) {
  return {
    stats: dashboardSelectors.stats(state),
    statsById: analyticsSelectors.statsById(state),
    campaigns: campaignsSelectors.campaigns(state),
    apiError: analyticsSelectors.getApiError(state),
    isStatsLoading: analyticsSelectors.isStatsLoading(state),
    dashParamsFromState: dashboardSelectors.dashParamsFromState(state),
    kpiCardVisibility: dashboardSelectors.kpiCardVisibility(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getStats: params => dispatch(dashboardActions.getStats(params)),
    getStatsById: (id, params) =>
      dispatch(analyticsActions.getStatsById(id, params)),
    getCampaigns: targetType =>
      dispatch(campaignsActions.getCampaigns(targetType)),
    saveDashParamsToState: params =>
      dispatch(dashboardActions.saveDashParamsToState(params)),
    setKpiCardVisibility: payload =>
      dispatch(dashboardActions.setKpiCardVisibility(payload)),
    clearApiError: actionType => dispatch(clearApiError(actionType)),
  };
}

const AnalyticsDetailContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(AnalyticsDetail);

export default AnalyticsDetailContainer;
