import React from 'react';
import uniqueId from 'lodash/uniqueId';
import Button from '../../../components/Button/Button';
import colors from '../../../theme/Colors.module.scss';
import './InventoryCard.scss';

const InventoryCard = props => {
  const { order, callback } = props;

  const setModal = () => callback && callback();

  const actionButtons = () => {
    return (
      <Button
        icon="view"
        iconColor={colors.slate}
        btnStyle="transparent"
        callback={setModal}
      />
    );
  };

  const buildInventory = () => {
    return (
      <div key={uniqueId('inventory_')} className="inventoryCard">
        <div className="address">
          <p>{order.address}</p>
          {order.address2 && <p>{order.address2}</p>}
          <p>
            {order.city} {order.state}, {order.zip}
          </p>
        </div>

        <div className="shipDate">{order.date_shipped}</div>

        <div className="actions">{actionButtons()}</div>
      </div>
    );
  };

  return <React.Fragment>{buildInventory()}</React.Fragment>;
};

export default InventoryCard;
