import React from 'react';
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Button,
  Text,
  Flex,
  Box,
} from '@chakra-ui/react';

import Icons from '../../../Icons';
import { setMessage } from 'modules/app/appActions';
import { useDispatch } from 'react-redux';
import { addUserFont } from 'modules/templateEditor/templateEditorApi';
import ProgressBar from 'components/ProgressBar/ProgressBar';
import DotSpinner from 'components/DotSpinner/DotSpinner';

const DEFAULT_ERROR_MSG = 'Opps, an error occurred while uploading';
const FONT_ERROR_MSG = 'Opps, you already have this font available to you.';

export default function UploadFontModal({
  isOpen,
  onClose,
  file,
  setActiveSubMenu,
  handleFontFamilyChange,
  activeSubMenu,
}) {
  const [isLoading, setIsLoading] = React.useState(false);
  const [progress, setProgress] = React.useState(0);
  const [error, setError] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState(DEFAULT_ERROR_MSG);
  const interval = React.useRef(null);
  const dispatch = useDispatch();

  const uploadFontFile = async () => {
    try {
      if (!file) return;
      const formData = new FormData();
      setIsLoading(true);
      initFakeProgress();
      formData.append('file', file);
      await new Promise(resolve => {
        dispatch(
          addUserFont(
            formData,
            'modules/templateEditor/ADD_USER_FONTS',
            res => finishFakeProgress(res),
            error => {
              setErrorMessage(FONT_ERROR_MSG);
              setError(true);
              setIsLoading(false);
            }
          )
        );
      });
    } catch (e) {
      setErrorMessage(DEFAULT_ERROR_MSG);
      setError(true);
      setIsLoading(false);
      console.log('error', e);
    }
  };

  const initFakeProgress = () => {
    if (interval.current) clearInterval(interval.current);

    let value = 0;
    interval.current = setInterval(() => {
      if (value >= 80) {
        clearInterval(interval.current);
      } else {
        value += 2;
        setProgress(value);
      }
    }, 100);
  };

  const finishFakeProgress = res => {
    if (interval?.current) {
      clearInterval(interval.current);
    }

    let value = progress;
    interval.current = setInterval(() => {
      if (value >= 100) {
        clearInterval(interval.current);

        setMessage({
          message: 'Font Uploaded Successfully',
          type: 'success',
        });
        handleFontFamilyChange(res);
        if (activeSubMenu) {
          setActiveSubMenu(null);
          setActiveSubMenu('FontFamily');
        }

        closeModal();
      } else {
        value += 4;
        setProgress(value);
      }
    }, 100);
  };

  const closeModal = () => {
    setProgress(0);
    setError(false);
    setIsLoading(false);
    interval.current && clearInterval(interval.current);
    onClose();
  };

  return (
    <Modal isOpen={isOpen} onClose={closeModal} isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader
          textAlign="center"
          m="0"
          p={!isLoading ? '10px 0 0' : '20px 0 10px'}
        >
          {!isLoading && !error && (
            <Icons.Checked
              height={48}
              style={{ display: 'block', margin: '0 auto' }}
            />
          )}

          {!isLoading && error && (
            <Icons.Warning
              height={48}
              style={{ display: 'block', margin: '0 auto' }}
            />
          )}

          <Text
            color="#293854"
            fontSize="18px"
            fontWeight="700"
            p="0"
            m="5px 0 0 "
          >
            {!isLoading
              ? !error
                ? 'Please Confirm'
                : `${errorMessage}`
              : 'Uploading'}
          </Text>
        </ModalHeader>
        {isLoading && <ModalCloseButton />}
        <ModalBody
          m="0"
          color="#293854"
          fontSize="1rem"
          fontWeight={400}
          textAlign="center"
        >
          {!isLoading &&
            !error &&
            'By uploading this font, I acknowledge that I own or have the right to use it for my intended purposes.'}

          {isLoading && !error && (
            <Flex
              w="100%"
              direction="column"
              align="center"
              pb="25px"
              mt="1rem"
            >
              <DotSpinner />
              <Box mt="2.5rem" w="100%">
                <ProgressBar value={progress} />
              </Box>
            </Flex>
          )}

          {error && 'Please try again.'}
        </ModalBody>

        <hr />

        {!isLoading && (
          <ModalFooter pt="21px" pb="1.1rem">
            <Flex align="center" w="100%">
              <Button
                flex="1"
                p="0.65 1rem"
                fontWeight={600}
                borderRadius="4px"
                fontSize="1rem"
                mr={3}
                onClick={closeModal}
                bgColor={error ? '#ff6f0f' : ''}
                _hover={{
                  backgroundColor: error ? '#ff6f0fe5' : '',
                }}
                color={error ? 'white' : ''}
              >
                Cancel
              </Button>
              {!error && (
                <Button
                  flex="1"
                  p="0.65 1rem"
                  fontWeight={600}
                  borderRadius="4px"
                  fontSize="1rem"
                  bgColor="#ff6f0f"
                  color="white"
                  _hover={{
                    backgroundColor: '#ff6f0fe5',
                  }}
                  onClick={uploadFontFile}
                >
                  Yes, upload!
                </Button>
              )}
            </Flex>
          </ModalFooter>
        )}
      </ModalContent>
    </Modal>
  );
}
