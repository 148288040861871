import { isEmpty } from 'lodash';

const templateSrc = (template, type) => {
  if (
    !isEmpty(template) &&
    template.bg_img &&
    template.double_sided === false
  ) {
    if (type === 'original') return `${template.bg_img.original_url}`;
    if (type === 'thumb') return `${template.bg_img.thumb_url}`; // Use thumb_url for square ratio.
  }

  if (!isEmpty(template) && template.double_sided) {
    const pages = template.ad_template_pages.sort(
      (a, b) => a.page_num - b.page_num
    );

    if (type === 'original')
      return `${
        pages[0]?.bg_img?.original_url || pages[1]?.bg_img?.original_url
      }`;
    if (type === 'thumb')
      return `${
        pages[0]?.bg_img?.original_url || pages[1]?.bg_img?.original_url
      }`; // Use thumb_url for square ratio.
  }

  return `#`;
};

export default templateSrc;
