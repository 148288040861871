import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
// import { useFlag } from 'flags';

import * as usersActions from 'modules/users/usersActions';

import InvitationCard from './InvitationCard/InvitationCard';
import EntityTable from 'components/EntityTable/EntityTable';
import LegendContainer from 'components/LegendContainer/LegendContainer';
import Legend from 'components/Legend/Legend';

import colors from 'theme/Colors.module.scss';

const INVITATIONS_HEADERS = [
  {
    name: 'E-mail',
    field: 'email',
    sortable: true,
    hasIndicator: true,
    align: 'left',
    width: '30%',
    sortIcon: 'alpha',
  },
  {
    name: 'Sent At',
    field: 'sent_at',
    sortable: true,
    sortIcon: 'numeric',
    align: 'left',
    width: '30%',
  },
  {
    name: 'Invited By',
    field: 'sender',
    sortable: false,
    align: 'left',
    width: '30%',
  },
  {
    name: 'Actions',
    field: 'actions',
    sortable: false,
    align: 'right',
    width: '10%',
  },
];

const INVITATIONS_PERSISTENCE_KEYS = ['search'];

const INVITATIONS_SORT_SUFFIX = [
  {
    field: 'sent_at',
    direction: 'desc',
  },
];

export default ({
  searchParameters,
  setSearchParameters,
  setSearchPlaceholder,
  setSearchLegend,
  match,
}) => {
  // const isReadOnly = useFlag(['features', 'isReadOnly']);
  const dispatch = useDispatch();

  useEffect(() => {
    setSearchPlaceholder('Search invitations');
    setSearchLegend(
      <LegendContainer>
        <Legend color={colors.status00} label="Active" />
        <Legend color={colors.status50} label="Expired" />
      </LegendContainer>
    );

    return () => {
      setSearchPlaceholder('');
      setSearchLegend(null);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderInvitationCard = (invitation, state) => {
    return (
      <InvitationCard
        headers={INVITATIONS_HEADERS}
        focus={state.focus}
        invitation={invitation}
      />
    );
  };

  return (
    <EntityTable
      headers={INVITATIONS_HEADERS}
      pageable
      sortable
      focusable
      scrollable
      // scrollSpacerHeight={65}
      persistable
      persistenceKeys={INVITATIONS_PERSISTENCE_KEYS}
      defaultSearchParameters={searchParameters}
      onSearchParameters={setSearchParameters}
      sortSuffix={INVITATIONS_SORT_SUFFIX}
      entityType="invitations"
      getEntities={searchParameters => {
        return new Promise((resolve, reject) => {
          dispatch(
            usersActions.searchInvitations(searchParameters, resolve, reject)
          );
        });
      }}
      renderRow={renderInvitationCard}
    />
  );
};
