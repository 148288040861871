import React from 'react';
import './InventoryHeaders.scss';

const InventoryHeaders = props => {
  return (
    <ul className="inventoryLabels">
      <li className="inventoryAddress">Fulfillment Address</li>
      <li className="inventoryShipDate">Shipment Date</li>
      <li className="inventoryActions">Order Details</li>
    </ul>
  )
}

export default InventoryHeaders;