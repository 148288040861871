import { connect } from 'react-redux';
import NotFound from './NotFound';

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {};
}

const NotFoundContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(NotFound);

export default NotFoundContainer;
