import { Box } from "@chakra-ui/react";
import React from "react";
import colors from 'theme/Colors.module.scss';

export const Card = ({ children, ...props }) => (
  <Box
    bgColor="white"
    boxShadow={`0px 2px 5px ${colors.lightShadow}`}
    p="1.875rem"
    borderRadius="4px"
    {...props}
  >
    {children}
  </Box>
);