import { apiActions } from '../api/apiDuck';
import schema from './audiencesSchema';

export const getOne = (id, type) => {
  return apiActions.apiAction({
    path: `audiences/${id}`,
    method: 'GET',
    type,
  });
};

export const getAudienceDetail = (id, type, successCallback) => {
  return apiActions.apiAction({
    path: `audiences/${id}?model=audiencedetail`,
    type,
    onSuccess: audience => {
      return () => {
        if (successCallback) successCallback(audience);
      };
    },
  });
};

export const getAudiences = (
  params,
  successCallback,
  failureCallback,
  type
) => {
  return apiActions.apiAction({
    path: `audiences`,
    params,
    type,
    normalize: schema,
    onSuccess: pageData => {
      return () => {
        if (successCallback) successCallback(pageData);
      };
    },
    onFailure: error => {
      return () => {
        if (failureCallback) failureCallback(error);
      };
    },
  });
};

export const updateAudience = (audience, type, doneCallback) => {
  return apiActions.apiAction({
    path: `audiences/${audience.id}`,
    method: 'PUT',
    body: JSON.stringify(audience),
    normalize: schema,
    type,
    onSuccess: res => () => doneCallback(res),
  });
};

export const createAudience = (audience, type, doneCallback) => {
  return apiActions.apiAction({
    path: `audiences`,
    method: 'POST',
    body: JSON.stringify(audience),
    type,
    onSuccess: res => () => doneCallback(res),
  });
};

export const deleteAudience = (id, type) => {
  return apiActions.apiAction({
    path: `audiences/${id}`,
    method: 'DELETE',
    type,
    meta: { id },
  });
};
