import React from 'react';

function ArrowUp({ size = 50, style = {} }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      x="0"
      y="0"
      viewBox="0 0 50 50"
      style={{ ...style }}
    >
      <path d="M25 12.75L2.75 35l2.094 2.094L25 16.937l20.156 20.157L47.25 35z"></path>
    </svg>
  );
}

export default ArrowUp;
