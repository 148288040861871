import React from 'react';
import moment from 'moment';

import Button from 'components/Button/Button';
import TableRowCard from 'components/TableRowCard/TableRowCard';
import colors from 'theme/Colors.module.scss';
import './InvitationCard.scss';

export default props => {
  const { invitation, headers, focus, onClick } = props;

  const getIndicatorColor = () =>
    invitation.inactive_at ? colors.greyDisabled : colors.status00;

  const actionButtons = () => {
    return (
      <>
        <Button
          icon="delete"
          iconColor={colors.slate}
          iconBaseline
          btnStyle="transparent"
          callback={e => {
            e.stopPropagation();
          }}
        />
      </>
    );
  };

  return (
    <TableRowCard
      key={invitation.id}
      className="invitationCard"
      indicatorColor={getIndicatorColor()}
      to={`/invitations/${invitation.id}`}
      onClick={onClick}
      focus={focus}
      headers={headers}
    >
      <td className="fName">
        <div className="nameGroup">{invitation.email}</div>
      </td>

      <td className="lName">
        <div className="nameGroup">{moment(invitation.sent_at).fromNow()}</div>
      </td>

      <td className="email">
        <div className="nameGroup">
          {invitation.sender
            ? `${invitation.sender.firstname} ${invitation.sender.lastname}`
            : '--'}
        </div>
      </td>

      <td className="actions">{actionButtons()}</td>
    </TableRowCard>
  );
};
