import { fromJS } from 'immutable';
import { clean, error } from 'helpers/reducerHelpers';
import { actionCreator, actionWrapper } from 'helpers/actionHelpers';
import _, { merge, uniqueId } from 'lodash';
import * as audiencesActions from 'modules/audiences/audiencesActions';
import * as campaignsActions from 'modules/campaigns/campaignsActions';
import * as appConstants from 'modules/app/appConstants';
import { dashboardConstants } from 'modules/dashboard/dashboardSlice';
import { analyticsConstants } from 'modules/analytics/analyticsDuck';
import { inventoryConstants } from 'modules/inventory/inventoryDuck';
import { audienceRuleConstants } from 'modules/audienceRules/audienceRulesDuck';
import { creativesConstants } from 'modules/creatives/creativesDuck';
import { billingConstants } from 'modules/billingInfo/billingInfoDuck';
import * as usersActions from 'modules/users/usersActions';
import { templateEditorConstants } from '../templateEditor/templateEditorDuck';

// Constants
const API = 'API';
const API_REQUEST = 'API_REQUEST';
const API_START = 'API_START';
const API_END = 'API_END';
const API_SUCCESS = 'API_SUCCESS';
const API_ERROR = 'API_ERROR';
const API_UNAUTHORIZED = 'API_UNAUTHORIZED';
const API_INVALID = 'API_INVALID';
const API_FAILURE = 'API_FAILURE';
const API_CLEAR_ERROR = 'API_CLEAR_ERROR';
const STATE_KEY_API_ERROR = 'API_ERROR';

export const apiConstants = {
  API,
  API_REQUEST,
  API_START,
  API_END,
  API_SUCCESS,
  API_ERROR,
  API_UNAUTHORIZED,
  API_FAILURE,
  API_CLEAR_ERROR,
  STATE_KEY_API_ERROR,
};

// Reducer
const reducer = (ogState = {}, action) => {
  const state = fromJS(ogState);

  switch (action.type) {
    case API_START:
      //console.log('API_REDUCER : ', 'API_START', action.payload)
      break;

    case API_END:
      //console.log('API_REDUCER : ', 'API_END', action.payload)
      break;

    case error(action.type):
      //console.log('API_REDUCER : ', 'API_ERROR', action.payload)
      const err = {
        type: clean(action.type),
        error: action.payload,
        id: uniqueId(),
      };
      return state.setIn([STATE_KEY_API_ERROR, clean(action.type)], err).toJS();
    default:
  }
  return ogState;
};

// Actions
/**
 * Builds action for an api request to be handled by apiMiddleware.
 * @param  {Object} payload ApiMiddleware args to execute api request.
 * @return {Object}         Action to be dispatched.
 */

export const apiActions = {
  apiAction: ({ normalize, meta, ...opts }) => {
    let action = { type: `${API}.${opts.type}`, payload: opts };

    if (normalize) action = merge(action, { meta: { schema: [normalize] } });
    if (meta) action = merge(action, { meta: meta });

    return action;
  },

  apiStart: actionWrapper(API_START),
  apiEnd: actionCreator(API_END),
  apiSuccess: actionWrapper(API_SUCCESS),
  apiError: actionWrapper(API_ERROR),
  apiUnauthorized: actionWrapper(API_UNAUTHORIZED),
  apiFailure: actionWrapper(API_FAILURE),
  apiInvalid: actionWrapper(API_INVALID),
  clearApiError: actionCreator(API_CLEAR_ERROR),
};

// Selectors
export const apiSelectors = {
  getApiError: createApiErrorSelector([
    appConstants.LOG_IN,
    appConstants.LOG_OUT,
    appConstants.CHECK_TOKEN,
    appConstants.SET_APP_MODE,
    appConstants.SEND_PW_RESET_EMAIL,
    appConstants.VERIFY_RESET_TOKEN,
    appConstants.CHANGE_PASSWORD,
    appConstants.UPLOAD_TEMPLATE,

    dashboardConstants.GET_STATS,
    analyticsConstants.GET_STATS_BY_ID,

    campaignsActions.getCampaign.type,
    campaignsActions.getCampaigns.type,
    campaignsActions.uploadImage.type,
    campaignsActions.updateCampaign.type,
    campaignsActions.createCampaign.type,
    campaignsActions.deleteCampaign.type,
    campaignsActions.submitToRetailers.type,

    templateEditorConstants.GET_TEMPLATE_BY_ID,
    templateEditorConstants.UPDATE_TEMPLATE_BY_ID,
    templateEditorConstants.GET_PRE_MADE_TEMPLATES,
    templateEditorConstants.COPY_PRE_MADE_TEMPLATE,
    templateEditorConstants.GET_MRU_VARIABLE_KEYS,
    templateEditorConstants.GET_USER_FONTS,
    templateEditorConstants.GET_USER_FONT_BY_ID,
    templateEditorConstants.UPLOAD_USER_FONT,
    templateEditorConstants.UPDATE_USER_FONT,
    templateEditorConstants.REMOVE_USER_FONT,
    templateEditorConstants.GET_TEMPLATE_PREVIEW,
    templateEditorConstants.CREATE_TEMPLATE,

    audiencesActions.getAudience.type,
    audiencesActions.getAudiences.type,
    audiencesActions.createAudience.type,
    audiencesActions.updateAudience.type,
    audiencesActions.deleteAudience.type,

    audienceRuleConstants.GET_AUDIENCE_RULE,
    audienceRuleConstants.GET_AUDIENCE_RULES,
    audienceRuleConstants.UPDATE_AUDIENCE_RULE,
    audienceRuleConstants.CREATE_AUDIENCE_RULE,
    audienceRuleConstants.CREATE_AUDIENCE_RULES,

    creativesConstants.CREATE_CREATIVE,
    creativesConstants.GET_CREATIVES,
    creativesConstants.GET_CREATIVE_BY_ID,
    creativesConstants.DELETE_TEMPLATE_BY_ID,
    creativesConstants.UPDATE_CREATIVE,

    inventoryConstants.GET_INVENTORY_ORDERS,
    inventoryConstants.GET_INVENTORY_BALANCES,

    usersActions.getAllUsers.type,
    usersActions.getUserById.type,
    usersActions.createUser.type,
    usersActions.updateUserById.type,
    usersActions.removeUserById.type,
    usersActions.invite.type,
    usersActions.checkInvitationToken.type,

    billingConstants.ADD_PAYMENT,
    billingConstants.REMOVE_PAYMENT,
    billingConstants.UPDATE_PAYMENT,
    billingConstants.SET_PRIMARY_PAYMENT,
    billingConstants.SET_BILLING_INFO,
  ]),
};

export function createApiErrorSelector(actionTypes) {
  const make = state => {
    return (
      _(actionTypes)
        .map(actionType => {
          return (
            state.modules.api[STATE_KEY_API_ERROR] &&
            state.modules.api[STATE_KEY_API_ERROR][actionType]
          );
        })
        .compact()
        .first() || ''
    );
  };

  return make;
}

export default reducer;
