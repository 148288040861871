import React from 'react';
function Barcode({ size }) {
  return (
    <svg height={size} viewBox="0 0 24 24">
      <path
        fill="currentColor"
        d="M2 4h2v16H2V4zm4 0h2v16H6V4zm3 0h3v16H9V4zm4 0h2v16h-2V4zm3 0h2v16h-2V4zm3 0h3v16h-3V4z"
      ></path>
    </svg>
  );
}

export default Barcode;
