import { connect } from 'react-redux';
import Inventory from './Inventory';
import { inventoryActions, inventorySelectors } from '../../modules/inventory/inventoryDuck';
import { apiActions } from '../../modules/api/apiDuck';

function mapStateToProps(state) {
  return {
    inventoryOrders: inventorySelectors.inventoryOrders(state),
    inventoryBalances: inventorySelectors.inventoryBalances(state),
    pageData: inventorySelectors.getPageData(state),
    apiError: inventorySelectors.getApiError(state),
  }
}

function mapDispatchToProps(dispatch) {
  return {
    testError: () => {
      dispatch(apiActions.apiAction({
        path: `notfound`,
        type: 'modules/inventory/GET_INVENTORY',
      }));
    },
    getInventoryOrders: payload => dispatch(inventoryActions.getInventoryOrders(payload)),
    getInventoryBalances: () => dispatch(inventoryActions.getInventoryBalances()),
    clearApiError: actionType => dispatch(apiActions.clearApiError(actionType))
  }
}

const InventoryContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(Inventory);

export default InventoryContainer;