import React from "react"

function Cross({ size }) {
    return (
        <svg height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M20 4.94267L19.0573 4L12 11.0573L4.94267 4L4 4.94267L11.0573 12L4 19.0573L4.94267 20L12 12.9427L19.0573 20L20 19.0573L12.9427 12L20 4.94267Z" fill="currentColor" />
        </svg>
    )
}

export default Cross