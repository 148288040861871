import {
  Box,
  Input,
  Button,
  Tooltip,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import Icons from '../../Icons';

function QRCodeMenu({
  keyObj,
  setKeyData,
  updateMetadata,
  closeMenu,
  dynSwitchChecked
}) {
  const [key, setKey] = useState('');
  const [defaultVal, setDefaultVal] = useState('');
  const [url, setUrl] = useState('');

  useEffect(() => {
    setUrl(keyObj?.object?.metadata?.url || '');
    setKey(keyObj?.key ?
      keyObj.key.key.substring(2, keyObj.key.key.length - 2) : '');
    setDefaultVal(keyObj?.key?.value || '');
  }, [keyObj]);
  
  const handleUrlChange = (e) => setUrl(e.target.value);
  
  const handleKeyChange = (e) => setKey(e.target.value);
  
  const handleDefaultChange = (e) => setDefaultVal(e.target.value);
  
  const handleApplyClick = (e) => {
    if (!dynSwitchChecked) {
      updateMetadata('url', url);
      setKeyData({
        ...keyObj.key,
        value: undefined,
        key: undefined,
      })
    } else {
      updateMetadata('url', undefined);
      setKeyData({
        ...keyObj.key,
        value: defaultVal,
        key: '{{' + key + '}}',
      })
    }
    closeMenu();
  }

  return (
    <Box>
      {!dynSwitchChecked ? (
        <Box>
          <Box
            sx={{
              fontSize: '14px',
              paddingBottom: '0.75rem',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <Box>URL</Box>
            <Tooltip label="The URL embedded in the QR code that the user will navigate to.">
              <Box color="#666666" cursor="pointer">
                <Icons.Info size={22} />
              </Box>
            </Tooltip>
          </Box>
          <Input
            fontSize="14px"
            borderRadius={0}
            style={{ width: '270px' }}
            value={url}
            name="variableName"
            onChange={handleUrlChange}
            _disabled={{
              color: '#666666 !important',
            }}
          />
        </Box>
      ) : (
        <>
          <Box>
            <Box
              sx={{
                fontSize: '14px',
                paddingBottom: '0.75rem',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <Box>Personalization Token</Box>
              <Tooltip label="The name of the variable that is passed to UnDigital in the template params of your order post.">
                <Box color="#666666" cursor="pointer">
                  <Icons.Info size={22} />
                </Box>
              </Tooltip>
            </Box>
            <Input
              fontSize="14px"
              borderRadius={0}
              style={{ width: '270px' }}
              value={key}
              name="variableName"
              onChange={handleKeyChange}
              _disabled={{
                color: '#666666 !important',
              }}
            />
          </Box>
          <Box>
            <Box
              sx={{
                fontSize: '14px',
                paddingBottom: '0.75rem',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                marginTop: '1rem',
              }}
            >
              <Box>Default value</Box>
              <Tooltip label='The value to use in place of the variable name while editing your ad template enabling you to more easily envision the final generated image.  For example, if the variable is for "firstname", you can place a default value of "John".'>
                <Box color="#666666" cursor="pointer">
                  <Icons.Info size={22} />
                </Box>
              </Tooltip>
            </Box>
            <Input
              fontSize="14px"
              borderRadius={0}
              name="variableValue"
              value={defaultVal}
              onChange={handleDefaultChange}                
            />
          </Box>
        </>
      )}
      <Box sx={{ display: 'flex', gap: '0.75rem', paddingTop: '0.75rem' }}>
        <Button
          colorScheme="orange"
          backgroundColor="rgb(255, 111, 15)"
          borderRadius={0}
          name="variablyApply"
          size="sm"
          flex={1}
          disabled={(!dynSwitchChecked && !url) || (!!dynSwitchChecked && !key)}
          onClick={handleApplyClick}
        >
          Apply
        </Button>
        <Button
          borderRadius={0}
          size="sm"
          flex={1}
          onClick={closeMenu}
        >
          Cancel
        </Button>
      </Box>
    </Box>
  );
}
export default QRCodeMenu;
