class BackgroundController {
  handler
  constructor(handler) {
    this.handler = handler
  }
  setBackgroundColor = (color) => {
    this.handler.setBackgroundColor(color)
  }

  setGradient = ({ angle, colors }) => {
    this.handler.setGradient({ angle, colors })
  }
}

export default BackgroundController
