import {
  EntityComponentsContext,
  EntityTable,
} from '@undigital/shared-web/dist/index';

import entityComponents from './entityComponents';

import './EntityTable.scss';

export default props => {
  return (
    <EntityComponentsContext.Provider value={entityComponents}>
      <EntityTable {...props} />
    </EntityComponentsContext.Provider>
  );
};
