import React from "react"

function Rect({ size }) {
    return (
        <svg height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="3" y="7" width="18" height="9" fill="currentColor" />
        </svg>
    )
}

export default Rect