import { connect } from 'react-redux';
import { templateEditorActions } from 'modules/templateEditor/templateEditorDuck';
import Modal from './Modal';

function mapDispatchToProps(dispatch) {
  return {
    getTemplatePreview: (payload, doneCallback) => {
      dispatch(templateEditorActions.getTemplatePreview(payload, doneCallback));
    },
  };
}

const previewModalContainer = connect(null, mapDispatchToProps)(Modal);

export default previewModalContainer;
