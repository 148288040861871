import React from 'react';
import { Box, Flex } from '@chakra-ui/react';
import ProgressBar from 'components/ProgressBar/ProgressBar';
import DotSpinner from 'components/DotSpinner/DotSpinner';
import ButtonComponent from './ButtonComponent';

export default function ConversionProcess({
  uploadFile,
  templateInformation,
  saveTemplateFile,
  designId,
  setDesignId,
  resumeDesignImport,
  setStep,
  updateMissingFonts,
  fileFormat,
  getDesignStatus,
  setModalState,
  sm,
  getMissingFonts,
  currentFonts,
}) {
  const [progress, setProgress] = React.useState(0);
  const [status, setStatus] = React.useState('uploading');
  const [template, setTemplate] = React.useState(null);
  const [intervalSymbol, setIntervalSymbol] = React.useState(Symbol());

  const updateStatus = status => {
    const statusses = {
      uploading: 'Uploading',
      pending: 'Preparing',
      downloaded: 'Preparing',
      submitted: 'Preparing',
      converted: 'Importing',
      translated: 'Importing',
      resuming: 'Resuming',
      error: 'Error',
    };
    let title = statusses[status];
    setModalState({ title, description: '' });
    setStatus(status);
    setIntervalSymbol(Symbol());
  };

  const getTemplate = templateLocation =>
    fetch(templateLocation)
      .then(response => response.json())
      .then(data => {
        saveTemplateFile(data);
        return data;
      });

  const validateStatus = (design, interval) => {
    if (design.status.code === 'translated') {
      updateStatus(design.status.code);
      setTemplate(design.output.template);
      clearInterval(interval);
    }

    if (
      design.status.code === 'ERROR' &&
      design.status.detail.type === 'MISSING_FONTS'
    ) {
      setDesignId(design.id);
      updateMissingFonts(design.status.detail.message);
      setStep(2);
      clearInterval(interval);
      return;
    }

    if (design.status.code === 'ERROR') {
      updateStatus(design.status.code);
      clearInterval(interval);
      setModalState({
        title: 'Error occurred',
        description: 'Please, try again.',
      });
      return;
    }

    updateStatus(design.status.code);
  };

  const getMissingFontsForPSDFile = async templateFile => {
    const fonts = await getMissingFonts(templateFile);
    if (!fonts.length) setStep(3);
    else setStep(2);
  };

  React.useEffect(() => {
    if (!templateInformation) {
      uploadFile();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (designId) {
      resumeDesignImport(designId, status => console.log(status));
    }
    const interval = setInterval(() => {
      getDesignStatus(templateInformation.id, template => {
        if (template.status.code) {
          validateStatus(template, interval);
        } else {
          updateStatus('ERROR');
          clearInterval(interval);
        }
      });
    }, 8000);
    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [templateInformation]);

  React.useEffect(() => {
    let value = progress;
    let endValue = progress + 20;
    const interval = setInterval(() => {
      if (status === 'translated' || value >= 80) {
        clearInterval(interval);
      } else if (value >= endValue) {
        clearInterval(interval);
      } else {
        value += 1;
        setProgress(value);
      }
    }, [200]);

    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [intervalSymbol]);

  React.useEffect(() => {
    if (!template) return;
    getTemplate(template).then(templateFile => {
      let value = progress;
      const interval = setInterval(() => {
        if (value >= 100) {
          if (fileFormat === 'ai') setStep(3);
          else getMissingFontsForPSDFile(templateFile);
          clearInterval(interval);
        } else {
          value += 1;
          setProgress(value);
        }
      }, [20]);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [template]);

  return (
    <Box minW={sm ? '500px' : '250px'}>
      <Flex justify="center" m="2.5rem 0">
        {status !== 'ERROR' && <DotSpinner />}
      </Flex>
      {status !== 'ERROR' && <ProgressBar value={progress} />}
      {status === 'ERROR' && (
        <ButtonComponent
          p={sm ? '14px 100px' : '14px 21px'}
          fontSize="20px"
          m="52px auto 0"
          onClick={() => setStep(0)}
        >
          OK
        </ButtonComponent>
      )}
    </Box>
  );
}
