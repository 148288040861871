import { useState, useRef, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Tabs, TabList, Tab } from '@chakra-ui/react';
import moment from 'moment-timezone';
import Graph from 'components/Graph/Graph';
import colors from 'theme/Colors.module.scss';
import './Graph.scss';
import {
  isValidDate,
  defaultDates,
  fillGapsWith,
} from 'helpers/presetDateHelpers';
import {
  dashboardActions,
  dashboardSelectors,
} from 'modules/dashboard/dashboardSlice';
import * as campaignsSelectors from 'modules/campaigns/campaignsSelectors';
import * as campaignsActions from 'modules/campaigns/campaignsActions';
import { paramsToString, paramsToObject } from 'helpers/paramHelpers';
import LoadingIndicator from 'components/LoadingIndicator/LoadingIndicator';
import DateRangePicker from 'components/DateRangePicker/DateRangePicker';
import OutsideClickHandler from 'hooks/OutsideClickHandler';
import ActiveCampaign2 from '../ActiveCampaign2/ActiveCampaign2';

function zip(...arrays) {
  const length = Math.min(...arrays.map(arr => arr.length));
  return Array.from({ length }, (_, i) => arrays.map(arr => arr[i]));
}

function formatNumber(number) {
  if (number < 1000) {
    return number.toString(); // Return the number as is if it's less than 1000
  } else {
    const formattedNumber = (number / 1000).toFixed(1);
    return `${formattedNumber}k`;
  }
}

export const tabOptions = [
  { label: 'Personalized Prints', value: 'prints' },
  { label: 'Personalized Packages', value: 'packages' },
];

const generateRange = (startDate, endDate) => ({
  start: startDate.local().format('YYYY-MM-DD'),
  startUtc: startDate.startOf('day').utc().format(),
  startRaw: startDate.local(),
  end: endDate.local().format('YYYY-MM-DD'),
  endUtc: endDate.endOf('day').utc().format(),
  endRaw: endDate.local(),
  days: endDate.diff(startDate, 'days'),
});

const GraphDashboard = (props, ref) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { search } = useLocation();
  const rangePickerRef = useRef(null);

  // States
  const [dateRangeDropdownVisible, setDateRangeDropdownVisible] =
    useState(false);
  const [isCustomRangePickerVisible, setIsCustomRangePickerVisible] =
    useState(false);
  const [tabOption, setTabOption] = useState(tabOptions[0]);
  const [printDataPoints, setPrintDataPoints] = useState([]);
  const [printedOrderDataPoints, setPrintedOrderDataPoints] = useState([]);

  // Selectors
  const printStats = useSelector(state => dashboardSelectors.printStats(state));
  const isPrintStatsLoading = useSelector(state =>
    dashboardSelectors.isPrintStatsLoading(state)
  );
  const printedOrderStates = useSelector(state =>
    dashboardSelectors.printedOrderStats(state)
  );
  const isPrintedOrderStatsLoading = useSelector(state =>
    dashboardSelectors.isPrintedOrderStatsLoading(state)
  );
  const dashParamsFromState = useSelector(state =>
    dashboardSelectors.dashParamsFromState(state)
  );
  const campaigns = useSelector(state => campaignsSelectors.campaigns(state));
  const getDateRange = useSelector(state =>
    campaignsSelectors.getDateRange(state)
  );

  // Dispatches
  const getPrintStates = params =>
    dispatch(dashboardActions.getPrintStates(params));
  const getPrintedOrderStates = params =>
    dispatch(dashboardActions.getPrintedOrderStates(params));
  const saveDashParamsToState = params =>
    dispatch(dashboardActions.saveDashParamsToState(params));
  const getCampaigns = targetType =>
    dispatch(campaignsActions.getCampaigns(targetType));
  const setDateRange = payload =>
    dispatch(campaignsActions.setDateRange(payload));

  // Stats
  let totalPrints = printStats?.data.reduce((a, b) => a + b[1], 0);
  let totalPackage = printedOrderStates?.data.reduce((a, b) => a + b[1], 0);

  // Dates Calculation
  const { start: userStart, end: userEnd } = paramsToObject(
    dashParamsFromState || search
  );

  const { from, to } = getDateRange;
  const range = generateRange(moment(from), moment(to));

  const getInitialDateState = () => {
    return {
      from: userStart
        ? new Date(moment(userStart))
        : new Date(defaultDates.start),
      to: userEnd ? new Date(moment(userEnd)) : new Date(defaultDates.end),
    };
  };

  const params = paramsToString({
    start: range.start,
    end: range.end,
  });

  const utcParams = paramsToString({
    start: range.startUtc,
    end: range.endUtc,
    tz: moment.tz(moment.tz.guess()).zoneAbbr(),
    all_campaigns: 0,
    show_by_day: 1,
  });

  // Initialization
  useEffect(() => {
    if (getDateRange?.length === 0) {
      setDateRange(getInitialDateState());
    }
    const payload = { current_page: 1, size: 500 };
    getCampaigns(payload);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      isValidDate(moment(from)) &&
      isValidDate(moment(to)) &&
      getDateRange?.length !== 0
    ) {
      getPrintStates(utcParams);
      getPrintedOrderStates(utcParams);
      saveDashParamsToState(params);
      history.push(`/dashboard${params}`);
    } else {
      setDateRange(getInitialDateState());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [from, to]);

  // Generate Data Points
  const printData = stats => {
    const printMap = new Map();

    // group data by campaign id
    if (stats) {
      stats.data.forEach(data => {
        if (printMap.has(data[0])) {
          printMap.get(data[0]).push(data);
        } else {
          printMap.set(data[0], [data], 0);
        }
      });
    }

    const groupPrintData = Array.from(printMap, ([id, data]) => ({ id, data }));

    // generate total_imp
    groupPrintData.forEach(campaignData => {
      let total_imp = 0;
      campaignData.data.forEach(item => (total_imp += item[1]));
      campaignData.total_imp = total_imp;
    });

    return groupPrintData;
  };

  const generateDataPoints = (stats, statsType) => {
    let acc = [];

    stats &&
      printData(stats).forEach(campaign => {
        const dataPoints = campaign.data;
        dataPoints.forEach(dataPoint => {
          const data = parseFloat(dataPoint[1]);
          let dt = moment(dataPoint[2]).format('YYYY-MM-DD');
          acc[dt] = acc[dt] ? (acc[dt] += data) : data;
        });
      });
    if (acc[range.start] === undefined) acc[range.start] = 0;
    if (acc[range.end] === undefined) acc[range.end] = 0;
    acc = fillGapsWith(acc, 0);
    acc = Object.fromEntries(Object.entries(acc).sort());

    if (statsType === 'print') {
      setPrintDataPoints(acc);
    }
    if (statsType === 'printedOrder') {
      setPrintedOrderDataPoints(acc);
    }
  };

  useEffect(() => {
    generateDataPoints(printStats, 'print');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [printStats]);

  useEffect(() => {
    generateDataPoints(printedOrderStates, 'printedOrder');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [printedOrderStates]);

  // Filter Campaigns
  const filteredCampaigns = (campaigns, stats) => {
    const campaignIdsInRange = printData(stats).map(stat => stat.id);
    const filtered = campaigns?.filter(campaign =>
      campaignIdsInRange.includes(campaign.id)
    );
    return filtered;
  };

  // Build Data
  const buildData = (dataPoints, dataType) => {
    const values = Object.values(dataPoints);
    const dataSets = {
      title:
        dataType === 'print' ? 'Personalized Prints' : 'Personalized Packages',
      values,
      total: '',
      color: colors.primary100,
      hoverColor: colors.primary900,
      stack: 'Base',
      type: 'bar',
    };
    return dataSets;
  };

  const dataSets = (dataPoints, dataType) => {
    return {
      data: [buildData(dataPoints, dataType)],
      xLabels: Object.keys(dataPoints),
    };
  };

  // Chart Options
  const chartOptions = (dataPoints, dataType) => {
    return {
      legend: {
        display: false,
      },

      tooltips: {
        // Disable the on-canvas tooltip
        enabled: false,
        custom: function (tooltipModel) {
          // Tooltip Element
          let tooltipEl = document.getElementById('chartjs-tooltip');
          // Create element on first render
          if (!tooltipEl) {
            tooltipEl = document.createElement('div');
            tooltipEl.id = 'chartjs-tooltip';
          }
          if (tooltipModel.body) {
            let zippedAry = zip(
              dataSets(dataPoints, dataType).xLabels,
              dataSets(dataPoints, dataType).data[0].values
            );
            let dateString = tooltipModel.title[0];
            let curPrintsIndex = zippedAry.findIndex(item => {
              return (
                new Date(item[0] + 'T00:00:00Z')
                  .toLocaleDateString('en-US', {
                    month: 'short',
                    day: 'numeric',
                    timeZone: 'UTC',
                  })
                  .replace(/^(\D*)0(\d)/, '$1$2') ===
                dateString.replace(/^(\D*)0(\d)/, '$1$2')
              );
            });

            let curPrints = zippedAry[curPrintsIndex][1];
            let prevPrints = 0;
            if (curPrintsIndex - 1 >= 0) {
              prevPrints = zippedAry[curPrintsIndex - 1][1];
              if (isNaN(prevPrints)) prevPrints = 0;
            }
            let percent = 100;
            if (prevPrints !== 0)
              percent = ((curPrints - prevPrints) / prevPrints) * 100;
            let isRise = true;
            if (percent < 0) isRise = false;
            if (isRise) {
              percent = '+' + percent.toFixed(2);
            } else {
              percent = percent.toFixed(2);
            }
            //     let arrowCircleIcon = `<div class='svg-container ${
            //       isRise ? '' : 'fa-rotate-90'
            //     }'><svg xmlns="http://www.w3.org/2000/svg" width="9" height="10" viewBox="0 0 9 10" fill="none">
            //   <path fillRule="evenodd" clipRule="evenodd" d="M0.721515 9.17603C0.410563 8.80436 0.410563 8.21089 0.721515 7.83997L5.73391 1.89016H2.87818C2.44272 1.87275 2.09769 1.4549 2.09769 0.94546C2.09769 0.436017 2.44272 0.0181674 2.87818 0.000756973L7.65313 0C8.0962 0.00454184 8.45249 0.42769 8.44893 0.94546V6.61822C8.44893 6.95053 8.2973 7.25786 8.05103 7.42364C7.80484 7.59017 7.50142 7.59017 7.25523 7.42364C7.00896 7.25786 6.85733 6.95053 6.85733 6.61822L6.85725 3.22925L1.84486 9.17755C1.69679 9.35468 1.49384 9.4546 1.28181 9.4546C1.06979 9.4546 0.866832 9.35468 0.718763 9.17755L0.721515 9.17603Z" fill="#F15F24"/>
            // </svg></div>`;
            tooltipEl.innerHTML = `<span class="tooltip-prints">${Number(
              tooltipModel.body[0].lines[0].split(':')[1]
            ).toLocaleString()}</span>`;
            document.body.appendChild(tooltipEl);
          }
          // Hide if no tooltip
          if (tooltipModel.opacity === 0) {
            tooltipEl.style.opacity = 0;
            return;
          }

          // Set caret Position
          tooltipEl.classList.remove('above', 'below', 'no-transform');
          if (tooltipModel.yAlign) {
            tooltipEl.classList.add(tooltipModel.yAlign);
          } else {
            tooltipEl.classList.add('no-transform');
          }

          // `this` will be the overall tooltip
          let position = this._chart.canvas.getBoundingClientRect();

          // Display, position, and set styles for font
          tooltipEl.style.opacity = 1;
          tooltipEl.style.position = 'absolute';
          tooltipEl.style.left =
            position.left + window.pageXOffset + tooltipModel.caretX + 'px';
          tooltipEl.style.top =
            position.top + window.pageYOffset + tooltipModel.caretY - 80 + 'px';
          tooltipEl.style.fontFamily = tooltipModel._bodyFontFamily;
          tooltipEl.style.fontSize = tooltipModel.bodyFontSize + 'px';
          tooltipEl.style.fontStyle = tooltipModel._bodyFontStyle;
          tooltipEl.style.pointerEvents = 'none';
        },
      },

      scales: {
        xAxes: [
          {
            ticks: {
              beginAtZero: true,
              userCallback: value => moment(value).format('MMM DD'),
            },
            gridLines: {
              display: false,
            },
          },
        ],
        yAxes: [
          {
            ticks: {
              beginAtZero: true,
              userCallback: value => formatNumber(value),
            },
            stacked: true,
          },
        ],
      },
    };
  };

  return (
    <Box className="dashboard-graph">
      <Box className="dashboard-graph__top-container">
        <Box className="dashboard-graph__tabs-wrapper">
          <Tabs className="dashboard-graph__tabs" variant="unstyled">
            <TabList className="dashboard-graph__tab-list">
              <Tab
                className="dashboard-graph__tab"
                onClick={() => {
                  setTabOption(tabOptions[0]);
                }}
                _selected={{ color: colors.white, bg: colors.primary900 }}
              >
                <span className="dashboard-graph__tab-text">
                  {`Personalized Prints (`}
                  <LoadingIndicator
                    minHeight="0px"
                    size={'14px'}
                    loading={isPrintStatsLoading}
                  >
                    {`${Number(totalPrints).toLocaleString('en-US')}`}
                  </LoadingIndicator>
                  &nbsp;
                  {`total)`}
                </span>
              </Tab>

              <Tab
                className="dashboard-graph__tab"
                onClick={() => {
                  setTabOption(tabOptions[1]);
                }}
                _selected={{ color: colors.white, bg: colors.secondary900 }}
              >
                <span className="dashboard-graph__tab-text">
                  {`Personalized Packages (`}
                  <LoadingIndicator
                    minHeight="0px"
                    size={'14px'}
                    loading={isPrintedOrderStatsLoading}
                  >
                    {`${Number(totalPackage).toLocaleString('en-US')}`}
                  </LoadingIndicator>
                  &nbsp;
                  {`total)`}
                </span>
              </Tab>
            </TabList>
          </Tabs>
        </Box>
        <Box className="dashboard-graph__date-range-wrapper">
          <OutsideClickHandler
            onHandler={(e, ref) => {
              setDateRangeDropdownVisible(false);
              setIsCustomRangePickerVisible(false);
            }}
          >
            <DateRangePicker
              isCustomRangePickerVisible={isCustomRangePickerVisible}
              setIsCustomRangePickerVisible={setIsCustomRangePickerVisible}
              dateRangeDropdownVisible={dateRangeDropdownVisible}
              setDateRangeDropdownVisible={setDateRangeDropdownVisible}
              ref={rangePickerRef}
              date={{ from, to }}
              setDate={setDateRange}
            />
          </OutsideClickHandler>
        </Box>
      </Box>

      {tabOption.value === 'prints' && (
        <LoadingIndicator
          minHeight="400px"
          size={'50px'}
          loading={isPrintStatsLoading}
        >
          <Graph
            dataSet={dataSets(printDataPoints, 'print')}
            customOptions={chartOptions(printDataPoints, 'print')}
            tabOption={tabOption}
          />
        </LoadingIndicator>
      )}
      {tabOption.value === 'packages' && (
        <LoadingIndicator
          minHeight="400px"
          size={'50px'}
          loading={isPrintedOrderStatsLoading}
        >
          <Graph
            dataSet={dataSets(printedOrderDataPoints, 'printedOrder')}
            customOptions={chartOptions(printedOrderDataPoints, 'printedOrder')}
            tabOption={tabOption}
          />
        </LoadingIndicator>
      )}

      {tabOption.value === 'prints' && (
        <LoadingIndicator
          minHeight="250px"
          size={'50px'}
          loading={isPrintStatsLoading}
        >
          <ActiveCampaign2
            campaigns={filteredCampaigns(campaigns, printStats)}
            stats={printData(printStats)}
            tabOption={tabOption}
          />
        </LoadingIndicator>
      )}
    </Box>
  );
};

export default GraphDashboard;
