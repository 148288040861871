import { createStore, applyMiddleware, combineReducers } from 'redux';
import { routerReducer } from 'react-router-redux';
import thunk from 'redux-thunk';
import apiMiddleware from './middlewares/apiMiddleware';
import normalizrMiddleware from './middlewares/normalizrMiddleware';
import dashboard from './modules/dashboard/dashboardSlice';
import analytics from './modules/analytics/analyticsDuck';
import creatives from './modules/creatives/creativesDuck';
import audiences from './modules/audiences/audiencesReducer';
import audienceRules from './modules/audienceRules/audienceRulesDuck';
import usersReducer from './modules/users/usersReducer';
import campaigns from './modules/campaigns/campaignsSlice';
import billingInfo from './modules/billingInfo/billingInfoDuck';
import app from './modules/app/appDuck';
import inventory from './modules/inventory/inventoryDuck';
import templateEditor from './modules/templateEditor/templateEditorDuck';
import canvasUtility from './modules/canvasUtility/canvasUtilityDuck';
import api from './modules/api/apiDuck';
import companyParams from './modules/params/companyParamsReducer';

const modulesReducer = combineReducers({
  dashboard,
  analytics,
  audiences,
  audienceRules,
  campaigns,
  templateEditor,
  canvasUtility,
  app,
  billingInfo,
  creatives,
  inventory,
  users: usersReducer,
  api,
  companyParams,
});

const scenesReducer = {};

const appReducer = combineReducers({
  router: routerReducer,
  modules: modulesReducer,
  scenes: scenesReducer,
});

export default function configureStore(initialState) {
  //const routerMiddleWare = syncHistoryWithStore(browserHistory);
  const createStoreWithMiddleware = applyMiddleware(
    apiMiddleware,
    normalizrMiddleware,
    thunk
  )(createStore);
  const store = createStoreWithMiddleware(appReducer, initialState);

  //routerMiddleWare.listenForReplays(store);
  /*
  if (module.hot) {
    // Enable Webpack hot module replacement for reducers
    module.hot.accept('../reducers', () => {
      const nextReducer = require('../reducers');
      store.replaceReducer(nextReducer);
    })
  }
*/
  return store;
}
