import React from 'react';
import { Flex } from '@chakra-ui/react';
import DotSpinner from 'components/DotSpinner/DotSpinner';
import ButtonComponent from './ButtonComponent';

export default function SetTemplateName({
  setModalState,
  sm,
  closeModal,
  templateFile,
  createTemplate,
  refresh,
  file,
  onSuccess,
  setStep,
  templateHeight,
}) {
  const [showCancelBtn, setShowCancelBtn] = React.useState(false);
  const createTemplateHandler = async () => {
    let payload = { ...templateFile, name: file.name };

    if (!templateFile.is_portrait) {
      payload = {
        ...payload,
        ad_template_vars: invertItemsPositions(
          payload.ad_template_vars,
          templateHeight
        ),
      };
    }

    await new Promise(resolve => {
      createTemplate(payload, data => {
        if (data !== 'Bad Request') {
          resolve(data);
          onSuccess();
          closeModal();
          refresh();
        } else {
          setModalState({
            title: 'Error',
            description: 'Something went wrong.\nPlease try againg',
          });
          setShowCancelBtn(true);
        }
      });
    });
  };

  const invertItemsPositions = (vars, templateHeight) =>
    vars.map(item => ({
      ...item,
      pos_x: templateHeight - item.pos_y,
      pos_y: item.pos_x,
    }));

  React.useEffect(() => {
    setShowCancelBtn(false);
    setModalState({ title: 'Loading', description: '' });
    if (templateFile) {
      createTemplateHandler();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [templateFile]);

  return (
    <Flex justify="center" minW={sm ? '500px' : '250px'} mt="2.5rem">
      {!showCancelBtn && <DotSpinner />}
      {showCancelBtn && (
        <ButtonComponent
          p={sm ? '14px 100px' : '14px 21px'}
          fontSize="20px"
          onClick={() => setStep(0)}
        >
          OK
        </ButtonComponent>
      )}
    </Flex>
  );
}
