import React from "react"
import { Box, IconButton } from "@chakra-ui/react"
import Icons from "../../../Icons"
import useTemplateEditorContext from "scenes/templateEditor/hooks/useTemplateEditorContext"

function TutorialClose() {
    const { setActiveSubMenu } = useTemplateEditorContext()
    return (
        <Box sx={{ position: "absolute", top: "16px", right: "16px", zIndex: 100 }}>
            <IconButton
                onClick={() => setActiveSubMenu(null)}
                borderRadius="0"
                variant="ghost"
                size="sm"
                icon={<Icons.Cross size={18} />}
            />
        </Box>
    )
}

export default TutorialClose