import { Box, Grid, GridItem } from '@chakra-ui/react';
import { newCampaignFilterOptions } from 'helpers/presetDateHelpers';
import React, { useEffect, useState } from 'react';
import colors from 'theme/Colors.module.scss';
import ActiveCampaignCard from './ActiveCampaignCard2';
import moment from 'moment';
import './ActiveCampaign2.scss';
import { checkCampaignStatus, getTemplateThumb } from 'helpers/campaignHelper';
import { useDispatch, useSelector } from 'react-redux';
import {
  creativesActions,
  creativesSelectors,
} from 'modules/creatives/creativesDuck';
import SortAsc from 'components/Icons/SortAsc';
import SortDesc from 'components/Icons/SortDesc';

const ActiveCampaign2 = props => {
  const { campaigns, stats, tabOption } = props;
  const [filterOption, setFilterOption] = useState(newCampaignFilterOptions[0]);
  const [filteredCampaignList, setFilteredCampaignList] = useState(campaigns);
  const dispatch = useDispatch();
  const templates = useSelector(state => creativesSelectors.creatives(state));

  useEffect(() => {
    setFilteredCampaignList(
      campaigns
        ?.filter(campaign => checkCampaignStatus(campaign))
        .sort((a, b) => b.priority - a.priority)
    );
  }, [campaigns]);

  useEffect(() => {
    if (!Array.isArray(campaigns) || !Array.isArray(stats)) {
      return;
    }

    let campaignClone = JSON.parse(JSON.stringify(campaigns));
    campaignClone.forEach(campaign => {
      let printsNum = stats.filter(stat => stat.id === campaign.id)[0]
        ?.total_imp;
      if (printsNum === undefined) printsNum = 0;
      campaign.printsNum = printsNum;
    });

    if (filterOption.type === 'priority' && filterOption.order === 'asc') {
      campaignClone.sort((a, b) => b.priority - a.priority);
      setFilteredCampaignList(campaignClone);
    } else if (
      filterOption.type === 'priority' &&
      filterOption.order === 'desc'
    ) {
      campaignClone.sort((a, b) => a.priority - b.priority);
      setFilteredCampaignList(campaignClone);
    } else if (filterOption.type === 'volume' && filterOption.order === 'asc') {
      campaignClone.sort((a, b) => a.printsNum - b.printsNum);
      setFilteredCampaignList(campaignClone);
    } else if (
      filterOption.type === 'volume' &&
      filterOption.order === 'desc'
    ) {
      campaignClone.sort((a, b) => b.printsNum - a.printsNum);
      setFilteredCampaignList(campaignClone);
    }
  }, [filterOption, campaigns, stats]);

  useEffect(() => {
    const payload = { current_page: 1, size: 10000 };
    dispatch(creativesActions.getTemplates(payload));
  }, [dispatch]);

  return (
    <Box className="active-campaign__container">
      <Box className="active-campaign__sort-wrapper">
        <Box className="active-campaign__sort-divider" />
        <Box className="active-campaign__sort">
          <Box className="active-campaign__sort-text">
            {`Campaigns (${filteredCampaignList?.length})`}
          </Box>
          {filterOption.order === 'desc' && (
            <SortDesc
              style={{
                color:
                  tabOption.value === 'prints'
                    ? colors.primary900
                    : colors.secondary900,
              }}
              onClick={() => {
                setFilterOption(newCampaignFilterOptions[1]);
              }}
            />
          )}
          {filterOption.order === 'asc' && (
            <SortAsc
              style={{
                color:
                  tabOption.value === 'prints'
                    ? colors.primary900
                    : colors.secondary900,
              }}
              onClick={() => setFilterOption(newCampaignFilterOptions[0])}
            />
          )}
        </Box>
        <Box className="active-campaign__sort-divider" />
      </Box>
      <Grid className="active-campaign__grid">
        {
          // stats.length > 0 &&
          // templates.length > 0 &&
          filteredCampaignList?.map((campaign, index) => {
            let template = templates.filter(
              template => template.id === campaign.ad_template_id
            );

            return (
              <GridItem
                className="active-campaign__grid-item"
                key={`active-campaign-${index}`}
              >
                <ActiveCampaignCard
                  startDate={moment(campaign.start_date)}
                  endDate={moment(campaign.end_date)}
                  isStartDateExist={campaign.start_date !== null}
                  isEndDateExist={campaign.end_date !== null}
                  dateFormat="MM/DD/YY"
                  statusString={
                    checkCampaignStatus(campaign) ? 'Active' : 'Inactive'
                  }
                  tabOption={tabOption}
                  priority={campaign.priority}
                  title={campaign.name}
                  printsNum={campaign.printsNum}
                  imgSrc={getTemplateThumb(template[0])}
                />
              </GridItem>
            );
          })
        }
      </Grid>
    </Box>
  );
};

export default ActiveCampaign2;
