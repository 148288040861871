import React, { useEffect, useState } from 'react';

import { format, isValid, parse } from 'date-fns';
import FocusTrap from 'focus-trap-react';
import { DayPicker } from 'react-day-picker';
import { Box } from '@chakra-ui/react';
import './DatePicker.scss';

const DatePicker = ({ selectedDate, onInputChange, inputStyle }) => {
  const [selected, setSelected] = useState(selectedDate ?? new Date());
  const [inputValue, setInputValue] = useState(
    selectedDate
      ? format(selectedDate, 'MM-dd-y')
      : format(new Date(), 'MM-dd-y')
  );
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    fireOnInputChange(selected);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  // const popperRef = useRef<HTMLDivElement>(null);
  // const buttonRef = useRef<HTMLButtonElement>(null);
  // const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(
  //   null
  // );

  // const popper = usePopper(popperRef.current, popperElement, {
  //   placement: 'bottom-start'
  // });

  // const closePopper = () => {
  //   setIsPopperOpen(false);
  //   buttonRef?.current?.focus();
  // };
  const fireOnInputChange = v => {
    console.log('fireOnInputChange', v);
    onInputChange && onInputChange(v);
  };

  const handleInputChange = e => {
    setInputValue(e.currentTarget.value);
    const date = parse(e.currentTarget.value, 'MM-dd-y', new Date());
    if (isValid(date)) {
      setSelected(date);
      fireOnInputChange(date);
    } else {
      setSelected(undefined);
      fireOnInputChange(undefined);
    }
  };

  const handleButtonClick = () => {
    setIsVisible(true);
  };

  const handleDaySelect = date => {
    setSelected(date);
    if (date) {
      const v = format(date, 'MM-dd-y');
      setInputValue(v);
      setIsVisible(false);
      fireOnInputChange(date);
    } else {
      setInputValue('');
      fireOnInputChange('');
    }
  };

  return (
    <div style={{ position: 'relative' }}>
      <div>
        <input
          size={12}
          type="text"
          placeholder={format(new Date(), 'y-MM-dd')}
          value={inputValue}
          onChange={handleInputChange}
          onClick={handleButtonClick}
          style={inputStyle}
        />
      </div>
      {isVisible && (
        <FocusTrap
          active
          focusTrapOptions={{
            initialFocus: false,
            allowOutsideClick: true,
            clickOutsideDeactivates: true,
            onDeactivate: () => setIsVisible(false),
            fallbackFocus: undefined,
          }}
        >
          <Box
            position={'absolute'}
            borderRadius={'6px'}
            border={'solid 1px #E5E7EB;'}
            boxShadow={'0px 0px 6px rgba(0, 0, 0, 0.10)'}
            backgroundColor={'white'}
            right={'0'}
            top={'45px'}
            zIndex={100}
          >
            <DayPicker
              initialFocus={isVisible}
              mode="single"
              defaultMonth={selected}
              selected={selected}
              onSelect={handleDaySelect}
            />
          </Box>
        </FocusTrap>
      )}
    </div>
  );
};

export default DatePicker;
