import { fromJS } from 'immutable';
import {
  start,
  success,
  failure,
  unauthorized,
  error,
} from 'helpers/reducerHelpers';
// import { actionCreator } from 'helpers/actionHelpers';
// import * as api from './appApi';
import * as appConstants from './appConstants';

// Reducer
export const reducer = (
  ogState = {
    [appConstants.STATE_KEY_APP_MODE]: 'retailer',
    [appConstants.STATE_KEY_MESSAGES]: [],
  },
  action
) => {
  const state = fromJS(ogState);

  switch (action.type) {
    case success(appConstants.LOG_IN):
      return state.set(appConstants.STATE_KEY_TOKEN, action.payload).toJS();
    case success(appConstants.LOG_OUT):
      // TODO remove this hack to make per-session flags work
      window.localStorage.removeItem('FLAG_OVERRIDES');
      return state
        .set(appConstants.STATE_KEY_TOKEN, action.payload.token)
        .toJS();
    case success(appConstants.CHECK_TOKEN):
      return state.set(appConstants.STATE_KEY_TOKEN, action.payload).toJS();
    // case success(appConstants.UPLOAD_TEMPLATE):
    //   return state.set(appConstants.UPLOAD_TEMPLATE, action.payload).toJS();
    case appConstants.SET_APP_MODE:
      return state.set(appConstants.STATE_KEY_APP_MODE, action.payload).toJS();
    case success(appConstants.SEND_PW_RESET_EMAIL):
    case start(appConstants.STATE_KEY_INVALID_PW_RESET_TOKEN):
      return state
        .set(appConstants.STATE_KEY_INVALID_PW_RESET_TOKEN, null)
        .toJS();
    case success(appConstants.VERIFY_RESET_TOKEN):
    case appConstants.CLEAR_INVALID_PW_RESET_TOKEN:
      return state
        .set(appConstants.STATE_KEY_INVALID_PW_RESET_TOKEN, null)
        .toJS();
    case success(appConstants.CHANGE_PASSWORD):
    case appConstants.SET_MESSAGE:
      const setMsgTarget = [appConstants.STATE_KEY_MESSAGES];
      const setMsgOriginal = state.getIn(setMsgTarget);
      const setMsgFinal = state
        .setIn(setMsgTarget, [...setMsgOriginal.toJS(), action.payload])
        .toJS();

      return setMsgFinal;
    case appConstants.CLEAR_MESSAGE:
      const clearMsgTarget = [appConstants.STATE_KEY_MESSAGES];
      const clearMsgOriginal = state.getIn(clearMsgTarget);
      const clearMsgArr = state.removeIn(clearMsgTarget);
      const clearMsgFinal = clearMsgArr
        .setIn(
          clearMsgTarget,
          // action.payload should be the index
          clearMsgOriginal.filter((_, index) => index !== action.payload)
        )
        .toJS();

      return clearMsgFinal;

    case unauthorized(appConstants.LOG_IN):
    case failure(appConstants.LOG_IN):
    case failure(appConstants.LOG_OUT):
    case failure(appConstants.CHECK_TOKEN):
    case failure(appConstants.SET_APP_MODE):
    case failure(appConstants.SEND_PW_RESET_EMAIL):
    case failure(appConstants.VERIFY_RESET_TOKEN):
      return state
        .set(appConstants.STATE_KEY_INVALID_PW_RESET_TOKEN, true)
        .toJS();
    case failure(appConstants.CHANGE_PASSWORD):
    case error(action.type):
      // GENERIC API ERROR HANDLER, will match any error for any type
      //console.log('APP_REDUCER : ', 'API_ERROR', action.payload)
      return ogState;
    default:
      return ogState;
  }
};

// Actions

export default reducer;
