import React, { useEffect, useState } from 'react';
import { paramsToObject, paramsToString } from '../../../helpers/paramHelpers';
import {
  drPresetOptions,
  changeDRPresetRange,
  isValidDate,
  defaultDates,
  fillGapsWith,
} from 'helpers/presetDateHelpers';
import {
  withRouter,
  useHistory,
  useLocation,
  useParams,
} from 'react-router-dom';
import moment from 'moment';
import sortBy from 'lodash/sortBy';
import startCase from 'lodash/startCase';

import Button from '../../../components/Button/Button';
// import Overview from '../dashboard/Overview/Overview';
import DataCard from '../../../components/DataCard/DataCard';
import MarketingTable from '../../../components/Table/MarketingTable';
import AdvertisingTable from '../../../components/Table/AdvertisingTable';
// import { useFlag } from '../../flags';
import colors from 'theme/Colors.module.scss';
import './AnalyticsDetail.scss';

const tabs = ['Marketing', 'Advertising']; // TODO: Add back 'Combined' tab when ready.
// TODO: Will need to add; 'Avg. Order Val.', 'Total Order Val.';
const marketingColumns = [
  'Campaign Name',
  // 'Conversions',
  'Personalized Prints',
  // 'Revenue',
];
const advertisingColumns = ['Advertiser Name', 'Revenue', 'Impressions'];
// const blank = '--';

// NOTE: not currently used in the v2 design - jk 2/13/24
const AnalyticsDetail = props => {
  const {
    stats,
    getStats,
    statsById,
    getStatsById,
    campaigns,
    getCampaigns,
    isStatsLoading,
    isMobile,
    dashParamsFromState,
    saveDashParamsToState,
    kpiCardVisibility,
    setKpiCardVisibility,
  } = props;
  const history = useHistory();
  const match = useParams();
  const { search } = useLocation();

  const { start: userStart, end: userEnd } = paramsToObject(
    dashParamsFromState || search
  );
  const [{ startDate, endDate }, setDates] = useState({
    startDate: userStart ? moment(userStart) : defaultDates.start,
    endDate: userEnd ? moment(userEnd) : defaultDates.end,
  });

  const [activeKPI, setActiveKPI] = useState('impressions');
  const [activeTab] = useState(tabs[0]);
  console.log('startDate in analytics - ', startDate);
  const range = {
    start: startDate.local().format('YYYY-MM-DD'),
    startUtc: startDate.startOf('day').utc().format(),
    startRaw: startDate.local(),
    end: endDate.local().format('YYYY-MM-DD'),
    endUtc: endDate.endOf('day').utc().format(),
    endRaw: endDate.local(),
    days: endDate.diff(startDate, 'days'),
  };

  const params = paramsToString({
    start: range.start,
    end: range.end,
  });

  const utcParams = paramsToString({
    start: range.startUtc,
    end: range.endUtc,
  });

  const id = match.id;
  const campaign =
    campaigns && campaigns.filter(campaign => campaign.id === id)[0];
  const campaignData =
    stats &&
    Object.keys(stats.data).map(id => {
      return {
        id,
        data: stats.data[id],
      };
    });

  let dataPoints = [];
  //let dateVals = [];

  statsById &&
    statsById.data &&
    statsById.data.forEach(dataPoint => {
      const indices = {
        date: 0,
        impressions: 1,
        conversions: 2,
        conversion_pct: 3,
        total: 4,
      };
      const index = indices[activeKPI];
      const data = dataPoint[index];

      let dt = moment(dataPoint[indices.date]).format('YYYY-MM-DD');
      dataPoints[dt] = data;
    });

  dataPoints = fillGapsWith(dataPoints, 0);
  dataPoints = Object.fromEntries(
    sortBy(Object.entries(dataPoints), function (o) {
      return o[0];
    })
  );

  useEffect(() => {
    getCampaigns();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isValidDate(startDate) && isValidDate(endDate)) {
      getStats(utcParams);
      getStatsById(id, utcParams);
      saveDashParamsToState(params);
      history.push(`/analytics/${id}${params}`);
    } else {
      setDates({
        startDate: defaultDates.start,
        endDate: defaultDates.end,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate, endDate]);

  const refreshData = id => getStatsById(id, utcParams);

  const changeCampaign = newID => {
    if (newID !== id) {
      history.push(`/analytics/${newID}${params}`);
      getStatsById(newID, utcParams);
    }
  };

  const changeDate = (day, type) => {
    const newDay = moment(day);

    if (type === 'start') setDates({ endDate, startDate: newDay });
    if (type === 'end') setDates({ startDate, endDate: newDay });
  };

  // const buildHeader = () => {
  //   const dynamicCards = () => {
  //     if (activeTab === 'Advertising') {
  //       return (
  //         <Overview
  //           title={`Analytics Overview`}
  //           cards={[
  //             {
  //               icon: <i className="fas fa-coins" />,
  //               value: `$ ${
  //                 statsById
  //                   ? parseFloat(statsById.total_amt).toLocaleString()
  //                   : blank
  //               }`,
  //               title: 'Revenue',
  //               subTitle: null,
  //               bgColor: colors.magenta,
  //             },
  //             {
  //               icon: <i className="fas fa-binoculars" />,
  //               value: statsById ? statsById.total_imp.toLocaleString() : blank,
  //               title: 'Impressions',
  //               subTitle: null,
  //               bgColor: colors.blue,
  //             },
  //           ]}
  //         />
  //       );
  //     }

  //     return (
  //       // TODO: Re-enable other cards data endpoint is available.
  //       <Overview
  //         title={`Analytics Overview`}
  //         cards={[
  //           {
  //             icon: <i className="fas fa-funnel-dollar" />,
  //             value: statsById ? statsById.total_conv.toLocaleString() : blank,
  //             title: `Conversions`,
  //             subTitle: `Rate: ${
  //               statsById
  //                 ? (parseFloat(statsById.total_conv_pct) * 100).toFixed(2)
  //                 : blank
  //             }%`,
  //             bgColor: colors.status00,
  //           },
  //           {
  //             icon: <i className="fas fa-binoculars" />,
  //             value: statsById ? statsById.total_imp.toLocaleString() : blank,
  //             title: 'Impressions',
  //             subTitle: null,
  //             bgColor: colors.blue,
  //           },
  //           {
  //             icon: <i className="fas fa-coins" />,
  //             value: `$ ${
  //               statsById
  //                 ? parseFloat(statsById.total_amt).toLocaleString()
  //                 : blank
  //             }`,
  //             title: 'Revenue',
  //             subTitle: null,
  //             bgColor: colors.magenta,
  //           },
  //           // {
  //           //   icon: <i className="fas fa-money-bill" />,
  //           //   value: `$ 0`,
  //           //   title: 'Conversion AOV',
  //           //   subTitle: null,
  //           //   bgColor: colors.status00
  //           // },
  //           // {
  //           //   icon: <i className="fas fa-file-invoice-dollar" />,
  //           //   value: `$ 0`,
  //           //   title: 'Total Order Value',
  //           //   subTitle: null,
  //           //   bgColor: colors.slate
  //           // }
  //         ]}
  //       />
  //     );
  //   };

  //   return <ul className="overview">{dynamicCards()}</ul>;
  // };

  const buildData = () => {
    const dataSets = [
      {
        values: Object.values(dataPoints),
        color: colors.secondary900,
      },
    ];

    return dataSets;
  };

  const buildKpiOptions = () => {
    if (activeTab === 'Advertising') {
      return (
        <React.Fragment>
          <option value="conversions">Conversions</option>
          <option value="total">Revenue</option>
        </React.Fragment>
      );
    }

    return (
      <React.Fragment>
        {/* <option value="conversions">Conversions</option> */}
        <option value="impressions">
          Total Packages With Personalized Prints
        </option>
        {/* <option value="total">Revenue</option> */}
        {/* TODO: Re-enable these when data is available. */}
        {/* <option value="avg_order_value">Avgerage Order Value</option>
        <option value="total_order_value">Total Order Value</option> */}
      </React.Fragment>
    );
  };

  const buildGraph = () => {
    const noSnakeCase =
      activeKPI === 'total'
        ? 'revenue'
        : activeKPI === 'impressions'
        ? 'Packages with personalized prints'
        : activeKPI.replace(/_/g, ' ');

    return (
      <DataCard
        title={`${startCase(noSnakeCase)}`}
        dataSet={{
          data: buildData(),
          xLabels: Object.keys(dataPoints),
        }}
        visible={kpiCardVisibility}
        setVisible={newVal => setKpiCardVisibility(newVal)}
        range={range}
        presetOptions={drPresetOptions}
        changeDate={(day, type) => changeDate(day, type)}
        onRefresh={() => refreshData(statsById.id)}
        changeKPI={newKPI => setActiveKPI(newKPI)}
        changeDRPresetRange={(days, label) =>
          changeDRPresetRange(days, setDates, label)
        }
        kpiOptions={buildKpiOptions()}
        activeKPI={activeKPI}
        loading={isStatsLoading}
        showLegend={false}
      />
    );
  };

  const buildTable = () => {
    if (activeTab === tabs[1]) {
      return (
        <AdvertisingTable
          isMobile={isMobile}
          data={campaignData} // TODO: change to 'advertiserData' when data is ready.
          columns={advertisingColumns}
          activeData={statsById}
          advertisers={null} // TODO: change to 'advertisers' when data is ready.
          rowClick={id => changeCampaign(id)}
        />
      );
    }

    return (
      <MarketingTable
        isMobile={isMobile}
        data={campaignData}
        columns={marketingColumns}
        activeData={statsById}
        campaigns={campaigns}
        rowClick={id => changeCampaign(id)}
      />
    );
  };

  return (
    <>
      <div className="analyticsHeader">
        <div className="backBtn">
          <Button
            type="button"
            text="Back"
            className="backBtn"
            textColor={colors.slate}
            icon="back"
            btnStyle="transparent"
            url={`/dashboard${params}`}
          />
        </div>

        <div className="analyticsTabs"></div>

        <div className="activeName">
          <h2>{campaign && campaign.name}</h2>
        </div>
      </div>

      {/* {buildHeader()} */}
      {buildGraph()}
      {buildTable()}
    </>
  );
};

export default withRouter(AnalyticsDetail);
