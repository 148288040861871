import Handlers from './handlers';
import EventManager from './EventManager';
import ZoomController from './controllers/ZoomController';
import BackgroundController from './controllers/BackgroundController';
import CanvasController from './controllers/CanvasController';
import FrameController from './controllers/FrameController';

class Editor extends EventManager {
  handlers;
  zoom;
  background;
  canvas;
  frame;
  context;
  doubleSided;

  constructor(props) {
    super();
    this.handlers = new Handlers({ ...props, editor: this });
    this.zoom = new ZoomController(this.handlers.zoomHandler);
    this.background = new BackgroundController(this.handlers.backgroundHandler);
    this.canvas = new CanvasController(this.handlers.canvasHandler);
    this.frame = new FrameController(this.handlers.frameHandler);
    this.context = props.context;
    this.doubleSided = props.context.doubleSided;
  }

  // Add objects to editor
  add = options => {
    this.handlers.objectsHandler.add(options);
  };
  // Update selected object properties
  update = options => {
    this.handlers.objectsHandler.update(options);
  };

  copy = () => {
    this.handlers.objectsHandler.copy();
  };

  copyStyle = () => {
    this.handlers.objectsHandler.copyStyle();
  };

  cut = () => {
    this.handlers.objectsHandler.cut();
  };

  paste = () => {
    this.handlers.objectsHandler.paste();
  };

  clone = () => {
    this.handlers.objectsHandler.clone();
  };

  delete = () => {
    this.handlers.objectsHandler.remove();
  };

  clear = () => {
    this.handlers.objectsHandler.clear();
  };

  deselect = () => {
    this.handlers.objectsHandler.deselect();
  };

  // HISTORY
  undo = () => {
    this.handlers.historyHandler.undo();
  };
  redo = () => {
    this.handlers.historyHandler.redo();
  };

  // ZOOM
  zoomIn = () => {
    this.handlers.zoomHandler.zoomIn();
  };
  zoomOut = () => {
    this.handlers.zoomHandler.zoomOut();
  };
  zoomToOne = () => {
    this.handlers.zoomHandler.zoomToOne();
  };
  zoomToFit = () => {
    this.handlers.zoomHandler.zoomToFit();
  };
  zoomToRatio = zoomRatio => {
    this.handlers.zoomHandler.zoomToRatio(zoomRatio);
  };

  // ALIGMENT
  alignTop = () => {
    this.handlers.objectsHandler.alignTop();
  };
  alignMiddle = () => {
    this.handlers.objectsHandler.alignMiddle();
  };
  alignBottom = () => {
    this.handlers.objectsHandler.alignBottom();
  };
  alignLeft = () => {
    this.handlers.objectsHandler.alignLeft();
  };
  alignCenter = () => {
    this.handlers.objectsHandler.alignCenter();
  };
  alignRight = () => {
    this.handlers.objectsHandler.alignRight();
  };

  //LAYERS
  bringForward = () => {
    this.handlers.objectsHandler.bringForward();
  };
  bringToFront = () => {
    this.handlers.objectsHandler.bringToFront();
  };
  sendBackwards = () => {
    this.handlers.objectsHandler.sendBackwards();
  };
  sendToBack = () => {
    this.handlers.objectsHandler.sendToBack();
  };

  // GROUP
  group = () => {
    this.handlers.objectsHandler.group();
  };

  ungroup = () => {
    this.handlers.objectsHandler.ungroup();
  };

  // LOCK - UNLOCK
  lock = () => {
    this.handlers.objectsHandler.lock();
  };

  unlock = () => {
    this.handlers.objectsHandler.unlock();
  };

  // GRADIENT
  setGradient = options => {
    this.handlers.objectsHandler.setGradient(options);
  };

  // SHADOW
  setShadow = options => {
    this.handlers.objectsHandler.setShadow(options);
  };

  // DESIGN
  exportToJSON = () => {
    return this.handlers.templateHandler.exportToJSON();
  };
  importFromJSON = data => {
    this.handlers.templateHandler.importFromJSON(data);
  };
  toSVG = () => {};
  toPNG = options => {
    return this.handlers.designHandler.toDataURL(options);
  };

  // Frame
  addFrame = () => {
    return this.handlers.frameHandler.create();
  };

  // Var key menu for element
  closeKeysMenu = () => {
    if (this.context.keysMenu?.visible === true) {
      this.context.setKeysMenu({
        visible: false,
        position: {
          top: 0,
          left: 0,
        },
      });
    }
  };

  // Right click context menu
  closeContextMenu = () => {
    if (this.context.contextMenu?.visible === true) {
      this.context.setContextMenu({
        visible: false,
        object: undefined,
        position: {
          top: 0,
          left: 0,
        },
      });
    }
  };

  destroy = () => {
    this.handlers.destroy();
  };
}

export default Editor;
