import { apiActions } from '../api/apiDuck';
// import { inventorySchema } from './inventoryDuck';

export const getInventoryOrders = (params, type) => {
  return apiActions.apiAction({
    path: `inventory/orders${params}`,
    type: type,
    method: 'GET',
  });
};

export const getInventoryBalances = type => {
  return apiActions.apiAction({
    path: `inventory/balances`,
    type: type,
    method: 'GET',
  });
};
