import { fromJS } from 'immutable';
import { success, failure, start } from '../../helpers/reducerHelpers';
import * as usersActions from './usersActions';

const STATE_KEY_ALL_USERS = 'allUsers';
const STATE_KEY_USER_INFO = 'accountInfo';
const STATE_KEY_USERS_LOADING = 'usersLoading';
const STATE_KEY_CREATED_USER = 'createdUser';

export const usersConstants = {
  STATE_KEY_ALL_USERS,
  STATE_KEY_USER_INFO,
  STATE_KEY_USERS_LOADING,
  STATE_KEY_CREATED_USER,
};

const reducer = (ogState = {}, action) => {
  const state = fromJS(ogState);

  switch (action.type) {
    case start(usersActions.getAllUsers.type):
      return state
        .merge({
          [STATE_KEY_ALL_USERS]: [],
          [STATE_KEY_USERS_LOADING]: true,
        })
        .toJS();
    case success(usersActions.getAllUsers.type):
      return state
        .merge({
          [STATE_KEY_ALL_USERS]: action.payload,
          [STATE_KEY_USERS_LOADING]: false,
        })
        .toJS();
    case start(usersActions.createUser.type):
      return state.set(STATE_KEY_CREATED_USER, null).toJS();
    case success(usersActions.createUser.type):
      return state.set(STATE_KEY_CREATED_USER, action.payload).toJS();
    case start(usersActions.getUserById.type):
      return state.set(STATE_KEY_USER_INFO, null).toJS();
    case success(usersActions.getUserById.type):
      return state.set(STATE_KEY_USER_INFO, action.payload).toJS();
    case success(usersActions.updateUserById.type):
      return state.set(STATE_KEY_USER_INFO, action.payload).toJS();
    case success(usersActions.removeUserById.type):
      return state.set(STATE_KEY_ALL_USERS, action.payload).toJS();

    case failure(usersActions.getAllUsers.type):
    case failure(usersActions.getUserById.type):
    case failure(usersActions.updateUserById.type):
    case failure(usersActions.removeUserById.type):
    default:
      return ogState;
  }
};

export default reducer;
