import React from 'react';
import { uniqBy } from 'lodash';
import {
  Box,
  Flex,
  Popover,
  PopoverTrigger,
  PopoverContent,
  InputRightElement,
  Text,
  Checkbox,
  CheckboxGroup,
  Button,
} from '@chakra-ui/react';
import Icons from '../../../Icons';

function TemplateFilter({ templates, sizeFormats, setFilter }) {
  const [templateSizeFormats, setTemplateSizeFormats] = React.useState([]);
  const [checkedItems, setCheckedItems] = React.useState([false, false]);
  const [filterExists, setFilterExists] = React.useState(false);

  React.useEffect(() => {
    if (templates && sizeFormats) {
      let uniqueTemplateSizeFormats = [];
      templates.data?.forEach(template => {
        const exists = uniqueTemplateSizeFormats.find(
          ts => ts.label === template.size_format.label
        );
        if (!exists) {
          uniqueTemplateSizeFormats = uniqueTemplateSizeFormats.concat({
            ...template.size_format,
          });
        }
      });
      const mergedTemplateSizeFormats =
        uniqueTemplateSizeFormats.concat(sizeFormats);
      const uniqueMergedSizeFormats = uniqBy(
        mergedTemplateSizeFormats,
        'label'
      );
      const checkedObject = {};
      uniqueMergedSizeFormats.forEach(uniqueMergedSizeFormat => {
        checkedObject[uniqueMergedSizeFormat.label] = false;
      });
      setCheckedItems(checkedObject);
      setTemplateSizeFormats(uniqueMergedSizeFormats);
    }
  }, [templates, sizeFormats]);

  const isDisabled = label => {
    const exists = sizeFormats.find(s => s.label === label);
    return !exists;
  };

  const handleApplyFilter = () => {
    let filterExist = false;
    Object.keys(checkedItems).forEach(k => {
      if (!filterExist) {
        filterExist = checkedItems[k];
      }
    });
    setFilterExists(filterExist);
    setFilter(checkedItems);
  };
  return (
    <Popover placement="bottom-end">
      <PopoverTrigger>
        <InputRightElement
          children={
            <Flex
              sx={{
                height: '2.5rem',
                width: '2.5rem',
                alignItems: 'center',
                justifyContent: 'center',
                cursor: 'pointer',
              }}
            >
              {filterExists && (
                <Box
                  sx={{
                    position: 'absolute',
                    top: 1,
                    right: 1,
                    height: '14px',
                    width: '14px',
                    background: '#7f66f1',
                    fontSize: '10px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    color: '#ffffff',
                    borderRadius: '50%',
                  }}
                >
                  1
                </Box>
              )}

              <Icons.Filter size={24} color="gray.300" />
            </Flex>
          }
        />
      </PopoverTrigger>
      <PopoverContent sx={{ width: '312px', padding: '1.5rem' }}>
        <Text fontWeight={600} fontSize="14px">
          Format size
        </Text>
        <Box marginY="1rem">
          <CheckboxGroup
            colorScheme="green"
            defaultValue={['naruto', 'kakashi']}
          >
            {templateSizeFormats.map((sizeFormat, index) => {
              return (
                <Checkbox
                  onChange={e =>
                    setCheckedItems({
                      ...checkedItems,
                      [sizeFormat.label]: e.target.checked,
                    })
                  }
                  checked={sizeFormat.label}
                  key={index}
                  colorScheme="blue"
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    marginBottom: '0.5rem',
                  }}
                  value={sizeFormat.label}
                >
                  <Flex fontSize="14px" alignItems="center">
                    <Box>{sizeFormat.label}</Box>
                    {isDisabled(sizeFormat.label) && (
                      <Box sx={{ paddingLeft: '1rem', color: '#afafaf' }}>
                        Disabled
                      </Box>
                    )}
                  </Flex>
                </Checkbox>
              );
            })}
          </CheckboxGroup>
        </Box>
        <Button
          onClick={handleApplyFilter}
          fontSize="14px"
          borderRadius={2}
          colorScheme="orange"
          background="rgb(255, 111, 15)"
          w="100%"
        >
          Apply filters
        </Button>
      </PopoverContent>
    </Popover>
  );
}
export default TemplateFilter;
