import { fabric } from 'fabric';
// import { objectTypes } from '../common/constants';

const conditionalPath =
  'M215.9,171.1c0.1-0.5,0.2-1,0.2-1.5v-43.9c0-10.3-8.4-18.8-18.8-18.8h-63.2v-22c17.1-3.4,30-18.5,30-36.5c0-20.5-16.7-37.2-37.2-37.2c-20.5,0-37.2,16.7-37.2,37.2c0,18.1,12.9,33.1,30,36.5v22.1H56.5c-10.3,0-18.8,8.4-18.8,18.8v43.6c-15.9,4.2-27.7,18.7-27.7,36c0,20.5,16.7,37.2,37.2,37.2s37.2-16.7,37.2-37.2c0-18.9-14.1-34.5-32.3-36.9v-42.8c0-2.4,2-4.3,4.3-4.3h140.8c2.4,0,4.3,2,4.3,4.3v43.9c0,0.5,0,1,0.2,1.5c-17.2,3.3-30.2,18.4-30.2,36.6c0,20.5,16.7,37.2,37.2,37.2c20.5,0,37.2-16.7,37.2-37.2C246,189.5,233,174.4,215.9,171.1z M104.1,48.3c0-12.6,10.2-22.8,22.8-22.8c12.6,0,22.8,10.2,22.8,22.8c0,12.5-10.2,22.8-22.8,22.8C114.3,71.1,104.1,60.9,104.1,48.3z M70,205.3c0,12.6-10.2,22.8-22.8,22.8c-12.6,0-22.8-10.2-22.8-22.8s10.2-22.8,22.8-22.8C59.8,182.5,70,192.8,70,205.3z M208.8,230.4c-12.6,0-22.8-10.2-22.8-22.8c0-12.6,10.2-22.8,22.8-22.8c12.6,0,22.8,10.2,22.8,22.8S221.4,230.4,208.8,230.4z';

//@ts-ignore
export class ConditionalQrObject extends fabric.Rect {
  static type = 'ConditionalQr';
  subtype = 'img';
  keyValues;
  label = '';

  getKeyScaling() {
    const { height, width } = this.getBoundingRect();

    let scale = 1;
    if (height > 120 && width > 120) {
      scale = 1.5;
    }
    if ((height < 80 && height > 70) || (width < 80 && width > 70)) {
      scale = 0.75;
    }
    if (height < 70 || width < 70) {
      scale = 0.55;
    }
    return scale;
  }

  getCanvasBoundingClientRect() {
    const canvasEl = document.getElementById('canvas');
    const position = {
      left: canvasEl?.getBoundingClientRect().left,
      top: canvasEl?.getBoundingClientRect().top,
    };
    return position;
  }

  _set(key, value) {
    if (key === 'keyValues') {
      const keyValues = value ? value : [];
      if (value.length > 0) {
        if (!keyValues[0].key && !keyValues[0].value) {
          this.keyValues = [];
          return super._set(key, []);
        }
        this.keyValues = keyValues;
      } else {
        return super._set(key, this.keyValues);
      }
    }
    return super._set(key, value);
  }

  initialize(options) {
    this.subtype = options.subtype;
    this.keyValues = options.keyValues ? options.keyValues : [];
    if (options.keyValues && options.keyValues.length < 1) {
      this.keyValues = (options?.keys || []).map(key => ({
        key: '{{' + key + '}}',
        value: '',
      }));
    }
    super.initialize({ ...options, strokeWidth: 0, fill: '#ecf0f1' });
    

    this.on('mouseup', e => {
      if (e.button === 1) {
        
      } else if (e.button === 3) {
        const canvasPosition = this.getCanvasBoundingClientRect();
        const eventData = {
          object: this,
          visible: true,
          position: {
            left: canvasPosition.left + e.pointer.x + 8,
            top: canvasPosition.top + e.pointer.y - 24,
          },
        };
        this.canvas.fire('object:rightclicked', eventData);
        this.canvas.setActiveObject(this);
      }
    });

    this.on('mousewheel', function () {
      this.canvas.renderAll();
      this._renderControls(this.canvas.contextTop, {
        hasControls: false,
      });
    });

    return this;
  }

  _renderPathFor(subtype) {
    return conditionalPath;
  }

  _renderStroke(ctx) {
    let keyScale = this.getKeyScaling();
    const path = this._renderPathFor(this.subtype);
    ctx.save();
    ctx.scale(0.5 / this.scaleX, 0.5 / this.scaleY);
    ctx.scale(keyScale, keyScale);
    ctx.translate(-125, -125);
    ctx.fillStyle = '#000000';
    const icon = new Path2D(path);
    ctx.fill(icon);
    ctx.font = '50px Arial'; 
    ctx.fillStyle = '#000000'; 
    ctx.fillText('Conditional QR', -40, 325); // Draw
    ctx.fillText('Code', 65, 375); // Draw
    ctx.restore();
  }

  toObject(propertiesToInclude = []) {
    return fabric.util.object.extend(
      super.toObject.call(this, propertiesToInclude),
      {
        clipPath: this.clipPath,
        label: this.label,
      }
    );
  }
  toJSON(propertiesToInclude = []) {
    return fabric.util.object.extend(
      super.toObject.call(this, propertiesToInclude),
      {
        clipPath: this.clipPath,
        label: this.label,
      }
    );
  }
  static fromObject(options, callback) {
    return callback && callback(new fabric.ConditionalQr(options));
  }
}

fabric.ConditionalQr = fabric.util.createClass(ConditionalQrObject, {
  type: ConditionalQrObject.type,
});
fabric.ConditionalQr.fromObject = ConditionalQrObject.fromObject;
