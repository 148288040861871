import React from 'react';
import { Box, Flex, Text } from '@chakra-ui/react';
import Icons from 'components/Icons';
import ButtonComponent from './ButtonComponent';
import DotSpinner from 'components/DotSpinner/DotSpinner';
import ProgressBar from 'components/ProgressBar/ProgressBar';

export default function MissingFontManager({
  font,
  isFontUploaded,
  uploadFontFile,
  index,
  currentFonts,
  templateFile,
  setTemplateFile,
  setFontsResolved,
  fileFormat,
}) {
  const [isReplaceSelected, setIsReplaceSelected] = React.useState(false);
  const [showFonts, setShowFonts] = React.useState(false);
  // const [fontOptions, setFontOptions] = React.useState([]);
  const [selectedFont, setSelectedFont] = React.useState();
  const [uploadProgress, setUploadProgress] = React.useState(0);
  const [isUploading, setIsUploading] = React.useState(false);
  const interval = React.useRef(null);
  const fileInputRef = React.useRef(null);

  const replaceFont = newFontSelected => {
    setSelectedFont(newFontSelected.postscriptname);
    const currentTemplateFile = templateFile;
    let new_ad_template_vars = [];

    templateFile.ad_template_vars.map(layer => {
      if (
        layer.res_type === 'txt_template' &&
        layer.metadata.postscriptname === font
      ) {
        new_ad_template_vars.push({
          label: layer.label,
          metadata: {
            ...layer.metadata,
            fontFamily: newFontSelected.family,
            postscriptname: newFontSelected.postscriptname,
            __fontFilename: newFontSelected.postscriptname,
          },
          pos_x: layer.pos_x,
          pos_y: layer.pos_y,
          res_type: layer.res_type,
          size_x: layer.size_x,
          size_y: layer.size_y,
          var_type: layer.var_type,
          z_index: layer.z_index,
        });
      } else {
        new_ad_template_vars.push(layer);
      }
      return layer;
    });

    currentTemplateFile.ad_template_vars = new_ad_template_vars;
    setTemplateFile(currentTemplateFile);
    setFontsResolved(prev => ({ ...prev, [font]: true }));
    // handleInitLoad();
  };

  const checkCondition = isFontUploaded || selectedFont;

  const onFileSlected = ev => {
    if (!ev.target.files[0]) {
      fileInputRef.current.value = '';
      return;
    }

    setIsUploading(true);

    if (interval.current) clearInterval(interval.current);
    let value = 0;

    const callback = onComplete => {
      if (interval.current) {
        clearInterval(interval.current);
      }
      let initValue = 80;
      interval.current = setInterval(() => {
        if (initValue >= 100) {
          setIsUploading(false);
          if (fileInputRef?.current) fileInputRef.current.value = '';
          if (!!onComplete) {
            onComplete();
          }
          clearInterval(interval.current);
        } else {
          initValue += 4;
          setUploadProgress(initValue);
        }
      }, 100);
    };

    interval.current = setInterval(() => {
      if (value >= 80) {
        uploadFontFile(ev, callback, () => {
          if (fileInputRef?.current) fileInputRef.current.value = '';
          clearInterval(interval.current);
          setIsUploading(false);
        });

        clearInterval(interval.current);
      } else {
        value += 2;
        setUploadProgress(value);
      }
    }, 100);
  };

  React.useEffect(() => {
    if (fileFormat !== 'psd') return;
    let privateFonts = {};

    currentFonts.private.map(font => {
      privateFonts[font.family] = [font];
      return font;
    });

    const filterFontOptions = [];
    const concatFonts = Object.values(currentFonts.public).concat(
      Object.values(privateFonts)
    );

    concatFonts.map(fonts => {
      fonts.map(font => {
        filterFontOptions.push(font);
        return font;
      });
      return fonts;
    });
    // setFontOptions(filterFontOptions);

    return () => {
      setSelectedFont(null);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fileFormat]);

  React.useEffect(() => {
    if (!isUploading) {
      setUploadProgress(0);
    }
  }, [isUploading]);

  return (
    <Box key={font}>
      <Flex align="center" justify="space-between">
        <Flex align="center" pl={!checkCondition ? '25px' : ''}>
          {checkCondition && <Icons.Checked />}
          <Text fontSize="18px" color="#293854" fontWeight={500} m="0 0 0 10px">
            {font}
          </Text>
        </Flex>
        {!checkCondition && (
          <Flex align="center">
            {!isReplaceSelected && fileFormat === 'psd' && (
              <ButtonComponent
                borderRadius="3px"
                bgColor="#f0f0f0"
                color="black"
                p="0.5rem 1rem"
                mr="2rem"
                display="flex"
                alignItems="center"
                fontWeight={400}
                hoverColor="#f3f3f3"
                mouseDownColor="#f6f6f6"
                onClick={() => setIsReplaceSelected(prev => !prev)}
              >
                <Icons.Exchange height={20} />
                <Text m="0 0 0 10px">Replace</Text>
              </ButtonComponent>
            )}
            <ButtonComponent
              bgColor="#f0f0f0"
              color="black"
              p="0.5rem 1rem"
              display="flex"
              alignItems="center"
              ml={fileFormat === 'ai' ? '1rem' : ''}
              fontWeight={400}
              hoverColor="#f3f3f3"
              mouseDownColor="#f6f6f6"
              onClick={() => {
                fileInputRef.current?.click();
                setIsReplaceSelected(false);
                setSelectedFont(null);
                setFontsResolved(prev => ({ ...prev, [font]: false }));
              }}
            >
              <Icons.UploadCloud height={20} />
              <Text m="0 0 0 10px">Upload</Text>
            </ButtonComponent>
            <input
              ref={fileInputRef}
              hidden
              type="file"
              id={`upload-font-btn-${index}`}
              onChange={onFileSlected}
            />
          </Flex>
        )}
      </Flex>

      {!checkCondition && isReplaceSelected && (
        <Flex align="center" m="10px 0 0 5rem">
          <Text fontSize="16px" fontWeight={400} color="#293854">
            Replace with
          </Text>

          <Box
            style={{
              height: '30px',
              borderRadius: '4px',
              outline: 'none',
              border: '1px solid #5E2EBA',
              marginLeft: '18px',
              flex: 1,
              cursor: 'pointer',
              position: 'relative',
              display: 'flex',
              alignItems: 'center',
            }}
            onClick={() => setShowFonts(prev => !prev)}
          >
            <Flex
              position="absolute"
              top="35px"
              left="0"
              w="100%"
              overflowY="auto"
              maxHeight="500px"
              display={showFonts ? 'flex' : 'none'}
              bgColor="white"
              border="1px solid #E2E8F0"
              zIndex={99}
              direction="column"
            >
              {!!currentFonts.private.length && (
                <Flex direction="column">
                  <Box
                    fontWeight="600"
                    p="1rem 1.5rem"
                    borderBottom="1px solid lightgray"
                    fontSize="12px"
                    color="black"
                  >
                    Private fonts
                  </Box>
                  {currentFonts.private.map(privateFont => (
                    <Flex
                      key={privateFont.postscriptname}
                      padding="0.5rem 0 0.5rem 1.5rem"
                      cursor="pointer"
                      _hover={{ backgroundColor: '#f2f2f2' }}
                      fontSize="24px"
                      align="center"
                      onClick={() => replaceFont(privateFont)}
                    >
                      <img
                        src={privateFont.thumb}
                        alt={privateFont.postscriptname}
                      />
                    </Flex>
                  ))}
                </Flex>
              )}

              {!!Object.keys(currentFonts.public).length && (
                <Flex direction="column">
                  <Box
                    fontWeight="600"
                    p="1rem 1.5rem"
                    borderTop="1px solid lightgray"
                    fontSize="12px"
                    color="black"
                  >
                    Public fonts
                  </Box>
                  {Object.keys(currentFonts.public).map((fontName, idx) => {
                    const fontFamily = currentFonts.public[fontName];
                    return (
                      <Flex key={`${fontName}-${idx}`} direction="column">
                        {fontFamily.map(publicFont => (
                          <Flex
                            key={publicFont.postscriptname}
                            padding="0.5rem 0 0.5rem 1.5rem"
                            cursor="pointer"
                            _hover={{ backgroundColor: '#f2f2f2' }}
                            fontSize="24px"
                            align="center"
                            onClick={() => replaceFont(publicFont)}
                          >
                            <img
                              src={publicFont.thumb}
                              alt={publicFont.postscriptname}
                            />
                          </Flex>
                        ))}
                      </Flex>
                    );
                  })}
                </Flex>
              )}
            </Flex>

            <Icons.Search style={{ marginLeft: '1rem' }} />
            <Text ml="1rem" color="#394A64" fontSize="20px" fontWeight={400}>
              {selectedFont}
            </Text>
          </Box>
        </Flex>
      )}

      {isUploading && (
        <Flex h="43px" bgColor="#D9D9D9" w="100%" align="center" mt="10px">
          <Text fontSize="18px" color="black" fontWeight={600} ml="3rem">
            Uploading
          </Text>
          <DotSpinner size={1.5} height={10} style={{ marginLeft: '2rem' }} />
          <ProgressBar
            value={uploadProgress}
            height={15}
            style={{ marginLeft: '2rem' }}
          />
          <Text
            fontSize="10px"
            fontWeight="300"
            textDecoration="underline"
            m="0 1rem 0 2rem"
            cursor="pointer"
            onClick={() => {
              if (!!interval.current) {
                setIsUploading(false);
                clearInterval(interval.current);
                if (!!fileInputRef.current) {
                  fileInputRef.current.value = '';
                }
              }
            }}
          >
            Cancel
          </Text>
        </Flex>
      )}
      <hr style={{ background: '#DEE5EF', margin: '1rem 0' }} />
    </Box>
  );
}
