import React, { createContext, useState } from 'react';
import {
  defaultGrid,
  initialSizeFormat,
} from 'components/Canvas/common/constants';

export const EditorContext = createContext();

export const EditorProvider = ({ children }) => {
  const [activeObject, setActiveObject] = useState();
  const [handlers, setHandlers] = useState();
  const [sizeFormat, setSizeFormat] = useState(initialSizeFormat);
  const [doubleSided, setDoubleSided] = useState(false);
  const [currentTemplate, setCurrentTemplate] = useState(null);
  const [defaultSizeFormat, setDefaultSizeFormat] = useState(initialSizeFormat);
  const [zoomRatio, setZoomRatio] = useState(1);
  const [fitZoomRatio, setFitZoomRatio] = useState(1);
  const [fillZoomRatio, setFillZoomRatio] = useState(1);
  const [contextMenuType, setContextMenuType] = useState('default');
  const [grid, setGrid] = useState(defaultGrid);

  // editor is actually a class of helpers
  const [editor, setEditor] = useState(null);
  const [zoomMode, setZoomMode] = useState('fit');
  const [currentBackgroundColor, setCurrentBackgroundColor] =
    useState('#ffffff');
  const [currentBackgroundImage, setCurrentBackgroundImage] = useState(false);
  const [keysMenu, setKeysMenu] = useState({
    isEditing: false,
    shouldShow: false,
    visible: false,
    object: undefined,
    position: {
      top: 0,
      left: 0,
    },
  });
  
  const [contextMenu, setContextMenu] = useState({
    visible: false,
    object: undefined,
    position: {
      top: 0,
      left: 0,
    },
  });
  const context = {
    activeObject,
    setActiveObject,
    handlers,
    setHandlers,
    sizeFormat,
    setSizeFormat,
    doubleSided,
    setDoubleSided,
    currentTemplate,
    setCurrentTemplate,
    defaultSizeFormat,
    setDefaultSizeFormat,
    zoomRatio,
    setZoomRatio,
    fitZoomRatio,
    setFitZoomRatio,
    fillZoomRatio,
    setFillZoomRatio,
    zoomMode,
    setZoomMode,
    keysMenu,
    setKeysMenu,
    contextMenu,
    setContextMenu,
    contextMenuType,
    setContextMenuType,
    grid,
    setGrid,
    editor,
    setEditor,
    currentBackgroundColor,
    setCurrentBackgroundColor,
    currentBackgroundImage,
    setCurrentBackgroundImage,
  };

  return (
    <EditorContext.Provider value={context}>{children}</EditorContext.Provider>
  );
};

export default EditorContext;