import React from 'react';
import _, { isEmpty } from 'lodash';
import './Tabs.scss';
// import colors from '../../theme/Colors.module.scss';
import classNames from 'classnames';

const Tabs = props => {
  const { tabs } = props;

  // const buildTabs = () => {};

  return (
    <ul className="Tabs">
      {(() => {
        if (isEmpty(tabs)) return null;

        return tabs.map(tabData => {
          const { key, label, active, disabled, props } = tabData;
          const component = tabData.component || 'li';

          return React.createElement(
            component,
            {
              key,
              ...props,
              className: classNames('Tabs-tab', props?.className, {
                'Tabs-tab-active': active,
              }),
            },

            <button type="button" disabled={disabled}>
              {_.startCase(label)}
            </button>
          );
        });
      })()}
    </ul>
  );
};

export default Tabs;
