import { fabric } from 'fabric';
import BaseHandler from './BaseHandler';
import shourcutsManager from 'components/Canvas/utils/shourcutsManager';
import { objectTypes } from '../common/constants';
import { isInsideFrontSideFrame } from '../utils/frame';

class EventsHandler extends BaseHandler {
  spaceIsDown = false;
  blankEventData = {};
  constructor(props) {
    super(props);
    this.initialize();
  }

  initialize() {
    this.canvas.wrapperEl.tabIndex = 1;
    this.canvas.wrapperEl.style.outline = 'none';
    this.canvas.on({
      'mouse:down': this.onMouseDown,
      'mouse:up': this.handleSelection,
      'selection:cleared': this.handleSelection,
      'selection:updated': this.handleSelection,
      'mouse:wheel': this.onMouseWheel,
      'object:modified': this.objectModified,
      'text:key:selected': this.onTextKeySelected,
      'object:added': this.onObjectAdded,
      'object:rightclicked': this.onObjectRightClicked,
      'object:moving': this.onObjectDragging,
    });

    this.canvas.wrapperEl.addEventListener(
      'keydown',
      this.onKeyDown.bind(this),
      false
    );

    this.canvas.wrapperEl.addEventListener(
      'keyup',
      this.onKeyUp.bind(this),
      false
    );

    this.blankEventData = {
      object: undefined,
      visible: false,
      position: {
        left: 0,
        top: 0,
      },
    };
  }

  onMouseDown = e => {
    this.handlers.objectsHandler.pasteStyle();
  };

  onMouseDownBefore = event => {
    const isShift = event.e.shiftKey;
    const isCtrlKey = event.e.ctrlKey;
    const target = event.target;
    if (target) {
      if (isShift && isCtrlKey) {
        target.set('lockMovementY', true);
      } else if (isShift) {
        target.set('lockMovementX', true);
        target.set('snapAngle', 15);
      }
    }
  };

  onMouseUpBefore = event => {
    const target = event.target;
    if (target) {
      target.set('snapAngle', null);
      target.set('lockMovementX', false);
      target.set('lockMovementY', false);
    }
  };

  destroy() {
    this.canvas.off({
      'mouse:down': this.onMouseDown,
      'mouse:up': this.handleSelection,
      'selection:cleared': this.handleSelection,
      'selection:updated': this.handleSelection,
      'mouse:wheel': this.onMouseWheel,
      'object:modified': this.objectModified,
      'text:key:selected': this.onTextKeySelected,
      'object:added': this.onObjectAdded,
      'object:rightclicked': this.onObjectRightClicked,
      'object:moving': this.onObjectDragging,
    });

    this.canvas.wrapperEl.removeEventListener(
      'keydown',
      this.onKeyDown.bind(this)
    );
  }

  onTextKeySelected = key => {
    this.context.setKeysMenu(key);
  };

  onObjectRightClicked = key => {
    // if (key.visible) {
    //   this.context.setKeysMenu({ ...this.blankEventData, key: null });
    // }
    this.context.setContextMenu(key);
  };

  objectModified = () => {
    this.handlers.historyHandler.save('object:modified');
  };

  onObjectDragging = event => {
    const draggedObject = event.target;
    const frames = this.canvas
      .getObjects()
      .filter(object => object.type === objectTypes.FRAME);
    // Update object's clipPath based on its position
    if (frames.length > 1) {
      const isFront = isInsideFrontSideFrame(frames, draggedObject);
      if (
        (!!isFront && draggedObject.clipPath.id === 'backSide') ||
        (!isFront && draggedObject.clipPath.id !== 'backSide')
      ) {
        draggedObject.clipPath = frames.find(
          each => each.id !== draggedObject.clipPath.id
        );
      }
    }
  };

  onMouseWheel = event => {
    const isCtrlKey = event.e.ctrlKey;
    if (isCtrlKey) {
      this.handleZoom(event);
    }
  };

  handlePan = event => {
    const delta = event.e.deltaY;
    const isShiftKey = event.e.shiftKey;
    let pointX = 0;
    let pointY = delta > 0 ? -30 : 30;

    if (isShiftKey) {
      pointY = 0;
      pointX = delta > 0 ? -30 : 30;
    }
    const point = new fabric.Point(pointX, pointY);
    this.canvas.relativePan(point);
  };

  handleZoom = event => {
    const delta = event.e.deltaY;
    let zoomRatio = this.canvas.getZoom();
    if (delta > 0) {
      zoomRatio -= 0.02;
    } else {
      zoomRatio += 0.02;
    }
    this.handlers.zoomHandler.zoomToPoint(
      new fabric.Point(this.canvas.getWidth() / 2, this.canvas.getHeight() / 2),
      zoomRatio
    );
    event.e.preventDefault();
    event.e.stopPropagation();
  };

  onObjectAdded = event => {
    const object = event.target;
    if (object.type === 'Frame') return;

    // object.on('mouseup', function () {
    //   if (!this.canvas) return;const frames = this.canvas
    //     .getObjects()
    //     .filter(object => object.type === objectTypes.FRAME);
    //   const frames = this.canvas
    //     .getObjects()
    //     .filter(object => object.type === objectTypes.FRAME);
    //   const activeObject = this.canvas.getActiveObject();
    //   const radians = (0 * Math.PI) / 180;
    //   const cosa = Math.cos(radians);
    //   const sina = Math.sin(radians);
    //   const wp = activeObject.width / 2;
    //   const hp = activeObject.height / 2;
    //   const objCenter = {
    //     x: activeObject.left + wp * cosa - hp * sina,
    //     y: activeObject.top + wp * sina + hp * cosa,
    //   };
    //   const isDoubleSided = frames.length > 1;

    //   const [front, back] = frames;
    //   const { top, left, width, height } = front;

    //   const backTop = back ? back.top : null;
    //   const backHeight = back ? back.height : null;

    //   const objTop = objCenter.y + this.top;
    //   const objLeft = objCenter.x + this.left;
    //   const objWidth = this.width * this.scaleX;
    //   const objHeight = this.height * this.scaleY;

    //   const outOfBoundsLeft = objLeft + objWidth < left;
    //   const outOfBoundsRight = objLeft > left + width;

    //   let outOfBoundsTop = false;
    //   let outOfBoundsBottom = false;

    //   const isFrontsideElement = isInsideFrontSideFrame(frames, {
    //     ...this,
    //     width: objWidth,
    //     height: objHeight,
    //     left: objLeft,
    //     top: objTop,
    //   });
    //   console.log('isFront', isFrontsideElement);

    //   const isInsideBackFrame = objTop + objHeight > backTop;
    //   const isInsideFrontFrame = objTop < top + height;

    //   if (isFrontsideElement) {
    //     outOfBoundsTop = objTop + objHeight < top;
    //     outOfBoundsBottom = objTop > top + height;
    //   } else {
    //     outOfBoundsTop = objTop + objHeight < backTop;
    //     outOfBoundsBottom = objTop > backTop + backHeight;
    //   }

    //   if (outOfBoundsLeft || outOfBoundsRight) {
    //     this.canvas.remove(this);
    //     return;
    //   }

    //   if (outOfBoundsTop && isFrontsideElement) {
    //     this.canvas.remove(this);
    //     return;
    //   }

    //   if (outOfBoundsBottom && (!isFrontsideElement || !isDoubleSided)) {
    //     this.canvas.remove(this);
    //     return;
    //   }

    //   if (
    //     isDoubleSided &&
    //     outOfBoundsBottom &&
    //     isFrontsideElement &&
    //     !isInsideBackFrame
    //   ) {
    //     this.canvas.remove(this);
    //     return;
    //   }
    //   if (
    //     isDoubleSided &&
    //     outOfBoundsTop &&
    //     !isFrontsideElement &&
    //     !isInsideFrontFrame
    //   ) {
    //     this.canvas.remove(this);
    //     return;
    //   }

    //   if (outOfBoundsBottom && isFrontsideElement && isInsideBackFrame) {
    //     this.clipPath = back;
    //     this.clipPath = back;
    //     return;
    //   }

    //   if (outOfBoundsTop && !isFrontsideElement && isInsideFrontFrame) {
    //     this.clipPath = front;
    //     this.clipPath = front;
    //     return;
    //   }
    // });
  };

  onKeyDown(event) {
    if (shourcutsManager.isCtrlZero(event)) {
      event.preventDefault();
      this.handlers.zoomHandler.zoomToFit();
    } else if (shourcutsManager.isCtrlMinus(event)) {
      event.preventDefault();
      this.handlers.zoomHandler.zoomIn();
    } else if (shourcutsManager.isCtrlEqual(event)) {
      event.preventDefault();
      this.handlers.zoomHandler.zoomOut();
    } else if (shourcutsManager.isCtrlOne(event)) {
      event.preventDefault();
      this.handlers.zoomHandler.zoomToOne();
    } else if (shourcutsManager.isCtrlZ(event)) {
      this.handlers.historyHandler.undo();
    } else if (shourcutsManager.isCtrlShiftZ(event)) {
      this.handlers.historyHandler.redo();
    } else if (shourcutsManager.isCtrlY(event)) {
      this.handlers.historyHandler.redo();
    } else if (shourcutsManager.isAltLeft(event)) {
      event.preventDefault();
      this.handlers.objectsHandler.updateCharSpacing(-10);
    } else if (shourcutsManager.isAltRight(event)) {
      event.preventDefault();
      this.handlers.objectsHandler.updateCharSpacing(+10);
    } else if (shourcutsManager.isAltUp(event)) {
      event.preventDefault();
      this.handlers.objectsHandler.updateLineHeight(+0.1);
    } else if (shourcutsManager.isAltDown(event)) {
      event.preventDefault();
      this.handlers.objectsHandler.updateLineHeight(-0.1);
    } else if (shourcutsManager.isCtrlA(event)) {
      event.preventDefault();
      this.handlers.objectsHandler.selectAll();
    } else if (shourcutsManager.isSpace(event)) {
      event.preventDefault();
      if (!this.spaceIsDown) {
        this.spaceIsDown = true;
        this.handlers.objectsHandler.enableToggleDragMode(event);
      }
    } else if (shourcutsManager.isDelete(event)) {
      event.preventDefault();
      this.handlers.objectsHandler.remove();
    } else if (shourcutsManager.isCtrlC(event)) {
      event.preventDefault();
      this.handlers.objectsHandler.copy();
    } else if (shourcutsManager.isCtrlV(event)) {
      event.preventDefault();
      this.handlers.objectsHandler.paste();
    } else if (shourcutsManager.isCtrlX(event)) {
      event.preventDefault();
      this.handlers.objectsHandler.cut();
    } else if (shourcutsManager.isArrowUp(event)) {
      let nudgeValue = -1;
      if (shourcutsManager.isShift(event)) {
        nudgeValue = -10;
      }
      this.handlers.objectsHandler.moveVertical(nudgeValue);
    } else if (shourcutsManager.isArrowDown(event)) {
      let nudgeValue = 1;
      if (shourcutsManager.isShift(event)) {
        nudgeValue = 10;
      }
      this.handlers.objectsHandler.moveVertical(nudgeValue);
    } else if (shourcutsManager.isArrowLeft(event)) {
      let nudgeValue = -1;
      if (shourcutsManager.isShift(event)) {
        nudgeValue = -10;
      }
      this.handlers.objectsHandler.moveHorizontal(nudgeValue);
    } else if (shourcutsManager.isArrowRight(event)) {
      let nudgeValue = 1;
      if (shourcutsManager.isShift(event)) {
        nudgeValue = 10;
      }
      this.handlers.objectsHandler.moveHorizontal(nudgeValue);
    } else if (shourcutsManager.isCtrlBracketRight(event)) {
      this.handlers.objectsHandler.bringForward();
    } else if (shourcutsManager.isCtrlAltBracketRight(event)) {
      this.handlers.objectsHandler.bringToFront();
    } else if (shourcutsManager.isCtrlBracketLeft(event)) {
      this.handlers.objectsHandler.sendBackwards();
    } else if (shourcutsManager.isCtrlAltBracketLeft(event)) {
      this.handlers.objectsHandler.sendToBack();
    }
  }

  onKeyUp(event) {
    if (shourcutsManager.isSpace(event)) {
      event.preventDefault();
      if (this.spaceIsDown) {
        this.spaceIsDown = false;
        this.handlers.objectsHandler.disableToggleMode(event);
      }
    }
  }

  handleSelection = target => {
    if (target) {
      // this.context.setActiveObject(null)
      const initialSelection = this.canvas.getActiveObject();
      const isGroup = initialSelection && initialSelection.type === 'group';
      if (initialSelection && !isGroup && initialSelection._objects) {
        const filteredObjects = initialSelection._objects.filter(object => {
          if (object.type === 'Frame') {
            return false;
          }
          return !object.locked;
        });
        this.canvas.discardActiveObject();
        if (filteredObjects.length > 0) {
          if (filteredObjects.length === 1) {
            this.canvas.setActiveObject(filteredObjects[0]);
            this.context.setActiveObject(filteredObjects[0]);
          } else {
            const activeSelection = new fabric.ActiveSelection(
              filteredObjects,
              {
                canvas: this.canvas,
              }
            );
            this.canvas.setActiveObject(activeSelection);
            this.context.setActiveObject(activeSelection);
          }
        }
      } else {
        this.context.setActiveObject(initialSelection);
      }
    } else {
      this.context.setActiveObject(null);
    }
    this.canvas.renderAll();
  };
}

export default EventsHandler;
