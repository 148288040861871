import * as api from './usersApi';
import { createApiAction } from '../../helpers/actionHelpers';

export const getAllUsers = createApiAction(
  'modules/users/GET_ALL_USERS',
  (type, params, successCallback, failureCallback) => {
    return api.getAllUsers(params, successCallback, failureCallback, type);
  }
);

export const getUserById = createApiAction(
  'modules/users/GET_USER_BY_ID',
  (type, id, successCallback, failureCallback) =>
    api.getUserById(id, successCallback, failureCallback, type)
);

export const createUser = createApiAction(
  'modules/users/CREATE_USER',
  (type, payload, successCallback, failureCallback) =>
    api.createUser(payload, successCallback, failureCallback, type)
);

export const updateUserById = createApiAction(
  'modules/users/UPDATE_USER_BY_ID',
  (type, id, data, successCallback, failureCallback) =>
    api.updateUserById(id, data, successCallback, failureCallback, type)
);

export const removeUserById = createApiAction(
  'modules/users/REMOVE_USER_BY_ID',
  (type, id) => api.removeUserById(id, type)
);

export const searchInvitations = createApiAction(
  'modules/users/SEARCH_INVITATIONS',
  (type, params, successCallback, failureCallback) => {
    return api.searchInvitations(
      params,
      successCallback,
      failureCallback,
      type
    );
  }
);

export const invite = createApiAction(
  'modules/users/INVITE',
  (type, email, successCallback) => api.invite(email, successCallback, type)
);

export const checkInvitationToken = createApiAction(
  'modules/users/CHECK_INVITATION_TOKEN',
  (type, token, successCallback) =>
    api.checkInvitationToken(token, successCallback, type)
);
