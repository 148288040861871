import React, { useEffect } from 'react';
import { Box, Button, Flex, Text } from '@chakra-ui/react';
import Icons from 'components/Icons';

const RemoveFontError = ({ isOpen, onClose }) => {
  const interval = React.useRef(null);

  const closeModal = () => {
    interval.current && clearInterval(interval.current);
    onClose();
  };

  useEffect(() => {
    const onEscape = ev => ev.key === 'Escape' && closeModal();
    window.addEventListener('keydown', onEscape);
    return () => window.removeEventListener('keydown', onEscape);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box
      height="100%"
      width="100%"
      zIndex={500}
      position="absolute"
      top={0}
      left={0}
      display={isOpen ? 'grid' : 'none'}
      placeContent="center"
      backgroundColor="rgba(0,0,0,.5)"
    >
      <Box
        w="450px"
        backgroundColor="white"
        fontWeight="bold"
        borderRadius="7px"
        display={isOpen ? 'block' : 'none'}
        position="relative"
      >
        {/* Modal Header */}
        <Box textAlign="center" m="0" p="10px 0 0">
          {
            <Icons.Warning
              height={48}
              style={{ display: 'block', margin: '0 auto' }}
            />
          }
          <Text
            color="#293854"
            fontSize="18px"
            fontWeight="700"
            p="0 0 16px 0"
            m="5px 0 0 "
          >
            This font can't be removed since it is currently
            <br /> in use by an Ad Template.
          </Text>
        </Box>
        {/* Modal Body */}
        <hr style={{ margin: 0 }} />
        {/* Modal Foter */}
        <Box pt="21px" pb="1.1rem" p="21px 20px 1.1rem">
          <Flex align="center" w="100%">
            <Button
              flex="1"
              p="0.65rem 1rem"
              fontWeight={600}
              borderRadius="4px"
              fontSize="1rem"
              mr="0"
              onClick={closeModal}
              bgColor="#ff6f0f"
              border="none"
              outline="none"
              cursor="pointer"
              _hover={{
                backgroundColor: '#ff6f0fe5',
              }}
              color="white"
            >
              OK
            </Button>
          </Flex>
        </Box>
      </Box>
    </Box>
  );
};

export default RemoveFontError;
