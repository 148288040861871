import React from 'react';

function CopyStyle({ size }) {
  return (
    <svg
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.75 3H5.25C4.65326 3 4.08097 3.23705 3.65901 3.65901C3.23705 4.08097 3 4.65326 3 5.25L3 12C3.00119 12.9942 3.39666 13.9473 4.09966 14.6503C4.80267 15.3533 5.7558 15.7488 6.75 15.75H9.75V21H14.25V15.75H17.25C18.2442 15.7488 19.1973 15.3533 19.9003 14.6503C20.6033 13.9473 20.9988 12.9942 21 12V5.25C21 4.65326 20.7629 4.08097 20.341 3.65901C19.919 3.23705 19.3467 3 18.75 3ZM5.25 4.5H10.5V6H12V4.5H13.5V7.5H15V4.5H16.5V9H18V4.5H18.75C18.9489 4.5 19.1397 4.57902 19.2803 4.71967C19.421 4.86032 19.5 5.05109 19.5 5.25V10.5H4.5V5.25C4.5 5.05109 4.57902 4.86032 4.71967 4.71967C4.86032 4.57902 5.05109 4.5 5.25 4.5ZM17.25 14.25H12.75V19.5H11.25V14.25H6.75C6.15326 14.25 5.58097 14.0129 5.15901 13.591C4.73705 13.169 4.5 12.5967 4.5 12H19.5C19.5 12.5967 19.2629 13.169 18.841 13.591C18.419 14.0129 17.8467 14.25 17.25 14.25Z"
        fill="black"
      />
    </svg>
  );
}

export default CopyStyle;
