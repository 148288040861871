import React from 'react';
import { Box } from '@chakra-ui/react';
import Icons from '../Icons';
import useTemplateEditorContext from '../../hooks/useTemplateEditorContext';
import ArrowLeft from '../Icons/ArrowLeft';

const options = [
  {
    label: 'Text',
    name: 'Text',
    panel: 'Text',
    className: 'panel_text',
  },
  {
    label: 'Images',
    name: 'Images',
    panel: 'Images',
    className: 'panel_images',
  },
  {
    label: 'Background',
    name: 'Background',
    panel: 'Background',
    className: 'panel_background',
  },
  {
    label: 'QR',
    name: 'QR',
    panel: 'QR',
    className: 'panel_qr',
  },
  {
    label: 'Barcode',
    name: 'Barcode',
    panel: 'Barcode',
    className: 'panel_barcode',
  },
  {
    label: 'Templates',
    name: 'Templates',
    panel: 'Templates',
    className: 'panel_templates',
  },
];

function PanelItemsList(props) {
  const { activePanel, setActivePanel, setActiveSubMenu } =
    useTemplateEditorContext();
  const { showPanel, isPanelOpen } = props;

  return (
    <Box sx={{ width: '89px', backgroundColor: '#0E1317' }}>
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        {options.map((option, index) => (
          <PanelListItem
            sx={{ backgroundColor: 'red', paddingTop: '10px' }}
            key={option.label}
            label={option.label}
            name={option.name}
            panel={option.panel}
            index={index}
            currentPanel={activePanel}
            isPanelOpen={isPanelOpen}
            showPanel={showPanel}
            setCurrentPanel={setActivePanel}
            className={option.className}
            setActiveSubMenu={setActiveSubMenu}
          />
        ))}
      </Box>

      <Box
        onClick={() => {
          showPanel();
        }}
        sx={{
          color: 'red',
          position: 'absolute',
          bottom: '27px',
          width: '87px',
          cursor: 'pointer',
          display: 'flex',
          justifyContent: 'center',
          transform: isPanelOpen ? 'rotate(180deg)' : 'rotate(360deg)',
        }}
      >
        <ArrowLeft size={24} />
      </Box>
    </Box>
  );
}

function PanelListItem({
  name,
  label,
  panel,
  currentPanel,
  index,
  setCurrentPanel,
  className,
  isPanelOpen,
  showPanel,
  setActiveSubMenu,
}) {
  const Icon = Icons[name];

  // const styleByIndex =
  //   index === 0
  //     ? {
  //         height: '71px',
  //         backgroundColor: '#29303A',
  //         position: 'absolute',
  //         width: '23px',
  //         left: '78px',
  //         top: '85px',
  //         zIndex: '50',
  //         borderRadius: '96% 0px 0px 96%',
  //       }
  //     : {
  //         height: '149px',
  //         backgroundColor: '#29303A',
  //         position: 'absolute',
  //         width: '23px',
  //         left: '81px',
  //         zIndex: '50',
  //         borderRadius: '96% 0px 0px 96%',
  //       };

  return (
    <Box
      className={className}
      onClick={() => {
        setActiveSubMenu(null);
        setCurrentPanel(panel);
        if (!isPanelOpen) {
          showPanel();
        }
      }}
      sx={{
        height: '72px',
        textAlign: 'center',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        color: panel === currentPanel ? '#ffffff' : 'rgba(255,255,255,0.65)',
        backgroundColor:
          panel === currentPanel ? '#29303A' : 'rgba(255,255,255,0)',
        userSelect: 'none',
        transition: 'all 0.2s ease',
        fontWeight: 600,
        ':hover': {
          color: '#ffffff',
          cursor: 'pointer',
          backgroundColor: '#29303A',
        },
      }}
    >
      <Icon size={24} />
      <Box sx={{ fontSize: '11px', paddingTop: '0.05rem' }}>{label}</Box>
      {/*{isPanelOpen && panel === currentPanel && <Box sx={styleByIndex}> </Box>}*/}
    </Box>
  );
}

export default PanelItemsList;
