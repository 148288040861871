import { fabric } from 'fabric';
import { groupBy, uniqueId, pick } from 'lodash';
// import { objectTypes } from '../common/constants';
import { findRectCenter, clickHit } from '../utils/coordination';

const controls = fabric.controlsUtils;
const getLocalPoint = controls.getLocalPoint;
const wrapWithFireEvent = controls.wrapWithFireEvent;
const wrapWithFixedAnchor = controls.wrapWithFixedAnchor;
const scaleSkewCursorStyleHandler = controls.scaleSkewCursorStyleHandler;
const REGEX_VAR = new RegExp(/\{\{[a-zA-Z0-9-_]+?\}\}/g);

const CENTER = 'center';
const ELLIPSIS = '...';

function isTransformCentered(transform) {
  return transform.originX === CENTER && transform.originY === CENTER;
}

function changeHeight(eventData, transform, x, y) {
  let target = transform.target,
    localPoint = getLocalPoint(
      transform,
      transform.originX,
      transform.originY,
      x,
      y
    ),
    multiplier = isTransformCentered(transform) ? 2 : 1,
    oldHeight = target.height,
    newHeight = Math.abs((localPoint.y * multiplier) / target.scaleY);
  target.set('height', Math.max(newHeight, 0));
  target.set('prevheight', Math.max(newHeight, 0));
  return oldHeight !== newHeight;
}

function changeWidth(eventData, transform, x, y) {
  let target = transform.target,
    localPoint = getLocalPoint(
      transform,
      transform.originX,
      transform.originY,
      x,
      y
    ),
    strokePadding =
      target.strokeWidth / (target.strokeUniform ? target.scaleX : 1),
    multiplier = isTransformCentered(transform) ? 2 : 1,
    oldWidth = target.width,
    oldHeight = target.height,
    newWidth =
      Math.abs((localPoint.x * multiplier) / target.scaleX) - strokePadding;

  target.set('width', Math.max(newWidth, 0));
  target.set('height', oldHeight);
  target.set('prevheight', oldHeight);

  return oldWidth !== newWidth;
}

fabric.Textbox.prototype.controls.mb = new fabric.Control({
  x: 0,
  y: 0.5,
  actionHandler: wrapWithFireEvent(
    'resizing',
    wrapWithFixedAnchor(changeHeight)
  ),
  cursorStyleHandler: scaleSkewCursorStyleHandler,
  actionName: 'resizing',
  visible: false,
});

fabric.Textbox.prototype.controls.tr = new fabric.Control({
  x: 0.5,
  y: -0.5,
  actionHandler: wrapWithFireEvent(
    'resizing',
    wrapWithFixedAnchor(changeHeight)
  ),
  cursorStyleHandler: scaleSkewCursorStyleHandler,
  actionName: 'resizing',
  visible: false,
});

fabric.Textbox.prototype.controls.tl = new fabric.Control({
  x: -0.5,
  y: -0.5,
  actionHandler: wrapWithFireEvent(
    'resizing',
    wrapWithFixedAnchor(changeHeight)
  ),
  cursorStyleHandler: scaleSkewCursorStyleHandler,
  actionName: 'resizing',
  visible: false,
});

fabric.Textbox.prototype.controls.br = new fabric.Control({
  x: 0.5,
  y: 0.5,
  actionHandler: wrapWithFireEvent(
    'resizing',
    wrapWithFixedAnchor(changeHeight)
  ),
  cursorStyleHandler: scaleSkewCursorStyleHandler,
  actionName: 'resizing',
  visible: false,
});

fabric.Textbox.prototype.controls.bl = new fabric.Control({
  x: -0.5,
  y: 0.5,
  actionHandler: wrapWithFireEvent(
    'resizing',
    wrapWithFixedAnchor(changeHeight)
  ),
  cursorStyleHandler: scaleSkewCursorStyleHandler,
  actionName: 'resizing',
  visible: false,
});

fabric.Textbox.prototype.controls.mt = new fabric.Control({
  x: 0,
  y: -0.5,
  actionHandler: wrapWithFireEvent(
    'resizing',
    wrapWithFixedAnchor(changeHeight)
  ),
  cursorStyleHandler: scaleSkewCursorStyleHandler,
  actionName: 'resizing',
  visible: false,
});

fabric.Textbox.prototype.controls.ml = new fabric.Control({
  x: -0.5,
  y: 0,
  actionHandler: wrapWithFireEvent(
    'resizing',
    wrapWithFixedAnchor(changeWidth)
  ),
  cursorStyleHandler: scaleSkewCursorStyleHandler,
  actionName: 'resizing',
});

fabric.Textbox.prototype.controls.mr = new fabric.Control({
  x: 0.5,
  y: 0,
  actionHandler: wrapWithFireEvent(
    'resizing',
    wrapWithFixedAnchor(changeWidth)
  ),
  cursorStyleHandler: scaleSkewCursorStyleHandler,
  actionName: 'resizing',
});

export class DynamicTextObject extends fabric.Textbox {
  static type = 'DynamicText';
  initiated = false;
  triggered = false;
  updating = false;
  updatingC = false;
  keyValues = [];
  keys = [];
  keysBounds = [];
  prevKey = '';
  originalText = '';
  label = '';

  updateKeyValues() {
    this.setKeyBounds();

    let updatedKeyValues = [];
    const initialParams = this.getParamsFromKeys(this.text);
    const paramsFromValues = this.getKeysFromValues();

    initialParams.forEach(ip => {
      const existingParam = this.keyValues.find(kv => kv.key === ip.key);
      if (existingParam) {
        updatedKeyValues = updatedKeyValues.concat(existingParam);
      } else {
        updatedKeyValues = updatedKeyValues.concat({
          key: ip.key,
          value: ip.key.substring(2, ip.key.length - 2),
        });
      }
    });

    paramsFromValues.forEach(paramFromValue => {
      const exists = updatedKeyValues.find(
        updatedKeyValue => updatedKeyValue.key === paramFromValue.key
      );
      if (!exists) {
        updatedKeyValues = updatedKeyValues.concat(paramFromValue);
      }
    });
    this.keyValues = updatedKeyValues;
  }

  setValuesForKeys() {
    let keyParams = [];
    this.keyValues.forEach(keyValue => {
      const value = keyValue.value;
      const key = keyValue.key;
      const regex = new RegExp(key, 'g');
      const matches = [...this.text.matchAll(regex)];
      let params = [];
      matches.forEach(match => {
        const matchWord = match['0'];
        const startIndex = match['index'];
        params = params.concat({
          key: matchWord,
          startIndex: startIndex,
          endIndex: startIndex + matchWord.length,
          id: uniqueId(matchWord),
        });
      });
      params.forEach(param => {
        const id = uniqueId(param.key);
        const keyParam = this.replaceKeyWithValue(key, value, id);
        keyParams = keyParams.concat(keyParam);
      });
    });
    return keyParams;
  }

  getParamsFromKeys(text) {
    let params = [];
    const matches = [...text.matchAll(REGEX_VAR)];
    matches.forEach(match => {
      const matchWord = match['0'];
      const startIndex = match['index'];
      params = params.concat({
        key: matchWord,
        startIndex: startIndex,
        endIndex: startIndex + matchWord.length,
        id: uniqueId(matchWord),
      });
    });
    return params;
  }

  updateParams(fromAddedEvent = false) {
    this.updateKeyValues();
    this.setKeyBounds();
    this.setValuesForKeys(fromAddedEvent);
    this.setKeyBounds();
    this.updateKeys();

    if (!fromAddedEvent) return;
    this.padding = 0;
    if (!this.initialWidth) return;
    this.width = this.initialWidth;
  }

  updateKeys() {
    setTimeout(() => {
      let keys = [];
      let textLines = this.getUpdatedTextLines();
      textLines.forEach(textLine => {
        const params = this.getKeysFromTextStyles(textLine.lineStyles);
        params.forEach(param => {
          keys = keys.concat(param.key.substring(2, param.key.length - 2));
        });
      });
      this.keys = keys;
    }, 150);
  }

  replaceKeyWithValue(key, value, id) {
    const matchIndex = this.text.search(key);
    const styles = Array(value.length).fill({
      textBackgroundColor: '#d4d1cb',
      fill: '#29251f',
      key: key,
      id: id,
      value: value,
      original: value,
    });

    if (matchIndex > -1) {
      this.insertChars(value, styles, matchIndex, matchIndex + key.length);
      return {
        id,
        key,
        value,
        startIndex: matchIndex,
        endIndex: matchIndex + value.length,
      };
    }
  }

  updateVariableName(props) {
    const currentKey = props.key;
    const nextKey = props.next;

    const updatedTextLines = this.getUpdatedTextLines();
    updatedTextLines.forEach(updatedTextLine => {
      const params = this.getKeysFromTextStyles(updatedTextLine.lineStyles);
      const newParam = params.find(p => p.key === currentKey);

      if (newParam) {
        const { value } = newParam;
        const styles = Array(value.length).fill({
          textBackgroundColor: '#d4d1cb',
          fill: '#29251f',
          key: nextKey,
          id: newParam.id,
          value: value,
        });

        const newStyles = { ...this.styles };

        const indexes = Array.from(
          { length: newParam.endIndex - newParam.startIndex + 1 },
          (v, k) => newParam.startIndex + k
        );

        indexes.forEach(index => {
          delete newStyles[updatedTextLine.textStyleGroupIndex][index];
        });

        this.styles = newStyles;

        this.insertChars(
          value,
          styles,
          updatedTextLine.groupStartIndex + newParam.startIndex,
          updatedTextLine.groupStartIndex + newParam.endIndex + 1
        );
        this.updateKeyValues();
        this.enterEditing();
        this.exitEditing();
        this.canvas.requestRenderAll();
        return false;
      }
    });
  }

  updateVariableNameX(props) {
    const currentKey = props.key;
    const nextKey = props.next;

    const styles = this.styles;
    const textLines = this.text.split('\n');

    const textLinesStyled = textLines.map((textLine, index) => {
      const prevItems = textLines
        .slice(0, index)
        .map(tl => tl.length)
        .concat(0)
        .reduce((a, b) => a + b);
      return {
        textLine,
        styles: styles[index] ? styles[index] : {},
        lineStartIndex: prevItems + index,
      };
    });

    textLinesStyled.forEach((textLineStyled, index) => {
      const { styles } = textLineStyled;
      const params = this.getKeysFromTextStyles(styles);
      params.forEach(param => {
        const newParam = this.keyValues.find(kv => kv.key === currentKey);
        const { value } = newParam;
        const styles = Array(value.length).fill({
          textBackgroundColor: '#d4d1cb',
          fill: '#29251f',
          key: nextKey,
          id: param.id,
          value: value,
        });
        this.removeStyleFromTo(
          textLineStyled.lineStartIndex + param.startIndex,
          textLineStyled.lineStartIndex + param.endIndex
        );
        this.insertChars(
          value,
          styles,
          textLineStyled.lineStartIndex + param.startIndex,
          textLineStyled.lineStartIndex + param.endIndex
        );
        this.canvas.requestRenderAll();
      });
    });
    this.updateKeyValues();
  }

  replaceValueWithKeyForText(textLine) {
    const params = this.getKeysFromTextStyles(textLine.lineStyles);
    const lineEnding = textLine.breakLine ? '\n' : '';
    const lineVariation = textLine.initialText === textLine.text ? '' : ' ';
    if (params.length === 0) {
      return lineVariation + textLine.initialText + lineEnding;
    } else {
      let pieces = [];
      let textArr = textLine.initialText.split('');
      params.forEach((param, index) => {
        const currentParam = param;
        const prevParam = params[index - 1];
        const nextParam = params[index + 1];
        const diff = textLine.startIndex;

        // calculate initial section
        if (index === 0) {
          const initialSection = textArr.slice(0, param.startIndex - diff);
          if (initialSection.length > 0) {
            pieces = pieces.concat(initialSection.join(''));
          }
        } else {
          const initialSection = textArr.slice(
            prevParam.endIndex - diff,
            currentParam.startIndex - diff
          );
          if (initialSection.length > 0) {
            pieces = pieces.concat(initialSection.join(''));
          }
        }

        pieces = pieces.concat(param.key.split(''));

        if (!nextParam) {
          const lastSection = textArr.slice(
            param.startIndex + param.value.length - diff
          );
          if (lastSection.length > 0) {
            pieces = pieces.concat(lastSection.join(''));
          }
        }
      });
      return lineVariation + pieces.join('') + lineEnding;
    }
  }

  getKeysFromTextStyles(textSyles) {
    let charStyles = [];
    let params = [];
    Object.keys(textSyles).forEach(style => {
      if (textSyles[style].key && textSyles[style].value) {
        charStyles = charStyles.concat({
          index: parseInt(style),
          key: textSyles[style].key,
          value: textSyles[style].value,
          id: textSyles[style].id,
        });
      }
    });
    const groupedCharStyles = groupBy(charStyles, 'id');
    Object.keys(groupedCharStyles).forEach(group => {
      const value = groupedCharStyles[group][0].value;
      const key = groupedCharStyles[group][0].key;
      const indexes = groupedCharStyles[group]
        .map(g => g.index)
        .sort((a, b) => a - b);
      const [startIndex] = [indexes[0]];
      const param = {
        key,
        value,
        startIndex,
        endIndex: startIndex + value.length - 1,
        id: group,
      };
      params = params.concat(param);
    });
    return params;
  }

  /**
   * Update text lines normalizing text and adding styles by text line
   */
  getUpdatedTextLines() {
    let allText = this.text;
    const textLines = this.textLines;
    let updatedTextLines = [];
    let textStyleGroupIndex = 0;
    let startIndex = 0;
    let lineIndex = 0;
    let groupStartIndexProgress = 0;
    let currentProgress = 0;
    textLines.forEach((textLine, index) => {
      let currentTextLine = textLine;
      let isBreakLine = false;
      lineIndex = index;
      const prevUpdatedLine = updatedTextLines[index - 1];
      if (allText[0] === '\n') {
        allText = allText.substring(1);
        textStyleGroupIndex += 1;
        if (index) {
          prevUpdatedLine.breakLine = true;
        }
      } else {
        const textLineChange = index ? ' ' : '';
        currentTextLine = textLineChange + currentTextLine;
      }

      const initialPart = allText.substring(0, currentTextLine.length);
      const remainingPart = allText.substring(currentTextLine.length);

      if (index) {
        if (prevUpdatedLine.breakLine) {
          startIndex = 0;
        } else {
          startIndex =
            prevUpdatedLine.startIndex +
            prevUpdatedLine.text.trimLeft().length +
            (allText.length - allText.trimLeft().length);
        }
      }

      if (prevUpdatedLine && prevUpdatedLine.breakLine) {
        groupStartIndexProgress += 1;
        currentProgress = groupStartIndexProgress;
      }

      allText = remainingPart;
      updatedTextLines = updatedTextLines.concat({
        text: initialPart,
        breakLine: isBreakLine,
        textStyleGroupIndex,
        startIndex,
        lineIndex: lineIndex,
        initialText: textLine,
        groupStartIndex:
          prevUpdatedLine && prevUpdatedLine.breakLine
            ? groupStartIndexProgress
            : currentProgress,
      });

      groupStartIndexProgress += initialPart.length;
    });

    const textStyleGroups = this.styles;
    const updatedTextLinesWithStyles = updatedTextLines.map(updatedTextLine => {
      const textStyleGroup =
        textStyleGroups[updatedTextLine.textStyleGroupIndex];
      const indexes = Array(updatedTextLine.text.length)
        .fill(0)
        .map((_, i) => (updatedTextLine.startIndex + i).toString());
      const lineStyles = pick(textStyleGroup, indexes);
      return { ...updatedTextLine, lineStyles };
    });

    return updatedTextLinesWithStyles;
  }

  getUpdatedTextLinesForObject(object) {
    let allText = object.text;
    const textLines = object.textLines;
    let updatedTextLines = [];
    let textStyleGroupIndex = 0;
    let startIndex = 0;
    let lineIndex = 0;

    textLines.forEach((textLine, index) => {
      let currentTextLine = textLine;
      let isBreakLine = false;
      lineIndex = index;
      const prevUpdatedLine = updatedTextLines[index - 1];
      if (allText[0] === '\n') {
        allText = allText.substring(1);
        textStyleGroupIndex += 1;
        if (index) {
          prevUpdatedLine.breakLine = true;
        }
      } else {
        const textLineChange = index ? ' ' : '';
        currentTextLine = textLineChange + currentTextLine;
      }

      const initialPart = allText.substring(0, currentTextLine.length);
      const remainingPart = allText.substring(currentTextLine.length);

      if (index) {
        if (prevUpdatedLine.breakLine) {
          startIndex = 0;
        } else {
          startIndex =
            prevUpdatedLine.startIndex + prevUpdatedLine.text.length + 1;
        }
      }

      allText = remainingPart;
      updatedTextLines = updatedTextLines.concat({
        text: initialPart,
        breakLine: isBreakLine,
        textStyleGroupIndex,
        startIndex,
        lineIndex: lineIndex,
        initialText: textLine,
      });
    });

    const textStyleGroups = object.styles;
    const updatedTextLinesWithStyles = updatedTextLines.map(updatedTextLine => {
      const textStyleGroup =
        textStyleGroups[updatedTextLine.textStyleGroupIndex];
      const indexes = Array(updatedTextLine.text.length)
        .fill(0)
        .map((_, i) => (updatedTextLine.startIndex + i).toString());
      const lineStyles = pick(textStyleGroup, indexes);
      return { ...updatedTextLine, lineStyles };
    });
    return updatedTextLinesWithStyles;
  }

  getKeysFromValues() {
    let textLines = this.getUpdatedTextLines();
    let params = [];
    textLines.forEach(textLine => {
      const keyValue = this.getKeysFromTextStyles(textLine.lineStyles);
      params = params.concat(keyValue);
    });
    return params;
  }

  setKeyBounds() {
    setTimeout(() => {
      let keysBounds = [];
      let textLines = this.getUpdatedTextLines();
      textLines.forEach(textLine => {
        const lineHeight =
          this.__lineHeights[parseInt(textLine.textStyleGroupIndex)];
        const params = this.getKeysFromTextStyles(textLine.lineStyles);
        const linekeyBounds = params.map(param => {
          if (!this.__charBounds[textLine.lineIndex]) return false;
          const charBounds = this.__charBounds[textLine.lineIndex].map(cbs => ({
            ...cbs,
            top: lineHeight * textLine.lineIndex,
          }));

          const charBoundMin =
            charBounds[param.startIndex - textLine.startIndex];
          let charBoundMax = charBounds[param.endIndex - textLine.startIndex];
          charBoundMax = charBoundMax
            ? charBoundMax
            : charBounds[charBounds.length - 1];
          if (!charBoundMin || !charBoundMax) return {};
          const lineWidth = this.__lineWidths[textLine.lineIndex];
          const width = this.width;
          let shift = 0;
          if (this.textAlign === 'center') {
            shift = (width - lineWidth) / 2;
          } else if (this.textAlign === 'right') {
            shift = width - lineWidth;
          }

          const updatedTextLines = this.getUpdatedTextLines();
          const currentStyle = updatedTextLines[textLine.lineIndex];

          let startIndexRelative = this.getStartIndex(
            updatedTextLines,
            currentStyle,
            textLine.lineIndex
          );

          const lineIndex = textLine.lineIndex;
          const charBound = {
            ...charBoundMin,
            ...param,
            lineIndex,
            shift,
            left: shift + charBoundMin.left,
            top: charBoundMin.top,
            width: charBoundMax.width + charBoundMax.left - charBoundMin.left,
            height: charBoundMin.height,
            absoluteStarIndex:
              textLine.textStyleGroupIndex !== 0
                ? startIndexRelative + param.startIndex
                : param.startIndex,
            absoluteEndIndex:
              textLine.textStyleGroupIndex !== 0
                ? startIndexRelative + param.endIndex
                : param.endIndex,
            textStyleGroupIndex: textLine.textStyleGroupIndex,
          };
          return charBound;
        });
        keysBounds = keysBounds.concat(linekeyBounds);
      });
      this.keysBounds = keysBounds;
    }, 250);
  }

  /**
   * Replace all values with keys
   */

  replaceTextLineStyled(textLineStyled) {
    const { textLine, styles } = textLineStyled;
    const params = this.getKeysFromTextStyles(styles);
    if (params.length === 0) {
      return textLine;
    }
    let textArr = textLine.split('');
    let pieces = [];

    params.forEach((param, index) => {
      const currentParam = param;
      const prevParam = params[index - 1];
      const nextParam = params[index + 1];
      if (index === 0) {
        const initialSection = textArr.slice(0, param.startIndex);
        if (initialSection.length > 0) {
          pieces = pieces.concat(initialSection.join(''));
        }
      } else {
        const initialSection = textArr.slice(
          prevParam.endIndex + 1,
          currentParam.startIndex
        );
        if (initialSection.length > 0) {
          pieces = pieces.concat(initialSection.join(''));
        }
      }
      pieces = pieces.concat(param.key.split(''));

      if (!nextParam) {
        const lastSection = textArr.slice(param.endIndex + 1);
        if (lastSection.length > 0) {
          pieces = pieces.concat(lastSection.join(''));
        }
      }
    });
    return pieces.join('');
  }

  replaceValueWithKey() {
    const styles = this.styles;
    const textLines = this.text.split('\n');
    const textLinesStyled = textLines.map((textLine, index) => {
      return {
        textLine,
        styles: styles[index] ? styles[index] : {},
      };
    });
    let updatedText = '';

    textLinesStyled.forEach((textLineStyled, index) => {
      let originalText = this.replaceTextLineStyled(textLineStyled);
      if (textLinesStyled[index + 1]) {
        originalText += '\n';
      }
      updatedText += originalText;
    });
    this.insertChars(updatedText, null, 0, this.text.length);
  }

  // [IMPORTANT] ONLY one value at a time will be updated
  updateExistingValues() {
    const updatedTextLines = this.getUpdatedTextLines();
    let indexChanges = 0;
    updatedTextLines.forEach(updatedTextLine => {
      const params = this.getKeysFromTextStyles(updatedTextLine.lineStyles);

      params.forEach(param => {
        const newParam = this.keyValues.find(kv => kv.key === param.key);
        if (newParam.value !== param.value) {
          const { key, value } = newParam;
          const styles = Array(value.length).fill({
            textBackgroundColor: '#d4d1cb',
            fill: '#29251f',
            key: key,
            id: param.id,
            value: value,
          });

          const newStyles = { ...this.styles };

          const indexes = Array.from(
            { length: param.endIndex - param.startIndex + 1 },
            (v, k) => param.startIndex + k
          );

          indexes.forEach(index => {
            delete newStyles[updatedTextLine.textStyleGroupIndex][index];
          });

          this.styles = newStyles;

          this.insertChars(
            value,
            styles,
            indexChanges + updatedTextLine.groupStartIndex + param.startIndex,
            indexChanges + updatedTextLine.groupStartIndex + param.endIndex + 1
          );
          indexChanges += newParam.value.length - param.value.length;
          this.canvas.requestRenderAll();
        }
      });
    });

    this.updateKeyValues();
  }

  removeEmptyText() {
    if (!this.text) {
      this.canvas.remove(this);
    }
  }

  handleKeyDown(e) {
    const key = e.key;
    if (this.triggered && this.isEditing) {
      const eventData = {
        object: this,
        isEditing: this.isEditing,
        visible: false,
        position: {
          left: 0,
          top: 0,
        },
        key: null,
      };

      if (this.canvas) {
        this.canvas.fire('text:key:selected', eventData);
      }
    }
    if (key === '{') {
      if (this.prevKey === '{') {
        const currentPosition = this.selectionStart;
        if (this.text[currentPosition - 1] === '{') {
          const canvasPosition = this.getCanvasBoundingClientRect();
          const cursorLocation = this.get2DCursorLocation();
          const zoom = this.canvas.getZoom();
          const charbounds = [].concat(...this.__charBounds);
          const charBound = charbounds[this.selectionStart];
          const { scaleX, scaleY, width, height } = this;
          const { left, top } = this.getBoundingRect(false);
          const padLeft = (width * scaleX * zoom - width) / 2;
          const padTop = (height * scaleY * zoom - height) / 2;

          const eventData = {
            object: this,
            isEditing: this.isEditing,
            visible: true,
            position: {
              left: canvasPosition.left + left + padLeft + charBound.left,
              top:
                canvasPosition.top +
                top +
                padTop +
                (cursorLocation.lineIndex + 1) * charBound.height * zoom,
            },
            key: undefined,
          };
          if (this.canvas) {
            this.canvas.fire('text:key:selected', eventData);
          }
          this.triggered = true;
        }
      } else {
        this.prevKey = '{';
      }
    }
  }

  getCanvasBoundingClientRect() {
    const canvasEl = document.getElementById('canvas');
    const position = {
      left: canvasEl?.getBoundingClientRect().left,
      top: canvasEl?.getBoundingClientRect().top,
    };
    return position;
  }

  triggerKeysMenu() {
    this.setCoords();
    const canvasPosition = this.getCanvasBoundingClientRect();
    const zoom = this.canvas.getZoom();
    const { scaleX, scaleY, width, height } = this;
    const { left, top } = this.getBoundingRect(false);
    const padLeft = (width * scaleX * zoom - width) / 2;
    const padTop = (height * scaleY * zoom - height) / 2;
    const cursorLocation = this.get2DCursorLocation();
    const charBounds = this.__charBounds;
    const charBound =
      charBounds[cursorLocation.lineIndex][cursorLocation.charIndex];
    const eventData = {
      object: this,
      isEditing: this.isEditing,
      visible: true,
      position: {
        left: canvasPosition.left + left + padLeft + charBound.left,
        top:
          canvasPosition.top +
          top +
          padTop +
          (cursorLocation.lineIndex + 1) * charBound.height * zoom,
      },
      key: undefined,
    };
    this.canvas.fire('text:key:selected', eventData);
  }

  insertKey(value) {
    const initial = this.selectionStart;
    const end = this.selectionEnd;
    if (this.text[initial - 1] === '{') {
      this.insertChars(value, null, initial - 2, end);
      this.selectionStart = initial + value.length - 2;
    } else {
      this.insertChars(value, null, initial, end);
      this.selectionStart = initial + value.length;
    }
    this.exitEditing();
    this.canvas?.renderAll();
    setTimeout(() => {
      this.enterEditing();
    }, 1000);
  }

  _set(key, value) {
    if (key === 'keyValues') {
      const keyValues = value || [];
      if (keyValues.length > 0) {
        this.keyValues = keyValues;
        this.fire('text:keys:updated');
      }
    }
    return super._set(key, value);
  }

  initialize(options) {
    const { text, ...textOptions } = options;
    this.prevheight = options.height && options.height;
    this.keys = [];
    this.keysBounds = [];
    this.keyValues = options.keyValues ? options.keyValues : [];
    this.triggered = false;
    let updatedKeyValues = [];
    const initialParams = this.getParamsFromKeys(text);
    initialParams.forEach(ip => {
      const existingParam = this.keyValues.find(kv => kv.key === ip.key);
      if (existingParam) {
        updatedKeyValues = updatedKeyValues.concat(existingParam);
      } else {
        updatedKeyValues = updatedKeyValues.concat({
          key: ip.key,
          value: ip.key.substring(2, ip.key.length - 2),
        });
      }
    });
    this.keyValues = updatedKeyValues;
    super.initialize(text, {
      ...textOptions,
      backgroundColor: 'rgba(255,255,255,0)',
      keyValues: updatedKeyValues,
      editable: false,
    });

    this.on('modified', () => {
      this.removeEmptyText();
    });

    this.on('added', () => {
      this.canvas.on('mouse:move', e => {
        if (e.target === this) {
          const pointer = this.canvas.getPointer(e.e, false);
          const key = this.keysBounds.find(key => {
            if (!key) return false;
            const rectCenter = findRectCenter(
              this.left,
              this.top,
              this.width,
              this.height,
              this.angle
            );
            const keyPos = findRectCenter(
              this.left,
              this.top,
              2 * key.left,
              2 * key.top,
              this.angle
            );
            return clickHit(
              [pointer.x, pointer.y],
              [keyPos.x, keyPos.y],
              [key.width, key.height],
              this.angle,
              [rectCenter.x, rectCenter.y]
            );
          });
          if (key) this.hoverCursor = 'pointer';
          else this.hoverCursor = 'move';
        }
      });
      this.updateParams(true);
    });

    this.on('mousedblclick', e => {
      this.set('editable', true);
      const pointer = this.canvas.getPointer(e.e, false);
      const key = this.keysBounds.find(key => {
        if (!key) return false;
        const rectCenter = findRectCenter(
          this.left,
          this.top,
          this.width,
          this.height,
          this.angle
        );
        const keyPos = findRectCenter(
          this.left,
          this.top,
          2 * key.left,
          2 * key.top,
          this.angle
        );
        return clickHit(
          [pointer.x, pointer.y],
          [keyPos.x, keyPos.y],
          [key.width, key.height],
          this.angle,
          [rectCenter.x, rectCenter.y]
        );
      });

      if (key) {
        const updatedTextLines = this.getUpdatedTextLines();
        const cursorLocation = this.get2DCursorLocation();

        const currentLine = updatedTextLines.find(
          u => u.lineIndex === cursorLocation.lineIndex
        );

        // const startIndex = key.startIndex + currentLine.groupStartIndex;
        const endIndex = key.endIndex + currentLine.groupStartIndex + 1;

        this.set('editable', true);
        this.setSelectionStart(endIndex);
        this.setSelectionEnd(endIndex);
        this.enterEditing();
      } else {
        this.enterEditing();
      }
    });

    // this.on('mousemove', e => {
    //   const pointer = this.canvas.getPointer(e.e, false);
    //   const key = this.keysBounds.find(key => {
    //     if (!key) return;
    //     if (
    //       pointer.x >= key.left + this.left &&
    //       pointer.x <= this.left + key.left + key.width &&
    //       pointer.y >= key.top + this.top &&
    //       pointer.y <= this.top + key.top + key.height
    //     )
    //       return true;
    //     else return false;
    //   });
    //   if (key) this.hoverCursor = 'pointer';
    //   else this.hoverCursor = 'move';
    // });

    this.on('mouseup', e => {
      const pointer = this.canvas.getPointer(e.e, false);
      const key = this.keysBounds.find(key => {
        if (!key) return false;
        const rectCenter = findRectCenter(
          this.left,
          this.top,
          this.width,
          this.height,
          this.angle
        );
        const keyCors = findRectCenter(
          this.left,
          this.top,
          2 * key.left,
          2 * key.top,
          this.angle
        );
        return clickHit(
          [pointer.x, pointer.y],
          [keyCors.x, keyCors.y],
          [key.width, key.height],
          this.angle,
          [rectCenter.x, rectCenter.y]
        );
      });
      if (e.button === 1) {
        if (key) {
          const updatedTextLines = this.getUpdatedTextLines();
          const cursorLocation = this.get2DCursorLocation();

          const currentLine = updatedTextLines.find(
            u => u.lineIndex === cursorLocation.lineIndex
          );

          // const startIndex = key.startIndex + currentLine.groupStartIndex;
          const endIndex = key.endIndex + currentLine.groupStartIndex;

          this.setSelectionStart(endIndex);
          this.setSelectionEnd(endIndex);

          const canvasPosition = this.getCanvasBoundingClientRect();
          this.setCoords();
          const zoom = this.canvas.getZoom();
          const { scaleX, scaleY, width, height } = this;
          const { left, top } = this.getBoundingRect(false);
          const padLeft = (width * scaleX * zoom - width) / 2;
          const padTop = (height * scaleY * zoom - height) / 2;

          this.canvas?.renderAll();
          this.exitEditing();
          this.enterEditing();

          const eventData = {
            object: this,
            isEditing: this.isEditing,
            visible: true,
            position: {
              left:
                canvasPosition.left +
                left +
                padLeft +
                key.width * zoom +
                key.left,
              top:
                canvasPosition.top + top + padTop + key.height * zoom + key.top,
            },
            key,
          };
          this.canvas.fire('text:key:selected', eventData);
        } else {
          const eventData = {
            object: this,
            visible: false,
            position: {
              left: 0,
              top: 0,
            },
          };
          this.canvas.fire('object:rightclicked', eventData);
        }
      } else if (e.button === 3) {
        const canvasPosition = this.getCanvasBoundingClientRect();
        const eventData = {
          object: this,
          visible: true,
          position: {
            left: canvasPosition.left + e.pointer.x + 8,
            top: canvasPosition.top + e.pointer.y - 24,
          },
        };
        this.canvas.fire('object:rightclicked', eventData);
        this.canvas.setActiveObject(this);
      }
    });

    this.on('editing:entered', () => {
      const eventData = {
        object: this,
        isEditing: this.isEditing,
        visible: false,
        position: {
          left: 0,
          top: 0,
        },
        key: null,
      };
      if (this.canvas) {
        this.canvas.fire('text:key:selected', eventData);
      }

      window.addEventListener('keydown', this.handleKeyDown.bind(this));
    });

    this.on('editing:exited', () => {
      this.updateParams();
      this.set('editable', false);
      const eventData = {
        object: this,
        isEditing: this.isEditing,
        visible: false,
        position: {
          left: 0,
          top: 0,
        },
        key: null,
      };

      if (this.canvas) {
        this.canvas.fire('text:key:selected', eventData);
      }
      window.removeEventListener('keydown', this.handleKeyDown.bind(this));
    });

    this.on('text:keys:updated', () => {
      if (!this.isEditing) {
        this.updateExistingValues();
      }
    });

    this.on('resizing', () => {
      const textWidth = this.width;
      if (textWidth === this.dynamicMinWidth) {
        // if (this.updating) return;
        // this.updating = true;
        setTimeout(() => {
          const lineWidths = this.__lineWidths;
          lineWidths.forEach((lineWidth, index) => {
            const updatedTextLines = this.getUpdatedTextLines();
            const currentText = this.textLines[index];

            if (!currentText) return;

            const currentTextSize = currentText.length;

            if (lineWidth === this.dynamicMinWidth) {
              if (currentText.includes(ELLIPSIS)) {
                // Handle shortened ...
                if (currentTextSize > 4) {
                  const currentStyle = updatedTextLines[index];
                  const initial = currentText.slice(0, currentTextSize - 4);
                  // const final = currentText.slice(currentTextSize - 4);
                  const updatedText = initial + ELLIPSIS;
                  const firstIndexStyle = Object.keys(
                    currentStyle.lineStyles
                  )[0];
                  const prevStyle = currentStyle.lineStyles[firstIndexStyle];
                  let startIndex = this.getStartIndex(
                    updatedTextLines,
                    currentStyle,
                    index
                  );
                  const options = {
                    // ...options,
                    ...prevStyle,
                    value: updatedText,
                    startIndex: startIndex,
                    prev: currentText,
                  };
                  this.updateTextLine(options);
                } else {
                  // console.log('TOO SHORT TO HANDLE');
                }
              } else {
                // Handle initial
                const currentStyle = updatedTextLines[index];
                if (
                  currentTextSize > 4 &&
                  currentStyle &&
                  Object.keys(currentStyle.lineStyles).length > 0
                ) {
                  const initial = currentText.slice(0, currentTextSize - 3);
                  // const final = currentText.slice(currentTextSize - 3);
                  const updatedText = initial + ELLIPSIS;
                  const firstIndexStyle = Object.keys(
                    currentStyle.lineStyles
                  )[0];
                  const prevStyle = currentStyle.lineStyles[firstIndexStyle];

                  let startIndex = this.getStartIndex(
                    updatedTextLines,
                    currentStyle,
                    index
                  );
                  // console.log('B', startIndex);

                  const options = {
                    ...prevStyle,
                    value: updatedText,
                    startIndex: startIndex,
                    prev: currentText,
                  };
                  this.updateTextLine(options);
                } else {
                  // console.log('OUT OF RULES');
                }
              }
            }
          });
          // this.updating = false;
        }, 100);
      } else if (textWidth > this.dynamicMinWidth) {
        // return;
        // if (this.updating) return;
        // this.updating = true;
        setTimeout(() => {
          // console.log('UPDATING C', this.updating);
          // this.setCoords();
          this.clone(cloned => {
            // console.log(cloned);
            const lineWidths = this.__lineWidths;
            lineWidths.forEach((lineWidth, index) => {
              const updatedTextLines = this.getUpdatedTextLines();
              const currentText = this.textLines[index];
              if (!currentText) return;
              const currentTextSize = currentText.length;
              const currentStyle = updatedTextLines[index];
              const firstIndexStyle = Object.keys(currentStyle.lineStyles)[0];
              const prevStyle = currentStyle.lineStyles[firstIndexStyle];

              if (!prevStyle) return;
              const orinalText = prevStyle.original;
              const matchIndex = this.text.search(this.textLines[index]);

              if (
                currentText.includes(ELLIPSIS) &&
                currentStyle &&
                Object.keys(currentStyle.lineStyles).length > 0
              ) {
                if (lineWidth < textWidth) {
                  if (currentTextSize === orinalText.length) {
                    // const startIndex = this.getStartIndex(
                    //   updatedTextLines,
                    //   currentStyle,
                    //   index
                    // );
                    // console.log('C1', startIndex);

                    let updatedText = orinalText;
                    const requestedWidth = this.measureText(updatedText);
                    if (textWidth > requestedWidth) {
                      const options = {
                        ...prevStyle,
                        value: updatedText,
                        startIndex: matchIndex,
                        prev: currentText,
                      };
                      this.updateTextLine(options);
                    }
                  } else {
                    // console.log('C2');
                    const initial = prevStyle.value.slice(
                      0,
                      currentTextSize - 3
                    );
                    const next = orinalText.slice(0, initial.length + 1);
                    const updatedText = next + ELLIPSIS;
                    const requestedWidth = this.measureText(updatedText);
                    // const up = this.getUpdatedTextLinesForObject(cloned);

                    if (textWidth > requestedWidth) {
                      // console.log('sxxxx', initial, prevStyle);
                      let startIndex = this.getStartIndex(
                        updatedTextLines,
                        currentStyle,
                        index,
                        true,
                        initial
                      );
                      // console.log('C2', initial);
                      const options = {
                        ...prevStyle,
                        value: updatedText,
                        startIndex: startIndex,
                        prev: currentText,
                      };
                      this.updateTextLine(options, true);
                    }
                  }
                }
              }
            });
          });
          // this.updating = false;
        }, 5);
      }
      this.set(
        'height',
        this.__lineHeights.reduce((a, b) => a + b)
      );
      this.updateParams();
    });

    this.on('mousewheel', function () {
      this.canvas.renderAll();
      this._renderControls(this.canvas.contextTop, {
        hasControls: false,
      });
    });

    return this;
  }

  getStartIndex(updatedTextLines, currentStyle, index, print, initial) {
    let updated = JSON.parse(JSON.stringify(updatedTextLines));
    let startChart = 0;
    const matchIndex = currentStyle.text.search(this.textLines[index]);

    let matchIndexInLine = 0;
    if (initial) {
      matchIndexInLine = this.textLines[index].search(initial);
    }
    const textLines = updated.splice(0, index);
    // let add = 0;
    // if (initial) {
    //   add = currentStyle.text.search(initial) - 1;
    // }

    for (const textLine of textLines) {
      const lineChange = textLine.breakLine ? 1 : 0;
      startChart += textLine.text.length + lineChange;
    }

    return startChart + matchIndex + matchIndexInLine;
  }

  updateTextLine(options) {
    const styles = Array(options.value.length).fill(options);
    this.insertChars(
      options.value,
      styles,
      options.startIndex,
      options.startIndex + options.prev.length
    );
  }

  measureText(text) {
    const ctx = this.getMeasuringContext();
    ctx.font = `${this.fontSize}px ${this.fontFamily}`;
    return ctx.measureText(text).width;
  }

  getText() {
    const styles = this.styles;
    const textLines = this.text.split('\n');
    const textLinesStyled = textLines.map((textLine, index) => {
      return {
        textLine,
        styles: styles[index] ? styles[index] : {},
      };
    });
    let updatedText = '';

    textLinesStyled.forEach((textLineStyled, index) => {
      let originalText = this.replaceTextLineStyled(textLineStyled);
      if (textLinesStyled[index + 1]) {
        originalText += '\n';
      }
      updatedText += originalText;
    });
    return updatedText;
  }

  moveCursorDown(e) {
    if (
      this.selectionStart >= this._text.length &&
      this.selectionEnd >= this._text.length
    ) {
      return;
    }
    this._moveCursorUpOrDown('Down', e);
    this.shouldChangeSelectionPosition('Down', e);
  }

  moveCursorLeft(e) {
    if (this.selectionStart === 0 && this.selectionEnd === 0) {
      return;
    }
    this._moveCursorLeftOrRight('Left', e);
    this.shouldChangeSelectionPosition('Left', e);
  }

  moveCursorUp(e) {
    if (this.selectionStart === 0 && this.selectionEnd === 0) {
      return;
    }
    this._moveCursorUpOrDown('Up', e);
    this.shouldChangeSelectionPosition('Up', e);
  }

  shouldChangeSelectionPosition(direction, e) {
    const updatedTextLines = this.getUpdatedTextLines();
    const cursorLocation = this.get2DCursorLocation();
    const currentLine = updatedTextLines.find(
      u => u.lineIndex === cursorLocation.lineIndex
    );
    const currentLineStyles = currentLine.lineStyles;
    const prevOffset = currentLine.startIndex === 0 ? 0 : -1;
    const nextOffset = currentLine.startIndex === 0 ? -1 : 0;
    let isPreviousStyled =
      currentLineStyles[
        currentLine.startIndex + cursorLocation.charIndex + prevOffset
      ];
    let isNextStyled =
      currentLineStyles[
        currentLine.startIndex + cursorLocation.charIndex + nextOffset
      ];
    if (direction === 'Left') {
      if (isPreviousStyled && isNextStyled) {
        this.moveCursorLeft(e);
      }
    } else if (direction === 'Right') {
      if (isPreviousStyled && isNextStyled) {
        this.moveCursorRight(e);
      }
    } else if (direction === 'Down') {
      if (isPreviousStyled && isNextStyled) {
        this.moveCursorRight(e);
      }
    } else if (direction === 'Up') {
      if (isPreviousStyled && isNextStyled) {
        this.moveCursorRight(e);
      }
    }
  }

  moveCursorRight(e) {
    if (
      this.selectionStart >= this._text.length &&
      this.selectionEnd >= this._text.length
    ) {
      return;
    }
    this._moveCursorLeftOrRight('Right', e);
    this.shouldChangeSelectionPosition('Right', e);
  }

  onInput(e) {
    let fromPaste = this.fromPaste;
    this.fromPaste = false;
    let currentKey;
    e && e.stopPropagation();

    this.keysBounds.map((key, index) => {
      if (!key) return false;
      if (
        key.absoluteStarIndex <= this.selectionStart &&
        this.selectionStart <= key.absoluteEndIndex
      )
        currentKey = { key, index };
      return true;
    });

    const updatedTextLines = this.getUpdatedTextLines();
    const cursorLocation = this.get2DCursorLocation();

    const currentLine = updatedTextLines.find(
      u => u.lineIndex === cursorLocation.lineIndex
    );

    const currentLineStyles = currentLine.lineStyles;
    const change = currentLine.startIndex === 0 ? -1 : +1;
    let isPreviousStyled =
      currentLineStyles[currentLine.startIndex + cursorLocation.charIndex - 1];
    let isNextStyled =
      currentLineStyles[
        currentLine.startIndex + cursorLocation.charIndex + change
      ];

    const isStyled = isPreviousStyled ? isPreviousStyled : isNextStyled;
    let startIndex = 0;
    let endIndex = 0;

    if (isStyled) {
      this.keysBounds.map((key, index) => {
        if (!key) return false;
        if (key.key === isStyled.key) currentKey = { key, index, currentLine };
        return true;
      });

      if (currentKey) {
        startIndex = currentKey.key.startIndex + currentLine.groupStartIndex;
        endIndex = currentKey.key.endIndex + currentLine.groupStartIndex + 1;
      }
    }

    if (!this.isEditing) {
      return;
    }

    let nextText = this._splitTextIntoLines(
      this.hiddenTextarea.value
    ).graphemeText;
    let charCount = this._text.length;
    let nextCharCount = nextText.length;
    let removedText;
    let insertedText;
    let charDiff = nextCharCount - charCount;
    let selectionStart = this.selectionStart;
    let selectionEnd = this.selectionEnd;
    let selection = selectionStart !== selectionEnd;
    let copiedStyle;
    let removeFrom;
    let removeTo;
    if (this.hiddenTextarea.value === '') {
      this.styles = {};
      this.updateFromTextArea();
      this.fire('changed');
      if (this.canvas) {
        this.canvas.fire('text:changed', { target: this });
        this.canvas.requestRenderAll();
      }
      return;
    }
    let textareaSelection = this.fromStringToGraphemeSelection(
      this.hiddenTextarea.selectionStart,
      this.hiddenTextarea.selectionEnd,
      this.hiddenTextarea.value
    );
    let backDelete = selectionStart > textareaSelection.selectionStart;

    if (selection) {
      removedText = this._text.slice(selectionStart, selectionEnd);
      charDiff += selectionEnd - selectionStart;
    } else if (nextCharCount < charCount) {
      if (backDelete) {
        removedText = this._text.slice(selectionEnd + charDiff, selectionEnd);
      } else {
        removedText = this._text.slice(
          selectionStart,
          selectionStart - charDiff
        );
      }
    }

    insertedText = nextText.slice(
      textareaSelection.selectionEnd - charDiff,
      textareaSelection.selectionEnd
    );
    if (removedText && removedText.length) {
      if (insertedText.length) {
        // let's copy some style before deleting.
        // we want to copy the style before the cursor OR the style at the cursor if selection
        // is bigger than 0.
        copiedStyle = this.getSelectionStyles(
          selectionStart,
          selectionStart + 1,
          false
        );
        // now duplicate the style one for each inserted text.
        copiedStyle = insertedText.map(function () {
          // this return an array of references, but that is fine since we are
          // copying the style later.
          return copiedStyle[0];
        });
      }
      if (selection) {
        removeFrom = selectionStart;
        removeTo = selectionEnd;
      } else if (backDelete) {
        // detect differences between forwardDelete and backDelete
        removeFrom = selectionStart - removedText.length;
        removeTo = selectionEnd;
      } else {
        removeFrom = selectionStart;
        removeTo = selectionEnd + removedText.length;
      }

      if (isStyled) {
        if (selection) {
          this.removeChars(selectionStart, selectionEnd);
          this.setSelectionStart(removeFrom);
          this.setSelectionEnd(removeFrom);
          this.setSelectionStart(this.selectionStart + 1);
        }

        if (
          (backDelete && !(startIndex <= this.selectionStart)) ||
          !(this.selectionStart <= endIndex)
        ) {
          this.removeChars(removeFrom, removeTo);
          this.updateParams();
        }

        if (backDelete && this.selectionStart === startIndex) {
          this.removeChars(removeFrom, removeTo);
        }

        if (
          backDelete &&
          currentKey &&
          currentKey.key.value &&
          endIndex === this.selectionStart
        ) {
          this.removeChars(startIndex, endIndex);
          this.setSelectionStart(startIndex);
          this.canvas?.renderAll();
          this.exitEditing();
          this.set('editable', true);
          this.enterEditing();
        }
      } else {
        if (selection) {
          this.removeChars(removeFrom, removeTo);
          // this.setSelectionStart(removeFrom);
          // this.setSelectionEnd(removeFrom);
          // this.setSelectionStart(this.selectionStart + 1);
          // this.updateParams();
        } else {
          this.removeChars(removeFrom, removeTo);
        }
      }
    }

    if (insertedText.length !== 0) {
      if (e.inputType !== 'insertLineBreak') {
        let startIndexRelative = 0;

        if (isStyled && currentKey) {
          const updatedTextLines = this.getUpdatedTextLines();
          const currentStyle = updatedTextLines[currentKey.key.lineIndex];

          startIndexRelative = this.getStartIndex(
            updatedTextLines,
            currentStyle,
            currentKey.key.lineIndex
          );
          // insert text in the variable final
          if (this.selectionStart === endIndex) {
            // if exist another variable in the same line
            if (
              this.keysBounds[currentKey.index + 1] &&
              this.keysBounds[currentKey.index + 1].textStyleGroupIndex ===
                currentKey.key.textStyleGroupIndex
            ) {
              this.insertNewStyleBlock(
                insertedText,
                this.keysBounds[currentKey.index + 1].startIndex +
                  currentKey.currentLine.groupStartIndex,
                copiedStyle
              );
              this.updateParams();
            }
            // if exist another variable in the same textStyleGroupIndex
            if (
              this.keysBounds[currentKey.index + 1] &&
              this.keysBounds[currentKey.index + 1].textStyleGroupIndex ===
                currentKey.key.textStyleGroupIndex &&
              this.keysBounds[currentKey.index + 1].lineIndex !==
                currentKey.key.lineIndex
            ) {
              this.updateParams();
            }
          } else {
            if (this.selectionStart !== startIndexRelative) {
              this.insertNewStyleBlock(
                insertedText,
                selectionStart,
                copiedStyle
              );
            } else {
              this.insertChars(
                insertedText.toString() + currentKey.key.key,
                null,
                startIndexRelative,
                startIndexRelative + currentKey.key.value.length
              );
            }
            this.updateParams();
          }
        } else {
          // insert text without variable
          if (!selection) {
            this.insertChars(
              insertedText.toString(),
              null,
              this.selectionStart,
              undefined
            );
          } else {
            this.updateParams();
          }
        }

        if (
          fromPaste &&
          insertedText.join('') === fabric.copiedText &&
          !fabric.disableStyleCopyPaste
        ) {
          copiedStyle = fabric.copiedTextStyle;
        }

        if (copiedStyle) {
          const haveStyles = copiedStyle.filter(
            style => style.textBackgroundColor === '#d4d1cb'
          );
          if (haveStyles.length > 0) {
            // this.insertNewStyleBlock(insertedText, selectionStart, copiedStyle);
          } else {
            this.insertNewStyleBlock(insertedText, selectionStart, undefined);
          }
          this.updateFromTextArea();
        }
      } else {
        if (currentKey && backDelete === false) {
          if (
            this.keysBounds[currentKey.index + 1] ||
            this.selectionStart === startIndex
          ) {
            this.insertChars(
              insertedText.toString(),
              null,
              this.selectionStart,
              undefined
            );
          }
        } else {
          this.insertChars(
            insertedText.toString(),
            null,
            this.selectionStart,
            0
          );
        }
        this.updateParams();
      }
    }

    this.updateFromTextArea();
    this.fire('changed');

    if (this.canvas) {
      this.canvas.fire('text:changed', { target: this });
      this.canvas.requestRenderAll();
    }
  }

  toObject(propertiesToInclude = []) {
    const originalText = this.getText();
    return fabric.util.object.extend(
      super.toObject.call(this, propertiesToInclude),
      {
        keys: this.keys,
        originalText: originalText,
        metadata: this.metadata,
        keyValues: this.keyValues,
        clipPath: this.clipPath,
        label: this.label,
      }
    );
  }

  toJSON(propertiesToInclude = []) {
    const originalText = this.getText();
    return fabric.util.object.extend(
      super.toObject.call(this, propertiesToInclude),
      {
        keys: this.keys,
        originalText: originalText,
        metadata: this.metadata,
        keyValues: this.keyValues,
        clipPath: this.clipPath,
        label: this.label,
      }
    );
  }

  static fromObject(options, callback) {
    return callback && callback(new fabric.DynamicText(options));
  }
}

fabric.DynamicText = fabric.util.createClass(DynamicTextObject, {
  type: DynamicTextObject.type,
});
fabric.DynamicText.fromObject = DynamicTextObject.fromObject;
