import SearchParameterChip from 'components/SearchParameterChip/SearchParameterChip';

import './SearchParameterFilterChip.scss';

export default ({ label, value, ...props }) => {
  return (
    <SearchParameterChip {...props}>
      <label className="SearchParameterFilterChip-label">{label}: </label>
      <span className="SearchParameterChip-value">{value}</span>
    </SearchParameterChip>
  );
};
