import React from 'react';
import Button from 'components/Button/Button';
import colors from 'theme/Colors.module.scss';

const DeleteAudience = props => {
  const { callback, name, id } = props;

  return (
    <React.Fragment>
      <p>
        You are about to delete the audience named: <strong>{name}</strong>.
      </p>
      <p
        style={{
          marginBottom: '20px',
        }}
      >
        Would you like to proceed?
      </p>

      <Button
        text="Continue"
        textColor="white"
        bgColor={colors.primary900}
        hoverColor={colors.secondary900}
        btnStyle="solid"
        iconBaseline
        callback={() => callback(id)}
      />
    </React.Fragment>
  );
};

export default DeleteAudience;
