import React, { useCallback } from 'react';
import {
  Box,
  //   Input,
  Button,
  //   InputGroup,
  //   InputLeftElement,
} from '@chakra-ui/react';
import uniqueId from 'lodash/uniqueId';
import { useEditor } from 'components/Canvas/hooks';
import PanelTitle from './components/PanelTitle';
// import Icons from '../../Icons';

function Images() {
  const editor = useEditor();

  const addObjectToCanvas = useCallback(() => {
    const objectOptions = {
      width: 100,
      height: 100,
      backgroundColor: '#bdc3c7',
      type: 'DynamicImage',
      subtype: 'img',
      keyValues: [{ key: '{{' + uniqueId('image_') + '}}', value: '' }],
      metadata: {
        // unique id to allow us to tie back a newly saved record with it's entity id
        __session_var_id: uniqueId('s'),
      },
    };
    editor.add(objectOptions);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box sx={{ flex: 1, marginTop: '1.5rem' }}>
      <PanelTitle title="Click to add to canvas" subMenu="ImageTutorial" />

      <Box sx={{ color: '#ffffff', padding: '1.5rem' }}>
        <Button
          w="100%"
          onClick={() => addObjectToCanvas()}
          size="sm"
          sx={{
            backgroundColor: 'rgba(255,255,255,0.1)',
            color: 'rgba(255,255,255,0.9)',
            height: '48px',
            justifyContent: 'start',
            borderRadius: '4px',
          }}
          _hover={{
            backgroundColor: 'rgba(255,255,255,0.15)',
          }}
          _active={{
            backgroundColor: 'rgba(255,255,255,0.2)',
          }}
        >
          Add dynamic image
        </Button>
      </Box>
    </Box>
  );
}

export default Images;
