import React from 'react';
import { Link } from 'react-router-dom';
import { useFlag } from 'flags';
import logo from 'static/images/logo.png';
import Menu from 'components/Menu/Menu';
import Icons from 'scenes/templateEditor/components/Icons';
import './MenuNav.scss';
import { Box, Tooltip } from '@chakra-ui/react';
import colors from 'theme/Colors.module.scss';

const MenuNav = props => {
  const { userDetails, mode, logout, setMobileMenu } = props;
  const isReadOnly = useFlag(['features', 'isReadOnly']);

  const features = mode => {
    const section = {
      title: null,
      menuItems: [
        {
          icon: Icons.Home,
          iconSize: 20,
          text: 'Dashboard',
          link: `${mode === 'advertiser' ? '/adv/dashboard' : '/dashboard'}`,
          callback: () => setMobileMenu(false),
        },
        {
          icon: Icons.Analytics,
          iconSize: 20,
          text: 'Analytics',
          link: '/analytics',
          callback: () => setMobileMenu(false),
        },
        {
          icon: Icons.Campaigns,
          iconSize: 20,
          text: 'Campaigns',
          link: '/campaigns',
          callback: () => setMobileMenu(false),
        },
        {
          icon: Icons.Audiences,
          iconSize: 20,
          text: 'Audiences',
          link: '/audiences',
          callback: () => setMobileMenu(false),
        },
        {
          icon: Icons.Templates,
          iconSize: 20,
          text: 'Templates',
          link: '/templates',
          callback: () => setMobileMenu(false),
        },
      ],
    };

    // TODO: Enable inventory nav item when api is ready.
    // if (mode != 'advertiser') {
    //   section.menuItems.push({ icon: "fas fa-clipboard-list", text: "Inventory", link: "/inventory" })
    // }

    return section;
  };

  const settings = (id, isReadOnly, logout) => {
    const section = {
      title: null,
      menuItems: [
        {
          icon: Icons.MyAccount,
          iconSize: 20,
          text: 'My Account',
          link: '/user/me',
          callback: () => setMobileMenu(false),
        },
        {
          icon: Icons.ManageUsers,
          iconSize: 20,
          text: 'Manage Users',
          link: '/users',
          callback: () => setMobileMenu(false),
        },
        // {icon: "fas fa-user-circle", text: "Profile", link: "/profile"},
        // {icon: "fas fa-file-invoice-dollar", text: "Billing Info", link: "/billing-info"},
        // {icon: "fas fa-bell", text: "Notifications", link: "/notifications"},
      ],
    };

    if (!isReadOnly) {
      section.menuItems.push(
        {
          icon: Icons.OrderFields,
          iconSize: 20,
          text: 'Order Fields',
          link: '/companyParams',
          callback: () => setMobileMenu(false),
        },
        {
          icon: Icons.ManageFonts,
          iconSize: 20,
          text: 'Manage Fonts',
          link: '/fonts',
          callback: () => setMobileMenu(false),
        }
      );
    }
    section.menuItems.push({
      icon: Icons.Logout,
      iconSize: 20,
      text: 'Logout',
      link: '/logout',
      callback: () => logout(),
    });
    return section;
  };

  const buildMenu = () => {
    return (
      <div className="menu-nav__content">
        <Menu content={features(mode)} />
        <Menu content={settings(userDetails?.id, isReadOnly, logout)} />
      </div>
    );
  };

  return (
    <>
      <Box className="menu-nav__logo-container">
        <Link to="/" className="menu-nav__logo-link">
          <img className="menu-nav__logo" src={logo} alt="UnDigital Logo." />
        </Link>
        <Box className="menu-nav__personal-info">
          <Box color={colors.neutral700} className="personal-info__full-name">
            {userDetails.fName} {userDetails.lName}
          </Box>
          <Box className="personal-info__work-mail">
            <Tooltip
              bg={colors.white}
              label={
                <span
                  className="tooltip__label"
                  style={{ color: colors.neutral700, fontWeight: '400' }}
                >
                  {userDetails.email}
                </span>
              }
              aria-label="A tooltip"
              placement="right"
            >
              <span>{userDetails.email} </span>
            </Tooltip>
          </Box>
        </Box>
      </Box>
      {buildMenu()}
    </>
  );
};

export default MenuNav;
