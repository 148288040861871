import { fabric } from 'fabric';
// import { objectTypes } from '../common/constants';

/**
 * Static textbox
 * Will add additional functionalities to textbox
 */
export class StaticTextObject extends fabric.Textbox {
  static type = 'StaticText';
  label = '';

  validateText() {
    if (!this.text) {
      this.canvas.remove(this);
    }
  }
  initialize(text, options) {
    super.initialize(text, options);
    this.on('modified', () => {
      this.validateText();
    });

    this.on('mousewheel', function () {
      this.canvas.renderAll();
      this._renderControls(this.canvas.contextTop, {
        hasControls: false,
      });
    });

    this.on('added', () => {
      this.width = this.initialWidth;
    });

    this.on('mouseup', e => {
      if (e.button === 3) {
        const canvasEl = document.getElementById('canvas');
        const canvasPosition = {
          left: canvasEl?.getBoundingClientRect().left,
          top: canvasEl?.getBoundingClientRect().top,
        };
        const eventData = {
          object: this,
          visible: true,
          position: {
            left: canvasPosition.left + e.pointer.x + 8,
            top: canvasPosition.top + e.pointer.y - 24,
          },
        };
        this.canvas.fire('object:rightclicked', eventData);
        this.canvas.setActiveObject(this);
      }
    });

    return this;
  }

  toObject(propertiesToInclude = []) {
    return super.toObject(propertiesToInclude, {
      text: this.text,
      clipPath: this.clipPath,
      label: this.label,
    });
  }
  toJSON(propertiesToInclude = []) {
    return super.toObject(propertiesToInclude, {
      text: this.text,
      clipPath: this.clipPath,
      label: this.label,
    });
  }

  static fromObject(options, callback) {
    const { text, ...textOptions } = options;
    return callback && callback(new fabric.StaticText(text, textOptions));
  }
}

fabric.StaticText = fabric.util.createClass(StaticTextObject, {
  type: StaticTextObject.type,
});
fabric.StaticText.fromObject = StaticTextObject.fromObject;
