import { Box, IconButton, Tooltip } from '@chakra-ui/react';
import React from 'react';
import { useEditor } from '../../../../components/Canvas';
import Duplicate from '../Icons/Duplicate';
import AddNewPage from '../Icons/AddNewPage';
import useTemplateEditorContext from '../../hooks/useTemplateEditorContext';

function NewFrameButtons() {
  const editor = useEditor();
  const { setIsEnabledDoubleSideMessage, isEnabledDoubleSide } =
    useTemplateEditorContext();

  const duplicateFrame = () => {
    if (!isEnabledDoubleSide) {
      setIsEnabledDoubleSideMessage(true);
      return;
    }

    editor.handlers.frameHandler.duplicate();
    editor.handlers.scrollbarHandler.setScrollToBottom();
  };

  const addNewFrame = () => {
    if (!isEnabledDoubleSide) {
      setIsEnabledDoubleSideMessage(true);
      return;
    }

    editor.handlers.frameHandler.addNewFrame();
    editor.handlers.scrollbarHandler.setScrollToBottom();
  };

  return (
    <Box
      id="add-duplicate-frames"
      sx={{
        position: 'absolute',
      }}
      onWheel={event => {
        editor.handlers.scrollbarHandler.onMouseWheel(event);
      }}
    >
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        <Tooltip label="Duplicate page" hasArrow mt="10px">
          <IconButton
            borderColor="#A7A7A7"
            variant="ghost"
            minW="20px"
            w="25px"
            h="20px"
            p="0"
            icon={<Duplicate size={20} />}
            onClick={() => {
              duplicateFrame();
            }}
          />
        </Tooltip>

        <Tooltip label="Add page" hasArrow mt="10px">
          <IconButton
            borderColor="#A7A7A7"
            variant="ghost"
            minW="20px"
            w="25px"
            h="20px"
            ml="10px"
            p="0"
            icon={<AddNewPage size={20} />}
            onClick={() => {
              addNewFrame();
            }}
          />
        </Tooltip>
      </Box>
    </Box>
  );
}

export default NewFrameButtons;
