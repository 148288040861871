import React from "react"

function Search({ size }) {
    return (
        <svg height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M21 19.9409L16.3098 15.2507C17.5281 13.7606 18.1271 11.8593 17.9829 9.93992C17.8387 8.02058 16.9623 6.2301 15.535 4.93881C14.1077 3.64753 12.2386 2.95424 10.3145 3.00235C8.39034 3.05046 6.55829 3.83628 5.19729 5.19729C3.83628 6.55829 3.05046 8.39034 3.00235 10.3145C2.95424 12.2386 3.64753 14.1077 4.93881 15.535C6.2301 16.9623 8.02058 17.8387 9.93992 17.9829C11.8593 18.1271 13.7606 17.5281 15.2507 16.3098L19.9409 21L21 19.9409ZM10.514 16.506C9.32888 16.506 8.17038 16.1546 7.185 15.4962C6.19962 14.8377 5.43161 13.9019 4.97809 12.807C4.52457 11.7121 4.40591 10.5073 4.63711 9.345C4.86831 8.18267 5.439 7.11499 6.27699 6.27699C7.11499 5.439 8.18267 4.86831 9.345 4.63711C10.5073 4.40591 11.7121 4.52457 12.807 4.97809C13.9019 5.43161 14.8377 6.19962 15.4962 7.185C16.1546 8.17038 16.506 9.32888 16.506 10.514C16.5042 12.1026 15.8723 13.6257 14.749 14.749C13.6257 15.8723 12.1026 16.5042 10.514 16.506Z" fill="currentColor" />
        </svg>
    )
}

export default Search