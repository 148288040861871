import * as yup from 'yup';

export const userFieldValidations = {
  firstname: yup.string().required('First name is required.').trim(),
  lastname: yup.string().required('Last name is required.').trim(),
  email: yup
    .string()
    .email('Invalid email.')
    .required('Email is required.')
    .trim(),
};

export const userPasswordFieldValidations = {
  password: yup.string().required('Password is required.'),
  password_confirmation: yup
    .string()
    .required('Password Confirmation is required.')
    .oneOf([yup.ref('password'), null], 'Passwords must match'),
};

export const userValidation = yup.object().shape({
  ...userFieldValidations,
});
