import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import AppContainer from './scenes/app/appContainer';
import { EditorProvider } from 'components/Canvas';
import { ViewportSizeProvider } from 'modules/layout/layoutContexts';
import * as serviceWorker from './serviceWorker';
import theme from './theme/theme';
import './index.scss';
import 'focus-visible/dist/focus-visible';
// import mirage from './mirage';

// Redux
import { Provider } from 'react-redux';
import configureStore from './store';
import { ChakraProvider } from '@chakra-ui/provider';
const reduxDevTools =
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__();
const store = configureStore(reduxDevTools);

// Main app
const app = (
  <Provider store={store}>
    <EditorProvider>
      <BrowserRouter>
        <ViewportSizeProvider>
          <ChakraProvider theme={theme}>
            <AppContainer />
          </ChakraProvider>
        </ViewportSizeProvider>
      </BrowserRouter>
    </EditorProvider>
  </Provider>
);

const target = document.getElementById('app');

// Main App
ReactDOM.render(app, target);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
