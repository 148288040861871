import moment from 'moment-timezone';
// import { paramsToString } from 'helpers/paramHelpers';

const format = 'YYYY-MM-DD';
const yearToDate = 'YTD';

// default date to be last 30 days (end day need to subtract 1 day as current day dont' count)
export const defaultDates = {
  start: moment().subtract(30, 'days'),
  end: moment().subtract(1, 'days'),
};

export const fillGapsWith = (data, filler) => {
  let dates = Object.keys(data).map(d => moment(d));

  let max = moment.max(dates);
  let min = moment.min(dates);

  for (var x = 0; x <= moment(max).diff(min, 'days'); x++) {
    let index = moment(min).add(x, 'days').format('YYYY-MM-DD');
    if (data[index] === undefined) {
      data[index] = filler;
    }
  }
  return data;
};

export const isValidDate = date => moment(date).isValid();

const daysInMonthRange = interval => {
  const start =
    interval === yearToDate
      ? moment().month('January').startOf('month')
      : moment().subtract(interval, 'month').startOf('month');
  const end =
    interval === yearToDate
      ? moment()
      : moment().subtract(1, 'month').endOf('month');

  return end.diff(start, 'days');
};

export const drPresetOptions = [
  { label: 'Last 30 days', days: 30 },
  { label: 'Last 60 days', days: 60 },
  { label: 'Last 90 days', days: 90 },
  // { label: 'All time', days: daysInMonthRange(120) },
  // { label: 'Last Month', days: daysInMonthRange(1) },
  // { label: 'Last 3 Months', days: daysInMonthRange(3) },
  // {label: 'Last 6 Months', days: daysInMonthRange(6)},
  { label: 'Year to date', days: daysInMonthRange(yearToDate) },
];

export const awPresetOptions = [
  { label: '30 days', days: 30 },
  { label: '60 days', days: 60 },
  { label: '90 days', days: 90 },
  { label: '120 days', days: 120 },
  { label: '6 months', days: 180 },
  { label: '1 year', days: 365 },
];

export const newCampaignFilterOptions = [
  { label: 'Print Volume: High to Low', type: 'volume', order: 'desc' },
  { label: 'Print Volume: Low to High', type: 'volume', order: 'asc' },
];

export const campaignFilterOptions = [
  { label: 'Print Volume: High to Low', type: 'volume', order: 'desc' },
  { label: 'Print Volume: Low to High', type: 'volume', order: 'asc' },
  { label: 'Priority: High to Low', type: 'priority', order: 'desc' },
  { label: 'Priority: Low to High', type: 'priority', order: 'asc' },
];

export const campaignTypeOptions = [
  { label: 'Print Volume', type: 'volume' },
  { label: 'Priority', type: 'priority' },
];

export const campaignOrderOptions = [
  { label: 'High to Low', order: 'desc' },
  { label: 'Low to High', order: 'asc' },
];

export const changeDRPresetRange = (
  days,
  setDates,
  label,
  isDateFormat = false
) => {
  let start = moment().subtract(parseInt(days), 'days');
  let end = moment();

  // subtract 1 day to exclude the current day for 30, 60, 90 selection
  if (days === 30 || days === 60 || days === 90) {
    end = end.subtract(1, 'days');
  }

  if (label === 'Last Month') {
    start = moment().subtract(1, 'month').startOf('month');
    end = moment().subtract(1, 'month').endOf('month');
  } else if (label === 'Last 3 Months') {
    start = moment().subtract(3, 'month').startOf('month');
    end = moment().subtract(1, 'month').endOf('month');
  }

  // TODO: Add to else if () chain when ready to enable.
  // if (label === 'Last 6 Months') {
  //   start = moment().subtract(6, 'month').startOf('month');
  //   end = moment().subtract(1, 'month').endOf('month');
  // };

  // if (label === 'Year to Date') {
  //   start = moment().month('January').startOf('month');
  // };
  if (isDateFormat) {
    setDates({
      from: new Date(start),
      to: new Date(end),
    });
  } else {
    setDates({
      startDate: start,
      endDate: end,
    });
  }
};

export const useCustomRange = range => {
  const presetVal = drPresetOptions.filter(
    preset => preset.days === range.days
  );
  const today = moment().format(format);
  const days =
    presetVal.length !== 0 ? presetVal[0].days : drPresetOptions[0].days;
  const lastMonthEnd = moment()
    .subtract(1, 'month')
    .endOf('month')
    .format(format);

  const dayMatch = () => {
    return (
      moment().subtract(days, 'days').format(format) === range.start &&
      today === range.end
    );
  };

  // const ytdMatch = () => {
  //   return (
  //     moment().month('January').startOf('month').format(format) ===
  //       range.start && today === range.end
  //   );
  // };

  const monthMatch = interval => {
    return (
      moment().subtract(interval, 'month').startOf('month').format(format) ===
        range.start && lastMonthEnd === range.end
    );
  };

  return dayMatch() || monthMatch(1) || monthMatch(3); // || monthMatch(6) || ytdMatch();
};

export const isPresignedURLExpired = url => {
  const intitialTimeField = 'X-Amz-Date';
  const expirationLimitField = 'X-Amz-Expires';

  const urlSearchParams = new URLSearchParams(url);
  const params = Object.fromEntries(urlSearchParams.entries());
  const initialTime = params[intitialTimeField];
  const expirationTime = params[expirationLimitField];
  if (initialTime && expirationTime) {
    const currentTime = moment().unix();
    const endTime = moment(initialTime, moment.ISO_8601).unix();
    const isExpired = currentTime - endTime > parseInt(expirationTime);
    return isExpired;
  }
  return false;
};
