import { schema } from 'normalizr';
import { fromJS } from 'immutable';
import { isEmpty } from 'lodash';
import {
  success,
  failure,
  invalid,
  error,
  start,
} from '../../helpers/reducerHelpers';
import { createSelector } from 'reselect';
import { createApiErrorSelector } from '../api/apiDuck';
import { actionCreator } from '../../helpers/actionHelpers';
import {
  searchParams,
  creativesParams,
  pageParams,
  paramsToString,
} from '../../helpers/paramHelpers';
import * as api from './creativesApi';

// Constants
const CREATE_CREATIVE = 'modules/creatives/CREATE_CREATIVE';
const GET_CREATIVES = 'modules/creatives/GET_CREATIVES';
const GET_CREATIVE_BY_ID = 'modules/creatives/GET_CREATIVES_BY_ID';
const CLEAR_CREATIVE_BY_ID = 'modules/creatives/CLEAR_CREATIVE_BY_ID';
const DELETE_TEMPLATE_BY_ID = 'modules/creatives/DELETE_TEMPLATE_BY_ID';
const UPDATE_CREATIVE = 'modules/creatives/UPDATE_CREATIVE';
const CLEAR_CREATED_CREATIVE = 'modules/creatives/CLEAR_CREATED_CREATIVE';
const GET_SIZE_FORMATS = 'modules/creatives/GET_SIZE_FORMATS';

const STATE_KEY_CREATIVES = 'allCreatives';
const STATE_KEY_CREATED_CREATIVE = 'creative';
const STATE_KEY_CREATIVE_BY_ID = 'creativeById';
const STATE_KEY_CREATIVES_LOADING = 'creativesLoading';
const STATE_KEY_SIZE_FORMATS = 'sizeFormats';

const SCHEMA_CREATIVES = 'creatives';

export const creativesConstants = {
  CREATE_CREATIVE,
  GET_CREATIVES,
  GET_CREATIVE_BY_ID,
  CLEAR_CREATIVE_BY_ID,
  DELETE_TEMPLATE_BY_ID,
  UPDATE_CREATIVE,
  CLEAR_CREATED_CREATIVE,

  STATE_KEY_CREATIVES,
  STATE_KEY_CREATED_CREATIVE,
  STATE_KEY_CREATIVE_BY_ID,
  STATE_KEY_CREATIVES_LOADING,

  SCHEMA_CREATIVES,
};

// Schema
export const creativesSchema = {
  schema: new schema.Entity(SCHEMA_CREATIVES),
};

// Reducer
export const reducer = (ogState = {}, action) => {
  const state = fromJS(ogState);

  switch (action.type) {
    case success(CREATE_CREATIVE):
      return state.set(STATE_KEY_CREATED_CREATIVE, action.payload).toJS();
    case start(GET_CREATIVES):
      return state.set(STATE_KEY_CREATIVES_LOADING, true).toJS();
    case success(GET_CREATIVES):
      return state
        .merge({
          [STATE_KEY_CREATIVES]: action.payload,
          [STATE_KEY_CREATIVES_LOADING]: false,
        })
        .toJS();
    case success(GET_CREATIVE_BY_ID):
      return state.set(STATE_KEY_CREATIVE_BY_ID, action.payload).toJS();
    case success(UPDATE_CREATIVE):
      return state.set(STATE_KEY_CREATED_CREATIVE, action.payload).toJS();
    case success(DELETE_TEMPLATE_BY_ID):
      const deleteTarget = [
        STATE_KEY_CREATIVES,
        'data',
        'entities',
        'creatives',
        action.meta.id,
      ];
      const deleteResult = [STATE_KEY_CREATIVES, 'data', 'result'];
      const deleteTemplate = state.removeIn(deleteTarget);
      const deleteFinal = deleteTemplate
        .setIn(
          deleteResult,
          state.getIn(deleteResult).filter(x => x !== action.meta.id)
        )
        .toJS();

      return deleteFinal;
    case success(GET_SIZE_FORMATS):
      return state.set(STATE_KEY_SIZE_FORMATS, action.payload.data).toJS();

    case CLEAR_CREATED_CREATIVE:
      return state.set(STATE_KEY_CREATED_CREATIVE, null).toJS();

    case CLEAR_CREATIVE_BY_ID:
      return state.set(STATE_KEY_CREATIVE_BY_ID, null).toJS();

    case invalid(UPDATE_CREATIVE):
    case invalid(CREATE_CREATIVE):
    case failure(GET_CREATIVES):
    case failure(GET_CREATIVE_BY_ID):
    case error(action.type):
      // GENERIC API ERROR HANDLER, will match any error for any type
      //console.log('CREATIVES_REDUCER : ', 'API_ERROR', action.payload)
      return ogState;

    default:
      return ogState;
  }
};

// Actions
export const creativesActions = {
  getSizeFormats: (successCallback, failureCallback) =>
    api.getSizeFormats(GET_SIZE_FORMATS, successCallback, failureCallback),
  clearCreatedCreative: actionCreator(CLEAR_CREATED_CREATIVE),
  clearCreativeById: actionCreator(CLEAR_CREATIVE_BY_ID),
  getCreativeById: payload => api.getCreativeById(payload, GET_CREATIVE_BY_ID),

  createCreative: (payload, doneCallback) => {
    const formData = new FormData();

    for (let key in payload) {
      formData.append(`${key}`, payload[key]);
    }

    return api.createCreative(formData, CREATE_CREATIVE, doneCallback);
  },

  getTemplates: (payload, successCallback, failureCallback) => {
    const params = paramsToString({
      ...creativesParams(payload),
      ...pageParams(payload),
      ...searchParams(payload),
    });
    return api.getTemplates(
      params,
      GET_CREATIVES,
      successCallback,
      failureCallback
    );
  },

  deleteTemplateById: (id, successCallback, failureCallback) =>
    api.deleteTemplateById(
      id,
      DELETE_TEMPLATE_BY_ID,
      successCallback,
      failureCallback
    ),

  updateCreative: (payload, doneCallback) => {
    const id = payload.id;
    const formData = new FormData();

    for (let key in payload) {
      formData.append(`${key}`, payload[key]);
    }

    return api.updateCreative(id, formData, UPDATE_CREATIVE, doneCallback);
  },
};

// Selectors
const getUnorderedCreatives = state =>
  state.modules.creatives[STATE_KEY_CREATIVES];

export const creativesSelectors = {
  sizeFormats: state => state.modules.creatives[STATE_KEY_SIZE_FORMATS],
  creativeById: state => state.modules.creatives[STATE_KEY_CREATIVE_BY_ID],
  createdCreative: state => state.modules.creatives[STATE_KEY_CREATED_CREATIVE],

  creatives: createSelector([getUnorderedCreatives], page => {
    if (page == null) return [];
    return (
      !isEmpty(page.data) &&
      page.data.result.map(id => page.data.entities[SCHEMA_CREATIVES][id])
    );
  }),

  isCreativesLoading: state =>
    state.modules.creatives[STATE_KEY_CREATIVES_LOADING],
  getPageData: createSelector([getUnorderedCreatives], page => page),
  getApiError: createApiErrorSelector([
    CREATE_CREATIVE,
    GET_CREATIVES,
    GET_CREATIVE_BY_ID,
    UPDATE_CREATIVE,
  ]),
};

export default reducer;
